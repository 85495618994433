import { switchMap, filter, delay, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from, empty } from 'rxjs'
import ClientActions, { ClientTypes } from "redux/ClientRedux";
import AlertActions from 'redux/AlertRedux'
import { CLIENT_REENTRY_FORM_TYPES, CLIENT_BFET_FORM_TYPES, CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'

export const getClientEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.getClient(action.id, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientSuccess(response.data))
          } else {
            return of(
              ClientActions.getClientFailure(),
              AlertActions.error(['Retreiving client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getClientsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENTS),
    delay(500),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClients(user.accessToken, (action.pageNumber || 1), action.sortKey, action.orderBy)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientsSuccess(response.data, (action.pageNumber || 1), action.sortKey, action.orderBy))
          } else {
            return of(
              ClientActions.getClientsFailure(),
              AlertActions.error(['Fetching Clients failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createClientEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.CREATE_CLIENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createClient(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            const actions = []
            if (action.params && action.params.reload) {
              actions.push(ClientActions.createClientSuccess())
              actions.push(ClientActions.getClients(1))
            } else {
              actions.push(AlertActions.success(['Successfully created a client.']))
              actions.push(ClientActions.createClientSuccess())
            }
            return of(...actions)
          } else {
            if (response.status === 422) {
              return of(
                ClientActions.createClientFailure(),
                AlertActions.error(['Client already exists. Please try searching.'])
              )
            } else {
              return of(
                ClientActions.createClientFailure(),
                AlertActions.error(['Create Client failed! Please try again.'])
              )
            }
          }
        })
      )
    })
  )

export const updateClientEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClient(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.updateClientSuccess(response.data))
          } else {
            return of(
              ClientActions.updateClientFailure(),
              AlertActions.error(['Upadting Client Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteClientEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.DELETE_CLIENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteClient(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.deleteClientSuccess(response.data))
          } else {
            return of(
              ClientActions.updateClientFailure(),
              AlertActions.error(['Upadting Client Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const searchClientEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.SEARCH_CLIENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.searchClients(action.params, action.pageNumber || 1, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.searchClientSuccess(response.data, (action.pageNumber || 1)))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Searching a client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getClientReentryIntakeInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_REENTRY_INTAKE_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getReentryIntakeInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientReentryIntakeInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching re-rentry intake information of a client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getClientBfetInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_BFET_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getBfetInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientBfetInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching BFET Information of a client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeIdentifyingInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_IDENTIFYING_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeIdentifyingInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Identifying Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.identifyingInformation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Identifying Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeIncomeInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_INCOME_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeIncomeInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Income Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.income, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Income Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeEmploymentSearchInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_EMPLOYMENT_SEARCH_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeEmploymentSearchInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employment Search Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.employmentSearchInformation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Employment Search Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeFamilyInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_FAMILY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeFamilyInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Family Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.family, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Family Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeHousingInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_HOUSING_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeHousingInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Housing Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.housing, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Housing Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeImmediateNeedsInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_IMMEDIATE_NEEDS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeImmediateNeedsInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Immediate Needs Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.immediateNeeds, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Immediate Needs Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeIncarcerationInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_INCARCERATION_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeIncarcerationInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Incarceration Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.incarceration, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Incarceration Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeLegalHistoryInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_LEGAL_HISTORY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeLegalHistoruInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Legal history Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.legalHistory, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Legal history Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakePersonalHealthInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_PERSONAL_HEALTH_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakePersonalHealthInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Personal health Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.personalHealthInformation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Personal health Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeSkillsAndAbilitiesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_SKILLS_AND_ABILITIES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeSkillsAndAbilitiesInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Skills & Abilities Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.skillsAndAbilities, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Skills & Abilities Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientReentryIntakeTransportationInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_INTAKE_TRANSPORTATION_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReEntryIntakeTransportationInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Transportation Information information successfully updated!']),
              ClientActions.updateFormSuccess(CLIENT_REENTRY_FORM_TYPES.transportation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Transportation Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

  /** Client BFET forms */
export const updateClientBfetEmploymentGoalsInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_EMPLOYMENT_GOALS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetEmploymentGoalsInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employment Goals Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.employmentGoals, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Employment Goals Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetEmploymentHistoryInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_EMPLOYMENT_HISTORY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetEmploymentHistoryInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employment History Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.employmentHistory, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Employment History Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetEmploymentPlanInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_EMPLOYMENT_PLAN_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetEmploymentPlanInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employment Plan Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.employmentPlan, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Employment Plan Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetAssessedEmploymentBarriersInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetAssessedEmploymentBarriersInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Assessed Employment Barriers Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.assessedEmploymentBarriers, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Assessed Employment Barriers Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetDeclarationAndSignatureInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_DECLARATION_AND_SIGNATURE_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetDeclarationAndSignatureInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Declaration & Signature Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.declarationAndSignature, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Declaration & Signature Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetSkillsAndQualificationInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_SKILLS_AND_QUALIFICATION_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateBfetSkillsAndQualificationInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Skills And Qualification Information information successfully updated!']),
              ClientActions.updateBfetFormSuccess(CLIENT_BFET_FORM_TYPES.skillsAndQualification, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Skills And Qualification Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// BFET Job Search
export const getClientBfetJobSearchInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_BFET_JOB_SEARCH_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientBfetJobSearchInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientBfetJobSearchInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching Job Search Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getClientBfetJobSearchEmployersInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_BFET_JOB_SEARCH_EMPLOYERS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientBfetJobSearchEmployersInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientBfetJobSearchEmployersInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching Job Search Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const postClientBfetJobSearchEmployerInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientBfetJobSearchEmployerInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {

          if (!action.params.file) {
            return of(
              AlertActions.success(['Employer posted successfully!']),
            )
          }

          if (response.ok) {
            return of(
              ClientActions.postClientBfetJobSearchEmployerInformationSuccess(action.id, response.data.employerId, action.params, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Adding an employer failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadClientBfetJobSearchEmployerInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS, ClientTypes.UPDATE_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value


      return from(api.initUploadClientBfetJobSearchEmployerForm(action.id, action.employerId, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadFile(response.data && response.data.uploadURL, {...action.params, id: action.id, employerId: action.employerId, message: 'Employer successfully updated!', type: 'BFET_JOB_SEARCH_EMPLOYER' }),
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Uploading resume failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadClientBfetJobSearchApplicationFormEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_APPLICATION_FORM),
    filter((action) => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value

      if (action.params && !action.params.file1) {
        return empty()
      }

      if (action.params && action.params.file) {
        delete action.params.file
      }

      return from(api.initUploadClientBfetJobSearchEmployerForm(action.id, action.employerId, action.params, 'application', user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadFile(response.data && response.data.uploadURL, {...action.params, id: action.id, documentType: 'application', employerId: action.employerId, message: 'Employer successfully updated!', type: 'BFET_JOB_SEARCH_EMPLOYER' }),
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Uploading application failed! Please try again.'])
            )
          }
        })
      )

    })
  )

export const updateClientBfetJobSearchEmployerInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientBfetJobSearchEmployerInformation(action.id, action.employerId, action.params, user.accessToken)).pipe(
        switchMap(response => {

          if (!action.params.file) {
            return of(
              AlertActions.success(['Employer Updated successfully!']),
            )
          }

          if (response.ok) {
            return of(
              ClientActions.updateClientBfetJobSearchEmployerInformationSuccess(action.id, action.employerId, action.params, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating employer failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteClientBfetJobSearchEmployerInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.DELETE_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      const employerID = action.employerId;
      return from(api.deleteClientBfetJobSearchEmployerInformation(action.id, action.employerId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employer Updated successfully!']),
              ClientActions.deleteClientBfetJobSearchEmployerInformationSuccess(employerID)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating employer failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBfetJobSearchInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_JOB_SEARCH_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientBfetJobSearchInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Job Search Information successfully updated!']),
              ClientActions.updateClientBfetJobSearchInformationSuccess(response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Job Search Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// Consent Health Forms
export const getClientConsentInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_CONSENT_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientConsentInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientConsentInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching Consent Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// GET DVR ServiceDelivery Forms
export const getClientDVRServiceDeliveryInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_DVR_SERVICE_DELIVERY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientDVRServiceDeliveryInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientDVRServiceDeliveryInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.getClientDVRServiceDeliveryInformationFailure(),
              AlertActions.error(['Fetching DVR Service Delivery Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// GET DVR Vocational Forms
export const getClientDVRVocatioanlInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_DVR_VOCATIONAL_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientDVRVocationalInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientDVRVocationalInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.getClientDVRVocationalInformationFailure(),
              AlertActions.error(['Fetching DVR Vocational Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientBFETSuppliesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_BFET_SUPPLIES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientBFETSuppliesInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['BFET Supplies Information successfully updated!']),
              ClientActions.updateClientBFETSuppliesInformationSuccess(response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating BFET Supplies Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientDVRServiceDeliveryInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_DVR_SERVICE_DELIVERY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientDVRServiceDeliveryInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['DVR Service Delivery Information successfully updated!']),
              ClientActions.updateClientDVRServiceDeliveryInformationSuccess(response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating DVR Service Delivery Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientDVRVocationalInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_DVR_VOCATIONAL_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientDVRVocationalInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['DVR Vocational Information successfully updated!']),
              ClientActions.updateClientDVRVocationalInformationSuccess(response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating DVR Vocational Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )



export const updateClientReentryConsentInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_REENTRY_CONSENT_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientConsentInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Consent Information successfully updated!']),
              ClientActions.updateClientReentryConsentInformationSuccess(response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Consent Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// Mental Health Forms
export const getClientMentalInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_MENTAL_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getClientMentalInformation(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientMentalInformationSuccess(response.data))
          } else {
            return of(
              ClientActions.searchClientFailure(),
              AlertActions.error(['Fetching Mental Information failed failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalDemographicsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_DEMOGRAPHICS),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalDemographics(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Demographics Information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.demographics, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Demographics Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalDevelopmentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_DEVELOPMENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalDevelopment(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Development Information information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.development, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Development Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalEducationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_EDUCATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalEducation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Education Information information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.education, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Education Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalEmploymentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_EMPLOYMENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalEmployment(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Employment Information information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.employment, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Employment Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalFamilyInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_FAMILY_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalFamilyInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Family Information information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.familyInformation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Family Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalLegalEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_LEGAL),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      let postParams = action.params;
      return from(api.updateClientMentalLegal(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.updateClientMentalLegalSuccess(action.id, postParams, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Legal Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadClientMentalLegalFormsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_LEGAL_SUCCESS),
    filter((action) => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value

      if (action.params && !action.params.file) {
        return of(
          AlertActions.success(['Legal Information information successfully updated!']),
          ClientActions.uploadLegalExemptionForm(action.id, action.params)
        )
      }

      return from(api.initiateClientMentalLegalForm(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadFile(response.data && response.data.uploadURL, { ...action.params, id: action.id, message: 'Legal Information information successfully updated!', type: 'MENTAL_HEALTH_LEGAL', subType: 'TREATMENT_FORM' }),
              ClientActions.uploadLegalExemptionForm(action.id, action.params)
            )
          } else {
            return of(
              ClientActions.uploadLegalExemptionForm(action.id, action.params),
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Legal Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadClientMentalLegalExemptionFormsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_LEGAL_EXEMPTION_FORM),
    filter((action) => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value

      if (action.params && !action.params.file1) {
        return empty()
      }

      if (action.params && action.params.file) {
        delete action.params.file
      }

      return from(api.initiateClientMentalLegalExemptionForm(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadFile(response.data && response.data.uploadURL, { ...action.params, id: action.id, message: 'Legal Information information successfully updated!', type: 'MENTAL_HEALTH_LEGAL', subType: 'EXEMPTION_FORM' }))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Legal Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadDocumentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_DOCUMENT),
    filter((action) => action.data.uploadURL),
    switchMap((action) => {
      let imageData = {
        imageUrl: window.URL.createObjectURL(action.params.file),
        type: action.params.file.type,
        blob: action.params.file,
        size: action.params.file.size
      }

      return from(api.uploadFile(action.data.uploadURL, imageData.blob, imageData.type)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully updated Documents.']),
              ClientActions.uploadDocumentFileSuccess(action.data),
            )
          } else {
            return of(
              AlertActions.error(['Posting Documents Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadDocumentFileSuccessEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_DOCUMENT_FILE_SUCCESS),
    switchMap((action) => {
      const { user } = store.value
      return from(api.uploadDocumentFileSuccess(action.params.clientId, action.params.documentId, action.params.documentCategory, user.accessToken)).pipe(
        switchMap(response => {
          return of (
            ClientActions.getClientDocumentsInformation(action.params.clientId, action.params.documentCategory)
          )
        })
      )
    })
  )

export const uploadFileEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_FILE),
    filter((action) => action.uploadURL),
    switchMap((action) => {
      let { file, file1 } = action.params
      file = file || file1;

      let imageData = {
        imageUrl: window.URL.createObjectURL(file),
        type: file.type,
        blob: file,
        size: file.size
      }
      return from(api.uploadFile(action.uploadURL, imageData.blob)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success([action.params.message]),
              ClientActions.uploadFileSuccess(action.params)
            )
          } else {
            return of(
              AlertActions.error(['Uploding file failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadFileSuccessEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_FILE_SUCCESS),
    switchMap((action) => {
      const { user } = store.value

      const { type, subType } = action.params
      let apiCall;
      if (type === 'BFET_JOB_SEARCH_EMPLOYER') {
        apiCall = api.uploadClientBfetJobSearchEmployerFormSuccess(action.params.id, action.params.employerId, user.accessToken)
      } else if (type === 'MENTAL_HEALTH_LEGAL') {
        if (subType === 'TREATMENT_FORM') {
          apiCall = api.clientMentalLegalFormUploadSuccess(action.params.id, user.accessToken)
        } else {
          apiCall = api.clientMentalLegalExemptionFormUploadSuccess(action.params.id, user.accessToken)
        }
      }

      return from(apiCall).pipe(
        switchMap(response => {
          if (response.ok) {
            if (type === 'BFET_JOB_SEARCH_EMPLOYER' ) {
              return of (ClientActions.updateBfetJobSearchEmployerInformationSuccess(action.params.employerId, response.data))
            } else if (type === 'MENTAL_HEALTH_LEGAL') {
              return of (ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.legal, response.data))
            } else {
              return empty()
            }
          }
        })
      )
    })
  )

export const updateClientMentalLeisureEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_LEISURE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalLeisure(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Leisure Information information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.leisure, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Leisure Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )


export const updateClientMentalPrimaryReasonsForSeekingServicesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_PRIMARY_REASONS_FOR_SEEKING_SERVICES),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalPrimaryReasonsForSeekingService(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Primary reasons for seeking services information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.primaryReasonsForSeekingServices, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Primary Reasons For Seeking Services failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalSocialRelationshipsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_SOCIAL_RELATIONSHIPS),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalSocialRelationship(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Social Relationships information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.socialRelationships, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Social Relationships Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalSpiritualEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_SPIRITUAL),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalSpiritual(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Spiritual information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.spiritual, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Spiritual Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )
  
export const updateClientMentalMilitaryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_MILITARY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalMilitary(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Military information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.military, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Military Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalMedicalHealthEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_MEDICAL_HEALTH),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalMedicalHealth(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Medical Health information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.medicalHealth, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Medical Health Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalChemicalUseHistoryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_CHEMICAL_USE_HISTORY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalChemicalUseHistory(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Chemical Use History information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.chemicalUseHistory, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Chemical Use History Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalAdditionalInfoEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_ADDITIONAL_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalAdditionalInformation(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Additional information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.additionalInformation, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Additional Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalNutritionEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_NUTRITION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalNutrition(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Nutrition information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.nutrition, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Nutrition Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )


export const updateClientMentalMedicalHistoryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_MEDICAL_HISTORY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalMedicalHistory(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Medical History information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.medicalHistory, response.data))
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Medical History Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientMentalTreatmentHistoryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_MENTAL_TREATMENT_HISTORY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientMentalTreatmentHistory(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Treatment History information successfully updated!']),
              ClientActions.updateMentalFormSuccess(CLIENT_MENTAL_FORM_TYPES.treatmentHistory, response.data)
            )
          } else {
            return of(
              ClientActions.updateFormError(),
              AlertActions.error(['Updating Treatment History Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// Notes Health Forms
export const getClientVisitorNotesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_CLIENT_VISITOR_NOTES_INFORMATION),
    filter(() => store.value.user.accessToken),
    mergeMap((action) => {
      const { user } = store.value
      return from(api.getClientVisitorNotesInformation(action.id, action.category, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ClientActions.getClientVisitorNotesInformationSuccess(action.category, response.data))
          } else {
            return of(
              AlertActions.error(['Fetching Visitor Notes Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const postClientVisitorNotesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_VISITOR_NOTES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientVisitorNotesInformation(action.id, action.category, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully Created Visitor Notes.']),
              ClientActions.postClientVisitorNotesInformationSuccess(action.category, response.data),
              ClientActions.getClientVisitorNotesInformation(action.id, action.category)
            )
          } else {
            return of(
              AlertActions.error(['Posting Visitor Notes Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientVisitorNotesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_VISITOR_NOTES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientVisitorNotesInformation(action.id, action.noteId, action.category, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully updated Visitor Notes.']),
              ClientActions.updateClientVisitorNotesInformationSuccess(action.category, response.data),
              ClientActions.getClientVisitorNotesInformation(action.id, action.category)
            )
          } else {
            return of(
              AlertActions.error(['Update Visitor Notes Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )
  

// Docs Health Forms
export const getClientDocumentsInformationEpic = (action$, store, { api }) =>
action$.pipe(
  ofType(ClientTypes.GET_CLIENT_DOCUMENTS_INFORMATION),
  filter(() => store.value.user.accessToken),
  mergeMap((action) => {
    const { user } = store.value
    return from(api.getClientDocumentsInformation(action.id, action.category, user.accessToken)).pipe(
      switchMap(response => {
        if (response.ok) {
          return of(ClientActions.getClientDocumentsInformationSuccess(action.category, response.data))
        } else {
          return of(
            AlertActions.error(['Fetching Documents Informatin failed! Please try again.'])
          )
        }
      })
    )
  })
)

export const postClientDocumentsInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_DOCUMENTS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientDocumentsInformation(action.id, action.category, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadDocument(action.id, action.category, action.params, response.data)
            )
          } else {
            return of(
              AlertActions.error(['Posting Documents Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteClientDocumentsInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.DELETE_CLIENT_DOCUMENTS_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteClientDocumentsInformation(action.id, action.docId, action.category, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully deleted Documents.']),
              ClientActions.deleteClientDocumentsInformationSuccess(action.category, response.data),
              ClientActions.getClientDocumentsInformation(action.id, action.category)
            )
          } else {
            return of(
              AlertActions.error(['Delete Documents Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )


export const deleteClientVisitorNotesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.DELETE_CLIENT_VISITOR_NOTES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteClientVisitorNotesInformation(action.id, action.noteId, action.category, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully deleted visit note.']),
              ClientActions.deleteClientVisitorNotesInformationSuccess(action.noteId, action.category, response.data),
              ClientActions.getClientVisitorNotesInformation(action.id, action.category)
            )
          } else {
            return of(
              AlertActions.error(['Delete visit notes Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

// Supplies Health Forms
export const getClientSuppliesInformationEpic = (action$, store, { api }) =>
action$.pipe(
  ofType(ClientTypes.GET_CLIENT_SUPPLIES_INFORMATION),
  filter(() => store.value.user.accessToken),
  mergeMap((action) => {
    const { user } = store.value
    return from(api.getClientSuppliesInformation(action.id, action.category, user.accessToken)).pipe(
      switchMap(response => {
        if (response.ok) {
          return of(ClientActions.getClientSuppliesInformationSuccess(action.category, response.data))
        } else {
          return of(
            AlertActions.error(['Fetching Client Supplies Informatin failed! Please try again.'])
          )
        }
      })
    )
  })
)

export const postClientSuppliesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_SUPPLIES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientSuppliesInformation(action.id, action.category, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success([`Successfully created supplies for ${action.category}.`]),
              ClientActions.postClientSuppliesInformationSuccess(action.category, response.data),
              ClientActions.getClientSuppliesInformation(action.id, action.category)
            )
          } else {
            return of(
              AlertActions.error(['Posting Client Supplies Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateClientSuppliesInformationEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPDATE_CLIENT_SUPPLIES_INFORMATION),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateClientSuppliesInformation(action.id, action.supplyId, action.category, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success([`Successfully updated supplies for ${action.category}.`]),
              ClientActions.updateClientSuppliesInformationSuccess(action.category, response.data)
            )
          } else {
            return of(
              AlertActions.error(['Posting Client Supplies Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const postClientPictureEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_PICTURE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientPicture(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.uploadClientPicture(action.id, action.params, response.data && response.data.uploadURL)
            )
          } else {
            return of(
              AlertActions.error(['Posting Documents Informatin failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteClientPictureEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.DELETE_CLIENT_PICTURE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteClientPicture(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.deleteClientPictureSuccess(response && response.data)
            )
          } else {
            return of(
              AlertActions.error(['Deleting Client Picture failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const uploadClientPictureEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.UPLOAD_CLIENT_PICTURE),
    filter((action) => action.uploadURL),
    switchMap((action) => {

      let imageData = {
        imageUrl: window.URL.createObjectURL(action.params.file),
        type: action.params.file.type,
        blob: action.params.file,
        size: action.params.file.size
      }
      
      return from(api.uploadFile(action.uploadURL, imageData.blob)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.postClientPictureSuccess(action.id)
            )
          } else {
            return of(
              AlertActions.error(['Uploading client picture failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const postClientPictureSuccessCallbackEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_PICTURE_SUCCESS),
    delay(25),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.postClientPictureSuccessCallback(action.id, user.accessToken)).pipe(
        delay(1000),
        switchMap((response) => {
          return of (
            ClientActions.getClientSuccess(response.data),
            ClientActions.postClientPictureSuccessCallbackComplete(action.id)
          )
        })
      )
    })
  )

export const postClientPictureSuccessCallbackDelayEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.POST_CLIENT_PICTURE_SUCCESS_CALLBACK_COMPLETE),
    delay(1000),
    switchMap(() => {
      return of (ClientActions.postClientPictureSuccessCallbackCompleteDelay())
    })
  )

export const clientReferEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.REFER_CLIENT),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.referClient(action.id, action.params, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully sent the referral.']),
              ClientActions.referClientSuccess()
            )
          } else {
            return of(
              ClientActions.referClientFailure(),
              AlertActions.error(['Sending client referral failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const accountClientUrlEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.GET_ACCOUNT_CLIENT_URL),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.getAccountsClientUrl(action.clientId, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ClientActions.getAccountClientUrlSuccess(response.data && response.data.url)
            )
          } else {
            return of(
              ClientActions.getAccountClientUrlFailure(),
              AlertActions.error(['There was a problem generating client referral URL! Please try again.'])
            )
          }
        })
      )
    })
  )


export const sendClientUrlEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ClientTypes.SEND_CLIENT_REFERRAL),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.sendClientReferral(action.clientId, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully sent client referral link.']),
            )
          } else {
            return of(
              AlertActions.error(['There was a problem generating client referral URL! Please try again.'])
            )
          }
        })
      )
    })
  )