import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class Military extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.military)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                <Row className='mb-3'>
                    <Col className='p-0 col-6 col-xs-12 col-sm-12 col-md-6 mb-3'>
                      <RadioButton
                        name='hasMilitaryExperience'
                        labelClassName='input-label d-block mb-2'
                        options={yesOrNoOptions}
                        label='Military experience?'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className='p-0 col-6 col-xs-12 col-sm-12 col-md-6 mb-3'>
                      <RadioButton
                        name='hasCombotExperience'
                        labelClassName='input-label d-block mb-2'
                        options={yesOrNoOptions}
                        label='Combat experience?'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-12 col-sm-12'>
                      <label className='input-label'>Branch</label>
                    </Col>
                    <Col className='col-12 p-0 mb-2 d-flex justify-content-between flex-wrap'>
                      <Checkbox name='isArmyBranch'>Army</Checkbox>
                      <Checkbox name='isNavyBranch'>Navy</Checkbox>
                      <Checkbox name='isMarinesBranch'>Marines</Checkbox>
                      <Checkbox name='isAirForceBranch'>Air Force</Checkbox>
                      <Checkbox name='isCoastGaurdBranch'>Coast Guard</Checkbox>
                      <Checkbox name='isSpaceForceBranch'>Space Force</Checkbox>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Where?' name='militaryLocation' type='text' placeholder='Where'/>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Date Entered' name='militaryStartDate' type='date' placeholder='Date'/>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Date Discharged' name='militaryDischargedDate' type='date' placeholder='Date'/>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Highest Rank Held' name='highestRankHeld' type='text' placeholder='Rank Held'/>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Rank At Discharge' name='rankAtDischarge' type='text' placeholder='Rank'/>
                    <TextInput className='p-0 pr-3 col-6 col-xs-12 col-md-6' labelClassName='mb-2' label='Type Of Discharge' name='typeOfDischarge' type='text' placeholder='Discharge'/>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}