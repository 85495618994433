import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextMessage } from 'components/Fields/TextMessage'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import BaseForm from 'components/Patient/BaseForm'
import { CLIENT_REENTRY_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

export default class ImmediateNeedsForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_REENTRY_FORM_TYPES.immediateNeeds)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 w-75' label='Please describe current immediate needs:' name='currentImmediateNeeds' type='textarea' placeholder='Description' size="md" rows='4' />
                  <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    <Col className='col-md-12 col-lg-12 col-12'>
                      <label className='input-label mb-2'>Please select all that apply:</label>
                    </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='alternateTreatments'> Alternate treatments</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='outpatientSubstanceAbuse'   inputClassName='mt-1 align-self-start'
                        inputLabelClassName='text-wrap align-self-start'> Outpatient Substance Abuse </Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='counseling'>  Counseling</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='inpatientDetox'> Inpatient Detox</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='domesticViolence'> Domestic Violence </Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='employment'>  Employment</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='financialAssistance'> Financial Assistance</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='hivTestingOrCounseling' > HIV Testing/ Counseling</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='housing'> Housing </Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='impulseToHarmSelfOrOthers'> Impulse to Harm Self or Others</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='legalOrCriminalAssistance'> Legal/Criminal Assistance</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='medicalCare'> Medical Care</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='mentalHealth'> Mental Health</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='parentingSkillsOrResourcesForChildren'> Parenting Skills/Resources for Childrens</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='stressAndPain'> Stress & Pain Management</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='volunteerWorkOrSchool'> Volunteer Work/School</Checkbox>
                      </Col>
                      <Col className='mb-3'>
                        <Checkbox labelClassName='text-wrap' name='others'> Others Not Listed</Checkbox>
                        <TextInput className='d-flex' name='othersDescription' type='text' placeholder='Others'/>
                      </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}