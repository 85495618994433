import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class EmploymentSearch extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Reentry &gt; Intake &gt; Employment Search:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row ref={el => (this.componentRef = el)} className="shadow rounded border p-4 mt-4 mr-1">
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Employment Search:</Col>
          {write && (
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>The kind of job I want is:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.jobType}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  The last time I looked for a job was:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.lastTimeJobSearchedAt}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>I have a current resume:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasResume)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  I have a current list of references:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasListOfReferences)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  I have missed past work because:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.missedPreviousWorkReason}</label>
              </Col>
            </Row>
          </Row>

          <Row className="col-12 p-0 mt-2">
            <Col className="col-12">
              <LightGrayLabel>
                I prefer to work the following hours on these days (such as 8am to 5pm or 11pm to 6am, etc.)
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <Row>
                <Col> Day </Col>
                <Col> StartTime </Col>
                <Col> EndTime </Col>
              </Row>
              {
                data.workingDays && data.workingDays.map((workingDay) => {
                  return (
                    <Row>
                      <Col> <label className="input-label">{ workingDay.day }</label> </Col>
                      <Col> <label className="input-label">{ workingDay.startTime }</label> </Col>
                      <Col> <label className="input-label">{ workingDay.startTime }</label> </Col>
                    </Row>
                  )
                }
              )
              }
            </Col>
          </Row>

          <Row className="col-12 p-0 mt-2">
            <Col className="col-12">
              <LightGrayLabel>
                I prefer NOT to work the following: 
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <Row>
                <Col> Day </Col>
                <Col> StartTime </Col>
                <Col> EndTime </Col>
              </Row>
              {
                data.nonWorkingDays && data.nonWorkingDays.map((workingDay) => {
                  return (
                    <Row>
                      <Col> <label className="input-label">{ workingDay.day }</label> </Col>
                      <Col> <label className="input-label">{ workingDay.startTime } </label></Col>
                      <Col> <label className="input-label">{ workingDay.startTime } </label></Col>
                    </Row>
                  )
                }
              )
              }
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Because:</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.cannotWorkReason}</label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
