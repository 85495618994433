import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_BFET_JOB_SEARCH } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export class BFETJobSearchView extends React.Component {
  componentDidMount() {
    this.props.getClientBfetJobSearchInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    let {
      clientID,
      data,
      isWriteBfetJobSearchEnabled,
      isReadBfetJobSearchEnabled,
    } = this.props;

    data = data || {};
    if (!isReadBfetJobSearchEnabled && !isWriteBfetJobSearchEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
            <Row className="pl-3">BFET &gt; Job Search Info:</Row>
            <Row className="pl-3 mt-2">
              <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
            </Row>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>BFET &gt; Job Search Info:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_BFET_JOB_SEARCH.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
             // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        {data &&
          data.employers &&
          data.employers.map((employer) => (
            <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 p-0">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Employer:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.employerName}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Address:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">{employer.addressLine1}</label>
                <label className="mr-3 input-label">{employer.city}</label>
                <label className="input-label">{employer.state}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Zip Code:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.zipcode}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Phone Number: (Include Area Code)</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.phoneNumber}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Website:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.website}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Email Address:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.email}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Contact Method:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.contactedMethod}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Person Contacted:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.personContacted}</label>

                </Col>
              </Row>
            
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Position</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.position}</label>

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Resume Or Application:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                {employer.resumeOrApplicationDownloadURL && (
                    <a
                      href={employer.resumeOrApplicationDownloadURL}
                      rel="noreferrer"
                      target="_blank"
                      alt="Image"
                    >
                      Open
                    </a>
                  )}

                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Result</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                <label className="input-label">{employer.result}</label>

                </Col>
              </Row>
      
            </Row>
          ))}
      </Row>
    );
  }

  render() {
    const { getBfetJobSearchFetching } = this.props;
    return (
      <>
        {!getBfetJobSearchFetching && this.renderLoading()}
        {getBfetJobSearchFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getBfetJobSearchFetching: state.client.getBfetJobSearchFetching,
    data: state.client.bfetJobSearch,
    isWriteBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_JOB_SEARCH_INFO
    ),
    isReadBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_JOB_SEARCH_INFO
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientBfetJobSearchInformation: (id) =>
      dispatch(ClientActions.getClientBfetJobSearchInformation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BFETJobSearchView);
