import React from "react";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import BaseForm from "components/Patient/BaseForm";
import { FormHeader, LightGrayLabel } from "../../../../styles/GenericStyles";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { Row } from "react-bootstrap";

export default class AssessedEmploymentBarriers extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_BFET_FORM_TYPES.assessedEmploymentBarriers
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <FormHeader className="mb-3">
                    CLIENT EMPLOYMENT BARRIERS
                  </FormHeader>

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="lackOfSkills"
                  >
                    {" "}
                    Lack of Skills
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressLackOfSkills"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="highSchoolDiplomaOrEquivalency"
                  >
                    {" "}
                    High School Diploma or Equivalency
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressHighSchoolDiplomaOrEquivalency"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="lackOfQualificationsForDesiredEmployment"
                  >
                    {" "}
                    Lack of Qualifications for Desired Employment
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressLackOfQualificationsForDesiredEmploymen"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="lackOfEmploymentHistory"
                  >
                    {" "}
                    Lack of Employment History
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressLackOfEmploymentHistory"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="limitedEnglishProficiency"
                  >
                    {" "}
                    Limited English Proficiency
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressLimitedEnglishProficiency"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="physicalHealthIncapacities"
                  >
                    {" "}
                    Physical Health Incapacities
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressPhysicalHealthIncapacities"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="mentalHealthIncapacities"
                  >
                    {" "}
                    Mental Health Incapacities
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressMentalHealthIncapacities"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="substanceUse"
                  >
                    {" "}
                    Substance Use
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressSubstanceUse"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="criminalRecordAffectingEmployment"
                  >
                    {" "}
                    Criminal Record affecting Employment
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressCriminalRecordAffectingEmployment"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="requiredEmploymentVerification"
                  >
                    {" "}
                    Required Employment Verification (i.e. ID / Birth
                    Certificate, Driver’s License, SSN Card)
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressRequiredEmploymentVerification"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="lackOfNecessaryResources"
                  >
                    {" "}
                    Lack of necessary resources (i.e. childcare, transportation,
                    personal hygiene)
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressLackOfNecessaryResources"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="housing"
                  >
                    {" "}
                    Housing
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressHousing"
                    type="text"
                    placeholder=""
                  />

                  <Checkbox
                    labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                    name="others"
                  >
                    {" "}
                    Other
                  </Checkbox>
                  <TextInput
                    className="mb-3 w-75 ml-3"
                    name="servicesToAddressOthers"
                    type="text"
                    placeholder=""
                  />
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
