
import React from 'react';
import { Form } from 'react-bootstrap'
import BaseModal from 'components/Modals/BaseModal'
import { USER_STATUS } from 'redux/UserRedux'

export class AccountUpdate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: props.email,
      firstName: props.firstName,
      lastName: props.lastName, 
      phone: props.phone,
      addressLine1: props.addressLine1,
      addressLine2: props.addressLine2,
      city: props.city,
      state: props.state,
      country: props.country,
      zipcode: props.zipcode
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save = () => {
    const { onSave, userId, onClose } = this.props
    const { firstName, lastName, phone, addressLine1, city, state, country, zipcode } = this.state
    const user = {
      firstname: firstName,
      lastname: lastName,
      phone: phone,
      address_line1: addressLine1,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode,
      user_details: JSON.stringify({ status: USER_STATUS.APPROVED, points: 34 })
    } 
    onSave && onSave(userId, user)
    onClose && onClose()
  }

  render () {
    const { email, firstName, lastName, phone, addressLine1, addressLine2, city, state, country, zipcode } = this.state
    return (
      <BaseModal heading={'Update Account Information'} onHide={this.props.onClose} onSave={this.save}>
        <Form className='form-horizontal'>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Email Address</Form.Label>
            <Form.Control className='col-md-7' value={email} disabled name="email" maxLength="150" type="text" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>First Name</Form.Label>
            <Form.Control className='col-md-7' value={firstName} name="firstName" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Last Name</Form.Label>
            <Form.Control className='col-md-7' value={lastName} name="lastName" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Phone</Form.Label>
            <Form.Control className='col-md-7' value={phone} name="phone" maxLength="150" type="text" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Address Line 1</Form.Label>
            <Form.Control className='col-md-7' value={addressLine1} name="addressLine1" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Address Line 2</Form.Label>
            <Form.Control className='col-md-7' value={addressLine2} name="addressLine2" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>City</Form.Label>
            <Form.Control className='col-md-7' value={city} name="city" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>State/Province</Form.Label>
            <Form.Control className='col-md-7' value={state} name="state" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group> <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Zip</Form.Label>
            <Form.Control className='col-md-7' value={zipcode} name="zipcode" maxLength="150" type="text" onChange={this.handleChange}/>
          </Form.Group> <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Country</Form.Label>
            <Form.Control className='col-md-7' value={country} name="country" maxLength="150" type="text" onChange={this.handleChange} />
          </Form.Group>
        </Form>
      </BaseModal>
    )
  }
}

export default AccountUpdate;