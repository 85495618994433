import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class Nutrition extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Mental Health &gt; Personal History &gt; Nutrition:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable">
      <Row className="pb-2 w-100">
        <Col> Mental Health &gt; Personal History &gt; Nutrition:</Col>
        {write && (
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
        )}
        <ReactToPrint
        // eslint-disable-next-line
          trigger={() => <a href="javascript:void();">Print</a>}
          content={() => this.componentRef}
        />
      </Row>
        <div className="w-100">
          <Row>
            <Col className="p-0">
              <Table borderless responsive>
                <thead>
                  <tr className="col">
                    <th className="col-1">Meals</th>
                    <th className="col-2">How Often</th>
                    <th className="col-3">Typical Foods Eaten</th>
                    <th className="col-4">Typical Amount Eaten</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="col">
                    <td className="align-middle">Breakfast</td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.breakfastHowOften}</label>
                        </Col>
                      </Col>
                    </td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.breakfastFoodsEaten}</label>
                        </Col>
                      </Col>
                    </td>
                  
                    <td className="align-middle">
                      <div className="d-flex flex-wrap">
                      <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>None</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.noBreakfastEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Low</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.lowBreakfastEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Med</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.mediumBreakfastEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>High</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.highBreakfastEaten)}</label>
                          </Col>
                        </Col>
                        
                      </div>
                    </td>
                  </tr>
                  <tr className="col">
                    <td className="align-middle">Lunch</td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.lunchHowOften}</label>
                        </Col>
                      </Col>
                    </td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.lunchFoodsEaten}</label>
                        </Col>
                      </Col>
                    </td>

                    <td className="align-middle">
                      <div className="d-flex flex-wrap">
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>None</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.noLunchEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Low</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.lowLunchEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Med</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.mediumLunchEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>High</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.highLunchEaten)}</label>
                          </Col>
                        </Col>
                      </div>
                    </td>
                  </tr>
                  <tr className="col">
                    <td className="align-middle">Dinner</td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.dinnerHowOften}</label>
                        </Col>
                      </Col>
                    </td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.dinnerFoodsEaten}</label>
                        </Col>
                      </Col>
                    </td>

                    <td className="align-middle">
                      <div className="d-flex flex-wrap">
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>None</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.noDinnerEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Low</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.lowDinnerEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Med</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.mediumDinnerEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>High</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.highDinnerEaten)}</label>
                          </Col>
                        </Col>
                      </div>
                    </td>
                  </tr>
                  <tr className="col">
                    <td className="align-middle">Snacks</td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.snacksHowOften}</label>
                        </Col>
                      </Col>
                    </td>
                    <td className="align-middle">
                      <Col className="col-12">
                        
                        <Col className="col-12">
                          <label className="input-label">{data.snacksFoodsEaten}</label>
                        </Col>
                      </Col>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex flex-wrap">
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>None</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.noSnacksEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Low</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.lowSnacksEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>Med</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.mediumSnacksEaten)}</label>
                          </Col>
                        </Col>
                        <Col className="col-12">
                          <Col className="col-12">
                            <LightGrayLabel>High</LightGrayLabel>
                          </Col>
                          <Col className="col-12">
                            <label className="input-label">{isBoolean(data.highSnacksEaten)}</label>
                          </Col>
                        </Col>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
