import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_MENTAL_HEALTH_NOTES } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import moment from "moment";
import ReactToPrint from "react-to-print";

export class MentalHealthNotesView extends React.Component {
  componentDidMount() {
    this.props.getClientVisitorNotesInformation(
      this.props.clientID,
      "MENTALHEALTH"
    );
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      data,
      clientID,
      isReadMentalVisitorNotesEnabled,
      isWriteMentalVisitorNotesEnabled,
    } = this.props;

    // data = data || {};

    if (!isReadMentalVisitorNotesEnabled && !isWriteMentalVisitorNotesEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Mental Health &gt; Notes:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Notes:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_MENTAL_HEALTH_NOTES.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Row className="pb-2 ml-1 w-100">
            {data &&
              data.notes &&
              data.notes.map((note, index) => {
                return (
                  <Row className="w-100">
                    <Col className="note-title">{note && note.title}</Col>
                    <Col className="note-time">
                      {note &&
                        moment(note.createdAt).format("MM-DD-YYYY HH:mm")}
                    </Col>
                    <Col className="note-notes">{note && note.notes}</Col>
              
                  </Row>
                );
              })}
    
          </Row>
        </div>
      </Row>
    );
  }

  render() {
    const { mentalVisitorNotesFetching } = this.props;
    return (
      <>
        {!mentalVisitorNotesFetching && this.renderLoading()}
        {mentalVisitorNotesFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mentalVisitorNotesFetching: state.client.notesFetching.MENTALHEALTH,
    data: state.client.notes.MENTALHEALTH,
    isWriteMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_NOTES
    ),
    isReadMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_NOTES
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientVisitorNotesInformation: (id, category) =>
      dispatch(ClientActions.getClientVisitorNotesInformation(id, category)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MentalHealthNotesView);
