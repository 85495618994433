import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextMessage } from 'components/Fields/TextMessage'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col, Table } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class MedicalHistory extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const medicalPrescriptions = values && values.medicalPrescriptions && values.medicalPrescriptions.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {...values, medicalPrescriptions, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.medicalHistory)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} cancel={this.onCancel} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row>
                    <Table className='w-100 pl-0 ml-0' borderless responsive>
                    <thead>
                      <th className='pl-0 align-top' style={{ width: '30%' }}>Currently Prescribed Medicines</th>
                      <th className='align-top' style={{ width: '10%' }}>Dose</th>
                      <th className='align-top' style={{ width: '10%' }}>Dates</th>
                      <th className='align-top' style={{ width: '20%' }}>Purpose</th>
                      <th className='align-top' style={{ width: '20%' }}>Side Effects</th>
                      <th className='align-top' style={{ width: '10%' }}></th>
                    </thead>
                      <tbody>
                        <FieldArray
                          name="medicalPrescriptions"
                          render={(arrayHelpers) => (
                            <>
                              {
                                values.medicalPrescriptions && values.medicalPrescriptions.length > 0 ? (
                                  values.medicalPrescriptions.map((medicalPrescription, index) => (

                                    <tr key={index}>
                                      <td className='pl-0 py-0'>
                                        <TextInput
                                          name={`medicalPrescriptions.${index}.medicineName`}
                                          placeholder='Medicine Name'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`medicalPrescriptions.${index}.dose`}
                                          placeholder='Does'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`medicalPrescriptions.${index}.date`}
                                          placeholder='Date'
                                          type='date'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`medicalPrescriptions.${index}.purpose`}
                                          placeholder='Purpose'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`medicalPrescriptions.${index}.sideEffects`}
                                          placeholder='Side Effects'
                                        />
                                      </td>
                                      
                                      <td className='d-flex p-0'>
                                        <button type="button" className='btn btn-outline-danger mr-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                        { index === 0 && <button type="button" className='btn btn-outline-primary' onClick={() => arrayHelpers.insert(index, '')}> Add </button> }
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <Col className='col-12 d-flex mb-3'>
                                    <button className='btn btn-outline-primary' onClick={() => arrayHelpers.push('')}> Add Member</button>
                                  </Col>
                                )
                              }
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                  </Row>
                  <Row className='align-items-center mb-3 mt-2'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='allergicToMedications'>
                        Are you allergic to any medications, antibiotics, or drugs?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='isAllergic' options={yesOrNoOptions} onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col className='col-10 p-0'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe:'
                        name='allergyDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0'>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0 col-4'>
                      <label className="input-label">Most Recent Examinations:</label>
                    </Col>
                    <Col className='p-0 col-2'>
                      <label className="input-label">Date</label>
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <label className="input-label">Reason</label>
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <label className="input-label">Result</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0 col-lg-4 col-md-12 mb-3'>
                      Last Physical Exam
                    </Col>
                    <Col className='p-0 col-2'>
                      <TextInput
                        name='lastPhysicalExamDate'
                        type='date'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastPhysicalExamReason'
                        placeholder='Reason'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastPhysicalExamResult'
                        placeholder='Result'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0 col-lg-4 col-md-12 mb-3'>
                      Last Doctor Visit
                    </Col>
                    <Col className='p-0 col-2'>
                      <TextInput
                        name='lastDoctorVisitDate'
                        type='date'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastDoctorVisitReason'
                        placeholder='Reason'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastDoctorVisitResult'
                        placeholder='Result'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0 col-lg-4 col-md-12 mb-3'>
                      Last Vision/Hearing Exam
                    </Col>
                    <Col className='p-0 col-2'>
                      <TextInput
                        name='lastVisionExamDate'
                        type='date'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastVisionExamReason'
                        placeholder='Reason'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='lastVisionExamResult'
                        placeholder='Result'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0 col-lg-4 col-md-12 mb-3'>
                      Most Recent Surgery
                    </Col>
                    <Col className='p-0 col-2'>
                      <TextInput
                        name='mostRecentSurgeryDate'
                        type='date'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='mostRecentSurgeryReason'
                        placeholder='Reason'
                      />
                    </Col>
                    <Col className='p-0 col-3 pl-3'>
                      <TextInput
                        name='mostRecentSurgeryResult'
                        placeholder='Result'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0'>
                      <hr />
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col className='p-0 col-12'>
                      <label className="input-label">How Was Your Early Childhood Development?</label>
                    </Col>
                    <Col className='p-0 col-lg-4 col-md-6'>
                      <label >
                        Walking
                      </label>
                    </Col>
                    <Col className='p-0 col-8 d-flex justify-content-between'>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentWalkingEarly'> Early</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentWalkingAverage'> Average</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentWalkingDelayed'> Delayed</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentWalkingUnknown'> Unknown</Checkbox>
                    </Col>
                    <Col className='p-0 col-lg-4 col-md-6'>
                      <label >
                        Talking
                      </label>
                    </Col>
                    <Col className='p-0 col-8 d-flex justify-content-between'>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentTalkingEarly'> Early</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentTalkingAverage'> Average</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentTalkingDelayed'> Delayed</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentTalkingUnknown'> Unknown</Checkbox>
                    </Col>
                    <Col className='p-0 col-lg-4 col-md-6'>
                      <label >
                        Toilet Training
                      </label>
                    </Col>
                    <Col className='p-0 col-8 d-flex justify-content-between'>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentToiletTrainingEarly'> Early</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentToiletTrainingAverage'> Average</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentToiletTrainingDelayed'> Delayed</Checkbox>
                      <Checkbox labelClassName='text-wrap' name='childhoodDevelopmentToiletTrainingUnknown'> Unknown</Checkbox>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4'>
                    <Col className='col-10 p-0'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='Family History Of Medical Problems:'
                        name='familyHistoryOfMedicalProblems'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3 mb-2'>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='sleepPatterns'> Sleep patterns</Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='eatingPatterns'> Eating patterns </Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='behaviour'>  Behavior</Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='energyLevel'> Energy level</Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='physicalActivity'> Physical activity </Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='generalDisposition'>  General disposition</Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='weight'> Weight</Checkbox>
                    </Col>
                    <Col className='mb-3 p-0'>
                      <Checkbox labelClassName='text-wrap' name='nervousness'> Nervousness/tension </Checkbox>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col className='col-10 p-0'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='Describe Changes In The Areas Which You Have Checked'
                        name='changesDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}