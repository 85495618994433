import React from 'react';
import { Select } from 'components/Fields/Select';
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from 'components/Fields/Checkbox';
import { Row, Col } from 'react-bootstrap';

export default class StringUI extends React.Component {
  render () {
    const { values, allowedTypesMap, onOperatorChange, index, accounts } = this.props
    return (
      <Row className='mt-2 w-100'>
        <Col>
          <Select
            name={`includedFilters.filters.${index}.operator`}
            className="custom-select mt-2"
            onChange={(event) => onOperatorChange(event.target.value)}
          >
            <option>Select</option>
            {
              Object.entries(allowedTypesMap[values.includedFilters.filters[index].dataType]).map(([key, value]) => {
                return <option key={key} value={key}>{value.displayName}</option>
              })
            }
          </Select>
        </Col>

        { values && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'STRING' && values.includedFilters.filters[index].name !== 'createdBy' && values.includedFilters.filters[index].operator && (values.includedFilters.filters[index].operator === 'IS' || values.includedFilters.filters[index].operator === 'CONTAINS' || values.includedFilters.filters[index].operator === 'STARTS_WITH' || values.includedFilters.filters[index].operator === 'ENDS_WITH' || values.includedFilters.filters[index].operator === 'IS_NOT' || values.includedFilters.filters[index].operator === 'DOES_NOT_CONTAIN' || values.includedFilters.filters[index].operator === 'DOES_NOT_START_WITH' || values.includedFilters.filters[index].operator === 'DOES_NOT_END_WITH' || values.includedFilters.filters[index].operator === 'IN_THE_FOLLOWING' || values.includedFilters.filters[index].operator === 'NOT_IN_THE_FOLLOWING' ) && (
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.value`}
                type='text'
              />
            </Col>
        )}


        { values && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'STRING' && values.includedFilters.filters[index].name === 'createdBy' && values.includedFilters.filters[index].operator && (values.includedFilters.filters[index].operator === 'IS' || values.includedFilters.filters[index].operator === 'CONTAINS' || values.includedFilters.filters[index].operator === 'STARTS_WITH' || values.includedFilters.filters[index].operator === 'ENDS_WITH' || values.includedFilters.filters[index].operator === 'IS_NOT' || values.includedFilters.filters[index].operator === 'DOES_NOT_CONTAIN' || values.includedFilters.filters[index].operator === 'DOES_NOT_START_WITH' || values.includedFilters.filters[index].operator === 'DOES_NOT_END_WITH' || values.includedFilters.filters[index].operator === 'IN_THE_FOLLOWING' || values.includedFilters.filters[index].operator === 'NOT_IN_THE_FOLLOWING' ) && (
            <Col>
              <Select
                name={`includedFilters.filters.${index}.value`}
                className="custom-select mt-2"
              >
                <option>Select</option>
                {
                  accounts && accounts.map((acc) => {
                    return <option key={acc.accountUserId} value={acc.accountUserId}>{acc.firstName + ' ' + acc.lastName}</option>
                  })
                }
              </Select>
            </Col>
        )}

        <Col>
          <Checkbox labelClassName='text-wrap mt-3' name={`includedFilters.filters.${index}.caseSensitive`} disabled={values.includedFilters.filters[index].operator === 'DOES_NOT_EXIST' || values.includedFilters.filters[index].operator === 'EXISTS'}> Case Sensitive </Checkbox>
        </Col>
      </Row>
    )
  }
}