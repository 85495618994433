import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_BFET_DOCUMENTS } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import { LightGrayLabel, Preview, PreviewFooter } from "styles/GenericStyles";
import ReactToPrint from "react-to-print";
import { ReactComponent as PDFIcon } from 'images/svg/pdf.svg';

export class PatientBFETDocumentView extends React.Component {
  componentDidMount() {
    this.props.getClientDocumentsInformation(this.props.clientID, "BFET");
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      data,
      clientID,
      isWriteBfetDocumentsEnabled,
      isReadBfetDocumentsEnabled,
    } = this.props;
    // data = data || {};

    if (!isReadBfetDocumentsEnabled && !isWriteBfetDocumentsEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Documents:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>BFET &gt; Documents:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_BFET_DOCUMENTS.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <Row className="col-12">
          <Col className="col-12 p-0 d-flex flex-wrap">
            {data &&
              data.documents &&
              data.documents.map((document, index) => (
                <div key={index}>
                  <Preview>
                    {
                      <a
                        href={document.downloadURL}
                        rel="noreferrer"
                        target="_blank"
                        alt="Image"
                      >
                        { (document.documentType === 'image/png' || document.documentType === 'image/jpeg' || document.documentType === 'image/jpg') ? <img alt='' src={document.downloadURL} onError={({ currentTarget }) => {currentTarget.src=`${document.downloadURL}`}} /> : (document.documentType === 'application/pdf' ? <PDFIcon /> : 'Open' )}
                      </a>
                    }
                  </Preview>
                  <PreviewFooter>
                    <span>{document.documentName}</span>
                    {/* <button type='button' className='delete-icon' onClick={() => {this.onDelete(document)}}><DeleteIcon /></button> */}
                  </PreviewFooter>
                </div>
              ))}
          </Col>
        </Row>
      </Row>
    );
  }

  render() {
    const { bfetDocumentsFetching } = this.props;
    return (
      <>
        {!bfetDocumentsFetching && this.renderLoading()}
        {bfetDocumentsFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bfetDocumentsFetching: state.client.documentsFetching.BFET,
    data: state.client.documents.BFET,
    isWriteBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_DOCUMENTS
    ),
    isReadBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_DOCUMENTS
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDocumentsInformation: (id, category) =>
      dispatch(ClientActions.getClientDocumentsInformation(id, category)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientBFETDocumentView);
