export const ROOT = '/'
export const CLIENTS = '/clients'
export const APPOINTMENTS = '/appointments'
export const EDIT_APPOINTMENT = '/appointments/:id/edit'
export const USERS = '/users'
export const ROLES = '/roles'

export const VIEW_PATIENT = '/clients/:id'

export const PATIENT_REENTRY = '/clients/:id/reentry'
export const CLIENT_REENTRY_INDENTIFYING_INFO = '/clients/:id/reentry/INDENTIFYING_INFORMATION'
export const CLIENT_REENTRY_FAMILY = '/clients/:id/reentry/FAMILY'
export const CLIENT_REENTRY_INCOME = '/clients/:id/reentry/INCOME'
export const CLIENT_REENTRY_HOUSING = '/clients/:id/reentry/HOUSING'
export const CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION = '/clients/:id/reentry/PERSONAL_HEALTH_INFORMATION'
export const CLIENT_REENTRY_LEGAL_HISTORY = '/clients/:id/reentry/LEGAL_HISTORY'
export const CLIENT_REENTRY_INCARCERATION_REENTRY = '/clients/:id/reentry/INCARCERATION_REENTRY'
export const CLIENT_REENTRY_TRANSPORTATION = '/clients/:id/reentry/TRANSPORTATION'
export const CLIENT_REENTRY_IMMEDIATE_NEEDS = '/clients/:id/reentry/IMMEDIATE_NEEDS'
export const CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION = '/clients/:id/reentry/EMPLOYMENT_SEARCH_INFORMATION'
export const CLIENT_REENTRY_SKILLS_AND_ABILITIES = '/clients/:id/reentry/SKILLS_AND_ABILITIES'

export const CLIENT_REENTRY_CONSENT = '/clients/:id/reentry/consent'
export const CLIENT_REENTRY_DOCUMENTS = '/clients/:id/reentry/documents'
export const CLIENT_REENTRY_NOTES = '/clients/:id/reentry/notes'
export const CLIENT_REENTRY_SUPPLIES = '/clients/:id/reentry/supplies'

export const CLIENT_BFET = '/clients/:id/bfet'
export const CLIENT_BFET_EMPLOYMENT_GOALS = '/clients/:id/bfet/EMPLOYMENT_GOALS'
export const CLIENT_BFET_SKILLS_AND_QUALIFICATION = '/clients/:id/bfet/SKILLS_AND_QUALIFICATION'
export const CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS = '/clients/:id/bfet/ASSESSED_EMPLOYMENT_BARRIERS'
export const CLIENT_BFET_EMPLOYMENT_PLAN = '/clients/:id/bfet/EMPLOYMENT_PLAN'
export const CLIENT_BFET_DECLARATION_AND_SIGNATURE = '/clients/:id/bfet/DECLARATION_AND_SIGNATURE'
export const CLIENT_BFET_EMPLOYMENT_HISTORY = '/clients/:id/bfet/EMPLOYMENT_HISTORY'

export const CLIENT_BFET_JOB_SEARCH = '/clients/:id/bfet/jobsearch'
export const CLIENT_BFET_DOCUMENTS = '/clients/:id/bfet/documents'
export const CLIENT_BFET_NOTES = '/clients/:id/bfet/notes'
export const CLIENT_BFET_SUPPLIES = '/clients/:id/bfet/supplies'

export const CLIENT_MENTAL_HEALTH = '/clients/:id/mentalhealth'
export const CLIENT_MENTAL_HEALTH_SUPPLIES = '/clients/:id/mentalhealth/supplies'

export const CLIENT_MENTAL_HEALTH_PH = '/clients/:id/mentalhealth/personalhistory'

export const CLIENT_MENTAL_HEALTH_DEMOGRAPHICS = '/clients/:id/mentalhealth/DEMOGRAPHICS'
export const CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES = '/clients/:id/mentalhealth/PRIMARY_REASONS_FOR_SEEKING_SERVICES'
export const CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY = '/clients/:id/mentalhealth/FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY'
export const CLIENT_MENTAL_HEALTH_DEVELOPMENT = '/clients/:id/mentalhealth/DEVELOPMENT'
export const CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS = '/clients/:id/mentalhealth/SOCIAL_RELATIONSHIPS'
export const CLIENT_MENTAL_HEALTH_EDUCATION = '/clients/:id/mentalhealth/EDUCATION'
export const CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION = '/clients/:id/mentalhealth/SPIRITUAL_RELIGION'
export const CLIENT_MENTAL_HEALTH_LEGAL = '/clients/:id/mentalhealth/LEGAL'
export const CLIENT_MENTAL_HEALTH_EMPLOYMENT = '/clients/:id/mentalhealth/EMPLOYMENT'
export const CLIENT_MENTAL_HEALTH_MILITARY = '/clients/:id/mentalhealth/MILITARY'
export const CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL = '/clients/:id/mentalhealth/LEISURE_AND_RECREATIONAL'
export const CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY = '/clients/:id/mentalhealth/COUNSELING_AND_PRIOR_TREATMENT_HISTORY'
export const CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH = '/clients/:id/mentalhealth/MEDICAL_AND_PHYSICAL_HEALTH'
export const CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY= '/clients/:id/mentalhealth/MEDICAL_HISTORY'
export const CLIENT_MENTAL_HEALTH_NUTRITION = '/clients/:id/mentalhealth/NUTRITION'
export const CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY = '/clients/:id/mentalhealth/CHEMICAL_USE_HISTORY'
export const CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION = '/clients/:id/mentalhealth/ADDITIONAL_INFORMATION'

export const CLIENT_MENTAL_HEALTH_DOCUMENTS = '/clients/:id/mentalhealth/documents'
export const CLIENT_MENTAL_HEALTH_NOTES = '/clients/:id/mentalhealth/notes'
export const CLIENT_VIISITOR_NOTES = '/clients/:id/visitornotes'
export const CLIENT_DOCUMENTS = '/clients/:id/documents'
export const CLIENT_SUPPLIES = '/clients/:id/supplies'

export const CLIENT_DVR_DOCUMENTS = '/clients/:id/dvr/documents'
export const CLIENT_DVR_NOTES = '/clients/:id/dvr/notes'
export const CLIENT_DVR_SERVICE_DELIVERY = '/clients/:id/dvr/servicedelivery'
export const CLIENT_DVR_VOCATIONAL = '/clients/:id/dvr/vocational'
export const CLIENT_DVR_SUPPLIES = '/clients/:id/dvr/supplies'

export const ACCOUNT_INFO = '/account'
export const LOGIN = '/login'
export const SIGN_UP = '/signup'
export const FORGOT_PASSWORD = '/forgot_password'
export const RESET_PASSWORD = '/reset_password/:token'
export const UPDATE_PASSWORD = '/update_password'

export const REPORTS = '/reports'
export const EDIT_REPORT = '/reports/:id'
export const TEMPLATES = '/templates'
export const QUERY_TEMPLATES = '/templates/:id/queries'

export const LOGGED_IN_ROUTES = [
  ROOT,
  APPOINTMENTS,
  EDIT_APPOINTMENT,
  USERS,
  VIEW_PATIENT,
  ACCOUNT_INFO,
  PATIENT_REENTRY,
  TEMPLATES,
  QUERY_TEMPLATES,
  CLIENT_REENTRY_INDENTIFYING_INFO,
  CLIENT_REENTRY_FAMILY,
  CLIENT_REENTRY_INCOME,
  CLIENT_REENTRY_HOUSING,
  CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION,
  CLIENT_REENTRY_LEGAL_HISTORY,
  CLIENT_REENTRY_INCARCERATION_REENTRY,
  CLIENT_REENTRY_TRANSPORTATION,
  CLIENT_REENTRY_IMMEDIATE_NEEDS,
  CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION,
  CLIENT_REENTRY_SKILLS_AND_ABILITIES,

  CLIENT_REENTRY_CONSENT,
  CLIENT_REENTRY_DOCUMENTS,
  CLIENT_REENTRY_NOTES,
  CLIENT_REENTRY_SUPPLIES,

  CLIENT_BFET,
  CLIENT_BFET_EMPLOYMENT_GOALS,
  CLIENT_BFET_SKILLS_AND_QUALIFICATION,
  CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS,
  CLIENT_BFET_EMPLOYMENT_PLAN,
  CLIENT_BFET_DECLARATION_AND_SIGNATURE,
  CLIENT_BFET_EMPLOYMENT_HISTORY,

  CLIENT_BFET_JOB_SEARCH,
  CLIENT_BFET_DOCUMENTS,
  CLIENT_BFET_NOTES,
  CLIENT_BFET_SUPPLIES,
  
  CLIENT_MENTAL_HEALTH,
  CLIENT_MENTAL_HEALTH_PH,
  CLIENT_MENTAL_HEALTH_DEMOGRAPHICS,
  CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES,
  CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY,
  CLIENT_MENTAL_HEALTH_DEVELOPMENT,
  CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS,
  CLIENT_MENTAL_HEALTH_EDUCATION,
  CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION,
  CLIENT_MENTAL_HEALTH_LEGAL,
  CLIENT_MENTAL_HEALTH_EMPLOYMENT,
  CLIENT_MENTAL_HEALTH_MILITARY,
  CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL,
  CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY,
  CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH,
  CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY,
  CLIENT_MENTAL_HEALTH_NUTRITION,
  CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY,
  CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION,
  CLIENT_MENTAL_HEALTH_SUPPLIES,

  CLIENT_MENTAL_HEALTH_DOCUMENTS,
  CLIENT_MENTAL_HEALTH_NOTES,

  CLIENT_DVR_DOCUMENTS,
  CLIENT_DVR_NOTES,
  CLIENT_DVR_SERVICE_DELIVERY,
  CLIENT_DVR_VOCATIONAL,
  CLIENT_MENTAL_HEALTH_SUPPLIES,

  CLIENT_VIISITOR_NOTES,
  CLIENT_DOCUMENTS,
  CLIENT_SUPPLIES,
  RESET_PASSWORD
]