import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col, Table } from 'react-bootstrap'
import { Label, LightGrayLabel } from 'styles/GenericStyles';
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'

export default class LeisureAndRecreational extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const leisures = values && values.leisures && values.leisures.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {...values, leisures, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.leisure)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm cancel={this.onCancel} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <Label className='text-wrap input-label'>
                        Describe special areas of interest or hobbies (e.g., art, books, crafts, physical fitness, sports, outdoor activities, church activities, walking, diet/health, hunting, fishing, bowling, travel, gardening, etc.)
                      </Label>
                    </Col>
                  </Row>

                  <Row>
                    <Table className='w-100 pl-0 ml-0' borderless responsive>
                    <thead>
                      <th className='pl-0 align-top' style={{ width: '30%' }}>Activity</th>
                      <th className='align-top' style={{ width: '30%' }}>How often?</th>
                      <th className='align-top' style={{ width: '30%' }}>How often in the past?</th>
                      <th className='align-top' style={{ width: '10%' }}></th>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="leisures"
                        render={(arrayHelpers) => (
                          <>
                            {
                              values.leisures && values.leisures.length > 0 ? (
                                values.leisures.map((leisure, index) => (

                                  <tr key={index}>
                                    <td className='pl-0 py-0'>
                                      <TextInput
                                        name={`leisures.${index}.activity`}
                                        placeholder='Activity'
                                      />
                                    </td>
                                    <td className='py-0'>
                                      <TextInput
                                        name={`leisures.${index}.howOften`}
                                        placeholder='How'
                                      />
                                    </td>
                                    <td className='py-0'>
                                      <TextInput
                                        name={`leisures.${index}.howOftenInPast`}
                                        placeholder='How'
                                      />
                                    </td>
                                    
                                    <td className='d-flex p-0'>
                                      <button type="button" className='btn btn-outline-danger mr-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                      { index === 0 && <button type="button" className='btn btn-outline-primary' onClick={() => arrayHelpers.insert(index, '')}> Add </button> }
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <Col className='col-12 d-flex mb-3'>
                                  <button className='btn btn-outline-primary' onClick={() => arrayHelpers.push('')}> Add Member</button>
                                </Col>
                              )
                            }
                          </>
                        )}
                      />
                    </tbody>
                    </Table>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}