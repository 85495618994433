import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_MENTAL_HEALTH_DOCUMENTS } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import Strings from "i18n/en";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import { LightGrayLabel, Preview, PreviewFooter } from "styles/GenericStyles";
import ReactToPrint from "react-to-print";
import { ReactComponent as PDFIcon } from 'images/svg/pdf.svg';

export class PatientMentalHealthDocumentView extends React.Component {
  componentDidMount() {
    this.props.getClientDocumentsInformation(
      this.props.clientID,
      "MENTALHEALTH"
    );
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      data,
      clientID,
      isWriteMentalDocumentsEnabled,
      isReadMentalDocumentsEnabled,
    } = this.props;
    // data = data || {};
    if (!isReadMentalDocumentsEnabled && !isWriteMentalDocumentsEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Mental Health &gt; Documents:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Documents:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_MENTAL_HEALTH_DOCUMENTS.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <Row className="col-12">
          <Col className="col-12 p-0 d-flex flex-wrap">
            {data &&
              data.documents &&
              data.documents.map((document, index) => (
                <div key={index}>
                  <Preview>
                    {
                      <a
                        href={document.downloadURL}
                        rel="noreferrer"
                        target="_blank"
                        alt="Image"
                      >
                        { (document.documentType === 'image/png' || document.documentType === 'image/jpeg' || document.documentType === 'image/jpg') ? <img alt='' src={document.downloadURL} onError={({ currentTarget }) => {currentTarget.src=`${document.downloadURL}`}}/> : (document.documentType === 'application/pdf' ? <PDFIcon /> : 'Open' )}
                      </a>
                    }
                  </Preview>
                  <PreviewFooter>
                    <span>{document.documentName}</span>
                    {/* <button type='button' className='delete-icon' onClick={() => {this.onDelete(document)}}><DeleteIcon /></button> */}
                  </PreviewFooter>
                </div>
              ))}
          </Col>
        </Row>
      </Row>
    );
  }

  render() {
    const { mentalDocumentsFetching } = this.props;
    return (
      <>
        {!mentalDocumentsFetching && this.renderLoading()}
        {mentalDocumentsFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mentalDocumentsFetching: state.client.documentsFetching.MENTALHEALTH,
    data: state.client.documents.MENTALHEALTH,
    isWriteMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DOCUMENTS
    ),
    isReadMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DOCUMENTS
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDocumentsInformation: (id, category) =>
      dispatch(ClientActions.getClientDocumentsInformation(id, category)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientMentalHealthDocumentView);
