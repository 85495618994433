import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "components/Fields/TextInput";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";
import { RadioButton } from 'components/Fields/RadioButton'

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]

export default class JobSearchLog extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      addEmployer: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (
      (this.props.bfetJobSearchEmployers &&
        this.props.bfetJobSearchEmployers.length) !==
      (newProps.bfetJobSearchEmployers &&
        newProps.bfetJobSearchEmployers.length)
    ) {
      this.setState({
        addEmployer: false,
      });
    }
  }

  handleSubmit = (values) => {
    this.props.updateClientBfetJobSearchInformation(this.props.clientID, {
      ...values,
      clientId: this.props.clientID,
    });
  };

  handleSubmitEmployer = (values) => {
    if (values && values.employerId) {
      this.props.updateClientBfetJobSearchEmployerInformation(
        this.props.clientID,
        values.employerId,
        {
          ...values,
          clientId: this.props.clientID,
        }
      );
    } else {
      this.props.postClientBfetJobSearchEmployerInformation(
        this.props.clientID,
        {
          ...values,
          clientId: this.props.clientID,
        }
      );
    }
  };

  removeEmployer = (employerId) => {
    if (employerId) {
      this.props.deleteClientBfetJobSearchEmployerInformation(
        this.props.clientID,
        employerId
      );
    } else {
      this.setState({
        addEmployer: false,
      });
    }
  };

  addEmployer = () => {
    this.setState({ addEmployer: true });
  };

  renderEmployerForm = (employer) => {
    const { read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        initialValues={{ ...employer, employerName: employer.employerName }}
        onSubmit={this.handleSubmitEmployer}
        validationSchema={Yup.object({
          employerName: Yup.string().required("Employer name is required"),
        })}
        render={({
          values,
          touched,
          dirty,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form disabled={!write}>
              <div className="shadow rounded border mt-3 mr-0 ml-0 p-5 w-100">
                <hr />
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="employerName">
                      Employer:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-6">
                    <TextInput
                      name="employerName"
                      type="text"
                      placeholder="Employer Name"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="col-2 p-0">
                    <label className="input-label" htmlFor="address1">
                      Address:
                    </label>
                  </Col>
                  <Col className="col-xs-12 col-md-8 d-flex flex-wrap p-0">
                    <TextInput
                      className="w-100"
                      name="addressLine1"
                      type="text"
                      placeholder="Address Line 1"
                    />
                    <TextInput
                      className="flex-grow-1 mr-3"
                      name="city"
                      type="text"
                      placeholder="City"
                    />
                    <TextInput
                      className="flex-grow-1"
                      name="state"
                      type="text"
                      placeholder="State"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="zipcode">
                      Zip Code:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4">
                    <TextInput
                      name="zipcode"
                      className="mr-2"
                      type="text"
                      placeholder="Zip Code"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label text-wrap" htmlFor="phone">
                      Phone Number: (Include Area Code)
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4">
                    <TextInput
                      name="phoneNumber"
                      className="mr-2"
                      type="text"
                      placeholder="Phone"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="websiteName">
                      Website:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-8">
                    <TextInput
                      name="website"
                      type="text"
                      placeholder="Website Name"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="emailAddress">
                      Email Address:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-8">
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="Email Address"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="contactMethod">
                      Contact Method:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4">
                    <TextInput
                      className="mr-2"
                      name="contactedMethod"
                      type="text"
                      placeholder="Contact"
                    />
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label
                      className="input-label ml-2"
                      htmlFor="personContacted"
                    >
                      Person Contacted:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4">
                    <TextInput
                      name="personContacted"
                      type="text"
                      placeholder="Person Contacted"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="position">
                      Position
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4">
                    <TextInput
                      className="mr-2"
                      name="position"
                      type="text"
                      placeholder="Position"
                    />
                  </Col>


                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label ml-2" htmlFor="choose">
                      Resume or Application:
                    </label>
                  </Col>

                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4 d-flex align-items-center">
                    {employer.resumeOrApplicationDownloadURL && (
                      <a
                        href={employer.resumeOrApplicationDownloadURL}
                        rel="noreferrer"
                        target="_blank"
                        alt="Image"
                      >
                        Open
                      </a>
                    )}

                    <div className="custom-file ml-3">
                      <input
                        type="file"
                        className="custom-file-input"
                        name="file"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        onChange={(event) => {
                          setFieldValue("file", event.target.files[0]);
                        }}
                      />
                      <label className="custom-file-label left-file-label">
                        Choose file
                      </label>
                    </div>
                  </Col>
                </Row>
                
                
                <Row className='align-items-center mb-4 w-100'>
                  <Col className='col-12 p-0'>
                    <label className='input-label mb-2' htmlFor='isResumeOrApplicationSubmitted'>Is Resume or Application submitted</label>
                  </Col>
                  <Col className='col-12 p-0'>
                    <RadioButton name='isResumeOrApplicationSubmitted' options={yesOrNoOptions} onChange={handleChange} />
                  </Col>
                </Row>


                <Row className="align-items-center mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                    <label className="input-label" htmlFor="result">
                      Result:
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-12">
                    <TextInput name="result" type="text" placeholder="Result" />
                  </Col>
                </Row>
                <button
                  type="button"
                  className="btn btn-outline-danger ml-3 mr-3"
                  onClick={() =>
                    this.removeEmployer(employer && employer.employerId)
                  }
                >
                  {" "}
                  Remove{" "}
                </button>
                <button type="submite" className="btn btn-outline-primary">
                  {employer && employer.employerId ? "Update" : "Save"}{" "}
                </button>
              </div>
            </Form>
          </>
        )}
      />
    );
  };

  render() {
    const { data, read, write, bfetJobSearchEmployers } =
      this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <>
        <Formik
          initialValues={{ ...data, clientEmployedId: null }}
          onSubmit={this.handleSubmit}
          render={({
            values,
            touched,
            dirty,
            errors,
            handleBlur,
            setFieldValue,
          }) => (
            <>
              <Prompt when={dirty} message={Strings.prompt.message} />
              <Form >
                <div className="shadow rounded border mr-0 ml-0 p-5 w-100">
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-sm-12 col-md-2">
                      <label className="input-label" htmlFor="firstName">
                        Name
                      </label>
                    </Col>
                    <Col className="p-0 col-xs-12 col-md-8 d-flex">
                      <TextInput
                        className="mr-3 mb-0"
                        name="firstName"
                        type="text"
                        placeholder="First"
                      />
                      <TextInput
                        className="mr-3 mb-0"
                        name="middleName"
                        type="text"
                        placeholder="MI"
                      />
                      <TextInput
                        className="mb-0"
                        name="lastName"
                        type="text"
                        placeholder="Last"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-2">
                      <label className="input-label" htmlFor="weekOf">
                        Week of:
                      </label>
                    </Col>
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-8">
                      <TextInput
                        name="weekOf"
                        type="text"
                        placeholder="Week of"
                      />
                    </Col>
                  </Row>
                  <Button variant="primary" type="submit">
                    {"SAVE"}
                  </Button>
                </div>
              </Form>
            </>
          )}
        />

        {bfetJobSearchEmployers &&
          bfetJobSearchEmployers.map((employer) =>
            this.renderEmployerForm(employer)
          )}
        {this.state.addEmployer && this.renderEmployerForm({})}

        <Row className="mb-3 mt-3 ml-1">
          <Col className="p-0">
            <button
              className="btn btn-outline-primary"
              onClick={this.addEmployer}
            >Add Employer
            </button>
          </Col>
        </Row>
      </>
    );
  }
}
