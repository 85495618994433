import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`
    html {
        height: 100%;
        margin: 0;
    }
    body {
        min-height: 100%;
        margin: 0;
        font-family: PoppinsRegular !important;
    }

    #root {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
        background-color: #3B41C2 !important;
        border-color: #3B41C2 !important;
    }

    .btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
        background-color: #E7ECF4 !important;
        border-color: #E7ECF4 !important;
        color: #333333 !important;
    }

    .input-label {
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.16px;
    }

    .custom-file-label.left-file-label::after {
        left: 0;
        border-left: inherit;
        border-radius: 0.25rem 0 0 0.25rem;
        background-color: #0469F6;
        width: 30%;
        text-align: center;
        text-indent: 0%;
        color: #FFFFFF;
    }

    .custom-file-label.left-file-label {
        text-indent: calc(30% + 1rem);
    }

    input[type="text"], input[type="password"] {
        border: 1px solid #9BA8C3;
        border-radius: 3px;
    }

    select {
        height: 48px;
        background: #FFFFFF;
        border: 2px solid #CCCCCC;
        border-radius: 4px;
        padding: .35rem 0 .35rem 1rem;
    }

    input::-webkit-input-placeholder {
        font-size: 14px;
        line-height: 4;
    }

    #clientWrapper .react-horizontal-scrolling-menu--wrapper, #clientWrapper .react-horizontal-scrolling-menu--scroll-container {
        background-color: #3B41C2;
        width: 100%;
        padding: 0 .5rem;
        
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .dashboardBanner {
        background: url('./images/dashboard-banner.png');
        background-position-x: right;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
    }

    .header-nav-dropdown {
        .dropdown-menu.show {
            left: auto;
            right: 0;
        }
    }

    .btn.btn-outline-primary.selected-slot {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

    .react-horizontal-scrolling-menu--item span {
        white-space: nowrap;
    }
    .react-horizontal-scrolling-menu--item a, #sidebar a {
        color: white;
    }
    
    .note-list {
        border: none;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0px;
        border-radius: 2px;
        color: #43425D;
        opacity: 1;
        max-height: 70vh;
        overflow: auto;
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    
    .note-list .note {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #BABABA;
        cursor: pointer;
        padding: 10px;
    }

    .note-list .note:first-of-type {
        border-radius: 2px 2px 0 0; 
    }

    .note-list .note:last-of-type {
        border-radius: 0 0 2px 2px; 
    }

    .note-list .note:hover, .note-list .note.note-selected {
        background: rgba(59, 65, 194, .06);
        border-radius: 2px;
    }

    .note-list .note .note-time {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #BEBEBE;
    }


    .note-list .note .note-createdBy {
        font-weight: 800;
    }

    .ath {
        position: fixed;
        z-index: 999;
        right: 22px;
        top: 100px;
    }

    .popover-no-arrow .arrow {
        display: none;
    }

    .search-input {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        height: 40px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        user-select: none;
        & .input-value {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    button a {
        color: #fff;
    }

    @media print{
        @page { 
            size: auto;
            margin: 15mm 24mm 15mm 24mm;
        }

        .printable .c-details {
            margin-left: 20px;
        }

        .printable a {
            display: none;
        }

        .printable.print-hide {
            display: none;
        }
    }

    form .error {
        padding: 5px
        color: red
    }

`;
