import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import BaseForm from "components/Patient/BaseForm";
import {
  FormHeader,
  LightGrayLabel,
} from "styles/GenericStyles";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import LoadingView from "components/LoadingView.jsx";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import { TextMessage } from "components/Fields/TextMessage";
import { RadioButton } from "components/Fields/RadioButton";

const yesOrNoOptions = [
  { key: "Yes", value: true },
  { key: "No", value: false },
];

export class Vocational extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getClientDVRVocationalInformation(this.props.clientID);
  }

  handleSubmit = (values) => {
    this.props.updateInfo(this.props.clientID, {
      ...values,
      clientId: this.props.clientID,
    });
  };

  renderContent() {

    const { data, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm disabled={!write}>
                <FormHeader className="mt-n2 mb-3">
                  VOCATIONAL ASSESSMENT WORKSHEET
                </FormHeader>
                <div className="w-100">

                <Row className="align-items-center mb-3 mt-2 col-12 p-0">
                  <Col className="col-12">
                    <label
                      className="input-label mb-2"
                      htmlFor="describeImpairing"
                    >
                      What Kind Of Job And Work Setting Are You Hoping To Find?
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="kindOfJobAndWorkHopingToFind"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3 mt-2">
                  <Col className="col-12 ">
                    <label className="input-label mb-2" htmlFor="inviteOther">
                      Would you like to invite others to attend your DVR
                      meetings and/or have them assist you with this form (e.g.,
                      friend, family member, advocate, legal guardian, teacher
                      or other VR counselor from another program)?
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton name="wouldLikeToInviteOthers" options={yesOrNoOptions} onChange={handleChange}/>
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other, Describe:
                    </label>
                  </Col>
                  <Col className=" col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="describeWhoYouWouldLikeToInvite"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3 mt-2">
                  <Col className="col-12">
                    <label
                      className="input-label mb-2"
                      htmlFor="socialSecurity"
                    >
                      If you receive Social Security benefits, are you
                      interested in learning more about them and how they may be
                      impacted by going to work?
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton
                      name="socialSecurityBenefitsImpact"
                      options={yesOrNoOptions}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other, Describe:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="describeSocialSecurityBenefitsImpact"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="monthlyBudget"
                    >
                      When you go to work, how much money will you need in your
                      monthly budget to support yourself and/or your family?
                    </label>
                  </Col>
                  <Col className="col-6">
                    <TextInput
                      name="moneyRequiredToSupportSelfAndFamily"
                      type='text'
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="laborMarket"
                    >
                      Describe the labor market information that indicates this
                      employment goal is in-demand in your local area:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="laborMarketInformation"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="inDemand"
                    >
                      If it is not in-demand, what have you considered that
                      could increase your chances of employment in this field
                      (e.g., targeted work experience, internship, on-the-job
                      training, relocation)?
                    </label>
                  </Col>
                  <Col className="col-6">
                    <TextInput
                      name="considerationsThatCouldIncreaseTheChancesOfEmployment"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="highDemand"
                    >
                      What jobs have you considered in potentially high demand
                      industries (e.g., such as computer science, healthcare,
                      science, technology, engineering and math)?
                    </label>
                  </Col>
                  <Col className="col-6">
                    <TextInput
                      name="jobsYouWouldConsiderInHighDemandIndustries"
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherJobsYouWouldConsider"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3 mt-2">
                  <Col className="col-12">
                    <label className="input-label mb-2" htmlFor="inviteOther">
                      Does your employment goal require a background check as a
                      condition of employment?
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton name="employmentGoalRequiresBackgroundCheck" options={yesOrNoOptions} onChange={handleChange}/>
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Yes, DVR Requires That A Background Check Be Completed
                      That Verifies You Will Not Be Excluded From The
                      Specificjob.
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="backgroundCheckInfo"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="goal"
                    >
                      Please describe the assessments that support your
                      employment goal (e.g., career tests, volunteer or
                      school-based work experiences, career development class,
                      etc.)?
                    </label>
                  </Col>
                  <Col className="col-6">
                  
                  <TextMessage
                      labelClassName="d-none"
                      name="assessmentsSupportingEmploymentGoals"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherAssessments"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="education"
                    >
                      Describe Your Education / Training History, Including
                      Licenses And Certificates.
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="educationOrTrainingHistory"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="culturalValue"
                    >
                      Do You Have Any Specific Cultural Values, Practices And/Or
                      Preferred Language Needs That You Would Like To Share With
                      DVR Prior To Job Search And Placement?
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="culturalValuesOrOtherPreferences"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="challenges"
                    >
                      Describe your previous successes and challenges obtaining
                      and maintaining a job (e.g., work history, gaps in
                      employment, transferrable skills, volunteer experiences,
                      etc.)?
                    </label>
                  </Col>
                  <Col className="col-6">
                  
                  <TextMessage
                      labelClassName="d-none"
                      name="previousSuccesses"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherPreviousSuccesses"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="individualStrengths"
                    >
                      Describe Your Individual Strengths That Make This
                      Employment Goal Appropriate:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="individualStrenghts"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>{" "}
                {/* one */}
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="personalResources"
                    >
                      Describe your personal resources that might support your
                      employment plan (e.g., family and social supports,
                      transportation, etc.):
                    </label>
                  </Col>
                  <Col className="col-6">
                  
                    <TextMessage
                      labelClassName="d-none"
                      name="personalResources"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherPersonalResources"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="disabilityRelated"
                    >
                      Describe your disability-related barriers to employment
                      (e.g., mobility concerns, problems standing for long
                      periods of time, communication barriers, getting along
                      with others, memory, difficulty learning new information /
                      tasks):
                    </label>
                  </Col>
                  <Col className="col-6">
                  
                    <TextMessage
                      labelClassName="d-none"
                      name="disabilities"
                      placeholder="Describe"
                      size="md"
                    />
                   
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherDisabilities"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="disabilityRelated"
                    >
                      Describe how you are currently managing our
                      disability-related barriers (e.g., counseling, physical
                      therapy, skills training, support group):
                    </label>
                  </Col>
                  <Col className="col-6">
                  
                    <TextMessage
                      labelClassName="d-none"
                      name="howDisabilityIsManaged"
                      placeholder="Description"
                      size="md"
                    />
                   
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherHowDisabilityIsManaged"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="additionalBarriers"
                    >
                      Describe any additional barriers to employment that are
                      not disability-related (e.g., childcare, no valid mailing
                      address / email / phone, criminal history, transportation,
                      housing, food assistance, etc.):
                    </label>
                  </Col>
                  <Col className="col-6">
                    <TextMessage
                      labelClassName="d-none"
                      name="additionalBarriersToEmploymentThatAreNotDisabilityRelated"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                  <Col className="pt-1 col-12">
                    <label
                      className="input-label text-wrap mb-2"
                      htmlFor="describe"
                    >
                      If Other:
                    </label>
                  </Col>
                  <Col className="col-10">
                    <TextMessage
                      labelClassName="d-none"
                      name="otherAdditionalBarriersToEmploymentThatAreNotDisabilityRelated"
                      placeholder="Describe"
                      size="md"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="p-0">
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-12 col-lg-12 col-12">
                    <label className="input-label mb-3">
                      What Services Do You Think You Might Need To Reach Your
                      Employment Goal?
                    </label>
                  </Col>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label
                        className="input-label"
                        htmlFor="assistiveTechnology"
                      >
                        Assistive Technology (AT)
                      </label>
                    </Col>
                    <Row className="col-8">
                      <TextMessage
                        labelClassName="d-none"
                        name="assistiveTechnology"
                        placeholder="assistiveTechnology"
                        size="md"
                        className="mr-2"
                      />
                      <TextInput
                        name="otherAssistiveTechnology"
                        type="text"
                        placeholder="If Other, Specify"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label
                        className="input-label"
                        htmlFor="independentLiving"
                      >
                        Independent Living (IL)
                      </label>
                    </Col>
                    <Row className="col-8 p-0">
                      <TextInput
                        name="independentLiving"
                        type="text"
                        placeholder="Describe"
                        className="col-12"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label
                        className="input-label"
                        htmlFor="personalAssistanceServices"
                      >
                        Personal Assistance Services (PAS)
                      </label>
                    </Col>
                    <Row className="col-8 p-0">
                      <TextInput
                        name="personalAssistanceServices"
                        type="text"
                        placeholder="Describe"
                        className="col-12"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label
                        className="input-label"
                        htmlFor="supportedEmployment"
                      >
                        Supported Employment (SE)
                      </label>
                    </Col>
                    <Row className="col-8 p-0">
                      <TextInput
                        name="supportedEmployment"
                        type="text"
                        placeholder="Describe"
                        className="col-12"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label
                        className="input-label"
                        htmlFor="transitionServices"
                      >
                        Transition Services (TS)
                      </label>
                    </Col>
                    <Row className="col-8 p-0">
                      <TextInput
                        name="transitionServices"
                        type="text"
                        placeholder="Describe"
                        className="col-12"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-2 w-100 ml-1">
                    <Col className="col-sm-12 col-md-4 col-lg-4">
                      <label className="input-label" htmlFor="otherServices">
                        Other Services
                      </label>
                    </Col>
                    <Row className="col-8">
                      <TextInput
                        name="otherServices"
                        type="text"
                        placeholder="Describe"
                        className="col-12"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12 col-lg-12 col-12">
                      <label className="input-label mb-3">
                        When you get ready to look for work, will you need help
                        with any of the following?
                      </label>
                    </Col>
                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3 ml-1">
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="applicationAssistance"
                        >
                          Application assistance
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="interviewPreparation"
                        >
                          Interview preparation
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox labelClassName="text-wrap" name="oneOnOneMeetingsToWorkOnResume">
                          One-on-one meetings to work on resume
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="coverLetters"
                        >
                          Cover letters
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="jobSearch"
                        >
                          Job search
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="onlineJobSearch"
                        >
                          Online job search
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="emailJobLeads"
                        >
                          Email job leads
                        </Checkbox>
                      </Col>

                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="masterApplication"
                        >
                          Master application
                        </Checkbox>
                      </Col>
                    </Row>



                    <Row className="mb-3 ml-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="referralToWorkSource"
                      >
                        Referral to WorkSource (e.g., Job Hunter series, basic
                        computer skills, Microsoft training, ex-offender
                        services, mature worker services, youth services)
                      </Checkbox>
                    </Row>


                    

                    <Row className="mb-3 ml-3 d-flex align-items-center">
                      <Checkbox
                        labelClassName="text-wrap d-flex align-items-center"
                        name="otherWork"
                      >
                        Other
                      </Checkbox>

                      <TextInput
                        className="mb-0"
                        name="otherWorkDescription"
                        type="text"
                        placeholder="(Specify)"
                      />
                    </Row>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label
                        className="input-label text-wrap mb-2"
                        htmlFor="empolymentGoal"
                      >
                        Describe Any Additional Information That Supports Your
                        Employment Goal:
                      </label>
                    </Col>
                    <Col className="col-10">
                      <TextMessage
                        labelClassName="d-none"
                        name="additionalInformationThatSupportsEmploymentGoal"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label
                        className="input-label text-wrap mb-2"
                        htmlFor="specificGoals"
                      >
                        Your Specific Employment Goal Should Be Consistent With
                        Your Strengths, Resources, Priorities, Concerns,
                        Abilities, Capabilities, Interests, And Informed Choice.
                      </label>
                    </Col>
                    <Col className="col-10">
                      <TextMessage
                        labelClassName="d-none"
                        name="specificEmploymentGoal"
                        placeholder="Your Specific Employment Goal Is:"
                        size="md"
                      />
                    </Col>
                  </Row>
                </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  render() {
    const { getDVRVocationalFetching } = this.props;
    return (
      <>
        {!getDVRVocationalFetching && this.renderLoading()}
        {getDVRVocationalFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getDVRVocationalFetching: state.client.getDVRVocationalFetching,
    data: state.client.dvrVocational,
    write: isPermissionEnabled(state.user, PERMISSIONS.WRITE_DVR_VOCATIONAL),
    read: isPermissionEnabled(state.user, PERMISSIONS.READ_DVR_VOCATIONAL),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDVRVocationalInformation: (id) =>
      dispatch(ClientActions.getClientDVRVocationalInformation(id)),
    updateInfo: (id, params) =>
      dispatch(ClientActions.updateClientDVRVocationalInformation(id, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vocational);
