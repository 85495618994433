import React from 'react';
import { ReactComponent as VerticalDotsIcon } from '../images/svg/dots-vertical.svg';
import { Popover, OverlayTrigger, ListGroup } from 'react-bootstrap';
import { IconBtn } from './Styles/AppointmentListStyles';
import { Formik } from 'formik';

export const ContextMenu = (props) => {
  
  const handleSubmit = (values) => {
    props.getAccountAppointments(props.accountId, values.start, values.end)
  }

  const options = props.options;

  return (
    <Formik initialValues={{ start: null, end: null }} onSubmit={handleSubmit}>
      <OverlayTrigger
        trigger="click"
        placement="left-start"
        rootClose
        overlay={
          <Popover className='popover-no-arrow p-0 border-0' id="popover-basic">
            <Popover.Content className='popover-content p-0'>
              <ListGroup defaultActiveKey="#link1">
                {options.map((option, index) => {
                  return <ListGroup.Item action className='py-2 px-4'
                      key={index}
                      onClick={option.onClick}
                    >
                      {option.name}
                    </ListGroup.Item>
                })}
              </ListGroup>
            </Popover.Content>
          </Popover>
      }>
      <IconBtn className='mr-3'>
        <VerticalDotsIcon />
      </IconBtn>
      </OverlayTrigger>
    </Formik>
  );
}
