import React from "react";
import Layout from "components/Layout.jsx";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import UserActions from "redux/UserRedux";
import ClientActions from "redux/ClientRedux";
import { TOKEN_TYPE } from 'redux/UserRedux'
import PatientBasicDetails from "components/Patient/PatientBasicDetails";
import EditClientModal from "components/Modals/EditClientModal";
import ReEntryIntake from "components/Patient/Reentry/Intake";
import ReEntryConsent from "components/Patient/Reentry/Consent";
import ReEntryDocuments from "components/Patient/Reentry/Documents";
import ReEntryNotes from "components/Patient/Reentry/Notes";
import ReEntrySupplies from "components/Patient/Reentry/Supplies";
import BFETIntake from "components/Patient/BFET/Intake";
import BFETJobSearchLog from "components/Patient/BFET/JobSearchLog";
import BFETDocuments from "components/Patient/BFET/Documents";
import BFETNotes from "components/Patient/BFET/Notes";
import BFETSupplies from "components/Patient/BFET/Supplies";
import MentalHealthPersonalHistory from "components/Patient/MentalHealth/PersonalHistory";
import MentalHealthDocuments from "components/Patient/MentalHealth/Documents";
import MentalHealthNotes from "components/Patient/MentalHealth/Notes";
import MentalHealthSupplies from "components/Patient/MentalHealth/Supplies";
import DVRServiceDelivery from "components/Patient/DVR/ServiceDelivery";
import DVRVocational from "components/Patient/DVR/Vocational";
import DVRDocuments from "components/Patient/DVR/Documents";
import DVRNotes from "components/Patient/DVR/Notes";
import DVRSupplies from "components/Patient/DVR/Supplies";
import VisitorNotes from "components/Patient/VisitorNotes";
import Documents from "components/Patient/Documents";
import Supplies from "components/Patient/Supplies";
import { CientDetailsText } from "pages/Styles/Patient";
import PatientSidebar from "components/Patient/PatientSidebar.jsx";
import { CLIENT_FORM_TYPES } from "redux/ClientRedux";
import LoadingView from "components/LoadingView.jsx";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class EditPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      clientID: props.match.params.id,
      clientToken: null,
      showEditClientModal: false
    };
  }

  componentDidMount() {
    this.setState({
      clientID: this.props.match.params.id,
      clientToken: this.props.location.search && this.props.location.search.split('token=')[1]
    }, () => {
      this.props.getClient(this.props.match.params.id);
      this.props.getClientReentryIntakeInformation(this.props.match.params.id);
      this.props.getClientBfetInformation(this.props.match.params.id);
      this.props.getClientMentalInformation(this.props.match.params.id);
      this.props.getClientConsentInformation(this.props.match.params.id);
      this.props.getClientBfetJobSearchInformation(this.props.match.params.id);
      this.props.getClientBfetJobSearchEmployersInformation(
        this.props.match.params.id
      );
    });
  }

  onSave = (user) => {
    this.props.createPatient(user);
  };

  updateCategory = (category) => {
    this.setState({ category });
  };

  renderLoading = () => {
    return <LoadingView />;
  };

  editClient = () => {
    this.setState({
      showEditClientModal: true
    })
  }

  onCloseEdiClienttModal = () => {
    this.setState({
      showEditClientModal: false
    })
  }

  render = () => {
    const { clientID, showEditClientModal } = this.state;
    const {
      category,
      client,
      reentry,
      bfet,
      mental,
      getReentryFetching,
      getBfetFetching,
      getMentalFetching,
      consent,
      getConsentFetching,
      defaultVisitorNotesFetching,
      reEntryVisitorNotesFetching,
      bfetVisitorNotesFetching,
      mentalVisitorNotesFetching,
      DVRVisitorNotesFetching,
      defaultVisitorNotes,
      reEntryVisitorNotes,
      bfetVisitorNotes,
      mentalVisitorNotes,
      DVRVisitorNotes,
      reEntryDocumentsFetching,
      bfetDocumentsFetching,
      mentalDocumentsFetching,
      DVRDocumentsFetching,
      reEntryDocuments,
      bfetDocuments,
      mentalDocuments,
      dvrDocuments,
      getBfetJobSearchFetching,
      bfetJobSearch,
      updateClientBfetJobSearchInformation,
      getBfetJobSearchEmployersFetching,
      bfetJobSearchEmployers,
      postClientBfetJobSearchEmployerInformation,
      updateClientBfetJobSearchEmployerInformation,
      deleteClientBfetJobSearchEmployerInformation,
      isWriteConsentEnabled,
      isReadConsentEnabled,
      isWritereEntryVisitorNotesEnabled,
      isReadreEntryVisitorNotesEnabled,
      isWritereEntryDocumentsEnabled,
      isReadreEntryDocumentsEnabled,
      isWriteBfetJobSearchEnabled,
      isReadBfetJobSearchEnabled,
      isWriteBfetNotesEnabled,
      isReadBfetNotesEnabled,
      isWriteBfetDocumentsEnabled,
      isReadBfetDocumentsEnabled,
      isWriteMentalVisitorNotesEnabled,
      isReadMentalVisitorNotesEnabled,
      isWriteMentalDocumentsEnabled,
      isReadMentalDocumentsEnabled,
      isWriteDVRDocumentsEnabled,
      isReadDVRDocumentsEnabled,
      isWriteDVRNotesEnabled,
      isReadDVRNotesEnabled,
      postingClientPicture,
      deleteClientPicture,
      defaultDocuments,
      defaultDocumentsFetching,
      isWriteDefaultDocumentsEnabled,
      isReadDefaultDocumentsEnabled,
      isWriteReEntryEnabled,
      isWriteBfetEnabled,
      isWriteMHEnabled,
      isWriteDVREnabled,
      isReadReEntryEnabled,
      isReadBfetEnabled,
      isReadMHEnabled,
      isReadDVREnabled,
      isWriteDefaultNotesEnabled,
      isWriteDefaultSuppliesEnabled,
      isReadDefaultNotesEnabled,
      isReadDefaultSuppliesEnabled,
    } = this.props;

    let goTo =
      this.props.match.path &&
      this.props.match.path.split("/").pop().toUpperCase();
    if (goTo) {
      if (goTo.split("_").length > 1) {
        goTo = goTo.replace(/_/g, " ");
      }
    }

    return (
      <Layout>
        <Col md={12} id="clientWrapper">
          <Row className="mb-3">
            <CientDetailsText>Client Details</CientDetailsText>
          </Row>

          {client && (
            <PatientBasicDetails
              client={client}
              identifyingInformation={reentry.identifyingInformation || {}}
              clientID={clientID}
              postClientPicture={this.props.postClientPicture}
              postingClientPicture={postingClientPicture}
              deleteClientPicture={deleteClientPicture}
              editClient={this.editClient}
            />
          )}

          <Row className="mb-3 d-flex flex-nowrap">
            <Col
              md={2}
              className="border px-0 shadow rounded"
              style={{ height: "fit-content" }}
            >
              <PatientSidebar
                clientID={clientID}
                updateCategory={this.updateCategory}
                category={category}
                isWriteReEntryEnabled={isWriteReEntryEnabled}
                isWriteBfetEnabled={isWriteBfetEnabled}
                isWriteMHEnabled={isWriteMHEnabled}
                isWriteDVREnabled={isWriteDVREnabled}
                isReadReEntryEnabled={isReadReEntryEnabled}
                isReadBfetEnabled={isReadBfetEnabled}
                isReadMHEnabled={isReadMHEnabled}
                isReadDVREnabled={isReadDVREnabled}
                isWriteDefaultNotesEnabled={isWriteDefaultNotesEnabled}
                isWriteDefaultSuppliesEnabled={isWriteDefaultSuppliesEnabled}
                isWriteDefaultDocumentsEnabled={isWriteDefaultDocumentsEnabled}
                isReadDefaultNotesEnabled={isReadDefaultNotesEnabled}
                isReadDefaultSuppliesEnabled={isReadDefaultSuppliesEnabled}
                isReadDefaultDocumentsEnabled={isReadDefaultDocumentsEnabled}

              />
            </Col>

            <Col md={10} className="ml-3">
              {(category === CLIENT_FORM_TYPES.REENTRY ||
                category === CLIENT_FORM_TYPES.REENTRY_INTAKE) &&
                !getReentryFetching &&
                this.renderLoading()}
              {(category === CLIENT_FORM_TYPES.REENTRY ||
                category === CLIENT_FORM_TYPES.REENTRY_INTAKE) &&
                getReentryFetching && (
                  <ReEntryIntake
                    client={client}
                    clientID={clientID}
                    reentry={reentry}
                    goTo={goTo}
                  />
                )}

              {category === CLIENT_FORM_TYPES.REENTRY_CONSENT &&
                !getConsentFetching &&
                this.renderLoading()}
              {category === CLIENT_FORM_TYPES.REENTRY_CONSENT &&
                getConsentFetching && (
                  <ReEntryConsent
                    clientID={clientID}
                    data={consent}
                    write={isWriteConsentEnabled}
                    read={isReadConsentEnabled}
                    updateClientReentryConsentInformation={
                      this.props.updateClientReentryConsentInformation
                    }
                  />
                )}

              { category === CLIENT_FORM_TYPES.CLIENT_REENTRY_SUPPLIES && (
                <ReEntrySupplies clientID={clientID} />
              )}

              {category === CLIENT_FORM_TYPES.REENTRY_DOCUMENTS && (
                <ReEntryDocuments
                  clientID={clientID}
                  data={reEntryDocuments}
                  fetching={reEntryDocumentsFetching}
                  getClientDocumentsInformation={
                    this.props.getClientDocumentsInformation
                  }
                  postClientDocumentsInformation={
                    this.props.postClientDocumentsInformation
                  }
                  deleteClientDocumentsInformation={
                    this.props.deleteClientDocumentsInformation
                  }
                  read={isReadreEntryDocumentsEnabled}
                  write={isWritereEntryDocumentsEnabled}
                />
              )}

              {category === CLIENT_FORM_TYPES.REENTRY_NOTES && (
                <ReEntryNotes
                  clientID={clientID}
                  data={reEntryVisitorNotes}
                  getNotesFetching={reEntryVisitorNotesFetching}
                  getClientVisitorNotesInformation={
                    this.props.getClientVisitorNotesInformation
                  }
                  postClientVisitorNotesInformation={
                    this.props.postClientVisitorNotesInformation
                  }
                  updateClientVisitorNotesInformation={
                    this.props.updateClientVisitorNotesInformation
                  }
                  deleteClientVisitorNotesInformation={
                    this.props.deleteClientVisitorNotesInformation
                  }
                  read={isReadreEntryVisitorNotesEnabled}
                  write={isWritereEntryVisitorNotesEnabled}
                />
              )}

              {(category === CLIENT_FORM_TYPES.BFET ||
                category === CLIENT_FORM_TYPES.CLIENT_BFET_INTAKE) &&
                !getBfetFetching &&
                this.renderLoading()}
              {(category === CLIENT_FORM_TYPES.BFET ||
                category === CLIENT_FORM_TYPES.CLIENT_BFET_INTAKE) &&
                getBfetFetching && (
                  <BFETIntake clientID={clientID} bfet={bfet} goTo={goTo} />
                )}

              {category === CLIENT_FORM_TYPES.CLIENT_BFET_JOB_SEARCH &&
                !getBfetJobSearchFetching &&
                !getBfetJobSearchEmployersFetching &&
                this.renderLoading()}
              {category === CLIENT_FORM_TYPES.CLIENT_BFET_JOB_SEARCH &&
                getBfetJobSearchFetching &&
                getBfetJobSearchEmployersFetching && (
                  <BFETJobSearchLog
                    clientID={clientID}
                    getBfetJobSearchFetching={getBfetJobSearchFetching}
                    data={bfetJobSearch}
                    getBfetJobSearchEmployersFetching={
                      getBfetJobSearchEmployersFetching
                    }
                    bfetJobSearchEmployers={bfetJobSearchEmployers}
                    updateClientBfetJobSearchInformation={
                      updateClientBfetJobSearchInformation
                    }
                    postClientBfetJobSearchEmployerInformation={
                      postClientBfetJobSearchEmployerInformation
                    }
                    updateClientBfetJobSearchEmployerInformation={
                      updateClientBfetJobSearchEmployerInformation
                    }
                    deleteClientBfetJobSearchEmployerInformation={
                      deleteClientBfetJobSearchEmployerInformation
                    }
                    read={isReadBfetJobSearchEnabled}
                    write={isWriteBfetJobSearchEnabled}
                  />
                )}

              { category === CLIENT_FORM_TYPES.CLIENT_BFET_SUPPLIES && (
                <BFETSupplies clientID={clientID} />
              )}

              {category === CLIENT_FORM_TYPES.CLIENT_BFET_DOCUMENTS && (
                <BFETDocuments
                  clientID={clientID}
                  data={bfetDocuments}
                  fetching={bfetDocumentsFetching}
                  getClientDocumentsInformation={
                    this.props.getClientDocumentsInformation
                  }
                  postClientDocumentsInformation={
                    this.props.postClientDocumentsInformation
                  }
                  deleteClientDocumentsInformation={
                    this.props.deleteClientDocumentsInformation
                  }
                  read={isReadBfetDocumentsEnabled}
                  write={isWriteBfetDocumentsEnabled}
                />
              )}

              {category === CLIENT_FORM_TYPES.CLIENT_BFET_NOTES && (
                <BFETNotes
                  clientID={clientID}
                  data={bfetVisitorNotes}
                  getNotesFetching={bfetVisitorNotesFetching}
                  getClientVisitorNotesInformation={
                    this.props.getClientVisitorNotesInformation
                  }
                  postClientVisitorNotesInformation={
                    this.props.postClientVisitorNotesInformation
                  }
                  updateClientVisitorNotesInformation={
                    this.props.updateClientVisitorNotesInformation
                  }
                  deleteClientVisitorNotesInformation={
                    this.props.deleteClientVisitorNotesInformation
                  }
                  read={isReadBfetNotesEnabled}
                  write={isWriteBfetNotesEnabled}
                />
              )}

              {(category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH ||
                category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_PH) &&
                !getMentalFetching &&
                this.renderLoading()}
              {(category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH ||
                category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_PH) &&
                getMentalFetching && (
                  <MentalHealthPersonalHistory
                    client={client}
                    clientID={clientID}
                    mental={mental}
                    goTo={goTo}
                  />
                )}

              { category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_SUPPLIES && (
                <MentalHealthSupplies clientID={clientID} />
              )}

              {category ===
                CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_DOCUMENTS && (
                <MentalHealthDocuments
                  clientID={clientID}
                  data={mentalDocuments}
                  fetching={mentalDocumentsFetching}
                  getClientDocumentsInformation={
                    this.props.getClientDocumentsInformation
                  }
                  postClientDocumentsInformation={
                    this.props.postClientDocumentsInformation
                  }
                  deleteClientDocumentsInformation={
                    this.props.deleteClientDocumentsInformation
                  }
                  write={isWriteMentalDocumentsEnabled}
                  read={isReadMentalDocumentsEnabled}
                />
              )}

              {category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_NOTES && (
                <MentalHealthNotes
                  clientID={clientID}
                  data={mentalVisitorNotes}
                  getNotesFetching={mentalVisitorNotesFetching}
                  getClientVisitorNotesInformation={
                    this.props.getClientVisitorNotesInformation
                  }
                  postClientVisitorNotesInformation={
                    this.props.postClientVisitorNotesInformation
                  }
                  updateClientVisitorNotesInformation={
                    this.props.updateClientVisitorNotesInformation
                  }
                  deleteClientVisitorNotesInformation={
                    this.props.deleteClientVisitorNotesInformation
                  }
                  write={isWriteMentalVisitorNotesEnabled}
                  read={isReadMentalVisitorNotesEnabled}
                />
              )}

             
              { category === CLIENT_FORM_TYPES.CLIENT_DVR_SERVICE_DELIVERY && (
                <DVRServiceDelivery clientID={clientID} />
              )}

              {category === CLIENT_FORM_TYPES.CLIENT_DVR_VOCATIONAL && (
                <DVRVocational clientID={clientID} />
              )}

              { category === CLIENT_FORM_TYPES.CLIENT_DVR_SUPPLIES && (
                <DVRSupplies clientID={clientID} />
              )}


                {category === CLIENT_FORM_TYPES.CLIENT_DVR_DOCUMENTS && (
                <DVRDocuments
                  clientID={clientID}
                  data={dvrDocuments}
                  fetching={DVRDocumentsFetching}
                  getClientDocumentsInformation={
                    this.props.getClientDocumentsInformation
                  }
                  postClientDocumentsInformation={
                    this.props.postClientDocumentsInformation
                  }
                  deleteClientDocumentsInformation={
                    this.props.deleteClientDocumentsInformation
                  }
                  write={isWriteDVRDocumentsEnabled}
                  read={isReadDVRDocumentsEnabled}
                />
              )}

              {category === CLIENT_FORM_TYPES.CLIENT_DVR_NOTES && (
                <DVRNotes
                  clientID={clientID}
                  data={DVRVisitorNotes}
                  getNotesFetching={DVRVisitorNotesFetching}
                  getClientVisitorNotesInformation={
                    this.props.getClientVisitorNotesInformation
                  }
                  postClientVisitorNotesInformation={
                    this.props.postClientVisitorNotesInformation
                  }
                  updateClientVisitorNotesInformation={
                    this.props.updateClientVisitorNotesInformation
                  }
                  deleteClientVisitorNotesInformation={
                    this.props.deleteClientVisitorNotesInformation
                  }
                  write={isWriteDVRNotesEnabled}
                  read={isReadDVRNotesEnabled}
                />
              )}

              {category === CLIENT_FORM_TYPES.VISITOR_NOTES && (
                <VisitorNotes
                  clientID={clientID}
                  data={defaultVisitorNotes}
                  getNotesFetching={defaultVisitorNotesFetching}
                  getClientVisitorNotesInformation={
                    this.props.getClientVisitorNotesInformation
                  }
                  postClientVisitorNotesInformation={
                    this.props.postClientVisitorNotesInformation
                  }
                  updateClientVisitorNotesInformation={
                    this.props.updateClientVisitorNotesInformation
                  }
                  deleteClientVisitorNotesInformation={
                    this.props.deleteClientVisitorNotesInformation
                  }
                />
              )}

              {category === CLIENT_FORM_TYPES.DOCUMENTS && (
                <Documents
                  clientID={clientID}
                  data={defaultDocuments}
                  fetching={defaultDocumentsFetching}
                  getClientDocumentsInformation={
                    this.props.getClientDocumentsInformation
                  }
                  postClientDocumentsInformation={
                    this.props.postClientDocumentsInformation
                  }
                  deleteClientDocumentsInformation={
                    this.props.deleteClientDocumentsInformation
                  }
                  write={isWriteDefaultDocumentsEnabled}
                  read={isReadDefaultDocumentsEnabled}
                />
              )}

              { category === CLIENT_FORM_TYPES.SUPPLIES && (
                <Supplies clientID={clientID} />
              )}

            </Col>
          </Row>
        </Col>
        { showEditClientModal && <EditClientModal client={client} onClose={this.onCloseEdiClienttModal} updateClient={this.props.updateClient} updatingClient={this.props.updatingClient}/> }
      </Layout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    client: state.client.client,
    getReentryFetching: state.client.getReentryFetching,
    getBfetFetching: state.client.getBfetFetching,

    getBfetJobSearchFetching: state.client.getBfetJobSearchFetching,
    getBfetJobSearchEmployersFetching:
      state.client.getBfetJobSearchEmployersFetching,
    bfetJobSearch: state.client.bfetJobSearch,
    bfetJobSearchEmployers: state.client.bfetJobSearchEmployers,

    getMentalFetching: state.client.getMentalFetching,
    getConsentFetching: state.client.getConsentFetching,
    reentry: state.client.reentry,
    bfet: state.client.bfet,
    mental: state.client.mental,
    consent: state.client.consent,

    isWriteReEntryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY
    ),
    isReadReEntryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY
    ),

    isWriteBfetEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET
    ),
    isReadBfetEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET
    ),

    isWriteMHEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH
    ),
    isReadMHEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH
    ),

    isWriteDVREnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR
    ),
    isReadDVREnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR
    ),

    
    isWriteDefaultNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_NOTES
    ),
    isReadDefaultNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_NOTES
    ),

    isWriteDefaultSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_SUPPLIES
    ),
    isReadDefaultSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_SUPPLIES
    ),

    isWriteConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_CONSENT
    ),
    isReadConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_CONSENT
    ),
    isWritereEntryVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_NOTES
    ),
    isReadreEntryVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_NOTES
    ),
    isWritereEntryDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_DOCUMENTS
    ),
    isReadreEntryDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_DOCUMENTS
    ),
    isWriteBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_JOB_SEARCH_INFO
    ),
    isReadBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_JOB_SEARCH_INFO
    ),
    isWriteBfetNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_NOTES
    ),
    isReadBfetNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_NOTES
    ),
    isWriteBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_DOCUMENTS
    ),
    isReadBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_DOCUMENTS
    ),

    isWriteMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_NOTES
    ),
    isReadMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_NOTES
    ),
    isWriteMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DOCUMENTS
    ),
    isReadMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DOCUMENTS
    ),
    
    isWriteDVRDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_DOCUMENTS
    ),
    isReadDVRDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_DOCUMENTS
    ),
    isWriteDVRNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_NOTES
    ),
    isReadDVRNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_NOTES
    ),
    isWriteDefaultDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_DOCUMENTS
    ),
    isReadDefaultDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_DOCUMENTS
    ),

    defaultVisitorNotesFetching: state.client.notesFetching.DEFAULT,
    reEntryVisitorNotesFetching: state.client.notesFetching.REENTRY,
    bfetVisitorNotesFetching: state.client.notesFetching.BFET,
    mentalVisitorNotesFetching: state.client.notesFetching.MENTALHEALTH,
    DVRVisitorNotesFetching: state.client.notesFetching.DVR,

    defaultVisitorNotes: state.client.notes.DEFAULT,
    reEntryVisitorNotes: state.client.notes.REENTRY,
    bfetVisitorNotes: state.client.notes.BFET,
    mentalVisitorNotes: state.client.notes.MENTALHEALTH,
    DVRVisitorNotes: state.client.notes.DVR,

    reEntryDocumentsFetching: state.client.documentsFetching.REENTRY,
    bfetDocumentsFetching: state.client.documentsFetching.BFET,
    mentalDocumentsFetching: state.client.documentsFetching.MENTALHEALTH,
    DVRDocumentsFetching: state.client.documentsFetching.DVR,

    reEntryDocuments: state.client.documents.REENTRY,
    bfetDocuments: state.client.documents.BFET,
    mentalDocuments: state.client.documents.MENTALHEALTH,
    dvrDocuments: state.client.documents.DVR,

    defaultDocuments: state.client.documents.DEFAULT,
    defaultDocumentsFetching: state.client.documentsFetching.DEFAULT,

    postingClientPicture: state.client.postingClientPicture,
    isUserToken: (state.user.tokenType === TOKEN_TYPE.ACCOUNT_USER_TOKEN),
    updatingClient: state.client.updatingClient

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPatient: (user) => dispatch(UserActions.createPatient(user)),
    updateClient: (id, params) => dispatch(ClientActions.updateClient(id, params)),
    getClient: (id) => dispatch(ClientActions.getClient(id)),
    getClientReentryIntakeInformation: (id) =>
      dispatch(ClientActions.getClientReentryIntakeInformation(id)),
    getClientBfetInformation: (id) =>
      dispatch(ClientActions.getClientBfetInformation(id)),
    getClientMentalInformation: (id) =>
      dispatch(ClientActions.getClientMentalInformation(id)),
    getClientConsentInformation: (id) =>
      dispatch(ClientActions.getClientConsentInformation(id)),
    getClientVisitorNotesInformation: (id, category) =>
      dispatch(ClientActions.getClientVisitorNotesInformation(id, category)),
    postClientVisitorNotesInformation: (id, category, params) =>
      dispatch(
        ClientActions.postClientVisitorNotesInformation(id, category, params)
      ),
    updateClientVisitorNotesInformation: (id, noteId, category, params) =>
      dispatch(
        ClientActions.updateClientVisitorNotesInformation(
          id,
          noteId,
          category,
          params
        )
      ),

    getClientDocumentsInformation: (id, category) =>
      dispatch(ClientActions.getClientDocumentsInformation(id, category)),
    postClientDocumentsInformation: (id, category, params) =>
      dispatch(
        ClientActions.postClientDocumentsInformation(id, category, params)
      ),
    deleteClientDocumentsInformation: (id, docId, category) =>
      dispatch(
        ClientActions.deleteClientDocumentsInformation(id, docId, category)
      ),

    updateClientReentryConsentInformation: (id, params) =>
      dispatch(ClientActions.updateClientReentryConsentInformation(id, params)),

    postClientPicture: (id, params) =>
      dispatch(ClientActions.postClientPicture(id, params)),
    getClientBfetJobSearchInformation: (id) =>
      dispatch(ClientActions.getClientBfetJobSearchInformation(id)),
    getClientBfetJobSearchEmployersInformation: (id) =>
      dispatch(ClientActions.getClientBfetJobSearchEmployersInformation(id)),

    updateClientBfetJobSearchInformation: (id, params) =>
      dispatch(ClientActions.updateClientBfetJobSearchInformation(id, params)),
    postClientBfetJobSearchEmployerInformation: (id, params) =>
      dispatch(
        ClientActions.postClientBfetJobSearchEmployerInformation(id, params)
      ),
    updateClientBfetJobSearchEmployerInformation: (id, employerId, params) =>
      dispatch(
        ClientActions.updateClientBfetJobSearchEmployerInformation(
          id,
          employerId,
          params
        )
      ),
    deleteClientBfetJobSearchEmployerInformation: (id, employerId) =>
      dispatch(
        ClientActions.deleteClientBfetJobSearchEmployerInformation(
          id,
          employerId
        )
      ),

    deleteClientVisitorNotesInformation: (id, noteId, category) => dispatch(ClientActions.deleteClientVisitorNotesInformation(id, noteId, category)),
    deleteClientPicture: (id) => dispatch(ClientActions.deleteClientPicture(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPatient);
