import React from 'react';
import { Select } from 'components/Fields/Select';
import { TextInput } from "components/Fields/TextInput";
import { Row, Col } from 'react-bootstrap';

export default class NestedNumericUI extends React.Component {
  render () {
    const { values, allowedTypesMap, onOperatorChange, index, ind } = this.props
    return (
      <Row className='mt-2 w-100'>
        <Col>
          <Select
            name={`includedFilters.filters.${index}.filters.${ind}.operator`}
            className="custom-select mt-2"
            onChange={(event) => onOperatorChange(event.target.value)}
          >
            <option>Select</option>
            {
              Object.entries(allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType]).map(([key, value]) => {
                return <option key={key} value={key}>{value.displayName}</option>
              })
            }
          </Select>
        </Col>


        { values && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'NUMERIC' && values.includedFilters.filters[index].filters[ind].operator && (values.includedFilters.filters[index].filters[ind].operator === 'IN_THE_FOLLOWING' || values.includedFilters.filters[index].filters[ind].operator === 'NOT_IN_THE_FOLLOWING') && (
          <Col>
            <TextInput
              className='mt-2'
              name={`includedFilters.filters.${index}.filters.${ind}.value`}
              type='number'
            />
          </Col>
        )}

        { values && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'NUMERIC' && values.includedFilters.filters[index].filters[ind].operator && (values.includedFilters.filters[index].filters[ind].operator === 'IS_BETWEEN' || values.includedFilters.filters[index].filters[ind].operator === 'IS_NOT_BETWEEN') && (
          <Row>
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.filters.${ind}.value`}
                type='number'
              />
            </Col>
            <span className='mt-3'> and </span>
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.filters.${ind}.value1`}
                type='number'
              />
            </Col>
          </Row>
        )}

        { values && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'NUMERIC' && values.includedFilters.filters[index].filters[ind].operator && (values.includedFilters.filters[index].filters[ind].operator === 'IS_EQUAL_TO' || values.includedFilters.filters[index].filters[ind].operator === 'IS_NOT_EQUAL_TO' || values.includedFilters.filters[index].filters[ind].operator === 'IS_LESS_THAN' || values.includedFilters.filters[index].filters[ind].operator === 'IS_GREATER_THAN') && (
          <Col>
            <TextInput
              className='mt-2'
              name={`includedFilters.filters.${index}.filters.${ind}.value`}
              type='number'
            />
          </Col>
        )}

      </Row>
    )
  }
}