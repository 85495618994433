import React from 'react';
import { ReactComponent as EditIcon } from '../../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/svg/ic_delete.svg';
import { PTr, PTd, IconButton } from '../Styles/PatientListStyles';

export default class User extends React.Component {
  onDelete = () => {
    this.props.onDelete(this.props.account.accountUserId)
  }

  onEdit = (e) => {
    e.preventDefault()
    this.props.onEdit(this.props.account.accountUserId)
  }

  getRoleNames = (accountRoles) => {
    const { roles } = this.props
    return accountRoles.map((ar) => {
      const r = roles.find((r) => r.accountRoleId === ar)
      return r && r.roleName
    }).join(', ')
  }

  render () {
    const { account, showActionsControl, isEditUserEnabled, isDeleteUserEnabled } = this.props
    return (
      <PTr>
          <PTd>{account.email}</PTd>
          <PTd>{account.firstName}</PTd>
          <PTd>{account.lastName}</PTd>
          <PTd>{this.getRoleNames(account.roles)}</PTd>
          { showActionsControl &&
            <PTd>
              <div className='d-flex'>
                { isEditUserEnabled && <IconButton className='mr-2'><EditIcon onClick={this.onEdit} /></IconButton> }
                { isDeleteUserEnabled && <IconButton><DeleteIcon onClick={this.onDelete}/></IconButton> }
              </div>
            </PTd>
         }
      </PTr>
    )
  }
}