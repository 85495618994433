import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import { humanFormat } from "utils/genericUtils";
import ReactToPrint from 'react-to-print';

export default class DeclarationAndSignature extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Declaration and Signature:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
<Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable">
<Row className="pb-2 w-100">
  <Col> BFET &gt; Declaration and Signature:</Col>
  {write && (
    <Col className="d-flex justify-content-end">
      <Link to={link}>Edit</Link>
    </Col>
  )}
  <ReactToPrint
    // eslint-disable-next-line
    trigger={() => <a href="javascript:void();">Print</a>}
    content={() => this.componentRef}
  />
</Row>
        <div>
          <Col className="p-0 col-12">
          <LightGrayLabel>

            <p>
              The following are the requirements to participate in the Basic
              Food Employment & Training (BFET) program:
            </p>
            <ul>
              <li>Receive Basic Food Assistance from DSHS;</li>
              <li>
                Not receiving Temporary Assistance for Needy Families (TANF),
                Refugee Cash Assistance (RCA) or other cash assistance under
                Title IV such as Tribal TANF Be able to work at least 20 hours
                per week;
              </li>
              <li>
                Cooperate with the requirements of this Individual Employment
                Plan; and
              </li>
              <li>Meet with your BFET case manager at least monthly.</li>
            </ul>
            </LightGrayLabel>

          </Col>
          <Col className="p-0 col-12">
            <p>
              I, <label className="input-label">{data.name}</label> have read the requirements and agree to abide by
              them.
            </p>
          </Col>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                I understand this form and the contents have been explained to
                me in my primary language.
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{humanFormat(data.hasUnderstoodForm)}</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-12 row-cols-md-2 align-items-end">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Client Signature</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.clientSignature}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.clientSignatureDate}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Case Manager Signature</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.clientManagerSignature}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.clientManagerSignatureDate}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Interpreter Signature (required if client cannot understand this form in English)</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.interpreterSignature}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.interpreterSignatureDate}</label>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    );
  }
}
