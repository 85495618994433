

import logo from './axion.jpeg'
import avatar from './profilePicture.jpeg'
import dashboardIcon from './dashboard-icon.svg'

const Images = {
  logo,
  avatar,
  dashboardIcon
}

export default Images