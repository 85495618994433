import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class AdditionalInfo extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Additional Info:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Additional Info:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Please Use This Page For Any Additional Information:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {data.additionalInformation}
              </label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Clinician Name And Credentials</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.clinicianName}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-6 p-0">
              <Col className="col-12">
                <LightGrayLabel>Clinician’s Signature</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.clinicianSignature}</label>
              </Col>
            </Col>
            <Col className="col-6 p-0">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {data.clinicianSignatureDate}
                </label>
              </Col>
            </Col>
            <Col className="col-6 p-0">
              <Col className="col-12">
                <LightGrayLabel>Participant’s Signature</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {data.participantSignature}
                </label>
              </Col>
            </Col>
            <Col className="col-6 p-0">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {data.participantSignatureDate}
                </label>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="p-0 d-flex flex-column align-items-center">
              <LightGrayLabel>
                All Information Gathered During This Assessment Process Will Be
                Used Collaboratively To Write The Report Summary.
              </LightGrayLabel>
              <LightGrayLabel>
                Unprofessional Conduct Rcw 18.30.180 To Report, Contact DOH
                Customer Service At (360) 236-4700
              </LightGrayLabel>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
