import React from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { TextMessage } from 'components/Fields/TextMessage'
import { RadioButton } from 'components/Fields/RadioButton'
import BaseForm from 'components/Patient/BaseForm'
import { FormHeader, LightGrayLabel } from '../../../../styles/GenericStyles'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class SocialRelationships extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.socialRelationships)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{...data}}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='mr-1 row'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className='w-100'>
                  <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    <Col className='col-md-12 col-lg-12 col-12 p-0'>
                      <label className='input-label mb-3'>Please select all that apply:</label>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isAffectionate'>Affectionate</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isAggressive'>Aggressive</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isAvoidant'>Avoidant</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isLeader'>Leader</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isFollower'>Follower</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isFriendly'>Friendly</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='willFightOrArgueOften'>Fight/Argue often</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isOutgoing'>Outgoing</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isShyOrWithdrawn'>Shy/withdrawn</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='isSubmissive'>Submissive</Checkbox>
                    </Col>
                    <Col className='mb-3 col-xs-12 col-md-8 col-lg-8 d-flex align-items-center'>
                      <Checkbox labelClassName='text-wrap' name='isOther'>Other</Checkbox>
                      <TextInput
                        name='other'
                        placeholder='Other (specify)'
                        className='d-inline mb-0'
                      />
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='sexualDysfunction'>Sexual dysfunction:</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasSexualDysfunction' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe'
                        name='sexualDysfunctionDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='sexualPerpetrator'>Any current/history of being a sexual perpetrator?</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='isSexualPerpetrator' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe'
                        name='sexualPerpetratorDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <FormHeader>CULTURAL/ETHNIC</FormHeader>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='culturalIssues'>
                        Are you experiencing any problems due to cultural or ethnic issues?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasCulturalOrEthnicIssues' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe'
                        name='culturalOrEthnicIssuesDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='Other Cultural/Ethnic Information:'
                        name='otherCulturalOrEthnicInformation'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}