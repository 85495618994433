import { useField } from 'formik';
import { ErrorMsg, Label } from 'styles/GenericStyles'

export const Checkbox = ({ children, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div>
      <Label className={props.labelClassName || ''}>
        <input type="checkbox" {...field} {...props} className={ meta.touched && meta.error ? 'is-invalid mr-2' : 'mr-2' } />
        {children}
      </Label>
      { meta.touched && meta.error ? (
        <ErrorMsg className="error">{meta.error}</ErrorMsg>
      ) : null }
    </div>
  )
}

