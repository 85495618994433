import * as R from 'ramda'
import Immutable from 'seamless-immutable'
const isImmutable = R.has('asMutable')

const convertToJs = (state) => state.asMutable({ deep: true })
const fromImmutable = R.when(isImmutable, convertToJs)
const toImmutable = (r) => Immutable(r)

const t = {
  out: (state) => {
    state.mergeDeep = R.identity
    return toImmutable(state)
  },
  in: (r) => {
    return fromImmutable(r)
  }
}

export default t