import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class LeisureAndRecreational extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Leisure And Recreational:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Leisure And Recreational:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="mb-3">
            <Col className="p-0 ml-3">
            <LightGrayLabel>

                Describe special areas of interest or hobbies (e.g., art, books,
                crafts, physical fitness, sports, outdoor activities, church
                activities, walking, diet/health, hunting, fishing, bowling,
                travel, gardening, etc.)
                </LightGrayLabel>

            </Col>
          </Row>

          <Row>
            <Table className="w-100 pl-0 ml-0" borderless responsive>
              <thead>
                <th className="align-top" style={{ width: "30%" }}>
                  Activity
                </th>
                <th className="align-top" style={{ width: "30%" }}>
                  How often?
                </th>
                <th className="align-top" style={{ width: "30%" }}>
                  How often in the past?
                </th>
              </thead>
              <tbody>
                {data &&
                  data.leisures &&
                  data.leisures.map((leisure) => (
                    <tr>
                      <td className="pl-0 py-0">
                        <Col className="col-12">
                          <label>{leisure.activity}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label>{leisure.howOften}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label>{leisure.howOftenInPast}</label>
                        </Col>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
        </div>
      </Row>
    );
  }
}
