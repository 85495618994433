import React from 'react';
import { Row, Spinner, Col } from 'react-bootstrap';

export function LoadingView(props) {
  return (
    <Row className='m-5'>
      <Col className="d-flex justify-content-center" >
        <Spinner animation="border" size={'xl'} id="spinner" />
      </Col>
    </Row>
  );
}

export default LoadingView;