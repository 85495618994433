import React from 'react';
import { Select } from 'components/Fields/Select';
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from 'components/Fields/Checkbox';
import { Row, Col } from 'react-bootstrap';

export default class NestedArrayStringUI extends React.Component {
  render () {
    const { values, allowedTypesMap, onOperatorChange, index, ind } = this.props
    return (
      <Row className='mt-2 w-100'>
        <Col>
          <Select
            name={`includedFilters.filters.${index}.filters.${ind}.operator`}
            className="custom-select mt-2"
            onChange={(event) => onOperatorChange(event.target.value)}
          >
            <option>Select</option>
            {
              Object.entries(allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType]).map(([key, value]) => {
                return <option key={key} value={key}>{value.displayName}</option>
              })
            }
          </Select>
        </Col>

        { values && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'ARRAY_STRING' 
            && values.includedFilters.filters[index].filters[ind].operator && (values.includedFilters.filters[index].filters[ind].operator !== 'EXISTS' && values.includedFilters.filters[index].filters[ind].operator !== 'DOES_NOT_EXIST') && (
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.filters.${ind}.value`}
                type='text'
              />
            </Col>
        )}

        <Col>
          <Checkbox labelClassName='text-wrap mt-3' name={`includedFilters.filters.${index}.filters.${ind}.caseSensitive`} disabled={values.includedFilters.filters[index].filters[ind].operator === 'DOES_NOT_EXIST' || values.includedFilters.filters[index].filters[ind].operator === 'EXISTS'}> Case Sensitive </Checkbox>
        </Col>
      </Row>
    )
  }
}