import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { TextMessage } from 'components/Fields/TextMessage'
import { Row, Col } from 'react-bootstrap'
import { TextInput } from 'components/Fields/TextInput';
import { FormHeader, LightGrayLabel } from 'styles/GenericStyles';
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class Legal extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const charges = values && values.charges && values.charges.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {
      ...values,
      charges,
      clientId: this.props.clientID
    }, CLIENT_MENTAL_FORM_TYPES.legal)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}

        render={({ values, touched, dirty, errors, handleBlur, handleChange, setFieldValue }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row className='align-items-center mb-3'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='areYouUnderOrder'>
                        WAC 246-341-0640: Are you under a civil or court ordered mental health or substance use treatment order?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasCourtOrderedSubstanceUse' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2'>
                      If Yes, Please Provide A Copy Of The Court Order For Your File.
                      </label>
                    </Col>
                    <Col className='col-10 p-0 d-flex align-items-center'>
                      { values.treatmentCourtOrderDownloadURL &&
                        <a
                          href={values.treatmentCourtOrderDownloadURL}
                          rel='noreferrer'
                          target="_blank"
                          alt='Image'>Open</a>
                      }

                      <div className="custom-file ml-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          name='file'
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                          onChange={(event) => {
                            setFieldValue("file", event.target.files[0]);
                          }}
                        />
                        <label className="custom-file-label left-file-label">Choose file</label>
                      </div>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-3 mt-2'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='exemptingFromReporting'>
                        Do you have a court order exempting you from reporting requirements?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasCourtOrderFromExemptingFromReportingRequirements' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2'>
                        If Yes, Please Provide A Copy Of The Court Order For Your File.
                      </label>
                    </Col>


                    <Col className='col-10 p-0 d-flex align-items-center'>
                      { values.exemptionCourtOrderDownloadURL &&
                          <a
                            href={values.exemptionCourtOrderDownloadURL}
                            rel='noreferrer'
                            target="_blank"
                            alt='Image'>Open</a>
                        }

                        <div className="custom-file ml-3">
                          <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            className="custom-file-input"
                            name='file1'
                            onChange={(event) => {
                              setFieldValue("file1", event.target.files[0]);
                            }}
                          />
                          <label className="custom-file-label left-file-label">Choose file</label>
                        </div>
                    </Col>



                  </Row>
                  <Row className='align-items-center mb-3 mt-2'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='convictedOfSexCrime'>
                        Have you ever been convicted of a sex crime?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasConvictedSexCrime' options={yesOrNoOptions} onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4'>
                    <Col className='col-10 p-0'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Please Describe And Indicate The Court And Hearing/Trial Dates And Charges:'
                        name='convictedSexCrimeDescription'
                        placeholder='Describe'
                        rows={4}
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-3 mt-2'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='presentlyOnParole'>
                        Are you presently on probation or parole?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='onProbation' options={yesOrNoOptions} onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4 row-cols-2 row-cols-xs-1 row-cols-md-2 mx-n2 mx-md-n3 mx-lg-n4'>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, name of PO Officer:'
                        name='probationOfficerName'
                        placeholder='Name'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='Phone of PO Officer:'
                        name='probationOfficerPhonenumber'
                        placeholder='Phone'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        type='date'
                        labelClassName='input-label text-wrap mb-2'
                        label='When is your probation/parole completed?'
                        name='probationCompleteDate'
                        placeholder='Describe'/>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className='p-0'>
                      <FormHeader>
                        PAST HISTORY:
                      </FormHeader>
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col className='p-0 col-6 mb-3'>
                      <RadioButton
                        name='hasTrafficViolations'
                        labelClassName='d-block mb-2'
                        options={yesOrNoOptions}
                        label='Traffic Violations:'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className='p-0 col-6 mb-3'>
                      <RadioButton
                        name='hasDWIorDUI'
                        labelClassName='d-block mb-2'
                        options={yesOrNoOptions}
                        label='DWI/DUI etc.:'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className='p-0 col-6 mb-3'>
                      <RadioButton
                        name='hasCriminalInvolvement'
                        labelClassName='d-block mb-2'
                        options={yesOrNoOptions}
                        label='Criminal Involvement:'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className='p-0 col-6 mb-3'>
                      <RadioButton
                        name='hasCivilInvolvement'
                        labelClassName='d-block mb-2'
                        options={yesOrNoOptions}
                        label='Civil Involvement:'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0'>
                      If you responded Yes to any of the above, please fill in the following information:
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0'>
                      <FieldArray
                        name="charges"
                        render={(arrayHelpers) => (
                          <>
                            {
                              values.charges && values.charges.length > 0 ? (
                                values.charges.map((charge, index) => (
                                  <Col key={index} className='col-12 d-flex'>
                                    <TextInput className='mr-3' name={`charges.${index}.charges`} type='number' placeholder='Charges'/>
                                    <TextInput className='mr-3' name={`charges.${index}.date`} type='date' placeholder='mm/dd/yyyy'/>
                                    <TextInput className='mr-3' name={`charges.${index}.place`} placeholder='City Name'/>
                                    <TextInput className='mr-3' name={`charges.${index}.results`} placeholder='Eg., Convicted, Pending, Etc'/>
                                    <button type="button" className='btn btn-outline-danger ml-3 mr-3 mb-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                    { index === 0 && <button type="button" className='btn btn-outline-primary mb-3' onClick={() => arrayHelpers.insert(index, '')}> Add </button>}
                                  </Col>
                                ))
                              ) : (
                                <Col className='col-12 d-flex mb-3'>
                                  <button className='btn btn-outline-primary' onClick={() => arrayHelpers.push('')}> Add Charges</button>
                                </Col>
                              )
                            }
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='p-0'>
                      <FormHeader>
                        INCARCERATION/REENTRY
                      </FormHeader>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4 row-cols-2 row-cols-xs-1 row-cols-md-2 mx-n2 mx-md-n3 mx-lg-n4'>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='DOC No.:'
                        name='incarcerationDocNum'
                        placeholder='Number'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='Institution'
                        name='incarcerationInstitution'
                        placeholder='Institution'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='Unit'
                        name='incarcerationUnit'
                        placeholder='Unit'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='Length of Incarceration'
                        name='lengthOfIncarceration'
                        placeholder='Length'/>
                    </Col>
                    <Col className='p-0 px-2 px-sm-2 px-md-2 px-lg-3'>
                      <TextInput
                        labelClassName='input-label text-wrap mb-2'
                        label='Date Released'
                        type='date'
                        name='incarcerationReleaseDate'
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}