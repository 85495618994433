import React from 'react';
import {
    ROOT,
    CLIENTS,
    APPOINTMENTS,
    REPORTS,
    USERS,
    LOGIN,
    ROLES,
    TEMPLATES
} from 'navigation/CONSTANTS'
import { SideBarContainer, NavContainer, NavItem, Branding } from 'components/Styles/SideBarStyles'
import { Link } from 'react-router-dom'
import { ReactComponent as DashboardIcon } from '../images/svg/dashboard-icon.svg';
import { ReactComponent as PatientsIcon } from '../images/svg/patients.svg';
import { ReactComponent as AppointmentsIcon } from '../images/svg/appointment.svg';
import { ReactComponent as SettingsIcon } from '../images/svg/settings-4.svg';
import { ReactComponent as LogoutIcon } from '../images/svg/logout.svg';

function Siderbar(props) {
    return (
        <SideBarContainer id='sidebar'>
            <NavContainer style={{ marginTop: '64px' }}>
                
                
                    <Link to={ROOT}>
                        <NavItem className='pl-3'>
                            <DashboardIcon className='nav-icon' />
                            <span className='nav-title'>Dashboard</span>
                        </NavItem>
                    </Link>
                
                    <Link to={CLIENTS}>
                        <NavItem className='pl-3'>
                            <PatientsIcon className='nav-icon' />
                            <span className='nav-title'>Clients</span>
                        </NavItem>
                    </Link>

                    <Link to={APPOINTMENTS}>
                        <NavItem className='pl-3'>
                            <AppointmentsIcon className='nav-icon' />
                            <span className='nav-title'>Appointments</span>
                        </NavItem>
                    </Link>

                    <Link to={REPORTS}>
                        <NavItem className='pl-3'>
                            <AppointmentsIcon className='nav-icon' />
                            <span className='nav-title'>Report Playground</span>
                        </NavItem>
                    </Link>

                    <Link to={TEMPLATES}>
                        <NavItem className='pl-3'>
                            <AppointmentsIcon className='nav-icon' />
                            <span className='nav-title'>Report Templates</span>
                        </NavItem>
                    </Link>

            </NavContainer>
            <div className="my-auto"></div>

            <NavContainer className='mt-auto'>
                <Link to={ROLES}>
                    <NavItem className='pl-3'>
                        <SettingsIcon className='nav-icon' />
                        <span className='nav-title'>Roles</span>
                    </NavItem>
                </Link>

                <Link to={USERS}>
                    <NavItem className='pl-3'>
                        <SettingsIcon className='nav-icon' />
                        <span className='nav-title'>Users</span>
                    </NavItem>
                </Link>

                {/* <Link to={{ pathname: 'https://axion.com'}} target='_blank'>
                    <NavItem className='pl-3'>
                        <HelpCenterIcon className='nav-icon' />
                        <span className='nav-title'>Help Center</span>
                    </NavItem>
                </Link> */}
            
                <Link to={LOGIN}>
                    <NavItem href='#' className='pl-3' onClick={() => {props.logout()}}>
                        <LogoutIcon className='nav-icon' />
                        <span className='nav-title'>Logout</span>
                    </NavItem>
                </Link>

            </NavContainer>
            <Branding className='mt-6 pl-3'>
                <span>Product by</span>
                <p className="brand">AXION GALAXY 360</p>
            </Branding>
        </SideBarContainer>
    )
}

export default Siderbar