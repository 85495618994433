import { switchMap, filter, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs'
import ReportActions, { ReportTypes } from "redux/ReportRedux";
import AlertActions from 'redux/AlertRedux'

export const getSearchMetaDataEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_SEARCH_META_DATA),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getSearchMetaData(user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getSearchMetaDataSuccess(response.data.allowedTypesMap, response.data.filterMetaData))
          } else {
            return of(
              ReportActions.getSearchMetaDataFailure(),
              AlertActions.error(['Fetching Reports MetaData failed! Please try again.'])
            )
          }
        })
      )
    })
  )
  
export const getqueriesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_QUERIES),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getQueries(action.pageNumber, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getQueriesSuccess(action.pageNumber, response.data.queries, response.data.totalCount ))
          } else {
            return of(
              ReportActions.getQueriesFailure(),
              AlertActions.error(['Fetching Reports failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getqueryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_QUERY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getQuery(action.queryId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getQuerySuccess(response.data))
          } else {
            return of(
              ReportActions.getQueryFailure(),
              AlertActions.error(['Fetching query failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteQueryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.DELETE_QUERY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteQuery(action.queryId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getQueries(1))
          } else {
            return of(
              ReportActions.deleteQueryFailure(),
              AlertActions.error(['Deleting query failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createQueryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.CREATE_QUERY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createQuery(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ReportActions.getQueries(1),
              AlertActions.success(['Successfully created Query!']),
            )
          } else {
            return of(
              AlertActions.error(['Creating Query failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getJobEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_JOB),
    filter(() => store.value.user.accessToken),
    mergeMap((action) => {
      const { user } = store.value
      return from(api.getJob(action.queryJobId, user.accessToken)).pipe(
        mergeMap(response => {
          if (response.ok) {
            return of(ReportActions.getJobSuccess(response.data))
          } else {
            return of(
              ReportActions.getJobFailure(),
              AlertActions.error(['Fetching query job failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createReportTemplateEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.CREATE_REPORT_TEMPLATE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createReportTemplate(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ReportActions.createReportTemplateFailure(null),
              ReportActions.createReportTemplateSuccess(response.data),
              AlertActions.success(['Successfully created Query!']),
            )
          } else {
            return of(
              ReportActions.createReportTemplateFailure(response.data.error_message),
            )
          }
        })
      )
    })
  )
  
export const getReportTemplatesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_REPORT_TEMPLATES),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getReportTemplates(action.pageNumber, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getReportTemplatesSuccess(action.pageNumber, response.data.reportTemplates, response.data.totalCount ))
          } else {
            return of(
              ReportActions.getReportTemplatesFailure(),
              AlertActions.error(['Fetching Report templates failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createReportTemplateQueryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.CREATE_REPORT_TEMPLATE_QUERY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createReportTemplateQuery(action.templateId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AlertActions.success(['Successfully executed template query!']),
            )
          } else {
            return of(
              ReportActions.createReportTemplateQueryFailure(response.data.error_message),
            )
          }
        })
      )
    })
  )

export const deleteReportTemplateEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.DELETE_REPORT_TEMPLATE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteReportTemplate(action.templateId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ReportActions.getReportTemplates(1),
              AlertActions.success(['Successfully deleted template query!'])
            )
          } else {
            return of(
              ReportActions.deleteReportTemplateQueryFailure(),
              AlertActions.error(['Deleting query template failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteReportTemplateQueryEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.DELETE_REPORT_TEMPLATE_QUERY),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteReportTemplateQuery(action.templateId, action.queryId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ReportActions.getReportQueryTemplates(action.templateId, 1),
              AlertActions.success(['Successfully deleted template query!'])
            )
          } else {
            return of(
              ReportActions.deleteReportTemplateQueryFailure(),
              AlertActions.error(['Deleting query template failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updateReportTemplateEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.UPDATE_REPORT_TEMPLATE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateReportTemplate(action.templateId, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              ReportActions.updateReportTemplateSuccess(response.data),
              AlertActions.success(['Successfully updated template query!']),
            )
          } else {
            return of(
              ReportActions.updateReportTemplateFailure(response.data.error_message),
            )
          }
        })
      )
    })
  )

export const getReportQueryTemplatesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(ReportTypes.GET_REPORT_QUERY_TEMPLATES),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getReportQueryTemplates(action.templateId, action.pageNumber, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(ReportActions.getReportQueryTemplatesSuccess(action.pageNumber, response.data.reportQueries, response.data.totalCount ))
          } else {
            return of(
              ReportActions.getReportQueryTemplatesFailure(),
              AlertActions.error(['Fetching Report query templates failed! Please try again.'])
            )
          }
        })
      )
    })
  )