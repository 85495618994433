import React from 'react';
import Header from 'components/Header.jsx'
import Sidebar from 'components/Sidebar.jsx'
import { connect } from 'react-redux'
import AlertActions from 'redux/AlertRedux'
import { Col, Row, Container } from 'react-bootstrap';
import AuthActions from 'redux/AuthRedux'
import { ToastNotification } from 'components/ToastNotification'
import { TOKEN_TYPE } from 'redux/UserRedux'

export class Layout extends React.Component {
  
  render () {
    const { children, messages, type, onClear, isUserToken } = this.props
    return (
      <Container className='d-flex flex-column h-100 p-0' fluid>
        <ToastNotification
          messages={messages}
          type={type}
          onClear={onClear}
        />

        <Row className='mx-0'>
          <Col className='p-0'>
            <Header isUserToken={isUserToken} />
          </Col>
        </Row>
        <Row className='mx-0 flex-grow-1'>
          {
            isUserToken && <Col className='p-0' style={{ maxWidth: '220px'}}>
              <Sidebar logout={this.props.logout} />
            </Col>
          }
          <Col className='p-4' style={{ overflow: 'auto', marginTop: '64px' }}>
            { children }
          </Col>
        </Row>
      </Container>
    ) 
  }
}

const mapStateToProps = (state) => {  
  return {
    messages: state.alert.messages,
    type: state.alert.type,
    isUserToken: (state.user.tokenType === TOKEN_TYPE.ACCOUNT_USER_TOKEN)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClear: () => dispatch(AlertActions.clear()),
    logout: (email) => dispatch(AuthActions.logoutRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);