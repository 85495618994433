import React from "react";
import StringMask from "string-mask";
import { Form } from 'react-bootstrap';
import { Field } from "formik";

const DELIMITER = "-";
const MASK = "000-00-0000";

function removeTrailingCharIfFound(str, char) {
  return str
    .split(char)
    .filter(segment => segment !== "")
    .join(char);
}

function formatValue(str) {
  const unmaskedValue = str.split(DELIMITER).join("");
  const formatted = StringMask.process(unmaskedValue, MASK);
  return removeTrailingCharIfFound(formatted.result, DELIMITER);
}

export function SSNField(props) {
  return (
    <Form.Group className={`position-relative ${props.className ? props.className : null}`}>
      <Field name={props.name}>
        {fieldProps => (
          <input
            {...fieldProps.field}
            class='form-control'
            onChange={event => {
              fieldProps.field.onChange(event.target.name)(
                formatValue(event.target.value)
              );
            }}
          />
        )}
      </Field>
    </Form.Group>
  );
}