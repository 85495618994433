
import React, { useState } from 'react';
import {
  CLIENTS
} from 'navigation/CONSTANTS'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { CustomAccordion } from './Styles/PatientSidebarStyles';
import { CLIENT_FORM_TYPES } from 'redux/ClientRedux'
import { ReactComponent as DashboardBlackIcon } from 'images/svg/dashboard-icon-black.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrows/svg/right-arrow-black.svg';
import { ReactComponent as AppointmentsBlackIcon } from 'images/svg/appointment-black.svg';

function PatientSidebar (props) { 
    let defaultEventKey = '0';
    const { category,
        isWriteReEntryEnabled,
        isWriteBfetEnabled,
        isWriteMHEnabled,
        isWriteDVREnabled,
        isReadReEntryEnabled,
        isReadBfetEnabled,
        isReadMHEnabled,
        isReadDVREnabled,
        isWriteDefaultNotesEnabled,
        isWriteDefaultSuppliesEnabled,
        isWriteDefaultDocumentsEnabled,
        isReadDefaultNotesEnabled,
        isReadDefaultSuppliesEnabled,
        isReadDefaultDocumentsEnabled
    } = props;
    const catLowerCase = category ? category.toLowerCase() : '';

    if (catLowerCase === CLIENT_FORM_TYPES.REENTRY) {
        defaultEventKey = '0'
    } else if (catLowerCase === CLIENT_FORM_TYPES.REENTRY_CONSENT || catLowerCase === CLIENT_FORM_TYPES.REENTRY_DOCUMENTS || catLowerCase === CLIENT_FORM_TYPES.REENTRY_NOTES) {
        defaultEventKey = '1'
    } else if (catLowerCase.startsWith('bfet')) {
        defaultEventKey = '2'
    } else if (catLowerCase.startsWith('mentalhealth')) {
        defaultEventKey = '3'
    } else if (catLowerCase.startsWith('dvr')) {
        defaultEventKey = '4'
    } else if (catLowerCase.startsWith('visitornotes')) {
        defaultEventKey = '5'
    } else if (catLowerCase.startsWith('documents')) {
        defaultEventKey = '6'
    } else if (catLowerCase.startsWith('supplies')) {
        defaultEventKey = '7'
    }


    const [eventKey, setEventKey] = useState(defaultEventKey);
    
    const handleClickToggle = function (eventKey) {
        return function () {
            setEventKey(eventKey);
        }
    }
    
    const BASE_URL = `${CLIENTS}/${props.clientID}`
    
    return <CustomAccordion defaultActiveKey={defaultEventKey}>
        <Card>
            <CustomAccordion.Toggle onClick={handleClickToggle('0')} as={Card.Header} className={(props.category === CLIENT_FORM_TYPES.REENTRY) ? 'selected pl-0' : 'pl-0'} eventKey="0">
                <Link to={`${BASE_URL}/reentry/INDENTIFYING_INFORMATION`}>
                    <div className='d-flex align-items-center pl-3' >
                        <span className={`r-auto ml-2 ${(props.category === CLIENT_FORM_TYPES.REENTRY) ? 'selected' : ''}`}>Intake Form</span>
                    </div>
                </Link>
            </CustomAccordion.Toggle>
        </Card>

        {
            (isWriteReEntryEnabled || isReadReEntryEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('1')} as={Card.Header} className='pl-0' eventKey="1">
                        <Link to={`${BASE_URL}/reentry/consent`}>
                            <div className='d-flex align-items-center pl-3' >
                                <DashboardBlackIcon fill='#111111' className='nav-icon' />
                                <span className='mr-auto ml-2'>Re-entry</span>
                                <ArrowRightIcon className={`arrow-icon ${eventKey === '1' ? 'show' : ''}`} />
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                    <CustomAccordion.Collapse eventKey="1" className='mb-3'>
                        <Card.Body className='mb-1'>
                        
                            <Link className={props.category === CLIENT_FORM_TYPES.REENTRY_CONSENT ? 'selected' : ''} to={`${BASE_URL}/reentry/consent`}>
                                <div>
                                    <span className='nav-title'>Consent Form</span>
                                </div>
                            </Link>

                            {/* <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_REENTRY_SUPPLIES ? 'selected' : ''} to={`${BASE_URL}/reentry/supplies`}>
                                <div>
                                    <span className='nav-title'>Supplies</span>
                                </div>
                            </Link> */}

                            <Link className={props.category === CLIENT_FORM_TYPES.REENTRY_DOCUMENTS ? 'selected' : ''} to={`${BASE_URL}/reentry/documents`}>
                                <div>
                                    <span className='nav-title'>Upload Docs</span>
                                </div>
                            </Link>
                            <Link className={props.category === CLIENT_FORM_TYPES.REENTRY_NOTES ? 'selected' : ''} to={`${BASE_URL}/reentry/notes`}>
                                <div>
                                    <span className='nav-title'>Case Notes</span>
                                </div>
                            </Link>
                        </Card.Body>
                    </CustomAccordion.Collapse>
                </Card>)
        }

        {
            (isWriteBfetEnabled || isReadBfetEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('2')} as={Card.Header} className='pl-0' eventKey="2">
                        <Link to={`${BASE_URL}/bfet/EMPLOYMENT_GOALS`}>
                            <div className='d-flex align-items-center pl-3'>
                                <AppointmentsBlackIcon className='nav-icon' />
                                <span className='mr-auto ml-2'>BFET</span>
                                <ArrowRightIcon className={`arrow-icon ${eventKey === '2' ? 'show' : ''}`} />
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                    <CustomAccordion.Collapse eventKey="2">
                        <Card.Body className='mb-3'>
                            <Link className={props.category === CLIENT_FORM_TYPES.BFET || props.category === CLIENT_FORM_TYPES.CLIENT_BFET_INTAKE ? 'selected' : ''} to={`${BASE_URL}/bfet/EMPLOYMENT_GOALS`}>
                                <div>
                                    <span className='nav-title'>IEP</span>
                                </div>
                            </Link>

                            {/* <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_BFET_SUPPLIES ? 'selected' : ''} to={`${BASE_URL}/bfet/supplies`}>
                                <div>
                                    <span className='nav-title'>Supplies</span>
                                </div>
                            </Link> */}


                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_BFET_JOB_SEARCH ? 'selected' : ''} to={`${BASE_URL}/bfet/jobsearch`}>
                                <div>
                                    <span className='nav-title'>Job Search Log</span>
                                </div>
                            </Link>

                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_BFET_DOCUMENTS ? 'selected' : ''} to={`${BASE_URL}/bfet/documents`}>
                                <div>
                                    <span className='nav-title'>Upload Doc</span>
                                </div>
                            </Link>

                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_BFET_NOTES ? 'selected' : ''} to={`${BASE_URL}/bfet/notes`}>
                                <div>
                                    <span className='nav-title'>Case Notes</span>
                                </div>
                            </Link>
                        </Card.Body>
                    </CustomAccordion.Collapse>
                </Card>)
        }

        {
            (isWriteMHEnabled || isReadMHEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('3')} as={Card.Header} className='pl-0' eventKey="3">
                        <Link to={`${BASE_URL}/mentalhealth/DEMOGRAPHICS`}>
                            <div className='d-flex align-items-center pl-3'>
                                <AppointmentsBlackIcon className='nav-icon' />
                                <span className='mr-auto ml-2'>Mental Health</span>
                                <ArrowRightIcon className={`arrow-icon ${eventKey === '3' ? 'show' : ''}`} />
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                    <CustomAccordion.Collapse eventKey="3">
                        <Card.Body className='mb-3'>
                            <Link
                                to={`${BASE_URL}/mentalhealth/DEMOGRAPHICS`}
                                className={(props.category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH || props.category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_PH) ? 'selected' : ''}
                            >
                                <div>
                                    <span className='nav-title'>Personal History</span>
                                </div>
                            </Link>

                            {/* <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_SUPPLIES ? 'selected' : ''} to={`${BASE_URL}/mentalhealth/supplies`}>
                                <div>
                                    <span className='nav-title'>Supplies</span>
                                </div>
                            </Link> */}

                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_DOCUMENTS ? 'selected' : ''}k to={`${BASE_URL}/mentalhealth/documents`}>
                                <div>
                                    <span className='nav-title'>Upload Doc</span>
                                </div>
                            </Link>

                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_NOTES ? 'selected' : ''}k to={`${BASE_URL}/mentalhealth/notes`}>
                                <div>
                                    <span className='nav-title'>Case Notes</span>
                                </div>
                            </Link>
                        </Card.Body>
                    </CustomAccordion.Collapse>
                </Card>)
        }

        {
            (isWriteDVREnabled || isReadDVREnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('4')} as={Card.Header} className='pl-0' eventKey="4">
                        <Link to={`${BASE_URL}/dvr/servicedelivery`}>
                            <div className='d-flex align-items-center pl-3'>
                                <AppointmentsBlackIcon className='nav-icon' />
                                <span className='mr-auto ml-2'>DVR</span>
                                <ArrowRightIcon className={`arrow-icon ${eventKey === '4' ? 'show' : ''}`} />
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                    <CustomAccordion.Collapse eventKey="4">
                        <Card.Body className='mb-3'>
                            <Link
                                to={`${BASE_URL}/dvr/servicedelivery`}
                                className={props.category === CLIENT_FORM_TYPES.CLIENT_DVR_SERVICE_DELIVERY ? 'selected' : ''}
                            >
                                <div>
                                    <span className='nav-title'>Service Delivery</span>
                                </div>
                            </Link>

                            <Link
                                to={`${BASE_URL}/dvr/vocational`}
                                className={props.category === CLIENT_FORM_TYPES.CLIENT_DVR_VOCATIONAL ? 'selected' : ''}
                            >
                                <div>
                                    <span className='nav-title'>Vocational</span>
                                </div>
                            </Link>

                            {/* <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_DVR_SUPPLIES ? 'selected' : ''} to={`${BASE_URL}/dvr/supplies`}>
                                <div>
                                    <span className='nav-title'>Supplies</span>
                                </div>
                            </Link> */}


                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_DVR_DOCUMENTS ? 'selected' : ''}k to={`${BASE_URL}/dvr/documents`}>
                                <div>
                                    <span className='nav-title'>Upload Doc</span>
                                </div>
                            </Link>

                            <Link className={props.category === CLIENT_FORM_TYPES.CLIENT_DVR_NOTES ? 'selected' : ''}k to={`${BASE_URL}/dvr/notes`}>
                                <div>
                                    <span className='nav-title'>Case Notes</span>
                                </div>
                            </Link>
                        </Card.Body>
                    </CustomAccordion.Collapse>
                </Card>)
        }

        {
            (isWriteDefaultNotesEnabled || isReadDefaultNotesEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('5')} as={Card.Header} className={props.category === CLIENT_FORM_TYPES.VISITOR_NOTES ? 'selected pl-0' : 'pl-0'} eventKey="5">
                        <Link to={`${BASE_URL}/visitornotes`} >
                            <div className='d-flex align-items-center pl-3'>
                            <span className={`r-auto ml-2 ${(props.category === CLIENT_FORM_TYPES.VISITOR_NOTES) ? 'selected' : ''}`}>Case Notes</span>
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                </Card>)
        }

        {
            (isWriteDefaultDocumentsEnabled || isReadDefaultDocumentsEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('6')} as={Card.Header} className={props.category === CLIENT_FORM_TYPES.DOCUMENTS ? 'selected pl-0' : 'pl-0'} eventKey="6">
                        <Link to={`${BASE_URL}/documents`} >
                            <div className='d-flex align-items-center pl-3'>
                            <span className={`r-auto ml-2 ${(props.category === CLIENT_FORM_TYPES.DOCUMENTS) ? 'selected' : ''}`}>Previous Visit Documents</span>
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                </Card>)
        }

         {
            (isWriteDefaultSuppliesEnabled || isReadDefaultSuppliesEnabled) &&
                (<Card>
                    <CustomAccordion.Toggle onClick={handleClickToggle('7')} as={Card.Header} className={props.category === CLIENT_FORM_TYPES.SUPPLIES ? 'selected pl-0' : 'pl-0'} eventKey="7">
                        <Link to={`${BASE_URL}/supplies`} >
                            <div className='d-flex align-items-center pl-3'>
                            <span className={`r-auto ml-2 ${(props.category === CLIENT_FORM_TYPES.SUPPLIES) ? 'selected' : ''}`}>Inventory</span>
                            </div>
                        </Link>
                    </CustomAccordion.Toggle>
                </Card>)
        }

    </CustomAccordion>
}

export default PatientSidebar