import React from "react";
// Intake Forms
import IdentifyingInfo from "components/Patient/Reentry/Intake/View/IdentifyingInfo";
import Family from "components/Patient/Reentry/Intake/View/Family";
import Income from "components/Patient/Reentry/Intake/View/Income";
import Housing from "components/Patient/Reentry/Intake/View/Housing";
import PersonalHealthInformation from "components/Patient/Reentry/Intake/View/PersonalHealthInformation";
import LegalHistory from "components/Patient/Reentry/Intake/View/LegalHistory";
import Incarceration from "components/Patient/Reentry/Intake/View/Incarceration";
import Transportation from "components/Patient/Reentry/Intake/View/Transportation";
import ImmediateNeeds from "components/Patient/Reentry/Intake/View/ImmediateNeeds";
import EmploymentSearch from "components/Patient/Reentry/Intake/View/EmploymentSearch";
import SkillsAndAbility from "components/Patient/Reentry/Intake/View/SkillsAndAbility";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import LoadingView from "components/LoadingView.jsx";
import {
  CLIENT_REENTRY_INDENTIFYING_INFO,
  CLIENT_REENTRY_FAMILY,
  CLIENT_REENTRY_INCOME,
  CLIENT_REENTRY_HOUSING,
  CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION,
  CLIENT_REENTRY_LEGAL_HISTORY,
  CLIENT_REENTRY_INCARCERATION_REENTRY,
  CLIENT_REENTRY_TRANSPORTATION,
  CLIENT_REENTRY_IMMEDIATE_NEEDS,
  CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION,
  CLIENT_REENTRY_SKILLS_AND_ABILITIES,
} from "navigation/CONSTANTS";

export class PatientIntakeView extends React.Component {
  componentDidMount() {
    this.props.getClientReentryIntakeInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      employmentSearchInformation,
      family,
      housing,
      identifyingInformation,
      immediateNeeds,
      incarceration,
      income,
      legalHistory,
      personalHealthInformation,
      skillsAndAbilities,
      transportation,
    } = this.props.reentry;
    const {
      clientID,
      isWriteIdentifyingInformationEnabled,
      isReadIdentifyingInformationEnabled,
      isWriteIncomeEnabled,
      isReadIncomeEnabled,
      isWriteFamilyEnabled,
      isReadFamilyEnabled,
      isWriteHousingEnabled,
      isReadHousingEnabled,
      isReadPersonalHealthInformationEnabled,
      isWritePersonalHealthInformationEnabled,
      isReadLegalHistoryEnabled,
      isWriteLegalHistoryEnabled,
      isReadIncarcerationEnabled,
      isWriteIncarcerationEnabled,
      isReadTransportationEnabled,
      isWriteTransportationEnabled,
      isReadImmediateNeedsEnabled,
      isWriteImmediateNeedsEnabled,
      isReadEmploymentSearchEnabled,
      isWriteEmploymentSearchEnabled,
      isReadskillsAndAbilitiesEnabled,
      isWriteskillsAndAbilitiesEnabled,
    } = this.props;

    return (
      <>
        <IdentifyingInfo
          link={CLIENT_REENTRY_INDENTIFYING_INFO.replace(":id", clientID)}
          read={isReadIdentifyingInformationEnabled}
          write={isWriteIdentifyingInformationEnabled}
          data={identifyingInformation}
          client={this.props.client}
        />
        <Income
          link={CLIENT_REENTRY_INCOME.replace(":id", clientID)}
          data={income}
          read={isReadIncomeEnabled}
          write={isWriteIncomeEnabled}
        />
        <Family
          link={CLIENT_REENTRY_FAMILY.replace(":id", clientID)}
          data={family}
          read={isReadFamilyEnabled}
          write={isWriteFamilyEnabled}
        />
        <Housing
          link={CLIENT_REENTRY_HOUSING.replace(":id", clientID)}
          data={housing}
          read={isReadHousingEnabled}
          write={isWriteHousingEnabled}
        />
        <PersonalHealthInformation
          link={CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION.replace(
            ":id",
            clientID
          )}
          data={personalHealthInformation}
          read={isReadPersonalHealthInformationEnabled}
          write={isWritePersonalHealthInformationEnabled}
        />
        <LegalHistory
          link={CLIENT_REENTRY_LEGAL_HISTORY.replace(":id", clientID)}
          data={legalHistory}
          read={isReadLegalHistoryEnabled}
          write={isWriteLegalHistoryEnabled}
        />
        <Incarceration
          link={CLIENT_REENTRY_INCARCERATION_REENTRY.replace(":id", clientID)}
          data={incarceration}
          read={isReadIncarcerationEnabled}
          write={isWriteIncarcerationEnabled}
        />
        <Transportation
          link={CLIENT_REENTRY_TRANSPORTATION.replace(":id", clientID)}
          data={transportation}
          read={isReadTransportationEnabled}
          write={isWriteTransportationEnabled}
        />
        <ImmediateNeeds
          link={CLIENT_REENTRY_IMMEDIATE_NEEDS.replace(":id", clientID)}
          data={immediateNeeds}
          read={isReadImmediateNeedsEnabled}
          write={isWriteImmediateNeedsEnabled}
        />
        <EmploymentSearch
          link={CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION.replace(
            ":id",
            clientID
          )}
          data={employmentSearchInformation}
          read={isReadEmploymentSearchEnabled}
          write={isWriteEmploymentSearchEnabled}
        />
        <SkillsAndAbility
          link={CLIENT_REENTRY_SKILLS_AND_ABILITIES.replace(":id", clientID)}
          data={skillsAndAbilities}
          read={isReadskillsAndAbilitiesEnabled}
          write={isWriteskillsAndAbilitiesEnabled}
        />
      </>
    );
  }

  render() {
    const { getReentryFetching } = this.props;
    return (
      <>
        {!getReentryFetching && this.renderLoading()}
        {getReentryFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getReentryFetching: state.client.getReentryFetching,
    reentry: state.client.reentry,
    isWriteIdentifyingInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INDENTIFYING_INFORMATION
    ),
    isReadIdentifyingInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INDENTIFYING_INFORMATION
    ),

    isWriteIncomeEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INCOME
    ),
    isReadIncomeEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INCOME
    ),
    isWriteFamilyEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_FAMILY
    ),
    isReadFamilyEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_FAMILY
    ),
    isWriteHousingEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_HOUSING
    ),
    isReadHousingEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_HOUSING
    ),
    isWritePersonalHealthInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_PERSONAL_HEALTH_INFORMATION
    ),
    isReadPersonalHealthInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_PERSONAL_HEALTH_INFORMATION
    ),
    isWriteLegalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_LEGAL_HISTORY
    ),
    isReadLegalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_LEGAL_HISTORY
    ),
    isWriteIncarcerationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INCARCERATION_REENTRY
    ),
    isReadIncarcerationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INCARCERATION_REENTRY
    ),
    isWriteTransportationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_TRANSPORTATION
    ),
    isReadTransportationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_TRANSPORTATION
    ),
    isWriteImmediateNeedsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_IMMEDIATE_NEEDS
    ),
    isReadImmediateNeedsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_IMMEDIATE_NEEDS
    ),
    isWriteEmploymentSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_EMPLOYMENT_SEARCH_INFORMATION
    ),
    isReadEmploymentSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_EMPLOYMENT_SEARCH_INFORMATION
    ),
    isWriteskillsAndAbilitiesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_SKILLS_AND_ABILITIES
    ),
    isReadskillsAndAbilitiesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_SKILLS_AND_ABILITIES
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientReentryIntakeInformation: (id) =>
      dispatch(ClientActions.getClientReentryIntakeInformation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientIntakeView);
