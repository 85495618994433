import React from 'react';
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { ReactComponent as LeftArrow } from '../images/arrows/svg/left-arrow.svg';
import { ReactComponent as RightArrow } from '../images/arrows/svg/right-arrow.svg';
import { ReactComponent as LeftArrowDisabled } from '../images/arrows/svg/left-arrow-disable.svg';
import { ReactComponent as RightArrowDisabled } from '../images/arrows/svg/right-arrow-disable.svg';
import { Pagination } from 'react-bootstrap';
import { PTh, PTable, CustomPagination } from './Styles/PatientListStyles';
import Query from 'components/Query';
import ConfirmationDialog from 'components/Modals/ConfirmationModal'
import ReportActions from 'redux/ReportRedux'

// import UpdatedQuery from 'components/UpdatedQuery';

export class QueryListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: props.currentpage,
      totalPages: props.totalPages,
      showConfirmation: false,
      tentativeDeleteQueryId: null
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  sortLastName = () => {
    this.setState({
      currentPage: 1,
      sortKey: 'lastName',
      orderBy: 'ASC'
    }, () => {
      this.props.getQueries(1, 'lastName', 'ASC')
    });
  }

  sortFirstName = () => {
    this.setState({
      currentPage: 1,
      sortKey: 'lastName',
      orderBy: 'DESC'
    }, () => {
      this.props.getQueries(1, 'lastName', 'DESC')
    });
  }

  showDeleteQueryConfirmation = (queryId) => {
    this.setState({
      showConfirmation: true,
      tentativeDeleteQueryId: queryId
    })
  }

  deleteQuery = () => {
    const { tentativeDeleteQueryId } = this.state
    this.setState({
      showConfirmation: false,
      tentativeDeleteQueryId: null
    }, () => {
      this.props.deleteQuery(tentativeDeleteQueryId)
    })
  }

  renderTable () {
    const { queries, accounts } = this.props     
    const list = queries && queries.map((query, index) => {
      return (
        <Query
          key={query.queryId}
          accounts={accounts}
          index={index}
          query={query}
          onEdit={this.props.onEdit}
          onDelete={this.showDeleteQueryConfirmation}
          onSave={this.props.onSave}
          onShowInfo={this.props.onShowInfo}
        />
      )
    })

    return <PTable variant='hover'  className='table-borderless h6' responsive>
      <thead>
        <tr>
          <PTh className='col-2'>Query Time</PTh>
          <PTh className='col-1'>By</PTh>
          <PTh className='col-6'>Description</PTh>
          <PTh className='col-2'>Status</PTh>
          <PTh className='col-1'>Action</PTh>
        </tr>
      </thead>
      <tbody>
        { list }
      </tbody>
    </PTable>
  }

  getPaginationLink(number, sortKey, orderBy) {
    const { currentPage } = this.state;
    return (
      <Pagination.Item key={number} active={number === currentPage} onClick={this.handlePaginationLinkClick.bind(this, number, sortKey, orderBy)}>
        {number}
      </Pagination.Item>
    )
  }

  handlePaginationLinkClick (pageNumber, sortKey, orderBy) {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.props.getQueries(pageNumber, sortKey, orderBy)
    });
  }

  renderPagination () {
    const { currentPage, totalPages, sortKey, orderBy } = this.state;    
    if (totalPages < 2) return null;
    const DEFAULT_PAGINATION_LENGTH = 4;
    const currentPageRange = DEFAULT_PAGINATION_LENGTH * Math.floor((currentPage - 1) / DEFAULT_PAGINATION_LENGTH);
    let rangeStart = currentPageRange + 1;
    let rangeEnd = Math.min(rangeStart + DEFAULT_PAGINATION_LENGTH - 1, totalPages);
    const items = [];
    while (rangeStart <= rangeEnd) {
      items.push(this.getPaginationLink(rangeStart, sortKey, orderBy));
      rangeStart++;
    }
    return <CustomPagination className='justify-content-end w-100 mr-3'>
            <Pagination.Item disabled={currentPage === 1} onClick={this.handlePaginationLinkClick.bind(this, currentPage - 1, sortKey, orderBy)}>
              {currentPage === 1 ? <LeftArrowDisabled /> : <LeftArrow />}
            </Pagination.Item>
            {items}
            <Pagination.Item disabled={currentPage >= totalPages} onClick={this.handlePaginationLinkClick.bind(this, currentPage + 1, sortKey, orderBy)}>
              {currentPage >= totalPages ? <RightArrowDisabled /> : <RightArrow />}
            </Pagination.Item>
          </CustomPagination>;
  }

  render () {
    const { showConfirmation } = this.state

    return (
      <>
        {this.renderTable()}
        {this.renderPagination()}
        { showConfirmation && <ConfirmationDialog 
            heading={'Delete Query'}
            buttonText={'Delete'}
            onClose={() => { this.setState({ showConfirmation: false, tentativeDeleteQueryId: null  })}}
            onSave={this.deleteQuery}
            message={'Are you sure you want to delete this query?'}
        /> }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteQuery: (queryId) => dispatch(ReportActions.deleteQuery(queryId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryListing);