import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class Family extends React.Component {
  render() {
    let { data, link, read, write } = this.props;
    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Family:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4  mr-1 mr-1 mb-4 printable">

        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Family:</Col>
          {write && (
         
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        {/* {JSON.stringify(data)} */}
        {data && data.familyList && data.familyList.map((family) => (
          <Row >
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Enter family members that may live with participant
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.membersLivingWith}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Identify any service needs of participant’s immediate family
                  members:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.immediateMembers}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Identify any family members who have been supportive:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.supportiveMembers}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Identify any family members who may not have been supportive:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.notSupportiveMembers}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Enter family members that do not live with participant:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.membersNotLivingWith}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Please describe circumstances:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{family.circumstances}</label>
              </Col>
            </Row>
          </Row>
        ))}
      </Row>
    );
  }
}
