import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class PriorTreatmentHistory extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Prior Treatment History:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Prior Treatment History:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="mb-3">
            <Col className="col-12">
              <ViewHeading>
                Information about client (past and present):{" "}
              </ViewHeading>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Counseling/ Psychiatric Treatment
              </LightGrayLabel>
            </Col>
            <Col className=" p-0 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.clientPsychiatricTreatment)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientPsychiatricTreatmentDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientPsychiatricTreatmentLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-start mb-4">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Your Reaction To Overall Experience
              </LightGrayLabel>
            </Col>
            <Col className="col-9">
              <label className="input-label">{data.clientPsychiatricTreatmentReaction}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Suicidal Thoughts/ Attempts
              </LightGrayLabel>
            </Col>
            <Col className=" p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">
                  {humanFormat(data.familyInvolvementWithSelfHelpGroup)}
                </label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientSuicidalAttemptDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientSuicidalAttemptLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-start mb-4">
            <Col className="col-3">
              <LightGrayLabel>
                Your Reaction To Overall Experience
              </LightGrayLabel>
            </Col>
            <Col className="col-9">
              <label className="input-label">{data.clientSuicidalAttemptReaction}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Drug / Alcohol Treatment
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label  ml-3">{humanFormat(data.clientDrugTreatment)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientDrugTreatmentDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientDrugTreatmentLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-start mb-4">
            <Col className="col-3">
              <LightGrayLabel>
                Your Reaction To Overall Experience
              </LightGrayLabel>
            </Col>
            <Col className="col-9">
              <label className="input-label">{data.clientDrugTreatmentReaction}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <LightGrayLabel>
                Hospitalizations
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.clientHospitalization)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientHospitalizationDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientHospitalizationLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
        
          <Row className="align-items-start mb-4">
            <Col className="col-3">
              <LightGrayLabel>
                Your Reaction To Overall Experience
              </LightGrayLabel>
            </Col>
            <Col className="col-9">
              <label className="input-label">{data.clientHospitalizationReaction}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Involvement With Self-Help Groups(E.G., Aa, Al-Anon, Na, Oa,
                Etc.)
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">
                  {humanFormat(data.clientInvolvementWithSelfHelpGroup)}
                </label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.clientInvolvementWithSelfHelpGroupDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {data.clientInvolvementWithSelfHelpGroupLocation}
                  </label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-start mb-4">
            <Col className="col-3">
              <LightGrayLabel>
                Your Reaction To Overall Experience
              </LightGrayLabel>
            </Col>
            <Col className="col-9">
              <label className="input-label">{data.clientInvolvementWithSelfHelpGroupReaction}</label>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <ViewHeading>
                Information about family/significant others (past or present):
              </ViewHeading>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Counseling/ Psychiatric Treatment
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.familyPsychiatricTreatment)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyPsychiatricTreatmentDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyPsychiatricTreatmentLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Suicidal Thoughts / Attempts
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.familySuicidalAttempt)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familySuicidalAttemptDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familySuicidalAttemptLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
              Drug / Alcohol Treatment
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.familyDrugTreatment)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyDrugTreatmentDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyDrugTreatmentLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Hospitalizations
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">{humanFormat(data.familyHospitalization)}</label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyHospitalizationDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyHospitalizationLocation}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <LightGrayLabel>
                Involvement With Self-Help Groups(E.G., Aa, Al-Anon, Na, Oa,
                Etc.)
              </LightGrayLabel>
            </Col>
            <Col className="p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
              <Col className="col-12">
                <label className="input-label ml-3">
                  {humanFormat(data.familyInvolvementWithSelfHelpGroup)}
                </label>
              </Col>

              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>When</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.familyInvolvementWithSelfHelpGroupDate}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Where</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {data.familyInvolvementWithSelfHelpGroupLocation}
                  </label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            <Col className="col-md-12 col-lg-12 col-12">
              <ViewHeading>
                Please check behaviors and symptoms that occur more often than
                you would like them to:
              </ViewHeading>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Aggression</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAggression)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Eating disorder</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasEatingDisorder)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Moodshift</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMoodShift)}</label>
              </Col>
            </Col>
            
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Alcohol Dependence</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAlcoholDependence)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Elevated Mood</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasElevatedMood)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Phobias/ fears</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasPhobias)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Antisocial behavior</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAntisocialBehavior)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Fatigue</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasFatigue)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Recurring/ disorganized thoughts
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasRecurring)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Anxiety/ Panic attacks</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAnxiety)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Gambling</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.doesGambling)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sexual Difficulties</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSexualDifficulties)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Avoiding people</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.avoidsPeople)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Hallucinations</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHallucinations)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sick often</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isSickOften)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Chest pain/ Heart palpitations</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasChestPain)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>High blood pressure</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHighBloodPressure)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sleeping problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSleepingProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Depression</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDepression)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Irritability</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasIrritability)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Trembling</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasTrembling)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Disorientation/ Dizziness</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDisorientation)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Judgment errors</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasJudgmentErrors)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Worrying/ hopelessness</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHopelessness)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Distractibility</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDistractibility)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Loneliness/ withdrawing</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasLoneliness)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Drug dependence</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDrugDependence)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Memory impairment</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMemoryImpairment)}</label>
              </Col>
            </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.other)}</label>
                </Col>
              </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Specify</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.specifyOther}</label>
                </Col>
              </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Briefly Describe How The Above Symptoms Impair Your Ability To
                  Function Effectively:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.impairAbilitySymptomps}</label>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Provide Any Additional Information That Would Assist Us In
                  Understanding Your Concerns Or Problems
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.additionalInformation}</label>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  {" "}
                  What Are Your Goals For Therapy?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.goalsForTherapy}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Do you feel suicidal at this time?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.feelSuicidal)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>If Yes, Describe:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.suicidalfeelingDescription}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
