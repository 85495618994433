import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, Preview, ViewHeading } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Legal extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};

    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Legal:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Legal: </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                WAC 246-341-0640: Are you under a civil or court ordered mental
                health or substance use treatment order?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.hasCourtOrderedSubstanceUse)}</label>
            </Col>
          </Row>

          <Row className="col-12 p-0 mb-3">
            <Col className='col-12'>
              <label className='input-label mb-2'>
              If Yes, Please Provide A Copy Of The Court Order For Your File.
              </label>
            </Col>
            <Col className='col-12'>
              { data.treatmentCourtOrderDownloadURL &&
              <>
                <Preview>
                  {
                    <a
                      href={data.treatmentCourtOrderDownloadURL}
                      rel="noreferrer"
                      target="_blank"
                      alt="Image"
                    >
                      <img alt="" src={data.treatmentCourtOrderDownloadURL} onError={({ currentTarget }) => {currentTarget.src=`${data.treatmentCourtOrderDownloadURL}`}} />
                    </a>
                  }
                </Preview>
                <a
                  href={data.treatmentCourtOrderDownloadURL}
                  rel='noreferrer'
                  target="_blank"
                  alt='Image'>Open
                </a>
              </>
              }
            </Col>
          </Row>


          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Do you have a court order exempting you from reporting
                requirements?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {humanFormat(
                  data.hasCourtOrderFromExemptingFromReportingRequirements
                )}
              </label>
            </Col>
          </Row>

          <Row className="col-12 p-0 mb-3">
            <Col className='col-12'>
              <label className='input-label mb-2'>
                If Yes, Please Provide A Copy Of The Court Order For Your File.
              </label>
            </Col>


            <Col className='col-12'>
              { data.exemptionCourtOrderDownloadURL &&
                <>
                  <Preview>
                    {
                      <a
                        href={data.exemptionCourtOrderDownloadURL}
                        rel="noreferrer"
                        target="_blank"
                        alt="Image"
                      >
                        <img alt="" src={data.exemptionCourtOrderDownloadURL} onError={({ currentTarget }) => {currentTarget.src=`${data.exemptionCourtOrderDownloadURL}`}} />
                      </a>
                    }
                  </Preview>

                  <a
                    href={data.exemptionCourtOrderDownloadURL}
                    rel='noreferrer'
                    target="_blank"
                    alt='Image'>Open
                  </a>
                </>
                }
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Have you ever been convicted of a sex crime?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.hasConvictedSexCrime)}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-4 p-0">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  If Yes, Please Describe And Indicate The Court And
                  Hearing/Trial Dates And Charges:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.convictedSexCrimeDescription}</label>
              </Col>
            </Row>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Are you presently on probation or parole?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.onProbation)}</label>
            </Col>
          </Row>
          <Row className=" mb-4 row-cols-2 row-cols-xs-1 row-cols-md-2 ">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>If Yes, name of PO Officer:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.probationOfficerName}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Phone of PO Officer:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.probationOfficerPhonenumber}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  When is your probation/parole completed?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.probationCompleteDate}</label>
              </Col>
            </Row>
          </Row>
          <hr />
          <Row>
            <Col className="col-12">
              <ViewHeading>PAST HISTORY: </ViewHeading>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="p-0 col-6 mb-3">
              <Col className="col-12">
                <LightGrayLabel>Traffic Violations:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasTrafficViolations)}</label>
              </Col>
            </Col>
            <Col className="p-0 col-6 mb-3">
              <Col className="col-12">
                <LightGrayLabel>DWI/DUI etc.:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasDWIorDUI)}</label>
              </Col>
            </Col>
            <Col className="p-0 col-6 mb-3">
              <Col className="col-12">
                <LightGrayLabel>Criminal Involvement:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasCriminalInvolvement)}</label>
              </Col>
            </Col>
            <Col className="p-0 col-6 mb-3">
              <Col className="col-12">
                <LightGrayLabel>Civil Involvement:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasCivilInvolvement)}</label>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              If you responded Yes to any of the above, please fill in the
              following information:
            </Col>
          </Row>
            <Row className="col-12 mt-3 mb-3">
              <Col className="col-12 p-0">
                <LightGrayLabel>If you responded Yes to any of the above, please fill in the following information:</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0">
                <Row>
                  <Col> Charges </Col>
                  <Col> Date </Col>
                  <Col> Place </Col>
                  <Col> Results </Col>
                </Row>
                {
                  data.charges && data.charges.map((charge) => {
                    return (
                      <Row>
                        <Col><label className="input-label"> { charge.charges }</label></Col>
                        <Col><label className="input-label"> { charge.date }</label> </Col>
                        <Col><label className="input-label"> { charge.place }</label> </Col>
                        <Col><label className="input-label"> { charge.results }</label> </Col>
                      </Row>
                    )
                  })
                }
              </Col>
            </Row>
            <Row className="mb-4 row-cols-2 row-cols-xs-1 row-cols-md-2 ">

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.date}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>City Name</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.place}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Eg., Convicted, Pending, Etc</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.results}</label>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="col-12">
              <ViewHeading>INCARCERATION/REENTRY </ViewHeading>
            </Col>
          </Row>
          <Row className="align-items-center mb-4 row-cols-2 row-cols-xs-1 row-cols-md-2 mx-n2 mx-md-n3 mx-lg-n4">
            <Col className="p-0 px-2 px-sm-2 px-md-2 px-lg-3">
              <Col className="col-12">
                <LightGrayLabel>DOC No.:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.incarcerationDocNum}</label>
              </Col>
            </Col>
            <Col className="p-0 px-2 px-sm-2 px-md-2 px-lg-3">
              <Col className="col-12">
                <LightGrayLabel>Institution</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.incarcerationInstitution}</label>
              </Col>
            </Col>
            <Col className="p-0 px-2 px-sm-2 px-md-2 px-lg-3">
              <Col className="col-12">
                <LightGrayLabel>Unit</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.incarcerationUnit}</label>
              </Col>
            </Col>
            <Col className="p-0 px-2 px-sm-2 px-md-2 px-lg-3">
              <Col className="col-12">
                <LightGrayLabel>Length of Incarceration</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.lengthOfIncarceration}</label>
              </Col>
            </Col>
            <Col className="p-0 px-2 px-sm-2 px-md-2 px-lg-3">
              <Col className="col-12">
                <LightGrayLabel>Date Released</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.incarcerationReleaseDate}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
