import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  loginRequest: ['email', 'password'],
  loginSuccess: ['userId'],
  loginFailure: ['error'],
  signUpRequest: ['email', 'password'],
  signUpSuccess: ['user'],
  signUpFailure: ['error'],
  sendEmailRequest: ['email'],
  emailSentSuccess: null,
  emailSentFailed: ['error'],
  validatePassword: ['password', 'token'],
  updatePassword: ['currentPassword', 'newPassword'],
  logoutRequest: null,
  logoutSuccess: null,
  logoutFailed: null,
  exit: null,
})

export const AuthTypes = Types
export default Creators

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  fetching: false,
  sending: false,
  updating: false,
  loggedIn: false
})

export const loginRequest = (state) => {
  return state.merge({
    fetching: true
  })
}

export const loginSuccess = (state) => {
  return state.merge({
    fetching: false,
    loggedIn: true
  })
}

export const loginFailure = (state) => {
  return state.merge({
    fetching: false
  })
}

export const signUpRequest = (state) => {
  return state.merge({
    fetching: true
  })
}

export const logoutSuccess = (state) => {
  return state.merge({
    loggedIn: false
  })
}

export const sendEmailRequest = (state) => {
  return state.merge({
    sending: true
  })
}

export const emailSentSuccess = (state) => {
  return state.merge({
    sending: false
  })
}

export const emailSentFailed = (state) => {
  return state.merge({
    sending: false
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SEND_EMAIL_REQUEST]: sendEmailRequest,
  [Types.EMAIL_SENT_SUCCESS]: emailSentSuccess,
  [Types.EMAIL_SENT_FAILED]: emailSentFailed
})