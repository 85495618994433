
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row } from 'react-bootstrap';
import { PTh, PTr, PTd, PTable } from '../Styles/PatientListStyles';

export default class ShowInfoModal extends React.Component {
  render () {
    const { rows } = this.props
    const firstRow = rows[0];

    const list = rows && rows.map((r, index) => {
      return (
        <PTr>
          {
            Object.values(r).map((v, i) => <PTd>{v}</PTd>)
          }
        </PTr>
      )
    })

    return (
      <BaseFormModal heading={'Info'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Row className='ml-0 mr-0'>
          <PTable variant='hover' className='table-borderless small pre-scrollable' responsive>
            { rows.length === 0 ? (
              <Row className='p-2 ml-2'>
                No Data
              </Row>
            ) : (
              <>
                <thead>
                  <tr>
                    { Object.keys(firstRow).map((k, index) => <PTh>{k}</PTh>) }
                  </tr>
                </thead>
                <tbody className='fixed'>
                  { list }
                </tbody>
              </>
            )}
            
          </PTable>
        </Row>
      </BaseFormModal>
    )
  }
}