import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { RadioButton } from "components/Fields/RadioButton";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_MENTAL_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export const Demographics = (props) => {
  const { data, updatingForm, read, write } = props;
  if (!write && !read) {
    return (
      <Row className="pt-4 pl-2">
        <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
      </Row>
    );
  }

  const handleSubmit = (values) => {
    props.onUpdate(
      props.clientID,
      { ...values, clientId: props.clientID },
      CLIENT_MENTAL_FORM_TYPES.demographics
    );
  };

  const genderRadioOptions = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Transgender", value: "transgender" },
  ];

  const sexualOrientationOptions = [
    { key: "Straight/ Heterosexual", value: "Straight_Hetro" },
    { key: "Gay, Homosexual, Lesbian", value: "homosexual" },
    { key: "Bisexual", value: "bisexual" },
    { key: "Other", value: "Other" },
  ];

  const ethnicityRadioOptions = [
    { key: "Asian", value: "asian" },
    { key: "Black/African American", value: "black_african_american" },
    { key: "Native Hawaiian/ Pacific Islander", value: "native_hawaian" },
    { key: "Decline", value: "decline" },
    { key: "White/ Caucasian", value: "white_caucasian" },
    { key: "Alaska Native/ American Indian – Tribe", value: "alaska_native" },
  ];

  const calculateAge = (dob) => {
    var Bday = +new Date(dob);
    const age = (Date.now() - Bday) / 31557600000;
    return Math.floor(age);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...data }}
      onSubmit={handleSubmit}
      render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
        <>
          <Prompt when={dirty} message={Strings.prompt.message} />
          <Form className="row mr-1">
            <BaseForm updatingForm={updatingForm} disabled={!write}>
              <div className="w-100">
                <Row className="mb-2">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4 col-lg-2">
                    <label className="input-label" htmlFor="firstName">
                      Name:
                    </label>
                  </Col>

                   <Col className="p-0 col-10 d-flex">
                    <TextInput
                      className="mr-3"
                      name="firstName"
                      type="text"
                      placeholder="First"
                      readonly='true'
                    />
                    <TextInput className='mr-3' readonly='true' name='middleName' type='text' placeholder='Middle'/>
                    <TextInput
                      name="lastName"
                      type="text"
                      placeholder="Last"
                      readonly='true'
                    />
                  </Col>

                  
         
                </Row>
                <Row className="mb-3">
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4 col-lg-2">
                    <label className="input-label" htmlFor="dob">
                      Date Of Birth
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-6 col-lg-10">
                    <TextInput
                      className="mr-3 mb-0"
                      name="dob"
                      type="date"
                      placeholder="MM/DD/YYYY"
                      readonly="readonly"
                    />
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-4 col-lg-2 mt-3">
                    <label className="input-label mr-2 mb-0" htmlFor="age">
                      Age
                    </label>
                  </Col>
                  <Col className="p-0 col-xs-12 col-sm-12 col-md-6 col-lg-10 mt-3">
                    <TextInput
                      className="mr-3 mb-0"
                      name="age"
                      placeholder="Age"
                      value={calculateAge(data && data.dob)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mt-4 mb-3">
                  <Col className="p-0 col-2">
                    <label className="input-label" htmlFor="gender">
                      Gender
                    </label>
                  </Col>
                  <Col className="p-0 col-4">
                    <RadioButton
                      className="mb-3"
                      readonly="readonly"
                      name="gender"
                      options={genderRadioOptions}
                    />
                  </Col>
                  <Col className="p-0 col-2">
                    <TextInput
                      name="pronoun"
                      type="text"
                      placeholder="Pronoun"
                      readonly='true'
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">
                  <Col className="col-2 p-0 col-md-6 col-lg-2 align-self-start">
                    <label className="input-label" htmlFor="sexualOrientation">
                      Sexual Orientation
                    </label>
                  </Col>
                  <Col className="col-10 p-0 d-flex flex-column">
                    <RadioButton
                      className="mb-3"
                      name="sexualOrientation"
                      options={sexualOrientationOptions}
                      onChange={handleChange}
                    />
                    <TextInput
                      className="w-50"
                      name="sexualOrientationOther"
                      type="text"
                      placeholder="Other (specify)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-10 col-lg-2 p-0">
                    <label className="input-label">
                      Describe your Ethnicity
                    </label>
                  </Col>
                  <Col className="col-xs-12 col-md-8 col-lg-10 p-0">
                    <Row>
                      <Col className=" col-4 mb-3">
                        <RadioButton
                          className="mb-3"
                          name="ethinicty"
                          options={ethnicityRadioOptions}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <TextInput
                          className="d-inline-flex"
                          label="Other"
                          name="ethinictyOther"
                          type="text"
                          placeholder="Other (specify)"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <hr />
                <Row className="mb-3">
                  <Col className="col-12">
                    <label className="input-label">
                      Form Completed By (If Someone Other Than Client)
                    </label>
                  </Col>
                  <Col className="p-0">
                    <TextInput
                      className="col-8"
                      name="formCompletedBy"
                      type="other"
                      placeholder="Form Completed By (If Someone Other Than Client)"
                    />
                  </Col>
                </Row>
              </div>
            </BaseForm>
          </Form>
        </>
      )}
    />
  );
};
