import styled from 'styled-components';

export const HeaderStyles = styled.div`
  background-color: ${props => props.theme.secondaryBackgroundColor};
  box-shadow: 0px 2px 5px #00000029;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 64px;
`
export const BrandName = styled.div`
  font-size: 26px;
  font-family: PoppinsBold;
`