import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { TextMessage } from 'components/Fields/TextMessage'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

export default class PhysicalAndMedicalHealth extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.medicalHealth)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAIDS'> AIDS</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasColdsOrCough'> Colds/Cough </Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasHepatitis'>  Hepatitis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSexuallyTransmittedDiseases'> Sexually transmitted diseases</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAlcoholism'> Alcoholism </Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasConstipation'>  Constipation</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasHighBloodPressure'> High blood pressure</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSinusitis'> Sinusitis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAbdominalPain'> Abdominal pain </Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasDentalProblems'> Dental problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasKidneyProblems'> Kidney problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSleepingDisorders'> Sleeping disorders</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAbortion'> Abortion</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasDiabetes'> Diabetes</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMeasles'> Measles</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSmallpox'> Smallpox</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAllergies'> Allergies (List Below)</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasDiarrhea'> Diarrhea</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMenstrualPain'> Menstrual pain</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSoreThroat'> Sore throat</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAnemia'> Anemia</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasDizziness'> Dizziness</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMiscarriages'> Miscarriages</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasStroke'> Stroke</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAppendicitis'> Appendicitis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasDrugAbuse'> Drug abuse</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMononucleosis'> Mononucleosis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasThyroidProblems'> Thyroid problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasArthritis'> Arthritis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasEarInfections'> Ear infections</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMumps'> Mumps</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasTonsilitis'> Tonsilitis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasAsthma'> Asthma</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasEatingProblems'> Eating problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasNausea'> Nausea</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasToothache'> Toothache</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasBronchitis'> Bronchitis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasEpilepsy'> Epilepsy</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasNeurologicalDisorders'> Neurological disorders</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasTuberculosis'> Tuberculosis</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasBedwetting'> Bedwetting</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasFainting'> Fainting</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasNoseBleeds'> Nose bleeds</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasVisionProblems'> Vision problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasCancer'> Cancer</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasFatigue'> Fatigue</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasPneumonia'> Pnemonia</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasWhoopingCough'> Whooping cough</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasChestPain'> Chest pain</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasFrequentUrination'> Frequent Urination</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasRheumaticFever'> Rheumatic Fever</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasChickenPox'> Chicken Pox</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasHeadaches'> Headaches</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasScarletFever'> Scarlet Fever</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasChronicPain'> Chronic pain</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasHearingProblems'> Hearing problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasSexualProblems'> Sexual problems</Checkbox>
                    </Col>
                    <Col className='mb-3'>
                      <Checkbox labelClassName='text-wrap' name='hasMemoryImpairment'> Memory impairment</Checkbox>
                    </Col>
                    <Col className='mb-3 d-flex align-items-center'>
                      <Checkbox labelClassName='text-wrap d-flex align-items-center' name='hasOther'> Other</Checkbox>
                      <TextInput className='mb-0' name='specifyOther' type='text' placeholder='(Specify)'/>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <hr />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-12'>
                      <label className='input-label text-wrap mb-2' htmlFor='describeCurrentHealthConcerns'>
                        List Any Current Health Concerns
                      </label>
                    </Col>
                    <Col className='p-0 col-10'>
                      <TextMessage
                        labelClassName='d-none'
                        name='healthConcerns'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-12'>
                      <label className='input-label text-wrap mb-2' htmlFor='describeRecentHealthChanges'>
                        List Any Recent Health Or Physical Changes
                      </label>
                    </Col>
                    <Col className='p-0 col-10'>
                      <TextMessage
                        labelClassName='d-none'
                        name='recentPhysicalChanges'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}