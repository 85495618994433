import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextInput } from 'components/Fields/TextInput'
import { Checkbox } from 'components/Fields/Checkbox'
import BaseForm from 'components/Patient/BaseForm'
import { FormHeader, Acknowlegdement, LightGrayLabel } from '../../../../styles/GenericStyles';
import { CLIENT_BFET_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'

export default class SkillsAndQualification extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_BFET_FORM_TYPES.skillsAndQualification)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <FormHeader>EDUCATION:</FormHeader>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='col-12 p-0 mb-2'>
                      <Checkbox labelClassName='input-label' name='isHighSchoolDiplomaOrEquivalent'>   High School Diploma or Equivalent </Checkbox>
                    </Col>
                    <Col className='col-xs-12 col-md-8 p-0'>
                      <TextInput name='highSchoolDiplomaOrEquivalent' type='text' placeholder='Notes' />
                    </Col>
                  </Row>
                  <Row className='row-cols-xs-1 row-cols-md-2'>
                    <Col className='p-0 col-xs-12 col-md-12 col-12 mb-2'>
                      <Checkbox labelClassName='input-label' name='isCollegeEducation'>  College Education </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <TextInput className='mr-3' name='collegeEducationDegree' type='text' placeholder='Degree' />
                    </Col>
                    <Col className='p-0'>
                      <TextInput className='mr-3' name='collegeEducationNotes' type='text' placeholder='Notes' />
                    </Col>
                  </Row>

                  <Row className='row-cols-xs-1 row-cols-md-2'>
                    <Col className='p-0 col-xs-12 col-md-12 col-12 mb-2'>
                      <Checkbox labelClassName='input-label' name='isVocationalTraning'>  Vocational Training </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <TextInput className='mr-3' name='vocationalTraningDegree' type='text' placeholder='Certificate' />
                    </Col>
                    <Col className='p-0'>
                      <TextInput className='mr-3' name='vocationalTraningNotes' type='text' placeholder='Notes' />
                    </Col>
                  </Row>

                  <hr />

                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <FormHeader>WORK EXPERIENCE:</FormHeader>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='col-12 d-flex mb-2 p-0'>
                      <label className='input-label my-auto'>
                        Number of employers in past 5 years: 
                      </label>
                      <Acknowlegdement className='ml-2 my-auto'>(List Employment History on separate sheet)</Acknowlegdement>
                    </Col>
                    <Col className='col-xs-12 col-md-8 p-0'>
                      <TextInput labelClassName='input-label mb-2' className='mr-3' name='numberOfEmployeersInLast5Years' type='number' placeholder='Description'/>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <Checkbox labelClassName='input-label mb-2' name='noWorkingExperienceInPastYear'>   No work experience in the past year</Checkbox>
                    </Col>
                  </Row>
                  <hr />

                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <FormHeader>TRANSFERRABLE SKILLS:</FormHeader>
                    </Col>
                  </Row>
                  <Row className='row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 mb-3'>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='customerService'>  Customer service </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='solveProblems'>  Solve problems </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='organized'>  Organized </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='writeClearly'>  Write clearly </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='calculateCompute'>  Calculate, compute </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='managePeople'>  Manage people </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='speakInPublic'>  Speak in public </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='listening'>  Listening</Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='runMeetings'>  Run meetings </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='speakMultipleLanguages'>  Speak multiple languages </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='managingTime'>  Managing time </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='directProjects'>  Direct projects </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='communicateVerbally'>  Communicate verbally </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='beingPunctual'>  Being punctual </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='handleFood'>  Handle food </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='cooperating'>  Cooperating </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='manageMoney'>  Manage money </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='followInstructions'>  Follow instructions </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='takeOrders'>  Take orders </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='constructOrRepair'>  Construct or repair </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='operateOffice'>  Operate office equipment </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='driveOrOperateVehicles'>  Drive or operate vehicles </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='computerSkills'>  Computer Skills </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='operateToolsAndMachinery'>  Operate tools and machinery </Checkbox>
                    </Col>
                    <Col className='p-0'>
                      <Checkbox labelClassName='text-wrap mb-2' name='transferrableSkillsothers'>  Others </Checkbox>
                    </Col>
                    <Col className='p-0 col-xs-12 col-sm-12 col-md-12 col-12'>
                      <TextInput className='w-50' name='otherTransferrableSkills' type='text' placeholder='Others'/>
                    </Col>
                  </Row>

                  <hr />
                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <FormHeader>OTHER QUALIFICATIONS:</FormHeader>
                    </Col>
                  </Row>

                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <TextInput labelClassName='input-label mb-2' className='mr-3' label='Certificate:' name='otherQualificationsCertificate' type='text' placeholder='Certificate:'/>
                    </Col>
                    <Col className='p-0'>
                      <TextInput labelClassName='input-label mb-2' className='mr-3' label='Professional License:' name='otherProfessionalLicense' type='text' placeholder='Professional License:'/>
                    </Col>
                  </Row>

                  <Row className='mb-3'>
                    <Col className='p-0'>
                      <TextInput labelClassName='input-label mb-2' label='Other' name='otherQualificationsOther' type='text' placeholder='Other:'/>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    ) 
  }
}