import React from 'react';
import { ReactComponent as CalendarIcon } from '../images/svg/calendar-outline.svg';
import { Popover, Button, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { IconButton } from './Styles/AppointmentListStyles';
import { Formik, Form } from 'formik';
import { TextInput } from 'components/Fields/TextInput'
import * as Yup from 'yup';

export const DateFilter = (props) => {
  
  const handleSubmit = (values) => {
    props.applySelectedDates(values.start, values.end)
    document.body.click();
  }

  return (
    <Formik
      initialValues={{ start: null, end: null }}
      validationSchema={Yup.object({
        start: Yup.date().required('Needs a start time'),
        end: Yup.date().required('Needs a end time')
      })}
      onSubmit={handleSubmit}>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        rootClose
        overlay={
          <Popover className='popover-no-arrow' id="popover-basic">
            <Popover.Content className='popover-content'>
              <Form>
                <Row>
                  <Col className='col-12'>
                    <label htmlFor="" className="input-label">From</label>
                  </Col>
                  <Col className='col-12 mb-2'>
                    <TextInput name='start' type='date' placeholder='MM/DD/YYYY'/>
                  </Col>
                  <Col className='col-12'>
                    <label htmlFor="" className="input-label">To</label>
                  </Col>
                  <Col className='col-12 mb-2'>
                    <TextInput name='end' type='date' placeholder='MM/DD/YYYY'/>
                  </Col>
                  <Col className='col-12 mb-2 d-flex'>
                    <Button type='submit' className='ml-auto'>Apply</Button>
                  </Col>
                </Row>
              </Form>
            </Popover.Content>
          </Popover>
      }>
      <IconButton className='mr-3'>
        <CalendarIcon />
      </IconButton>
      </OverlayTrigger>
    </Formik>
  );
}
