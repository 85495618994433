import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_SUPPLIES } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";
import moment from "moment";

export class ServiceDeliveryView extends React.Component {
  componentDidMount() {
    this.props.getClientSuppliesInformation(this.props.clientID, "DEFAULT");
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    let {
      clientID,
      data,
      isWriteSuppliesEnabled,
      isReadSuppliesEnabled,
    } = this.props;
    data = data || {};
    if (!isReadSuppliesEnabled && !isWriteSuppliesEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
            <Row className="pl-3"> Supplies:</Row>
            <Row className="pl-3 mt-2">
              <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
            </Row>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Supplies:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_SUPPLIES.replace(":id", clientID)}>Edit</Link>
          </Col>
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Row className="pb-2 ml-4 pl-2 w-100 mb-3">
            {data &&
              data.supplies &&
              data.supplies.map((supply, index) => {
                return (
                  <Row>
                    <Row className="row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>Supply Title</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.supplyTitle}
                          </label>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>Note Time</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply &&
                              moment(supply.createdAt).format(
                                "MM-DD-YYYY HH:mm"
                              )}
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>Case Number</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.caseNumber}
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>EJAS Number</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.ejasNumber}
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>Issued To</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.issuedTo}
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>Supply Date</LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.supplyDate}
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="col-12">
                          <LightGrayLabel>
                            Case Manager Signature
                          </LightGrayLabel>
                        </Col>
                        <Col>
                          <label className="input-label">
                            {supply && supply.caseManagerSignature}
                          </label>
                        </Col>
                      </Row>
                    </Row>

                    <Row className="table-responsive">
                      <Table className="w-100 pl-0 ml-0" borderless responsive>
                        <thead>
                          <th
                            className="pl-0 align-top"
                            style={{ width: "20%" }}
                          >
                            <LightGrayLabel>Item Name</LightGrayLabel>
                          </th>
                          <th className="align-top" style={{ width: "20%" }}>
                            <LightGrayLabel>funding Source</LightGrayLabel>
                          </th>
                          <th className="align-top" style={{ width: "20%" }}>
                            <LightGrayLabel>quantity</LightGrayLabel>
                          </th>
                          <th className="align-top" style={{ width: "20%" }}>
                            <LightGrayLabel>unit Price</LightGrayLabel>
                          </th>
                          <th className="align-top" style={{ width: "20%" }}>
                            <LightGrayLabel>total Price</LightGrayLabel>
                          </th>
                        </thead>
                        <tbody>
                          {supply.items &&
                            supply.items.map((item) => {
                              return (
                                <tr>
                                  <td className="pl-0 py-0">
                                    <label className="input-label">
                                      {item.itemName}
                                    </label>
                                  </td>
                                  <td className="py-0">
                                    <label className="input-label">
                                      {item.fundingSource}
                                    </label>
                                  </td>
                                  <td className="py-0">
                                    <label className="input-label">
                                      {item.quantity}
                                    </label>
                                  </td>
                                  <td className="py-0">
                                    <label className="input-label">
                                      {item.unitPrice}
                                    </label>
                                  </td>
                                  <td className="py-0">
                                    <label className="input-label">
                                      {item.totalPrice}
                                    </label>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Row>

                  </Row>
                );
              })}
        
          </Row>
    
        </div>
      </Row>
    );
  }

  render() {
    const { getSuppliesFetching } = this.props;
    return (
      <>
        {!getSuppliesFetching && this.renderLoading()}
        {getSuppliesFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSuppliesFetching: state.client.suppliesFetching.DEFAULT,
    data: state.client.supplies.DEFAULT,
    isWriteSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_SUPPLIES
    ),
    isReadSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_SUPPLIES
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientSuppliesInformation: (id, category) =>
      dispatch(ClientActions.getClientSuppliesInformation(id, category)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryView);
