import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { TextMessage } from 'components/Fields/TextMessage'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]

export default class Development extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.development)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className='w-100'>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-3' htmlFor='hasTraumaticCircumstances'>Are there special, unusual, or traumatic circumstances that affected your development?:</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton className='mb-3' name='underSpecialCircumstances' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                <Row className='my-3'>
                    <Col className='p-0'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Please Describe'
                        name='specialCircumstances'
                        placeholder='Describe'
                        rows={4}
                        size="md"/>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='align-items-center mb-4 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='hasHistoryOfAbuse'>History of abuse?</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='hasHistoryOfAbuse' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2'>If Yes, Which Type(S)?</label>
                    </Col>
                    <Col className='col-12 d-flex p-0'>
                      <Checkbox labelClassName='text-wrap mr-4' name='isSexualAbuse'>Sexual</Checkbox>
                      <Checkbox labelClassName='text-wrap mr-4' name='isPhysicalAbuse'>Physical</Checkbox>
                      <Checkbox labelClassName='text-wrap mr-4' name='isVerbalAbuse'>Verbal</Checkbox>
                      <Checkbox labelClassName='text-wrap mr-4' name='isEmotionalAbuse'>Emotional</Checkbox>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-4 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2'>If Yes, Was The Abuse As A:</label>
                    </Col>
                    <Col className='col-12 d-flex p-0'>
                      <Checkbox labelClassName='text-wrap mr-4' name='isVictim'>Victim</Checkbox>
                      <Checkbox labelClassName='text-wrap mr-4' name='isPerptrator'>Perpetrator</Checkbox>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='my-3'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-0'>Childhood Issues?</label>
                    </Col>
                    <Col className='col-3 p-0 d-flex align-items-center'>
                      <Checkbox labelClassName='text-wrap' name='neglected'>Neglect</Checkbox>
                    </Col>
                    <Col className='col-4 p-0 d-flex align-items-center'>
                      <Checkbox labelClassName='text-wrap' name='inadequateNutrition'>Inadequate nutrition</Checkbox>
                    </Col>
                    <Col className='col-5 p-0 d-flex align-items-center mb-3'>
                      <Checkbox labelClassName='text-wrap mr-2' name='childHoodIssuesOther'>Other</Checkbox>
                      <TextInput
                        type='text'
                        name='otherChildHoodIssues'
                        placeholder='Other (specify)'
                        className='d-inline mb-0'
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row className='align-items-center mt-3 mb-4 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2'>In your life, have you ever had any experience that was so frightening, horrible, or upsetting that in the past month you:</label>
                    </Col>
                    <Col className='col-12'>
                      <RadioButton
                        name='hadNightMares'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-4'
                        options={yesOrNoOptions}
                        label='Had nightmares about it, or thought about it when you did not want to?'
                        onChange={handleChange}
                      />
                      <RadioButton
                        name='triedAvoidingSituations'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-4'
                        options={yesOrNoOptions}
                        label='Tried hard not to think about it, or went out of your way to avoid situations that reminded you of it?'
                        onChange={handleChange}
                      />
                      <RadioButton
                        name='isOnConstantGuard'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-4'
                        options={yesOrNoOptions}
                        label='Were constantly on guard, watchful, or easily startled?'
                        onChange={handleChange}
                      />
                      <RadioButton
                        name='hasFeltNumb'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-4'
                        options={yesOrNoOptions}
                        label='Felt numb or detached from others, activities, or your surroundings?'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}