import styled from 'styled-components';
import { Table, Pagination } from 'react-bootstrap';

export const PTh = styled.th`
  color: #8898AA;
  
`

export const PTd = styled.td`
  color: #343A40;
  vertical-align: middle !important;
  .ptd-name-container {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
    }
  }
`

export const PTr = styled.tr`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #2C28281C;
  border-radius: 6px;
  opacity: 1;
`

export const PTable = styled(Table)`
  border-spacing: 0 10px;
  border-collapse: separate;
`
export const IconButton = styled.button`
  background-color: #F2F2F2;
  border-radius: 50%;
  border: none;
  width: 36px;
  height: 36px;
`;

export const CustomPagination = styled(Pagination)`
  .page-item {
    cursor: pointer;
    user-select: none;
    &.active .page-link {
      background: #3B41C2 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #2C28281C;
      color: #FFFFFF;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  .page-item .page-link {
    width: 36px;
    height: 37px;
    border: 1px solid #8898AA80;
    border-radius: 50%;
    padding: 0;
    margin-left: .5em;
    color: #7764E4;
    letter-spacing: 0px;
    text-align: center;
    line-height: 37px;
  }
`