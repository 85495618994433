import styled from 'styled-components';
import { Col } from 'react-bootstrap';
export const GreetingContainer = styled.div`
  background-image: url('../../../images/png/dashboard-banner.png')
`

export const GreetingText = styled.div`
  font-size: 27px;
  font-family: PoppinsBold;
`
export const SearchBox = styled.input`
  background-color: none !important;
`

export const CardNumber = styled.div`
  font-size: 27px;
  font-family: PoppinsBold;
  color: ${props => props.theme.primaryBgColor};
`

export const CardText = styled.div`
  color: ${props => props.color ? props.color : '#BABABA' };
  size: ${props => props.size ? props.size : '15px' };
  font-family: ${props => props.bold ? 'PoppinsBold' : 'PoppinsRegular' };
  margin-bottom: 6px; 
`
export const IconContainer = styled.div`
  padding: 12px 16px;
  border-radius: 4px;
  background: ${props => props.theme.primaryBgColorWithOpacity};
`

export const BookAppointmentCard = styled(Col)`
  background: ${props => props.theme.primaryBgColorWithOpacity};
`