import React from "react";
import { Formik, Form } from "formik";
import { TextMessage } from "components/Fields/TextMessage";
import { TextInput } from "components/Fields/TextInput";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";

export default class EmploymentGoals extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_BFET_FORM_TYPES.employmentGoals
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <TextMessage
                  className="mb-3"
                  labelClassName="input-label mb-2"
                  label="Career Plan:"
                  name="careerGoal"
                  type="textarea"
                  placeholder="Enter Here"
                  rows={5}
                  cols={100}
                  size="md"
                />
                <TextMessage
                  className="mb-3"
                  labelClassName="input-label mb-2"
                  label="Wage Expectation:"
                  name="wageExpectation"
                  type="textarea"
                  placeholder="Enter Here"
                  rows={5}
                  cols={100}
                  size="md"
                />
                <TextMessage
                  className="mb-3"
                  labelClassName="input-label mb-2"
                  label="Immediate Job Goals(s):"
                  name="immediateGoals"
                  type="textarea"
                  placeholder="Enter Here"
                  rows={5}
                  cols={100}
                  size="md"
                />
                <Row className="align-items-center mb-3 col-12">
                  <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-5 p-0">
                    <label
                      className="input-label"
                      htmlFor="targetEmploymentDate"
                    >
                      Target Employment Date:
                    </label>
                  </Col>
                  <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                    <TextInput
                      name="targetEmploymentDate"
                      type="date"
                      placeholder="MM/DD/YYYY"
                    />
                  </Col>
                </Row>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
