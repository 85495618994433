import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Transportation extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Transportation:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Transportation:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <div>
          <Row className="mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Vehicle Available:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.isVehicleAvailable)}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Driver’s License:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.isDriverLicenseAvailable)}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>If yes, State:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.driverLicenseState}</label>
              </Col>
            </Row>

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Liability Insurance:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.hasLiabilityInsurance)}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>If No, explain:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {data.noLiabilityInsuranceExplaination}
                </label>
              </Col>
            </Row>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                I am willing and able to travel n miles to a job.
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {data.numberOfMilesWillingToTravelToJob}
              </label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                I am able to take public transportation
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {humanFormat(data.isAbleToTakePublicTransportation)}
              </label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>I am willing to relocate</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {humanFormat(data.isWillingToRelocate)}
              </label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
