import React from "react";
import { Row } from "react-bootstrap";

// Intake Forms
import { Demographics } from "components/Patient/MentalHealth/PersonalHistory/Demographics";
import Development from "components/Patient/MentalHealth/PersonalHistory/Development";
import Education from "components/Patient/MentalHealth/PersonalHistory/Education";
import FamilyInformation from "components/Patient/MentalHealth/PersonalHistory/FamilyInformation";
import PrimaryReasonsForSeekingServices from "components/Patient/MentalHealth/PersonalHistory/PrimaryReasonsForSeekingServices";
import SocialRelationships from "components/Patient/MentalHealth/PersonalHistory/SocialRelationships";
import SpiritualReligion from "components/Patient/MentalHealth/PersonalHistory/SpiritualReligion";
import Legal from "components/Patient/MentalHealth/PersonalHistory/Legal";
import Employment from "components/Patient/MentalHealth/PersonalHistory/Employment";
import Military from "components/Patient/MentalHealth/PersonalHistory/Military";
import LeisureAndRecreational from "components/Patient/MentalHealth/PersonalHistory/LeisureAndRecreational";
import PriorTreatmentHistory from "components/Patient/MentalHealth/PersonalHistory/PriorTreatmentHistory";
import PhysicalAndMedicalHealth from "components/Patient/MentalHealth/PersonalHistory/PhysicalAndMedicalHealth";
import MedicalHistory from "components/Patient/MentalHealth/PersonalHistory/MedicalHistory";
import Nutrition from "components/Patient/MentalHealth/PersonalHistory/Nutrition";
import ChemicalUseHistory from "components/Patient/MentalHealth/PersonalHistory/ChemicalUseHistory";
import AdditionalInformation from "components/Patient/MentalHealth/PersonalHistory/AdditionalInformation";
import ClientActions from "redux/ClientRedux";
import { CLIENT_MENTAL_FORM_TYPES } from "redux/ClientRedux";
import { connect } from "react-redux";

// Scrollable navigation
import ScrollingNavigation from "components/Patient/ScrollingNavigation";
import { PATIENT_MENTAL_TABS } from "config/CONSTANTS";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class PatientMentalHealth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTabKey: props.goTo,
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.goTo !== newProps.goTo) {
      this.setState({
        defaultTabKey: newProps.goTo,
      });
    }
  }

  onUpdate = (id, params, category) => {
    this.props.updateFormInit();
    switch (category) {
      case CLIENT_MENTAL_FORM_TYPES.additionalInformation:
        this.props.updateClientMentalAdditionalInformation(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.chemicalUseHistory:
        this.props.updateClientMentalChemicalUseHistory(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.demographics:
        this.props.updateClientMentalDemographics(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.development:
        this.props.updateClientMentalDevelopment(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.education:
        this.props.updateClientMentalEducation(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.employment:
        this.props.updateClientMentalEmployment(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.familyInformation:
        this.props.updateClientMentalFamilyInformation(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.legal:
        this.props.updateClientMentalLegal(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.leisure:
        this.props.updateClientMentalLeisure(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.medicalHealth:
        this.props.updateClientMentalMedicalHealth(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.medicalHistory:
        this.props.updateClientMentalMedicalHistory(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.military:
        this.props.updateClientMentalMilitary(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.nutrition:
        this.props.updateClientMentalNutrition(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.primaryReasonsForSeekingServices:
        this.props.updateClientMentalPrimaryReasonsForSeekingServices(
          id,
          params
        );
        break;
      case CLIENT_MENTAL_FORM_TYPES.socialRelationships:
        this.props.updateClientMentalSocialRelationships(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.spiritual:
        this.props.updateClientMentalSpiritual(id, params);
        break;
      case CLIENT_MENTAL_FORM_TYPES.treatmentHistory:
        this.props.updateClientMentalTreatmentHistory(id, params);
        break;
      default:
        break;
    }
  };

  render() {
    const { defaultTabKey } = this.state;
    const links = [];

    const {
      additionalInformation,
      chemicalUseHistory,
      demographics,
      development,
      education,
      employment,
      familyInformation,
      legal,
      leisure,
      medicalHealth,
      medicalHistory,
      military,
      nutrition,
      primaryReasonsForSeekingServices,
      socialRelationships,
      spiritual,
      treatmentHistory,
    } = this.props.mental;

    const {
      clientID,
      client,
      updatingForm,
      isWriteAdditionalInformationEnabled,
      isReadAdditionalInformationEnabled,
      isWriteChemicalUseHistoryEnabled,
      isReadChemicalUseHistoryEnabled,
      isWriteDemographicsEnabled,
      isReadDemographicsEnabled,
      isWriteDevelopmentEnabled,
      isReadDevelopmentEnabled,
      isWriteEducationEnabled,
      isReadEducationEnabled,
      isWriteEmploymentEnabled,
      isReadEmploymentEnabled,
      isWriteFamilyInformationEnabled,
      isReadFamilyInformationEnabled,
      isWriteLegalEnabled,
      isReadLegalEnabled,
      isWriteLeisureEnabled,
      isReadLeisureEnabled,
      isWriteMedicalHealthEnabled,
      isReadMedicalHealthEnabled,
      isWriteMedicalHistoryEnabled,
      isReadMedicalHistoryEnabled,
      isWriteMilitaryEnabled,
      isReadMilitaryEnabled,
      isWriteNutritionEnabled,
      isReadNutritionEnabled,
      isWritePrimaryReasonsForSeekingServicesEnabled,
      isReadPrimaryReasonsForSeekingServicesEnabled,
      isWriteSocialRelationshipsEnabled,
      isReadSocialRelationshipsEnabled,
      isWriteSpiritualEnabled,
      isReadSpiritualEnabled,
      isWriteTreatmentHistoryEnabled,
      isReadTreatmentHistoryEnabled,
    } = this.props;

    for (const [key, value] of Object.entries(PATIENT_MENTAL_TABS)) {
      links.push({ id: key, value: value });
    }

    return (
      <>
        <Row className="mr-1">
          <ScrollingNavigation
            path={`/clients/${clientID}/mentalhealth`}
            links={links}
            onSelected={() => {}}
            active={defaultTabKey}
          />
        </Row>
        {defaultTabKey === PATIENT_MENTAL_TABS.DEMOGRAPHICS && (
          <Demographics
            clientID={clientID}
            data={{ ...demographics, ...client }}
            
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadDemographicsEnabled}
            write={isWriteDemographicsEnabled}
          />
        )}
        {defaultTabKey ===
          PATIENT_MENTAL_TABS.PRIMARY_REASONS_FOR_SEEKING_SERVICES && (
          <PrimaryReasonsForSeekingServices
            clientID={clientID}
            data={primaryReasonsForSeekingServices}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadPrimaryReasonsForSeekingServicesEnabled}
            write={isWritePrimaryReasonsForSeekingServicesEnabled}
          />
        )}
        {defaultTabKey ===
          PATIENT_MENTAL_TABS.FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY && (
          <FamilyInformation
            clientID={clientID}
            data={familyInformation}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadFamilyInformationEnabled}
            write={isWriteFamilyInformationEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.DEVELOPMENT && (
          <Development
            clientID={clientID}
            data={development}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadDevelopmentEnabled}
            write={isWriteDevelopmentEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.SOCIAL_RELATIONSHIPS && (
          <SocialRelationships
            clientID={clientID}
            data={socialRelationships}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadSocialRelationshipsEnabled}
            write={isWriteSocialRelationshipsEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.EDUCATION && (
          <Education
            clientID={clientID}
            data={education}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEducationEnabled}
            write={isWriteEducationEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.SPIRITUAL_RELIGION && (
          <SpiritualReligion
            clientID={clientID}
            data={spiritual}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadSpiritualEnabled}
            write={isWriteSpiritualEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.LEGAL && (
          <Legal
            clientID={clientID}
            data={legal}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadLegalEnabled}
            write={isWriteLegalEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.EMPLOYMENT && (
          <Employment
            clientID={clientID}
            data={employment}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEmploymentEnabled}
            write={isWriteEmploymentEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.MILITARY && (
          <Military
            clientID={clientID}
            data={military}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadMilitaryEnabled}
            write={isWriteMilitaryEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.LEISURE_AND_RECREATIONAL && (
          <LeisureAndRecreational
            clientID={clientID}
            data={leisure}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadLeisureEnabled}
            write={isWriteLeisureEnabled}
          />
        )}
        {defaultTabKey ===
          PATIENT_MENTAL_TABS.COUNSELING_AND_PRIOR_TREATMENT_HISTORY && (
          <PriorTreatmentHistory
            clientID={clientID}
            data={treatmentHistory}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadTreatmentHistoryEnabled}
            write={isWriteTreatmentHistoryEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.MEDICAL_AND_PHYSICAL_HEALTH && (
          <PhysicalAndMedicalHealth
            clientID={clientID}
            data={medicalHealth}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadMedicalHealthEnabled}
            write={isWriteMedicalHealthEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.MEDICAL_HISTORY && (
          <MedicalHistory
            clientID={clientID}
            data={medicalHistory}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadMedicalHistoryEnabled}
            write={isWriteMedicalHistoryEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.NUTRITION && (
          <Nutrition
            clientID={clientID}
            data={nutrition}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadNutritionEnabled}
            write={isWriteNutritionEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.CHEMICAL_USE_HISTORY && (
          <ChemicalUseHistory
            clientID={clientID}
            data={chemicalUseHistory}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadChemicalUseHistoryEnabled}
            write={isWriteChemicalUseHistoryEnabled}
          />
        )}
        {defaultTabKey === PATIENT_MENTAL_TABS.ADDITIONAL_INFORMATION && (
          <AdditionalInformation
            clientID={clientID}
            data={additionalInformation}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadAdditionalInformationEnabled}
            write={isWriteAdditionalInformationEnabled}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatingForm: state.client.updatingForm,
    client: state.client.client,

    isWriteAdditionalInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_ADDITIONAL_INFORMATION
    ),
    isReadAdditionalInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_ADDITIONAL_INFORMATION
    ),

    isWriteChemicalUseHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_CHEMICAL_USE_HISTORY
    ),
    isReadChemicalUseHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_CHEMICAL_USE_HISTORY
    ),

    isWriteDemographicsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DEMOGRAPHICS
    ),
    isReadDemographicsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DEMOGRAPHICS
    ),

    isWriteDevelopmentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DEVELOPMENT
    ),
    isReadDevelopmentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DEVELOPMENT
    ),

    isWriteEducationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_EDUCATION
    ),
    isReadEducationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_EDUCATION
    ),

    isWriteEmploymentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_EMPLOYMENT
    ),
    isReadEmploymentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_EMPLOYMENT
    ),

    isWriteFamilyInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY
    ),
    isReadFamilyInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY
    ),

    isWriteLegalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_LEGAL
    ),
    isReadLegalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_LEGAL
    ),

    isWriteLeisureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_LEISURE_AND_RECREATIONAL
    ),
    isReadLeisureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_LEISURE_AND_RECREATIONAL
    ),


    isWriteMedicalHealthEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MEDICAL_AND_PHYSICAL_HEALTH
    ),
    isReadMedicalHealthEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MEDICAL_AND_PHYSICAL_HEALTH
    ),

    isWriteMedicalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MEDICAL_HISTORY
    ),
    isReadMedicalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MEDICAL_HISTORY
    ),

    isWriteMilitaryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MILITARY
    ),
    isReadMilitaryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MILITARY
    ),

    isWriteNutritionEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_NUTRITION
    ),
    isReadNutritionEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_NUTRITION
    ),

    isWritePrimaryReasonsForSeekingServicesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES
    ),
    isReadPrimaryReasonsForSeekingServicesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES
    ),

    isWriteSocialRelationshipsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_SOCIAL_RELATIONSHIPS
    ),
    isReadSocialRelationshipsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_SOCIAL_RELATIONSHIPS
    ),
    isWriteSpiritualEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_SPIRITUAL_RELIGION
    ),
    isReadSpiritualEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_SPIRITUAL_RELIGION
    ),
    isWriteTreatmentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY
    ),
    isReadTreatmentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFormInit: () => dispatch(ClientActions.updateForm()),
    updateClientMentalAdditionalInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientMentalAdditionalInformation(id, params)
      ),
    updateClientMentalChemicalUseHistory: (id, params) =>
      dispatch(ClientActions.updateClientMentalChemicalUseHistory(id, params)),
    updateClientMentalDemographics: (id, params) =>
      dispatch(ClientActions.updateClientMentalDemographics(id, params)),
    updateClientMentalDevelopment: (id, params) =>
      dispatch(ClientActions.updateClientMentalDevelopment(id, params)),
    updateClientMentalEducation: (id, params) =>
      dispatch(ClientActions.updateClientMentalEducation(id, params)),
    updateClientMentalEmployment: (id, params) =>
      dispatch(ClientActions.updateClientMentalEmployment(id, params)),
    updateClientMentalFamilyInformation: (id, params) =>
      dispatch(ClientActions.updateClientMentalFamilyInformation(id, params)),
    updateClientMentalLegal: (id, params) =>
      dispatch(ClientActions.updateClientMentalLegal(id, params)),
    updateClientMentalLeisure: (id, params) =>
      dispatch(ClientActions.updateClientMentalLeisure(id, params)),
    updateClientMentalMedicalHealth: (id, params) =>
      dispatch(ClientActions.updateClientMentalMedicalHealth(id, params)),
    updateClientMentalMedicalHistory: (id, params) =>
      dispatch(ClientActions.updateClientMentalMedicalHistory(id, params)),
    updateClientMentalMilitary: (id, params) =>
      dispatch(ClientActions.updateClientMentalMilitary(id, params)),
    updateClientMentalNutrition: (id, params) =>
      dispatch(ClientActions.updateClientMentalNutrition(id, params)),
    updateClientMentalPrimaryReasonsForSeekingServices: (id, params) =>
      dispatch(
        ClientActions.updateClientMentalPrimaryReasonsForSeekingServices(
          id,
          params
        )
      ),
    updateClientMentalSocialRelationships: (id, params) =>
      dispatch(ClientActions.updateClientMentalSocialRelationships(id, params)),
    updateClientMentalSpiritual: (id, params) =>
      dispatch(ClientActions.updateClientMentalSpiritual(id, params)),
    updateClientMentalTreatmentHistory: (id, params) =>
      dispatch(ClientActions.updateClientMentalTreatmentHistory(id, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientMentalHealth);
