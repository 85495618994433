
import React from 'react';
import { Modal, Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux'
import { TextInput } from 'components/Fields/TextInput'
import PatientList from 'components/PatientList'
import LoadingView from 'components/LoadingView'
import ClientActions from 'redux/ClientRedux'
import { LightGrayLabel } from 'styles/GenericStyles';
import { PAGE_SIZE } from 'config/CONSTANTS'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'

export class AdvancedSearchModal extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      searchQuery: {
        lastName: props.searchQuery 
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (obj) => {
    Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});
    this.setState({
      searchQuery: obj
    }, () => {
      this.props.searchClient(obj, 1)
    }) 
  }

  onClose = () => {}

  onSearch = () => {

  }

  renderLoading = () => {
    return <LoadingView />
  }

  componentDidMount () {
    this.state.searchQuery && this.props.searchClient(this.state.searchQuery, 1)
  }


  getPaginatedClients = (pageNumber) => {
    this.props.searchClient(this.state.searchQuery, pageNumber)
  }

  renderSearchResults = () => {
    const { clients, totalSearchResults, currentSearchPage, sortKey, orderBy } = this.props
    return (
      <>
        <hr />
        <LightGrayLabel className='ml-4'>{`${totalSearchResults} results`} </LightGrayLabel>
        <Row className='ml-0 mr-0'>
          <PatientList
            showActionsControl={false}
            clients={clients}
            currentpage={currentSearchPage}
            sortKey={sortKey}
            orderBy={orderBy}
            totalPages={Math.ceil(totalSearchResults / PAGE_SIZE)}
            getClients={this.getPaginatedClients}
          />
        </Row>
      </>
    )
  }

  renderDisplayMessage = () => {
    return (
      <>
        <hr />
        <LightGrayLabel className='m-3 p-5 d-flex justify-content-center'>
          Search Results list is empty
        </LightGrayLabel>
      </>
    )
  }

  render () {
    const { onClose, onAddClient, clients, loading, searchQuery, isAddClientEnabled } = this.props
    return (
      <Modal animation={false} show={true} size={'xl'} centered onHide={onClose}>
        <Modal.Header closeButton className="p-3">
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ firstName: undefined, lastName: searchQuery, dob: undefined, phoneNumber: undefined, clientShortId: undefined }}
            validationSchema={Yup.object({
              email: Yup.string().email('Invalid email format')
            })}
            onSubmit={this.handleSubmit}
          >
            <Form>
              <Row className='p-3'>
                <Col><TextInput className='mr-1' name="firstName" type='text' placeholder="First Name" /></Col>
                <Col><TextInput className='mr-1' name="lastName" type='text' placeholder="Last Name" /></Col>
                <Col><TextInput className='mr-1' name="phoneNumber" type='text' placeholder="Phone" /></Col>
                <Col><TextInput className='mr-1' name='clientShortId' type='text' placeholder='Client ID' /></Col>
                <Col><TextInput className='mr-1' type='date' name="dob" placeholder="MM/DD/YYYY" /></Col>
              </Row>
              <Row className='justify-content-end mr-3'>
                <Button variant="outline-secondary" onClick={onClose}>Clear</Button>
                <Button variant="primary" className='ml-3' type='submit'>Search</Button>
              </Row>
            </Form>
          </Formik>

          { isAddClientEnabled && <Row className='justify-content-center mr-3 text-primary' onClick={onAddClient}>+ Add Client</Row> }

          { loading && this.renderLoading() }
          { !loading && clients && clients.length > 0 && this.renderSearchResults() }
          { !loading && clients && clients.length === 0 && this.renderDisplayMessage() }

        </Modal.Body>
      </Modal>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    loading: state.client.searchingClient,
    clients: state.client.searchResults,
    totalSearchResults: state.client.totalSearchResults,
    currentSearchPage: state.client.currentSearchPage,
    sortKey: state.client.sortKey,
    orderBy: state.client.orderBy,
    isAddClientEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_CLIENT)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchClient: (query, pageNumber) => dispatch(ClientActions.searchClient(query, pageNumber))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchModal);
