import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class SocialRealtionships extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Social Realtionships:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Social Realtionships:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Col className="col-12 p-0">
            <LightGrayLabel>Please select all that apply:</LightGrayLabel>
          </Col>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Affectionate</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isAffectionate)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Aggressive</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isAggressive)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Avoidant</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isAvoidant)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Leader</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isLeader)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Follower</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isFollower)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Friendly</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isFriendly)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Fight/Argue often</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.willFightOrArgueOften)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Outgoing</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isOutgoing)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Shy/withdrawn</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isShyOrWithdrawn)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Submissive</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isSubmissive)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Other</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.isOther}</label>
              </Col>
            </Row>
          </Row>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 p-0">
            <Row className="ml-1">
              <Col className="col-12">
                <LightGrayLabel>Sexual dysfunction:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasSexualDysfunction)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>If Yes, Describe</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.sexualDysfunctionDescription}</label>
              </Col>
            </Row>
          </Row>
          <Row className="p-0  pl-1">
            <Col className="col-12">
              <LightGrayLabel>
                Any current/history of being a sexual perpetrator?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.isSexualPerpetrator)}</label>
            </Col>
          </Row>
          <Row className="p-0  pl-1">
            <Col className="col-12">
              <LightGrayLabel>
              If Yes, Describe
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.sexualPerpetratorDescription}</label>
            </Col>
          </Row>
      
          <hr />
          <Row className='mb-3'>
                    <Col className='p-0'>
                      <ViewHeading>CULTURAL/ETHNIC</ViewHeading>
                    </Col>
                  </Row>
                  <Row className="p-0  pl-1">
            <Col className="col-12">
              <LightGrayLabel>
              Are you experiencing any problems due to cultural or ethnic issues?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.hasCulturalOrEthnicIssues)}</label>
            </Col>
          </Row>
          <Row className="p-0  pl-1">
            <Col className="col-12">
              <LightGrayLabel>
              If Yes, Describe
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.culturalOrEthnicIssuesDescription}</label>
            </Col>
          </Row>
          <Row className="p-0  pl-1">
            <Col className="col-12">
              <LightGrayLabel>
              Other Cultural/Ethnic Information:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.otherCulturalOrEthnicInformation}</label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
