import React from 'react';
import Layout from 'components/Layout.jsx'
import AddClientModal from 'components/Modals/AddClientModal'
import { Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux'
import { TextInput } from 'components/Fields/TextInput'
import { Select } from 'components/Fields/Select'
import { FormHeader } from '../../styles/GenericStyles'
import CalendarStyle from "styles/CalendarStyles"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Formik, Form } from 'formik';
import AppointmentActions from 'redux/AppointmentRedux'
import moment from 'moment';
import LoadingView from 'components/LoadingView.jsx'
import BaseForm from 'components/Patient/BaseForm'
import { MenuContainer } from "pages/Styles/AppointmentsStyles"
import ClientActions from 'redux/ClientRedux'
import RoleActions from 'redux/RoleRedux'
import AccountActions from 'redux/AccountRedux'

export class Appointments extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      client: null,
      selectedDate: moment(),
      selectedSlot: null,
      showAutoSuggestion: false,
      roleSelected: null,
      selectedAccount: null
    }
  }

  componentDidMount () {
    this.props.getAccountAppointment(this.props.match.params.id);
    this.props.getRoles(1)
    this.props.getAccounts(1)
  }

  componentWillReceiveProps (newProps) {
    if ((this.props.accountAppointment && this.props.accountAppointment.appointmentId) !== (newProps.accountAppointment && newProps.accountAppointment.appointmentId)) {
      this.setState({
        client: newProps.accountAppointment.client,
        selectedDate: moment(newProps.accountAppointment.startTime),
        selectedAccount: newProps.accountAppointment.accountUserId
      })
    }
  }

  handleSubmit = (values) => {
    const { selectedDate, selectedSlot, selectedAccount } = this.state;
    const { accountAppointment } = this.props;

    var startTime = moment(selectedDate).set({
      "hour": selectedSlot.split(':')[0],
      "minute": selectedSlot.split(':')[1],
      "second": 0,
      "millisecond": 0
    }).toISOString();

    var endTime = moment(startTime).add(30, 'minutes').toISOString();

    this.props.updateAppointment(accountAppointment.appointmentId, {
      "accountUserId": selectedAccount,
      "appointmentType": values.documentType,
      "clientId": this.state.client && this.state.client.clientId,
      "endTime": endTime,
      "startTime": startTime
    })
  }

  onSearch = (event) => {
    this.setState({
      searchString: event.target.value
    });
  }

  onSelect = (client) => {
    this.setState({
      client: client,
      showAutoSuggestion: false
    });
  }

  onChange = (value) => {
    this.setState({
      selectedDate: moment(value)
    }, () => {
      const startTime = moment(value).startOf('day').toISOString()
      const endTime = moment(value).endOf('day').toISOString()
      const { getAccountAppointments, getClientAppointments } = this.props
      getAccountAppointments(this.state.selectedAccount, startTime, endTime)
      getClientAppointments(this.state.client && this.state.client.clientId, startTime, endTime)
    })
  }

  onChangeUser = (e) => {
    this.setState({
      selectedAccount: e.target.value
    }, () => {
      const { selectedDate } = this.state
      const startTime = selectedDate.startOf('day').toISOString()
      const endTime = selectedDate.endOf('day').toISOString()
      const { getAccountAppointments, getClientAppointments } = this.props
      getAccountAppointments(e.target.value, startTime, endTime)
      getClientAppointments(this.state.client && this.state.client.clientId, startTime, endTime)
    })
  }

  onChangeClientId = (e) => {
    const val = e.target.value
    if (!val) {
      this.setState({ showAutoSuggestion: false })
      return null
    }
    this.setState({
      showAutoSuggestion: true
    }, () => {
      this.props.searchClient({ lastName: e.target.value }, 1)
    })
  }

  
  toggleAddClientModal = () => {
    const { showAddClientModal } = this.state;
    this.setState({
      showAddClientModal: !showAddClientModal
    })
  }

  onCreate = (params) => {
    this.props.createClient(params)
    this.toggleAddClientModal()
  }

  renderLoading = () => {
    return <LoadingView />
  }

  handleSlot = (e, slot) => {
    this.setState({
      selectedSlot: slot
    })
  }

  renderNoResults = () => {
    return (
      <Row className='p-2 border-bottom mr-0 ml-0 flex-column'>
        <div>No results.</div>
      </Row>
    )
  }

  calculateSlots = (appointments) => {
    return appointments && appointments.map((cAppt) => moment(cAppt.startTime).format('HH:mm'))
  }

  renderTimeSlots = () => {
    const { selectedDate } = this.state
    const { accountAppointmentsFetching, clientAppointmentsFetching, accountAppointments, clientAppointments, fetchingAccounts, fetchingRoles } = this.props;
    
    if (accountAppointmentsFetching || clientAppointmentsFetching || fetchingAccounts || fetchingRoles ) {
      return this.renderLoading()
    }

    let slots = [];
    let slotInterval = 30;
    let currentTime = moment.now()

    let startTime = moment({
      year :selectedDate.year(),
      month : selectedDate.month(),
      day : selectedDate.date(),
      hour : '09',
      minute :'00'
    });

    let endTime = moment({
      year: selectedDate.year(),
      month: selectedDate.month(),
      day: selectedDate.date(),
      hour: '17',
      minute: '00'
    });

    while (startTime < endTime) {
      if (startTime > currentTime) {
        slots.push(startTime.format("HH:mm"));
      }
      startTime.add(slotInterval, 'minutes');
    }
    
    const aSlots = this.calculateSlots(accountAppointments);
    const cSlots = this.calculateSlots(clientAppointments);
    let bookedSlots = [...new Set([...aSlots,...cSlots])]
    const availableSlots = slots.filter((s) => !bookedSlots.includes(s))
  
    return (
      <div className='d-flex flex-column mt-4 slotsDiv'>
        {
          availableSlots.map((slot, index) => <Button
            key={index}
            variant="outline-primary"
            className={slot === this.state.selectedSlot ? 'mb-2 p-2 selected-slot' : 'mb-2 p-2'}
            onClick={(e) => this.handleSlot(e, slot)}>{moment(slot, 'hh:mm').format('LT')}
          </Button>)
        }
      </div>
    )
  }

  renderForm = () => {
    let { selectedDate, showAutoSuggestion, roleSelected, selectedAccount } = this.state
    const { searchResults, searchingClient, accountAppointment, updatingForm, accounts, roles } = this.props;
    const selectedAccountRoles = accounts && accounts.find((account) => account.accountUserId === selectedAccount)?.roles
    const selectedRole = selectedAccountRoles && roles && roles.find((role) => role.accountRoleId === (selectedAccountRoles && selectedAccountRoles[0]))

    let accountList

    const roleNameList = roles && roles.map((role) => {
      return <option value={role.accountRoleId} label={role.roleName} selected={selectedRole?.accountRoleId === role.accountRoleId} />
    })

    const roleAccounts = roleSelected && accounts && accounts.filter((account) => account.roles.includes(roleSelected))

    if (roleSelected){
      accountList = roleAccounts && roleAccounts.map((account) => {
        return <option value={account.accountUserId} label={account.firstName + ' ' + account.lastName} selected={selectedAccount === account.accountUserId} />
      })
    } else {
      accountList = accounts && accounts.map((account) => {
        return <option value={account.accountUserId} label={account.firstName + ' ' + account.lastName} selected={selectedAccount === account.accountUserId} />
      })
    }
  
    return (
      <Formik
        initialValues={{ 'documentType': (accountAppointment && accountAppointment.appointmentType) }}
        onSubmit={this.handleSubmit}>
          <Form>
            <BaseForm updatingForm={updatingForm}>
              <Row className='w-100'>
                <Col className='col-xs-12 col-md-4'>
                  <FormHeader className='mb-3'>CLIENT INFO</FormHeader>
                  <div className='d-flex flex-column'>
                    <TextInput
                      name='client'
                      type='text'
                      label='Select Client'
                      labelClassName='input-label mb-2'
                      placeholder='Search Client by lastName'
                      autoComplete='off'
                      onChange={this.onChangeClientId}
                      value={this.state.client && (this.state.client.firstName + ' ' + this.state.client.lastName)}
                    />
                   <MenuContainer show={showAutoSuggestion}>
                      { searchingClient && this.renderLoading() }
                      { !searchingClient && searchResults && searchResults.length === 0 && this.renderNoResults() }
                      { !searchingClient && searchResults && searchResults.length > 0 && searchResults.map((client, index) => {
                         return (
                          <Row className='p-2 border-bottom mr-0 ml-0 flex-column' onClick={() => this.onSelect(client)}>
                            <div>{`${client.firstName} ${client.lastName}`}</div>
                            <div>{client.email}</div>
                          </Row>
                        )
                      }) }
                   </MenuContainer>
                    <Button
                      onClick={this.toggleAddClientModal}
                      className='font-weight-bold ml-auto pr-0 mb-3 pt-0'
                      style={{boxShadow: 'none'}}
                      variant='link'>+ Add Client
                    </Button>
                  </div>

                  <Row className='ml-0 mr-0'>
                    <Select
                      name="role"
                      className='custom-select mr-3'
                      label='Provider Type'
                      labelClassName='input-label mb-2'
                      onChange={(e) => this.setState({ roleSelected : e.target.value })}
                    >
                      <option label="Select" />
                      { roleNameList }
                    </Select>
                  </Row>

                  <Row className='ml-0 mr-0 mt-3'>
                    <Select
                      name="selectedAccount"
                      className='custom-select mr-3'
                      label='Providers'
                      labelClassName='input-label mb-2'
                      onChange={this.onChangeUser}
                    >
                      <option label="Select" />
                      { accountList }
                    </Select>
                  </Row>

                  <Row className='ml-0 mr-0 mt-3'>
                    <Select
                      name="documentType"
                      className='custom-select mr-3'
                      label='Appointment Type'
                      labelClassName='input-label mb-2'
                    >
                      <option label="Select" />
                      <option value="Food Assistance (SNAP)" label="Food Assistance (SNAP)" />
                      <option value="Housing - BFET" label="Housing - BFET" />
                      <option value="Family Reunification - REENTRY" label="Family Reunification - REENTRY" />
                      <option value="Job Training - BFET/DVR/REENTRY" label="Job Training - BFET/DVR/REENTRY" />
                      <option value="Job Search - BFET/DVR/REENTRY" label="Job Search - BFET/DVR/REENTRY" />
                      <option value="Job Retention - BFET/DVR" label="Job Retention - BFET/DVR" />
                      <option value="Basic Needs (Hygiene/Bus Pass/Gas Card, etc) - BFET/REENTRY" label="Basic Needs (Hygiene/Bus Pass/Gas Card, etc) - BFET/REENTRY" />
                      <option value="Life Skills Training -BFET/REENTRY" label="Life Skills Training -BFET/REENTRY" />
                      <option value="SUD - Mental Health" label="SUD - Mental Health" />
                      <option value="Anger Management - Mental Health" label="Anger Management - Mental Health" />
                      <option value="Domestic Violence - Mental Health" label="Domestic Violence - Mental Health" />
                      <option value="Civil Legal Assistance - BFET/REENTRY" label="Civil Legal Assistance - BFET/REENTRY" />
                    </Select>
                  </Row>
                </Col>

                <Col className='col-xs-12 col-md-8'>
                  <div className='calendar-div p-3'>
                    <label className='input-label mb-3'>Select a Date & Time</label>
                    <div className='d-flex justify-content-around'>
                      <Calendar
                        onChange={this.onChange}
                        view={'month'}
                        className='custom-react-calendar'
                        tileClassName='custom-tile'
                        minDate={new Date()}
                        value={new Date(selectedDate.year(), selectedDate.month(), selectedDate.date())}
                      />
                      <div className='ml-3 mt-2'>
                        <span>{moment(selectedDate).format('dddd, MMMM, D')}</span>
                        { this.renderTimeSlots() }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </BaseForm>
          </Form>
      </Formik>
    )
  }
  
  renderContent = () => {
    const { accountAppointmentFetching, creatingClient } = this.props
    return (
      <>
        <CalendarStyle />
        <Col md={12}>
          <Row>
            <h4 className='text-nowrap'>Edit Appointment</h4>
          </Row>
          <Row>
            <Col className='col-12'>
              { accountAppointmentFetching && this.renderLoading() }
              { !accountAppointmentFetching && this.renderForm() }
            </Col>
          </Row>
        </Col>
        { this.state.showAddClientModal && <AddClientModal onClose={this.toggleAddClientModal} createClient={this.onCreate} creatingClient={creatingClient} />}
      </>
    );
  }

  render = () => {
    const { fetchingAccounts, fetchingRoles } = this.props
    return (
      <Layout>
        { (fetchingAccounts || fetchingRoles) && this.renderLoading() }
        { (!fetchingAccounts && !fetchingRoles) && this.renderContent() }
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingAccounts: state.account.fetchingAccounts,
    accounts: state.account.accounts,
    fetchingRoles: state.role.fetchingRoles,
    roles: state.role.roles,
    accountAppointment: state.appointment.accountAppointment,
    accountId: state.user.accountUserId,
    accountAppointmentsFetching: state.appointment.accountAppointmentsFetching,
    accountAppointmentFetching: state.appointment.accountAppointmentFetching,
    clientAppointmentsFetching: state.appointment.clientAppointmentsFetching,
    accountAppointments: state.appointment.accountAppointments,
    clientAppointments: state.appointment.clientAppointments,
    searchResults: state.client.searchResults,
    searchingClient: state.client.searchingClient,
    updatingForm: state.appointment.updatingForm,
    creatingClient: state.client.creatingClient
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (pageNumber) => dispatch(RoleActions.getRoles(pageNumber)),
    getAccounts: (pageNumber) => dispatch(AccountActions.getAccounts(pageNumber)),
    getAccountAppointment: (appointmentId) => dispatch(AppointmentActions.getAccountAppointment(appointmentId)),
    getAccountAppointments: (accountId, to, from) => dispatch(AppointmentActions.getAccountAppointments(accountId, to, from)),
    getClientAppointments: (clientId, to, from) => dispatch(AppointmentActions.getClientAppointments(clientId, to, from)),
    createAppointment: (params) => dispatch(AppointmentActions.createAppointment(params)),
    updateAppointment: (appointmentId, params) => dispatch(AppointmentActions.updateAppointment(appointmentId, params)),
    searchClient: (query, pageNumber) => dispatch(ClientActions.searchClient(query, pageNumber)),
    createClient: (params) => dispatch(ClientActions.createClient(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);