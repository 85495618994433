import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Military extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Military:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Military:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row>
            <Col className="p-0 col-6 col-xs-12 col-sm-12 col-md-6">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Military experience?</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.hasMilitaryExperience)}</label>
                </Col>
              </Col>
            </Col>
            <Col className="p-0 col-6 col-xs-12 col-sm-12 col-md-6">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Combat experience?</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.hasCombotExperience)}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <ViewHeading> Branch</ViewHeading>
            </Col>
            <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-2 row-cols-lg-4">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Army</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isArmyBranch)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Navy</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isNavyBranch)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Marines</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isMarinesBranch)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Air Force</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isAirForceBranch)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Coast Guard</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isCoastGaurdBranch)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Space Force</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isSpaceForceBranch)}</label>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-1 row-cols-lg-4">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Where?</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.militaryLocation}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0 ">
              <Col className="col-12">
                <LightGrayLabel>Date Entered</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.militaryStartDate}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Date Discharged</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.militaryDischargedDate}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Highest Rank Held</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.highestRankHeld}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Rank At Discharge</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.rankAtDischarge}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Type Of Discharge</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.typeOfDischarge}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
