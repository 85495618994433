import styled from "styled-components";

export const PTag = styled.p`
  color: ${(props) => props.theme.textColor};
`;

export const ErrorMsg = styled.div`
  color: ${(props) => props.theme.dangerColor};
`;

export const LabelWithin = styled.label`
  color: ${(props) => props.theme.labelColor};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "white")};
  position: absolute;
  top: -12px;
  left: 12px;
  padding-left: 6px;
  padding-right: 6px;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.labelColor};
  margin-right: 15px;
  align-self: center;
  white-space: nowrap;
  margin-bottom: 0px;
`;

export const LightGrayLabel = styled(Label)`
  color: #bababa;
  white-space: pre-wrap;
  font-size: 16px;
`;

export const ViewHeading = styled(Label)`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: block;
  white-space: pre-wrap;

`;

export const LinkTag = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.secondaryBackgroundColor};
`;
export const FormHeader = styled.h3`
  letter-spacing: 0.4px;
  color: #111111;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 30px;
`;
export const Acknowlegdement = styled.p`
  letter-spacing: -0.14px;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 22px;
`;
export const GuideLine = styled.p`
  letter-spacing: 0px;
  color: #333333;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 600;
  line-height: 17px;
`;
export const GuideLine2 = styled.p`
  letter-spacing: 0px;
  color: #9b9b9b;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
`;

export const NoticeHeader = styled.h5`
  letter-spacing: -0.14px;
  color: #333333;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
`;

export const FileDropContainer = styled.div`
  width: 174px;
  height: 218px;
  margin: 5px;
  background: #f2f4f8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p:first-of-type {
    padding-top: 24px;
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    color: #606060;
    margin-bottom: 0;
  }
  .browseText {
    color: #3b86ff;
  }
`;

export const Preview = styled.div`
  position: relative;
  margin: 5px;
  width: 174px;
  height: 218px;
  background: #f2f4f8 0% 0% no-repeat padding-box;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
  }
`;
export const PreviewFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 0.14px;
  color: #747474;
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
  .delete-icon {
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    padding-bottom: 2px;
    text-align: center;
    :hover {
      background-color: #f2f2f2;
      border-radius: 50%;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
`
