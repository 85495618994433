import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  getClients: ['pageNumber', 'sortKey', 'orderBy'],
  getClientsSuccess: ['clients', 'pageNumber', 'sortKey', 'orderBy'],
  getClientsFailure: null,

  getClient: ['id'],
  getClientSuccess: ['client'],
  getClientFailure: null,

  createClient: ['params'],
  createClientSuccess: ['client'],
  createClientFailure: null,

  updateClient: ['id', 'params'],
  updateClientSuccess: ['client'],
  updateClientFailure: null,

  deleteClient: ['id'],
  deleteClientSuccess: null,
  deleteClientFailure: null,

  searchClient: ['params', 'pageNumber'],
  searchClientSuccess: ['clients', 'pageNumber'],
  searchClientFailure: null,

  getClientReentryIntakeInformation: ['id'],
  getClientReentryIntakeInformationSuccess: ['data'],

  getClientBfetInformation: ['id'],
  getClientBfetInformationSuccess: ['data'],

  getClientMentalInformation: ['id'],
  getClientMentalInformationSuccess: ['data'],

  getClientConsentInformation: ['id'],
  getClientConsentInformationSuccess: ['data'],

  getClientDVRServiceDeliveryInformation: ['id'],
  getClientDVRServiceDeliveryInformationSuccess: ['data'],
  getClientDVRServiceDeliveryInformationFailure: null,

  getClientDVRVocationalInformation: ['id'],
  getClientDVRVocationalInformationSuccess: ['data'],
  getClientDVRVocationalInformationFailure: null,


  getClientVisitorNotesInformation: ['id', 'category'],
  getClientVisitorNotesInformationSuccess: ['category', 'data'],
  postClientVisitorNotesInformation: ['id', 'category', 'params'],
  postClientVisitorNotesInformationSuccess: ['category', 'data'],
  updateClientVisitorNotesInformation: ['id', 'noteId', 'category', 'params'],
  updateClientVisitorNotesInformationSuccess: ['category', 'noteId', 'data'],

  getClientDocumentsInformation: ['id', 'category'],
  getClientDocumentsInformationSuccess: ['category', 'data'],
  postClientDocumentsInformation: ['id', 'category', 'params'],
  uploadDocument: ['id', 'category', 'params', 'data'],
  postClientDocumentsInformationSuccess: ['category', 'data'],
  deleteClientDocumentsInformation: ['id', 'docId', 'category', 'params'],
  deleteClientDocumentsInformationSuccess: ['category', 'docId', 'data'],

  deleteClientVisitorNotesInformation: ['id', 'noteId', 'category'],
  deleteClientVisitorNotesInformationSuccess: ['noteId', 'category', 'data'],

  getClientSuppliesInformation: ['id', 'category', 'params'],
  getClientSuppliesInformationSuccess: ['category', 'data'],
  postClientSuppliesInformation: ['id', 'category', 'params'],
  postClientSuppliesInformationSuccess: ['category', 'data'],
  updateClientSuppliesInformation: ['id', 'supplyId', 'category', 'params'],
  updateClientSuppliesInformationSuccess: ['category', 'data'],

  postClientPicture: ['id', 'params'],
  uploadClientPicture: ['id', 'params', 'uploadURL'],
  postClientPictureSuccess: ['id'],
  postClientPictureSuccessCallbackComplete: ['id'],
  postClientPictureSuccessCallbackCompleteDelay: null,

  deleteClientPicture: ['id', 'params'],
  deleteClientPictureSuccess: ['data'],

  updateForm: null,
  updateFormSuccess: ['category', 'data'],
  updateMentalFormSuccess: ['category', 'data'],
  updateBfetFormSuccess: ['category', 'data'],
  updateFormError: null,
  // ReEntry
  updateClientReentryIntakeIdentifyingInformation: ['id', 'params'],
  updateClientReentryIntakeIncomeInformation: ['id', 'params'],
  updateClientReentryIntakeEmploymentSearchInformation: ['id', 'params'],
  updateClientReentryIntakeFamilyInformation: ['id', 'params'],
  updateClientReentryIntakeHousingInformation: ['id', 'params'],
  updateClientReentryIntakeImmediateNeedsInformation: ['id', 'params'],
  updateClientReentryIntakeIncarcerationInformation: ['id', 'params'],
  updateClientReentryIntakeLegalHistoryInformation: ['id', 'params'],
  updateClientReentryIntakePersonalHealthInformation: ['id', 'params'],
  updateClientReentryIntakeSkillsAndAbilitiesInformation: ['id', 'params'],
  updateClientReentryIntakeTransportationInformation: ['id', 'params'],
  // BFET
  updateClientBfetEmploymentGoalsInformation: ['id', 'params'],
  updateClientBfetEmploymentHistoryInformation:  ['id', 'params'],
  updateClientBfetEmploymentPlanInformation:  ['id', 'params'],
  updateClientBfetAssessedEmploymentBarriersInformation:  ['id', 'params'],
  updateClientBfetDeclarationAndSignatureInformation:  ['id', 'params'],
  updateClientBfetSkillsAndQualificationInformation:  ['id', 'params'],

  getClientBfetJobSearchInformation:  ['id'],
  getClientBfetJobSearchInformationSuccess: ['data'],
  getClientBfetJobSearchEmployersInformation: ['id'],
  getClientBfetJobSearchEmployersInformationSuccess: ['data'],
  updateClientBfetJobSearchInformation:  ['id', 'params'],
  updateClientBfetJobSearchInformationSuccess: ['data'],
  postClientBfetJobSearchEmployerInformation:  ['id', 'params'],
  postClientBfetJobSearchEmployerInformationSuccess: ['id', 'employerId', 'params', 'data'],

  updateClientBfetJobSearchEmployerInformation: ['id', 'employerId', 'params'],
  updateClientBfetJobSearchEmployerInformationSuccess: ['id', 'employerId', 'params', 'data'],
  updateBfetJobSearchEmployerInformationSuccess: ['employerId', 'data'],

  deleteClientBfetJobSearchEmployerInformation: ['id', 'employerId'],
  deleteClientBfetJobSearchEmployerInformationSuccess: ['employerId'],

  uploadApplicationForm: ['id', 'employerId', 'params'],

  // Mental
  updateClientMentalAdditionalInformation: ['id', 'params'],
  updateClientMentalChemicalUseHistory: ['id', 'params'],
  updateClientMentalDemographics: ['id', 'params'],
  updateClientMentalDevelopment: ['id', 'params'],
  updateClientMentalEducation: ['id', 'params'],
  updateClientMentalEmployment: ['id', 'params'],
  updateClientMentalFamilyInformation: ['id', 'params'],
  updateClientMentalLegal: ['id', 'params'],
  updateClientMentalLegalSuccess: ['id', 'params', 'data'],
  uploadFile: ['uploadURL', 'params'],
  uploadFileSuccess: ['params'],
  uploadDocumentFileSuccess: ['params'],
  uploadLegalExemptionForm: ['id', 'params'],
  updateClientMentalLeisure: ['id', 'params'],
  updateClientMentalMedicalHealth: ['id', 'params'],
  updateClientMentalMedicalHistory: ['id', 'params'],
  updateClientMentalMilitary: ['id', 'params'],
  updateClientMentalNutrition: ['id', 'params'],
  updateClientMentalPrimaryReasonsForSeekingServices: ['id', 'params'],
  updateClientMentalSocialRelationships: ['id', 'params'],
  updateClientMentalSpiritual: ['id', 'params'],
  updateClientMentalTreatmentHistory: ['id', 'params'],
  // Consent
  updateClientReentryConsentInformation: ['id', 'params'],
  updateClientReentryConsentInformationSuccess: ['data'],

  // DVR
  updateClientDVRServiceDeliveryInformation: ['id', 'params'],
  updateClientDVRServiceDeliveryInformationSuccess: ['data'],

  updateClientDVRVocationalInformation: ['id', 'params'],
  updateClientDVRVocationalInformationSuccess: ['data'],

  referClient: ['id', 'params'],
  referClientSuccess: null,
  referClientFailure: null,

  getAccountClientUrl: ['clientId'],
  getAccountClientUrlSuccess: ['url'],
  getAccountClientUrlFailure: null,

  sendClientReferral: ['clientId']
})

export const ClientTypes = Types
export default Creators

export const CLIENT_FORM_TYPES = Object.freeze({
  'REENTRY': 'reentry',
  'BFET': 'bfet',
  'VISITOR_NOTES': 'visitornotes',
  'SUPPLIES': 'supplies',
  'DOCUMENTS': 'documents',

  'REENTRY_INTAKE': 'reentry/intake',
  'REENTRY_CONSENT': 'reentry/consent',
  'REENTRY_DOCUMENTS': 'reentry/documents',
  'REENTRY_NOTES': 'reentry/notes',
  'CLIENT_BFET_INTAKE': 'bfet/intake',
  'CLIENT_BFET_JOB_SEARCH': 'bfet/jobsearch',
  'CLIENT_BFET_DOCUMENTS': 'bfet/documents',
  'CLIENT_BFET_NOTES': 'bfet/notes',
  'CLIENT_BFET_SUPPLIES': 'bfet/supplies',
  'CLIENT_MENTAL_HEALTH': 'mentalHealth',
  'CLIENT_MENTAL_HEALTH_PH': 'mentalHealth/personalhistory',
  'CLIENT_MENTAL_HEALTH_DOCUMENTS': 'mentalHealth/documents',
  'CLIENT_MENTAL_HEALTH_NOTES': 'mentalHealth/notes',
  'CLIENT_DVR_SERVICE_DELIVERY': 'dvr/servicedelivery',
  'CLIENT_DVR_VOCATIONAL': 'dvr/vocational',
  'CLIENT_DVR_DOCUMENTS': 'dvr/documents',
  'CLIENT_DVR_NOTES': 'dvr/notes',
  'CLIENT_REENTRY_SUPPLIES': 'reentry/supplies',
  'CLIENT_MENTAL_HEALTH_SUPPLIES': 'mentalHealth/supplies',
  'CLIENT_DVR_SUPPLIES': 'dvr/supplies'
})

export const CLIENT_NOTES_TYPES = Object.freeze({
  'DEFAULT': 'DEFAULT',
  'REENTRY': 'REENTRY',
  'BFET': 'BFET',
  'MENTALHEALTH': 'MENTALHEALTH'
})

export const CLIENT_REENTRY_FORM_TYPES = Object.freeze({
  employmentSearchInformation: 'employmentSearchInformation',
  family: 'family',
  housing: 'housing',
  identifyingInformation: 'identifyingInformation',
  immediateNeeds: 'immediateNeeds',
  incarceration: 'incarceration',
  income: 'income',
  legalHistory: 'legalHistory',
  personalHealthInformation: 'personalHealthInformation',
  skillsAndAbilities: 'skillsAndAbilities',
  transportation: 'transportation'
})

export const CLIENT_BFET_FORM_TYPES = Object.freeze({
  assessedEmploymentBarriers: 'assessedEmploymentBarriers',
  declarationAndSignature: 'declarationAndSignature',
  employmentGoals: 'employmentGoals',
  employmentHistory: 'employmentHistory',
  employmentPlan: 'employmentPlan',
  skillsAndQualification: 'skillsAndQualification'
})

export const CLIENT_MENTAL_FORM_TYPES = Object.freeze({
  additionalInformation: 'additionalInformation',
  chemicalUseHistory: 'chemicalUseHistory',
  demographics: 'demographics',
  development: 'development',
  education: 'education',
  employment: 'employment',
  familyInformation: 'familyInformation',
  legal: 'legal',
  leisure: 'leisure',
  medicalHealth: 'medicalHealth',
  medicalHistory: 'medicalHistory',
  military: 'military',
  nutrition: 'nutrition',
  primaryReasonsForSeekingServices: 'primaryReasonsForSeekingServices',
  socialRelationships: 'socialRelationships',
  spiritual: 'spiritual',
  treatmentHistory: 'treatmentHistory'
})

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  fetchingClients: false,
  searchingClient: false,
  clients: [],
  totalCount: 0,
  currentPage: 1,
  sortKey: 'lastName',
  orderBy: 'ASC',
  currentSearchPage: 1,
  client: null,
  searchResults: [],
  totalSearchResults: 0,
  getReentryFetching: false,
  getBfetFetching: false,
  getMentalFetching: false,
  getConsentFetching: false,
  getDVRServiceDeliveryFetching: false,
  getDVRVocationalFetching: false,
  getBFETSuppliesFetching: false,
  creatingClient: false,
  updatingClient: false,
  updatingForm: false,
  updatingMentalForm: null,
  referringClientInProgress: false,
  reentry: {
    employmentSearchInformation: null,
    family: null,
    housing: null,
    identifyingInformation: null,
    immediateNeeds: null,
    incarceration: null,
    income: null,
    legalHistory: null,
    personalHealthInformation: null,
    skillsAndAbilities: null,
    transportation: null,
  },
  bfet: {
    assessedEmploymentBarriers: null,
    declarationAndSignature: null,
    employmentGoals: null,
    employmentHistory: null,
    employmentPlan: null,
    skillsAndQualification: null
  },
  mental: {
    additionalInformation: null,
    chemicalUseHistory: null,
    demographics: null,
    development: null,
    education: null,
    employment: null,
    familyInformation: null,
    legal: null,
    leisure: null,
    medicalHealth: null,
    medicalHistory: null,
    military: null,
    nutrition: null,
    primaryReasonsForSeekingServices: null,
    socialRelationships: null,
    spiritual: null,
    treatmentHistory: null
  },
  consent: null,
  dvrSeliveryDelivery: null,
  dvrVocational: null,
  bfetSupplies: null,
  notes: {
    DEFAULT: null,
    REENTRY: null,
    BFET: null,
    MENTALHEALTH: null,
    DVR: false
  },
  notesFetching: {
    DEFAULT: false,
    REENTRY: false,
    BFET: false,
    MENTALHEALTH: false,
    DVR: false
  },
  documents: {
    DEFAULT: null,
    REENTRY: null,
    BFET: null,
    MENTALHEALTH: null,
    DVR: false
  },
  documentsFetching: {
    DEFAULT: false,
    REENTRY: false,
    BFET: false,
    MENTALHEALTH: false,
    DVR: false
  },
  supplies: {
    DEFAULT: null,
    REENTRY: null,
    BFET: null,
    MENTALHEALTH: null,
    DVR: false
  },
  suppliesFetching: {
    DEFAULT: false,
    REENTRY: false,
    BFET: false,
    MENTALHEALTH: false,
    DVR: false
  },
  getBfetJobSearchFetching: false,
  getBfetJobSearchEmployersFetching: false,
  bfetJobSearch: null,
  bfetJobSearchEmployers: null,
  postingClientPicture: false,
  clientReferralUrl: null
})

/* ---------- Reducers ----------------*/
export const getClients = (state) => {
  return state.merge({
    fetchingClients: true
  })
}

export const getClientsSuccess = (state, { clients, pageNumber, sortKey, orderBy }) => {
  return state.merge({
    fetchingClients: false,
    clients: clients.clients,
    totalCount: clients.totalCount,
    currentPage: pageNumber,
    sortKey: sortKey,
    orderBy: orderBy
  })
}

export const getClientsFailure = (state) => {
  return state.merge({
    fetchingClient: false
  })
}

// Get a single client.
export const getClient = (state, { clientId }) => {
  return state.merge({
    fetchingClient: true
  })
}

export const getClientSuccess = (state, { client }) => {
  return state.merge({
    fetchingClient: false,
    client
  })
}

export const getClientFailure = (state) => {
  return state.merge({
    fetchingClient: false
  })
}

// // Search a single client.
export const searchClient = (state, { params }) => {
  return state.merge({
    searchingClient: true
  })
}

export const searchClientSuccess = (state, { clients, pageNumber }) => {
  return state.merge({
    searchingClient: false,
    searchResults: clients.clients,
    totalSearchResults: clients.totalCount,
    currentSearchPage: pageNumber
  })
}

export const searchClientFailure = (state) => {
  return state.merge({
    searchingClient: false
  })
}

export const getClientReentryIntakeInformationSuccess = (state, { data }) => {
  return state.merge({
    getReentryFetching: true,
    reentry: {
      ...state.reentry,
      employmentSearchInformation: data.employmentSearchInformation,
      family: data.family,
      housing: data.housing,
      identifyingInformation: data.identifyingInformation,
      immediateNeeds: data.immediateNeeds,
      incarceration: data.incarceration,
      income: data.income,
      legalHistory: data.legalHistory,
      personalHealthInformation: data.personalHealthInformation,
      skillsAndAbilities: data.skillsAndAbilities,
      transportation: data.transportation
    }
  })
}

export const getClientBfetInformationSuccess = (state, { data }) => {
  return state.merge({
    getBfetFetching: true,
    bfet: {
      ...state.bfet,
      assessedEmploymentBarriers: data.assessedEmploymentBarriers,
      declarationAndSignature: data.declarationAndSignature,
      employmentGoals: data.employmentGoals,
      employmentHistory: data.employmentHistory,
      employmentPlan: data.employmentPlan,
      skillsAndQualification: data.skillsAndQualification
    }
  })
}

export const getClientMentalInformationSuccess = (state, { data }) => {
  return state.merge({
    getMentalFetching: true,
    mental: {
      ...state.mental,
      additionalInformation: data.additionalInformation,
      chemicalUseHistory: data.chemicalUseHistory,
      demographics: data.demographics,
      development: data.development,
      education: data.education,
      employment: data.employment,
      familyInformation: data.familyInformation,
      legal: data.legal,
      leisure: data.leisure,
      medicalHealth: data.medicalHealth,
      medicalHistory: data.medicalHistory,
      military: data.military,
      nutrition: data.nutrition,
      primaryReasonsForSeekingServices: data.primaryReasonsForSeekingServices,
      socialRelationships: data.socialRelationships,
      spiritual: data.spiritual,
      treatmentHistory: data.treatmentHistory
    }
  })
}

const getClientBfetJobSearchInformationSuccess = (state, { data }) => {
  return state.merge({
    getBfetJobSearchFetching: true,
    bfetJobSearch: data
  })
}

const getClientBfetJobSearchEmployersInformationSuccess = (state, { data }) => {
  return state.merge({
    getBfetJobSearchEmployersFetching: true,
    bfetJobSearchEmployers: data
  })
}

const postClientBfetJobSearchEmployerInformationSuccess = (state, { id, employerId, params, data }) => {
  return state.merge({
    bfetJobSearchEmployers: [ ...state.bfetJobSearchEmployers, data ]
  })
}

const updateClientBfetJobSearchEmployerInformationSuccess = (state, { id, employerId, params, data }) => {
  const index = state.bfetJobSearchEmployers.findIndex((employer) => employer.employerId === employerId)
  return state.merge({
    bfetJobSearchEmployers: [
      ...state.bfetJobSearchEmployers.slice(0, index),
      { ...data },
      ...state.bfetJobSearchEmployers.slice(index + 1)
    ]
  })
}

const updateBfetJobSearchEmployerInformationSuccess = (state, { employerId, data }) => {
  const index = state.bfetJobSearchEmployers.findIndex((employer) => employer.employerId === employerId)
  return state.merge({
    bfetJobSearchEmployers: [
      ...state.bfetJobSearchEmployers.slice(0, index),
      { ...data },
      ...state.bfetJobSearchEmployers.slice(index + 1)
    ]
  })
}

const deleteClientBfetJobSearchEmployerInformationSuccess = (state, { employerId }) => {
  return state.merge({
    bfetJobSearchEmployers: state.bfetJobSearchEmployers.filter((employer) => {
      return employer.employerId !== employerId 
    })
  })
}

export const getClientConsentInformationSuccess = (state, { data }) => {
  return state.merge({
    getConsentFetching: true,
    consent: data
  })
}

export const getClientDVRServiceDeliveryInformationSuccess = (state, { data }) => {
  return state.merge({
    getDVRServiceDeliveryFetching: true,
    dvrSeliveryDelivery: data
  })
}

export const getClientDVRVocationalInformationSuccess = (state, { data }) => {
  return state.merge({
    getDVRVocationalFetching: true,
    dvrVocational: data
  })
}

export const getClientDVRServiceDeliveryInformationFailure = (state) => {
  return state.merge({
    getDVRServiceDeliveryFetching: true
  })
}

export const getClientDVRVocationalInformationFailure = (state) => {
  return state.merge({
    getDVRVocationalFetching: true
  })
}

export const getClientVisitorNotesInformation = (state, { id, category }) => {
  return state.merge({
    notesFetching: {
      ...state.notesFetching,
      [category]: false
    }
  })
}

export const getClientVisitorNotesInformationSuccess = (state, { category, data }) => {
  return state.merge({
    notes: {
      ...state.notes,
      [category]: data
    },
    notesFetching: {
      ...state.notesFetching,
      [category]: true
    }
  })
}

export const getClientDocumentsInformationSuccess = (state, { category, data }) => {
  return state.merge({
    documents: {
      ...state.documents,
      [category]: data
    },
    documentsFetching: {
      ...state.documentsFetching,
      [category]: true
    }
  })
}
             
export const getClientSuppliesInformationSuccess = (state, { category, data }) => {
  return state.merge({
    supplies: {
      ...state.supplies,
      [category]: data
    },
    suppliesFetching: {
      ...state.suppliesFetching,
      [category]: true
    }
  })
}

export const updateClientConsentInformationSuccess = (state, { data }) => {
  return state.merge({
    consent: data
  })
}

export const updateClientDVRServiceDeliveryInformationSuccess = (state, { data }) => {
  return state.merge({
    dvrSeliveryDelivery: data
  })
}

export const updateClientDVRVocationalInformationSuccess = (state, { data }) => {
  return state.merge({
    dvrVocational: data
  })
}

export const updateFormSuccess = (state, { category, data }) => {
  return state.merge({
    updatingForm: false,
    reentry: {
      ...state.reentry,
      [category]: data
    }
  })
}

export const updateMentalFormSuccess = (state, { category, data }) => {
  return state.merge({
    updatingForm: false,
    mental: {
      ...state.mental,
      [category]: data
    }
  })
}

export const updateBfetFormSuccess = (state, { category, data }) => {
  return state.merge({
    updatingForm: false,
    bfet: {
      ...state.bfet,
      [category]: data
    }
  })
}

export const updateFormError = (state, { category, data }) => {
  return state.merge({
    updatingForm: false
  })
}

export const updateForm = (state) => {
  return state.merge({
    updatingForm: true
  })
}

export const createClient = (state) => {
  return state.merge({
    creatingClient: true
  })
}

export const createClientSuccess = (state) => {
  return state.merge({
    creatingClient: false
  })
}

export const createClientFailure = (state) => {
  return state.merge({
    creatingClient: false
  })
}

export const updateClient = (state) => {
  return state.merge({
    updatingClient: true
  })
}

export const updateClientSuccess = (state, { client }) => {
  return state.merge({
    updatingClient: false,
    client
  })
}

export const updateClientFailure = (state) => {
  return state.merge({
    updatingClient: false
  })
}

export const postClientPicture = (state) => {
  return state.merge({
    postingClientPicture: true
  })
}

// export const postClientPictureSuccess = (state) => {
//   return state.merge({
//     postingClientPicture: false
//   })
// }

export const deleteClientPicture = (state) => {
  return state.merge({
    postingClientPicture: true
  })
}

export const deleteClientPictureSuccess = (state, { data }) => {
  return state.merge({
    client: data,
    postingClientPicture: false
  })
}


export const postClientPictureSuccessCallbackCompleteDelay = (state) => {
  return state.merge({
    postingClientPicture: false
  })
}

export const referClient = (state) => {
  return state.merge({
    referringClientInProgress: true
  })
}

export const referClientSuccess = (state) => {
  return state.merge({
    referringClientInProgress: false
  })
}

export const referClientFailure = (state) => {
  return state.merge({
    referringClientInProgress: false
  })
}

export const getAccountClientUrlSuccess = (state, {url}) => {
  return state.merge({
    clientReferralUrl: url
  })
}
/* ------------------------------------------------ */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLIENTS]: getClients,
  [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
  [Types.GET_CLIENTS_FAILURE]: getClientsFailure,
  [Types.GET_CLIENT]: getClient,
  [Types.GET_CLIENT_SUCCESS]: getClientSuccess,
  [Types.GET_CLIENT_FAILURE]: getClientFailure,
  [Types.SEARCH_CLIENT]: searchClient,
  [Types.SEARCH_CLIENT_SUCCESS]: searchClientSuccess,
  [Types.SEARCH_CLIENT_FAILURE]: searchClientFailure,
  [Types.UPDATE_FORM]: updateForm,

  [Types.CREATE_CLIENT]: createClient,
  [Types.CREATE_CLIENT_SUCCESS]: createClientSuccess,
  [Types.CREATE_CLIENT_FAILURE]: createClientFailure,

  [Types.UPDATE_CLIENT]: updateClient,
  [Types.UPDATE_CLIENT_SUCCESS]: updateClientSuccess,
  [Types.UPDATE_CLIENT_FAILURE]: updateClientFailure,

  [Types.UPDATE_FORM_SUCCESS]: updateFormSuccess,
  [Types.UPDATE_MENTAL_FORM_SUCCESS]: updateMentalFormSuccess,
  [Types.UPDATE_BFET_FORM_SUCCESS]: updateBfetFormSuccess,

  [Types.UPDATE_FORM_ERROR]: updateFormError,
  [Types.GET_CLIENT_REENTRY_INTAKE_INFORMATION_SUCCESS]: getClientReentryIntakeInformationSuccess,
  [Types.GET_CLIENT_BFET_INFORMATION_SUCCESS]: getClientBfetInformationSuccess,
  [Types.GET_CLIENT_MENTAL_INFORMATION_SUCCESS]: getClientMentalInformationSuccess,
  [Types.GET_CLIENT_CONSENT_INFORMATION_SUCCESS]: getClientConsentInformationSuccess,
  [Types.UPDATE_CLIENT_REENTRY_CONSENT_INFORMATION_SUCCESS]: updateClientConsentInformationSuccess,

  [Types.GET_CLIENT_VISITOR_NOTES_INFORMATION]: getClientVisitorNotesInformation,

  [Types.GET_CLIENT_VISITOR_NOTES_INFORMATION_SUCCESS]: getClientVisitorNotesInformationSuccess,
  [Types.GET_CLIENT_DOCUMENTS_INFORMATION_SUCCESS]: getClientDocumentsInformationSuccess,
  [Types.GET_CLIENT_SUPPLIES_INFORMATION_SUCCESS]: getClientSuppliesInformationSuccess,

  [Types.GET_CLIENT_DVR_SERVICE_DELIVERY_INFORMATION_SUCCESS]: getClientDVRServiceDeliveryInformationSuccess,
  [Types.GET_CLIENT_DVR_SERVICE_DELIVERY_INFORMATION_FAILURE]: getClientDVRServiceDeliveryInformationFailure,

  [Types.GET_CLIENT_DVR_VOCATIONAL_INFORMATION_SUCCESS]: getClientDVRVocationalInformationSuccess,
  [Types.GET_CLIENT_DVR_VOCATIONAL_INFORMATION_FAILURE]: getClientDVRVocationalInformationFailure,

  [Types.UPDATE_CLIENT_DVR_SERVICE_DELIVERY_INFORMATION_SUCCESS]: updateClientDVRServiceDeliveryInformationSuccess,
  [Types.UPDATE_CLIENT_DVR_VOCATIONAL_INFORMATION_SUCCESS]: updateClientDVRVocationalInformationSuccess,

  [Types.GET_CLIENT_BFET_JOB_SEARCH_INFORMATION_SUCCESS]: getClientBfetJobSearchInformationSuccess,
  [Types.GET_CLIENT_BFET_JOB_SEARCH_EMPLOYERS_INFORMATION_SUCCESS]: getClientBfetJobSearchEmployersInformationSuccess,

  [Types.UPDATE_CLIENT_BFET_JOB_SEARCH_INFORMATION_SUCCESS]: getClientBfetJobSearchInformationSuccess,

  [Types.DELETE_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS]: deleteClientBfetJobSearchEmployerInformationSuccess,

  [Types.POST_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS]: postClientBfetJobSearchEmployerInformationSuccess,
  [Types.UPDATE_CLIENT_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS]: updateClientBfetJobSearchEmployerInformationSuccess,
  [Types.UPDATE_BFET_JOB_SEARCH_EMPLOYER_INFORMATION_SUCCESS]: updateBfetJobSearchEmployerInformationSuccess,

  [Types.POST_CLIENT_PICTURE]: postClientPicture,
  // [Types.POST_CLIENT_PICTURE_SUCCESS]: postClientPictureSuccess,

  [Types.POST_CLIENT_PICTURE_SUCCESS_CALLBACK_COMPLETE_DELAY]: postClientPictureSuccessCallbackCompleteDelay,



  [Types.DELETE_CLIENT_PICTURE]: deleteClientPicture,
  [Types.DELETE_CLIENT_PICTURE_SUCCESS]: deleteClientPictureSuccess,

  [Types.REFER_CLIENT]: referClient,
  [Types.REFER_CLIENT_SUCCESS]: referClientSuccess,
  [Types.REFER_CLIENT_FAILURE]: referClientFailure,

  [Types.GET_ACCOUNT_CLIENT_URL_SUCCESS]: getAccountClientUrlSuccess

})