
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LOGIN } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthActions from 'redux/AuthRedux'
import AlertMessage from 'components/AlertMessage.jsx'
import AlertActions from 'redux/AlertRedux'
import { ForgotPasswordContainer, ForgotPasswordInnerContainer, ForgotPasswordText, ForgotPasswordDetailText } from 'pages/Styles/ForgotPassword'
import { TextInput } from 'components/Fields/TextInput'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

export class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const { email } = values
    this.props.sendEmail(email)
  }

  render () {
    let { sending, messages, type, onClear } = this.props
    messages = messages && messages.length > 0 ? messages : null
    type = type || 'success'

    return (
      <Container fluid className='p-0 h-100 w-100'>
        <ForgotPasswordContainer>
          <Row className='h-100 justify-content-center align-items-center'>
            <ForgotPasswordInnerContainer>
              <Col>
                <Row>
                  <AlertMessage
                    type={type}
                    messages={messages }
                    onClear={onClear}
                  />
                </Row>
                
                <Row >
                  <ForgotPasswordText>Forgot Password</ForgotPasswordText>
                  <ForgotPasswordDetailText>Don't worry resetting your password is easy, Just type in the email you registered to AXION GALAXY 360.</ForgotPasswordDetailText>
                </Row>

                <Row className='mt-5'>
                  <Col className='p-0'>
                    <Formik
                      initialValues={{ email: '' }}
                      validationSchema={Yup.object({
                        email: Yup.string().required('Please enter an email.')
                      })}
                      onSubmit={this.handleSubmit}
                    >
                      <Form>
                        <TextInput label='Email' name='email' type='text' placeholder='Enter your email address' size='lg' labelwithin={true} />
                        <Button size="lg" className='mt-5' block variant="primary" disabled={sending} type='submit'>{sending ? 'Sending Instructions...' : 'Send Instructions' }</Button>
                      </Form>
                    </Formik>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col className='p-0'>
                    <p><Link to={LOGIN}>Already have an account? Sign in!</Link></p>
                    {/* <p><Link to={SIGN_UP}>Don't have an account? Sign Up!</Link></p> */}
                  </Col>
                </Row>
              </Col>

              </ForgotPasswordInnerContainer>
            </Row>
        </ForgotPasswordContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sending: state.auth.sending,
    messages: state.alert.messages,
    type: state.alert.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (email) => dispatch(AuthActions.sendEmailRequest(email)),
    onClear: () => dispatch(AlertActions.clear())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);