import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { TextMessage } from 'components/Fields/TextMessage'
import { Label, LightGrayLabel } from 'styles/GenericStyles';
import { Row, Col } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'

const yesOrNoOptions = [
  { key: "Yes", value: true },
  { key: "No", value: false },
];
export default class TreatmentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_MENTAL_FORM_TYPES.treatmentHistory
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm
                handleSubmit={this.handleSubmit}
                updatingForm={updatingForm} disabled={!write}
              >
                <div className="w-100">
                  <Row className="mb-3">
                    <Col className="p-0">
                      <Label>
                        Information about client (past and present):
                      </Label>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="counselledOrTreated"
                      >
                        Counseling/ Psychiatric Treatment
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-8 mb-2 d-flex"
                        name="clientPsychiatricTreatment"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-8 col-lg-4 mb-2 mb-0"
                        name="clientPsychiatricTreatmentDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-8 mb-2"
                        name="clientPsychiatricTreatmentLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="counselledOrTreatedExperience"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientPsychiatricTreatmentReaction"
                        placeholder="Overall Experience:"
                        // rows={3}
                        size="md"
                      />
                    </Col>
                  </Row>


                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label className="input-label" htmlFor="suicidalThoughts">
                        Suicidal Thoughts
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientSuicidalAttempt"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientSuicidalAttemptDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientSuicidalAttemptLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="clientSuicidalAttemptReaction"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientSuicidalAttemptReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label className="input-label" htmlFor="suicidalThoughts">
                        Suicidal Attempts
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientSuicidalThought"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientSuicidalThoughtDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientSuicidalThoughtLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="clientSuicidalThoughtReaction"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientSuicidalThoughtReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>


                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label className="input-label" htmlFor="suicidalThoughts">
                        Self Harm
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientSelfHarm"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientSelfHarmDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientSelfHarmLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="clientSelfHarmtReaction"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientSelfHarmReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="drugOrAlcoholTreatment"
                      >
                        Drug / Alcohol Treatment
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientDrugTreatment"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientDrugTreatmentDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientDrugTreatmentLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="drugOrAlcoholTreatmentExperience"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientDrugTreatmentReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label className="input-label" htmlFor="hospitalizations">
                        Hospitalizations
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-8 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientHospitalization"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientHospitalizationDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientHospitalizationLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="hospitalizationsExperience"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientHospitalizationReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="involvedInSelfHelpGroups"
                      >
                        Involvement With Self-Help Groups(E.G., Aa, Al-Anon, Na,
                        Oa, Etc.)
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="clientInvolvementWithSelfHelpGroup"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="clientInvolvementWithSelfHelpGroupDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="clientInvolvementWithSelfHelpGroupLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-4">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="involvedInSelfHelpGroupsExperience"
                      >
                        Your Reaction To Overall Experience
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9">
                      <TextMessage
                        labelClassName="d-none"
                        name="clientInvolvementWithSelfHelpGroupReaction"
                        placeholder="Overall Experience:"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0 col-12">
                      <label className="input-label">
                        Information about family/significant others (past or
                        present):
                      </label>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="familyCounselledOrTreated"
                      >
                        Counseling/ Psychiatric Treatment
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="familyPsychiatricTreatment"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="familyPsychiatricTreatmentDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="familyPsychiatricTreatmentLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="familySuicidalThoughts"
                      >
                        Suicidal Thoughts / Attempts
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="familySuicidalAttempt"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0 "
                        name="familySuicidalAttemptDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="familySuicidalAttemptLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="familyDrugOrAlcoholTreatment"
                      >
                        Drug / Alcohol Treatment
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="familyDrugTreatment"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0 "
                        name="familyDrugTreatmentDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="familyDrugTreatmentLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="familyHospitalizations"
                      >
                        Hospitalizations
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-12 mb-2 d-flex"
                        name="familyHospitalization"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="familyHospitalizationDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="familyHospitalizationLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col className="p-0 col-xs-12 col-sm-12 col-md-12 col-lg-3">
                      <label
                        className="input-label"
                        htmlFor="familyInvolvedInSelfHelpGroups"
                      >
                        Involvement With Self-Help Groups(E.G., Aa, Al-Anon, Na,
                        Oa, Etc.)
                      </label>
                    </Col>
                    <Col className="pl-3 p-0 col-xs-12 col-sm-12 col-md-9 row row-cols-xs-1 row-cols-md-1 row-cols-lg-3">
                      <RadioButton
                        className="mb-0 col-md-8 mb-2 d-flex"
                        name="familyInvolvementWithSelfHelpGroup"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                      <TextInput
                        className="col-md-12 col-lg-4 mb-2 mb-0"
                        name="familyInvolvementWithSelfHelpGroupDate"
                        type="date"
                        placeholder="When"
                      />
                      <TextInput
                        className="mb-0 col-md-12 mb-2"
                        name="familyInvolvementWithSelfHelpGroupLocation"
                        type="text"
                        placeholder="Where"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>
                  <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <Col className="col-md-12 col-lg-12 col-12">
                      <label className="input-label mb-3">
                        Please check behaviors and symptoms that occur more
                        often than you would like them to:
                      </label>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasAggression">
                        {" "}
                        Aggression
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasEatingDisorder"
                      >
                        {" "}
                        Eating disorder{" "}
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasMoodShift">
                        {" "}
                        Moodshift
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasAlcoholDependence"
                      >
                        {" "}
                        Alcohol Dependence
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasElevatedMood"
                      >
                        {" "}
                        Elevated Mood{" "}
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasPhobias">
                        {" "}
                        Phobias/ fears
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasAntisocialBehavior"
                      >
                        {" "}
                        Antisocial behavior
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasFatigue">
                        {" "}
                        Fatigue
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasRecurring">
                        {" "}
                        Recurring/ disorganized thoughts{" "}
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasAnxiety">
                        {" "}
                        Anxiety/ Panic attacks
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="doesGambling">
                        {" "}
                        Gambling
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasSexualDifficulties"
                      >
                        {" "}
                        Sexual Difficulties
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="avoidsPeople">
                        {" "}
                        Avoiding people
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasHallucinations"
                      >
                        {" "}
                        Hallucinations
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="isSickOften">
                        {" "}
                        Sick often
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasChestPain">
                        {" "}
                        Chest pain/ Heart palpitations
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasHighBloodPressure"
                      >
                        {" "}
                        High blood pressure
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasSleepingProblems"
                      >
                        {" "}
                        Sleeping problems
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasDepression">
                        {" "}
                        Depression
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasIrritability"
                      >
                        {" "}
                        Irritability
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasTrembling">
                        {" "}
                        Trembling
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasDisorientation"
                      >
                        {" "}
                        Disorientation/ Dizziness
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasJudgmentErrors"
                      >
                        {" "}
                        Judgment errors
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasHopelessness"
                      >
                        {" "}
                        Worrying/ hopelessness
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasDistractibility"
                      >
                        {" "}
                        Distractibility
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox labelClassName="text-wrap" name="hasLoneliness">
                        {" "}
                        Loneliness/ withdrawing
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasDrugDependence"
                      >
                        {" "}
                        Drug dependence
                      </Checkbox>
                    </Col>
                    <Col className="mb-3">
                      <Checkbox
                        labelClassName="text-wrap"
                        name="hasMemoryImpairment"
                      >
                        {" "}
                        Memory impairment
                      </Checkbox>
                    </Col>
                    <Col className="mb-3 d-flex align-items-center">
                      <Checkbox
                        labelClassName="text-wrap d-flex align-items-center"
                        name="other"
                      >
                        {" "}
                        Other
                      </Checkbox>
                      <TextInput
                        className="mb-0"
                        name="specifyOther"
                        type="text"
                        placeholder="(Specify)"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0 col-12">
                      <label
                        className="input-label text-wrap mb-2"
                        htmlFor="describeImpairing"
                      >
                        Briefly Describe How The Above Symptoms Impair Your
                        Ability To Function Effectively:
                      </label>
                    </Col>
                    <Col className="p-0 col-10">
                      <TextMessage
                        labelClassName="d-none"
                        name="impairAbilitySymptomps"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0 col-12">
                      <label
                        className="input-label text-wrap mb-2"
                        htmlFor="additionalInfo"
                      >
                        Provide Any Additional Information That Would Assist Us
                        In Understanding Your Concerns Or Problems
                      </label>
                    </Col>
                    <Col className="p-0 col-10">
                      <TextMessage
                        labelClassName="d-none"
                        name="additionalInformation"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="p-0 col-12">
                      <label
                        className="input-label text-wrap mb-2"
                        htmlFor="goalsOftherapy"
                      >
                        What Are Your Goals For Therapy?
                      </label>
                    </Col>
                    <Col className="p-0 col-10">
                      <TextMessage
                        labelClassName="d-none"
                        name="goalsForTherapy"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="feelingSuicidal"
                      >
                        Do you feel suicidal at this time?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="feelSuicidal"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col className="col-10 p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="If Yes, Describe:"
                        name="suicidalfeelingDescription"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
