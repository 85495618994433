import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const PTh = styled.th`
  border-bottom: none !important;
  color: #BEBEBE;
  line-height: 27px;
  font-weight: 600
`

export const PTd = styled.td`
  color: #43425D;
  line-height: 25px;
  font-size: medium;
  vertical-align: middle !important;
`

export const PTr = styled.tr`
  border-radius: 6px;
`

export const PTable = styled(Table)`
  border-spacing: 0 2px;
  border-collapse: separate;
`

export const IconButton = styled.button`
  background-color: white;
  border: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #5A85A229;
  border-radius: 4px;
  width: 42px;
  height: 40px;
  &.disabled {
    background: #F2F4F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
  }
`
export const IconBtn = styled.button`
  background-color: white;
  border: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  height: 40px;
`
export const FilterDiv = styled.div`
  background-color: transparent;
  & .weeksFilter {
    & span {
      margin-right: 30px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #43425D;
    }
  }
`
export const AddButton = styled.button`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #5A85A229;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  padding: 8px;
`
export const WeekDiv = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  letter-spacing: 0px;
  & div {
    text-align: center;
    & .day {
      font-size: 18px;
      line-height: 27px;
      color: #43425D;
    }
    & .date {
      display: block;
      font-size: 14px;
      line-height: 21px;
      color: #BEBEBE;
    }
    &.selected {
      & .day, & .date {
        color: #3B41C2;
      }
    }
  }
`