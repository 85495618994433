import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { Checkbox } from "components/Fields/Checkbox";
import { RadioButton } from "components/Fields/RadioButton";
import { BOOLEAN_CHECKBOX_OPTIONS } from "config/CONSTANTS";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class SkillsAndAbilityForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    const params = {
      ...values,
      certificatesOrLicenses: [values.certificatesOrLicenses],
      equipmentsSpecialized: [values.equipmentsSpecialized],
      softwaresSpecialized: [values.softwaresSpecialized],
    };
    this.props.onUpdate(
      this.props.clientID,
      { ...params, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.skillsAndAbilities
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          certificatesOrLicenses: data && data.certificatesOrLicenses && data.certificatesOrLicenses.toString(),
          equipmentsSpecialized: data && data.equipmentsSpecialized && data.equipmentsSpecialized.toString(),
          softwaresSpecialized: data && data.softwaresSpecialized && data.softwaresSpecialized.toString(),
        }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Col className="p-0">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="certificatesOrLicenses"
                      >
                        List any certifications/licenses you have:
                      </label>
                    </Col>
                    <TextMessage
                      className="mb-3 col-sm-12 col-lg-12"
                      name="certificatesOrLicenses"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                  <Col className="p-0">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="equipmentsSpecialized"
                      >
                        List any specialized equipment you know how to use:
                      </label>
                    </Col>
                    <TextMessage
                      className="mb-3 col-sm-12 col-lg-12"
                      name="equipmentsSpecialized"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                  <Col className="p-0">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="softwaresSpecialized"
                      >
                        List any specialized software you are familiar with:
                      </label>
                    </Col>
                    <TextMessage
                      className="mb-3 col-sm-12 col-lg-12"
                      name="softwaresSpecialized"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>

                  <Col className="col-xs-12 col-sm-12 col-md-6">
                    <RadioButton
                      className="mb-3"
                      label="Can you type?"
                      labelClassName="d-block input-label mb-2"
                      name="canType"
                      options={BOOLEAN_CHECKBOX_OPTIONS}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className="col-xs-12 col-sm-12 col-md-6">
                    <TextInput
                      labelClassName="input-label mb-2"
                      label="How many Words Per Minute (WPM)?"
                      name="numberOfTypeingWordsPerMinute"
                      type="number"
                      placeholder="WPM"
                    />
                  </Col>
                  <RadioButton
                    className="mb-3 ml-3"
                    label="Do you have 10-key experience?"
                    labelClassName="d-block input-label mb-2"
                    name="has10KeyExperience"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <RadioButton
                    className="mb-3 ml-3"
                    label="Are you comfortable on computers?"
                    labelClassName="d-block input-label mb-2"
                    name="isComfortableWithComputers"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />

                  <Row className="ml-4">
                    <Checkbox name="isComfortableWithPC"> PC </Checkbox>
                    <Checkbox name="isComfortableWithMAC"> MAC </Checkbox>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
