import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Select } from 'components/Fields/Select';
import { LightGrayLabel, Preview, PreviewFooter, ButtonContainer } from 'styles/GenericStyles';
import { ReactComponent as DeleteIcon } from 'images/svg/trash.svg';
import LoadingView from 'components/LoadingView.jsx'
import Dropzone from 'react-dropzone'
import { FileDropContainer } from 'styles/GenericStyles'
import { ReactComponent as UploadIcon } from 'images/svg/upload.svg';
import { ReactComponent as PDFIcon } from 'images/svg/pdf.svg';

import Strings from 'i18n/en';

export default class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      type: null
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  componentDidMount () {
    this.props.getClientDocumentsInformation(this.props.clientID, 'DEFAULT')
  }

  handleSubmit = (values) => {
    this.props.postClientDocumentsInformation(this.props.clientID, 'DEFAULT', { ...values,  clientId: this.props.clientID })
  }

  onDelete = (document) => {
    this.props.deleteClientDocumentsInformation(this.props.clientID, document.documentId, 'DEFAULT')
  }

  onSelectType = (e) => {
    this.setState({ type: e.currentTarget.value })
  }

  renderContent() {
    const { data, read, write } = this.props;
    const { type } = this.state

    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        initialValues={{ file: null }}
        onSubmit={this.handleSubmit}>
        {
          (formProps) => (
            <Form disabled={!write}>
              <Row className='shadow rounded border mt-3 mr-0 ml-0 p-5 w-100'>
                <div className='w-100'>
                  <Row className='mb-3'>
                    <Col className='col-8 p-0'>
                      <Select name="docName" className='custom-select' onChange={this.onSelectType}>
                        <option label="Select" />
                        <option value="Medical/Insurance Card(s)" label="Medical/Insurance Card(s)" />
                        <option value="Driver's License/ID Card" label="Driver's License/ID Card" />
                        <option value="Court Documents" label="Court Documents" />
                        <option value="Clinical Documents" label="Clinical Documents" />
                        <option value="Job Search logs" label="Job Search logs" />
                        <option value="Consent Form" label="Consent Form" />
                        <option value="Inventory Form" label="Inventory Form" />
                        <option value="Declaration and Signature" label="Declaration and Signature" />
                      </Select>
                    </Col>

                    <Col className='col-12 p-0 mt-4 d-flex flex-wrap'>
                      <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <FileDropContainer {...getRootProps()}>
                              <input
                                name='file'
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                {...getInputProps()}
                                onChange={(event) => {
                                  formProps.setFieldValue("file", event.target.files[0]);
                                  this.handleSubmit({ file: event.target.files[0], docName: type });
                                }}
                              />
                              <UploadIcon />
                              <p className='browseText'>Browse</p>
                            </FileDropContainer>
                          </section>
                        )}
                      </Dropzone>
                      {
                        data && data.documents && data.documents.map((document, index) => (
                          <div key={index}>
                            <Preview>
                              {
                                <a
                                  href={document.downloadURL}
                                  rel='noreferrer'
                                  target="_blank"
                                  alt='Image'
                                >
                                  { (document.fileType === 'image/png' || document.fileType === 'image/jpeg' || document.fileType === 'image/jpg') ? <img alt='' src={document.downloadURL} onError={({ currentTarget }) => {currentTarget.src=`${document.downloadURL}`}} /> : (document.fileType === 'application/pdf' ? <PDFIcon /> : 'Open' )}
                                </a>
                              }
                              <ButtonContainer type='button' className='delete-icon' onClick={() => {this.onDelete(document)}}><DeleteIcon /></ButtonContainer>
                            </Preview>
                            <PreviewFooter>
                              <Row className='m-0'><LightGrayLabel> Type:</LightGrayLabel> {document.documentType}</Row>
                              <Row className='m-0 pl-1'><LightGrayLabel>File:</LightGrayLabel> {document.documentName}</Row>
                            </PreviewFooter>
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                </div>
              </Row>
            </Form>
          )
        }
        </Formik>
    )
  }

  render() {
    const { fetching } = this.props
    return (
      <>
        { !fetching && this.renderLoading() }
        { fetching && this.renderContent() }
      </>
    )
  }
}