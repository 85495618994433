import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import {
  CLIENT_DVR_VOCATIONAL,
} from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import ReactToPrint from "react-to-print";

export class VocationalView extends React.Component {
  componentDidMount() {
    this.props.getClientDVRVocationalInformation(this.props.clientID, "BEFT");
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    let {
      data,
      clientID,
      isWriteDVRVocationalEnabled,
      isReadDVRVocationalEnabled,
    } = this.props;
    data = data || {};

    if (!isReadDVRVocationalEnabled && !isWriteDVRVocationalEnabled) {
      return (
        <Row className="shadow rounded border mt-4 p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">DVR &gt; Vocational:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>DVR &gt; Vocational:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_DVR_VOCATIONAL.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <div className="w-100">
          <Row className="align-items-center mb-3 mt-2 col-12 p-0">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  What Kind Of Job And Work Setting Are You Hoping To Find?
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.kindOfJobAndWorkHopingToFind}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Would you like to invite others to attend your DVR meetings
                  and/or have them assist you with this form (e.g., friend,
                  family member, advocate, legal guardian, teacher or other VR
                  counselor from another program)?
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{humanFormat(data.wouldLikeToInviteOthers)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>If Other, Describe:</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.describeWhoYouWouldLikeToInvite}</label>
              </Col>
            </Row>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                If you receive Social Security benefits, are you interested in
                learning more about them and how they may be impacted by going
                to work?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{humanFormat(data.socialSecurityBenefitsImpact)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Other, Describe:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.describeSocialSecurityBenefitsImpact}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                When you go to work, how much money will you need in your
                monthly budget to support yourself and/or your family?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.moneyRequiredToSupportSelfAndFamily}</label>
            </Col>
          </Row>

          <Row className="col-12 p-0 ">
            <Col className="col-12">
              <LightGrayLabel>
                Describe the labor market information that indicates this
                employment goal is in-demand in your local area:
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.laborMarketInformation}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                If it is not in-demand, what have you considered that could
                increase your chances of employment in this field (e.g.,
                targeted work experience, internship, on-the-job training,
                relocation)?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">
                {data.considerationsThatCouldIncreaseTheChancesOfEmployment}
              </label>
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                What jobs have you considered in potentially high demand
                industries (e.g., such as computer science, healthcare, science,
                technology, engineering and math)?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.jobsYouWouldConsiderInHighDemandIndustries}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Other:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.otherJobsYouWouldConsider}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Does your employment goal require a background check as a
                condition of employment?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">
                {humanFormat(data.employmentGoalRequiresBackgroundCheck)}
              </label>
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                If Yes, DVR Requires That A Background Check Be Completed That
                Verifies You Will Not Be Excluded From The Specificjob.
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.backgroundCheckInfo}</label>
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Please describe the assessments that support your employment
                goal (e.g., career tests, volunteer or school-based work
                experiences, career development class, etc.)?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.assessmentsSupportingEmploymentGoals}</label>
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Other:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.otherAssessments}</label>
            </Col>
          </Row>

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Describe Your Education / Training History, Including Licenses
                And Certificates.
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.educationOrTrainingHistory}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Do You Have Any Specific Cultural Values, Practices And/Or
                Preferred Language Needs That You Would Like To Share With DVR
                Prior To Job Search And Placement?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.culturalValuesOrOtherPreferences}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Describe your previous successes and challenges obtaining and
                maintaining a job (e.g., work history, gaps in employment,
                transferrable skills, volunteer experiences, etc.)?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.previousSuccesses}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Describe Your Individual Strengths That Make This Employment
                Goal Appropriate:
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.individualStrenghts}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Describe your personal resources that might support your
                employment plan (e.g., family and social supports,
                transportation, etc.):
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.personalResources}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Describe your disability-related barriers to employment (e.g.,
                mobility concerns, problems standing for long periods of time,
                communication barriers, getting along with others, memory,
                difficulty learning new information / tasks):
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.disabilities}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel> If Other:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.otherDisabilities}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Describe how you are currently managing our disability-related
                barriers (e.g., counseling, physical therapy, skills training,
                support group):
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.howDisabilityIsManaged}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel> If Other:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.otherHowDisabilityIsManaged}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                {" "}
                Describe any additional barriers to employment that are not
                disability-related (e.g., childcare, no valid mailing address /
                email / phone, criminal history, transportation, housing, food
                assistance, etc.):
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">
                {data.additionalBarriersToEmploymentThatAreNotDisabilityRelated}
              </label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel> If Other:</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">
                {
                  data.otherAdditionalBarriersToEmploymentThatAreNotDisabilityRelated
                }
              </label>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12 col-lg-12 col-12">
              <label className="input-label mb-3">
                What Services Do You Think You Might Need To Reach Your
                Employment Goal?
              </label>
            </Col>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>Assistive Technology (AT)</LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="mr-3">{data.assistiveTechnology}</label>
                <label className="input-label">{data.otherAssistiveTechnology}</label>
              </Row>
            </Row>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>Independent Living (IL)</LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="input-label">{data.independentLiving}</label>
              </Row>
            </Row>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>
                  Personal Assistance Services (PAS)
                </LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="input-label">{data.personalAssistanceServices}</label>
              </Row>
            </Row>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>Supported Employment (SE)</LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="input-label">{data.supportedEmployment}</label>
              </Row>
            </Row>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>Transition Services (TS)</LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="input-label">{data.transitionServices}</label>
              </Row>
            </Row>
            <Row className="mb-2 w-100 ml-1">
              <Col className="col-sm-12 col-md-4 col-lg-4">
                <LightGrayLabel>Other Services</LightGrayLabel>
              </Col>
              <Row className="col-8 p-0">
                <label className="input-label">{data.otherServices}</label>
              </Row>
            </Row>
            <Row className="mb-3">
              <Col className="p-0">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-12 col-lg-12 col-12 ml-3">
                <LightGrayLabel>
                  When you get ready to look for work, will you need help with
                  any of the following?
                </LightGrayLabel>
              </Col>

              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3 ml-1">
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Application assistance</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.applicationAssistance)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Interview preparation</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.interviewPreparation)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>
                      One-on-one meetings to work on resume
                    </LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">
                      {isBoolean(data.oneOnOneMeetingsToWorkOnResume)}
                    </label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Cover letters</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.coverLetters)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Job search</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.jobSearch)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Online job search</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.onlineJobSearch)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Email job leads</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.emailJobLeads)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Master application</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.masterApplication)}</label>
                  </Col>
                </Col>
              </Row>

              <Row className="mb-3 ml-1">
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>
                      Referral to WorkSource (e.g., Job Hunter series, basic
                      computer skills, Microsoft training, ex-offender services,
                      mature worker services, youth services)
                    </LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.referralToWorkSource)}</label>
                  </Col>
                </Col>
              </Row>

              <Row className="mb-3 ml-1 d-flex align-items-center">
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Other</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{isBoolean(data.otherWork)}</label>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Specify</LightGrayLabel>
                  </Col>
                  <Col className="col-12 p-0 ml-3">
                    <label className="input-label">{data.otherWorkDescription}</label>
                  </Col>
                </Col>
              </Row>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Describe Any Additional Information That Supports Your
                  Employment Goal
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-4">
                <label className="input-label">
                  {data.additionalInformationThatSupportsEmploymentGoal}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12 ml-1">
                <LightGrayLabel>
                  Your Specific Employment Goal Should Be Consistent With Your
                  Strengths, Resources, Priorities, Concerns, Abilities,
                  Capabilities, Interests, And Informed Choice.
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-4">
                <label className="input-label">{data.specificEmploymentGoal}</label>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    );
  }

  render() {
    const { getDVRVocationalFetching } = this.props;
    return (
      <>
        {!getDVRVocationalFetching && this.renderLoading()}
        {getDVRVocationalFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getDVRVocationalFetching: state.client.getDVRVocationalFetching,
    data: state.client.dvrVocational,
    isWriteDVRVocationalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_VOCATIONAL
    ),
    isReadDVRVocationalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_VOCATIONAL
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDVRVocationalInformation: (id, category) =>
      dispatch(ClientActions.getClientDVRVocationalInformation(id, category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VocationalView);
