import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  getAppointments: null,
  getAppointmentsSuccess: ['data'],
  getAppointmentsFailure: null,

  getAccountAppointment: ['appointmentId'],
  getAccountAppointmentSuccess: ['data'],

  getAccountAppointments: ['accountId', 'start', 'end'],
  getAccountAppointmentsSuccess: ['data'],

  getClientAppointments: ['clientId', 'start', 'end'],
  getClientAppointmentsSuccess: ['data'],

  getAllAppointments: ['start', 'end'],
  getAllAppointmentsSuccess: ['data'],
  getAllAppointmentsFailure: null,

  createAppointment: ['params'],
  createAppointmentSuccess: ['data'],
  cancelAppointment: ['appointmentId'],
  cancelAppointmentSuccess: ['appointmentId'],
  updateAppointment: ['appointmentId', 'params'],
  updateAppointmentSuccess: ['appointmentId', 'data'],
})

export const AppointmentTypes = Types
export default Creators

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  appointments: [],
  accountAppointments: [],
  accountAppointmentsFetching: false,
  totalAccountAppointments: 0,

  accountAppointment: null,
  accountAppointmentFetching: false,

  clientAppointments: [],
  clientAppointmentsFetching: false,
  totalClientAppointments: 0,

  updatingForm: false,
  currentAppointment: null
})

export const getAppointmentsSuccess = (state, { data }) => {
  return state.merge({
    appointments: data.appointments
  })
}

export const getAccountAppointments = (state, { data }) => {
  return state.merge({
    accountAppointmentsFetching: true
  })
}

export const getAccountAppointment = (state, { data }) => {
  return state.merge({
    accountAppointmentFetching: true
  })
}

export const getAccountAppointmentSuccess = (state, { data }) => {
  return state.merge({
    accountAppointment: data,
    accountAppointmentFetching: false
  })
}

export const getAccountAppointmentsSuccess = (state, { data }) => {
  return state.merge({
    accountAppointments: data.appointments,
    totalAccountAppointments: data.totalCount,
    accountAppointmentsFetching: false
  })
}

export const cancelAppointmentSuccess = (state, { appointmentId }) => {
  return state.merge({
    accountAppointments: state.accountAppointments.filter((appt) => appt.appointmentId !== appointmentId),
    totalAccountAppointments: state.totalAccountAppointments - 1
  })
}

export const getClientAppointments = (state, { data }) => {
  return state.merge({
    clientAppointmentsFetching: true
  })
}

export const getClientAppointmentsSuccess = (state, { data }) => {
  return state.merge({
    clientAppointments: data.appointments,
    totalClientAppointments: data.totalCount,
    clientAppointmentsFetching: false
  })
}

export const createAppointment = (state) => {
  return state.merge({
    updatingForm: true
  })
}

export const createAppointmentSuccess = (state, { data }) => {
  return state.merge({
    updatingForm: false,
    currentAppointment: data
  })
}



export const getAllAppointments = (state, { data }) => {
  return state.merge({
    accountAppointmentsFetching: true
  })
}

export const getAllAppointmentsSuccess = (state, { data }) => {
  return state.merge({
    accountAppointments: data.appointments,
    totalAccountAppointments: data.totalCount,
    accountAppointmentsFetching: false
  })
} 

export const getAllAppointmentsFaliure = (state, { data }) => {
  return state.merge({
    accountAppointmentsFetching: false
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_APPOINTMENTS_SUCCESS]: getAppointmentsSuccess,
  [Types.GET_ACCOUNT_APPOINTMENTS]: getAccountAppointments,
  [Types.GET_ACCOUNT_APPOINTMENT]: getAccountAppointment,
  [Types.GET_CLIENT_APPOINTMENTS]: getClientAppointments,
  [Types.GET_ACCOUNT_APPOINTMENTS_SUCCESS]: getAccountAppointmentsSuccess,
  [Types.GET_CLIENT_APPOINTMENTS_SUCCESS]: getClientAppointmentsSuccess,
  [Types.CANCEL_APPOINTMENT_SUCCESS]: cancelAppointmentSuccess,
  [Types.GET_ACCOUNT_APPOINTMENT_SUCCESS]: getAccountAppointmentSuccess,
  [Types.CREATE_APPOINTMENT]: createAppointment,
  [Types.CREATE_APPOINTMENT_SUCCESS]: createAppointmentSuccess,

  [Types.GET_ALL_APPOINTMENTS]: getAllAppointments,
  [Types.GET_ALL_APPOINTMENTS_SUCCESS]: getAllAppointmentsSuccess,
  [Types.GET_ALL_APPOINTMENTS_FAILURE]: getAllAppointmentsFaliure,

})