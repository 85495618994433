import React from 'react';
import Layout from 'components/Layout.jsx'
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { Button, Row, Col } from 'react-bootstrap';
import { PAGE_SIZE } from 'config/CONSTANTS'
import ReportActions from 'redux/ReportRedux'
import QueryListing from 'components/QueryListing'
import Strings from 'i18n/en'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import { Select } from 'components/Fields/Select';
import { Formik, Form, FieldArray } from 'formik';
import BooleanUI from 'components/DataTypes/BooleanUI'
import NestedBooleanUI from 'components/DataTypes/NestedBooleanUI'
import NumericUI from 'components/DataTypes/NumericUI';
import NestedNumericUI from 'components/DataTypes/NestedNumericUI';
import StringUI from 'components/DataTypes/StringUI';
import NestedStringUI from 'components/DataTypes/NestedStringUI';
import DateUI from 'components/DataTypes/DateUI';
import NestedDateUI from 'components/DataTypes/NestedDateUI';
import ArrayStringUI from 'components/DataTypes/ArrayStringUI';
import NestedArrayStringUI from 'components/DataTypes/NestedArrayStringUI';
import { RadioButton } from 'components/Fields/RadioButton';
import SaveTemplateModal from 'components/Modals/SaveTemplateModal';
import { LightGrayLabel } from "styles/GenericStyles";
import ShowInfoModal from 'components/Modals/ShowInfoModal';
import AccountActions from 'redux/AccountRedux'
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput';
import QueryModal from 'components/Modals/QueryModel'

export class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addFilter: false,
      query: null,
      savingTemplate: false,
      savingQuery: null,
      showingInfo: false,
      isEditingQuery: false,
      editingQuery: null,
      rows: []
    };
  }

  addFilter = () => {
    this.setState({ addFilter: true });
  };
  
  componentDidMount () {
    this.props.getSearchMetaData(1)
    this.props.getQueries(1)
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_USERS) && this.props.getAccounts(1)
  }

  componentWillReceiveProps (newProps) {
    if ((this.props.query && this.props.query.queryId) !== (newProps.query && newProps.query.queryId)) {
      this.setState({
        query: newProps.query
      })
    }

    if (newProps.template && newProps.template.templateName) {
      this.onHideSaveTemplateModal()
    }

  }

  renderLoading = () => {
    return <LoadingView />
  }

  onShowSaveTemplateModal = (query) => {
    this.props.createReportTemplateFailure(null);
    this.setState({ savingTemplate: true, savingQuery: query })
  }

  onHideSaveTemplateModal = () => {
    this.props.createReportTemplateFailure(null);
    this.setState({ savingTemplate: false, savingQuery: null, templateName: null })
  }

  onShowInfo = (rows) => {
    this.setState({
      showingInfo: true,
      rows
    })
  }

  onHideInfo = () => {
    this.setState({
      showingInfo: false,
      rows: []
    })
  }

  render () {
    const { fetchingMetaData, fetchingQueries, queries, createReportTemplateFailureMessage, fetchingAccounts, accounts } = this.props
    const { savingTemplate, savingQuery, showingInfo, rows, isEditingQuery, editingQuery } = this.state
    return (
      <Layout>
        { fetchingMetaData && this.renderLoading() }
        { !fetchingMetaData && this.renderContent() }

        { fetchingQueries && this.renderLoading() }
        { !fetchingQueries && (queries && queries.length > 0) && this.renderQueries() }

        { (savingTemplate || createReportTemplateFailureMessage) && <SaveTemplateModal onClose={this.onHideSaveTemplateModal} onSave={this.onHideSaveTemplateModal} createReportTemplateFailureMessage={createReportTemplateFailureMessage} query={savingQuery} createReportTemplate={this.props.createReportTemplate} /> }
        { showingInfo && <ShowInfoModal onClose={this.onHideInfo} onSave={this.onHideInfo} rows={rows} /> }

        { isEditingQuery && <QueryModal reportType={'playground'} template={editingQuery} onClose={this.onHideEditingQueryModal} onSave={this.onHideEditingQueryModal} accounts={accounts}></QueryModal>}

      </Layout>
    )
  }

  renderContent () {
    let { isReadReportsEnabled, types, allowedTypesMap, filterMetaData, query, accounts, createQuery, getQuerySuccess } = this.props
    let outputColumns = (query && query.outputColumns) || []
    let request = (query && query.request) || { 'includedFilters' : { 'filterOperator': 'AND', 'filters': []} }
    let iValues = { ...request, outputColumns }
    const filterOperations = [
      { key: 'AND', value: 'AND' },
      { key: 'OR', value: 'OR' },
    ];

    const validationSchema = Yup.object().shape({
      'outputColumns': Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("is required"),
          parentType: Yup.string().required("is required"),
          filterType: Yup.string().required("is required")
        })
      ),
      'includedFilters': Yup.object().shape({
        'filterOperator': Yup.string().required(),

        'filters': Yup.array().of(
          Yup.object().shape({
            filterOperator: Yup.string().optional(),
            isNested: Yup.boolean().optional(),
            filters: Yup.array().of(
              Yup.object().shape({
                parentType: Yup.string().required("is required"),
                filterType: Yup.string().required("is required"),
                dataType: Yup.string().required("is required"),
                name: Yup.string().required("is required"),
                operator: Yup.string().required("is required"),
                value: Yup.mixed()
                  .when(['dataType', 'operator'], {
                    is: (dataType, operator) => {
                      if (dataType === 'DATE_TIME' && operator) {
                        return !(allowedTypesMap[dataType][operator] && allowedTypesMap[dataType][operator]['dateTypes'] && allowedTypesMap[dataType][operator]['dateTypes'].length === 0)
                      }
                      
                      return (dataType === 'NUMERIC' ||  dataType === 'STRING' || dataType === 'BOOLEAN' || dataType === 'ARRAY_STRING')
                    },
                    then: (schema) => schema.required("is required"),
                    otherwise: (schema) => schema.notRequired()
                  })
                })
            ).when('isNested', {
              is: true,
              then: (schema) => schema.min(1)
            }),
            parentType: Yup.string().required("is required"),
            filterType: Yup.string().required("is required"),
            dataType: Yup.string().required("is required"),
            name: Yup.string().required("is required"),
            operator: Yup.string().required("is required"),
            value: Yup.mixed()
              .when(['dataType', 'operator'], {
                is: (dataType, operator) => {
                  if (dataType === 'DATE_TIME' && operator) {
                    return !(allowedTypesMap[dataType][operator] && allowedTypesMap[dataType][operator]['dateTypes'] && allowedTypesMap[dataType][operator]['dateTypes'].length === 0)
                  }

                  return (dataType === 'NUMERIC' ||  dataType === 'STRING' || dataType === 'BOOLEAN' || dataType === 'ARRAY_STRING')
                },
                then: (schema) => schema.required("is required"),
                otherwise: (schema) => schema.notRequired()
              })
          }) 
        ),
      })
    }).test('has-either-item', null, function (value) {
      const hasOutputColumns = Array.isArray(value.outputColumns) && value.outputColumns.length > 0;
      const hasFilters = value.includedFilters && Array.isArray(value.includedFilters.filters) && value.includedFilters.filters.length > 0;
      if (!hasOutputColumns && !hasFilters) {
        return this.createError({
          message: 'At least one item is required in outputColumns array or filters array',
          path: 'outputColumns', // You can use any field here, as this error is for the entire object
        });
      }
    
      return true;
    });

    return (
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <h4 className='text-nowrap'>Queries</h4>
        </Row>

        { isReadReportsEnabled ? (
          <Formik
            enableReinitialize
            initialValues={{ ...iValues }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm}) => {

              let iCFilters = values.includedFilters && values.includedFilters.filters.map((filter) => {
                if (filter.isNested) {
                  const filteredArray = filter.filters.filter((element) => element !== "");
                  
                  let fs = filteredArray.map((f) => {
                    delete f.isNested
                    delete f.filterOperator
                    return f
                  });
                 
                  const tfilterOption = filter.filterOperator;
                  delete filter.filters;
                  delete filter.isNested;
                  delete filter.filterOperator;
                  return { isNested: true, filterOperator: tfilterOption, filters: [...fs, filter] }
                }

                return filter;
              })

              const outputColumns = values.outputColumns;
              delete values.outputColumns;
             
              const req = {
                "request": { 'includedFilters': { filterOperator: values.includedFilters.filterOperator, filters: iCFilters } },
                "outputColumns": outputColumns
              }

              createQuery(req)
              resetForm();
            }}
          >
              {({ values, isValid, dirty, setFieldValue, resetForm }) => (
                <Form>
                  <Row className='p-2 w-100'>
                    <Row className='w-100 border rounded p-2 pt-4 pb-4'>
                      <Col className='col-2'><LightGrayLabel>Report Columns:</LightGrayLabel></Col>
                      <Col className='col-10'>
                        <FieldArray
                          name="outputColumns"
                          render={(arrayHelpers) => (
                            <>
                              {
                                values.outputColumns && values.outputColumns.length > 0 ? (
                                  values.outputColumns.map((column, index) => (
                                    <Row className='mb-2'>

                                      <Col className='col-2'>
                                        <Select
                                          name={`outputColumns.${index}.parentType`}
                                          className="custom-select"
                                          onChange={(event) => {
                                            const selectedType = event.target.value;
                                            setFieldValue(`outputColumns.${index}.parentType`, selectedType);
                                            setFieldValue(`outputColumns.${index}.filterType`, '');
                                          }}
                                        >
                                          <option>Select</option>
                                          {
                                            types.map((s) => {
                                              return <option key={s} value={s}>{s}</option>
                                            })
                                          }
                                        </Select>  
                                      </Col>

                                      { values && values.outputColumns && values.outputColumns[index].parentType && (
                                        <Col className='col-2'>
                                          <Select
                                            name={`outputColumns.${index}.filterType`}
                                            className="custom-select"
                                            onChange={(event) => {
                                              const selectedFilterType = event.target.value;
                                              setFieldValue(`outputColumns.${index}.filterType`, selectedFilterType);
                                              setFieldValue(`outputColumns.${index}.name`, '');
                                            }}
                                          >
                                            <option>Select</option>
                                            {
                                              Object.entries(filterMetaData[values.outputColumns[index].parentType]).map(([key, value]) => {
                                                return <option key={value.displayName} value={key}>{value.displayName}</option>
                                              })
                                            }
                                          </Select>
                                        </Col>
                                      )}

                                      { values && values.outputColumns && values.outputColumns[index].parentType && values.outputColumns[index].filterType && (
                                        <Col className='col-2'>
                                          <Select
                                            name={`outputColumns.${index}.name`}
                                            className="custom-select"
                                            onChange={(event) => {
                                              setFieldValue(`outputColumns.${index}.name`, event.target.value);
                                            }}
                                          >
                                            <option>Select</option>
                                            {
                                              Object.entries(filterMetaData[values.outputColumns[index].parentType][values.outputColumns[index].filterType]['DEFAULT']).map(([key, value]) => {
                                                return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                              })
                                            }
                                          </Select>
                                        </Col>
                                      )}

                                      { values && values.outputColumns && values.outputColumns[index].parentType && values.outputColumns[index].filterType && values.outputColumns[index].name && (
                                        <Col className='col-2'>
                                          <TextInput name={`outputColumns.${index}.displayName`} type='text' placeholder='Header(Optional)'/>
                                        </Col>
                                      )}
                                      
                                      <Col className='col-4'>
                                        <button type="button" className='btn btn-outline-danger ml-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                        { index === 0 && <button type="button" className='btn btn-outline-primary ml-2' onClick={() => arrayHelpers.insert(index, '')}> + Add </button> }
                                      </Col>
                                    </Row>
                                  ))
                                ) : (
                                  <Row className='justify-content-between ml-3 w-100'>
                                    <Button variant="btn btn-outline-primary" onClick={() => arrayHelpers.push('')}>+ Add</Button>
                                  </Row>
                                )
                              }
                            </>
                          )} 
                        />
                      </Col>
                    </Row>

                    <Row className='w-100 mt-2 p-2'>
                      <Col className='col-2'>
                        <LightGrayLabel>Operator:</LightGrayLabel>
                      </Col>

                      <Col className='col-10'>
                        <RadioButton
                          className='mb-3'
                          name='includedFilters.filterOperator'
                          options={filterOperations}
                          onChange={(e) => {
                            setFieldValue(`includedFilters.filterOperator`, e.target.value)
                            values.includedFilters.filters && values.includedFilters.filters.map((f, i) => {
                              if (f.isNested) {
                                setFieldValue(`includedFilters.filters.${i}.filterOperator`, e.target.value === 'OR' ? 'AND' : 'OR')
                              }
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Row>
                    
                  <FieldArray
                    name="includedFilters.filters"
                    render={(arrayHelpers) => (
                      <>
                        {
                          values.includedFilters.filters && values.includedFilters.filters.length > 0 ? (
                            values.includedFilters.filters.map((filter, index) => (
                              <Row className='border border-dark rounded p-2 mt-4' key={`outer-${index}`}>
                                
                                <FieldArray
                                  key={`outer-filter-${index}`}
                                  name={`includedFilters.filters.${index}.filters`}
                                  render={(aH) => (
                                    <>
                                    { 
                                      values.includedFilters.filters && values.includedFilters.filters[index].isNested && (
                                        <Row className='w-100 mt-2'>
                                          <Col className='col-10'>
                                            <RadioButton
                                              className='mb-3'
                                              name={`includedFilters.filters.${index}.filterOperator`}
                                              options={filterOperations} 
                                              onChange={(e) => {
                                                values.includedFilters.filters && values.includedFilters.filters.map((f, i) => {
                                                  if (f.isNested) {
                                                    setFieldValue(`includedFilters.filters.${i}.filterOperator`, e.target.value)
                                                  }
                                                })
                                                setFieldValue(`includedFilters.filterOperator`, e.target.value === 'OR' ? 'AND' : 'OR')
                                              }}
                                            />
                                          </Col>
                                        </Row>)
                                    }

                                    {
                                      values.includedFilters.filters[index].filters && values.includedFilters.filters[index].filters && values.includedFilters.filters[index].filters.length > 0 ? (
                                        filter.filters.map((nF, ind) => (
                                          <Row className='m-2 p-2 w-100 rounded border border-info'>
                                            <Row className='w-100'>
                                              <Col className='col-4'>
                                                <Select
                                                  name={`includedFilters.filters.${index}.filters.${ind}.parentType`}
                                                  className="custom-select mt-2"
                                                  onChange={(event) => {
                                                    const selectedType = event.target.value;
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.parentType`, selectedType);
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.filterType`, '');
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, null);
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                  }}
                                                >
                                                  <option>Select</option>
                                                  {
                                                    types.map((s) => {
                                                      return <option key={s} value={s}>{s}</option>
                                                    })
                                                  }
                                                </Select>  
                                              </Col>

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].parentType && (
                                                <Col className='col-4'>
                                                  <Select
                                                    name={`includedFilters.filters.${index}.filters.${ind}.filterType`}
                                                    className="custom-select mt-2"
                                                    onChange={(event) => {
                                                      const selectedFilterType = event.target.value;
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.filterType`, selectedFilterType);
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, null);
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.name`, '');
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                    }}
                                                  >
                                                    <option>Select</option>
                                                    {
                                                      Object.entries(filterMetaData[values.includedFilters.filters[index].filters[ind].parentType]).map(([key, value]) => {
                                                        return <option key={value.displayName} value={key}>{value.displayName}</option>
                                                      })
                                                    }
                                                  </Select>
                                                </Col>
                                              )}
                                          
                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].parentType && values.includedFilters.filters[index].filters[ind].filterType && (
                                                <Col className='col-4'>
                                                  <Select
                                                    name={`includedFilters.filters.${index}.filters.${ind}.name`}
                                                    className="custom-select mt-2"
                                                    onChange={(event) => {                                                      
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.name`, event.target.value);
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, event.target.options[event.target.selectedIndex].dataset.dtype)
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                    }}
                                                  >
                                                    <option>Select</option>
                                                    {
                                                      Object.entries(filterMetaData[values.includedFilters.filters[index].filters[ind].parentType][values.includedFilters.filters[index].filters[ind].filterType]['DEFAULT']).map(([key, value]) => {
                                                        return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                                      })
                                                    }
                                                  </Select>
                                                </Col>
                                              )}
                                            </Row>

                                            { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'BOOLEAN' && (
                                              <NestedBooleanUI 
                                                values={values}
                                                index={index}
                                                ind={ind}
                                                allowedTypesMap={allowedTypesMap}
                                                onOperatorChange={(v) => {
                                                  setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                  if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                  }
                                                }}
                                                onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, v)} 
                                              />
                                            )}

                                            { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'NUMERIC' && (
                                              <NestedNumericUI 
                                                values={values}
                                                index={index}
                                                ind={ind}
                                                allowedTypesMap={allowedTypesMap}
                                                onOperatorChange={(v) => {
                                                  setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                  if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                  }
                                                }}
                                              />
                                            )}

                                            { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && (values.includedFilters.filters[index].filters[ind].dataType === 'STRING') && (
                                              <NestedStringUI 
                                                values={values}
                                                index={index}
                                                ind={ind}
                                                accounts={accounts}
                                                allowedTypesMap={allowedTypesMap}
                                                onOperatorChange={(v) => {
                                                  setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                  if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                  }
                                                }}
                                              />
                                            )}

                                            { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'ARRAY_STRING' && (
                                              <NestedArrayStringUI 
                                                values={values}
                                                index={index}
                                                ind={ind}
                                                allowedTypesMap={allowedTypesMap}
                                                onOperatorChange={(v) => {
                                                  setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                  if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                  }
                                                }}
                                              />
                                            )}

                                            { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'DATE_TIME' && (
                                              <NestedDateUI 
                                                values={values}
                                                index={index}
                                                ind={ind}
                                                allowedTypesMap={allowedTypesMap}
                                                onOperatorChange={(v) => {
                                                  setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                  if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                  }
                                                }}
                                                onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.filters.${ind}.valueType`, v)}
                                              />
                                            )}

                                            <Row className='mt-2'>
                                              <button type="button" className='btn btn-outline-danger ml-3'
                                                onClick={() => {
                                                  aH.remove(index)
                                                  index === 0 && setFieldValue(`includedFilters.filters.${index}.isNested`, false)
                                                }
                                              }>Remove</button>

                                              { ind === 0 && 
                                                <button 
                                                  type="button" 
                                                  className='btn btn-outline-info ml-2' 
                                                  disabled={!isValid || !dirty} 
                                                  onClick={() => {
                                                    aH.insert(index, '')
                                                    setFieldValue(`includedFilters.filters.${index}.isNested`, true)
                                                    setFieldValue(`includedFilters.filters.${index}.filterOperator`, values.includedFilters.filterOperator === 'AND' ? 'OR' : 'AND')
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.type`, 'inner')

                                                  }}> + Inner Nested
                                                </button> }
                                            </Row>
                                          </Row>
                                        ))
                                      ) : (
                                        <Row className='ml-2 mt-3 w-100'>
                                          <Button 
                                            variant="btn btn-outline-info" 
                                            disabled={!isValid || !dirty}
                                            onClick={() => {
                                              aH.push('')
                                              setFieldValue(`includedFilters.filters.${index}.isNested`, true)
                                              setFieldValue(`includedFilters.filters.${index}.filterOperator`, values.includedFilters.filterOperator === 'AND' ? 'OR' : 'AND')
                                              setFieldValue(`includedFilters.filters.${index}.filters.0.type`, 'inner')
                                            }}>+ Inner Nested
                                          </Button>
                                        </Row>
                                      )
                                    }
                                    </>
                                  )}
                                />



                                <Row className='ml-2 w-100'>
                                  <Row className='w-100'>
                                    <Col className='col-4'>
                                      <Select
                                        key={`parentType-outer-${index}`}
                                        name={`includedFilters.filters.${index}.parentType`}
                                        className="custom-select mt-2"
                                        onChange={(event) => {
                                          const selectedType = event.target.value;
                                          
                                          if (selectedType !== 'Select') {
                                            setFieldValue(`includedFilters.filters.${index}.parentType`, selectedType);
                                            setFieldValue(`includedFilters.filters.${index}.filterType`, '');
                                            setFieldValue(`includedFilters.filters.${index}.dataType`, null);
                                            setFieldValue(`includedFilters.filters.${index}.operator`, null);
                                            setFieldValue(`includedFilters.filters.${index}.valueType`, null);
                                            setFieldValue(`includedFilters.filters.${index}.value`, null)
                                            setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                          } else {
                                            setFieldValue(`includedFilters.filters.${index}.parentType`, null);
                                          }
                                        }}
                                      >
                                        <option>Select</option>
                                        {
                                          types.map((s) => {
                                            return <option key={s} value={s}>{s}</option>
                                          })
                                        }
                                      </Select>  
                                    </Col>

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && filterMetaData[values.includedFilters.filters[index].parentType] && (
                                      <Col className='col-4'>
                                        <Select
                                          name={`includedFilters.filters.${index}.filterType`}
                                          className="custom-select mt-2"
                                          onChange={(event) => {
                                            const selectedFilterType = event.target.value;
                                            setFieldValue(`includedFilters.filters.${index}.filterType`, selectedFilterType);
                                            setFieldValue(`includedFilters.filters.${index}.dataType`, null);
                                            setFieldValue(`includedFilters.filters.${index}.name`, '');
                                            setFieldValue(`includedFilters.filters.${index}.value`, null)
                                            setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                          }}
                                        >
                                          <option>Select</option>
                                          {
                                            Object.entries(filterMetaData[values.includedFilters.filters[index].parentType]).map(([key, value]) => {
                                              return <option key={value.displayName} value={key}>{value.displayName}</option>
                                            })
                                          }
                                        </Select>
                                      </Col>
                                    )}
                                
                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].filterType && (
                                      <Col className='col-4'>
                                        <Select
                                          name={`includedFilters.filters.${index}.name`}
                                          className="custom-select mt-2"
                                          onChange={(event) => {
                                            setFieldValue(`includedFilters.filters.${index}.name`, event.target.value);
                                            setFieldValue(`includedFilters.filters.${index}.dataType`, event.target.options[event.target.selectedIndex].dataset.dtype)
                                            setFieldValue(`includedFilters.filters.${index}.value`, null)
                                            setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                          }}
                                        >
                                          <option>Select</option>
                                          {
                                            Object.entries(filterMetaData[values.includedFilters.filters[index].parentType][values.includedFilters.filters[index].filterType]['DEFAULT']).map(([key, value]) => {
                                              return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                            })
                                          }
                                        </Select>
                                      </Col>
                                    )}
                                  </Row>

                                  { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'BOOLEAN' && (
                                    <BooleanUI 
                                      values={values}
                                      index={index}
                                      allowedTypesMap={allowedTypesMap}
                                      onOperatorChange={(v) => {
                                        setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                        if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                          setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                        }
                                      }}
                                      onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.value`, v)} 
                                    />
                                  )}

                                  { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'NUMERIC' && (
                                    <NumericUI 
                                      values={values}
                                      index={index}
                                      allowedTypesMap={allowedTypesMap}
                                      onOperatorChange={(v) => {
                                        setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                        if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                          setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                        }
                                      }}
                                    />
                                  )}

                                  { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].dataType && (values.includedFilters.filters[index].dataType === 'STRING') && (
                                    <StringUI 
                                      accounts={accounts}
                                      values={values}
                                      index={index}
                                      allowedTypesMap={allowedTypesMap}
                                      onOperatorChange={(v) => {
                                        setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                        if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                          setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                        }
                                      }}
                                    />
                                  )}

                                  { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'ARRAY_STRING' && (
                                    <ArrayStringUI 
                                      values={values}
                                      index={index}
                                      allowedTypesMap={allowedTypesMap}
                                      onOperatorChange={(v) => {
                                        setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                        if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                          setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                        }
                                      }}
                                    />
                                  )}

                                  { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'DATE_TIME' && (
                                    <DateUI 
                                      values={values}
                                      index={index}
                                      allowedTypesMap={allowedTypesMap}
                                      onOperatorChange={(v) => {
                                        setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                        if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                          setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                        }
                                      }}
                                      onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.valueType`, v)}
                                    />
                                  )}

                                  <Row className='mt-2'>
                                    <button type="button" className='btn btn-outline-danger ml-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                    { index === 0 && <button 
                                      type="button" 
                                      className='btn btn-outline-primary ml-2'
                                      disabled={!isValid || !dirty}
                                      onClick={() => {
                                        arrayHelpers.insert(index, '')
                                        setFieldValue(`includedFilters.filters.${index}.type`, 'outer')
                                        setFieldValue(`includedFilters.filters.${index}.parentType`, 'Select')
                                      }}> + Outer Filter </button> }
                                  </Row>
                                </Row>
                              </Row>
                            ))
                          ) : (
                            <Row className='mt-3 w-100'>
                              <Button 
                                variant="btn btn-outline-primary" 
                                onClick={() => {
                                  arrayHelpers.push('')
                                  setFieldValue(`includedFilters.filters.0.type`, 'outer')
                                }}>+ Outer Filter</Button>
                            </Row>
                          )
                        }
                      </>
                    )}
                  />

                  <Row className='mt-3'>
                    <Button variant="primary" type='submit' disabled={!isValid || !dirty} >Get Report</Button>
                    <Button 
                      variant="btn btn-outline-secondary"
                      className='ml-2' 
                      onClick={() => { 
                        getQuerySuccess(null);
                        resetForm();
                      }}>Cancel
                    </Button>
                  </Row>
                </Form>
              )}
          </Formik>
        ) : <Row>{Strings.permissions.message}</Row> }
      </Col>
    )
  }


  onShowEditingQueryModal = (query) => {
    this.setState({
      isEditingQuery: true,
      editingQuery: query
    })
  }

  onHideEditingQueryModal = () => {
    this.setState({
      isEditingQuery: false,
      editingQuery: null
    })
  }


  renderQueries () {
    let { queries, currentPage, totalCount, accounts } = this.props
    return (
      <Col md={12}>
        <Row>
          <QueryListing
            accounts={accounts}
            queries={queries}
            currentpage={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            getQueries={this.props.getQueries}
            onEdit={this.onShowEditingQueryModal}
            onSave={this.onShowSaveTemplateModal}
            onShowInfo={this.onShowInfo}
          />
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isReadReportsEnabled: isPermissionEnabled(state.user, PERMISSIONS.REPORT_QUERIES),
    types: state.report.types,
    query: state.report.query,
    queries: state.report.queries,
    currentPage: state.report.currentPage,
    totalCount: state.report.totalCount,
    fetchingMetaData: state.report.fetchingMetaData,
    fetchingQueries: state.report.fetchingQueries,
    fetchingQuery: state.report.fetchingQuery,
    allowedTypesMap: state.report.allowedTypesMap,
    filterMetaData: state.report.filterMetaData,
    createReportTemplateFailureMessage: state.report.createReportTemplateFailureMessage,
    template: state.report.template,
    accounts: state.account.accounts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccounts: (pageNumber) => dispatch(AccountActions.getAccounts(pageNumber)),
    getQueries: (pageNumber) => dispatch(ReportActions.getQueries(pageNumber)),
    getQuery: (queryId) => dispatch(ReportActions.getQuery(queryId)),
    getSearchMetaData: () => dispatch(ReportActions.getSearchMetaData()),
    createQuery: (params) => dispatch(ReportActions.createQuery(params)),
    createReportTemplate: (params) => dispatch(ReportActions.createReportTemplate(params)),
    createReportTemplateFailure: (message) => dispatch(ReportActions.createReportTemplateFailure(message)),
    getQuerySuccess: (query) => dispatch(ReportActions.getQuerySuccess(query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);