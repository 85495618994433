import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import BaseForm from "components/Patient/BaseForm";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class IncomeForm extends React.Component {
  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.income
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;

    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, setFieldValue }) => {
          let getSum = (ele, eleVal, values) => {
            let s = 0
            for (const [key, value] of Object.entries(values)) {
              if (key !== 'totalHouseholdResources' && key !== ele && (key === 'disability' || key === 'tanf' || key === 'disabilityLifeLine' || key === 'retirement' || key === 'ssaORssdi' || key === 'snap' || key === 'wages')) {
                s += Number(value)
              }         
            }
            s += eleVal
            return s
          }
            return (
            <>
              <Prompt when={dirty} message={Strings.prompt.message} />
              <Form className="row mr-1">
                <BaseForm updatingForm={updatingForm} disabled={!write}>
                  <div>
                    <Row className="row-cols-xs-1 row-cols-md-2">
                      <Col className="p-0 col-12">
                        <label className="input-label">Source Of Income:</label>
                      </Col>

                      <Col className="col-xs-10 col-md-10">
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 mx-n2 mx-md-n3 mx-lg-n4">
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasDisability">
                                Disability
                              </Checkbox>
                              <TextInput
                                className="mt-2"
                                name="disability"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`disability`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('disability',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>

                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasTanf">TANF</Checkbox>
                              <TextInput
                                className="mt-2"
                                name="tanf"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`tanf`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('tanf',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasDisabilityLifeLine">
                                Disability Life Line
                              </Checkbox>
                              <TextInput
                                className="mt-2"
                                name="disabilityLifeLine"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`disabilityLifeLine`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('disabilityLifeLine',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasRetirement">
                                Retirement
                              </Checkbox>
                              <TextInput
                                className="mt-2"
                                name="retirement"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`retirement`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('retirement',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasSsaORssdi">SSA/SSDI</Checkbox>
                              <TextInput
                                className="mt-2"
                                name="ssaORssdi"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`ssaORssdi`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('ssaORssdi',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasSnap">SNAP</Checkbox>
                              <TextInput
                                className="mt-2"
                                name="snap"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`snap`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('snap',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasWages">Wages/Salary</Checkbox>
                              <TextInput
                                className="mt-2"
                                name="wages"
                                placeholder="$"
                                onChange={(event) => {
                                  setFieldValue(`wages`, Number(event.target.value));
                                  setFieldValue(`totalHouseholdResources`, getSum('wages',  Number(event.target.value), values));
                                }}
                              />
                            </Row>
                          </Col>
                          <Col className="mb-2 px-2 px-sm-2 px-md-3 px-lg-4">
                            <Row>
                              <Checkbox name="hasNone">None</Checkbox>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-2 row-cols-2 row-cols-xs-1 row-cols-md-2">
                      <Col className="p-0 col-xs-12 col-md-4">
                        <label
                          className="input-label"
                          htmlFor="totalHouseholdResources"
                        >
                          Total Household Resources:
                        </label>
                      </Col>
                      <Col className="p-0 col-xs-12 col-md-8">
                        <TextInput
                          className="w-100"
                          readonly="readonly"
                          name="totalHouseholdResources"
                          placeholder="Household Resources"
                        />
                      </Col>
                    </Row>
                  </div>
                </BaseForm>
              </Form>
            </>
          );
        }}
      />
    );
  }
}
