import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class EmploymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    console.log('c values: ' + JSON.stringify(values))

    const params = values.employmentHistory && values.employmentHistory.filter((e) => e);
    
    console.log('c params: ' + JSON.stringify(params))

    this.props.onUpdate(
      this.props.clientID,
      { ...values, employmentHistory: params, clientId: this.props.clientID },
      CLIENT_BFET_FORM_TYPES.employmentHistory
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <FieldArray
                  name="employmentHistory"
                  render={(arrayHelpers) => (
                    <>
                      {values.employmentHistory &&
                      values.employmentHistory.length > 0 ? (
                        values.employmentHistory.map((eh, index) => (
                          <div className="w-100" key={index}>
                            <hr />
                            <Row className="align-items-center mb-3 mt-2">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                                <label
                                  className="input-label"
                                  htmlFor="employerName"
                                >
                                  Employer:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                                <TextInput
                                  className="mb-0"
                                  name={`employmentHistory.${index}.employerName`}
                                  type="text"
                                  placeholder="Employer Name"
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                                <label
                                  className="input-label"
                                  htmlFor="employerName"
                                >
                                  Employment Dates:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8 p-0">
                                <TextInput
                                  className="mb-2 col-12"
                                  name={`employmentHistory.${index}.employmentStartDate`}
                                  type="date"
                                  placeholder="From"
                                />
                                <TextInput
                                  className="mb-0 col-12"
                                  name={`employmentHistory.${index}.employmentEndDate`}
                                  type="date"
                                  placeholder="To"
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                              <label
                                  className="input-label"
                                  htmlFor="employerName"
                                >
                                  Wages
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                              <TextInput
                                  className="mb-0 "
                                  name={`employmentHistory.${index}.wages`}
                                  type="text"
                                  placeholder="Wages"
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                              <label
                                  className="input-label"
                                  htmlFor="employerName"
                                >
                                  Hours Per Week:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                              <TextInput
                                  className="mb-0 "
                                  name={`employmentHistory.${index}.hoursPerWeek`}
                                  type="text"
                                  placeholder="Hrs"
                                />
                              </Col>
                            </Row>
                          
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                                <label
                                  className="input-label"
                                  htmlFor="jobTitle"
                                >
                                  Job Title:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                                <TextInput
                                  className="mb-0"
                                  name={`employmentHistory.${index}.jobTitle`}
                                  type="text"
                                  placeholder="Job Title"
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                                <label
                                  className="input-label"
                                  htmlFor="workPerformed"
                                >
                                  Work Performed:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                                <TextMessage
                                  className="mb-0"
                                  name={`employmentHistory.${index}.workPerformed`}
                                  type="text"
                                  placeholder="Work Performed"
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                              <Col className="col-2 col-xs-12 col-lg-2 col-md-12 mr-4">
                                <label
                                  className="input-label"
                                  htmlFor="reasonForLeaving"
                                >
                                  Reason For Leaving:
                                </label>
                              </Col>
                              <Col className="col-10 col-xs-12 col-md-8">
                                <TextInput
                                  className="mb-0"
                                  name={`employmentHistory.${index}.reasonForLeaving`}
                                  type="text"
                                  placeholder="Reason"
                                />
                              </Col>
                            </Row>
                            <button
                              type="button"
                              className="btn btn-outline-danger ml-3 mr-3"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              {" "}
                              Remove{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => arrayHelpers.insert(index, "")}
                            >
                              {" "}
                              Add{" "}
                            </button>
                          </div>
                        ))
                      ) : (
                        <Col className="col-12 d-flex mb-3">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => arrayHelpers.push("")}
                          >
                            {" "}
                            Add Employer
                          </button>
                        </Col>
                      )}
                    </>
                  )}
                />
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
