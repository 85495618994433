import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class PhysicalAndMedicalHealth extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Physical And Medical
            Health:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Physical And Medical
            Health:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>AIDS</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAIDS)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Colds/Cough</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasColdsOrCough)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Hepatitis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHepatitis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sexually transmitted diseases</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSexuallyTransmittedDiseases)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Alcoholism</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAlcoholism)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Constipation</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasConstipation)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>High blood pressure</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHighBloodPressure)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sinusitis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSinusitis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Abdominal pain</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAbdominalPain)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Dental problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDentalProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Kidney problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasKidneyProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sleeping disorders</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSleepingDisorders)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Abortion</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAbortion)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Diabetes</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDiabetes)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Measles</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMeasles)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Smallpox</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSmallpox)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Allergies (List Below)</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAllergies)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Diarrhea</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDiarrhea)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Menstrual pain</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMenstrualPain)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sore throat</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSoreThroat)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Anemia</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAnemia)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Dizziness</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDizziness)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Miscarriages</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMiscarriages)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Stroke</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasStroke)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Appendicitis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAppendicitis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Drug abuse</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasDrugAbuse)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>Mononucleosis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMononucleosis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>Thyroid problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasThyroidProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>Arthritis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasArthritis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Ear infections</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasEarInfections)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Mumps</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMumps)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel> Tonsilitis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasTonsilitis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Asthma</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasAsthma)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Eating problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasEatingProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel> Nausea</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasNausea)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Toothache</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasToothache)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Bronchitis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasBronchitis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Epilepsy</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasEpilepsy)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Neurological disorders</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasNeurologicalDisorders)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Tuberculosis</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasTuberculosis)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Bedwetting</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasBedwetting)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Fainting</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasFainting)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Nose bleeds</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasNoseBleeds)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Vision problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasVisionProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Cancer</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasCancer)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Fatigue</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasFatigue)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Pnemonia</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasPneumonia)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Whooping cough</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasWhoopingCough)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Chest pain</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasChestPain)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Frequent Urination</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasFrequentUrination)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Rheumatic Fever</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasRheumaticFever)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Chicken Pox</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasChickenPox)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Headaches</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHeadaches)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Scarlet Fever</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasScarletFever)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Chronic pain</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasChronicPain)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Hearing problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasHearingProblems)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sexual problems</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasSexualProblems)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Memory impairment</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.hasMemoryImpairment)}</label>
              </Col>
            </Col>

            <Col className="mb-3 d-flex align-items-center">
              <Col className="col-12 p-0">
                <Col className="col-12 p-0">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0">
                  <label className="input-label">{isBoolean(data.hasOther)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Specify</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.specifyOther}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  List Any Current Health Concerns
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.healthConcerns}</label>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  List Any Recent Health Or Physical Changes
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentPhysicalChanges}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
