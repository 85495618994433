import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class employmentHistory extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Employment History:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable">
      <Row className="pb-2 w-100">
        <Col>BFET &gt; Employment History: </Col>
        {write && (
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
        )}
        <ReactToPrint
          // eslint-disable-next-line
          trigger={() => <a href="javascript:void();">Print</a>}
          content={() => this.componentRef}
        />
      </Row>
        {data &&
          data.employmentHistory &&
          data.employmentHistory.map((eh) => (
            <Row className='border p-2 mb-2' >
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Employer:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.employerName}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Employment start Dates:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.employmentStartDate}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Employment end Dates:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.employmentEndDate}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Wages:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.wages}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Hours Per Week:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.hoursPerWeek}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Job Title:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.jobTitle}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Work Performed:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.workPerformed}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Reason For Leaving:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label>{eh.reasonForLeaving}</label>
                </Col>
              </Row>
            </Row>
          ))}
      </Row>
    );
  }
}
