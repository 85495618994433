import React from "react";
import Layout from "components/Layout.jsx";
import { Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import ClientActions from "redux/ClientRedux";
import PatientBasicDetails from "components/Patient/PatientBasicDetails";
import { CientDetailsText } from "pages/Styles/Patient";
import { TOKEN_TYPE } from 'redux/UserRedux'
import ReEntryIntakeView from "components/Patient/Reentry/Intake/View";
import ReEntryConsentView from "components/Patient/Reentry/Consent/View";
import ReEntryDocumentsView from "components/Patient/Reentry/Documents/View";
import ReEntryNotesView from "components/Patient/Reentry/Notes/View";
import EditClientModal from "components/Modals/EditClientModal";
import BFETIntakeView from "components/Patient/BFET/Intake/View";
import BFETJobSearchView from "components/Patient/BFET/JobSearchLog/View";
import BFETDocumentsView from "components/Patient/BFET/Documents/View";
import BFETNotesView from "components/Patient/BFET/Notes/View";
import MentalHealthPatientView from "components/Patient/MentalHealth/PersonalHistory/View";
import MentalHealthDocumentView from "components/Patient/MentalHealth/Documents/View";
import MentalHealthNotesView from "components/Patient/MentalHealth/Notes/View";
import DefaultNotesView from "components/Patient/VisitorNotes/View";
import LoadingView from "components/LoadingView.jsx";
import DVRNotesView from "components/Patient/DVR/Notes/View";
import DVRDocuments from "components/Patient/DVR/Documents/View";
import DVRServiceDelivery from "components/Patient/DVR/ServiceDelivery/View";
import DVRVocational from "components/Patient/DVR/Vocational/View";
import ReactToPrint from "react-to-print";
import Supplies from "components/Patient/Supplies/View";
import AddReferModal from "components/Modals/ReferModal"
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class EditPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      clientID: props.match.params.id,
      referred: false,
      referralDisabled: true,
      showReferModal: false,
      copyText: 'Copy Share Link',
      showEditClientModal: false
    };
  }

  handleReferral = () => {
    this.setState({
      showReferModal: true
    })
  }

  onCloseRefer = () => {
    this.setState({
      showReferModal: false
    })
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ copyText: 'Copied' })
    setTimeout(() => {
      this.setState({ copyText: 'Copy Share Link' })
    }, 2000)
  };

  componentDidMount() {
    this.props.isUserToken && this.props.getAccountClientUrl(this.props.match.params.id);
    this.setState({ clientID: this.props.match.params.id }, () => {
      this.props.getClient(this.props.match.params.id);
    });
  }

  sendClientReferral = () => {
    this.props.sendClientReferral(this.state.clientID)
  }

  renderLoading = () => {
    return <LoadingView />;
  };


  editClient = () => {
    this.setState({
      showEditClientModal: true
    })
  }

  onCloseEdiClienttModal = () => {
    this.setState({
      showEditClientModal: false
    })
  }

  render = () => {
    const { clientID, showReferModal, showEditClientModal } = this.state;
    const { client,
      clientReferralUrl,
      isUserToken,
      isWriteBfetEnabled,
      isReadBfetEnabled,
      isWriteMHEnabled,
      isReadMHEnabled,
      isWriteDVREnabled,
      isReadDVREnabled,
      isWriteDefaultNotesEnabled,
      isReadDefaultNotesEnabled,
      isWriteDefaultSuppliesEnabled,
      isReadDefaultSuppliesEnabled,
      isWriteConsentEnabled,
      isWritereEntryVisitorNotesEnabled,
      isReadreEntryVisitorNotesEnabled,
      isWritereEntryDocumentsEnabled,
      isReadreEntryDocumentsEnabled,
      isWriteBfetJobSearchEnabled,
      isReadBfetJobSearchEnabled,
      isWriteBfetNotesEnabled,
      isReadBfetNotesEnabled,
      isWriteBfetDocumentsEnabled,
      isReadBfetDocumentsEnabled,
      isWriteMentalVisitorNotesEnabled,
      isReadMentalVisitorNotesEnabled,
      isWriteMentalDocumentsEnabled,
      isReadMentalDocumentsEnabled,
      isWriteDVRDocumentsEnabled,
      isReadDVRDocumentsEnabled,
      isWriteDVRNotesEnabled,
      isWriteDVRVocationalEnabled,
      isReadDVRVocationalEnabled,
      postingClientPicture
    } = this.props;

    return (
      <Layout>
        <Col md={12} id="clientWrapper">
          <div ref={(el) => (this.componentRef = el)} class="printable">
            <Row className="mb-3 justify-content-between">
              <CientDetailsText className="c-details col-2 p-0">
                Client Details
              </CientDetailsText>
              <Col className="col-5 d-flex justify-content-end p-0 print-hide">
                { isUserToken && 
                  <>
                    <Button
                      className="btn btn-primiary ml- 3mr-3"
                      onClick={this.handleReferral}
                    >
                      Refer
                    </Button>

                    <Button
                      className="btn btn-primiary mr-3 ml-3"
                      onClick={this.sendClientReferral}
                    >
                      Share Link Via Email
                    </Button>

                    <input ref={(textarea) => this.textArea = textarea} className='w-100' style={{ opacity: .01,  height: 0, position: 'absolute', 'z-index': -1 }} value={clientReferralUrl} type='text' />
                    <Button className="btn btn-primiary mr-3" onClick={this.copyToClipboard}>{this.state.copyText}</Button>
                  </>
                }
                <ReactToPrint
                // eslint-disable-next-line
                  trigger={() => <Button>Print</Button>}
                  content={() => this.componentRef}
                />
              </Col>
            </Row>
            {client && (
              <PatientBasicDetails
                client={client}
                identifyingInformation={this.props.reentry.identifyingInformation || {}}
                clientID={clientID}
                postingClientPicture={postingClientPicture}
                postClientPicture={this.props.postClientPicture}
                editClient={this.editClient}
              />
            )}
            <Row className="mb-3 d-flex">
              {/* <Col
                md={2}
                className="border px-0 shadow rounded"
                style={{ height: "fit-content" }}
              >
                <PatientViewSideBar clientID={clientID} />
              </Col> */}
              <Col className="pr-0">
                <ReEntryIntakeView clientID={clientID} client={client}/>
                { (isWriteConsentEnabled || isWriteConsentEnabled) && <ReEntryConsentView clientID={clientID} /> }
                { (isWritereEntryDocumentsEnabled || isReadreEntryDocumentsEnabled) && <ReEntryDocumentsView clientID={clientID} /> }
                { (isWritereEntryVisitorNotesEnabled || isReadreEntryVisitorNotesEnabled) && <ReEntryNotesView clientID={clientID} />}

                { (isWriteBfetEnabled || isReadBfetEnabled) && <BFETIntakeView clientID={clientID} />}
                { (isWriteBfetJobSearchEnabled || isReadBfetJobSearchEnabled) && <BFETJobSearchView clientID={clientID} />}
                { (isWriteBfetDocumentsEnabled || isReadBfetDocumentsEnabled) && <BFETDocumentsView clientID={clientID} />}
                { (isWriteBfetNotesEnabled || isReadBfetNotesEnabled) && <BFETNotesView clientID={clientID} /> }

                { ( isWriteMHEnabled || isReadMHEnabled ) && <MentalHealthPatientView clientID={clientID} />}

                { ( isWriteMentalDocumentsEnabled ||isReadMentalDocumentsEnabled ) && <MentalHealthDocumentView clientID={clientID} />}
                { ( isWriteMentalVisitorNotesEnabled||isReadMentalVisitorNotesEnabled ) && <MentalHealthNotesView clientID={clientID} />}

                { ( isWriteDVREnabled || isReadDVREnabled ) && <DVRServiceDelivery clientID={clientID} /> }
                { ( isWriteDVRVocationalEnabled || isReadDVRVocationalEnabled ) && <DVRVocational clientID={clientID} /> }
                { ( isWriteDVRNotesEnabled || isWriteDVRNotesEnabled ) && <DVRNotesView clientID={clientID} /> }
                { ( isWriteDVRDocumentsEnabled || isReadDVRDocumentsEnabled ) && <DVRDocuments clientID={clientID} /> }

                { (isWriteDefaultNotesEnabled || isReadDefaultNotesEnabled) && <DefaultNotesView clientID={clientID} /> }
                { (isWriteDefaultSuppliesEnabled || isReadDefaultSuppliesEnabled) && <Supplies clientID={clientID} /> }
              </Col>
            </Row>
            { showEditClientModal && <EditClientModal client={client} onClose={this.onCloseEdiClienttModal} updateClient={this.props.updateClient} updatingClient={this.props.updatingClient}/> }
          </div>
        </Col>
        { showReferModal && <AddReferModal onClose={this.onCloseRefer} clientID={clientID} referClient={this.props.referClient} referringClientInProgress={this.props.referringClientInProgress}/>}
      </Layout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    client: state.client.client,
    reentry: state.client.reentry,
    referringClientInProgress: state.client.referringClientInProgress,
    clientReferralUrl: state.client.clientReferralUrl,
    isUserToken: (state.user.tokenType === TOKEN_TYPE.ACCOUNT_USER_TOKEN),

    isWriteBfetEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET
    ),
    isReadBfetEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET
    ),

    isWriteMHEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH
    ),
    isReadMHEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH
    ),

    isWriteDVREnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR
    ),
    isReadDVREnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR
    ),

    isWriteDefaultNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_NOTES
    ),
    isReadDefaultNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_NOTES
    ),

    isWriteDefaultSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_SUPPLIES
    ),
    isReadDefaultSuppliesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_SUPPLIES
    ),

    isWriteConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_CONSENT
    ),
    isReadConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_CONSENT
    ),

    isWritereEntryVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_NOTES
    ),
    isReadreEntryVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_NOTES
    ),
    isWritereEntryDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_DOCUMENTS
    ),
    isReadreEntryDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_DOCUMENTS
    ),

    isWriteBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_JOB_SEARCH_INFO
    ),
    isReadBfetJobSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_JOB_SEARCH_INFO
    ),
    isWriteBfetNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_NOTES
    ),
    isReadBfetNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_NOTES
    ),
    isWriteBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_DOCUMENTS
    ),
    isReadBfetDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_DOCUMENTS
    ),

    isWriteMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_NOTES
    ),
    isReadMentalVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_NOTES
    ),
    isWriteMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DOCUMENTS
    ),
    isReadMentalDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DOCUMENTS
    ),
    
    isWriteDVRDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_DOCUMENTS
    ),
    isReadDVRDocumentsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_DOCUMENTS
    ),
    isWriteDVRNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_NOTES
    ),
    isReadDVRNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_NOTES
    ),

    isWriteDVRVocationalEnabled: isPermissionEnabled(state.user, PERMISSIONS.WRITE_DVR_VOCATIONAL),
    isReadDVRVocationalEnabled: isPermissionEnabled(state.user, PERMISSIONS.READ_DVR_VOCATIONAL),
    updatingClient: state.client.updatingClient,

    postingClientPicture: state.client.postingClientPicture
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClient: (id) => dispatch(ClientActions.getClient(id)),
    postClientPicture: (id, params) =>
      dispatch(ClientActions.postClientPicture(id, params)),
    referClient: (id, params) => dispatch(ClientActions.referClient(id, params)),
    getAccountClientUrl: (id) => dispatch(ClientActions.getAccountClientUrl(id)),
    sendClientReferral: (id) => dispatch(ClientActions.sendClientReferral(id)),
    updateClient: (id, params) => dispatch(ClientActions.updateClient(id, params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPatient);
