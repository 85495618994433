import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class SpritualReligion extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Spritual Religion:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Spritual Religion:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <ViewHeading>
                Are there special, unusual, or traumatic circumstances that
                affected your development?:{" "}
              </ViewHeading>
            </Col>
            <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-1 row-cols-lg-4">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Not</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.noSpiritualImportance)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Little</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.littleSpiritualImportance)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Somewhat</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.somewhatSpiritualImportance)}</label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Very</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.highSpiritualImportance)}</label>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Are you affiliated with a spiritual/religious group?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.isAffiliatedWithSpiritualGroup)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Yes, Describe</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.spiritualGroupAffiliationDescription}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Were you raised within a spiritual/religious group?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.isRaisedWithinSpiritualGroup)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Yes, Describe</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.raisedWithinSpiritualGroupDescription}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Would you like your spiritual/religious beliefs incorporated
                into your counseling?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.spiritualBeliefInCounselling)}</label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
