import styled from 'styled-components';

export const ImageTag = styled.img`
  border-radius: 16px;
  margin: 22px;
  border: 2px solid #F2F4F8;
`

export const ImageCircleTag = styled.img`
  border-radius: 50px;
  border: 2px solid #F2F4F8;
`
export const CientDetailsText = styled.span`
  font-size: 24px;
`

export const BreadcrumbContainer = styled.div`

`