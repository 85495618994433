import React from 'react';
import { Navbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import AuthActions from 'redux/AuthRedux'
import { HeaderStyles, BrandName } from 'components/Styles/HeaderStyles'
import Images from 'assets';

export class Header extends React.Component {
  constructor (props) {
    super(props)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout = (e) => {
    e.preventDefault()
    this.props.logout()
  }

  render () {
    return (
      <HeaderStyles id="header">
        <Navbar className='w-100 justify-content-between'>
          <Navbar.Brand>
            <BrandName>{(this.props.accountInfo && this.props.accountInfo.accountName) || 'AXION GALAXY'}</BrandName>
          </Navbar.Brand>
          { this.props.isUserToken ? (<div>
            <img alt='' src={this.props.profilePictureDownloadURL || Images['avatar']} className='mr-2' width={30} height={30} onError={({ currentTarget }) => {currentTarget.src=`${this.props.profilePictureDownloadURL}`}} />
            <span className='mr-4'>{this.props.userName}</span>
          </div>) : (
          // eslint-disable-next-line
          <div> <a className='mr-4' href="javascript:void(0);" onClick={this.props.exit}>Exit</a></div>) }
        </Navbar>
      </HeaderStyles>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.user.accountInfo,
    email: state.user.email,
    userName: (state.user.firstName +  ' ' + state.user.lastName),
    profilePictureDownloadURL: state.user.profilePictureDownloadURL
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (email) => dispatch(AuthActions.logoutRequest()),
    exit: () => dispatch(AuthActions.exit())
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);