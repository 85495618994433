/**
 * All API urls and other constants will reside here.
 * Works for remote REST APIS and local data 
 **/

// Actual endpoints. Uncomment below section to use actual data.
export const LOGIN_URL = `/accounts/login`
export const GET_PASSWORD_URL = '/accounts/password'
export const SIGN_UP_URL = `/accounts`
export const LOGOUT_URL = `/accounts/logout`
export const TOKEN_INFO_URL = '/token_info'
export const SEND_EMAIL_URL = `/send_email`
export const UPDATE_PASSWORD_URL = `/update_password`
export const GET_ACCOUNTS_URL = '/accounts/users/'
export const GET_APPOINTMENTS_URL = '/appointments/'
export const GET_ROLES_URL = '/accounts/roles/'
export const GET_ACCOUNT_CLIENT_URL = '/accounts/client/'
export const GET_QUERIES_URL = '/queries'

// Clients
export const GET_CLIENTS_URL = '/clients/'


// Local endpoints. Uncomment below section to use dummy local data.
// export const GET_ALL_USERS = `/data/servers`;
