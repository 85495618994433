import { merge } from "rxjs";
import API from 'services/api'

import {
  loginEpic,
  signUpEpic,
  sendEmailEpic,
  updatePasswordEpic,
  logoutEpic,
  loginSuccessEpic,
  logoutSuccessEpic,
  validatePasswordEpic,
  exitEpic
} from 'epics/authEpics'

import {
  appLoadEpic,
  userTokenValidEpic,
  userTokenInValidEpic,
  userTokenValidatedEpic,
  getUserInfoEpic,
  updateUserInfoEpic,
} from 'epics/userEpic'

import {
  clientReferEpic,
  getClientEpic,
  getClientsEpic,
  createClientEpic,
  updateClientEpic,
  deleteClientEpic,
  searchClientEpic,
  getClientReentryIntakeInformationEpic,
  getClientBfetInformationEpic,
  getClientConsentInformationEpic,

  getClientDVRServiceDeliveryInformationEpic,
  getClientDVRVocatioanlInformationEpic,
  updateClientDVRServiceDeliveryInformationEpic,
  updateClientDVRVocationalInformationEpic,
  updateClientBFETSuppliesInformationEpic,

  updateClientReentryConsentInformationEpic,
  updateClientReentryIntakeIdentifyingInformationEpic,
  updateClientReentryIntakeIncomeInformationEpic,
  updateClientReentryIntakeFamilyInformationEpic,
  updateClientReentryIntakeHousingInformationEpic,
  updateClientReentryIntakeImmediateNeedsInformationEpic,
  updateClientReentryIntakeIncarcerationInformationEpic,
  updateClientReentryIntakeLegalHistoryInformationEpic,
  updateClientReentryIntakePersonalHealthInformationEpic,
  updateClientReentryIntakeSkillsAndAbilitiesInformationEpic,
  updateClientReentryIntakeTransportationInformationEpic,
  updateClientReentryIntakeEmploymentSearchInformationEpic,

  updateClientBfetEmploymentGoalsInformationEpic,
  updateClientBfetEmploymentHistoryInformationEpic,
  updateClientBfetEmploymentPlanInformationEpic,
  updateClientBfetAssessedEmploymentBarriersInformationEpic,
  updateClientBfetDeclarationAndSignatureInformationEpic,
  updateClientBfetSkillsAndQualificationInformationEpic,

  getClientMentalInformationEpic,
  updateClientMentalDemographicsEpic,
  updateClientMentalDevelopmentEpic,
  updateClientMentalEducationEpic,
  updateClientMentalEmploymentEpic,
  updateClientMentalFamilyInformationEpic,
  updateClientMentalLegalEpic,
  uploadClientMentalLegalFormsEpic,
  uploadFileEpic,
  uploadFileSuccessEpic,
  uploadDocumentFileSuccessEpic,
  uploadClientMentalLegalExemptionFormsEpic,
  updateClientMentalPrimaryReasonsForSeekingServicesEpic,
  updateClientMentalSocialRelationshipsEpic,
  updateClientMentalSpiritualEpic,
  updateClientMentalMilitaryEpic,
  updateClientMentalLeisureEpic,
  updateClientMentalMedicalHealthEpic,
  updateClientMentalAdditionalInfoEpic,
  updateClientMentalChemicalUseHistoryEpic,
  updateClientMentalNutritionEpic,
  updateClientMentalMedicalHistoryEpic,
  updateClientMentalTreatmentHistoryEpic,

  getClientVisitorNotesInformationEpic,
  postClientVisitorNotesInformationEpic,
  updateClientVisitorNotesInformationEpic,

  getClientDocumentsInformationEpic,
  postClientDocumentsInformationEpic,
  deleteClientDocumentsInformationEpic,
  uploadDocumentEpic,

  postClientPictureEpic,
  uploadClientPictureEpic,
  postClientPictureSuccessCallbackEpic,
  postClientPictureSuccessCallbackDelayEpic,

  deleteClientPictureEpic,

  getClientBfetJobSearchInformationEpic,
  getClientBfetJobSearchEmployersInformationEpic,
  updateClientBfetJobSearchInformationEpic,
  postClientBfetJobSearchEmployerInformationEpic,
  updateClientBfetJobSearchEmployerInformationEpic,
  deleteClientBfetJobSearchEmployerInformationEpic,
  uploadClientBfetJobSearchEmployerInformationEpic,
  uploadClientBfetJobSearchApplicationFormEpic,
  
  getClientSuppliesInformationEpic,
  postClientSuppliesInformationEpic,
  updateClientSuppliesInformationEpic,

  deleteClientVisitorNotesInformationEpic,
  accountClientUrlEpic,
  sendClientUrlEpic
} from 'epics/clientEpics'

import {
  getAccountEpic,
  getAccountsEpic,
  createAccountEpic,
  updateAccountEpic,
  deleteAccountEpic,
  getAccountSuppliesEpic,
  accountReferEpic
} from 'epics/accountEpics'

import {
  getAccountAppointmentsEpic,
  getAllAppointmentsEpic,
  getClientAppointmentsEpic,
  createAppointmentEpic,
  cancelAppointmentEpic,
  getAccountAppointmentEpic,
  updateAppointmentEpic,
  createAppointmentSuccessEpic
} from 'epics/appointmentEpics'

import {
  AlertSuccesEpic
} from 'epics/alertEpics'

import {
  getRolesEpic,
  createRoleEpic,
  updateRoleEpic,
  deleteRoleEpic
} from 'epics/roleEpics'


import {
  getqueriesEpic,
  getqueryEpic,
  getSearchMetaDataEpic,
  createQueryEpic,
  deleteQueryEpic,
  getJobEpic,
  createReportTemplateEpic,
  getReportTemplatesEpic,
  createReportTemplateQueryEpic,
  updateReportTemplateEpic,
  deleteReportTemplateQueryEpic,
  deleteReportTemplateEpic,
  getReportQueryTemplatesEpic
} from 'epics/reportEpics'

export const api = API.create()

const rootEpic = (action$, store) => {
  return merge(
    loginEpic(action$, store, { api }),
    signUpEpic(action$, store, { api }),
    logoutEpic(action$, store, { api }),
    sendEmailEpic(action$, store, { api }),
    updatePasswordEpic(action$, store, { api }),
    loginSuccessEpic(action$, store),
    logoutSuccessEpic(action$, store),
    validatePasswordEpic(action$, store, { api }),

    exitEpic(action$, store, { api }),

    // User Epics
    appLoadEpic(action$, store, { api }),
    userTokenValidEpic(action$, store),
    userTokenInValidEpic(action$, store),
    userTokenValidatedEpic(action$, store),
    getUserInfoEpic(action$, store, { api }),
    updateUserInfoEpic(action$, store, { api }),

    //Client Epics
    getClientEpic(action$, store, { api }),
    getClientsEpic(action$, store, { api }),
    createClientEpic(action$, store, { api }),
    updateClientEpic(action$, store, { api }),
    deleteClientEpic(action$, store, { api }),
    searchClientEpic(action$, store, { api }),
    getClientReentryIntakeInformationEpic(action$, store, { api }),
    getClientBfetInformationEpic(action$, store, { api }),
    getClientConsentInformationEpic(action$, store, { api }),

    getClientDVRServiceDeliveryInformationEpic(action$, store, { api }),
    getClientDVRVocatioanlInformationEpic(action$, store, { api }),
    updateClientDVRServiceDeliveryInformationEpic(action$, store, { api }),
    updateClientDVRVocationalInformationEpic(action$, store, { api }),
    updateClientBFETSuppliesInformationEpic(action$, store, { api }),

    updateClientReentryConsentInformationEpic(action$, store, { api }),
    updateClientReentryIntakeIdentifyingInformationEpic(action$, store, { api }),
    updateClientReentryIntakeIncomeInformationEpic(action$, store, { api }),
    updateClientReentryIntakeFamilyInformationEpic(action$, store, { api }),
    updateClientReentryIntakeHousingInformationEpic(action$, store, { api }),
    updateClientReentryIntakeImmediateNeedsInformationEpic(action$, store, { api }),
    updateClientReentryIntakeIncarcerationInformationEpic(action$, store, { api }),
    updateClientReentryIntakeLegalHistoryInformationEpic(action$, store, { api }),
    updateClientReentryIntakePersonalHealthInformationEpic(action$, store, { api }),
    updateClientReentryIntakeSkillsAndAbilitiesInformationEpic(action$, store, { api }),
    updateClientReentryIntakeTransportationInformationEpic(action$, store, { api }),
    updateClientReentryIntakeEmploymentSearchInformationEpic(action$, store, { api }),

    // Bfet
    updateClientBfetEmploymentGoalsInformationEpic(action$, store, { api }),
    updateClientBfetEmploymentHistoryInformationEpic(action$, store, { api }),
    updateClientBfetEmploymentPlanInformationEpic(action$, store, { api }),
    updateClientBfetAssessedEmploymentBarriersInformationEpic(action$, store, { api }),
    updateClientBfetDeclarationAndSignatureInformationEpic(action$, store, { api }),
    updateClientBfetSkillsAndQualificationInformationEpic(action$, store, { api }),

    //Mental
    getClientMentalInformationEpic(action$, store, { api }),
    updateClientMentalDemographicsEpic(action$, store, { api }),
    updateClientMentalDevelopmentEpic(action$, store, { api }),
    updateClientMentalEducationEpic(action$, store, { api }),
    updateClientMentalEmploymentEpic(action$, store, { api }),
    updateClientMentalFamilyInformationEpic(action$, store, { api }),
    updateClientMentalLegalEpic(action$, store, { api }),
    uploadClientMentalLegalFormsEpic(action$, store, { api }),
    uploadFileEpic(action$, store, { api }),
    uploadFileSuccessEpic(action$, store, { api }),
    uploadDocumentFileSuccessEpic(action$, store, { api }),
    uploadClientMentalLegalExemptionFormsEpic(action$, store, { api }),
    updateClientMentalLeisureEpic(action$, store, { api }),
    updateClientMentalPrimaryReasonsForSeekingServicesEpic(action$, store, { api }),
    updateClientMentalSocialRelationshipsEpic(action$, store, { api }),
    updateClientMentalSpiritualEpic(action$, store, { api }),
    updateClientMentalMilitaryEpic(action$, store, { api }),
    updateClientMentalLeisureEpic(action$, store, { api }),
    updateClientMentalMedicalHealthEpic(action$, store, { api }),
    updateClientMentalAdditionalInfoEpic(action$, store, { api }),
    updateClientMentalChemicalUseHistoryEpic(action$, store, { api }),
    updateClientMentalNutritionEpic(action$, store, { api }),
    updateClientMentalMedicalHistoryEpic(action$, store, { api }),
    updateClientMentalTreatmentHistoryEpic(action$, store, { api }),

    getClientBfetJobSearchInformationEpic(action$, store, { api }),
    getClientBfetJobSearchEmployersInformationEpic(action$, store, { api }),
    updateClientBfetJobSearchInformationEpic(action$, store, { api }),
    postClientBfetJobSearchEmployerInformationEpic(action$, store, { api }),
    updateClientBfetJobSearchEmployerInformationEpic(action$, store, { api }),
    deleteClientBfetJobSearchEmployerInformationEpic(action$, store, { api }),
    uploadClientBfetJobSearchEmployerInformationEpic(action$, store, { api }),
    uploadClientBfetJobSearchApplicationFormEpic(action$, store, { api }),
    // Picture
    postClientPictureEpic(action$, store, { api }),
    uploadClientPictureEpic(action$, store, { api }),
    postClientPictureSuccessCallbackEpic(action$, store, { api }),
    postClientPictureSuccessCallbackDelayEpic(action$, store, { api }),

    deleteClientPictureEpic(action$, store, { api }),


    //Notes
    getClientVisitorNotesInformationEpic(action$, store, { api }),
    postClientVisitorNotesInformationEpic(action$, store, { api }),
    updateClientVisitorNotesInformationEpic(action$, store, { api }),

    // Docs
    getClientDocumentsInformationEpic(action$, store, { api }),
    postClientDocumentsInformationEpic(action$, store, { api }),
    deleteClientDocumentsInformationEpic(action$, store, { api }),
    uploadDocumentEpic(action$, store, { api }),

    //Client Epics
    getAccountEpic(action$, store, { api }),
    getAccountsEpic(action$, store, { api }),
    createAccountEpic(action$, store, { api }),
    updateAccountEpic(action$, store, { api }),
    deleteAccountEpic(action$, store, { api }),
    getAccountSuppliesEpic(action$, store, { api }),
    accountReferEpic(action$, store, { api }),
    
    // Appointments Epics
    getAllAppointmentsEpic(action$, store, { api }),
    getAccountAppointmentsEpic(action$, store, { api }),
    getClientAppointmentsEpic(action$, store, { api }),
    createAppointmentEpic(action$, store, { api }),
    cancelAppointmentEpic(action$, store, { api }),
    getAccountAppointmentEpic(action$, store, { api }),
    updateAppointmentEpic(action$, store, { api }),
    createAppointmentSuccessEpic(action$, store, { api }),

    // Alert Epics
    AlertSuccesEpic(action$, store, { api }),

    // Roles Epics
    getRolesEpic(action$, store, { api }),
    createRoleEpic(action$, store, { api }),
    updateRoleEpic(action$, store, { api }),
    deleteRoleEpic(action$, store, { api }),

    getClientSuppliesInformationEpic(action$, store, { api }),
    postClientSuppliesInformationEpic(action$, store, { api }),
    updateClientSuppliesInformationEpic(action$, store, { api }),

    clientReferEpic(action$, store, { api }),
    deleteClientVisitorNotesInformationEpic(action$, store, { api }),

    accountClientUrlEpic(action$, store, { api }),
    sendClientUrlEpic(action$, store, { api }),


    // Reports Epics
    getSearchMetaDataEpic(action$, store, { api }),
    getqueriesEpic(action$, store, { api }),
    getqueryEpic(action$, store, { api }),
    deleteQueryEpic(action$, store, { api }),
    createQueryEpic(action$, store, { api }),
    getJobEpic(action$, store, { api }),
    createReportTemplateEpic(action$, store, { api }),
    getReportTemplatesEpic(action$, store, { api }),
    createReportTemplateQueryEpic(action$, store, { api }),
    updateReportTemplateEpic(action$, store, { api }),
    deleteReportTemplateEpic(action$, store, { api }),
    deleteReportTemplateQueryEpic(action$, store, { api }),
    getReportQueryTemplatesEpic(action$, store, { api })
  )

}

export default rootEpic;