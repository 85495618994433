
import React from 'react';
import { Container, Form, Row, Col, Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux'
import AuthActions from 'redux/AuthRedux'
import { REQUIRED_PASSWORD_LENGTH } from 'config/CONSTANTS'
import Images from 'assets'

export class UpdatePassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { password, confirmPassword } = this.state
    if (!password || !confirmPassword || password !== confirmPassword || password.length < REQUIRED_PASSWORD_LENGTH) {
      this.setState({
        error: true
      })
    } else {
      this.props.updatePassword(password)
    }
  }

  render () {
    const { updating } = this.props
    const { password, confirmPassword, error } = this.state

    return (
      <Container>
        <Row className="d-flex justify-content-center ">
          <Col id="login-box" className="center-block" md={5}>
            <Row>
              <Col sm={12} className="center">
                <Image src={Images['logo']} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                To reset your password, enter the email address associated with your account below.
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <Form>
                  <Form.Group>
                    <Form.Control className={`${error && !password ? 'is-invalid' : ''}`}  name="password" type="password" placeholder="Password" onChange={this.handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control className={`${error && !confirmPassword ? 'is-invalid' : ''}`}  name="confirmPassword" type="password" placeholder="Re-type password" onChange={this.handleChange}/>
                  </Form.Group>
                  <Button size="lg" block variant="primary" disabled={updating} onClick={this.handleSubmit}>{updating ? 'Updating...' : 'Update'}</Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updating: state.auth.updating
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (password) => dispatch(AuthActions.updatePasswordRequest(password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);