import { switchMap, filter, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs'
import AccountActions, { AccountTypes } from "redux/AccountRedux";
import AlertActions from 'redux/AlertRedux'

export const getAccountEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.GET_ACCOUNT),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.getAccount(action.id, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.getAccountSuccess(response.data))
          } else {
            return of(
              AccountActions.getAccountFailure(),
              AlertActions.error(['Retreiving client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getAccountsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.GET_ACCOUNTS),
    delay(500),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getAccounts((action.pageNumber || 1), user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            const accounts = response.data && response.data.accountUsers && response.data.accountUsers.filter((account) => account.status === 'ENABLED')
            return of(AccountActions.getAccountsSuccess(accounts, response.data.totalCount, (action.pageNumber || 1)))
          } else {
            return of(
              AccountActions.getAccountsFailure(),
              AlertActions.error(['Fetching Users failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createAccountEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.CREATE_ACCOUNT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createAccount(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.getAccounts(1))
          } else {
            let errors = [];
            if (response.data && response.data.error_message) {
              errors.push(response.data.error_message)
            }
            if (response.data && response.data.errors && response.data.errors.hasUppercase) {
              errors.push(response.data.errors.hasUppercase)
            }
            return of(
              AlertActions.error(errors)
            )
          }
        })
      )
    })
  )

export const updateAccountEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.UPDATE_ACCOUNT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateAccount(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.getAccounts(1))
          } else {
            return of(
              AlertActions.error(['Upadting Account Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteAccountEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.DELETE_ACCOUNT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteAccount(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.getAccounts(1))
          } else {
            return of(
              AccountActions.updateAccountFailure(),
              AlertActions.error(['Deleting Account Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getAccountSuppliesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.GET_ACCOUNT_SUPPLIES_INFORMATION),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.getAccountSuppliesInformation(action.category, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.getAccountSuppliesInformationSuccess(action.category, response.data))
          } else {
            return of(
              AlertActions.error(['Fetching account supplies failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const accountReferEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AccountTypes.REFER_ACCOUNT),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.referAccount(action.params, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AccountActions.referAccountSuccess())
          } else {
            return of(
              AccountActions.referAccountFailure(),
              AlertActions.error(['Sending referral failed! Please try again.'])
            )
          }
        })
      )
    })
  )