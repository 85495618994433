import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import BaseForm from "components/Patient/BaseForm";
import {
  FormHeader,
  Acknowlegdement,
  GuideLine,
  GuideLine2,
  NoticeHeader,
  LightGrayLabel,
} from "styles/GenericStyles";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";

export default class Consent extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.updateClientReentryConsentInformation(this.props.clientID, {
      ...values,
      clientId: this.props.clientID,
    });
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row>
                    <FormHeader className="mt-n2 mb-3">
                      CLIENT IDENTIFICATION:{" "}
                    </FormHeader>
                  </Row>

                  <Row>
                    <Col className="p-0 mb-2">
                      <NoticeHeader className="mb-1">
                        NOTICE TO CLIENTS:
                      </NoticeHeader>
                      <Acknowlegdement>
                        The Department of Social and Health Services (DSHS) can
                        help you better if we are able to work with other
                        agencies and professionals that know you and your
                        family. By signing this form, you are giving permission
                        for DSHS and the agencies and individuals listed below
                        to use and share confidential information about you.
                        DSHS cannot refuse you benefits if you do not sign this
                        form unless your consent is needed to determine your
                        eligibility. If you do not sign this form, DSHS may
                        still share information about you to the extent allowed
                        by law. If you have questions about how DSHS shares
                        client information or your privacy rights, please
                        consult the DSHS Notice of Privacy Practices or as the
                        person giving you this form.
                      </Acknowlegdement>
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-12 p-0">
                      <label className="input-label" htmlFor="firstName">
                        Participant Name:
                      </label>
                    </Col>
                    <Row className='row-cols-1 row-cols-md-3 row-cols-lg-3'>

                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="firstName"
                        type="text"
                        placeholder="First"
                      />
                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="middleName"
                        type="text"
                        placeholder="MI"
                      />
                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="lastName"
                        type="text"
                        placeholder="Last"
                      />
                    </Row>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="dob">
                        Date of Birth:
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                      <TextInput
                        name="dob"
                        type="date"
                        placeholder="MM/DD/YYYY"
                      />
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="IDNumber">
                        Identification Number:
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                      <TextInput
                        name="identificationNumber"
                        type="text"
                        placeholder="Identification Number"
                      />
                    </Col>
                  </Row>

                  <Row className="align-items-start mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="address1">
                        Address:
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-12 col-md-8 p-0">
                      <Row className="col-10 p-0">
                        <TextInput
                          className="col-xs-12 col-md-12 col-lg-12"
                          name="addressLine1"
                          type="text"
                          placeholder="Address Line 1"
                        />
                        <TextInput
                          className="col-xs-12 col-md-12 col-lg-12"
                          name="city"
                          type="text"
                          placeholder="City"
                        />
                        <TextInput
                          className="col-xs-12 col-md-12 col-lg-12"
                          name="state"
                          type="text"
                          placeholder="State"
                        />
                      </Row>
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="zipcode">
                        Zip Code:
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                      <TextInput
                        name="zipcode"
                        type="text"
                        placeholder="Zip Code"
                      />
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="phone">
                        Phone Number (Include Area Code)
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                      <TextInput
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col className="col-xs-12 col-sm-12 col-md-4 p-0">
                      <label className="input-label" htmlFor="email">
                        Email Address:
                      </label>
                    </Col>
                    <Col className="col-xs-12 col-sm-6 col-md-6 p-0">
                      <TextInput
                        name="email"
                        type="text"
                        placeholder="Email Address"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <FormHeader>CONSENT:</FormHeader>
                  </Row>

                  <Row className="col-md-12 col-lg-12">
                    <Acknowlegdement>
                      I consent to the use of confidential information about me
                      within DSHS to plan, provide, and coordinate services,
                      treatment, payments, and benefits for me or for other
                      purposes authorised by law. I further grant permission to
                      DSHS and the below listed agencies, providers, or persons
                      to use my confidential information and disclose it to each
                      other for these purposes. Information may be shared
                      verbally or by computer data transfer, mail, or hand
                      delivery.
                    </Acknowlegdement>
                  </Row>
                  <Row className="col-md-12 col-lg-12">
                    <GuideLine>
                      Please check all below who are included in this consent in
                      addition to DSHS and identify them by name and address:
                    </GuideLine>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="hasConsentToHealthCareProviders"
                      >
                        {" "}
                        Health care providers:
                      </Checkbox>
                    </Col>

                    <FieldArray name="healthCareProviders">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex ml-3 w-75"
                              type="text"
                              name={`healthCareProviders.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToMentalHealthCareProviders"
                      >
                        {" "}
                        Mental health care providers:
                      </Checkbox>
                    </Col>

                    <FieldArray name="mentalHealthCareProviders">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`mentalHealthCareProviders.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToSubstanceUseDisorderServiceProviders"
                      >
                        {" "}
                        Substance use disorder service providers:
                      </Checkbox>
                    </Col>

                    <FieldArray name="substanceUseDisorderServiceProviders">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`substanceUseDisorderServiceProviders.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToOtherDSHSContractedProviders"
                      >
                        {" "}
                        Other DSHS contracted providers:
                      </Checkbox>
                    </Col>

                    <FieldArray name="otherDSHSContractedProviders">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`otherDSHSContractedProviders.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToHousingPrograms"
                      >
                        {" "}
                        Housing programs:
                      </Checkbox>
                    </Col>

                    <FieldArray name="housingPrograms">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`housingPrograms.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToSchoolDistrictsOrColleges"
                      >
                        {" "}
                        School districts or colleges:
                      </Checkbox>
                    </Col>

                    <FieldArray name="schoolDistrictsOrColleges">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`schoolDistrictsOrColleges.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToDepartmentOfCorrections"
                      >
                        {" "}
                        Department of Corrections:
                      </Checkbox>
                    </Col>

                    <FieldArray name="departmentOfCorrections">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`departmentOfCorrections.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToEmploymentSecurityDepartmentAndItsEmploymentPartners"
                      >
                        {" "}
                        Employment Security Department and its employment
                        partners:
                      </Checkbox>
                    </Col>

                    <FieldArray name="employmentSecurityDepartmentAndItsEmploymentPartners">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`employmentSecurityDepartmentAndItsEmploymentPartners.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>
                  <Row className="mb-2">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="hasConsentToSocialSecurityAdministrationOrOtherFederalAgency"
                      >
                        {" "}
                        Social Security Administration or other federal agency:
                      </Checkbox>
                    </Col>

                    <FieldArray name="socialSecurityAdministrationOrOtherFederalAgency">
                      {({ replace }) => {
                        return (
                          <Col className="p-0">
                            <TextInput
                              className="d-flex w-75 ml-3"
                              type="text"
                              name={`socialSecurityAdministrationOrOtherFederalAgency.0`}
                              placeholder=""
                              onChange={(e) => replace(0, e.target.value)}
                            />
                          </Col>
                        );
                      }}
                    </FieldArray>
                  </Row>

                  {/* <Row className="align-items-center mb-3">
                    <Col className="col-12 p-0">
                      <Checkbox
                        labelClassName="input-label mb-2 text-wrap col-lg-12 col-md-12 col-sm-4"
                        name="adonaiCounseling"
                      >
                        {" "}
                        Other: AxionG Counseling & Employment Inc:
                      </Checkbox>
                    </Col>
                    <Row className='ml-1 p-0 row-cols-1 row-cols-md-3 row-cols-lg-3'>

                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="firstNames"
                        type="text"
                        placeholder="First"
                      />
                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="middleNames"
                        type="text"
                        placeholder="MI"
                      />
                      <TextInput
                        className="col-md-10 col-lg-4"
                        name="lastNames"
                        type="text"
                        placeholder="Last"
                      />
                    </Row>
                  </Row> */}

                  <Row>
                    <Col className="p-0 ml-3">
                      <Checkbox labelClassName="mb-2" name="seeAttachedList">
                        {" "}
                        See attached list
                      </Checkbox>
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Col className=" col-12 p-0 ml-3">
                      <p>
                        <strong>
                          I authorize and consent to sharing the following
                          records and information (check all that apply):
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" py-2 col-md-10 col-lg-12">
                      <Checkbox
                        labelClassName="mb-2"
                        name="hasConsentToClientRecords"
                      >
                        {" "}
                        All my client records
                      </Checkbox>
                    </Col>
                    <Col className=" py-2">
                      <Checkbox
                        labelClassName="mb-2"
                        name="hasConsentToRecordsOnAttachedList"
                      >
                        {" "}
                        Records on attached list
                      </Checkbox>
                    </Col>
                    <Col className="col-12 py-2">
                      <label> Only the following records</label>
                    </Col>
                    <Col className="col-12 ml-3">
                      <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3">
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToFamilySocialEmploymentHistory"
                        >
                          {" "}
                          Family, social and employment history:
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToHealthCareInformation"
                        >
                          {" "}
                          Health care information
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToTreatmentOrCarePlans"
                        >
                          {" "}
                          Treatment or care plans
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToPaymentRecords"
                        >
                          {" "}
                          Payment records
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToIndividualAssessments"
                        >
                          {" "}
                          Individual assessments
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToSchoolEducationTraining"
                        >
                          {" "}
                          School, education, and training
                        </Checkbox>
                        <Checkbox
                          labelClassName="mb-2 text-wrap"
                          name="hasConsentToOtherlists"
                        >
                          {" "}
                          Other (list)
                        </Checkbox>
                      </Row>
                    </Col>
                  </Row>

                  <hr />
                  <Row className="mb-2">
                    <Col >
                      <p className="mb-0">
                        <small>
                          <strong className="d-block ml-2">
                            PLEASE NOTE:{" "}
                          </strong>
                        </small>
                      </p>
                      <Row className="col-md-12 col-lg-12 ml-2">
                        <GuideLine2>
                          If your client records include any of the following
                          information, you must also complete this section to
                          include these records.
                        </GuideLine2>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <label className="input-label col-md-12 col-lg-12">
                        I give my permission to disclose the following records
                        (check all that apply):
                      </label>
                    </Col>
                    <Col className="p-0 d-flex flex-wrap ml-3 row-cols-xs-1 row-cols-sm-1 row-cols-lg-1">
                      <Checkbox
                        name="hasConsentToMentalHealth "
                        labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                      >
                        {" "}
                        Mental health
                      </Checkbox>
                      <Checkbox
                        name="hasConsentToHIVData"
                        labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                      >
                        {" "}
                        HIV/AIDS and STD test results, diagnosis, or treatment
                      </Checkbox>
                      <Checkbox
                        name="hasConsentToSubstanceUseDisorder"
                        labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                      >
                        {" "}
                        Substance Use Disorder
                      </Checkbox>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label className="input-label">
                        This consent is valid for
                      </label>
                    </Col>
                    <Col className="p-0 d-flex flex-wrap ml-3 row-cols-xs-1 row-cols-sm-1 row-cols-lg-1">
                      <Checkbox
                        name="hasConsentUptoOneYear"
                        labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                      >
                        {" "}
                        One Year
                      </Checkbox>
                      <Checkbox
                        name="hasConsentAslongAsDSHSNeeds"
                        labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                      >
                        {" "}
                        As long as DSHS needs records
                      </Checkbox>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          name="hasConsentUntil "
                          labelClassName="mb-2 text-wrap col-md-12 col-lg-12"
                        >
                          {" "}
                          Until
                        </Checkbox>
                        <TextInput
                          className="mb-0"
                          name="consentUntilDate"
                          type="date"
                          placeholder="Date Or Event"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-md-12 col-lg-12">
                      <p>
                        <small>
                          <strong>
                            * I may revoke or withdraw this consent at any time
                            in writing, but that will not affect any information
                            already shared.
                          </strong>
                        </small>
                      </p>
                      <p>
                        <small>
                          <strong>
                            * I understand that records shared under this
                            consent may no longer be protected under the laws
                            that apply to DSHS.
                          </strong>
                        </small>
                      </p>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-lg-12 col-md-12">
                      <label className="input-label">
                        * A copy of this form is valid to give my permission to
                        share records.
                      </label>
                    </Col>
                    <Row>
                      <Col>
                        <TextInput
                          className="w-75 ml-3"
                          labelClassName="mb-2 d-block"
                          label="Signature"
                          name="clientSignature"
                          type="text"
                          placeholder="Signature"
                        />
                      </Col>
                      <Col className="p-0">
                        <TextInput
                          className="w-75"
                          labelClassName="mb-2 d-block"
                          label="Date"
                          name="clientSignatureDate"
                          type="date"
                          placeholder="MM/DD/YYYY"
                        />
                      </Col>
                    </Row>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-lg-12 col-sm-12 mb-2">
                      <label className="input-label">
                        Witness / Notary (Sign And Print Name, If Applicable)
                      </label>
                    </Col>
                    <Row>
                      <Col>
                        <TextInput
                          className="w-75 ml-3"
                          name="witnessSignature"
                          type="text"
                          placeholder="Signature"
                        />
                      </Col>
                      <Col className="p-0">
                        <TextInput
                          className="w-75"
                          name="witnessSignatureDate"
                          type="date"
                          placeholder="MM/DD/YYYY"
                        />
                      </Col>
                    </Row>
                  </Row>

                  <Row className="align-items-end mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3">
                    <Col className="col-lg-4 col-md-12">
                      <TextInput
                        labelClassName="text-wrap d-block mb-2"
                        label="Parent Or Other Representative’s Signature (If Applicable)"
                        name="parentOrOtherRepresentativeSignature"
                        type="text"
                        placeholder="Signature"
                      />
                    </Col>
                    <Col className="col-lg-4 col-md-12">
                      <TextInput
                        labelClassName="text-wrap d-block mb-2"
                        label="Telephone Number (Include Area Code)"
                        name="parentOrOtherRepresentativePhoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                    <Col className="col-lg-4 col-md-8">
                      <TextInput
                        labelClassName="text-wrap d-block"
                        label="Date"
                        name="parentOrOtherRepresentativeDate"
                        type="date"
                        placeholder="MM/DD/YYYY"
                      />
                    </Col>
                  </Row>

                  <hr />
                  <Row className="mb-2">
                    <Col className="col-lg-12 col-md-12 mb-2">
                      <label className="input-label">
                        If I am not the subject of the records, I am authorized
                        to sign because I am the: (attach proof of authority)
                      </label>
                    </Col>
                    <Col className="p-0 d-flex flex-wrap ml-3 row row-cols-1">
                      <Checkbox
                        name="isParent"
                        className="mb-2 text-wrap col-md-6 col-lg-12"
                      >
                        {" "}
                        Parent
                      </Checkbox>
                      <Checkbox
                        name="isLegalGuardian"
                        className="mb-2 text-wrap col-md-6 col-lg-12"
                      >
                        {" "}
                        Legal Guardian (attach court order)
                      </Checkbox>
                      <Checkbox
                        name="isPersonalRepresentative"
                        className="mb-2 text-wrap col-md-6 col-lg-12"
                      >
                        {" "}
                        Personal Representative
                      </Checkbox>
                      <Checkbox
                        name="isOther"
                        className="mb-2 text-wrap col-md-6 col-lg-12"
                      >
                        {" "}
                        Other
                      </Checkbox>
                    </Col>
                  </Row>


                  <hr />

                  <Row>
                    <Col className="col-lg-12 col-md-12 mb-2">
                      <label className="input-label">
                        Documents Upload Status
                      </label>
                    </Col>

                    <Col className="col-lg-12 col-md-12 mb-2">
                      <Checkbox
                        name="isDocumentUploaded"
                        className="mb-2 text-wrap col-md-6 col-lg-12"
                      >
                        {" "}
                        Documents Uploaded
                      </Checkbox>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col className="p-0">
                      <NoticeHeader className="mb-1 col-lg-12 col-sm-12">
                        NOTICE TO RECIPIENTS OF INFORMATION:
                      </NoticeHeader>
                      <Acknowlegdement className="col-lg-12 col-sm-12">
                        If these records contain information about HIV, STDs, or
                        AIDS, you may not further disclose that information
                        without the client’s specific permission. If you have
                        received information related to drug or alcohol abuse by
                        the client, you must include the following statement
                        when further disclosing information as required by 42
                        CFR 2.32:
                      </Acknowlegdement>
                      <Acknowlegdement className="col-lg-12 col-sm-12">
                        This information has been disclosed to you from records
                        protected by Federal confidentiality rules (42 CFR part
                        2). The Federal rules prohibit you from making any
                        further disclosure of this information unless further
                        disclosure is expressly permitted by the written consent
                        of the person to whom it pertains or as otherwise
                        permitted by 42 CFR pert 2. A general authorization for
                        the release of medical or other information is NOT
                        sufficient for this purpose. The Federal rules restrict
                        any use of the information to criminally investigate or
                        prosecute any alcohol or drug abuse patient.
                      </Acknowlegdement>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
