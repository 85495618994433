import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class MedicalHistory extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Medical History:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Medical History:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row>
            <Table className="w-100" borderless responsive>
              <thead>
                <th className="align-top" style={{ width: "30%" }}><LightGrayLabel>
                  Currently Prescribed Medicines</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "10%" }}><LightGrayLabel>
                  Dose</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "20%" }}><LightGrayLabel>
                  Dates</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "20%" }}><LightGrayLabel>
                  Purpose</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "20%" }}><LightGrayLabel>
                  Side Effects</LightGrayLabel>
                </th>
              </thead>
              <tbody>
                {data &&
                  data.medicalPrescriptions &&
                  data.medicalPrescriptions.map((medicalPrescription) => (
                    <tr>
                      <td className="pl-0 py-0">
                        <Col className="col-12">
                          <label className="input-label">{medicalPrescription.medicineName}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label className="input-label">{medicalPrescription.dose}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label className="input-label">{medicalPrescription.date}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label className="input-label">{medicalPrescription.purpose}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label className="input-label">{medicalPrescription.sideEffects}</label>
                        </Col>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Are you allergic to any medications, antibiotics, or drugs?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.isAllergic)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>If Yes, Describe:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.allergyDescription}</label>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <label className="input-label">Most Recent Examinations:</label>
            </Col>
            <Col className="p-0 col-2">
              <label className="input-label">Date</label>
            </Col>
            <Col className="p-0 col-3 pl-3">
              <label className="input-label">Reason</label>
            </Col>
            <Col className="p-0 col-3 pl-3">
              <label className="input-label">Result</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-4 row-cols-lg-4">
            <Col className="col-12">Last Physical Exam</Col>
            <Col className="col-12">
              <label className="input-label">{data.lastPhysicalExamDate}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastPhysicalExamReason}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastPhysicalExamResult}</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-4 row-cols-lg-4">
            <Col className="col-12">Last Doctor Visit</Col>
            <Col className="col-12">
              <label className="input-label">{data.lastDoctorVisitDate}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastDoctorVisitReason}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastDoctorVisitResult}</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-4 row-cols-lg-4">
            <Col className="col-12">Last Vision/Hearing Exam</Col>
            <Col className="col-12">
              <label className="input-label">{data.lastVisionExamDate}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastVisionExamReason}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.lastVisionExamResult}</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-4 row-cols-lg-4">
            <Col className="col-12">Most Recent Surgery</Col>
            <Col className="col-12">
              <label className="input-label">{data.mostRecentSurgeryDate}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.mostRecentSurgeryReason}</label>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.mostRecentSurgeryResult}</label>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="col-12">
              <label className="input-label">How Was Your Early Childhood Development?</label>
            </Col>
            <Col className="col-lg-4 col-md-6">
              <label className="input-label">Walking</label>
            </Col>
            <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-2 row-cols-lg-4">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Early</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentWalkingEarly)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Average</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentWalkingAverage)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Delayed</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentWalkingDelayed)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Unknown</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentWalkingUnknown)}
                  </label>
                </Col>
              </Col>
            </Row>
            <Col className=" col-lg-4 col-md-6">
              <label className="input-label">Talking</label>
            </Col>
            <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-2 row-cols-lg-4">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Early</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentTalkingEarly)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Average</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentTalkingAverage)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Delayed</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentTalkingDelayed)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Unknown</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentTalkingUnknown)}
                  </label>
                </Col>
              </Col>
            </Row>
            <Col className="col-lg-4 col-md-6">
              <label className="input-label">Toilet Training</label>
            </Col>
            <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-2 row-cols-lg-4">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Early</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentToiletTrainingEarly)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Average</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentToiletTrainingAverage)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Delayed</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentToiletTrainingDelayed)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Unknown</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.childhoodDevelopmentToiletTrainingUnknown)}
                  </label>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row className="align-items-center mb-4">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Family History Of Medical Problems:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.familyHistoryOfMedicalProblems}</label>
              </Col>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 mb-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sleep patterns</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.sleepPatterns)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Eating patterns</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.eatingPatterns)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Behavior</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.behaviour)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>Energy level</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.energyLevel)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>Physical activity</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.physicalActivity)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0 p-0">
              <Col className="col-12">
                <LightGrayLabel>General disposition</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.generalDisposition)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Weight</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.weight)}</label>
              </Col>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Nervousness/tension</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.nervousness)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Describe Changes In The Areas Which You Have Checked
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.changesDescription}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
