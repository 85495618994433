import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class PersonalHealthInformation extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};

    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Reentry &gt; Intake &gt; Personal Health Information:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Personal Health Information:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <Row className=" col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
          <Col className="col-6 col-xs-12">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>Emergency Contact:</LightGrayLabel>
              </Col>
              <Col xs={10}>
                <label className="input-label">{data.emergencyContact}</label>
              </Col>
            </Row>
          </Col>
          <Col className="col-6 col-xs-12 ">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>Phone:</LightGrayLabel>
              </Col>
              <Col xs={10}>
                <label className="input-label">
                  {data.emergencyContactPhoneNumber}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className=" col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
          <Col className="col-6 col-x">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>Physician:</LightGrayLabel>
              </Col>
              <Col xs={10}>
                <label className="input-label">{data.physician}</label>
              </Col>
            </Row>
          </Col>
          <Col className="col-6 col-xs-12">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>Phone:</LightGrayLabel>
              </Col>
              <Col xs={10}>
                <label className="input-label">
                  {data.physicianPhoneNumber}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className=" col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
          <Row className="p-0">
            <Col className="col-12 p-0">
              <Col className=" col-12">
                <LightGrayLabel>
                  Does participant have a disability of a long duration?
                </LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.hasDisability)}
              </label>
            </Col>
          </Row>
          <Row className="p-0">
            <Col className="col-12 p-0">
              <Col className=" col-12">
                <LightGrayLabel>
                  has participant ever been diagnosed?
                </LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.isOrWasDiagnosed)}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>Mental Illness</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.hasMentalIllness)}
              </label>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col className="p-0">
            <Col className=" col-12">
              <LightGrayLabel>
                Participant has a Substance Abuse problem? If yes, are you
                receiving treatment:
              </LightGrayLabel>
            </Col>
            <label className="input-label ml-3">
              {humanFormat(data.hasSubstanceAbuseProblem)}
            </label>
          </Col>
        </Row>
        <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-1">
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>If Yes, Where:</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {data.hasSubstanceAbuseProblemTreatmentLocation}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>When:</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {data.hasSubstanceAbuseProblemTreatmentDate}
              </label>
            </Col>
          </Row>
        </Row>

        <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-1">
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>HIV/AIDS and related diseases</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.hivDiseasesApplicable)}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>Developmental disability</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.developmentDisabilityApplicable)}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Col className=" col-12">
                <LightGrayLabel>Physical disability</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {humanFormat(data.physicalDisabilityApplicable)}
              </label>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col className="p-0">
            <Col className=" col-12">
              <LightGrayLabel>
                How do your disability barriers affect you?
              </LightGrayLabel>
            </Col>
            <label className="input-label ml-3">
              {data.disabilityBarriersAffect}
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="p-0 col-12">
            <Col className=" col-12">
              <LightGrayLabel>
                Does participant have a history of any psychiatric conditions?
              </LightGrayLabel>
            </Col>
            <label className="input-label ml-3">
              {humanFormat(data.hasPsychiatricConditions)}
            </label>
          </Col>
          <Col className="col-12">
            <LightGrayLabel>Check All That Apply:</LightGrayLabel>
          </Col>
          <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-2">
            <Col className="col-lg-12 col-sm-4 mb-3">
              <table className="table-bordered text-center w-100">
                <thead>
                  <tr>
                    <th>Psychiatric Condition</th>
                    <th>Currently</th>
                    <th>History of</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Suicidal Ideas</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfSuicidalIdeas)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlySuicidalIdeas)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Suicide Attempts</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfSuicideAttempts)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlySuicideAttempts)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Hallucinations</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfHallucinations)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyHallucinations)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Arson/Fire Setting</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfFireSetting)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyFireSetting)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Victim of Sexual Abuse/Assault</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfVictimofSexualAbuseAssault)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyVictimofSexualAbuseAssault)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Victim of Trauma</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfVictimofTrauma)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyVictimofTrauma)}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="col-lg-12 col-sm-4 mb-3">
              <table className="table-bordered text-center w-100">
                <thead>
                  <tr>
                    <th>Psychiatric Condition</th>
                    <th>Currently</th>
                    <th>History of</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Homicidal Ideas Attempts</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfHomicidalIdeasAttempts)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyHomicidalIdeasAttempts)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Assaultive Behavior</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfAssaultiveBehavior)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyAssaultiveBehavior)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Delusions</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfDelusions)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyDelusions)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Severe Depression</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfSevereDepression)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlySevereDepression)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Severe Thought Disorder</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfSevereThoughtDisorder)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlySevereThoughtDisorder)}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Cognitive Impairment</td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.historyOfCognitiveImpairment)}
                      </label>
                    </td>
                    <td>
                      <label className="input-label">
                        {humanFormat(data.currentlyCognitiveImpairment)}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="col-12 p-0">
              <Col className=" col-12">
                <LightGrayLabel>Other (Specify):</LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {data.psychiatricConditionsOther}
              </label>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col className="p-0 my-3">
            <hr />
          </Col>
        </Row>
        <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-2">
          <Row>
          <Col className="p-0 ml-3 pl-3">
            <Col className="col-6 col-sm-12 col-lg-12">
              <LightGrayLabel>
              If applicable, please list hospitalizations for these
                  conditions:
              </LightGrayLabel>
            </Col>
            <label className="input-label ml-3">
              {data.hospitalizations}
            </label>
          </Col>
          </Row>
          <Row>
            <Col className="p-0 ml-3 pl-3">
              <Col className=" col-12">
                <LightGrayLabel>
                Does participant receive psychiatric care?

                </LightGrayLabel>
              </Col>
              <label className="input-label ml-3">
                {data.hasReceivedPsychiatricCare}
              </label>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col className="p-0">
            <Col className="col-6 col-sm-12 col-lg-12">
              <LightGrayLabel>
                If yes, please list Name, Address, and Phone Number of all
                psychiatric care providers:
              </LightGrayLabel>
            </Col>
            <label className="input-label ml-3">
              {data.psychiatricCareProviders}
            </label>
          </Col>
        </Row>
      </Row>
    );
  }
}
