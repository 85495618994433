import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { RadioButton } from "components/Fields/RadioButton";
import { BOOLEAN_CHECKBOX_OPTIONS } from "config/CONSTANTS";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class TransportationForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.transportation
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <RadioButton
                    className="mb-3"
                    label="Vehicle Available:"
                    name="isVehicleAvailable"
                    labelClassName="mb-2 input-label d-block"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <Row className="mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                    <Col className="col-12">
                      <RadioButton
                        className="mb-3"
                        label="Driver’s License:"
                        name="isDriverLicenseAvailable"
                        options={BOOLEAN_CHECKBOX_OPTIONS}
                        labelClassName="mb-2 input-label d-flex"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-12">
                      <TextInput
                        labelClassName="input-label"
                        className="d-flex mr-3"
                        label="If yes, State:"
                        name="driverLicenseState"
                        type="text"
                        placeholder="State"
                      />
                    </Col>
                  </Row>
                  <RadioButton
                    className="mb-3"
                    label="Liability Insurance:"
                    name="hasLiabilityInsurance"
                    labelClassName="mb-2 input-label d-block"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <TextMessage
                    labelClassName="input-label mb-2"
                    className="mb-3"
                    label="If No, explain:"
                    name="noLiabilityInsuranceExplaination"
                    type="textarea"
                    placeholder="Description"
                    size="md"
                    rows="4"
                  />
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label
                        className="input-label"
                        htmlFor="numberOfMilesWillingToTravelToJob"
                      >
                        I am willing and able to travel n miles to a job.
                      </label>
                    </Col>
                    <Col className="col-12 col-lg-8">
                      <TextInput
                        labelClassName="input-label"
                        className="mr-3"
                        name="numberOfMilesWillingToTravelToJob"
                        type="number"
                        placeholder="Miles"
                      />
                    </Col>
                  </Row>
                  <RadioButton
                    className="mb-3"
                    label="I am able to take public transportation"
                    name="isAbleToTakePublicTransportation"
                    labelClassName="mb-2 input-label d-block"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <RadioButton
                    className="mb-3"
                    label="I am willing to relocate"
                    name="isWillingToRelocate"
                    labelClassName="mb-2 input-label d-block"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
