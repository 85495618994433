import React from "react";
import { Row } from "react-bootstrap";

// Intake Forms
import EmploymentGoals from "components/Patient/BFET/Intake/EmploymentGoals";
import SkillsAndQualification from "components/Patient/BFET/Intake/SkillsAndQualification";
import AssessedEmploymentBarriers from "components/Patient/BFET/Intake/AssessedEmploymentBarriers";
import EmploymentPlan from "components/Patient/BFET/Intake/EmploymentPlan";
import DeclarationAndSignature from "components/Patient/BFET/Intake/DeclarationAndSignature";
import EmploymentHistory from "components/Patient/BFET/Intake/EmploymentHistory";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";

// Scrollable navigation
import ScrollingNavigation from "components/Patient/ScrollingNavigation";
import { PATIENT_BFET_TABS } from "config/CONSTANTS";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class PatientBFET extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTabKey: props.goTo,
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.goTo !== newProps.goTo) {
      this.setState({
        defaultTabKey: newProps.goTo,
      });
    }
  }

  onUpdate = (id, params, category) => {
    this.props.updateFormInit();
    switch (category) {
      case CLIENT_BFET_FORM_TYPES.employmentGoals:
        this.props.updateClientBfetEmploymentGoalsInformation(id, params);
        break;
      case CLIENT_BFET_FORM_TYPES.employmentHistory:
        this.props.updateClientBfetEmploymentHistoryInformation(id, params);
        break;
      case CLIENT_BFET_FORM_TYPES.assessedEmploymentBarriers:
        this.props.updateClientBfetAssessedEmploymentBarriersInformation(
          id,
          params
        );
        break;
      case CLIENT_BFET_FORM_TYPES.declarationAndSignature:
        this.props.updateClientBfetDeclarationAndSignatureInformation(
          id,
          params
        );
        break;
      case CLIENT_BFET_FORM_TYPES.employmentPlan:
        this.props.updateClientBfetEmploymentPlanInformation(id, params);
        break;
      case CLIENT_BFET_FORM_TYPES.skillsAndQualification:
        this.props.updateClientBfetSkillsAndQualificationInformation(
          id,
          params
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { defaultTabKey } = this.state;
    const links = [];

    const {
      assessedEmploymentBarriers,
      declarationAndSignature,
      employmentGoals,
      employmentHistory,
      employmentPlan,
      skillsAndQualification,
    } = this.props.bfet;

    const {
      clientID,
      updatingForm,
      isWriteEmploymentGoalsEnabled,
      isReadEmploymentGoalsEnabled,
      isWriteSkillsAndQualificationEnabled,
      isReadSkillsAndQualificationEnabled,
      isWriteEmploymentHistoryEnabled,
      isReadEmploymentHistoryEnabled,
      isWriteEmploymentPlanEnabled,
      isReadEmploymentPlanEnabled,
      isWriteAssessedEmploymentBarriersEnabled,
      isReadAssessedEmploymentBarriersEnabled,
      isWriteDeclarationAndSignatureEnabled,
      isReadDeclarationAndSignatureEnabled,
    } = this.props;

    for (const [key, value] of Object.entries(PATIENT_BFET_TABS)) {
      links.push({ id: key, value: value });
    }

    return (
      <>
        <Row className="mr-1">
          <ScrollingNavigation
            path={`/clients/${clientID}/bfet`}
            links={links}
            onSelected={() => {}}
            active={defaultTabKey}
          />
        </Row>
        {defaultTabKey === PATIENT_BFET_TABS.EMPLOYMENT_GOALS && (
          <EmploymentGoals
            clientID={clientID}
            data={employmentGoals}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEmploymentGoalsEnabled}
            write={isWriteEmploymentGoalsEnabled}
          />
        )}
        {defaultTabKey === PATIENT_BFET_TABS.SKILLS_AND_QUALIFICATION && (
          <SkillsAndQualification
            clientID={clientID}
            data={skillsAndQualification}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadSkillsAndQualificationEnabled}
            write={isWriteSkillsAndQualificationEnabled}
          />
        )}
        {defaultTabKey === PATIENT_BFET_TABS.ASSESSED_EMPLOYMENT_BARRIERS && (
          <AssessedEmploymentBarriers
            clientID={clientID}
            data={assessedEmploymentBarriers}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadAssessedEmploymentBarriersEnabled}
            write={isWriteAssessedEmploymentBarriersEnabled}
          />
        )}
        {defaultTabKey === PATIENT_BFET_TABS.EMPLOYMENT_PLAN && (
          <EmploymentPlan
            clientID={clientID}
            data={employmentPlan}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEmploymentPlanEnabled}
            write={isWriteEmploymentPlanEnabled}
          />
        )}
        {defaultTabKey === PATIENT_BFET_TABS.DECLARATION_AND_SIGNATURE && (
          <DeclarationAndSignature
            clientID={clientID}
            data={declarationAndSignature}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadDeclarationAndSignatureEnabled}
            write={isWriteDeclarationAndSignatureEnabled}
          />
        )}
        {defaultTabKey === PATIENT_BFET_TABS.EMPLOYMENT_HISTORY && (
          <EmploymentHistory
            clientID={clientID}
            data={employmentHistory}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEmploymentHistoryEnabled}
            write={isWriteEmploymentHistoryEnabled}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatingForm: state.client.updatingForm,
    isWriteEmploymentGoalsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_GOALS
    ),
    isReadEmploymentGoalsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_GOALS
    ),

    isWriteSkillsAndQualificationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_SKILLS_AND_QUALIFICATION
    ),
    isReadSkillsAndQualificationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_SKILLS_AND_QUALIFICATION
    ),
    isWriteAssessedEmploymentBarriersEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_ASSESSED_EMPLOYMENT_BARRIERS
    ),
    isReadAssessedEmploymentBarriersEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_ASSESSED_EMPLOYMENT_BARRIERS
    ),
    isWriteEmploymentPlanEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_PLAN
    ),
    isReadEmploymentPlanEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_PLAN
    ),
    isWriteDeclarationAndSignatureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_DECLARATION_AND_SIGNATURE
    ),
    isReadDeclarationAndSignatureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_DECLARATION_AND_SIGNATURE
    ),
    isWriteEmploymentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_HISTORY
    ),
    isReadEmploymentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_HISTORY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFormInit: () => dispatch(ClientActions.updateForm()),
    updateClientBfetEmploymentGoalsInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetEmploymentGoalsInformation(id, params)
      ),
    updateClientBfetEmploymentHistoryInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetEmploymentHistoryInformation(id, params)
      ),
    updateClientBfetEmploymentPlanInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetEmploymentPlanInformation(id, params)
      ),
    updateClientBfetAssessedEmploymentBarriersInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetAssessedEmploymentBarriersInformation(
          id,
          params
        )
      ),
    updateClientBfetDeclarationAndSignatureInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetDeclarationAndSignatureInformation(
          id,
          params
        )
      ),
    updateClientBfetSkillsAndQualificationInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientBfetSkillsAndQualificationInformation(
          id,
          params
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBFET);
