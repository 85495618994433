
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'
import { TextMessage } from 'components/Fields/TextMessage'
import { Checkbox } from "components/Fields/Checkbox";

export class Refer extends React.Component {
  
  handleSubmit = (values) => {
    this.props.referClient(this.props.clientID, values);
  };

  componentWillReceiveProps (newProps) {
    if (!newProps.referringClientInProgress && this.props.referringClientInProgress) {
      this.props.onClose();
    }
  }

  render () {
    const { referringClientInProgress } = this.props
    return (
      <BaseFormModal heading={'Refer Client'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Formik
          initialValues={{
            email: '',
            referralReason: '',
            justiceInvolved: ''
          }}

          validationSchema={
            Yup.object({
              email: Yup
                .string()
                .email('Invalid email format').required('Email cannot be empty'),
            })
          }

          onSubmit={this.handleSubmit}
        >
            <Form>
              <Row>
                <Col col-12>
                  <TextInput name='email' placeholder='Email' label='Email' />
                </Col>
              </Row>
              <Row>
                <Col col-12>
                  <TextMessage name="referralReason" placeholder="Referral Reason" label="Referral Reason" type='text'/>
                </Col>
              </Row>
              <Row>
                <Col col-12>
                  <Checkbox labelClassName='text-wrap' name='justiceInvolved'> Justice Involved</Checkbox>
                </Col>
              </Row>
              <Button size="md" className='float-right' variant="primary" disabled={referringClientInProgress} type='submit'>{referringClientInProgress ? 'Referring...' : 'Refer'}</Button>
            </Form>
        </Formik>
      </BaseFormModal>
    )
  }
}

export default Refer;