import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class FamilyInformation extends React.Component {
  render() {
    let { data, link, read, write } = this.props;
    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Family Information:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Family Information:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row>
            <Table className="w-100 pl-0 ml-0" borderless responsive>
              <thead>
                <th style={{ width: "20%" }}><LightGrayLabel>Relationship</LightGrayLabel></th>
                <th style={{ width: "15%" }}><LightGrayLabel>Name</LightGrayLabel></th>
                <th style={{ width: "10%" }}><LightGrayLabel>Age</LightGrayLabel></th>
                <th style={{ width: "10%" }}><LightGrayLabel>Living</LightGrayLabel></th>
                <th style={{ width: "25%" }}><LightGrayLabel>Living With you</LightGrayLabel></th>
                <th style={{ width: "10%" }}></th>
              </thead>
              <tbody>
                {data &&
                  data.familyInfoList &&
                  data.familyInfoList.map((familyInfoList) => (
                    <>
                      <tr>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{familyInfoList.relationship}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{familyInfoList.name}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{familyInfoList.age}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{familyInfoList.isLiving ? 'Yes' : 'No'}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{familyInfoList.isLivingWithYou ? 'Yes' : 'No'}</label>
                          </Col>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </Row>

          <Row className="mb-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Does participant receive psychiatric care?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.receivesPsychiatricCare)}</label>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col claaName="p-0 ">
              <label className="input-label">MARITAL STATUS: </label>
            </Col>
            <Table className="w-100 " borderless>
              <tbody>
                {data &&
                  data.martialStatusList &&
                  data.martialStatusList.map((martialStatusList) => (
                    <tr>
                      <td className="pl-0 py-0">
                        <Col className="col-12">
                          <label className="input-label">{martialStatusList.status}</label>
                        </Col>
                      </td>
                      <td className="py-0">
                        <Col className="col-12">
                          <label className="input-label">
                            {martialStatusList.lengthOfTimeInMonths}
                          </label>
                        </Col>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>

          <hr />
          <Row className="my-3">
            <Col className="p-0 col-md-12 col-lg-12">
              <Col className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Specify</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.totalNumberOfMarriages}</label>
                </Col>
              </Col>
              <Col>
                <label
                  className="input-label"
                  htmlFor="currentRelationshipAssessment"
                >
                  Assessment Of Current Relationship (If Applicable):
                </label>
              </Col>

              <Col className="col-12">
                <label className="input-label">{humanFormat(data.currentRelationshipAssessment)}</label>
              </Col>
            </Col>
          </Row>
          <hr />
          <Col className="col-12">
            <ViewHeading>PARENTAL INFORMATION: </ViewHeading>
          </Col>
          <Row className="mb-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 gx-4">
            <Col className="col-4">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Parents legally married</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.parentsLegallyMarried)}</label>
                </Col>
              </Col>
            </Col>
            <Col className="col-4">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Parents have separated</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.parentsHaveSeperated)}</label>
                </Col>
              </Col>
            </Col>
            <Col className="col-4">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Parents divorced</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.parentsHaveDivorced)}</label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 gx-4">
            <Col className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Mother remarried</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.motherMarried)}</label>
              </Col>
            </Col>

            <Col className="col-12">
              <Col className="col-12">
                <LightGrayLabel>No. of times</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.totalNumberOfTimesMotherRemarried}</label>
              </Col>
            </Col>
            <Col className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Father remarried</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.fatherMarried)}</label>
              </Col>
            </Col>

            <Col className="col-12">
              <Col className="col-12">
                <LightGrayLabel>No. of times</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.totalNumberOfTimesFatherRemarried}</label>
              </Col>
            </Col>
          </Row>
          <hr />
          <Col className="col-12">
            <Col className="col-12 p-0">
              <LightGrayLabel>
                Special circumstances (e.g., raised by person other than
                parents, information about spouse/children not living with you,
                etc.):
              </LightGrayLabel>
            </Col>
            <Col className="col-12 p-0">
              <label className="input-label">{data.specialCircumstances}</label>
            </Col>
          </Col>
        </div>
      </Row>
    );
  }
}
