import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and ActionCreators
const { Types, Creators } = createActions({
  getSearchMetaData: null,
  getSearchMetaDataSuccess: ['allowedTypesMap', 'filterMetaData'],
  getSearchMetaDataFailure: null,
  createQuery: ['params'],
  createQuerySuccess: ['query'],
  getQueries: ['pageNumber'],
  getQueriesSuccess: ['pageNumber', 'queries', 'totalCount'],
  getQueriesFailure: null,
  deleteQuery: ['queryId'],
  deleteQuerySuccess: ['queryId'],
  deleteQueryFailure: null,
  getQuery: ['queryId'],
  getQuerySuccess: ['query'],
  getQueryFailure: null,
  getJob: ['queryJobId'],
  getJobSuccess: ['job'],
  getJobFailure: null,
  getReportTemplates: ['pageNumber'],
  getReportTemplatesSuccess: ['pageNumber', 'templates', 'totalCount'],
  getReportTemplatesFailure: null,
  createReportTemplate: ['params'],
  createReportTemplateSuccess: ['template'],
  createReportTemplateFailure: ['message'],
  deleteReportTemplate: ['templateId'],
  updateReportTemplate: ['templateId', 'params'],
  updateReportTemplateSuccess: ['template'],
  updateReportTemplateFailure: null,
  getReportQueryTemplates: ['templateId', 'pageNumber'],
  getReportQueryTemplatesSuccess: ['pageNumber', 'queryTemplates', 'totalCount'],
  getReportQueryTemplatesFailure: null,
  createReportTemplateQuery: ['templateId'],
  deleteReportTemplateQuery: ['templateId', 'queryId'],
  deleteReportTemplateQueryFailure: null,
  createReportTemplateQueryFailure: null,
});

export const ReportTypes = Types;
export default Creators;

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  fetchingMetaData: false,
  fetchingQueries: false,
  fetchingQuery: false,
  deletingQuery: false,
  allowedTypesMap: {},
  filterMetaData: {},
  types: [],
  queries: [],
  query: { 'request': { 'includedFilters' : { 'filterOperator': 'AND', 'filters': []} }},
  totalCount: 0,
  currentPage: 1,
  fetchingJob: false,
  jobs: {},
  fetchingTemplates: false,
  templates: [],
  template: null,
  templateTotalCount: 0,
  templateCurrentPage: 1,
  createReportTemplateFailureMessage: null,
  queryTemplates: [],
  queryTemplatesTotalCount: 0,
  queryTemplatesCurrentPage: 1,
  fetchingQueryTemplates: false
});

/* ---------- Reducers ----------------*/
export const getSearchMetaData = (state) => {
  return state.merge({
    fetchingMetaData: true
  })
}

export const getSearchMetaDataSuccess = (state, {allowedTypesMap, filterMetaData }) => {
  return state.merge({
    fetchingMetaData: false,
    types: Object.keys(filterMetaData),
    allowedTypesMap : allowedTypesMap,
    filterMetaData: filterMetaData
  })
}

export const getSearchMetaDataFailure = (state) => {
  return state.merge({
    fetchingMetaData: false
  })
}

export const getQueries = (state) => {
  return state.merge({
    fetchingQueries: true
  })
}

export const getQueriesSuccess = (state, { pageNumber, queries, totalCount }) => {
  return state.merge({
    fetchingQueries: false,
    queries,
    currentPage: pageNumber,
    totalCount
  })
}

export const getQueriesFailure = (state) => {
  return state.merge({
    fetchingQueries: false
  })
}

export const getQuery = (state) => {
  return state.merge({
    fetchingQuery: true
  })
}

export const getQuerySuccess = (state, { query }) => {
  return state.merge({
    fetchingQuery: false,
    query
  })
}

export const getQueryFailure = (state) => {
  return state.merge({
    fetchingQuery: false
  })
}

export const getJob = (state) => {
  return state.merge({
    fetchingJob: true
  })
}

export const getJobSuccess = (state, { job }) => {
  return state.merge({
    fetchingJob: false,
    jobs: {
      ...state.jobs,
      [job.jobId]: {
        ...state.jobs[job.jobId],
        ...job
      }
    }
  })
}

export const getJobFailure = (state) => {
  return state.merge({
    fetchingJob: false
  })
}

export const deleteQuery = (state) => {
  return state.merge({
    deletingQuery: true
  })
}

export const deleteQuerySuccess = (state, { queryId }) => {
  return state.merge({
    deletingQuery: false,
    queries: state.queries && state.queries.filter((query) => query.queryId !== queryId)
  })
}

export const deleteQueryFailure = (state) => {
  return state.merge({
    deletingQuery: false
  })
}

export const createQuerySuccess = (state, { query }) => {
  return state.merge({
    queries: [query, ...state.queries],
    query
  })
}

export const getReportTemplates = (state) => {
  return state.merge({
    fetchingTemplates: true
  })
}

export const getReportTemplatesSuccess = (state, { pageNumber, templates, totalCount }) => {
  return state.merge({
    fetchingTemplates: false,
    templates,
    templateCurrentPage: pageNumber,
    templateTotalCount: totalCount
  })
}

export const getReportTemplatesFailure = (state) => {
  return state.merge({
    fetchingTemplates: false
  })
}

export const createReportTemplateSuccess = (state, { template }) => {
  return state.merge({
    template
  })
}

export const createReportTemplateFailure = (state, { message }) => {
  return state.merge({
    createReportTemplateFailureMessage: message,
    template: null
  })
}

export const getReportQueryTemplates = (state) => {
  return state.merge({
    fetchingQueryTemplates: true
  })
}
  
export const getReportQueryTemplatesSuccess = (state, { pageNumber, queryTemplates, totalCount }) => {
  return state.merge({
    fetchingQueryTemplates: false,
    queryTemplates,
    queryTemplatesCurrentPage: pageNumber,
    queryTemplatesTotalCount: totalCount
  })
}

export const getReportQueryTemplatesFailure = (state) => {
  return state.merge({
    fetchingQueryTemplates: false
  })
}

export const updateReportTemplateSuccess = (state, { template }) => {
  return state.merge({
    templates: state.templates.map((t) => {
      if (t.templateId === template.templateId) {
        return {
          ...t,
          ...template
        }
      }
      return t
    })
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SEARCH_META_DATA]: getSearchMetaData,
  [Types.GET_SEARCH_META_DATA_SUCCESS]: getSearchMetaDataSuccess,
  [Types.GET_SEARCH_META_DATA_FAILURE]: getSearchMetaDataFailure,
  [Types.GET_QUERIES]: getQueries,
  [Types.GET_QUERIES_SUCCESS]: getQueriesSuccess,
  [Types.GET_QUERIES_FAILURE]: getQueriesFailure,
  [Types.CREATE_QUERY_SUCCESS]: createQuerySuccess,
  [Types.GET_QUERY]: getQuery,
  [Types.GET_QUERY_SUCCESS]: getQuerySuccess,
  [Types.GET_QUERY_FAILURE]: getQueryFailure,
  [Types.GET_JOB]: getJob,
  [Types.GET_JOB_SUCCESS]: getJobSuccess,
  [Types.GET_JOB_FAILURE]: getJobFailure,
  [Types.DELETE_QUERY]: deleteQuery,
  [Types.DELETE_QUERY_SUCCESS]: deleteQuerySuccess,
  [Types.DELETE_QUERY_FAILURE]: deleteQueryFailure,
  [Types.GET_REPORT_TEMPLATES]: getReportTemplates,
  [Types.GET_REPORT_TEMPLATES_SUCCESS]: getReportTemplatesSuccess,
  [Types.GET_REPORT_TEMPLATES_FAILURE]: getReportTemplatesFailure,
  [Types.CREATE_REPORT_TEMPLATE_FAILURE]: createReportTemplateFailure,
  [Types.CREATE_REPORT_TEMPLATE_SUCCESS]: createReportTemplateSuccess,
  [Types.GET_REPORT_QUERY_TEMPLATES]: getReportQueryTemplates,
  [Types.GET_REPORT_QUERY_TEMPLATES_SUCCESS]: getReportQueryTemplatesSuccess,
  [Types.GET_REPORT_QUERY_TEMPLATES_FAILURE]: getReportQueryTemplatesFailure,
  [Types.UPDATE_REPORT_TEMPLATE_SUCCESS]: updateReportTemplateSuccess
});
