import { Switch, Route } from 'react-router-dom'
import {
  ROOT,
  CLIENTS,
  APPOINTMENTS,
  EDIT_APPOINTMENT,
  USERS,
  ROLES,
  VIEW_PATIENT,
  PATIENT_REENTRY,
  CLIENT_VIISITOR_NOTES,
  CLIENT_DOCUMENTS,
  CLIENT_SUPPLIES,
  REPORTS,
  TEMPLATES,
  QUERY_TEMPLATES,
  CLIENT_REENTRY_INDENTIFYING_INFO,
  CLIENT_REENTRY_FAMILY,
  CLIENT_REENTRY_INCOME,
  CLIENT_REENTRY_HOUSING,
  CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION,
  CLIENT_REENTRY_LEGAL_HISTORY,
  CLIENT_REENTRY_INCARCERATION_REENTRY,
  CLIENT_REENTRY_TRANSPORTATION,
  CLIENT_REENTRY_IMMEDIATE_NEEDS,
  CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION,
  CLIENT_REENTRY_SKILLS_AND_ABILITIES,
  CLIENT_REENTRY_SUPPLIES,

  CLIENT_BFET_EMPLOYMENT_GOALS,
  CLIENT_BFET_SKILLS_AND_QUALIFICATION,
  CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS,
  CLIENT_BFET_EMPLOYMENT_PLAN,
  CLIENT_BFET_DECLARATION_AND_SIGNATURE,
  CLIENT_BFET_EMPLOYMENT_HISTORY,

  CLIENT_REENTRY_CONSENT,
  CLIENT_REENTRY_DOCUMENTS,
  CLIENT_REENTRY_NOTES,
  CLIENT_BFET,
  CLIENT_BFET_JOB_SEARCH,
  CLIENT_BFET_DOCUMENTS,
  CLIENT_BFET_NOTES,
  CLIENT_BFET_SUPPLIES,

  CLIENT_DVR_DOCUMENTS,
  CLIENT_DVR_NOTES,
  CLIENT_DVR_SERVICE_DELIVERY,
  CLIENT_DVR_VOCATIONAL,
  CLIENT_DVR_SUPPLIES,

  CLIENT_MENTAL_HEALTH,
  CLIENT_MENTAL_HEALTH_PH,
  CLIENT_MENTAL_HEALTH_DEMOGRAPHICS,
  CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES,
  CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY,
  CLIENT_MENTAL_HEALTH_DEVELOPMENT,
  CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS,
  CLIENT_MENTAL_HEALTH_EDUCATION,
  CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION,
  CLIENT_MENTAL_HEALTH_LEGAL,
  CLIENT_MENTAL_HEALTH_EMPLOYMENT,
  CLIENT_MENTAL_HEALTH_MILITARY,
  CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL,
  CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY,
  CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH,
  CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY,
  CLIENT_MENTAL_HEALTH_NUTRITION,
  CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY,
  CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION,
  CLIENT_MENTAL_HEALTH_SUPPLIES,

  CLIENT_MENTAL_HEALTH_DOCUMENTS,
  CLIENT_MENTAL_HEALTH_NOTES,
  ACCOUNT_INFO,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  EDIT_REPORT,
} from './CONSTANTS'

import { PrivateRoute } from 'navigation/PrivateRoute'
import Home from 'pages/Home.jsx'
import Patient from 'pages/Patient';
import Appointments from 'pages/Appointments';
import EditAppointment from 'pages/Appointments/Edit'

import Users from 'pages/Users';
import Roles from 'pages/Roles';
import Reports from 'pages/Reports';
import Templates from 'pages/Templates';
import TemplateQueries from 'pages/TemplateQueries';
import EditReport from 'pages/Reports/Edit'
import { CLIENT_FORM_TYPES } from 'redux/ClientRedux'
import EditPatient from 'pages/Patient/Edit'
import ViewPatient from 'pages/Patient/View'

import AccountInfo from 'pages/AccountInfo'
import NotFound from 'pages/NotFound'
import Login from 'pages/Login'
import ForgotPassword from 'pages/ForgotPassword'
import UpdatePassword from 'pages/UpdatePassword'
import ResetPassword from 'pages/ResetPassword'

export const RouterConfig = (props) => {
  return (
    <Switch>
      <PrivateRoute exact path={ROOT} component={Home} loggedIn={props.loggedIn} />
      <PrivateRoute exact path={CLIENTS} component={Patient} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={APPOINTMENTS} component={Appointments} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={EDIT_APPOINTMENT} component={EditAppointment} loggedIn={props.loggedIn} />
      <PrivateRoute exact path={ROLES} component={Roles} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={USERS} component={Users} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={REPORTS} component={Reports} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={EDIT_REPORT} component={EditReport} loggedIn={props.loggedIn} />
      <PrivateRoute exact path={TEMPLATES} component={Templates} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={QUERY_TEMPLATES} component={TemplateQueries} loggedIn={props.loggedIn}/>
      <PrivateRoute exact path={VIEW_PATIENT} component={ViewPatient} loggedIn={props.loggedIn} />
      
      <PrivateRoute exact path={PATIENT_REENTRY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_INDENTIFYING_INFO} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_FAMILY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_INCOME} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_HOUSING} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_PERSONAL_HEALTH_INFORMATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_LEGAL_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_INCARCERATION_REENTRY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_TRANSPORTATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_IMMEDIATE_NEEDS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_EMPLOYMENT_SEARCH_INFORMATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_SKILLS_AND_ABILITIES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY} />
      <PrivateRoute exact path={CLIENT_REENTRY_CONSENT} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY_CONSENT} />
      <PrivateRoute exact path={CLIENT_REENTRY_DOCUMENTS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY_DOCUMENTS} />
      <PrivateRoute exact path={CLIENT_REENTRY_NOTES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.REENTRY_NOTES} />
      <PrivateRoute exact path={CLIENT_REENTRY_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_REENTRY_SUPPLIES} />

      <PrivateRoute exact path={CLIENT_BFET} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_EMPLOYMENT_GOALS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_SKILLS_AND_QUALIFICATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_EMPLOYMENT_PLAN} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_DECLARATION_AND_SIGNATURE} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_EMPLOYMENT_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.BFET} />
      <PrivateRoute exact path={CLIENT_BFET_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_BFET_SUPPLIES} />
      <PrivateRoute exact path={CLIENT_BFET_JOB_SEARCH} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_BFET_JOB_SEARCH} />
      <PrivateRoute exact path={CLIENT_BFET_DOCUMENTS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_BFET_DOCUMENTS} />
      <PrivateRoute exact path={CLIENT_BFET_NOTES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_BFET_NOTES} />
      
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_PH} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_PH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_DEMOGRAPHICS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_DEVELOPMENT} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_EDUCATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_LEGAL} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_EMPLOYMENT} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_MILITARY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_NUTRITION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_SUPPLIES} />

      <PrivateRoute exact path={CLIENT_DVR_SERVICE_DELIVERY} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_DVR_SERVICE_DELIVERY} />
      <PrivateRoute exact path={CLIENT_DVR_VOCATIONAL} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_DVR_VOCATIONAL} />
      <PrivateRoute exact path={CLIENT_DVR_DOCUMENTS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_DVR_DOCUMENTS} />
      <PrivateRoute exact path={CLIENT_DVR_NOTES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_DVR_NOTES} />
      <PrivateRoute exact path={CLIENT_DVR_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_DVR_SUPPLIES} />

      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_DOCUMENTS} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_DOCUMENTS} />
      <PrivateRoute exact path={CLIENT_MENTAL_HEALTH_NOTES} component={EditPatient} loggedIn={props.loggedIn} category={CLIENT_FORM_TYPES.CLIENT_MENTAL_HEALTH_NOTES} />
      <PrivateRoute exact path={CLIENT_VIISITOR_NOTES} component={EditPatient} loggedIn={props.loggedIn} category={'visitornotes'} />

      <PrivateRoute exact path={CLIENT_DOCUMENTS} component={EditPatient} loggedIn={props.loggedIn} category={'documents'} />
      <PrivateRoute exact path={CLIENT_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={'supplies'} />

      <PrivateRoute exact path={CLIENT_SUPPLIES} component={EditPatient} loggedIn={props.loggedIn} category={'supplies'} />


      <PrivateRoute exact path={ACCOUNT_INFO} component={AccountInfo} loggedIn={props.loggedIn}/>
      <Route exact path={LOGIN} component={Login} />
      {/* <Route exact path={SIGN_UP} component={SignUp} /> */}
      <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={UPDATE_PASSWORD} component={UpdatePassword} />
      <Route exact path={RESET_PASSWORD} component={ResetPassword} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
};

export default RouterConfig;