import React from 'react';
import { Field } from 'formik';
import { Label, ErrorMsg } from 'styles/GenericStyles'

export const RadioButton = (props) => {
  const { label, name, options, meta, ...rest } = props;
  return (
    <div className={`${props.className ? props.className : null}`}>
      {label ? <Label className={`${props.labelClassName ? props.labelClassName : null}`} htmlFor={name}>{label}</Label> : null}

      <Field name={name} {...rest}>
        {
          ({ field, meta }) => {

            const fValue = field.value === 'true' ? true : (field.value === 'false' ? false : field.value )
            const list = options.map((option) => {
              return (
                <div className={`d-inline-flex align-items-center ${props.optionClassName || ''}`} key={option.key}>
                  <input
                    type='radio'
                    id={option.id}
                    {...field}
                    value={option.value}
                    checked={fValue === option.value}
                    className={`ml-2 mr-2 ${props.inputClassName || ''}`}
                    onChange={rest.onChange}
                  />
                  <Label className={props.inputLabelClassName || ''} htmlFor={option.id}>{option.key}</Label>
                </div>
              );
            })
            return (
              <>
                { list }
                { meta.touched && meta.error ? (
                  <ErrorMsg className="error">{meta.error}</ErrorMsg>
                ) : null }

              </>
            )
          }
        }
      </Field>
    </div>
  );
};