import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Income extends React.Component {


  calculateTotalKitCost = (items, ind, itemObj) => {
    return (items && items.reduce((sum, item, index) => {
      return sum + ((index === ind) ? itemObj.totalPrice : item.totalPrice)
    }, 0))
  }
  
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};

    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Income:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Income:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <Col className="p-0">
          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>Disability</LightGrayLabel>
            </Col>
            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasDisability)}</label>
              <label className="input-label">{data.disability}</label>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>TANF</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasTanf)}</label>

              <label className="input-label">{data.tanf}</label>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>Disability Life Line</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasDisability)}</label>

              <label className="input-label">{data.disabilityLifeLine}</label>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>Retirement</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasRetirement)}</label>

              <label className="input-label">{data.retirement}</label>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>SSA/SSDI</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasSsaORssdi)}</label>

              <label className="input-label">{data.ssaORssdi}</label>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>SNAP</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasSnap)}</label>

              <label className="input-label">{data.snap}</label>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>Wages/ Salary</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="mr-2 input-label">{isBoolean(data.hasWages)}</label>

              <label className="input-label">{data.wages}</label>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="col-xs-12 col-sm-12 col-md-4">
              <LightGrayLabel>Total Household Resources:</LightGrayLabel>
            </Col>

            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="input-label">{data.totalHouseholdResources}</label>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
