import React from "react";
import { Row } from "react-bootstrap";

// Intake Forms
import IdentifyingInfoForm from "components/Patient/Reentry/Intake/IdentifyingInfoForm";
import FamilyForm from "components/Patient/Reentry/Intake/FamilyForm";
import IncomeForm from "components/Patient/Reentry/Intake/IncomeForm";
import HousingForm from "components/Patient/Reentry/Intake/HousingForm";
import PersonalHealthInformation from "components/Patient/Reentry/Intake/PersonalHealthInformationForm";
import LegalHistoryForm from "components/Patient/Reentry/Intake/LegalHistoryForm";
import IncarcerationForm from "components/Patient/Reentry/Intake/IncarcerationForm";
import TransportationForm from "components/Patient/Reentry/Intake/TransportationForm";
import ImmediateNeedsForm from "components/Patient/Reentry/Intake/ImmediateNeedsForm";
import EmploymentSearchForm from "components/Patient/Reentry/Intake/EmploymentSearchForm";
import SkillsAndAbilityForm from "components/Patient/Reentry/Intake/SkillsAndAbilityForm";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

// Scrollable navigation
import ScrollingNavigation from "components/Patient/ScrollingNavigation";
import { PATIENT_TABS } from "config/CONSTANTS";

export class PatientIntake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTabKey: props.goTo,
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.goTo !== newProps.goTo) {
      this.setState({
        defaultTabKey: newProps.goTo,
      });
    }
  }

  onUpdate = (id, params, category) => {
    this.props.updateFormInit();

    switch (category) {
      case CLIENT_REENTRY_FORM_TYPES.identifyingInformation:
        this.props.updateClientReentryIntakeIdentifyingInformation(id, params);
        break;
      case CLIENT_REENTRY_FORM_TYPES.income:
        this.props.updateClientReentryIntakeIncomeInformation(id, params);
        break;
      case CLIENT_REENTRY_FORM_TYPES.employmentSearchInformation:
        this.props.updateClientReentryIntakeEmploymentSearchInformation(
          id,
          params
        );
        break;
      case CLIENT_REENTRY_FORM_TYPES.family:
        this.props.updateClientReentryIntakeFamilyInformation(id, params);
        break;
      case CLIENT_REENTRY_FORM_TYPES.housing:
        this.props.updateClientReentryIntakeHousingInformation(id, params);
        break;
      case CLIENT_REENTRY_FORM_TYPES.immediateNeeds:
        this.props.updateClientReentryIntakeImmediateNeedsInformation(
          id,
          params
        );
        break;
      case CLIENT_REENTRY_FORM_TYPES.incarceration:
        this.props.updateClientReentryIntakeIncarcerationInformation(
          id,
          params
        );
        break;
      case CLIENT_REENTRY_FORM_TYPES.legalHistory:
        this.props.updateClientReentryIntakeLegalHistoryInformation(id, params);
        break;
      case CLIENT_REENTRY_FORM_TYPES.personalHealthInformation:
        this.props.updateClientReentryIntakePersonalHealthInformation(
          id,
          params
        );
        break;
      case CLIENT_REENTRY_FORM_TYPES.skillsAndAbilities:
        this.props.updateClientReentryIntakeSkillsAndAbilitiesInformation(
          id,
          params
        );
        break;
      case CLIENT_REENTRY_FORM_TYPES.transportation:
        this.props.updateClientReentryIntakeTransportationInformation(
          id,
          params
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { defaultTabKey } = this.state;

    const {
      employmentSearchInformation,
      family,
      housing,
      identifyingInformation,
      immediateNeeds,
      incarceration,
      income,
      legalHistory,
      personalHealthInformation,
      skillsAndAbilities,
      transportation,
    } = this.props.reentry;

    const {
      clientID,
      updatingForm,
      isWriteIdentifyingInformationEnabled,
      isReadIdentifyingInformationEnabled,
      isWriteIncomeEnabled,
      isReadIncomeEnabled,
      isWriteHousingEnabled,
      isReadHousingEnabled,
      isWriteFamilyEnabled,
      isReadFamilyEnabled,
      isReadPersonalHealthInformationEnabled,
      isWritePersonalHealthInformationEnabled,
      isReadLegalHistoryEnabled,
      isWriteLegalHistoryEnabled,
      isReadIncarcerationEnabled,
      isWriteIncarcerationEnabled,
      isReadTransportationEnabled,
      isWriteTransportationEnabled,
      isReadImmediateNeedsEnabled,
      isWriteImmediateNeedsEnabled,
      isReadEmploymentSearchEnabled,
      isWriteEmploymentSearchEnabled,
      isReadskillsAndAbilitiesEnabled,
      isWriteskillsAndAbilitiesEnabled,
    } = this.props;

    const links = [];

    for (const [key, value] of Object.entries(PATIENT_TABS)) {
      links.push({ id: key, value: value });
    }

    return (
      <>
        <Row className="mr-1">
          <ScrollingNavigation
            links={links}
            path={`/clients/${clientID}/reentry`}
            onSelected={() => {}}
            active={defaultTabKey}
          />
        </Row>
        {defaultTabKey === PATIENT_TABS.INDENTIFYING_INFORMATION && (
          <IdentifyingInfoForm
            read={isReadIdentifyingInformationEnabled}
            write={isWriteIdentifyingInformationEnabled}
            clientID={clientID}
            client={this.props.client}
            data={identifyingInformation}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
          />
        )}
        {defaultTabKey === PATIENT_TABS.INCOME && (
          <IncomeForm
            clientID={clientID}
            data={income}
            read={isReadIncomeEnabled}
            write={isWriteIncomeEnabled}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
          />
        )}
        {defaultTabKey === PATIENT_TABS.FAMILY && (
          <FamilyForm
            clientID={clientID}
            data={family}
            read={isReadFamilyEnabled}
            write={isWriteFamilyEnabled}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
          />
        )}
        {defaultTabKey === PATIENT_TABS.HOUSING && (
          <HousingForm
            clientID={clientID}
            data={housing}
            read={isReadHousingEnabled}
            write={isWriteHousingEnabled}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
          />
        )}
        {defaultTabKey === PATIENT_TABS.PERSONAL_HEALTH_INFORMATION && (
          <PersonalHealthInformation
            clientID={clientID}
            data={personalHealthInformation}
            read={isReadPersonalHealthInformationEnabled}
            write={isWritePersonalHealthInformationEnabled}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
          />
        )}
        {defaultTabKey === PATIENT_TABS.LEGAL_HISTORY && (
          <LegalHistoryForm
            clientID={clientID}
            data={legalHistory}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadLegalHistoryEnabled}
            write={isWriteLegalHistoryEnabled}
          />
        )}
        {defaultTabKey === PATIENT_TABS.INCARCERATION_REENTRY && (
          <IncarcerationForm
            clientID={clientID}
            data={incarceration}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadIncarcerationEnabled}
            write={isWriteIncarcerationEnabled}
          />
        )}
        {defaultTabKey === PATIENT_TABS.TRANSPORTATION && (
          <TransportationForm
            clientID={clientID}
            data={transportation}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadTransportationEnabled}
            write={isWriteTransportationEnabled}
          />
        )}
        {defaultTabKey === PATIENT_TABS.IMMEDIATE_NEEDS && (
          <ImmediateNeedsForm
            clientID={clientID}
            data={immediateNeeds}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadImmediateNeedsEnabled}
            write={isWriteImmediateNeedsEnabled}
          />
        )}
        {defaultTabKey === PATIENT_TABS.EMPLOYMENT_SEARCH_INFORMATION && (
          <EmploymentSearchForm
            clientID={clientID}
            data={employmentSearchInformation}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadEmploymentSearchEnabled}
            write={isWriteEmploymentSearchEnabled}
          />
        )}
        {defaultTabKey === PATIENT_TABS.SKILLS_AND_ABILITIES && (
          <SkillsAndAbilityForm
            clientID={clientID}
            data={skillsAndAbilities}
            onUpdate={this.onUpdate}
            updatingForm={updatingForm}
            read={isReadskillsAndAbilitiesEnabled}
            write={isWriteskillsAndAbilitiesEnabled}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatingForm: state.client.updatingForm,
    isWriteIdentifyingInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INDENTIFYING_INFORMATION
    ),
    isReadIdentifyingInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INDENTIFYING_INFORMATION
    ),

    isWriteIncomeEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INCOME
    ),
    isReadIncomeEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INCOME
    ),
    isWriteFamilyEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_FAMILY
    ),
    isReadFamilyEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_FAMILY
    ),
    isWriteHousingEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_HOUSING
    ),
    isReadHousingEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_HOUSING
    ),
    isWritePersonalHealthInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_PERSONAL_HEALTH_INFORMATION
    ),
    isReadPersonalHealthInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_PERSONAL_HEALTH_INFORMATION
    ),
    isWriteLegalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_LEGAL_HISTORY
    ),
    isReadLegalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_LEGAL_HISTORY
    ),
    isWriteIncarcerationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_INCARCERATION_REENTRY
    ),
    isReadIncarcerationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_INCARCERATION_REENTRY
    ),
    isWriteTransportationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_TRANSPORTATION
    ),
    isReadTransportationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_TRANSPORTATION
    ),
    isWriteImmediateNeedsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_IMMEDIATE_NEEDS
    ),
    isReadImmediateNeedsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_IMMEDIATE_NEEDS
    ),
    isWriteEmploymentSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_EMPLOYMENT_SEARCH_INFORMATION
    ),
    isReadEmploymentSearchEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_EMPLOYMENT_SEARCH_INFORMATION
    ),
    isWriteskillsAndAbilitiesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_SKILLS_AND_ABILITIES
    ),
    isReadskillsAndAbilitiesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_SKILLS_AND_ABILITIES
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFormInit: () => dispatch(ClientActions.updateForm()),
    updateClientReentryIntakeIdentifyingInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeIdentifyingInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeIncomeInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeIncomeInformation(id, params)
      ),
    updateClientReentryIntakeEmploymentSearchInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeEmploymentSearchInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeFamilyInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeFamilyInformation(id, params)
      ),
    updateClientReentryIntakeHousingInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeHousingInformation(id, params)
      ),
    updateClientReentryIntakeImmediateNeedsInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeImmediateNeedsInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeIncarcerationInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeIncarcerationInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeLegalHistoryInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeLegalHistoryInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakePersonalHealthInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakePersonalHealthInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeSkillsAndAbilitiesInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeSkillsAndAbilitiesInformation(
          id,
          params
        )
      ),
    updateClientReentryIntakeTransportationInformation: (id, params) =>
      dispatch(
        ClientActions.updateClientReentryIntakeTransportationInformation(
          id,
          params
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientIntake);
