import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Demographics extends React.Component {

  calculateAge = (dob) => {
    var Bday = +new Date(dob);
    const age = (Date.now() - Bday) / 31557600000;
    return Math.floor(age);
  };

  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Demographics:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Demographics:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-1 row-cols-lg-2">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Name:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.firstName + " " + (data.middleName || '') + " " + data.lastName}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date Of Birth</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.dob}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Age</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{this.calculateAge(data.dob)}</label>
              </Col>
            </Row>

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Gender</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.gender + (data.pronoun !== '' ? ('(' + data.pronoun + ')') : '')}</label>
              </Col>
            </Row>

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Sexual Orientation</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="mr-2 input-label">{humanFormat(data.sexualOrientation)}</label>
                <label className="input-label">{data.sexualOrientationOther}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Describe your Ethnicity</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="mr-2 input-label">{humanFormat(data.ethinicty)}</label>
                <label className="input-label">{data.ethinictyOther}</label>
              </Col>
            </Row>
          </Row>

          <hr />

          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Form Completed By (If Someone Other Than Client)
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.formCompletedBy}</label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
