import { switchMap, filter, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs'
import RoleActions, { RoleTypes } from "redux/RoleRedux";
import AlertActions from 'redux/AlertRedux'

export const getRolesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(RoleTypes.GET_ROLES),
    delay(500),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getRoles((action.pageNumber || 1), user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            const roles = response.data && response.data.accountRoles && response.data.accountRoles.filter((account) => account.status === 'ENABLED')
            return of(RoleActions.getRolesSuccess(roles, response.data.totalCount, (action.pageNumber || 1)))
          } else {
            return of(
              RoleActions.getRolesFailure(),
              AlertActions.error(['Fetching Roles failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createRoleEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(RoleTypes.CREATE_ROLE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createRole(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(RoleActions.getRoles(1))
          } else {
            let errors = ['Failed creating Role! please try again.'];

            if (response.data && response.data.error_message) {
              errors.push(response.data.error_message)
            }

            if (response.data && response.data.errors && response.data.errors.hasUppercase) {
              errors.push(response.data.errors.hasUppercase)
            }

            return of(
              AlertActions.error(errors)
            )
          }
        })
      )
    })
  )

export const updateRoleEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(RoleTypes.UPDATE_ROLE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateRole(action.id, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(RoleActions.getRoles(1))
          } else {
            return of(
              AlertActions.error(['Upadting Role Information failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const deleteRoleEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(RoleTypes.DELETE_ROLE),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.deleteRole(action.id, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(RoleActions.getRoles(1))
          } else {
            return of(
              RoleActions.updateRoleFailure(),
              AlertActions.error(['Deleting Role failed! Please try again.'])
            )
          }
        })
      )
    })
  )