import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { isBoolean, humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Housing extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Housing:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    let lengthOfHomelessness =
      humanFormat(data.homelessnessLength || "") +
      " " +
      (data.otherHomelessnessLength || "");

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Housing:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        {/* form start */}
        <Row>
          <Col className="col-12">
            <LightGrayLabel>Length Of Homelessness:</LightGrayLabel>
          </Col>
          <Col xs={10}>
            <label className="input-label">{lengthOfHomelessness}</label>
          </Col>
        </Row>
        <Row className="pl-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Number of homeless episodes in the last five years:
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.numberOfhomelessnessEpisodesInLastFiveYears}
              </label>
            </Col>
          </Row>
          <Row className="p-0">
            <Col className="col-12 p-0 ml-3">
              <LightGrayLabel>Approximate number in lifetime:</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.approximateNumberInLifetime}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Within the last four (4) years, how many nights, months, or
                years, if any, have you spent in a shelter(s)?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.spentInShelter}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 ">
              <LightGrayLabel>
                Where have you slept for the last thirty (30) days?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.sleptDetailsInLastThirtyDays}
              </label>
            </Col>
          </Row>

          <Row className="p-0">
            <Col className="col-12 ">
              <LightGrayLabel>
                Is participant a Veteran, (anyone who has been on active
                military duty)
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {humanFormat(data.isReceivingHousingSubsidy)}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 ">
              <LightGrayLabel>
                What type of housing subsidy is the participant receiving?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.housingSubsidyType}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 ">
              <LightGrayLabel>
                Does/did participant pay own rent?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {isBoolean(data.paysOwnRent)}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 ">
              <LightGrayLabel>
                Does/did participant pay for own utilities?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {isBoolean(data.paysForOwnUtilities)}
              </label>
            </Col>
          </Row>

          <Col className="col-6 col-xs-12 p-0">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>
                  Has participant ever been evicted?
                </LightGrayLabel>
              </Col>
              <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                <label className="input-label">
                  {isBoolean(data.hasEverEvicted)}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 p-0 mt-4 mb-2">
            <ViewHeading>
              Reason For Leaving Last Housing Situation:
            </ViewHeading>
          </Col>

          <Col className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
            <Row className="mb-2">
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Eviction due to unpaid rent</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToUnpaidRent)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>
                  Eviction for reason other than unpaid rent
                </LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToReasonOtherThanUnpaidRent)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Conflict with friends or family</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToConflictWithFamily)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Overcrowding</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToOverCrowding)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Domestic violence:</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToDomesticViolence)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Incarceration:</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToIncarceration)}
                </label>
              </Col>
            </Row>

            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Housing condemned</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToHousingCondemned)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>
                  Hospitalization, including long term treatment
                </LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToHospitalization)}
                </label>
              </Col>
            </Row>

            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Fire</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToFire)}
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Other</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.evictionDueToOther)}
                </label>
              </Col>
            </Row>
            {/* </Col> */}
            <Row>
              <Col className="col-xs-12 col-sm-12 col-md-12 ml-4">
                <LightGrayLabel>Please Specify:</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">{data.otherEviction}</label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 mb-2">
            <ViewHeading>
              Please Identify Any Contributing Factors To Housing Instability:
            </ViewHeading>
          </Col>

          <Col className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>
                  Non-Housing (Street, park, car, etc.)
                </LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.nonHousing)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Emergency Shelter, Please name:</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.emergencyShelter)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Transitional Housing</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8  ml-4">
                <label className="input-label">
                  {isBoolean(data.transitionalHousing)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Psychiatric Facility</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.pyschiatricFacility)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Hospital</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.hospital)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>
                  Substance Abuse Treatment Facility
                </LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.substanceAbuseTreatmentFacility)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Prison/Jail</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.prisonOrJail)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Domestic Violence Shelter</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.domesticViolenceShelter)}
                </label>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel> Living with friends/family</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.livingWithFriendsOrFamily)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Rental Housing</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.rentalHousing)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Own apartment or house</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {isBoolean(data.ownApartmentOrHouse)}
                </label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="col-12 ml-4">
                <LightGrayLabel>Specify Other</LightGrayLabel>
              </Col>

              <Col className="col-xs-12 col-sm-12 col-md-8 ml-4">
                <label className="input-label">
                  {data.otherHousingInstabilityFactors}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      // </Row>
    );
  }
}
