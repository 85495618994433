
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'
import { Label } from 'styles/GenericStyles';
import { RadioButton } from 'components/Fields/RadioButton'

export class AddClient extends React.Component {
  handleSubmit = (params) => {
    let values = {}
    for (const [key, value] of Object.entries(params)) {
        if (value.trim()) {
          values[key] = value.trim();
        }
    }
    this.props.createClient(values)
  }

  componentWillReceiveProps (newProps) {
    if (this.props.creatingClient && !newProps.creatingClient) {
      this.props.onClose()
    }
  }
  
  setDate = (e) => {
    e.target.setAttribute('max', new Date().toISOString().split("T")[0])
  }

  render () {
    const genderRadioOptions = [
      { key: 'Male', value: 'male' },
      { key: 'Female', value: 'female' },
      { key: 'Transgender', value: 'transgender' }
    ];

   const { creatingClient } = this.props;

    return (
      <BaseFormModal heading={'Add Client'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Formik
          initialValues={{
            pronoun:'',
            eJasNumber: '',
            firstName: '',
            middleName: '',
            lastName: '',
            dob: '',
            phoneNumber: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            zipcode: '',
            state: '',
            country: 'USA',
            gender: ''
          }}

          validationSchema={
            Yup.object({
              firstName: Yup.string().trim().required('First name cannot be empty'),
              lastName: Yup.string().trim().required('Last name cannot be empty'),
              middleName: Yup.string().trim().required('Middle name cannot be empty'),
              // email: Yup
              //   .string()
              //   .email('Invalid email format').required('Email cannot be empty'),
              dob: Yup.date().required('Date of birth cannot be empty'),
              // phoneNumber: Yup.string().required('Phone Number cannot be empty'),
              // addressLine1: Yup.string().required('Address cannot be empty'),
              // addressLine2: Yup.string().required('Address cannot be empty'),
              // city: Yup.string().required('City cannot be empty'),
              // zipcode: Yup.string().required('Zipcode cannot be empty'),
              // state: Yup.string().required('State cannot be empty'),
              // country: Yup.string().required('Country cannot be empty')
              // gender: Yup.boolean().required('Select Gender').oneOf(genderRadioOptions, 'Select Gender')
            })
          }

          onSubmit={this.handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <Row> 
                <Col col-6>
                  <TextInput name="firstName" placeholder="First Name" label="First Name" />
                </Col>
                <Col col-6>
                  <TextInput name="middleName" placeholder="Middle Name" label="Middle Name" />
                </Col>
                <Col col-6>
                  <TextInput name="lastName" placeholder="Last Name" label="Last Name" />
                </Col>
              </Row>
              <Row>
                <Col col-6>
                  <TextInput name="dob" placeholder="DOB" label="Date of Birth" type='date' onClick={this.setDate}/>
                </Col>
                <Col col-6>
                  <TextInput name="phoneNumber" placeholder="Mobile Number" label="Mobile Number" />
                </Col>
              </Row>
              <Row>
                <Col col-12>
                  <TextInput name='email' placeholder='Email' label='Email (If none leave blank)' />
                </Col>
              </Row>

              <Row className='mt-3 mb-2'>
                <Col className='col-2'>
                  <label className='input-label' htmlFor='gender'>Gender:</label>
                </Col>
                <Col className='col-6'>
                  <RadioButton className='mb-3' name='gender' options={genderRadioOptions} onChange={handleChange} />
                </Col>
                <Col className='col-4'>
                  <TextInput name="pronoun" placeholder="Pronoun"/>
                </Col>
              </Row>

              <Label>Address</Label>
              <Row>
                <Col col-6>
                  <TextInput name='addressLine1' placeholder='Address'/>
                </Col>
                <Col col-6>
                  <TextInput name='addressLine2' placeholder='Apt, Suite etc' label='' />
                </Col>
              </Row>
              <Row>
                <Col col-6>
                  <TextInput name='city' placeholder='City' />
                </Col>
                <Col col-6>
                  <TextInput name='zipcode' placeholder='Zip Code' />
                </Col>
              </Row>
              <Row>
                <Col col-6>
                  <TextInput name='state' placeholder='State' />
                </Col>
                <Col col-6>
                  <TextInput name='country' placeholder='Country' readonly="true"/>
                </Col>
              </Row>

            <Label>EJAS Number</Label>
              <Row>
                <Col col-6>
                  <TextInput name='ejasNumber' placeholder='EJAS Number' />
                </Col>
              </Row>
              <Button size="md" className='float-right' disabled={creatingClient} variant="primary" type='submit'>{creatingClient ? 'Adding...' : 'Add'}</Button>
            </Form>
          )}
        </Formik>
      </BaseFormModal>
    )
  }
}

export default AddClient;