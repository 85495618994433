import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_VIISITOR_NOTES } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import moment from "moment";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export class DefaultNotesView extends React.Component {
  componentDidMount() {
    this.props.getClientVisitorNotesInformation(this.props.clientID, "DEFAULT");
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      data,
      clientID,
      isWriteDefaultVisitorNotesEnabled,
      isReadDefaultVisitorNotesEnabled,
    } = this.props;

    if (
      !isReadDefaultVisitorNotesEnabled &&
      !isWriteDefaultVisitorNotesEnabled
    ) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Visitors Notes:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Notes:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_VIISITOR_NOTES.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Row className="pb-2 ml-1 w-100">
            {data &&
              data.notes &&
              data.notes.map((note, index) => {
                return (
                  <Row className="w-100">
                    <Col className="note-title">{note && note.title}</Col>
                    <Col className="note-time">
                      {note &&
                        moment(note.createdAt).format("MM-DD-YYYY HH:mm")}
                    </Col>
                    <Col className="note-notes">{note && note.notes}</Col>
                  </Row>
                  
                );
              })}
          </Row>

        </div>
      </Row>
    );
  }

  render() {
    const { defaultVisitorNotesFetching } = this.props;
    return (
      <>
        {!defaultVisitorNotesFetching && this.renderLoading()}
        {defaultVisitorNotesFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultVisitorNotesFetching: state.client.notesFetching.DEFAULT,
    data: state.client.notes.DEFAULT,
    isWriteDefaultVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DEFAULT_NOTES
    ),
    isReadDefaultVisitorNotesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DEFAULT_NOTES
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientVisitorNotesInformation: (id, category) =>
      dispatch(ClientActions.getClientVisitorNotesInformation(id, category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultNotesView);
