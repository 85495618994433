import React from "react";
import EmploymentGoals from "components/Patient/BFET/Intake/View/EmploymentGoals";
import SkillsAndQualification from "components/Patient/BFET/Intake/View/SkillsAndQualification";
import AssessedEmploymentBarriers from "components/Patient/BFET/Intake/View/AssessedEmploymentBarriers";
import EmploymentPlan from "components/Patient/BFET/Intake/View/EmploymentPlan";
import DeclarationAndSignature from "components/Patient/BFET/Intake/View/DeclarationAndSignature";
import EmploymentHistory from "components/Patient/BFET/Intake/View/EmploymentHistory";

import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import LoadingView from "components/LoadingView.jsx";
import {
  CLIENT_BFET_EMPLOYMENT_GOALS,
  CLIENT_BFET_SKILLS_AND_QUALIFICATION,
  CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS,
  CLIENT_BFET_EMPLOYMENT_PLAN,
  CLIENT_BFET_DECLARATION_AND_SIGNATURE,
  CLIENT_BFET_EMPLOYMENT_HISTORY,
} from "navigation/CONSTANTS";

import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class PatientBFETView extends React.Component {
  componentDidMount() {
    this.props.getClientBfetInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      assessedEmploymentBarriers,
      declarationAndSignature,
      employmentGoals,
      employmentHistory,
      employmentPlan,
      skillsAndQualification,
    } = this.props.bfet;

    const {
      clientID,
      isWriteEmploymentGoalsEnabled,
      isReadEmploymentGoalsEnabled,
      isWriteSkillsAndQualificationEnabled,
      isReadSkillsAndQualificationEnabled,
      isWriteEmploymentHistoryEnabled,
      isReadEmploymentHistoryEnabled,
      isWriteEmploymentPlanEnabled,
      isReadEmploymentPlanEnabled,
      isWriteAssessedEmploymentBarriersEnabled,
      isReadAssessedEmploymentBarriersEnabled,
      isWriteDeclarationAndSignatureEnabled,
      isReadDeclarationAndSignatureEnabled,
    } = this.props;

    return (
      <>
        <EmploymentGoals
          link={CLIENT_BFET_EMPLOYMENT_GOALS.replace(":id", clientID)}
          data={employmentGoals}
          read={isReadEmploymentGoalsEnabled}
            write={isWriteEmploymentGoalsEnabled}
        />
        <SkillsAndQualification
          link={CLIENT_BFET_SKILLS_AND_QUALIFICATION.replace(":id", clientID)}
          data={skillsAndQualification}
          read={isReadSkillsAndQualificationEnabled}
          write={isWriteSkillsAndQualificationEnabled}
        />
        <AssessedEmploymentBarriers
          link={CLIENT_BFET_ASSESSED_EMPLOYMENT_BARRIERS.replace(
            ":id",
            clientID
          )}
          data={assessedEmploymentBarriers}
          read={isReadAssessedEmploymentBarriersEnabled}
          write={isWriteAssessedEmploymentBarriersEnabled}
        />
        <EmploymentPlan
          link={CLIENT_BFET_EMPLOYMENT_PLAN.replace(":id", clientID)}
          data={employmentPlan}
          read={isReadEmploymentPlanEnabled}
          write={isWriteEmploymentPlanEnabled}
        />
        <DeclarationAndSignature
          link={CLIENT_BFET_DECLARATION_AND_SIGNATURE.replace(":id", clientID)}
          data={declarationAndSignature}
          read={isReadDeclarationAndSignatureEnabled}
          write={isWriteDeclarationAndSignatureEnabled}
        />
        <EmploymentHistory
          link={CLIENT_BFET_EMPLOYMENT_HISTORY.replace(":id", clientID)}
          data={employmentHistory}
          read={isReadEmploymentHistoryEnabled}
          write={isWriteEmploymentHistoryEnabled}
        />
      </>
    );
  }

  render() {
    const { getBfetFetching } = this.props;
    return (
      <>
        {!getBfetFetching && this.renderLoading()}
        {getBfetFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getBfetFetching: state.client.getBfetFetching,
    bfet: state.client.bfet,
    isWriteEmploymentGoalsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_GOALS
    ),
    isReadEmploymentGoalsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_GOALS
    ),

    isWriteSkillsAndQualificationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_SKILLS_AND_QUALIFICATION
    ),
    isReadSkillsAndQualificationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_SKILLS_AND_QUALIFICATION
    ),
    isWriteAssessedEmploymentBarriersEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_ASSESSED_EMPLOYMENT_BARRIERS
    ),
    isReadAssessedEmploymentBarriersEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_ASSESSED_EMPLOYMENT_BARRIERS
    ),
    isWriteEmploymentPlanEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_PLAN
    ),
    isReadEmploymentPlanEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_PLAN
    ),
    isWriteDeclarationAndSignatureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_DECLARATION_AND_SIGNATURE
    ),
    isReadDeclarationAndSignatureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_DECLARATION_AND_SIGNATURE
    ),
    isWriteEmploymentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_BFET_EMPLOYMENT_HISTORY
    ),
    isReadEmploymentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_BFET_EMPLOYMENT_HISTORY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientBfetInformation: (id) =>
      dispatch(ClientActions.getClientBfetInformation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBFETView);
