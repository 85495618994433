import React from 'react';
import Layout from 'components/Layout.jsx'
import AddRoleModal from 'components/Modals/AddRoleModal'
import RoleList from 'components/Roles/RolesListing'
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { Button, Row, Col } from 'react-bootstrap';
import { PAGE_SIZE } from 'config/CONSTANTS'
import RoleActions from 'redux/RoleRedux'
import Strings from 'i18n/en'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'

export class Roles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddRoleModal: false
    }
  }

  componentDidMount () {
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_ROLES) && this.props.getRoles(1)
  }

  renderLoading = () => {
    return <LoadingView />
  }

  toggleAddRoleModal = () => {
    const { showAddRoleModal } = this.state;
    this.setState({
      showAddRoleModal: !showAddRoleModal
    })
  }

  onCreate = (params) => {
    this.props.createRole(params)
    this.toggleAddRoleModal()
  }

  render () {
    const { fetchingRoles } = this.props
    return (
      <Layout>
        { fetchingRoles && this.renderLoading() }
        { !fetchingRoles && this.renderContent() }
        { this.state.showAddRoleModal && <AddRoleModal onClose={this.toggleAddRoleModal} createRole={this.onCreate} />}
      </Layout>
    )
  }

  renderContent () {
    const { roles, totalCount, currentPage, isAddUserEnabled, isViewRolesEnabled, isEditUserEnabled, isDeleteUserEnabled  } = this.props
    return (
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <h4 className='text-nowrap'>Roles</h4>
          { isAddUserEnabled && <Button onClick={this.toggleAddRoleModal} className='ml-auto font-weight-bold text-nowrap' variant='link'> Add Role</Button>}
        </Row>

        { isViewRolesEnabled ? (
          <Row>
            <RoleList
              clients={roles}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              currentPage={currentPage}
              getRoles={this.props.getRoles}
              isEditUserEnabled={isEditUserEnabled}
              isDeleteUserEnabled={isDeleteUserEnabled}
            />
          </Row>
        ) : <Row>{Strings.permissions.message}</Row> }
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    fetchingRoles: state.role.fetchingRoles,
    roles: state.role.roles,
    totalCount: state.role.totalCount,
    currentPage: state.role.currentPage,
    isAddUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_ROLE),
    isEditUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.EDIT_ROLE),
    isDeleteUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.DELETE_ROLE),
    isViewRolesEnabled: isPermissionEnabled(state.user, PERMISSIONS.VIEW_ROLES)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (pageNumber) => dispatch(RoleActions.getRoles(pageNumber)),
    createRole: (params) => dispatch(RoleActions.createRole(params)),
    deleteRole: (id) => dispatch(RoleActions.deleteRole(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);