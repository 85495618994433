import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextMessage } from 'components/Fields/TextMessage';
import { TextInput } from 'components/Fields/TextInput'
import moment from 'moment'
import LoadingView from 'components/LoadingView.jsx'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { Select } from "components/Fields/Select";

export default class Notes extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      selected: props.data && props.data.notes && props.data.notes[0],
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  componentDidMount () {
    this.props.getClientVisitorNotesInformation(this.props.clientID, 'DEFAULT')
  }

  componentWillReceiveProps (newProps) {
    if (this.props.data && newProps.data && this.props.data.notes && newProps.data.notes && (newProps.data.notes.length !== this.props.data.notes.length || newProps.data.notes !== this.props.data)) {
      this.setState({
        selected: newProps.data.notes[newProps.data.notes.length - 1]
      })
    }
  }

  handleSubmit = (values) => {
    if (this.state.selected && this.state.selected.notesId) {
      this.props.updateClientVisitorNotesInformation(this.props.clientID, this.state.selected.notesId,  'DEFAULT', {...values, clientId: this.props.clientID })
    } else {
      this.props.postClientVisitorNotesInformation(this.props.clientID, 'DEFAULT', {...values, clientId: this.props.clientID })
    }
  }
  
  select = (notesId) => {
    const note = this.props.data.notes.filter((note) => note.notesId === notesId)
    this.setState({
      selected: note[0]
    })
  }

  addNewNote = () => {
    this.setState({
      selected: null
    })
  }

  onClose = (values) => {
    this.props.updateClientVisitorNotesInformation(this.props.clientID, this.state.selected.notesId,  'DEFAULT', {...this.state.selected, isClosed: true, clientId: this.props.clientID })
  }

  renderContent () {
    const { data } = this.props
    const { selected } = this.state

    return (
      <Row className='row mr-1'>
        <Col className='p-0 col-3'>
          <div className="note-list">
            <div className="add-note note" onClick={this.addNewNote}> + Take Note</div>
            {
              data && data.notes && data.notes.map((note, index) => {
                return (
                  <div key={index} className={`note ${((selected && selected.notesId) === (note && note.notesId)) ? 'note-selected' : ''}`} onClick={() => this.select(note && note.notesId) }>
                    <Row className='justify-content-between pr-3 pl-3'>
                      <span className="note-title">{note && note.title}</span>
                      {/* <DeleteIcon onClick={() => this.props.deleteClientVisitorNotesInformation(clientID, note.notesId, 'DEFAULT')} /> */}
                    </Row>

                    <span className="note-time">{ note && moment(note.createdAt).format('MM-DD-YYYY HH:mm')}</span>
                    <span className="note-time note-createdBy">{ `Created by ${note && note.createdByName}`}</span>
                    <span className="note-time note-modifedBy">{ `Last Modified by ${note && note.lastModifiedByName}`}</span>
                  </div>
                )
              })
            }
          </div>
        </Col>
        <Col className='p-0 pl-1 col-9'>
          <Formik
            key={selected && selected.notesId}
            enableReinitialize
            initialValues={{ ...selected }}
            onSubmit={this.handleSubmit}
            render={({ values, touched, dirty, errors, handleBlur }) => (
              <>
                <Prompt when={dirty} message={Strings.prompt.message} />
                <Form>
                <Select
                    name="notesType"
                    className="custom-select mb-2"
                    disabled={selected && selected.isClosed}
                  >
                    <option>Select</option>
                    <option value="Case Management Notes">Case Management Notes</option>
                    <option value="Clinical Notes (non-mental Health)">Clinical Notes (non-mental Health)</option>
                    <option value="Clinical Notes (Mental Health)">Clinical Notes (Mental Health)</option>
                    <option value="Sticky Notes-(for reminders to other staff regarding client)">Sticky Notes-(for reminders to other staff regarding client)</option>
                  </Select>
                  <TextInput disabled={selected && selected.isClosed} className='w-100 mb-1' name='title' placeholder={'Enter Title...'}/>
                  <TextMessage
                    rows={20}
                    labelClassName='d-none'
                    className='d-flex'
                    name='notes'
                    type='textarea'
                    placeholder='Enter notes....'
                    disabled={selected && selected.isClosed}
                  />
                  <Row className='justify-content-between mt-3 mr-0 ml-0 w-100'>
                    { selected && selected.notesId && !selected.isClosed && <Button variant="primary" onClick={this.onClose}>Close</Button> }
                    <Button variant="primary" type='submit' disabled={selected && selected.isClosed}>{selected && selected.notesId ? 'UPDATE' : 'SAVE'}</Button>
                  </Row>
                </Form>
              </>
            )}
          />
        </Col>
      </Row>      
    )
  }

  render() {
    const { getNotesFetching } = this.props
    return (
      <>
        { !getNotesFetching && this.renderLoading() }
        { getNotesFetching && this.renderContent() }
      </>
    )
  }
}
