import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  getAccount: null,
  getAccounts: ['pageNumber'],
  getAccountsSuccess: ['accounts', 'total', 'pageNumber'],
  getAccountsFailure: null,
  createAccount: ['params'],
  updateAccount: ['id', 'params'],
  deleteAccount: ['id'],
  editAccount: ['id'],
  getAccountSuppliesInformation: ['category'],
  getAccountSuppliesInformationSuccess: ['category', 'data'],
  referAccount: ['params'],
  referAccountSuccess: null,
  referAccountFailure: null
})

export const AccountTypes = Types
export default Creators

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  accounts: [],
  fetchingAccounts: false,
  totalCount: 0,
  currentPage: 1,
  accountSupplies: [],
  referringAccountInProgress: false
})

/* ---------- Reducers ----------------*/
export const getAccounts = (state) => {
  return state.merge({
    fetchingAccounts: true
  })
}

export const getAccountsSuccess = (state, { accounts, total, pageNumber }) => {
  return state.merge({
    fetchingAccounts: false,
    accounts: accounts,
    totalCount: total,
    currentPage: pageNumber || 1
  })
}

export const getAccountsFailure = (state) => {
  return state.merge({
    fetchingAccounts: false
  })
}

export const getAccountSuppliesInformationSuccess = (state, { category, data }) => {
  return state.merge({
    accountSupplies: data.supplies
  })
}

export const referAccount = (state) => {
  return state.merge({
    referringAccountInProgress: true
  })
}

export const referAccountSuccess = (state) => {
  return state.merge({
    referringAccountInProgress: false
  })
}

export const referAccountFailure = (state) => {
  return state.merge({
    referringAccountInProgress: false
  })
}

/* ------------------------------------------------ */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ACCOUNTS]: getAccounts,
  [Types.GET_ACCOUNTS_SUCCESS]: getAccountsSuccess,
  [Types.GET_ACCOUNTS_FAILURE]: getAccountsFailure,
  [Types.REFER_ACCOUNT]: referAccount,
  [Types.REFER_ACCOUNT_SUCCESS]: referAccountSuccess,
  [Types.REFER_ACCOUNT_FAILURE]: referAccountFailure,
  [Types.GET_ACCOUNT_SUPPLIES_INFORMATION_SUCCESS]: getAccountSuppliesInformationSuccess
})


export const getBFETSupplies = (state, category) => {
  return state && state.accountSupplies
  // return state && state.accountSupplies && state.accountSupplies.filter((supply) => supply.supplyCategory === category)
};
