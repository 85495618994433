
import React from 'react';
import { Form, Alert } from 'react-bootstrap'
import BaseModal from 'components/Modals/BaseModal'
import { REQUIRED_PASSWORD_LENGTH } from 'config/CONSTANTS'

export class AccountUpdate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: ''
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  save = () => {
    const { onSave, onClose } = this.props
    const { currentPassword, newPassword, confirmNewPassword } = this.state

    if (!currentPassword || currentPassword.length < REQUIRED_PASSWORD_LENGTH) {
      this.setState({
        errorMessage: 'Your current password is invalid.'
      })
    } else if (!newPassword || newPassword < REQUIRED_PASSWORD_LENGTH) {
      this.setState({
        errorMessage: 'Your new password is invalid, must be atleast 8 characters.'
      })
    } else if (newPassword !== confirmNewPassword) {
      this.setState({
        errorMessage: 'Your new password and confirm password does not match.'
      })
    } else {
      onSave && onSave(currentPassword, confirmNewPassword)
      onClose && onClose()
    }
  }

  render () {
    const { currentPassword, newPassword, confirmNewPassword, errorMessage } = this.state

    return (
      <BaseModal heading={'Update Account Password'} onHide={this.props.onClose} onSave={this.save}>
        { errorMessage && 
          <Alert className='full-width' variant={'danger'} dismissible={true}>
            {errorMessage}
          </Alert>
        }
        <Form className='form-horizontal'>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Password</Form.Label>
            <Form.Control className='col-md-7' placeholder="Current Password" value={currentPassword} name="currentPassword" type="password" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>First Name</Form.Label>
            <Form.Control className='col-md-7' placeholder="New Password" value={newPassword} name="newPassword" type="password" onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group className='row'>                  
            <Form.Label className='col-md-4 font-weight-bold'>Last Name</Form.Label>
            <Form.Control className='col-md-7' placeholder="Confirm Password" value={confirmNewPassword} name="confirmNewPassword" type="password" onChange={this.handleChange}/>
          </Form.Group>
        </Form>
      </BaseModal>
    )
  }
}

export default AccountUpdate;