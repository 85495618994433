import React from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap'
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { TextInput } from 'components/Fields/TextInput'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextMessage } from 'components/Fields/TextMessage'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class Education extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.education)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{...data}}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row className='align-items-center mb-4'>
                    <Col className='col-lg-6 col-md-12 mb-3 p-0'>
                      <TextInput
                          type='number'
                          name='yearsOfEducation'
                          placeholder='Other (specify)'
                          label='Years Of Education'
                          labelClassName='input-label mb-2'
                          className='w-75 mb-0'
                        />
                    </Col>
                    <Col className='col-6 p-0'>
                      <RadioButton
                        className='mb-0'
                        name='isCurrentlyEnrolledInSchool'
                        labelClassName='input-label mb-2 d-block'
                        options={yesOrNoOptions}
                        label='Currently enrolled in school?'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col className='col-12 p-0'>
                      <Checkbox labelClassName='text-wrap' name='isHighSchoolGraduate'>High school graduate/GED</Checkbox>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='col-md-6 col-lg-5 p-0 d-flex align-items-center'>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-2' name='isVocational'>Vocational</Checkbox>
                      <TextInput
                        type='number'
                        name='numberOfVocationalYears'
                        placeholder='No. of years'
                        className='w-50 mb-0 mr-3 ml-auto'
                      />
                    </Col>
                    <Col className='col-7 p-0 d-flex align-items-center'>
                      <RadioButton
                        name='vocationalFinished'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-0'
                        options={yesOrNoOptions}
                        label='Graduated:'
                        onChange={handleChange}
                      />
                      <TextInput
                        name='vocationalMajor'
                        placeholder='Major'
                        className='d-inline mb-0'
                      />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='col-md-6 col-lg-5  p-0 d-flex align-items-center'>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-2' name='isCollege'>College</Checkbox>
                      <TextInput
                        type='number'
                        name='numberOfCollegeYears'
                        placeholder='No. of years'
                        className='w-50 mb-0 mr-3 ml-auto'
                      />
                    </Col>
                    <Col className='col-7 p-0 d-flex align-items-center'>
                      <RadioButton
                        name='collegeFinished'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-0'
                        options={yesOrNoOptions}
                        label='Graduated:'
                        onChange={handleChange}
                      />
                      <TextInput
                        name='collegeMajor'
                        placeholder='Major'
                        className='d-inline mb-0'
                      />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='col-md-6 col-lg-5  p-0 d-flex align-items-center'>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-2' name='isGraduate'>Graduate</Checkbox>
                      <TextInput
                        type='number'
                        name='numberOfGraduateYears'
                        placeholder='No. of years'
                        className='w-50 mb-0 mr-3 ml-auto'
                      />
                    </Col>
                    <Col className='col-7 p-0 d-flex align-items-center'>
                      <RadioButton
                        name='graduationFinished'
                        labelClassName='input-label text-wrap mr-auto'
                        className='d-flex align-items-center mb-0'
                        options={yesOrNoOptions}
                        label='Graduated:'
                        onChange={handleChange}
                      />
                      <TextInput
                        name='graduationMajor'
                        placeholder='Major'
                        className='d-inline mb-0'
                      />
                    </Col>
                  </Row>
                  <Row className='mt-4 mb-3'>
                    <Col className='p-0 col-10'>
                      <TextMessage
                        labelClassName='text-wrap mb-2'
                        label='Other Training'
                        name='otherTraining'
                        placeholder='Describe'
                        rows={3}
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-10'>
                      <TextMessage
                        labelClassName='text-wrap mb-2'
                        label='Special Circumstances'
                        name='specialCircumstances'
                        placeholder='(e.g., learning disabilities, gifted, etc.):'
                        rows={3}
                        size="md"/>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}