import React from 'react';
import Layout from 'components/Layout.jsx'
import AddClientModal from 'components/Modals/AddClientModal'
import PatientList from 'components/PatientList'
import { connect } from 'react-redux'
import UserActions from 'redux/UserRedux'
import ClientActions from 'redux/ClientRedux'
import { PAGE_SIZE } from 'config/CONSTANTS'
import LoadingView from 'components/LoadingView.jsx'
import { Row, Col, Form, Button } from 'react-bootstrap';
import AdvancedSearchModal from 'components/Modals/AdvancedSearchModal'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import Strings from 'i18n/en'

export class PatientsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showAddClientModal: false,
      showSearchModal: false,
      searchQuery: undefined
    }
  }

  componentDidMount () {
    if (isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_CLIENTS)) {
      this.props.getClients(1, 'lastName')
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  toggleAddClientModal = () => {
    const { showAddClientModal } = this.state;
    this.setState({
      showAddClientModal: !showAddClientModal
    })
  }

  onCreate = (params) => {
    this.props.createClient({ ...params, reload: true })
    this.toggleAddClientModal()
  }

  onAddClient = () => {
    this.setState({
      showSearchModal: false,
      showAddClientModal: true
    })
  }

  render () {
    const { fetchingClients, creatingClient } = this.props
    const { showSearchModal, searchQuery } = this.state
    return (
      <Layout>
        { fetchingClients && this.renderLoading() }
        { !fetchingClients && this.renderContent() }

        { showSearchModal && <AdvancedSearchModal searchQuery={searchQuery} onClose={this.onCloseAdvancedSearch} onAddClient={this.onAddClient} />}
        { this.state.showAddClientModal && <AddClientModal onClose={this.toggleAddClientModal} createClient={this.onCreate} creatingClient={creatingClient} />}
      </Layout>
    )
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        showSearchModal: true,
        searchQuery: e.target.value
      })
    }
  }

  onCloseAdvancedSearch = () => {
    this.setState({
      showSearchModal: false
    })
  }

  renderContent () {
    const { clients, totalCount, currentPage, sortKey, orderBy, isSearchClientEnabled, isViewClientsEnabled, isAddClientEnabled } = this.props
    return (
      <Col md={12}>
        <Row className="d-flex justify-content-between align-items-center">
          <h4 className='text-nowrap'>Client List</h4>

          <Row>
            <Row className='ml-0 mt-2 mr-0'>
              { isAddClientEnabled && <Button
                onClick={this.toggleAddClientModal}
                className='font-weight-bold ml-auto pr-0 mb-3 pt-0'
                style={{boxShadow: 'none'}}
                variant='link'>+ Add Client
              </Button> }
            </Row>

            {
              isSearchClientEnabled && (
                <Row className='mr-0 ml-4'>
                  <Form.Control type='text' placeholder='Search by last name' onKeyDown={this._handleKeyDown} />
                </Row>
              )
            }
          </Row>
          
        </Row>

        {
          isViewClientsEnabled ? (<Row>
            <PatientList
              clients={clients}
              currentpage={currentPage}
              sortKey={sortKey}
              orderBy={orderBy}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              getClients={this.props.getClients}
            />
          </Row> ) : <Row>{Strings.permissions.message}</Row>
        }
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingClients: state.client.fetchingClients,
    clients: state.client.clients,
    totalCount: state.client.totalCount,
    currentPage: state.client.currentPage,
    sortKey: state.client.sortKey,
    orderBy: state.client.orderBy,
    creatingClient: state.client.creatingClient,
    isSearchClientEnabled: isPermissionEnabled(state.user, PERMISSIONS.SEARCH_CLIENT),
    isViewClientsEnabled: isPermissionEnabled(state.user, PERMISSIONS.VIEW_CLIENTS),
    isAddClientEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_CLIENT),
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPatient: (userId) => dispatch(UserActions.getPatient(userId)),
    updatePatient: (userId, user) => dispatch(UserActions.updateUserInfo(userId, user)),
    createClient: (params) => dispatch(ClientActions.createClient(params)),
    getClients: (pageNumber, sortKey, orderBy) => dispatch(ClientActions.getClients(pageNumber, sortKey, orderBy))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientsPage);