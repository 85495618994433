import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Employment extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Employment:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col> Mental Health &gt; Personal History &gt; Employment:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row>
            <Col>
              <Table className="w-100 pl-0 ml-0" borderless responsive>
                <thead>
                  <th className="pl-0 align-top" style={{ width: "10%" }}><LightGrayLabel>
                    Employer</LightGrayLabel>
                  </th>
                  <th className="align-top" style={{ width: "20%" }}><LightGrayLabel>
                    Dates Worked</LightGrayLabel>
                  </th>
                  <th className="align-top" style={{ width: "20%" }}><LightGrayLabel>
                    Job Title</LightGrayLabel>
                  </th>
                  <th className="align-top" style={{ width: "25%" }}><LightGrayLabel>
                    Reason You Left Job</LightGrayLabel>
                  </th>
                  <th className="align-top" style={{ width: "25%" }}><LightGrayLabel>
                    How often did you miss work?</LightGrayLabel>
                  </th>
                </thead>
                <tbody>
                  {data &&
                    data.employers &&
                    data.employers.map((employer) => (
                      <tr>
                        <td className="pl-0 py-0">
                          <Col className="col-12">
                            <label className="input-label">{employer.employerName}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{employer.numberOfDaysWorked}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{employer.jobTitle}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{employer.reasonsToLeaveJob}</label>
                          </Col>
                        </td>
                        <td className="py-0">
                          <Col className="col-12 p-0">
                            <label className="input-label">{employer.howOftenWorkIsMissed}</label>
                          </Col>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Currently employed:</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.isCurrentlyEmployed)}</label>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center w-100">
            <Col className=" col-12">
              <label className="input-label mb-1">If Yes, Yes:</label>
            </Col>
            <Row className="mb-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0 ml-1">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Full Time</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isFullTime)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Part Time</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isPartTime)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Hours Per Week</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.numberOfHoursPerWeek}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Rate Of Pay</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.hourlyPay}</label>
                </Col>
              </Row>
            </Row>
          </Row>
          <Row className="align-items-center w-100">
            <Col className=" col-12">
              <label className="input-label mb-1">If Yes, No:</label>
            </Col>
          </Row>
          <Row className="mb-3 row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Fired</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.fired)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Quit</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.quit)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Laid off</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.laidOff)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Disabled</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.disabled)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Retired</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.retired)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Social Security</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.socialSecurity)}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Student</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.student)}</label>
              </Col>
            </Col>

            <Col className="p-0 d-flex align-items-center col-md-4">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.other}</label>
                </Col>
              </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Specify</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.otherReason}</label>
                </Col>
              </Col>
            </Col>
          </Row>

          <Col className="p-0 col-12 mb-2">
            <label className="input-label">
              I Prefer To Work The Following Days (Check All That Apply):
            </label>
          </Col>
          <Row className="mb-3 row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-6">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Mon</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnMon)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col- p-0">
              <Col className="col-12">
                <LightGrayLabel>Tues</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnTue)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Wed</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnWed)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Thurs</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnThu)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Fri</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnFri)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sat</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnSat)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Sun</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnSun)}</label>
              </Col>
            </Col>
            <Col className="p-0 mb-2"></Col>
          </Row>
          <Col className="p-0 col-12">
            <label className="input-label">
              I Prefer To Work The Following Shifts (Check All That Apply):
            </label>
          </Col>
          <Row className="mb-3 row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-6">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Days</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnDays)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Nights</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnNights)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Graveyard</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.preferToWorkOnGraveyard)}</label>
              </Col>
            </Row>
            <Col className="p-0 d-flex align-items-center col-md-4">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.preferToWorkOnOther)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Specify</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.otherPreferToWorkOn)}</label>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
