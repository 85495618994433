import React from "react";
import { Formik, Form } from "formik";
import BaseForm from "components/Patient/BaseForm";
import { TextMessage } from "components/Fields/TextMessage";
import { TextInput } from "components/Fields/TextInput";
import { Row, Col } from "react-bootstrap";
import { CLIENT_MENTAL_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class AdditionalInformation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_MENTAL_FORM_TYPES.additionalInformation
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm
                handleSubmit={this.handleSubmit}
                updatingForm={updatingForm}
                disabled={!write}
              >
                <div className="w-100">
                  <Row className="align-items-center">
                    <Col className="col-12 p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="Please Use This Page For Any Additional Information:"
                        name="additionalInformation"
                        placeholder="Additional Information"
                        rows="4"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col className="col-10 p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="Clinician Name And Credentials"
                        name="clinicianName"
                        placeholder="Clinician Name And Credentials"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-6 p-0">
                      <TextInput
                        type="text"
                        name="clinicianSignature"
                        className="w-75"
                        labelClassName="mb-2"
                        label="Clinician’s Signature"
                        placeholder="Clinician’s Signature"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        className="w-75"
                        labelClassName="mb-2"
                        name="clinicianSignatureDate"
                        type="date"
                        label="Date"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        type="text"
                        name="participantSignature"
                        className="w-75"
                        labelClassName="mb-2"
                        label="Participant’s Signature"
                        placeholder="Participant’s Signature"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        className="w-75"
                        labelClassName="mb-2"
                        name="participantSignatureDate"
                        type="date"
                        label="Date"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0 d-flex flex-column align-items-center">
                      <p className="text-wrap text-center w-75">
                        All Information Gathered During This Assessment Process
                        Will Be Used Collaboratively To Write The Report
                        Summary.
                      </p>
                      <p className="text-wrap">
                        <strong>Unprofessional Conduct Rcw 18.30.180</strong> To
                        Report, Contact DOH Customer Service At (360) 236-4700
                      </p>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
