/*
* Any App specific constant can go here!
*/ 
export const SYSTEM_ERROR = "System error. Please try again later!";
export const REQUIRED_PASSWORD_LENGTH = 7
export const POLL_INTERVAL = 5000 // in seconds
export const PATIENT_PASSWORD = 'Welcome$123'
export const PAGE_SIZE = 10
export const ACCOUNTS_PAGE_SIZE = 25
export const APPOINTMENTS_PAGE_SIZE = 50

export const PATIENT_TABS = Object.freeze({
  INDENTIFYING_INFORMATION: 'INDENTIFYING INFORMATION',
  FAMILY: 'FAMILY',
  INCOME: 'INCOME',
  HOUSING: 'HOUSING',
  PERSONAL_HEALTH_INFORMATION: 'PERSONAL HEALTH INFORMATION',
  LEGAL_HISTORY: 'LEGAL HISTORY',
  INCARCERATION_REENTRY: 'INCARCERATION REENTRY',
  TRANSPORTATION: 'TRANSPORTATION',
  IMMEDIATE_NEEDS: 'IMMEDIATE NEEDS',
  EMPLOYMENT_SEARCH_INFORMATION: 'EMPLOYMENT SEARCH INFORMATION',
  SKILLS_AND_ABILITIES: 'SKILLS AND ABILITIES'
 })

 export const PATIENT_BFET_TABS = Object.freeze({
  EMPLOYMENT_GOALS: 'EMPLOYMENT GOALS',
  SKILLS_AND_QUALIFICATION: 'SKILLS AND QUALIFICATION',
  ASSESSED_EMPLOYMENT_BARRIERS: 'ASSESSED EMPLOYMENT BARRIERS',
  EMPLOYMENT_PLAN: 'EMPLOYMENT PLAN',
  DECLARATION_AND_SIGNATURE: 'DECLARATION AND SIGNATURE',
  EMPLOYMENT_HISTORY: 'EMPLOYMENT HISTORY'
 })

 export const PATIENT_MENTAL_TABS = Object.freeze({
  DEMOGRAPHICS: 'DEMOGRAPHICS',
  PRIMARY_REASONS_FOR_SEEKING_SERVICES: 'PRIMARY REASONS FOR SEEKING SERVICES',
  FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY: 'FAMILY INFORMATION AND PSYCHOSOCIAL HISTORY',
  DEVELOPMENT: 'DEVELOPMENT',
  SOCIAL_RELATIONSHIPS: 'SOCIAL RELATIONSHIPS',
  EDUCATION: 'EDUCATION',
  SPIRITUAL_RELIGION: 'SPIRITUAL RELIGION',
  LEGAL: 'LEGAL',
  EMPLOYMENT: 'EMPLOYMENT',
  MILITARY: 'MILITARY',
  LEISURE_AND_RECREATIONAL: 'LEISURE AND RECREATIONAL',
  COUNSELING_AND_PRIOR_TREATMENT_HISTORY: 'COUNSELING AND PRIOR TREATMENT HISTORY',
  MEDICAL_AND_PHYSICAL_HEALTH: 'MEDICAL AND PHYSICAL HEALTH',
  MEDICAL_HISTORY: 'MEDICAL HISTORY',
  NUTRITION: 'NUTRITION',
  CHEMICAL_USE_HISTORY: 'CHEMICAL USE HISTORY',
  ADDITIONAL_INFORMATION: 'ADDITIONAL INFORMATION',
 })

export const BOOLEAN_CHECKBOX_OPTIONS = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]