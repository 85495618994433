import React from "react";
// Intake Forms
import Demographics from "components/Patient/MentalHealth/PersonalHistory/View/Demographics";
import Development from "components/Patient/MentalHealth/PersonalHistory/View/Development";
import Education from "components/Patient/MentalHealth/PersonalHistory/View/Education";
import FamilyInformation from "components/Patient/MentalHealth/PersonalHistory/View/FamilyInformation";
import PrimaryReasonsForSeekingServices from "components/Patient/MentalHealth/PersonalHistory/View/PrimaryReasonsForSeekingServices";
import SocialRelationships from "components/Patient/MentalHealth/PersonalHistory/View/SocialRelationships";
import SpiritualReligion from "components/Patient/MentalHealth/PersonalHistory/View/SpiritualReligion";
import Legal from "components/Patient/MentalHealth/PersonalHistory/View/Legal";
import Employment from "components/Patient/MentalHealth/PersonalHistory/View/Employment";
import Military from "components/Patient/MentalHealth/PersonalHistory/View/Military";
import LeisureAndRecreational from "components/Patient/MentalHealth/PersonalHistory/View/LeisureAndRecreational";
import PriorTreatmentHistory from "components/Patient/MentalHealth/PersonalHistory/View/PriorTreatmentHistory";
import PhysicalAndMedicalHealth from "components/Patient/MentalHealth/PersonalHistory/View/PhysicalAndMedicalHealth";
import MedicalHistory from "components/Patient/MentalHealth/PersonalHistory/View/MedicalHistory";
import Nutrition from "components/Patient/MentalHealth/PersonalHistory/View/Nutrition";
import ChemicalUseHistory from "components/Patient/MentalHealth/PersonalHistory/View/ChemicalUseHistory";
import AdditionalInformation from "components/Patient/MentalHealth/PersonalHistory/View/AdditionalInformation";

import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import LoadingView from "components/LoadingView.jsx";

import {
  CLIENT_MENTAL_HEALTH_DEMOGRAPHICS,
  CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES,
  CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY,
  CLIENT_MENTAL_HEALTH_DEVELOPMENT,
  CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS,
  CLIENT_MENTAL_HEALTH_EDUCATION,
  CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION,
  CLIENT_MENTAL_HEALTH_LEGAL,
  CLIENT_MENTAL_HEALTH_EMPLOYMENT,
  CLIENT_MENTAL_HEALTH_MILITARY,
  CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL,
  CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY,
  CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH,
  CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY,
  CLIENT_MENTAL_HEALTH_NUTRITION,
  CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY,
  CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION,
} from "navigation/CONSTANTS";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class PatientMentalView extends React.Component {
  componentDidMount() {
    this.props.getClientMentalInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    const {
      additionalInformation,
      chemicalUseHistory,
      demographics,
      development,
      education,
      employment,
      familyInformation,
      legal,
      leisure,
      medicalHealth,
      medicalHistory,
      military,
      nutrition,
      primaryReasonsForSeekingServices,
      socialRelationships,
      spiritual,
      treatmentHistory,
    } = this.props.mental;

    const {
      clientID,
      isWriteAdditionalInformationEnabled,
      isReadAdditionalInformationEnabled,
      isWriteChemicalUseHistoryEnabled,
      isReadChemicalUseHistoryEnabled,
      isWriteDemographicsEnabled,
      isReadDemographicsEnabled,
      isWriteDevelopmentEnabled,
      isReadDevelopmentEnabled,
      isWriteEducationEnabled,
      isReadEducationEnabled,
      isWriteEmploymentEnabled,
      isReadEmploymentEnabled,
      isWriteFamilyInformationEnabled,
      isReadFamilyInformationEnabled,
      isWriteLegalEnabled,
      isReadLegalEnabled,
      isWriteLeisureEnabled,
      isReadLeisureEnabled,
      isWriteMedicalHealthEnabled,
      isReadMedicalHealthEnabled,
      isWriteMedicalHistoryEnabled,
      isReadMedicalHistoryEnabled,
      isWriteMilitaryEnabled,
      isReadMilitaryEnabled,
      isWriteNutritionEnabled,
      isReadNutritionEnabled,
      isWritePrimaryReasonsForSeekingServicesEnabled,
      isReadPrimaryReasonsForSeekingServicesEnabled,
      isWriteSocialRelationshipsEnabled,
      isReadSocialRelationshipsEnabled,
      isWriteSpiritualEnabled,
      isReadSpiritualEnabled,
      isWriteTreatmentHistoryEnabled,
      isReadTreatmentHistoryEnabled,
      client
    } = this.props;

    return (
      <>
        <Demographics
          link={CLIENT_MENTAL_HEALTH_DEMOGRAPHICS.replace(":id", clientID)}
          data={{ ...demographics, ...client }}
          read={isReadDemographicsEnabled}
          write={isWriteDemographicsEnabled}
        />
        <PrimaryReasonsForSeekingServices
          link={CLIENT_MENTAL_HEALTH_PRIMARY_REASONS_FOR_SEEKING_SERVICES.replace(
            ":id",
            clientID
          )}
          data={primaryReasonsForSeekingServices}
          read={isReadPrimaryReasonsForSeekingServicesEnabled}
            write={isWritePrimaryReasonsForSeekingServicesEnabled}
        />
        <FamilyInformation
          link={CLIENT_MENTAL_HEALTH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY.replace(
            ":id",
            clientID
          )}
          data={familyInformation}
          read={isReadFamilyInformationEnabled}
          write={isWriteFamilyInformationEnabled}
        />
        <Development
          link={CLIENT_MENTAL_HEALTH_DEVELOPMENT.replace(":id", clientID)}
          data={development}
          read={isReadDevelopmentEnabled}
          write={isWriteDevelopmentEnabled}
        />
        <SocialRelationships
          link={CLIENT_MENTAL_HEALTH_SOCIAL_RELATIONSHIPS.replace(
            ":id",
            clientID
          )}
          data={socialRelationships}
          read={isReadSocialRelationshipsEnabled}
          write={isWriteSocialRelationshipsEnabled}
        />
        <Education
          link={CLIENT_MENTAL_HEALTH_EDUCATION.replace(":id", clientID)}
          data={education}
          read={isReadEducationEnabled}
          write={isWriteEducationEnabled}
        />
        <SpiritualReligion
          link={CLIENT_MENTAL_HEALTH_SPIRITUAL_RELIGION.replace(
            ":id",
            clientID
          )}
          data={spiritual}
          read={isReadSpiritualEnabled}
          write={isWriteSpiritualEnabled}
        />
        <Legal
          link={CLIENT_MENTAL_HEALTH_LEGAL.replace(":id", clientID)}
          data={legal}
          read={isReadLegalEnabled}
          write={isWriteLegalEnabled}
        />
        <Employment
          link={CLIENT_MENTAL_HEALTH_EMPLOYMENT.replace(":id", clientID)}
          data={employment}
          read={isReadEmploymentEnabled}
          write={isWriteEmploymentEnabled}
        />
        <Military
          link={CLIENT_MENTAL_HEALTH_MILITARY.replace(":id", clientID)}
          data={military}
          read={isReadMilitaryEnabled}
          write={isWriteMilitaryEnabled}
        />
        <LeisureAndRecreational
          link={CLIENT_MENTAL_HEALTH_LEISURE_AND_RECREATIONAL.replace(
            ":id",
            clientID
          )}
          data={leisure}
          read={isReadLeisureEnabled}
          write={isWriteLeisureEnabled}
        />
        <PriorTreatmentHistory
          link={CLIENT_MENTAL_HEALTH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY.replace(
            ":id",
            clientID
          )}
          data={treatmentHistory}
          read={isReadTreatmentHistoryEnabled}
          write={isWriteTreatmentHistoryEnabled}
        />
        <PhysicalAndMedicalHealth
          link={CLIENT_MENTAL_HEALTH_MEDICAL_AND_PHYSICAL_HEALTH.replace(
            ":id",
            clientID
          )}
          data={medicalHealth}
          read={isReadMedicalHealthEnabled}
          write={isWriteMedicalHealthEnabled}
        />
        <MedicalHistory
          link={CLIENT_MENTAL_HEALTH_MEDICAL_HISTORY.replace(":id", clientID)}
          data={medicalHistory}
          read={isReadMedicalHistoryEnabled}
            write={isWriteMedicalHistoryEnabled}
        />
        <Nutrition
          link={CLIENT_MENTAL_HEALTH_NUTRITION.replace(":id", clientID)}
          data={nutrition}
          read={isReadNutritionEnabled}
          write={isWriteNutritionEnabled}
        />
        <ChemicalUseHistory
          link={CLIENT_MENTAL_HEALTH_CHEMICAL_USE_HISTORY.replace(
            ":id",
            clientID
          )}
          data={chemicalUseHistory}
          read={isReadChemicalUseHistoryEnabled}
          write={isWriteChemicalUseHistoryEnabled}
        />
        <AdditionalInformation
          link={CLIENT_MENTAL_HEALTH_ADDITIONAL_INFORMATION.replace(
            ":id",
            clientID
          )}
          data={additionalInformation}
          read={isReadAdditionalInformationEnabled}
          write={isWriteAdditionalInformationEnabled}
        />
      </>
    );
  }

  render() {
    const { getMentalFetching } = this.props;
    return (
      <>
        {!getMentalFetching && this.renderLoading()}
        {getMentalFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.client,
    getMentalFetching: state.client.getMentalFetching,
    mental: state.client.mental,
    isWriteAdditionalInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_ADDITIONAL_INFORMATION
    ),
    isReadAdditionalInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_ADDITIONAL_INFORMATION
    ),

    isWriteChemicalUseHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_CHEMICAL_USE_HISTORY
    ),
    isReadChemicalUseHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_CHEMICAL_USE_HISTORY
    ),

    isWriteDemographicsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DEMOGRAPHICS
    ),
    isReadDemographicsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DEMOGRAPHICS
    ),

    isWriteDevelopmentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_DEVELOPMENT
    ),
    isReadDevelopmentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_DEVELOPMENT
    ),

    isWriteEducationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_EDUCATION
    ),
    isReadEducationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_EDUCATION
    ),

    isWriteEmploymentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_EMPLOYMENT
    ),
    isReadEmploymentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_EMPLOYMENT
    ),

    isWriteFamilyInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY
    ),
    isReadFamilyInformationEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY
    ),

    isWriteLegalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_LEGAL
    ),
    isReadLegalEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_LEGAL
    ),

    isWriteLeisureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_LEISURE_AND_RECREATIONAL
    ),
    isReadLeisureEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_LEISURE_AND_RECREATIONAL
    ),


    isWriteMedicalHealthEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MEDICAL_AND_PHYSICAL_HEALTH
    ),
    isReadMedicalHealthEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MEDICAL_AND_PHYSICAL_HEALTH
    ),

    isWriteMedicalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MEDICAL_HISTORY
    ),
    isReadMedicalHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MEDICAL_HISTORY
    ),

    isWriteMilitaryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_MILITARY
    ),
    isReadMilitaryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_MILITARY
    ),

    isWriteNutritionEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_NUTRITION
    ),
    isReadNutritionEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_NUTRITION
    ),

    isWritePrimaryReasonsForSeekingServicesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES
    ),
    isReadPrimaryReasonsForSeekingServicesEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES
    ),

    isWriteSocialRelationshipsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_SOCIAL_RELATIONSHIPS
    ),
    isReadSocialRelationshipsEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_SOCIAL_RELATIONSHIPS
    ),
    isWriteSpiritualEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_SPIRITUAL_RELIGION
    ),
    isReadSpiritualEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_SPIRITUAL_RELIGION
    ),
    isWriteTreatmentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY
    ),
    isReadTreatmentHistoryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientMentalInformation: (id) =>
      dispatch(ClientActions.getClientMentalInformation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientMentalView);
