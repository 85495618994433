import React from 'react';
import Layout from 'components/Layout.jsx'
import AddUserModal from 'components/Modals/AddUserModal'
import UserList from 'components/Users/UserListing'
import { connect } from 'react-redux'
import AccountActions from 'redux/AccountRedux'
import LoadingView from 'components/LoadingView.jsx'
import { Button, Row, Col } from 'react-bootstrap';
import { PAGE_SIZE } from 'config/CONSTANTS'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import Strings from 'i18n/en'
import RoleActions from 'redux/RoleRedux'

export class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddUserModal: false
    }
  }

  componentDidMount () {
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_USERS) && this.props.getAccounts(1)
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_ROLES) && this.props.getRoles(1)
  }

  renderLoading = () => {
    return <LoadingView />
  }

  toggleAddClientModal = () => {
    const { showAddUserModal } = this.state;
    this.setState({
      showAddUserModal: !showAddUserModal
    })
  }

  onCreate = (params) => {
    this.props.createAccount(params)
    this.toggleAddClientModal()
  }

  render () {
    const { fetchingAccounts, fetchingRoles, roles } = this.props

    return (
      <Layout>
        { (fetchingAccounts || fetchingRoles) && this.renderLoading() }
        { !(fetchingAccounts && fetchingRoles) && this.renderContent() }
        { this.state.showAddUserModal && <AddUserModal onClose={this.toggleAddClientModal} createAccount={this.onCreate} roles={roles} />}
      </Layout>
    )
  }

  renderContent () {
    const { accounts, totalCount, currentPage, isAddUserEnabled, isViewUsersEnabled, isEditUserEnabled, isDeleteUserEnabled, roles } = this.props
    return (
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <h4 className='text-nowrap'>Users</h4>
          { isAddUserEnabled && <Button onClick={this.toggleAddClientModal} className='ml-auto font-weight-bold text-nowrap' variant='link'> Add User</Button> }
        </Row>

        { isViewUsersEnabled ? (<Row>
            <UserList
              clients={accounts}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              currentPage={currentPage}
              getAccounts={this.props.getAccounts}
              isEditUserEnabled={isEditUserEnabled}
              isDeleteUserEnabled={isDeleteUserEnabled}
              roles={roles}
            />
          </Row>
        ) : <Row>{Strings.permissions.message}</Row> }
        
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    fetchingRoles: state.role.fetchingRoles,
    roles: state.role.roles,
    fetchingAccounts: state.account.fetchingAccounts,
    accounts: state.account.accounts,
    totalCount: state.account.totalCount,
    currentPage: state.account.currentPage,
    isAddUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_USER),
    isEditUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.EDIT_USER),
    isDeleteUserEnabled: isPermissionEnabled(state.user, PERMISSIONS.DELETE_USER),
    isViewUsersEnabled: isPermissionEnabled(state.user, PERMISSIONS.VIEW_USERS),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (pageNumber) => dispatch(RoleActions.getRoles(pageNumber)),
    getAccount: (id) => dispatch(AccountActions.getAccount(id)),
    getAccounts: (pageNumber) => dispatch(AccountActions.getAccounts(pageNumber)),
    updateAccount: (id) => dispatch(AccountActions.updateAccount(id)),
    createAccount: (params) => dispatch(AccountActions.createAccount(params)),
    deleteAccount: (id) => dispatch(AccountActions.deleteAccount(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);