import React from "react";
import { Formik, Form } from "formik";
import { TextMessage } from "components/Fields/TextMessage";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";

export default class FamilyForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    const familyList = [
      {
        circumstances: values.circumstances,
        immediateMembers: [values.immediateMembers],
        membersLivingWith: [values.membersLivingWith],
        membersNotLivingWith: [values.membersNotLivingWith],
        notSupportiveMembers: [values.notSupportiveMembers],
        supportiveMembers: [values.supportiveMembers],
      },
    ];
    this.props.onUpdate(

      this.props.clientID,
      { familyList: familyList, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.family
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{
          circumstances: data && data.familyList[0].circumstances,
          immediateMembers:
            data && data.familyList[0].immediateMembers.join(", "),
          membersLivingWith:
            data && data.familyList[0].membersLivingWith.join(", "),
          membersNotLivingWith:
            data && data.familyList[0].membersNotLivingWith.join(", "),
          notSupportiveMembers:
            data && data.familyList[0].notSupportiveMembers.join(", "),
          supportiveMembers:
            data && data.familyList[0].supportiveMembers.join(", "),
        }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label className="input-label" htmlFor="membersLivingWith">
                      Enter family members that may live with participant
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3 "
                      name="membersLivingWith"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label className="input-label" htmlFor="immediateMembers">
                      Identify any service needs of participant’s immediate
                      family members:
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3"
                      name="immediateMembers"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label className="input-label" htmlFor="supportiveMembers">
                      Identify any family members who have been supportive:
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3 "
                      name="supportiveMembers"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label
                      className="input-label"
                      htmlFor="notSupportiveMembers"
                    >
                      Identify any family members who may not have been
                      supportive:
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3"
                      name="notSupportiveMembers"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label
                      className="input-label"
                      htmlFor="membersNotLivingWith"
                    >
                      Enter family members that do not live with participant:
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3 "
                      name="membersNotLivingWith"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="input-label col-12 p-0">
                    <label className="input-label" htmlFor="circumstances">
                      Please describe circumstances:
                    </label>
                  </Col>
                  <Col className="col-12 p-0">
                    <TextMessage
                      className="mb-3"
                      name="circumstances"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      rows="4"
                    />
                  </Col>
                </Row>
                {/* <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Enter family members that may live with participant' name='membersLivingWith' type='textarea' placeholder='Family Members' size="md" rows='4'/>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Identify any service needs of participant’s immediate family members:' name='immediateMembers' type='textarea' placeholder='Family Members' size="md" rows='4'/>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Identify any family members who have been supportive:' name='supportiveMembers' type='textarea' placeholder='Family Members' size="md" rows='4'/>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Identify any family members who may not have been supportive:' name='notSupportiveMembers' type='textarea' placeholder='Family Members' size="md" rows='4'/>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Enter family members that do not live with participant:' name='membersNotLivingWith' type='textarea' placeholder='Family Members' size="md" rows='4'/>
                  <TextMessage labelClassName='input-label mb-2' className='mb-4 col-lg-12 col-md-6 col-sx-4' label='Please describe circumstances:' name='circumstances' type='textarea' placeholder='circumstances' size="md" rows='4'/> */}
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
