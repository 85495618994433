import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { Checkbox } from "components/Fields/Checkbox";
import { RadioButton } from "components/Fields/RadioButton";
import { BOOLEAN_CHECKBOX_OPTIONS } from "config/CONSTANTS";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class LegalHistoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    const convictions = values.convictions && values.convictions.filter((c) => c)
    
    this.props.onUpdate(this.props.clientID, {
      ...values,
      convictions,
      clientId: this.props.clientID
    }, CLIENT_REENTRY_FORM_TYPES.legalHistory)
    
  }

  render() {
    const doctorVerificationOptions = [
      ...BOOLEAN_CHECKBOX_OPTIONS,
      { key: "Don't Know", value: "Don'tKnow" },
    ];

    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row>
                    <Col className="col-12 p-0">
                      <RadioButton
                        className="mt-3 mb-3"
                        label="Are you under Civil or Criminal Court Ordered Mental Health or Substance Abuse Treatment Program?"
                        name="isUnderCivilCourtOrCriminalCourt"
                        options={BOOLEAN_CHECKBOX_OPTIONS}
                        labelClassName="input-label mb-2 text-wrap"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <div className="d-flex">
                        <Checkbox name="isUnderCivilCourt">Civil </Checkbox>
                        <Checkbox name="isUnderCriminalCourt">Criminal{" "}</Checkbox>
                      </div>
                    </Col>
                  </Row>
                  <Col className="col-sm-12 p-0">
                    <RadioButton
                      className="mt-3 mb-3"
                      label="Is there a court order in place exempting you from reporting the requirements of behavioral health services?"
                      name="isCourtOrderInPlaceExemptingToReportRequirementsOfBHS"
                      options={BOOLEAN_CHECKBOX_OPTIONS}
                      labelClassName="input-label mb-2 text-wrap"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className="col-sm-12 p-0">
                    <TextMessage
                      label="Please identify any barriers you or a family member has that is interfering with your ability to get or keep a job:"
                      name="interferingBarriersToGetAJob"
                      type="textarea"
                      placeholder="Description"
                      size="md"
                      labelClassName="input-label mb-2 text-wrap"
                    />
                  </Col>
                  <Col className="col-12 p-0">
                    <label
                      className="input-label mb-2"
                      htmlFor="isCovictedOfASexCrime"
                    >
                      Have you ever been convicted of a sex crime?
                    </label>
                  </Col>
                  <RadioButton
                    className="mb-3 "
                    name="isCovictedOfASexCrime"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <Col className="col-12 p-0">
                    <label
                      className="input-label mb-2"
                      htmlFor="isCovictedOfCrimes"
                    >
                      Have you ever been convicted of a crime(s)?
                    </label>
                  </Col>
                  <RadioButton
                    className="mb-3 "
                    name="isCovictedOfCrimes"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />

                  <Row>
                    <Col className="col-12">
                      <label className="input-label mb-2" htmlFor="conviction">
                        If Yes, Please list conviction(s) and date(s):
                      </label>
                    </Col>

                    <FieldArray
                      name="convictions"
                      render={(arrayHelpers) => (
                        <>
                          {values.convictions &&
                          values.convictions.length > 0 ? (
                            values.convictions.map((conviction, index) => (
                              <Col key={index} className="col-12 d-flex">
                                <TextInput
                                  className="mr-3"
                                  name={`convictions.${index}.conviction`}
                                  type="text"
                                  placeholder="Conviction"
                                />
                                <TextInput
                                  name={`convictions.${index}.convictionDate`}
                                  type="date"
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-danger ml-3 mr-3 mb-3"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  {" "}
                                  Remove{" "}
                                </button>
                                {index === 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary mb-3"
                                    onClick={() =>
                                      arrayHelpers.insert(index, "")
                                    }
                                  >
                                    {" "}
                                    Add{" "}
                                  </button>
                                )}
                              </Col>
                            ))
                          ) : (
                            <Col className="col-12 d-flex mb-3">
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {" "}
                                Add conviction
                              </button>
                            </Col>
                          )}
                        </>
                      )}
                    />
                  </Row>

                  <RadioButton
                    className="mb-3"
                    labelClassName="input-label mb-2 d-block"
                    label="I am currently on Probation/Parole:"
                    name="isCurrentlyOnProbation"
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <Row>
                    <Col className="col-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="probationOfficerName"
                      >
                        If yes, Name & Phone of PO Officer:
                      </label>
                    </Col>
                    <Col className="col-10 d-flex">
                      <TextInput
                        className="mr-3"
                        name="probationOfficerName"
                        type="text"
                        placeholder="Name"
                      />
                      <TextInput
                        className="mr-3"
                        name="probationPhoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="col-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="convictionName"
                      >
                        When is your Probation/Parole up?
                      </label>
                    </Col>
                    <Col className="col-12 col-lg-8">
                      <TextInput
                        className="mr-3"
                        name="probationUpDate"
                        type="date"
                        placeholder="Probation"
                      />
                    </Col>
                  </Row>

                  <hr />
                  <TextMessage
                    labelClassName="input-label mb-2"
                    label="Other Barriers to employment:"
                    name="otherBarriersToEmployment"
                    type="textarea"
                    placeholder="Description"
                    size="md"
                  />
                  <Row className="p-0 col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-2">
                    <TextInput
                      className="col-6 col-xs-12 col-sm-10"
                      label="Person with barrier?"
                      name="personWithBarrier"
                      labelClassName="input-label mb-2"
                      type="text"
                      placeholder="Barrier"
                    />
                    <TextInput
                      className="col-6 col-xs-12 col-sm-10"
                      label="When did it start?"
                      name="barrierStartDate"
                      labelClassName="input-label mb-2"
                      type="date"
                      placeholder="Start"
                    />
                  </Row>
                  <Col className="col-10 p-0">
                    <label
                      className="input-label mb-2"
                      htmlFor="hasBeenVerifiedByDoctor"
                    >
                      Has it been verified by a doctor, counselor, etc.?
                    </label>
                  </Col>
                  <RadioButton
                    name="hasBeenVerifiedByDoctor"
                    options={doctorVerificationOptions}
                    onChange={handleChange}
                  />
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
