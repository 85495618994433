import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Row, Col, Table } from 'react-bootstrap'
import BaseForm from 'components/Patient/BaseForm'
import { TextInput } from 'components/Fields/TextInput'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class FamilyInformation extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const employers = values && values.employers && values.employers.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {...values, employers, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.employment)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange}) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row>
                    <Col className='p-0'>
                    <Table className='w-100 pl-0 ml-0' borderless responsive>
                      <thead>
                        <th className='pl-0 align-top' style={{ width: '20%' }}>Employer</th>
                        <th className='align-top' style={{ width: '10%' }}>Dates Worked</th>
                        <th className='align-top' style={{ width: '20%' }}>Job Title</th>
                        <th className='align-top' style={{ width: '20%' }}>Reason You Left Job</th>
                        <th className='align-top' style={{ width: '20%' }}>How often did you miss work?</th>
                        <th className='align-top' style={{ width: '10%' }}></th>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="employers"
                          render={(arrayHelpers) => (
                            <>
                              {
                                values.employers && values.employers.length > 0 ? (
                                  values.employers.map((employer, index) => (

                                    <tr key={index}>
                                      <td className='pl-0 py-0'>
                                        <TextInput
                                          name={`employers.${index}.employerName`}
                                          placeholder='Employer Name'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`employers.${index}.numberOfDaysWorked`}
                                          placeholder='# of Dates worked'
                                          type='number'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`employers.${index}.jobTitle`}
                                          placeholder='Job Title'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`employers.${index}.reasonsToLeaveJob`}
                                          placeholder='Reasons'
                                        />
                                      </td>
                                      <td className='py-0'>
                                        <TextInput
                                          name={`employers.${index}.howOftenWorkIsMissed`}
                                          placeholder='#'
                                        />
                                      </td>
                                      
                                      <td className='d-flex p-0'>
                                        <button type="button" className='btn btn-outline-danger mr-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                        { index === 0 && <button type="button" className='btn btn-outline-primary' onClick={() => arrayHelpers.insert(index, '')}> Add </button> }
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <Col className='col-12 d-flex mb-3'>
                                    <button className='btn btn-outline-primary' onClick={() => arrayHelpers.push('')}> Add Employer</button>
                                  </Col>
                                )
                              }
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                    </Col>
                  </Row>
                  <Row className='mb-3 mt-2'>
                    <Col className='p-0'>
                      <RadioButton
                        name='isCurrentlyEmployed'
                        labelClassName='input-label mb-3 d-block'
                        options={yesOrNoOptions}
                        label='Currently employed:'
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-3 align-items-center w-100'>
                    <Col className='p-0 col-12'>
                      <label className='input-label mb-0'>If Yes, Yes:</label>
                    </Col>
                    <Col className='p-0 col-12 d-flex align-items-center'>
                      <Checkbox name='isFullTime'>Full Time</Checkbox>
                      <Checkbox name='isPartTime'>Part Time</Checkbox>
                      <TextInput className='mr-3 mb-0 d-inline' name='numberOfHoursPerWeek' type='number' placeholder='Hours Per Week'/>
                      <TextInput className='mb-0 d-inline' name='hourlyPay' type='number' placeholder='Rate Of Pay'/>
                    </Col>
                  </Row>
                  <Row className='align-items-center w-100'>
                    <Col className='p-0 col-12'>
                      <label className='input-label mb-0'>If Yes, No:</label>
                    </Col>
                  </Row>
                  <Row className='mb-3 row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 gx-4'>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='fired'>Fired</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='quit'>Quit</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='laidOff'>Laid off</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='disabled'>Disabled</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='retired'>Retired</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='socialSecurity'>Social Security</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='student'>Student</Checkbox>
                    </Col>
                    <Col className="p-0 d-flex align-items-center col-md-4">
                      <Checkbox name='other'>Other</Checkbox>
                      <TextInput className='mb-0 d-inline' name='otherReason' type='text' placeholder='Specify'/>
                    </Col>
                  </Row>
                  <Row className='row-cols-xs-1 row-cols-sm-7 mb-3'>
                    <Col className='p-0 col-12 mb-2'>
                      <label className='input-label'>I Prefer To Work The Following Days (Check All That Apply):</label>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnMon'>Mon</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnTue'>Tues</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnWed'>Wed</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnThu'>Thurs</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnFri'>Fri</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnSat'>Sat</Checkbox>
                    </Col>
                    <Col className='p-0 mb-2'>
                      <Checkbox name='preferToWorkOnSun'>Sun</Checkbox>
                    </Col>
                  </Row>
                  <Row className='row-cols-xs-1 row-cols-sm-7 mb-3'>
                    <Col className='p-0 col-12'>
                      <label className='input-label'>I Prefer To Work The Following Shifts (Check All That Apply):</label>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='preferToWorkOnDays'>Days</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='preferToWorkOnNights'>Nights</Checkbox>
                    </Col>
                    <Col className='p-0 mb-3 mt-2'>
                      <Checkbox name='preferToWorkOnGraveyard'>Graveyard</Checkbox>
                    </Col>
                    <Col className="p-0 d-flex align-items-center col-md-4">
                      <Checkbox name='preferToWorkOnOther'>Other</Checkbox>
                      <TextInput className='mb-0 d-inline' name='otherPreferToWorkOn' type='text' placeholder='Specify'/>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}