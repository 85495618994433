import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class EmploymentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_BFET_FORM_TYPES.employmentPlan
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row className="mb-3">
                    <Col className="col-12 p-0 d-flex row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
                      <Checkbox
                        labelClassName="input-label"
                        name="isEducationPlan"
                      >
                        {" "}
                        Education/Training Plan
                      </Checkbox>

                      <Checkbox labelClassName="input-label ml-5" name="isVE">
                        {" "}
                        VE
                      </Checkbox>
                      <Checkbox labelClassName="input-label" name="isBE">
                        {" "}
                        BE (Including ESL)
                      </Checkbox>
                    </Col>
                  </Row>

                  <Row className="row-cols-2 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                    <Col className="p-0">
                      <TextInput
                        className="mr-3 col-md-6 col-lg-12"
                        name="educationalInstitution"
                        type="text"
                        placeholder="Educational Institution"
                      />
                    </Col>
                    <Col className="p-0">
                      <TextInput
                        className="mr-3 col-md-6 col-lg-12"
                        name="academicGoal"
                        type="text"
                        placeholder="Academic Goal"
                      />
                    </Col>
                    <Col className="p-0">
                      <Row>
                        <Col className='col-1 ml-3'> Start: </Col>
                        <Col><TextInput
                          className="mr-3 col-md-6 col-lg-12"
                          name="datesOfTraining"
                          type="date"
                          placeholder="Dates Of Training"
                        /></Col>
                      </Row>
                    </Col>

                    <Col className="p-0">
                      <Row>
                        <Col className='col-1 ml-3'> End: </Col>
                        <Col><TextInput
                          className="mr-3 col-md-6 col-lg-12"
                          name="start"
                          type="date"
                          placeholder="Start"
                        /></Col>
                      </Row>
                    </Col>

                    <Col className="p-0">
                      <TextInput
                        className="mr-3 col-md-6 col-lg-12"
                        name="certification"
                        type="text"
                        placeholder="Degree/Certification"
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row className='flex-column'>
                    <Row className="p-0 mb-2 d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                      <Checkbox
                        labelClassName="input-label"
                        className="col-md-12 col-lg-12 "
                        name="isJobSearchTraining"
                      >
                        {" "}
                        Job Search Training (JT)
                      </Checkbox>
                    </Row>
                    <Row>
                      <Col className='col-1'>Start:</Col>
                      <Col className='col-4'>
                        <TextInput
                          name="jobSearchTrainingStartDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="Start"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className='col-1'>End: </Col>
                      <Col className='col-4'>
                        <TextInput
                          name="jobSearchTrainingEndDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="End"
                      />
                      </Col>
                    </Row>
                  </Row>


                  <Row>
                    <Col className="p-0 col-md-12 col-lg-12">
                      <p>
                        Job Search Training may include, but not limited to:
                      </p>
                      <ul>
                        <li>Resume writing</li>
                        <li>Interview skills</li>
                        <li>Master application</li>
                        <li>Workplace etiquette</li>
                      </ul>
                    </Col>
                  </Row>
                  <hr />


                  <Row className='flex-column'>
                    <Row className="p-0 mb-2 d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                      <Checkbox
                        labelClassName="input-label"
                        className="col-md-6 col-lg-12 "
                        name="isSupervisedJobSearch"
                      >
                        {" "}
                        Supervised Job Search (JS)
                      </Checkbox>
                    </Row>

                    <Row>
                      <Col className='col-1'>Start: </Col>
                      <Col className='col-4'><TextInput
                        name="supervisedJobSearchStartDate"
                        className="col-md-6 col-lg-12 mt-2"
                        type="date"
                        placeholder="Start"
                      />
                      </Col>
                    </Row>

                    <Row>
                      <Col className='col-1'>End: </Col>
                      <Col className='col-4'><TextInput
                        name="supervisedJobSearchEndDate"
                        className="col-md-6 col-lg-12 mt-2"
                        type="date"
                        placeholder="Start"
                      />
                      </Col>
                    </Row>

                    <Col className="p-0">
                      <p>
                        Supervised Job Search activity may include, but not
                        limited to:
                      </p>
                      <ul>
                        <li>Use of computer, email, fax, telephone</li>
                        <li>Search of job listings</li>
                        <li>Participation in a job club</li>
                        <li>
                          Securing identification, professional license or
                          certifications
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <hr />

                  <Row className='flex-column'>
                    <Row className="p-0 mb-2 d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                      <Checkbox
                        labelClassName="input-label"
                        className="col-md-6 col-lg-12 "
                        name="isJobRetentionService"
                      >
                        {" "}
                        Job Retention Service Extension (BR)
                      </Checkbox>
                    </Row>

                    <Row>
                      <Col className='col-1'>Start:</Col>
                      <Col className='col-4'>
                        <TextInput
                          name="jobRetentionServiceStartDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="Start"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className='col-1'>End: </Col>
                      <Col className='col-4'>
                        <TextInput
                          name="jobRetentionServiceEndDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="End"
                      />
                      </Col>
                    </Row>


                    <Col className="p-0">
                      <p>
                        Job Retention Service Extension may include, but not
                        limited to:
                      </p>
                      <ul>
                        <li>Counseling</li>
                        <li>Coaching</li>
                        <li>Case management</li>
                        <li>Financial Empowerment Training</li>
                        <li>Participant reimbursement</li>
                      </ul>
                    </Col>
                  </Row>


                  <hr />

                  <Row className='flex-column'>
                    <Row className="p-0 mb-2 d-flex col-12">
                      <Checkbox labelClassName="input-label" name="isLifeSkill">
                        {" "}
                        Life Skills (SL)
                      </Checkbox>
                    </Row>

                    <Row>
                      <Col className='col-1'>Start:</Col>
                      <Col className='col-4'>
                        <TextInput
                          name="lifeSkillStartDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="Start"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className='col-1'>End: </Col>
                      <Col className='col-4'>
                        <TextInput
                          name="lifeSkillEndDate"
                          type="date"
                          className="col-md-12 mt-2 col-lg-12"
                          placeholder="End"
                      />
                      </Col>
                    </Row>

                    <Col className="p-0">
                      <p>Life Skills may include, but not limited to:</p>
                      <ul>
                        <li>Work Preparation</li>
                        <li>Health and well-being</li>
                        <li>Effective communication</li>
                        <li>Personal strength builders</li>
                        <li>Community enagement</li>
                      </ul>
                    </Col>
                  </Row>





                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
