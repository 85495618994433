import styled from "styled-components";

export const ForgotPasswordContainer = styled.div`
  background-color: #e9e9f0;
  height: 100%;
  overflow-x: hidden;
`;

export const ForgotPasswordInnerContainer = styled.div`
  background-color: white;
  padding: 6%;
  width: 40%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const ForgotPasswordText = styled.span`
  font-size: 34px;
  color: ${(props) => props.theme.textColor};
  font-family: PoppinsBold;
`;

export const ForgotPasswordDetailText = styled.span`
  font-size: 18px;
  color: ${(props) => props.theme.textColor};
  margin-top: 14px;
`;
