
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'

export class SaveTemplateModal extends React.Component {
  handleSubmit = (params) => {
    let p = { templateName: params.templateName, request: this.props.query.request, outputColumns: this.props.query.outputColumns }
    this.props.createReportTemplate(p)
  }

  render () {
    const { createReportTemplateFailureMessage } = this.props
    return (
      <BaseFormModal heading={'Save Template'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Formik
          initialValues={{
            templateName : '',
            request: {}
          }}

          validationSchema={
            Yup.object({
              templateName: Yup.string().trim().required('Template Name cannot be empty')
            })
          }
          onSubmit={this.handleSubmit}
        >
            <Form>
              <Row> 
                <Col col-2>
                  <TextInput name="templateName" placeholder="Template Name" />
                </Col>
              </Row>
               {createReportTemplateFailureMessage && (
                <Row>
                   <Col className='col-12 text-danger'>
                    { createReportTemplateFailureMessage }
                   </Col>
                </Row>
               )}
              <Button size="md" className='float-right' variant="primary" type='submit'>{'Save'}</Button>
            </Form>
        </Formik>
      </BaseFormModal>
    )
  }
}

export default SaveTemplateModal;