import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {Formik, Form} from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import {TextMessage} from 'components/Fields/TextMessage'
import {TextInput} from 'components/Fields/TextInput';
import {RadioButton} from 'components/Fields/RadioButton';
import { CLIENT_REENTRY_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const housingSubsidyOptions = [
  { key: "Yes", value: "yes" },
  { key: "No", value: "no" },
  { key: "Don’t Know", value: "Don’t Know" },
  { key: "Refused", value: "Refused" },
];

const yesOrNoOptions = [
  { key: "Yes", value: true },
  { key: "No", value: false },
];

export default class PersonalHealthInformation extends React.Component {
  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.personalHealthInformation
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row className=" col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                    <Col className="col-6 col-xs-12 pl-0 pr-4">
                      <TextInput
                        className="d-flex flex-wrap w-50"
                        label="Emergency Contact:"
                        labelClassName="input-label mb-2"
                        name="emergencyContact"
                        type="text"
                        placeholder="Name"
                      />
                    </Col>
                    <Col className="col-6 col-xs-12 pl-0">
                      <TextInput
                        className="d-flex flex-wrap w-50"
                        label="Phone:"
                        labelClassName="input-label mb-2"
                        name="emergencyContactPhoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                  </Row>
                  <Row className=" col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                    <Col className="col-6 col-xs-12 pl-0 pr-4">
                      <TextInput
                        className=" d-flex flex-wrap w-50"
                        label="Physician:"
                        labelClassName="input-label mb-2"
                        name="physician"
                        type="text"
                        placeholder="Name"
                      />
                    </Col>
                    <Col className="col-6 col-xs-12 pl-0">
                      <TextInput
                        className="d-flex flex-wrap w-50"
                        label="Phone:"
                        labelClassName="input-label mb-2"
                        name="physicianPhoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="col-10 p-0">
                      <Col className=" col-sm-10 col-lg-10">
                        <label
                          className="input-label mb-2"
                          htmlFor="hasDisability"
                        >
                          Does participant have a disability of a long duration?
                        </label>
                      </Col>
                      <RadioButton
                        className="mb-3 ml-2"
                        name="hasDisability"
                        options={housingSubsidyOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <Col className=" col-sm-10 col-lg-10">
                        <label
                          className="input-label mb-2"
                          htmlFor="isOrWasDiagnosed"
                        >
                          Has participant ever been diagnosed with?
                        </label>
                      </Col>
                      <RadioButton
                        className="mb-3 ml-2"
                        name="isOrWasDiagnosed"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col >
                      <RadioButton
                        className="mb-3"
                        label="Mental Illness"
                        labelClassName="d-block input-label mb-2"
                        name="hasMentalIllness"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <Col className=" col-sm-10 col-lg-10">
                        <label
                          className="input-label mb-2"
                          htmlFor="hasSubstanceAbuseProblem"
                        >
                          Participant has a Substance Abuse problem? If yes, are
                          you receiving treatment:
                        </label>
                      </Col>
                      <RadioButton
                        className="mb-3 ml-3"
                        name="hasSubstanceAbuseProblem"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-2">
                    <Col className="col-md-8 col-lg-4 col-xs-12 pl-0 pr-4">
                      <TextInput
                        labelClassName="mb-2"
                        label="If Yes, Where:"
                        type="text"
                        name="hasSubstanceAbuseProblemTreatmentLocation"
                      />
                    </Col>
                    <Col className="col-md-8 col-lg-4 col-xs-12 pl-0">
                      <TextInput
                        labelClassName="mb-2"
                        label="When:"
                        type="date"
                        name="hasSubstanceAbuseProblemTreatmentDate"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <RadioButton
                        className="mb-3"
                        label="HIV/AIDS and related diseases"
                        labelClassName="d-block input-label mb-2"
                        name="hivDiseasesApplicable"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <RadioButton
                        className="mb-3"
                        label="Developmental disability"
                        labelClassName="d-block input-label  mb-2"
                        name="developmentDisabilityApplicable"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <RadioButton
                        className="mb-3"
                        label="Physical disability"
                        labelClassName="d-block input-label  mb-2"
                        name="physicalDisabilityApplicable"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <TextMessage
                        label="How do your disability barriers affect you?"
                        className="col-sm-10 col-lg-10"
                        name="disabilityBarriersAffect"
                        labelClassName="input-label mb-2"
                        rows="4"
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0 col-12">
                      <Col className=" col-sm-10 col-lg-10">
                        <label
                          className="input-label mb-2"
                          htmlFor="hasPsychiatricConditions"
                        >
                          Does participant have a history of any psychiatric
                          conditions?
                        </label>
                      </Col>
                      <RadioButton
                        className="mb-3 ml-3"
                        name="hasPsychiatricConditions"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className=" col-12">
                      <label className="input-label">
                        Check All That Apply:
                      </label>
                    </Col>
                    <Col className="ml-2 col-lg-12 col-sm-3 mb-3">
                      <table className="table-bordered text-center">
                        <thead>
                          <tr>
                            
                            <th className='py-2 col'>Psychiatric Condition</th>
                            <th className='py-2 col'>Currently</th>
                            <th className='py-2 col'>History of</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                     
                            <td className='py-2'>Suicidal Ideas</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfSuicidalIdeas"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlySuicidalIdeas"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
             
                            <td className='py-2'>Suicide Attempts</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfSuicideAttempts"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlySuicideAttempts"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            
                            <td className='py-2'>Hallucinations</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfHallucinations"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyHallucinations"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                         
                            <td className='py-2'>Arson/Fire Setting</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfFireSetting"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyFireSetting"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
              
                            <td className='py-2'>Victim of Sexual Abuse/Assault</td>
                            <td className='py-2'> 
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfVictimofSexualAbuseAssault"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyVictimofSexualAbuseAssault"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
   
                            <td className='py-2'>Victim of Trauma</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfVictimofTrauma"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyVictimofTrauma"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className='py-2'>Homicidal Ideas Attempts</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfHomicidalIdeasAttempts"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyHomicidalIdeasAttempts"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
      
                          </tr>
                          <tr>
                            <td className='py-2'>Assaultive Behavior</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfAssaultiveBehavior"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyAssaultiveBehavior"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                  
                          </tr>
                          <tr>
                            <td className='py-2'>Delusions</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfDelusions"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyDelusions"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                
                          </tr>
                          <tr>
                            <td className='py-2'>Severe Depression</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfSevereDepression"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlySevereDepression"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                          
                          </tr>
                          <tr>
                            <td className='py-2'>Severe Thought Disorder</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfSevereThoughtDisorder"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlySevereThoughtDisorder"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            
                          </tr>
                          <tr>
                            <td className='py-2'>Cognitive Impairment</td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="historyOfCognitiveImpairment"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            <td className='py-2'>
                              <RadioButton
                                className="d-lg-flex col-sm-4"
                                name="currentlyCognitiveImpairment"
                                options={yesOrNoOptions}
                                onChange={handleChange}
                              />
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col className="col-12 p-0">
                      <TextMessage
                        label="Other (Specify):"
                        className=" col-sm-10 col-lg-10"
                        name="psychiatricConditionsOther"
                        labelClassName="input-label mb-2"
                        rows="4"
                        placeholder="Describe"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0 my-3">
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <Col className="col-6 col-sm-10 col-lg-12">
                        <label
                          className="input-label mb-2"
                          htmlFor="hospitalizations"
                        >
                          If applicable, please list hospitalizations for these
                          conditions:
                        </label>
                      </Col>
                      <TextMessage
                        className="col-sm-10 col-lg-10"
                        name="hospitalizations"
                        rows="4"
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <RadioButton
                        className="mb-3"
                        label="Does participant receive psychiatric care?"
                        labelClassName="d-block input-label mb-2"
                        name="hasReceivedPsychiatricCare"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <Col className="col-6 col-sm-10 col-lg-10">
                        <label
                          className="input-label mb-2"
                          htmlFor="psychiatricCareProviders"
                        >
                          If yes, please list Name, Address, and Phone Number of
                          all psychiatric care providers:
                        </label>
                      </Col>
                      <TextMessage
                        name="psychiatricCareProviders"
                        placeholder="Description"
                        className="col-sm-10 col-lg-10"
                        rows="4"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
