import React from 'react';
import { connect } from 'react-redux'
import { ReactComponent as EditIcon } from '../images/svg/edit-icon.svg';
import { ReactComponent as SaveIcon } from '../images/svg/save.svg';
import { ReactComponent as InfoIcon } from '../images/svg/info.svg';

import { ReactComponent as DeleteIcon } from '../images/svg/ic_delete.svg';
import { Button } from 'react-bootstrap';
import { PTr, PTd, IconButton } from './Styles/PatientListStyles';
import moment from "moment";
import { generateConcatenatedString } from "utils/genericUtils";
import ReportActions from 'redux/ReportRedux'

export class Query extends React.Component {
  constructor(props) {
    super(props)
    this.timer = null;
    this.state = {
      job: null
    }
  }

  componentDidMount () {
    this.props.getJob(this.props.query.jobId)
    setTimeout(() => this.startPolling(this.props.query.jobId), 3000)
  }

  componentWillReceiveProps (newProps) {
    if (this.props.query && this.props.query.jobId)  {
      this.setState({
        job: newProps.jobs[this.props.query.jobId]
      })
    }
  }

  startPolling (jobId) {
    const { job } = this.state
    if (job && (job.jobStatus !== 'SUCCESS' || job.jobStatus === 'FAILED')) {
      this.timer = setInterval(()=> this.props.getJob(jobId), 3000);
    }
  }

  onDelete = () => {
    this.props.onDelete(this.props.query.queryId)
  }

  render () {
    const { query, accounts } = this.props
    const { job } = this.state

    if (job && (job.jobStatus === 'SUCCESS' || job.jobStatus === 'FAILED') ) {
      clearInterval(this.timer);
    }
    
    const res = query && query.request && generateConcatenatedString(query.request.includedFilters, accounts)

    return (
      <PTr>
        <PTd>{moment(query.createdAt).format(`LT`)} <br></br>{ moment(query.createdAt).format(`Do MMMM YYYY`) }</PTd>
        <PTd>{query.createdByName}</PTd>
        <PTd>
          { res }
        </PTd>
        <PTd>
          { job && job.jobStatus === 'FAILED' && <Button variant="danger" disabled>{'Failed'}</Button> }
          { job && job.jobStatus === 'SUCCESS' && <Button variant="success"><a href={job.downloadURL} download>Download</a></Button> }
          { job && job.jobStatus === 'RUNNING' && <Button variant="info" disabled>{'Preparing...'}</Button> }          
        </PTd>
        <PTd>
          <div className='d-flex'>
            <IconButton className='mr-2'>
              <InfoIcon onClick={() => { this.props.onShowInfo(job.rows || []) }} />
            </IconButton>
            <IconButton className='mr-2'>
              <SaveIcon onClick={() => this.props.onSave(query)} />
            </IconButton>

            <IconButton className='mr-2'>
              <EditIcon onClick={() => this.props.onEdit(query)} />
            </IconButton>
            
            <IconButton>
              <DeleteIcon onClick={this.onDelete} />
            </IconButton>
          </div>
        </PTd>
      </PTr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingJob: state.report.fetchingJob,
    jobs: state.report.jobs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getJob: (jobId) => dispatch(ReportActions.getJob(jobId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Query);