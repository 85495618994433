import React from 'react';
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../images/svg/ic_delete.svg';
import { ReactComponent as InfoIcon } from '../images/svg/info.svg';
import { PTr, PTd, IconButton } from './Styles/PatientListStyles';
import moment from "moment";
import ReportActions from 'redux/ReportRedux'

export class Query extends React.Component {
  constructor(props) {
    super(props)
    this.timer = null;
    this.state = {
      job: null
    }
  }

  componentDidMount () {
    this.props.getJob(this.props.template.jobId)
    setTimeout(() => this.startPolling(this.props.template.jobId), 3000)
  }

  componentWillReceiveProps (newProps) {
    if (this.props.template && this.props.template.jobId)  {
      this.setState({
        job: newProps.jobs[this.props.template.jobId]
      })
    }
  }


  startPolling (jobId) {
    const { job } = this.state
    if (job && (job.jobStatus !== 'SUCCESS' || job.jobStatus === 'FAILED')) {
      this.timer = setInterval(()=> this.props.getJob(jobId), 3000);
    }
  }

  render () {
    const { template } = this.props
    const { job } = this.state

    if (job && (job.jobStatus === 'SUCCESS' || job.jobStatus === 'FAILED') ) {
      clearInterval(this.timer);
    }

    return (
      <PTr>
        <PTd>{moment(template.createdAt).format(`LT`)} <br></br>{ moment(template.createdAt).format(`Do MMMM YYYY`) }</PTd>
        <PTd>{template.createdByName}</PTd>
        <PTd>
          { job && job.jobStatus === 'FAILED' && <Button variant="danger" disabled>{'Failed'}</Button> }
          { job && job.jobStatus === 'SUCCESS' && <Button variant="success"><a href={job.downloadURL} download>Download</a></Button> }
          { job && job.jobStatus === 'RUNNING' && <Button variant="info" disabled>{'Preparing...'}</Button> }          
        </PTd>
        <PTd>
          <div className='d-flex'>
            {
              (job && job.rows && job.rows.length > 0) && (
                <IconButton>
                  <InfoIcon onClick={() => this.props.onShowInfo(job.rows)} />
                </IconButton>
              )
            }
            <IconButton>
              <DeleteIcon onClick={() => this.props.onDelete(template.templateId, template.queryId)} />
            </IconButton>
          </div>
        </PTd>
      </PTr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingJob: state.report.fetchingJob,
    jobs: state.report.jobs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getJob: (jobId) => dispatch(ReportActions.getJob(jobId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Query);