import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import BaseForm from "components/Patient/BaseForm";
import {
  FormHeader,
  LightGrayLabel,
} from "styles/GenericStyles";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import LoadingView from "components/LoadingView.jsx";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";

export class ServiceDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getClientDVRServiceDeliveryInformation(this.props.clientID);
  }

  handleSubmit = (values) => {
    this.props.updateInfo(this.props.clientID, {
      ...values,
      clientId: this.props.clientID,
    });
  };

  renderContent() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}> 
                <Row>
                  <FormHeader className="mt-n2 mb-3">
                    SERVICE DELIVERY OUTCOME REPORT
                  </FormHeader>
                  <div className="w-100">
                    <Row className="mb-3 ml-1">
                      <TextInput
                        className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6"
                        labelClassName="mb-2"
                        label="DVR Customer"
                        name="dvrCustomer"
                        type="text"
                        placeholder="DVR Customer"
                      />
                      <TextInput
                        className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6"
                        labelClassName="mb-2"
                        label="Social Security Number (Last Four Digits)"
                        name="ssnNumber"
                        type="text"
                        placeholder="XXX-XX-XXX"
                      />
                      <TextInput
                        className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6"
                        labelClassName="mb-2"
                        label="CRP Name"
                        name="crpName"
                        type="text"
                        placeholder="CRP Name"
                      />
                      <TextInput
                        className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6"
                        labelClassName="mb-2"
                        label="CRP Representative"
                        name="crpRepresentative"
                        type="text"
                        placeholder="CRP Representative"
                      />
                      <TextInput
                        className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6"
                        labelClassName="mb-2"
                        label="DVR Counselor"
                        name="dvrCounselor"
                        type="text"
                        placeholder="DVR Counselor"
                      />
                      <Col>
                        <label>Total Cost</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend mb-3">
                            <span class="input-group-text">$</span>
                          </div>
                          <TextInput
                            class="form-control"
                            name="totalCost"
                            type="number"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3">
                      <Col className="col-md-12 col-lg-12 col-12">
                        <label className="input-label mb-3">
                          CRP Service Category
                        </label>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isVocationalEvaluationServices"
                        >
                          {" "}
                          Vocational Evaluation Services
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isIntensiveTrainingServices"
                        >
                          {" "}
                          Intensive Training Services{" "}
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isTrailWorkExperience"
                        >
                          {" "}
                          Trial Work Experience
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isObRetentionServices"
                        >
                          {" "}
                          Job Retention Services
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isCommunityBasedAssessment"
                        >
                          {" "}
                          Community Based Assessment{" "}
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isOffSitePyschoSE"
                        >
                          {" "}
                          Off-Site Psycho-Social – NON-SE
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isObPlacementServices"
                        >
                          {" "}
                          Job Placement Services
                        </Checkbox>
                      </Col>
                      <Col className="mb-3">
                        <Checkbox
                          labelClassName="text-wrap"
                          name="isOffSitePyschoSocialNonSE"
                        >
                          {" "}
                          Off-Site Psycho - SE
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="p-0">
                        <hr />
                      </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2 pt-3">
                      <Col>
                        <label className="input-label mb-3">
                          Time Lines (Overall Plan)
                        </label>
                        <Row>
                          <Col>
                            <label>From:</label>
                          </Col>
                          <Col>
                            <TextInput
                              className="p-0 pr-3 col-12"
                              name="timelineFromDate"
                              type="date"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>To:</label>
                          </Col>
                          <Col>
                            <TextInput
                              className="p-0 pr-3 col-12"
                              name="timelineToDate"
                              type="date"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <label className="input-label mb-3">
                          Dates Of This Reporting Period
                        </label>
                        <Row>
                          <Col>
                            <label>From:</label>
                          </Col>
                          <Col>
                            <TextInput
                              className="p-0 pr-3 col-12"
                              name="reportingPeriodFromDate"
                              type="date"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>To:</label>
                          </Col>
                          <Col>
                            <TextInput
                              className="p-0 pr-3 col-12"
                              name="reportingPeriodToDate"
                              type="date"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="p-0">
                        <hr />
                      </Col>
                    </Row>

                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2 pt-3">
                      <Col className="col-md-12 col-lg-12 col-12">
                        <label className="input-label mb-3">
                          Pre-Employment Transition Services Crp Service
                          Category
                        </label>
                      </Col>
                      <Col>
                        {" "}
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSInformationalInterview"
                          >
                            {" "}
                            Pre-ETS: Informational Interview
                          </Checkbox>
                        </Col>{" "}
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSJobExploration"
                          >
                            {" "}
                            Pre-ETS: Job Exploration
                          </Checkbox>
                        </Col>{" "}
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSJobShadow"
                          >
                            Pre-ETS: Job Shadow
                          </Checkbox>
                        </Col>{" "}
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSSocialSkills"
                          >
                            Pre-ETS: Social Skills
                          </Checkbox>
                        </Col>
                      </Col>
                      <Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkBasedLearningA"
                          >
                            Pre-ETS: Work-Based Learning A
                          </Checkbox>
                        </Col>

                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkBasedLearningB"
                          >
                            {" "}
                            Pre-ETS: Work-Based Learning B
                          </Checkbox>
                        </Col>

                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkBasedLearningC"
                          >
                            Pre-ETS: Work-Based Learning C
                          </Checkbox>
                        </Col>

                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkplaceReadinessTrainingA"
                          >
                            Pre-ETS: Workplace Readiness Training A
                          </Checkbox>
                        </Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkplaceReadinessTrainingB"
                          >
                            Pre-ETS: Workplace Readiness Training B
                          </Checkbox>
                        </Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isPreETSWorkplaceReadinessTrainingC"
                          >
                            Pre-ETS: Workplace Readiness Training C
                          </Checkbox>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="p-0">
                        <hr />
                      </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2">
                      <Col>
                        <label className="input-label mb-3">
                          Type Of Report
                        </label>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isIntakeReport"
                          >
                            Intake Report
                          </Checkbox>
                        </Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isOutcomeReport"
                          >
                            Outcome Report
                          </Checkbox>
                        </Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isJobPlacementActivityReport"
                          >
                            Job Placement Activity Report
                          </Checkbox>
                        </Col>
                        <Col className="mb-3">
                          <Checkbox
                            labelClassName="text-wrap"
                            name="isMonthlyUpdate"
                          >
                            Monthly Update
                          </Checkbox>
                        </Col>
                      </Col>
                      <Col>
                        <label className="input-label mb-3">
                          Level Of Service For:
                        </label>
                        <Col className=" row row-cols-1 row-cols-md-2 row-cols-lg-2 pl-4 pt-2">
                          <Col className="mb-3">
                            <Checkbox
                              labelClassName="text-wrap"
                              name="isLevel1Service"
                            >
                              Level 1
                            </Checkbox>
                          </Col>
                          <Col className="mb-3">
                            <Checkbox
                              labelClassName="text-wrap"
                              name="isLevel2Service"
                            >
                              Level 2
                            </Checkbox>
                          </Col>
                          <Col className="mb-3">
                            <Checkbox
                              labelClassName="text-wrap"
                              name="isLevel3Service"
                            >
                              Level 3
                            </Checkbox>
                          </Col>
                          <Col className="mb-3">
                            <Checkbox
                              labelClassName="text-wrap"
                              name="isLevel4Service"
                            >
                              Level 4
                            </Checkbox>
                          </Col>
                          <Col className="mb-3">
                            <Checkbox labelClassName="text-wrap" name="isLevelOfServiceNotApplicable">
                              N/A
                            </Checkbox>
                          </Col>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="justify-content-end mt-3 mr-0 ml-0 w-100">
                      <TextInput
                        className="p-0 pr-3 col-6 "
                        labelClassName="mb-2"
                        label="Date"
                        name="reportDate"
                        type="date"
                      />
                    </Row>
                  </div>
                </Row>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  render() {
    const { getDVRServiceDeliveryFetching } = this.props;
    return (
      <>
        {!getDVRServiceDeliveryFetching && this.renderLoading()}
        {getDVRServiceDeliveryFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getDVRServiceDeliveryFetching: state.client.getDVRServiceDeliveryFetching,
    data: state.client.dvrSeliveryDelivery,
    write: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_SERVICE_DELIVERY
    ),
    read: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_SERVICE_DELIVERY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDVRServiceDeliveryInformation: (id) =>
      dispatch(ClientActions.getClientDVRServiceDeliveryInformation(id)),
    updateInfo: (id, params) =>
      dispatch(
        ClientActions.updateClientDVRServiceDeliveryInformation(id, params)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDelivery);
