
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FORGOT_PASSWORD } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthActions from 'redux/AuthRedux'
import { RightWrapper, LeftWrapper, WelcomeText, WelcomeTextMessage, LoginForm, SignInText, HorizontalLine } from 'pages/Styles/LoginStyles'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { theme } from 'themes'
import { TextInput } from 'components/Fields/TextInput'
import { REQUIRED_PASSWORD_LENGTH } from 'config/CONSTANTS'
import AlertActions from 'redux/AlertRedux'
import AlertMessage from 'components/AlertMessage'

export class Login extends React.Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const { email, password } = values
    this.props.login(email, password)
  }

  render () {
    const { fetching, messages, type, onClear } = this.props
    return (
      <Container fluid className='p-0 h-100'>
        <Row className='h-100'>
          <Col lg className='p-0'>
            <LeftWrapper>
              <WelcomeText>Welcome to AXION GALAXY 360</WelcomeText>
              <HorizontalLine />
              <WelcomeTextMessage>Holistic Management Network</WelcomeTextMessage>
            </LeftWrapper>
          </Col>
          <Col lg className='p-0'>
            <RightWrapper>
              <SignInText>Sign In</SignInText>
              <HorizontalLine color={theme.primaryBgColor} />
              <AlertMessage
                type={type}
                messages={messages}
                onClear={onClear}
              />
                <Formik
                  initialValues={{ email: '', passwoord: '' }}
                  validationSchema={Yup.object({
                    email: Yup.string().required('Please enter an email'),
                    password: Yup.string()
                      .required('No password provided.') 
                      .min(REQUIRED_PASSWORD_LENGTH, 'Password is too short - should be 8 chars minimum.')
                      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
                  })}
                  onSubmit={this.handleSubmit}
                >
                  <LoginForm>
                    <Form>
                      <TextInput label='Email' name='email' type='text' placeholder='Email' size='lg' labelwithin={true} bgcolor={theme.loginSecondaryBgColor} className='mb-5' />
                      <TextInput label='Password' name='password' type='password' placeholder='Password' size='lg' labelwithin={true} bgcolor={theme.loginSecondaryBgColor} />
                      <Row>
                        {/* <Col><Checkbox name='acceptedTerms'>   Remember me</Checkbox></Col> */}
                        <Col className='d-flex justify-content-end align-items-end'><Link to={FORGOT_PASSWORD}>Forgot your password?</Link></Col>
                      </Row>
                      <Button size="lg" className='mt-5' block variant="primary" disabled={fetching} type='submit'>{fetching ? 'Loading...' : 'Login'}</Button>
                    </Form>
                    {/* <PTag className='mt-2'>Don't have an Account? <Link to={SIGN_UP}>Sign Up!</Link></PTag> */}
                  </LoginForm>
                </Formik>
            </RightWrapper>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.auth.fetching,
    messages: state.alert.messages,
    type: state.alert.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(AuthActions.loginRequest(email, password)),
    onClear: () => dispatch(AlertActions.clear())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);