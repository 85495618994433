import { switchMap, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs'
import AlertActions, { AlertTypes } from "redux/AlertRedux";

export const AlertSuccesEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AlertTypes.SUCCESS),
    delay(6000),
    switchMap((action) => {
      return of(AlertActions.clear())
    })
  )