import styled from 'styled-components';

export const SideBarContainer = styled.div`
  background-color: ${props => props.theme.primaryBgColor};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 220px; 
`
export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const NavItem = styled.div`
  padding: .625em 0;
  position: relative;
  white-space: nowrap;
  :hover {
    color: white;
    text-decoration: none;
    :after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      opacity: .21;
    }
  }

  .nav-icon {
    width: .9em;
  }

  .nav-title {
    margin-left: .75em;
  }

  @media (max-width: 768px) {
    .nav-icon {
      display: none;
    }
    .nav-title {
      margin-left: .25em;
    }
  }
`
export const Branding = styled.div`
  padding: .625em 0;
  span {
    text-align: left;
    font: normal normal bold 12px/14px Metropolis;
    letter-spacing: 0.36px;
    color: #FFFFFF;
    opacity: 1;
  }
  .brand {
    text-align: left;
    font: normal normal bold 19px/23px Metropolis;
    letter-spacing: 0.57px;
    color: #111111;
    text-transform: uppercase;
    opacity: 1;
  }
`