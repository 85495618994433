import React from "react";
import { Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { RadioButton } from "components/Fields/RadioButton";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class IncarcerationForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.incarceration
    );
  };

  render() {
    const incarcerationLength = [
      {
        key: "Less than one month",
        value: "less_than_1_month",
      },
      {
        key: "At least one month but less than 6 months",
        value: "1_to_6_months",
      },
      {
        key: "At least 6 months but less than 1 year",
        value: "6_months_to_1_year",
      },
      {
        key: "At least one year but less than two years",
        value: "1_year_to_2_years",
      },
      {
        key: "At least two years but less than three years",
        value: "2_years_to_3_years",
      },
      {
        key: "Three years or more",
        value: "3_years_or_more",
      },
    ];

    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row className="p-0 col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
                    <TextInput
                      className="col-6 col-xs-12 col-md-8 col-sm-4"
                      labelClassName="input-label mb-2"
                      label="DOC Number"
                      name="docNumber"
                      type="text"
                      placeholder="Number"
                    />
                    <TextInput
                      className="col-6 col-xs-12 col-md-8 col-sm-4"
                      labelClassName="input-label mb-2"
                      label="Institution"
                      name="institution"
                      type="text"
                      placeholder="Institution"
                    />
                    <TextInput
                      className="col-6 col-xs-12 col-md-8 col-sm-4"
                      labelClassName="input-label mb-2"
                      label="Unit"
                      name="unit"
                      type="text"
                      placeholder="Unit"
                    />
                  </Row>

                  <Row className="mb-3">
                    {/* <TextInput className='col-6 col-xs-12' labelClassName='input-label mb-2' label='Length of Incarceration' name='lengthOfIncarceration' type='text' placeholder='Length'/> */}
                    <TextInput
                      className="col-6 col-xs-12"
                      labelClassName="input-label mb-2"
                      label="Release Date"
                      name="releaseDate"
                      type="date"
                      placeholder="MM/DD/YYYY"
                    />
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="lengthOfIncarceration"
                      >
                        Length of Incarceration
                      </label>
                    </Col>
                    <Col className="ml-1 d-flex">
                      <RadioButton
                        className="mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2"
                        name="courtOrdered"
                        optionClassName="mb-2"
                        options={incarcerationLength}
                        inputClassName='mt-1 align-self-start'
                        inputLabelClassName='text-wrap align-self-start'
                        onChange={handleChange}
                      /> 
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="coOfficerName"
                      >
                        Name & Phone of CO Officer:
                      </label>
                    </Col>
                    <Col className="col-12 d-flex p-0">
                      <TextInput
                        className="d-flex col-sm-4"
                        name="coOfficerName"
                        type="text"
                        placeholder="Name"
                      />
                      <TextInput
                        className="d-flex col-sm-4"
                        name="coPhoneNumber"
                        type="text"
                        placeholder="Phone"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className=" col-sm-10 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="numberOfTimesIncarceratedInLifetime"
                      >
                        Approximate number of times incarcerated in your
                        lifetime:
                      </label>
                    </Col>
                    <Col className="col-12 col-sm-10 col-lg-12">
                      <TextInput
                        className="mr-3"
                        name="numberOfTimesIncarceratedInLifetime"
                        type="text"
                        placeholder="Number Of Times"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="incarceratedCounty"
                      >
                        Which County/State are you returning to upon reentry?
                      </label>
                    </Col>
                    <Col className="col-12 col-sm-10 col-lg-12">
                      <TextInput
                        className="mr-3"
                        name="incarceratedCountry"
                        type="text"
                        placeholder="County/State"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="supportTeamDuringCurrentIncarceration"
                      >
                        Who is in your support team during your current incarceration(s)?
                      </label>
                    </Col>
                    <Col className="col-12 col-sm-10 col-lg-12">
                      <TextMessage
                        className="mb-3"
                        name="supportTeamDuringCurrentIncarceration"
                        type="textarea"
                        placeholder="Description"
                        size="md"
                        rows="4"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className=" col-sm-12 col-lg-12">
                      <label
                        className="input-label mb-2"
                        htmlFor="supportTeamReturningHome"
                      >
                        Who is in your support team returning home?
                      </label>
                    </Col>
                    <Col className="col-12 col-sm-10 col-lg-12">
                      <TextMessage
                        className="mb-3"
                        name="supportTeamReturningHome"
                        type="textarea"
                        placeholder="Description"
                        size="md"
                        rows="4"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
