import React from 'react';
import { connect } from 'react-redux'
import { ReactComponent as RunIcon } from '../images/svg/play.svg';
import { ReactComponent as ViewIcon } from '../images/svg/view.svg';
import { ReactComponent as DeleteIcon } from '../images/svg/ic_delete.svg';
import { ReactComponent as EditIcon } from '../images/svg/edit-icon.svg';
import { PTr, PTd, IconButton } from './Styles/PatientListStyles';
import moment from "moment";
import { generateConcatenatedString } from "utils/genericUtils";
import ReportActions from 'redux/ReportRedux'
import {
  QUERY_TEMPLATES
} from 'navigation/CONSTANTS'
import { Link } from 'react-router-dom'

export class Query extends React.Component {
  constructor(props) {
    super(props)
    this.timer = null;
  }


  onDelete = () => {
    this.props.onDelete(this.props.template.templateId)
  }

  render () {
    const { index, template, accounts } = this.props

    const res = template && template.request && generateConcatenatedString(template.request.includedFilters, accounts)

    return (
      <PTr key={index}>
        <PTd>{moment(template.createdAt).format(`LT`)} <br></br>{ moment(template.createdAt).format(`Do MMMM YYYY`) }</PTd>
        <PTd>{template.templateName}</PTd>
        <PTd>{template.createdByName}</PTd>
        <PTd>
          { res }
        </PTd>
        <PTd>
          <div className='d-flex'> 
            <Link to={QUERY_TEMPLATES.replace(":id", template.templateId)}>
              <ViewIcon></ViewIcon>
            </Link>

            <IconButton className='mr-2'>
              <RunIcon onClick={() => this.props.onRun(template.templateId)} />
            </IconButton>

            <IconButton className='mr-2'>
              <EditIcon onClick={() => this.props.onEdit(template)} />
            </IconButton>
            
            <IconButton>
              <DeleteIcon onClick={this.onDelete} />
            </IconButton>
          </div>
        </PTd>
      </PTr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingJob: state.report.fetchingJob,
    job: state.report.job
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getJob: (jobId) => dispatch(ReportActions.getJob(jobId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Query);