import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { RadioButton } from "components/Fields/RadioButton";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { SSNField } from "components/Fields/SSNField";
import { LightGrayLabel } from 'styles/GenericStyles';
import { Checkbox } from 'components/Fields/Checkbox'

export default class IdentifyingInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.identifyingInformation
    );
  };

  setDate = (e) => {
    e.target.setAttribute('max', new Date().toISOString().split("T")[0])
  }

  render() {
    let { data, updatingForm, client, read, write } = this.props;
    data = { ...data, ...client }

    if (!write && !read) {
      return (
        <Row className='pt-4 pl-2'>
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      )
    }

    const genderRadioOptions = [
      { key: "Male", value: "male" },
      { key: "Female", value: "female" },
      { key: "Transgender", value: "transgender" }  
    ];

    const raceRadioOptions = [
      { key: "White", value: "white" },
      { key: "Black/African American", value: "black_african_american" },
      { key: "Asian", value: "asian" },
      { key: "Multi-Racial/Other", value: "multi_racial_other" },
    ];

    const ethnicityRadioOptions = [
      { key: "Hispanic or Latino", value: "hispanic_or_latino" },
      {
        key: "Non-Hispanic or Non-Latino",
        value: "non-hispanic_or_non_latino",
      },
    ];

    const relationShipRadioOptions = [
      { key: 'Single', value: 'single' },
      { key: 'Married', value: 'married' },
      { key: 'Widow/Widower', value: 'widowed_widower' },
      { key: 'Married & Separated', value: 'married_and_seperated' },
      { key: 'Divorced', value: 'divorced' },
      { key: 'Significant Other', value: 'domestic_partner' }
    ]

    const violenceIssueOptions = [
      { key: "Yes", value: true },
      { key: "No", value: false },
    ];

    let ssn = data && data.ssn;
    if (ssn) {
      const sArray = ssn.split("-");
      ssn = `xxx-xx-${sArray[2]}`;
    }
    
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data, ssn }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange}) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <Row className="align-items-center w-100">
                  <Col className="col-xs-12 col-sm-12 col-md-4">
                    <label className="input-label" htmlFor="date">
                      Date Information is Gathered:
                    </label>
                  </Col>
                  <Col className="col-xs-12 col-sm-12 col-md-8">
                    <TextInput
                      name="informationGatheredDate"
                      type="date"
                      placeholder="Date Information"
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-sm-12 col-md-4">
                    <label className="input-label" htmlFor="firstName">
                      Participant Name:
                    </label>
                  </Col>
                  <Col className="col-4 d-flex">
                    <TextInput
                      className="mr-3"
                      name="firstName"
                      type="text"
                      placeholder="First"
                      readonly='true'
                    />
                    <TextInput className='mr-3' readonly='true' name='middleName' type='text' placeholder='Middle'/>
                    <TextInput
                      name="lastName"
                      type="text"
                      placeholder="Last"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="addressLine1">
                      Address:
                    </label>
                  </Col>

                  <Col className="col-8 d-flex flex-wrap">
                    <TextInput
                      className="w-100"
                      name="addressLine1"
                      type="text"
                      placeholder="Address"
                      readonly='true'
                    />
                    <TextInput
                      className="w-100"
                      name="addressLine2"
                      type="text"
                      placeholder="Apt, Suite etc"
                      readonly='true'
                    />

                    <TextInput
                      className="flex-grow-1 mr-3"
                      name="city"
                      type="text"
                      placeholder="City"
                      readonly='true'
                    />
                    <TextInput
                      className="flex-grow-1"
                      name="state"
                      type="text"
                      placeholder="State"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="zipcode">
                      Zip Code:
                    </label>
                  </Col>
                  <Col className="col-8">
                    <TextInput
                      className="w-50"
                      name="zipcode"
                      type="text"
                      placeholder="Zip Code"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="phone">
                      Phone Where Participant Can Be Reached:
                    </label>
                  </Col>
                  <Col className="col-8">
                    <TextInput
                      className="w-50"
                      name="phoneNumber"
                      type="text"
                      placeholder="Phone"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="emailAddress">
                      Email Address:
                    </label>
                  </Col>
                  <Col className="col-8">
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="Email Address"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label
                      className="input-label"
                      htmlFor="socialSecurityNumber"
                    >
                      Social Security Number:
                    </label>
                  </Col>
                  <Col className="col-8 d-flex">
                    <SSNField name="ssn" />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="dob">
                      Date Of Birth:
                    </label>
                  </Col>
                  <Col className="col-8 d-flex">
                    <TextInput
                      className="w-80"
                      name="dob"
                      type="date"
                      onClick={this.setDate}
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="place">
                      Place Of Birth:
                    </label>
                  </Col>
                  <Col className="col-8 d-flex">
                    <TextInput
                     
                      name="placeOfBirth"
                      type="text"
                      placeholder="Place"
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="gender">
                      Gender:
                    </label>
                  </Col>
                  <Col className="col-6" style={{ 'max-width': '40%' }}>
                    <RadioButton
                      name="gender"
                      options={genderRadioOptions}
                      readonly='true'
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className="col-2">
                    <TextInput
                      name="pronoun"
                      type="text"
                      placeholder="Pronoun"
                      readonly='true'
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="race">
                      Race:
                    </label>
                  </Col>
                  <Col className="col-8">
                    <RadioButton
                      className="mb-3"
                      name="race"
                      options={raceRadioOptions}
                      onChange={handleChange}
                    />
                    <TextInput
                      className="d-flex col-xs-12 col-md-6"
                      name="raceOther"
                      type="text"
                      placeholder="Others"
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100">
                  <Col className="col-4">
                    <label className="input-label" htmlFor="ethniciity">
                      Ethinicity:
                    </label>
                  </Col>
                  <Col className="col-8">
                    <RadioButton
                      className="mb-3"
                      name="ethnicity"
                      options={ethnicityRadioOptions}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2 w-100 row-cols-1 row-cols-md-1 row-cols-lg-2">
                  <Col>
                    <Row className="align-items-center mb-2 ">
                      <Col className="col-12">
                        <label className="input-label" htmlFor="primaryLang">
                          What Is Participant’s Primary Language?
                        </label>
                      </Col>
                      <Col className="col-12">
                        <TextInput
                          name="primaryLanguage"
                          type="text"
                          placeholder="Primary Lang"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="align-items-center mb-2">
                      <Col className="col-12">
                        <label className="input-label" htmlFor="secondaryLang">
                          What Is Participant’s Secondary Language?
                        </label>
                      </Col>
                      <Col className="col-12">
                        <TextInput
                          name="secondaryLanguage"
                          type="text"
                          placeholder="Secondary Lang"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="align-items-center w-100 mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="relationshipStatus">
                      Relationship Status:
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton
                      className="mb-3"
                      name="relationshipStatus"
                      options={relationShipRadioOptions}
                      onChange={handleChange}
                    />
                    <TextInput
                      label="Other (Specify)"
                      className="d-flex col-12"
                      name="relationshipStatusOther"
                      type="text"
                      placeholder="Others"
                    />
                  </Col>
                </Row>

                <hr />

                <Row className="align-items-center w-100 mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="violence_issues">
                      Are there any identified, past or current, domestic
                      violence issues?
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton
                      className="mb-3"
                      name="domesticViolenceApplicable"
                      options={violenceIssueOptions}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row className="align-items-center w- mb-2">
                  <Col className="col-12">
                    <label
                      className="input-label"
                      htmlFor="violenceDescription"
                    >
                      Please Describe, With Dates Of Incidents:
                    </label>
                  </Col>
                  <Col className="col-12">
                    <TextMessage
                      className="w-75"
                      rows={4}
                      cols={50}
                      name="domesticViolenceDescription"
                      type="text"
                      placeholder="Description"
                    />
                  </Col>
                </Row>

                <Row className="align-items-center w-100 mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="isVeteran">
                      Is participant a Veteran, (anyone who has been on active
                      military duty)
                    </label>
                  </Col>
                  <Col className="col-12">
                    <RadioButton
                      className="mb-3"
                      name="isVeteran"
                      options={violenceIssueOptions}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row className="align-items-center mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="branch">
                      Which Branch:
                    </label>
                  </Col>

                  <Row className=" ml-3 row-cols-1 row-cols-md-2 row-cols-lg-5 d-flex mb-2 align-items-center">
                    <Checkbox name='isArmyBranch'>Army</Checkbox>
                    <Checkbox name='isNavyBranch'>Navy</Checkbox>
                    <Checkbox name='isMarinesBranch'>Marines</Checkbox>
                    <Checkbox name='isAirForceBranch'>Air Force</Checkbox>
                    <Checkbox name='isCoastGaurdBranch'>Coast Guard</Checkbox>
                    <Checkbox name='isSpaceForceBranch'>Space Force</Checkbox>
                  </Row>
                </Row>

                <Row className="align-items-center w-100 mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="dischargedBranchType">
                    (If Discharged, What Kind?)
                    </label>
                  </Col>
                  <Col className="col-12">
                    <TextMessage
                      rows={4}
                      name="dischargedBranchType"
                      type="text"
                      placeholder="What Kind?"
                      />
                  </Col>
                </Row>

                {/* <Row className="align-items-center w-100 mb-2">
                  <Col className="col-12">
                    <label className="input-label" htmlFor="dischargedBranchType">
                    Contact Type
                    </label>
                  </Col>
                  <Col className="col-12">
                    <Select name="contactType" className='custom-select'>
                      <option label="Select" />
                      <option value="In-Person" label="In-Person" />
                      <option value="Phone/Video-conference" label="Phone/Video-conference" />
                      <option value="Electronic (email)" label="Electronic (email)" />
                      <option value="Letter" label="Letter" />
                      </Select>
                  </Col>
                </Row> */}
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}