import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class EmploymentGoals extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Employment Goals:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col> BFET &gt; Employment Goals:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <Row className="col-12 p-0">
          <Col className="col-12">
            <LightGrayLabel>Career Plan:</LightGrayLabel>
          </Col>
          <Col className="col-12 p-0 ml-3">
            <label className="input-label">{data.careerGoal}</label>
          </Col>
        </Row>
        <Row className="col-12 p-0">
          <Col className="col-12">
            <LightGrayLabel>Wage Expectation:</LightGrayLabel>
          </Col>
          <Col className="col-12 p-0 ml-3">
            <label className="input-label">{data.wageExpectation}</label>
          </Col>
        </Row>
        <Row className="col-12 p-0">
          <Col className="col-12">
            <LightGrayLabel>Immediate Job Goals(s):</LightGrayLabel>
          </Col>
          <Col className="col-12 p-0 ml-3">
            <label className="input-label">{data.immediateGoals}</label>
          </Col>
        </Row>
        <Row className="col-12 p-0">
          <Col className="col-12">
            <LightGrayLabel>Target Employment Date:</LightGrayLabel>
          </Col>
          <Col className="col-12 p-0 ml-3">
            <label className="input-label">{data.targetEmploymentDate}</label>
          </Col>
        </Row>
      </Row>
    );
  }
}
