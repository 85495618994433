
import React from 'react';
import Layout from 'components/Layout.jsx'
import LoadingView from 'components/LoadingView.jsx'
import { connect } from 'react-redux'
import { Row, Alert, Col, Form } from 'react-bootstrap';
import { GreetingText } from 'pages/Styles/HomeStyles'
import AdvancedSearchModal from 'components/Modals/AdvancedSearchModal'
import AddClientModal from 'components/Modals/AddClientModal'
import ClientActions from 'redux/ClientRedux'
import { AppointmentList } from 'components/AppointmentList';
import AppointmentActions from 'redux/AppointmentRedux'
import moment from 'moment'
import { FilterDiv } from 'components/Styles/AppointmentListStyles';
import { DateFilter } from 'components/DateFilter';
import ScrollingNavigation from 'components/Appointments/ScrollingNavigation'
import ConfirmationDialog from 'components/Modals/ConfirmationModal'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import AccountActions from 'redux/AccountRedux'

export class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchQuery: undefined,
      showSearchModal: false,
      showAddClientModal: false,
      selectedDate: moment(),
      startDate: moment(),
      endDate: moment().add(7, 'days'),
      showConfirmation: false,
      tentativeCancelApponitmentId: null,
      referred: false,
      referralDisabled: true,
      viewAll: false
    }
  }

  componentDidMount () {
    const { accountUserId, getAccountAppointments } = this.props
    const { selectedDate } = this.state
    const startTime = selectedDate.startOf('day').toISOString()
    const endTime = selectedDate.endOf('day').toISOString()
    getAccountAppointments(accountUserId, startTime, endTime)
  }

  renderLoading = () => {
    return <LoadingView />
  }

  render () {
    const { loading } = this.props
    return (
      <Layout>
        { loading && this.renderLoading() }
        { !loading && this.renderContent() }
      </Layout>
    )
  }

  onChange = (e) => {
    this.props.searchPatient(e.target.value)
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        showSearchModal: true,
        searchQuery: e.target.value
      })
    }
  }

  onCloseAdvancedSearch = () => {
    this.setState({
      showSearchModal: false
    })
  }

  onAddClient = () => {
    this.setState({
      showSearchModal: false,
      showAddClientModal: true
    })
  }

  onCloseAddClient = () => {
    this.setState({
      showAddClientModal: false
    })
  }

  onSelect = (date) => {
    var startTime = moment(date).startOf('day').toISOString()
    var endTime = moment(date).endOf('day').toISOString()
    const { viewAll } = this.state

    this.setState({
      selectedDate: moment(date)
    }, () => 
      viewAll ? this.props.getAllAppointments(startTime, endTime) : this.props.getAccountAppointments(this.props.accountUserId, startTime, endTime)
    )
  }

  applyDates = (startDate, endDate) => {
    var startTime = moment(startDate).startOf('day').toISOString()
    var endTime = moment(startDate).endOf('day').toISOString()
    const { viewAll } = this.state
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate).add(1, 'days')
    }, () => {
      viewAll ? this.props.getAllAppointments(startTime, endTime) : this.props.getAccountAppointments(this.props.accountUserId, startTime, endTime)
    })
  }

  viewAll = (e) => {
    const isChecked = e.currentTarget.checked
    this.setState({
      viewAll : isChecked
    }, () => {
      const { selectedDate } = this.state
      const startTime = selectedDate.startOf('day').toISOString()
      const endTime = selectedDate.endOf('day').toISOString()
      if (isChecked) {
        this.props.getAllAppointments(startTime, endTime)
      } else {
        this.props.getAccountAppointments(this.props.accountUserId, startTime, endTime)
      }
    })
  }

  renderFilters () {
    const { startDate, endDate, selectedDate } = this.state
    let n = endDate.diff(startDate, 'days')
    let resultDates = []
    let sDate = moment(startDate.format('YYYY-MM-DD'))
    const { viewAll } = this.state

    while (Math.abs(n) > 0) {
        resultDates.push({
          date: sDate.format('YYYY-MM-DD'),
          weekDay: sDate.format('dddd'),
          day: sDate.format('D MMM')
        })
        sDate.add(1, "day")
        n--;
    }

    const { accountUserId, isViewAllAppointmentsEnabled, getAccountAppointments, getAllAppointments } = this.props
    const links = resultDates.map((item) => ({ id: item.date, value: item.day, weekDay: item.weekDay}) );
        
    return <>
      <FilterDiv className='mb-3 d-flex'>
        <div className="weeksFilter mr-auto"></div>
        <div className="dateFilter d-flex justify-content-center align-items-center">
          <DateFilter
            accountId={accountUserId}
            applySelectedDates={this.applyDates}
            getAccountAppointments={viewAll ? getAllAppointments : getAccountAppointments}
          />

          { isViewAllAppointmentsEnabled && 
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="View All"
              onChange={this.viewAll}
            />
          }
        </div>
      </FilterDiv>

      <ScrollingNavigation links={links} onSelected={this.onSelect} active={selectedDate.format('YYYY-MM-DD')} />


      {/* <WeekDiv className='pt-3'>
        {
          resultDates.map((d, index) => {
            return (
              <div onClick={() => this.onSelect(d)} className={moment(selectedDate).format('YYYY-MM-DD') == d.date ? 'selected mb-3' : 'mb-3'}>
                <span className='day'>{d.weekDay}</span>
                <span className='date'>{d.day}</span>
              </div>
            )
          })
        }
      </WeekDiv> */}
    </>
  }

  showCancelAppointmentConfirmation = (appointmentId) => {
    this.setState({
      showConfirmation: true,
      tentativeCancelApponitmentId: appointmentId
    })
  }

  cancelAppointment = () => {

    const { tentativeCancelApponitmentId } = this.state
    this.setState({
      showConfirmation: false,
      tentativeCancelApponitmentId: null
    }, () => {
      this.props.cancelAppointment(tentativeCancelApponitmentId)
    })
  }

  renderContent () {
    const { showSearchModal, showAddClientModal, searchQuery, showConfirmation } = this.state
    const { userEmail, firstName, lastName, accountAppointmentsFetching, isSearchClientEnabled, isViewAppointmentsEnabled, isEditAppointmentEnabled, isCancelAppointmentEnabled } = this.props
    
    let name = firstName + ' ' + lastName
    if (!name) {
      name = userEmail
    }

    return (
      <>
        <Alert key={'primary'} variant={'dark'} className='ml-2 mr-2 p-5 mt-3 dashboardBanner'>
          <GreetingText>{`Greetings, ${name} !!!`} </GreetingText>
          <div className='pt-3'> {this.props.welcomeText || 'AXION GALAXY 360, welcomes you.'} </div>
          {
            isSearchClientEnabled && (
              <>
                <Form.Control type='text' placeholder='Search by last name' md={1} className='w-50 mt-3' onKeyDown={this._handleKeyDown} />
                <Row className='ml-1 pt-3 text-primary justify-content-start' md={1} onClick={() => this.setState({ showSearchModal: true })}> Advanced Search</Row>
              </>
            )
          }
        </Alert>

        <Row className='justify-content-around ml-2 mr-2'>

          {/* <Col className='shadow rounded d-flex flex-column align-items-center justify-content-center p-4' md={4}>
            <Row className='justify-content-center text-center mb-2'><CardText bold={true} size={'21px'} color={theme.primaryBgColor}>Book An Appointment</CardText></Row>
            <Row className='justify-content-center'><Button className='btn btn-primiary'><Link style={{color: '#FFF'}} to={APPOINTMENTS}>Now</Link></Button></Row>
          </Col> */}

          {/* <Col className='shadow rounded d-flex p-4' md={2}>
            <Row>
              <Col lg className='d-flex justify-content-center'>
                <IconContainer className='d-flex align-self-center'><CalendarAccountIcon/></IconContainer>
              </Col>
              <Col lg >
                <Row className='justify-content-center'><CardNumber>184</CardNumber></Row>
                <Row className='justify-content-center text-center'><CardText>Total Appointments</CardText></Row>
              </Col>
            </Row>
          </Col>

          <Col className='shadow rounded d-flex flex-column p-4' md={2}>
            <Row>
              <Col lg className='d-flex justify-content-center'>
                <IconContainer className='d-flex align-self-center'><CalendarCheckIcon/></IconContainer>
              </Col>
              <Col lg >
                <Row className='justify-content-center'><CardNumber>184</CardNumber></Row>
                <Row className='justify-content-center text-center'><CardText>Completed Appointments</CardText></Row>
              </Col>
            </Row>
          </Col> */}

          {/* <Col className='shadow rounded d-flex flex-column align-items-center p-4' md={4}>
            {
              referringAccountInProgress ? (
                <>
                  <Row className='justify-content-center text-center'><CardText size={'12px'}>Referral</CardText></Row>
                  <LightGrayLabel>Sending referral...</LightGrayLabel>
                </>
                ) : (referred ? (
                <>
                  <Row className='justify-content-center text-center'><CardText size={'12px'}>Referral</CardText></Row>
                  <LightGrayLabel>Referral email sent successfully!</LightGrayLabel>
                </>
              ) : (
                <Row className='justify-content-center p-3'>
                  <CardText size={'12px'}>Referral</CardText>
                  <Form.Control type='email' placeholder='Enter email' md={1} className='mb-3'/>
                  <Button className='btn btn-primiary' onClick={(e) => this._handleReferral(e)}>Refer</Button>
                </Row>
              ))
            }
          </Col> */}

        </Row>
        {
          isViewAppointmentsEnabled && <Row className='ml-1 mr-1'>
            <Col>
              { this.renderFilters() }
              { accountAppointmentsFetching && this.renderLoading() }
              { 
                !accountAppointmentsFetching &&<AppointmentList
                  accountAppointments={this.props.accountAppointments}
                  editAppointment={this.props.editAppointment}
                  cancelAppointment={this.showCancelAppointmentConfirmation}
                  isEditAppointmentEnabled={isEditAppointmentEnabled}
                  isCancelAppointmentEnabled={isCancelAppointmentEnabled}
                />
              }            
            </Col>
          </Row>
        }

        { showSearchModal && <AdvancedSearchModal searchQuery={searchQuery} onClose={this.onCloseAdvancedSearch} onAddClient={this.onAddClient} />}
        { showAddClientModal && <AddClientModal onClose={this.onCloseAddClient} createClient={this.props.createClient} creatingClient={this.props.creatingClient} />}
        
        { showConfirmation && <ConfirmationDialog 
            heading={'Cancel Appotinment'}
            buttonText={'Cancel'}
            onClose={() => { this.setState({ showConfirmation: false, tentativeCancelApponitmentId: null  })}}
            onSave={this.cancelAppointment}
            message={'Are you sure you want to cancel this appointment?'}
        /> }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    welcomeText: state.user.accountInfo && state.user.accountInfo.welcomeText,
    loading: state.user.fetchingPatients,
    userEmail: state.user.email,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    accountUserId: state.user.accountUserId,
    accountAppointments: state.appointment.accountAppointments,
    accountAppointmentsFetching: state.appointment.accountAppointmentsFetching,
    creatingClient: state.client.creatingClient,

    referringAccountInProgress: state.account.referringAccountInProgress,

    isSearchClientEnabled: isPermissionEnabled(state.user, PERMISSIONS.SEARCH_CLIENT),
    isViewAppointmentsEnabled: isPermissionEnabled(state.user, PERMISSIONS.VIEW_APPOINTMENTS),
    isEditAppointmentEnabled: isPermissionEnabled(state.user, PERMISSIONS.EDIT_APPOINTMENT),
    isCancelAppointmentEnabled: isPermissionEnabled(state.user, PERMISSIONS.CANCEL_APPOINTMENT),
    isViewAllAppointmentsEnabled: isPermissionEnabled(state.user, PERMISSIONS.VIEW_ALL_APPOINTMENTS),

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createClient: (params) => dispatch(ClientActions.createClient(params)),
    getAccountAppointments: (accountId, start, end) => dispatch(AppointmentActions.getAccountAppointments(accountId, start, end)),
    
    getAllAppointments: (start, end) => dispatch(AppointmentActions.getAllAppointments(start, end)),

    cancelAppointment: (appointmentId) => dispatch(AppointmentActions.cancelAppointment(appointmentId)),
    referAccount: (params) => dispatch(AccountActions.referAccount(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);