import React from 'react';
import Layout from 'components/Layout.jsx'
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { Button, Row, Col } from 'react-bootstrap';
import ReportActions from 'redux/ReportRedux'
import Strings from 'i18n/en'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import { Select } from 'components/Fields/Select';
import { Formik, Form, FieldArray } from 'formik';
import BooleanUI from 'components/DataTypes/BooleanUI'
import NumericUI from 'components/DataTypes/NumericUI';
import StringUI from 'components/DataTypes/StringUI';
import DateUI from 'components/DataTypes/DateUI';
import ArrayStringUI from 'components/DataTypes/ArrayStringUI';
import { RadioButton } from 'components/Fields/RadioButton';

export class EditReport extends React.Component {
  constructor(props) {
    super(props)
    this.timer = null;
    this.state = {
      query: null
    };
  }
  
  componentDidMount () {
    this.props.getSearchMetaData()
    this.props.getQuery(this.props.match.params.id)
  }

  componentWillReceiveProps (newProps) {
    if ((this.props.query && this.props.query.queryId) !== (newProps.query && newProps.query.queryId)) {
      this.setState({
        query: newProps.query
      }, () => this.startPolling(newProps.query.jobId))
    }
  }
  
  startPolling (jobId) {
    this.timer = setInterval(()=> this.props.getJob(jobId), 1000);
  }

  renderLoading = () => {
    return <LoadingView />
  }

  render () {
    const { fetchingMetaData, fetchingQuery, job } = this.props
    if (job && job.jobStatus === 'SUCCESS') {
      clearInterval(this.timer);
    }

    return (
      <Layout>
        { (fetchingMetaData || fetchingQuery) && this.renderLoading() }
        { (!fetchingMetaData && !fetchingQuery) && this.renderContent() }
      </Layout>
    )
  }

  handleSubmit = (params) => {
    if (params.filters && params.filters.length === 0) return false
    const req = {
      "request": {
          "includedFilters": {
              "filterOperator": "AND",
              ...params
          }
      }
    }
    this.props.createQuery(req)
  }

  renderContent () {
    let { isReadReportsEnabled, types, allowedTypesMap, filterMetaData, query, job } = this.props
    query = this.state.query
    let request = query && query.request
    let iValues = request && request.includedFilters
    
    const filterOperations = [
      { key: 'AND', value: 'AND' },
      { key: 'OR', value: 'OR' },
    ];


    if (iValues && iValues.filters && iValues.filters.length === 0) {
      return <></>
    }

    return (
      <Col md={12}>
         <Row className="d-flex align-items-center">
          <Col className='col-10'><h4 className='text-nowrap'>Query </h4></Col>

          <Col>
            { job && job.jobStatus === 'FAILED' && <Button variant="danger" disabled className='ml-3'>{'Failedto Generate Report'}</Button> }
            { job && job.jobStatus === 'SUCCESS' && <Button variant="success" className='ml-3'><a href={job.downloadURL} download>Download Report</a></Button> }
            { job && job.jobStatus === 'PENDING' && <Button variant="info" disabled className='ml-3'>{'Generating Report...'}</Button> }
          </Col>

        </Row>

        { isReadReportsEnabled ? (
          <Formik
            enableReinitialize
            initialValues={{ ...iValues }}
            onSubmit={this.handleSubmit}
          >
            {({ values, handleChange, setFieldValue }) => (
             <Form>
                <Row className='border p-2 mt-2'>
                  <Col className='col-6'>
                    <RadioButton className='mb-3' name='filterOperator' options={filterOperations} onChange={handleChange}/>
                  </Col>
                </Row>
                <FieldArray
                  name="filters"
                  render={(arrayHelpers) => (
                    <>
                      {
                        values.filters && values.filters.length > 0 ? (
                          values.filters.map((filter, index) => (
                            <Row className='border p-4' key={index}>
                              <Row className='w-100'>
                                <Col>
                                  <Select
                                    name={`filters.${index}.parentType`}
                                    className="custom-select"
                                    onChange={(event) => {
                                      const selectedType = event.target.value;

                                      setFieldValue(`filters.${index}.parentType`, selectedType);
                                      setFieldValue(`filters.${index}.filterType`, '');
                                      setFieldValue(`filters.${index}.dataType`, null);
                                      setFieldValue(`filters.${index}.value`, null)
                                      setFieldValue(`filters.${index}.value1`, null)
                                    }}
                                  >
                                    <option>Select</option>
                                    {
                                      types.map((s) => {
                                        return <option key={s} value={s}>{s}</option>
                                      })
                                    }
                                  </Select>  
                                </Col>
            
                                { values && values.filters[index] && values.filters[index].parentType && (
                                  <Col>
                                    <Select
                                      name={`filters.${index}.filterType`}
                                      className="custom-select"
                                      onChange={(event) => {
                                        const selectedFilterType = event.target.value;
                                        setFieldValue(`filters.${index}.filterType`, selectedFilterType);
                                        setFieldValue(`filters.${index}.dataType`, null);
                                        setFieldValue(`filters.${index}.name`, '');
                                        setFieldValue(`filters.${index}.value`, null)
                                        setFieldValue(`filters.${index}.value1`, null)
                                      }}
                                    >
                                      <option>Select</option>
                                      {
                                        Object.entries(filterMetaData[values.filters[index].parentType]).map(([key, value]) => {
                                          return <option key={value.displayName} value={key}>{value.displayName}</option>
                                        })
                                      }
                                    </Select>
                                  </Col>
                                )}
                              </Row>
            
                              { values && values.filters[index] && values.filters[index].parentType && values.filters[index].filterType && (
                                <Row className='mt-2 w-100'>
                                  <Col>
                                    <Select
                                      name={`filters.${index}.name`}
                                      className="custom-select mt-2"
                                      onChange={(event) => {
                                        setFieldValue(`filters.${index}.name`, event.target.value);
                                        setFieldValue(`filters.${index}.dataType`, event.target.options[event.target.selectedIndex].dataset.dtype)
                                        setFieldValue(`filters.${index}.value`, null)
                                        setFieldValue(`filters.${index}.value1`, null)
                                      }}
                                    >
                                      <option>Select</option>
                                      {
                                        Object.entries(filterMetaData[values.filters[index].parentType][values.filters[index].filterType]['DEFAULT']).map(([key, value]) => {
                                          return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                        })
                                      }
                                    </Select>
                                  </Col>
                                </Row>
                              )}
            
                              { values && values.filters[index] && values.filters[index].dataType && values.filters[index].dataType === 'BOOLEAN' && (
                                <BooleanUI 
                                  values={values}
                                  index={index}
                                  allowedTypesMap={allowedTypesMap}
                                  onOperatorChange={(v) => {
                                    setFieldValue(`filters.${index}.operator`, v)
                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                      setFieldValue(`filters.${index}.value`, ' ')
                                    }
                                  }}
                                  onNegateChange={(v) => setFieldValue(`filters.${index}.value`, v)} 
                                />
                              )}
            
                              { values && values.filters[index] && values.filters[index].dataType && values.filters[index].dataType === 'NUMERIC' && (
                                <NumericUI 
                                  values={values}
                                  index={index}
                                  allowedTypesMap={allowedTypesMap}
                                  onOperatorChange={(v) => {
                                    setFieldValue(`filters.${index}.operator`, v)
                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                      setFieldValue(`filters.${index}.value`, ' ')
                                    }
                                  }}
                                />
                              )}
            
                              { values && values.filters[index] && values.filters[index].dataType && (values.filters[index].dataType === 'STRING') && (
                                <StringUI 
                                  values={values}
                                  index={index}
                                  allowedTypesMap={allowedTypesMap}
                                  onOperatorChange={(v) => {
                                    setFieldValue(`filters.${index}.operator`, v)
                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                      setFieldValue(`filters.${index}.value`, ' ')
                                    }
                                  }}
                                />
                              )}
            
                              { values && values.filters[index] && values.filters[index].dataType && values.filters[index].dataType === 'ARRAY_STRING' && (
                                <ArrayStringUI 
                                  values={values}
                                  index={index}
                                  allowedTypesMap={allowedTypesMap}
                                  onOperatorChange={(v) => {
                                    setFieldValue(`filters.${index}.operator`, v)
                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                      setFieldValue(`filters.${index}.value`, ' ')
                                    }
                                  }}
                                />
                              )}
            
                            { values && values.filters[index] && values.filters[index].dataType && values.filters[index].dataType === 'DATE_TIME' && (
                                <DateUI 
                                  values={values}
                                  index={index}
                                  allowedTypesMap={allowedTypesMap}
                                  onOperatorChange={(v) => {
                                    setFieldValue(`filters.${index}.operator`, v)
                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                      setFieldValue(`filters.${index}.value`, ' ')
                                    }
                                  }}
                                  onNegateChange={(v) => setFieldValue(`filters.${index}.valueType`, v)}
                                />
                              )}
                            
          
                              <Row className='mt-2'>
                                <button type="button" className='btn btn-outline-danger ml-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                { index === 0 && <button type="button" className='btn btn-outline-primary ml-2' onClick={() => arrayHelpers.insert(index, '')}> + Filter </button> }
                              </Row>
                            </Row>
                          ))
                        ) : (
                          <Row className='justify-content-between mt-3 mr-0 ml-0 w-100'>
                            <Button variant="btn btn-outline-primary" onClick={() => arrayHelpers.push('')}>+ Filter</Button>
                          </Row>
                        )
                      }
                      <Row className='mt-3'>
                        <Button variant="primary" type='submit'>{'Update Report'}</Button>
                      </Row>
                    </>
                  )} 
                />
              </Form>
            )}
          </Formik>
        ) : <Row>{Strings.permissions.message}</Row> }
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isReadReportsEnabled: isPermissionEnabled(state.user, PERMISSIONS.REPORT_QUERIES),
    types: state.report.types,
    query: state.report.query,
    queries: state.report.queries,
    currentPage: state.report.currentPage,
    totalCount: state.report.totalCount,
    fetchingMetaData: state.report.fetchingMetaData,
    fetchingQuery: state.report.fetchingQuery,
    allowedTypesMap: state.report.allowedTypesMap,
    filterMetaData: state.report.filterMetaData,
    fetchingJob: state.report.fetchingJob,
    job: state.report.job
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuery: (queryId) => dispatch(ReportActions.getQuery(queryId)),
    getJob: (jobId) => dispatch(ReportActions.getJob(jobId)),
    getSearchMetaData: () => dispatch(ReportActions.getSearchMetaData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditReport);