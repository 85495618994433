import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  Acknowlegdement,
  GuideLine,
  GuideLine2,
  LightGrayLabel,
  NoticeHeader,
  ViewHeading,
} from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import { CLIENT_REENTRY_CONSENT } from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export class PatientConsentView extends React.Component {
  componentDidMount() {
    this.props.getClientConsentInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    let {
      clientID,
      data,
      isWriteConsentEnabled,
      isReadConsentEnabled,
    } = this.props;

    data = data || {};

    if (!isReadConsentEnabled && !isWriteConsentEnabled) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Consent:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    let name =
      (data.firstName || "") +
      " " +
      (data.middleName || "") +
      " " +
      (data.lastName || "");
    let address =
      (data.addressLine1 || "") +
      " " +
      (data.city || "") +
      " " +
      (data.state || "");
    let otherEmployment =
      (data.firstName || "") +
      " " +
      (data.middleName || "") +
      " " +
      (data.lastName || "");

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Consent:</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_REENTRY_CONSENT.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Row>
            <Col className="col-12 mb-2">
              <ViewHeading>CLIENT IDENTIFICATION:</ViewHeading>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <NoticeHeader className="mb-1">NOTICE TO CLIENTS:</NoticeHeader>
              <Acknowlegdement>
                The Department of Social and Health Services (DSHS) can help you
                better if we are able to work with other agencies and
                professionals that know you and your family. By signing this
                form, you are giving permission for DSHS and the agencies and
                individuals listed below to use and share confidential
                information about you. DSHS cannot refuse you benefits if you do
                not sign this form unless your consent is needed to determine
                your eligibility. If you do not sign this form, DSHS may still
                share information about you to the extent allowed by law. If you
                have questions about how DSHS shares client information or your
                privacy rights, please consult the DSHS Notice of Privacy
                Practices or as the person giving you this form.
              </Acknowlegdement>
            </Col>
          </Row>
          <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Participant Name:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{name}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Date of Birth:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.dob}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Identification Number:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {data.identificationNumber}
                </label>
              </Col>
            </Row>
          </Row>
          <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Address:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{address}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Zip Code:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.zipcode}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Phone Number (Include Area Code)
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.phoneNumber}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Email Address:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.email}</label>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="col-12 mb-2">
              <ViewHeading>CONSENT:</ViewHeading>
            </Col>
          </Row>

          <Row className="col-md-12 col-lg-12">
            <Acknowlegdement>
              I consent to the use of confidential information about me within
              DSHS to plan, provide, and coordinate services, treatment,
              payments, and benefits for me or for other purposes authorised by
              law. I further grant permission to DSHS and the below listed
              agencies, providers, or persons to use my confidential information
              and disclose it to each other for these purposes. Information may
              be shared verbally or by computer data transfer, mail, or hand
              delivery.
            </Acknowlegdement>
          </Row>
          <Row className="col-md-12 col-lg-12">
            <GuideLine>
              Please check all below who are included in this consent in
              addition to DSHS and identify them by name and address:
            </GuideLine>
          </Row>
          <Row className="p-0 col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Health care providers:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToHealthCareProviders)}
                </label>
                <label className="input-label">
                  {data.healthCareProviders}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Mental health care providers:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToMentalHealthCareProviders)}
                </label>
                <label className="input-label">
                  {data.mentalHealthCareProviders}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Substance use disorder service providers:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(
                    data.hasConsentToSubstanceUseDisorderServiceProviders
                  )}
                </label>
                <label className="input-label">
                  {data.substanceUseDisorderServiceProviders}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Other DSHS contracted providers:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToOtherDSHSContractedProviders)}
                </label>
                <label className="input-label">
                  {data.otherDSHSContractedProviders}
                </label>
              </Col>
            </Row>

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Housing programs:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToHousingPrograms)}
                </label>
                <label className="input-label">{data.housingPrograms}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>School districts or colleges:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToSchoolDistrictsOrColleges)}
                </label>
                <label className="input-label">
                  {data.schoolDistrictsOrColleges}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Department of Corrections:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(data.hasConsentToDepartmentOfCorrections)}
                </label>
                <label className="input-label">
                  {data.departmentOfCorrections}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Employment Security Department and its employment partners:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(
                    data.hasConsentToEmploymentSecurityDepartmentAndItsEmploymentPartners
                  )}
                </label>
                <label className="input-label">
                  {data.employmentSecurityDepartmentAndItsEmploymentPartners}
                </label>
              </Col>
            </Row>

            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Social Security Administration or other federal agency:
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label mr-2">
                  {isBoolean(
                    data.hasConsentToSocialSecurityAdministrationOrOtherFederalAgency
                  )}
                </label>
                <label className="input-label">
                  {data.socialSecurityAdministrationOrOtherFederalAgency}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Other: AXION GALAXY 360 Counseling & Employment Inc:
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-2">
                <label className="input-label mr-2">
                  {isBoolean(data.adonaiCounseling)}
                </label>
                <label className="input-label">{otherEmployment}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>See attached list</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {isBoolean(data.seeAttachedList)}
                </label>
              </Col>
            </Row>
          </Row>
          <hr />
          <Row>
            <Col className=" col-md-12 col-lg-12">
              <p>
                <strong>
                  I authorize and consent to sharing the following records and
                  information (check all that apply):
                </strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>All my client records</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {isBoolean(data.hasConsentToClientRecords)}
                </label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Records on attached list</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {isBoolean(data.hasConsentToRecordsOnAttachedList)}
                </label>
              </Col>
            </Row>
            <Col className="col-12 py-2">
              <label className="input-label"> Only the following records</label>
            </Col>
            <Col className="col-12 ">
              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3">
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>
                      Family, social and employment history:
                    </LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(
                        data.hasConsentToFamilySocialEmploymentHistory
                      )}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Health care information</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToHealthCareInformation)}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Treatment or care plans</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToTreatmentOrCarePlans)}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Payment records</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToPaymentRecords)}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Individual assessments</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToIndividualAssessments)}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>
                      School, education, and training
                    </LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToSchoolEducationTraining)}
                    </label>
                  </Col>
                </Row>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Other (list)</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {isBoolean(data.hasConsentToOtherlists)}
                    </label>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>

          <hr />
          <Row className="mb-2">
            <Col>
              <p className="mb-0">
                <small>
                  <strong className="d-block">PLEASE NOTE: </strong>
                </small>
              </p>
              <Row className="col-md-12 col-lg-12">
                <GuideLine2>
                  If your client records include any of the following
                  information, you must also complete this section to include
                  these records.
                </GuideLine2>
              </Row>
            </Col>
          </Row>
          <Row className="p-0">
            <Col className="col-12">
              <label className="input-label col-md-12 col-lg-12 p-0">
                I give my permission to disclose the following records (check
                all that apply):
              </label>
            </Col>
            <Col className="p-0 d-flex flex-wrap ml-3 row-cols-xs-1 row-cols-sm-1 row-cols-lg-1">
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Mental health</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentToMentalHealth)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    HIV/AIDS and STD test results, diagnosis, or treatment
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentToHIVData)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Substance Use Disorder</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentToSubstanceUseDisorder)}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col className="col-12">
              <label className="input-label">This consent is valid for</label>
            </Col>
            <Col className="p-0 d-flex flex-wrap ml-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-1  row-cols-lg-2">
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>One Year</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentUptoOneYear)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>As long as DSHS needs records</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentAslongAsDSHSNeeds)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Until</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.hasConsentUntil)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Date Or Event</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.consentUntilDate}</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-md-12 col-lg-12">
              <LightGrayLabel>
                * I may revoke or withdraw this consent at any time in writing,
                but that will not affect any information already shared.
              </LightGrayLabel>

           
                    <LightGrayLabel>
                      * I understand that records shared under this consent may
                      no longer be protected under the laws that apply to DSHS.
                    </LightGrayLabel>
         
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="col-lg-12 col-md-12">
            <LightGrayLabel>

                * A copy of this form is valid to give my permission to share
                records.
                </LightGrayLabel>

            </Col>
            <Row>
              <Col>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Signature</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {data.clientSignature}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Date</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {data.clientSignatureDate}
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>

          <Row className="mb-3">
            <Col className="col-lg-12 col-sm-12 mb-2">
              <label className="input-label">
                Witness / Notary (Sign And Print Name, If Applicable)
              </label>
            </Col>
            <Row>
              <Col>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Signature</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {data.witnessSignature}
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="col-12">
                  <Col className="col-12">
                    <LightGrayLabel>Data</LightGrayLabel>
                  </Col>
                  <Col className="col-12">
                    <label className="input-label">
                      {data.witnessSignatureDate}
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>

          <Row className="align-items-end mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3">
            <Col className="pl-0 col-lg-4 col-md-12">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Parent Or Other Representative’s Signature (If Applicable)
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {data.parentOrOtherRepresentativeSignature}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col className="pl-0 col-lg-4 col-md-12">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Telephone Number (Include Area Code)
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {data.parentOrOtherRepresentativePhoneNumber}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col className="pl-0 col-lg-4 col-md-8">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Date</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {data.parentOrOtherRepresentativeDate}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr />
          <Row className="mb-2">
            <Col className="col-lg-12 col-md-12mb-2">
              <label className="input-label">
                If I am not the subject of the records, I am authorized to sign
                because I am the: (attach proof of authority)
              </label>
            </Col>
            <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Parent</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.isParent)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Legal Guardian (attach court order)
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.isLegalGuardian)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Personal Representative</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.isPersonalRepresentative)}
                  </label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">
                    {isBoolean(data.isOther)}
                  </label>
                </Col>
              </Row>
            </Row>
          </Row>

          <hr />

          <Row>
            <Col className="p-0">
              <NoticeHeader className="mb-1 col-lg-12 col-sm-12">
                NOTICE TO RECIPIENTS OF INFORMATION:
              </NoticeHeader>
              <Acknowlegdement className="col-lg-12 col-sm-12">
                If these records contain information about HIV, STDs, or AIDS,
                you may not further disclose that information without the
                client’s specific permission. If you have received information
                related to drug or alcohol abuse by the client, you must include
                the following statement when further disclosing information as
                required by 42 CFR 2.32:
              </Acknowlegdement>
              <Acknowlegdement className="col-lg-12 col-sm-12">
                This information has been disclosed to you from records
                protected by Federal confidentiality rules (42 CFR part 2). The
                Federal rules prohibit you from making any further disclosure of
                this information unless further disclosure is expressly
                permitted by the written consent of the person to whom it
                pertains or as otherwise permitted by 42 CFR pert 2. A general
                authorization for the release of medical or other information is
                NOT sufficient for this purpose. The Federal rules restrict any
                use of the information to criminally investigate or prosecute
                any alcohol or drug abuse patient.
              </Acknowlegdement>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }

  render() {
    const { getConsentFetching } = this.props;
    return (
      <>
        {!getConsentFetching && this.renderLoading()}
        {getConsentFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getConsentFetching: state.client.getConsentFetching,
    data: state.client.consent,
    isWriteConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_REENTRY_CONSENT
    ),
    isReadConsentEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_REENTRY_CONSENT
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientConsentInformation: (id) =>
      dispatch(ClientActions.getClientConsentInformation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientConsentView);
