import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class LegalHistory extends React.Component {
  render() {
    let { data, link, read, write } = this.props;
    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Legal History:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    let name =
      (data.probationOfficerName || "") +
      " " +
      (data.probationPhoneNumber || "");
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Legal History:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <Row className="col-12 p-0">
          <Col className="col-12 p-0">
            <LightGrayLabel className="col-12">
              Are you under Civil or Criminal Court Ordered Mental Health or
              Substance Abuse Treatment Program?
            </LightGrayLabel>
          </Col>
          <Col>
            <div className="d-flex">
              <Col className="p-0">
                <Col className="p-0 col-12">
                  <label className="input-label">Civil</label>
                </Col>
                <label className="input-label mb-2 p-0">
                  {isBoolean(data.isUnderCivilCourt)}
                </label>
              </Col>
              <Col className="p-0">
                <Col className=" col-12 p-0">
                  <label className="input-label">Criminal</label>
                </Col>
                <label className="input-label mb-2 p-0">
                  {isBoolean(data.isUnderCriminalCourt)}
                </label>
              </Col>
            </div>
          </Col>
        </Row>

        <Col className="col-sm-12 p-0">
          <Col className="col-12 p-0">
            <LightGrayLabel>
              Is there a court order in place exempting you from reporting the
              requirements of behavioral health services?
            </LightGrayLabel>
          </Col>
          <label className="input-label">
            {isBoolean(
              data.isCourtOrderInPlaceExemptingToReportRequirementsOfBHS
            )}
          </label>
        </Col>
        <Col className="col-sm-12 p-0">
          <Col className="col-12 p-0">
            <LightGrayLabel>
              Please identify any barriers you or a family member has that is
              interfering with your ability to get or keep a job:
            </LightGrayLabel>
          </Col>
          <label className="input-label">
            {data.interferingBarriersToGetAJob}
          </label>
        </Col>

        <Row className="col-12 row row-cols-xs-1 row-cols-md-1 row-cols-sm-1 row-cols-lg-2">
          <Col className="col-12 p-0">
            <Col className="col-12 p-0">
              <LightGrayLabel>
                Have you ever been convicted of a sex crime?
              </LightGrayLabel>
            </Col>
            <label className="input-label">
              {isBoolean(data.isCovictedOfASexCrime)}
            </label>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12 p-0">
              <LightGrayLabel>
                Have you ever been convicted of a crime(s)?
              </LightGrayLabel>
            </Col>
            <label className="input-label">
              {isBoolean(data.isCovictedOfCrimes)}
            </label>
          </Col>
        </Row>

        <Col className="col-12 p-0 mb-3">
          <Col className="col-12 p-0">
            <LightGrayLabel>
              If Yes, Please list conviction(s) and date(s):
            </LightGrayLabel>
          </Col>

          {data &&
            data.convictions &&
            data.convictions.map((conviction) => (
              <Row className="mt-3 col-12 p-0">
                <Col className="col-6">
                  <LightGrayLabel>Conviction: </LightGrayLabel>
                  <label className="input-label">{conviction.conviction}</label>
                </Col>
                <Col className="col-6 p-0">
                  <LightGrayLabel>Conviction Date: </LightGrayLabel>
                  <label className="input-label">
                    {conviction.convictionDate}
                  </label>
                </Col>
              </Row>
            ))}
        </Col>
        <Col className="col-12 p-0">
          <Col className="col-12 p-0">
            <LightGrayLabel>I am currently on Probation/Parole:</LightGrayLabel>
          </Col>
          <label className="input-label">
            {isBoolean(data.isCurrentlyOnProbation)}
          </label>
        </Col>
        <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                If yes, Name & Phone of PO Officer:
              </LightGrayLabel>
            </Col>
            <Col className="col-10 d-flex">
              <label className="input-label">{name} </label>
            </Col>
          </Row>

          <Row className="p-0">
            <Col className="col-12 ">
              <LightGrayLabel>When is your Probation/Parole up?</LightGrayLabel>
            </Col>
            <Col className="col-12 col-lg-8">
              <label className="input-label">{data.probationUpDate}</label>
            </Col>
          </Row>
        </Row>
        <hr />
        <Row>
          <Col className="col-12">
            <LightGrayLabel>Other Barriers to employment:</LightGrayLabel>
          </Col>
          <Col className="col-12 col-lg-8">
            <label className="input-label">
              {data.otherBarriersToEmployment}
            </label>
          </Col>
        </Row>

        <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-1">
          <Row>
            <Col className="col-12">
              <LightGrayLabel>Person with barrier?</LightGrayLabel>
            </Col>
            <Col className="col-12 col-lg-8">
              <label className="input-label">{data.personWithBarrier}</label>
            </Col>
          </Row>
          <Row >
            <Col className="col-12">
              <LightGrayLabel>When did it start?</LightGrayLabel>
            </Col>
            <Col className="col-12 col-lg-8">
              <label className="input-label">{data.barrierStartDate}</label>
            </Col>
          </Row>
        </Row>
        <Col className="col-10 p-0">
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Has it been verified by a doctor, counselor, etc.?
              </LightGrayLabel>
            </Col>
            <Col className="col-12 col-lg-8">
              <label className="input-label">
                {humanFormat(data.hasBeenVerifiedByDoctor)}
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
