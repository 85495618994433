import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import LoadingView from "components/LoadingView.jsx";
import {
  CLIENT_DVR_SERVICE_DELIVERY,
} from "navigation/CONSTANTS";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export class ServiceDeliveryView extends React.Component {

  componentDidMount() {
    this.props.getClientDVRServiceDeliveryInformation(this.props.clientID);
  }

  renderLoading = () => {
    return <LoadingView />;
  };

  renderContent() {
    let {
      clientID,
      data,
      isWriteDVRServiceDeliveryEnabled,
      isReadDVRServiceDeliveryEnabled,
    } = this.props;
    data = data || {};
    if (!isReadDVRServiceDeliveryEnabled && !isWriteDVRServiceDeliveryEnabled) {
      return (
        <Row className="shadow rounded border p-4 mt-4 mr-1 d-flex flex-column">
          <Row className="pl-3">DVR &gt; Service Delivery</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }
    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>DVR &gt; Service Delivery</Col>
          <Col className="d-flex justify-content-end">
            <Link to={CLIENT_DVR_SERVICE_DELIVERY.replace(":id", clientID)}>
              Edit
            </Link>
          </Col>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="mb-3 ml-1">
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>DVR Customer</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.dvrCustomer}</label>
              </Col>
            </Row>
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>
                  Social Security Number (Last Four Digits)
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.ssnNumber}</label>
              </Col>
            </Row>
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>CRP Name</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.crpName}</label>
              </Col>
            </Row>
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>CRP Representative</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.crpRepresentative}</label>
              </Col>
            </Row>
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>CRP Counselor</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.dvrCounselor}</label>
              </Col>
            </Row>
            <Row className="p-0 pr-3 col-6 col-xs-12 col-md-12 col-lg-6">
              <Col className="col-12">
                <LightGrayLabel>Total Cost</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.totalCost}</label>
              </Col>
            </Row>
          </Row>

          <Row className="row-cols-1 row-cols-md-1 row-cols-lg-3">
            <Col className="col-md-12 col-lg-12 col-12">
              <label className="input-label mb-3">CRP Service Category</label>
            </Col>

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Vocational Evaluation Services</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isVocationalEvaluationServices)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Intensive Training Services</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isIntensiveTrainingServices)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Trial Work Experience</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isTrailWorkExperience)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>ob Retention Services</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isObRetentionServices)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Community Based Assessment</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isCommunityBasedAssessment)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Off-Site Psycho-Social – NON-SE</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isOffSitePyschoSE)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>ob Placement Services</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isObPlacementServices)}
                </label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Off-Site Psycho - SE</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">
                  {isBoolean(data.isOffSitePyschoSocialNonSE)}
                </label>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2 pt-3">
            <Col>
              <label className="input-label mb-3">
                Time Lines (Overall Plan)
              </label>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>From:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.timelineFromDate}</label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>To:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.timelineToDate}</label>
                </Col>
              </Row>
            </Col>
            <Col>
              <label className="input-label mb-3">
                Dates Of This Reporting Period
              </label>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>From:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {data.reportingPeriodFromDate}
                  </label>
                </Col>
              </Row>
              <Row className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>To:</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {data.reportingPeriodToDate}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>

          <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2 pt-3">
            <Col className="col-md-12 col-lg-12 col-12">
              <label className="input-label mb-3">
                Pre-Employment Transition Services Crp Service Category
              </label>
            </Col>
            <Col className=" p-0">
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Informational Interview
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSInformationalInterview)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Pre-ETS: Job Exploration</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSJobExploration)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Pre-ETS: Job Shadow</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSJobShadow)}
                  </label>
                </Col>
              </Col>

              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Pre-ETS: Social Skills</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSSocialSkills)}
                  </label>
                </Col>
              </Col>
            </Col>
            <Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Work-Based Learning A
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkBasedLearningA)}
                  </label>
                </Col>
              </Col>

              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Work-Based Learning B
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkBasedLearningB)}
                  </label>
                </Col>
              </Col>

              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Work-Based Learning C
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkBasedLearningC)}
                  </label>
                </Col>
              </Col>

              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Workplace Readiness Training A
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkplaceReadinessTrainingA)}
                  </label>
                </Col>
              </Col>

              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Workplace Readiness Training B
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkplaceReadinessTrainingB)}
                  </label>
                </Col>
              </Col>
              <Col className="col-12 p-0">
                <Col className="col-12">
                  <LightGrayLabel>
                    Pre-ETS: Workplace Readiness Training C
                  </LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">
                    {isBoolean(data.isPreETSWorkplaceReadinessTrainingC)}
                  </label>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className=" p-0 row-cols-1 row-cols-md-1 row-cols-lg-2">
            <Col>
              <label className="input-label mb-3">Type Of Report</label>
              <Col className="p-0">
                <Col className="p-0">
                  <LightGrayLabel>Intake Report</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isIntakeReport)}
                    </label>
                  </Col>
                </Col>
                <Col className="p-0">
                  <LightGrayLabel>Outcome Report</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isOutcomeReport)}
                    </label>
                  </Col>
                </Col>

                <Col className="p-0">
                  <LightGrayLabel>Job Placement Activity Report</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isJobPlacementActivityReport)}
                    </label>
                  </Col>
                </Col>
                <Col className="p-0">
                  <LightGrayLabel>Monthly Update</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isMonthlyUpdate)}
                    </label>
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col >
              <label className="input-label mb-3 ml-3">Level Of Service For:</label>
              <Col className=" row row-cols-1 row-cols-md-2 row-cols-lg-2 ml-1 pt-2">
                <Col className="p-0">
                  <LightGrayLabel>Level 1</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isLevel1Service)}
                    </label>
                  </Col>
                </Col>

                <Col className="p-0">
                  <LightGrayLabel>Level 2</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isLevel2Service)}
                    </label>
                  </Col>
                </Col>

                <Col className="p-0">
                  <LightGrayLabel>Level 3</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isLevel3Service)}
                    </label>
                  </Col>
                </Col>

                <Col className="p-0">
                  <LightGrayLabel>Level 4</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isLevel4Service)}
                    </label>
                  </Col>
                </Col>
                <Col className="p-0">
                  <LightGrayLabel>N/A</LightGrayLabel>
                  <Col className="p-0">
                    <label className="input-label">
                      {isBoolean(data.isLevelOfServiceNotApplicable)}
                    </label>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <LightGrayLabel>Date</LightGrayLabel>
            </Col>
            <Col className="col-12 p-0 ml-3">
              <label className="input-label">{data.reportDate}</label>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }

  render() {
    const { getDVRServiceDeliveryFetching } = this.props;
    return (
      <>
        {!getDVRServiceDeliveryFetching && this.renderLoading()}
        {getDVRServiceDeliveryFetching && this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getDVRServiceDeliveryFetching: state.client.getDVRServiceDeliveryFetching,
    data: state.client.dvrServiceDelivery,
    isWriteDVRServiceDeliveryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.WRITE_DVR_SERVICE_DELIVERY
    ),
    isReadDVRServiceDeliveryEnabled: isPermissionEnabled(
      state.user,
      PERMISSIONS.READ_DVR_SERVICE_DELIVERY
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientDVRServiceDeliveryInformation: (id) =>
      dispatch(ClientActions.getClientDVRServiceDeliveryInformation(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryView);
