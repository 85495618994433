import React from 'react';
import LoadingView from 'components/LoadingView.jsx'
import { Row } from 'react-bootstrap';
import { PTh, PTr, PTd, PTable } from './Styles/AppointmentListStyles';
import { ContextMenu } from 'components/ContextMenu';
import { CLIENTS } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import moment from 'moment'

export class AppointmentList extends React.Component {
  renderLoading = () => {
    return <LoadingView />
  }

  onEdit = (appointment) => {
    this.props.editAppointment()
  }

  onCancel = (appointment) => {


    this.props.cancelAppointment(appointment.appointmentId)
  }

  renderTable (appts) {
    const { isEditAppointmentEnabled, isCancelAppointmentEnabled } = this.props

    const list = appts && appts.map((appointment, index) => {
      const options = []

      if (isEditAppointmentEnabled) {
        options.push({
          name: <Link to={`appointments/${appointment.appointmentId}/edit`}>Edit</Link>
        })
      }
  
      if (isCancelAppointmentEnabled) {
        options.push({
          name: 'Cancel',
          onClick: () => this.onCancel(appointment)
        })
      }

      return (
        <PTr key={index}>
          <PTd><Link to={`${CLIENTS}/${appointment.client.clientId}/reentry/INDENTIFYING_INFORMATION`}> {appointment.client && (appointment.client.firstName + ' ' + appointment.client.lastName) }</Link></PTd>
          <PTd>{appointment.client.ejasNumber}</PTd>
          <PTd>{appointment.client.clientShortId}</PTd>
          <PTd>{appointment.accountUser.firstName + ' ' + appointment.accountUser.lastName}</PTd>
          <PTd>{`${moment(appointment.startTime).format('LT')} -  ${moment(appointment.endTime).format('LT')}`}</PTd>
          <PTd>{appointment.appointmentType}</PTd>
          <PTd className='text-center'>
            <ContextMenu
              options={options}
            />
          </PTd>
        </PTr>
      )
    })

    return <>
      <PTable>
        <thead>
          <tr>
            <PTh className='col-2'>Client</PTh>
            <PTh>EJAS Number</PTh>

            <PTh>Client ID</PTh>
            <PTh>Provider</PTh>
            <PTh>Time</PTh>
            <PTh>Appointment Type</PTh>
            <PTh className='text-center'>Action</PTh>
          </tr>
        </thead>
        <tbody>
          { list }
        </tbody>
      </PTable>
    </>
  }

  render () {
    const { accountAppointments } = this.props;
    const appts = [...accountAppointments].reverse()

    if (appts && appts.length === 0) {
      return (
        <Row className='justify-content-center mt-4'>No Appointments for this day!</Row>
      )
    }
    return (
      this.renderTable(appts)
    )
  }

}

export default AppointmentList