import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and ActionCreators
const { Types, Creators } = createActions({
  updateUserToken: [ "jwt", "tokenInfo", "accountInfo", "permissions", "tokenType"],
  updateUserInfo: ["userId", "user", "fetch"],
  updateUser: ["user"],

  loggedOut: null,
  userTokenValid: ["valid", "user"],
  getUserInfo: ["userId"],
  getUserInfoFailure: null,

  deleteUser: ["userId", "fetch"],
  deleteUserSuccess: null,
});

export const UserTypes = Types;
export default Creators;

export const USER_STATUS = Object.freeze({
  PENDING: "pending",
  APPROVED: "approved",
});

export const PERMISSIONS = Object.freeze({
  SEARCH_CLIENT: "SEARCH_CLIENT",
  ADD_CLIENT: "ADD_CLIENT",
  EDIT_CLIENT: "EDIT_CLIENT",
  VIEW_CLIENTS: "VIEW_CLIENTS",
  ADD_USER: "ADD_USER",
  EDIT_USER: "EDIT_USER",
  DELETE_USER: "DELETE_USER",
  VIEW_USERS: "VIEW_USERS",
  ADD_APPOINTMENT: "ADD_APPOINTMENT",
  EDIT_APPOINTMENT: "EDIT_APPOINTMENT",
  CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
  VIEW_APPOINTMENTS: "VIEW_APPOINTMENTS",
  VIEW_ALL_APPOINTMENTS: "VIEW_ALL_APPOINTMENTS",
  READ_REENTRY_INDENTIFYING_INFORMATION: "READ_REENTRY_IDENTIFYING_INFORMATION",
  WRITE_REENTRY_INDENTIFYING_INFORMATION: "WRITE_REENTRY_IDENTIFYING_INFORMATION",
  READ_REENTRY_FAMILY: "READ_REENTRY_FAMILY",
  WRITE_REENTRY_FAMILY: "WRITE_REENTRY_FAMILY",
  READ_REENTRY_INCOME: "READ_REENTRY_INCOME",
  WRITE_REENTRY_INCOME: "WRITE_REENTRY_INCOME",
  READ_REENTRY_HOUSING: "READ_REENTRY_HOUSING",
  WRITE_REENTRY_HOUSING: "WRITE_REENTRY_HOUSING",
  READ_REENTRY_PERSONAL_HEALTH_INFORMATION: "READ_REENTRY_PERSONAL_HEALTH_INFORMATION",
  WRITE_REENTRY_PERSONAL_HEALTH_INFORMATION: "WRITE_REENTRY_PERSONAL_HEALTH_INFORMATION",
  READ_REENTRY_LEGAL_HISTORY: "READ_REENTRY_LEGAL_HISTORY",
  WRITE_REENTRY_LEGAL_HISTORY: "WRITE_REENTRY_LEGAL_HISTORY",
  READ_REENTRY_INCARCERATION_REENTRY: "READ_REENTRY_INCARCERATION_REENTRY",
  WRITE_REENTRY_INCARCERATION_REENTRY: "WRITE_REENTRY_INCARCERATION_REENTRY",
  READ_REENTRY_TRANSPORTATION: "READ_REENTRY_TRANSPORTATION",
  WRITE_REENTRY_TRANSPORTATION: "WRITE_REENTRY_TRANSPORTATION",
  READ_REENTRY_IMMEDIATE_NEEDS: "READ_REENTRY_IMMEDIATE_NEEDS",
  WRITE_REENTRY_IMMEDIATE_NEEDS: "WRITE_REENTRY_IMMEDIATE_NEEDS",
  READ_REENTRY_EMPLOYMENT_SEARCH_INFORMATION: "READ_REENTRY_EMPLOYMENT_SEARCH_INFORMATION",
  WRITE_REENTRY_EMPLOYMENT_SEARCH_INFORMATION: "WRITE_REENTRY_EMPLOYMENT_SEARCH_INFORMATION",
  READ_REENTRY_SKILLS_AND_ABILITIES: "READ_REENTRY_SKILLS_AND_ABILITIES",
  WRITE_REENTRY_SKILLS_AND_ABILITIES: "WRITE_REENTRY_SKILLS_AND_ABILITIES",
  READ_REENTRY_CONSENT: "READ_REENTRY_CONSENT",
  WRITE_REENTRY_CONSENT: "WRITE_REENTRY_CONSENT",
  READ_REENTRY_DOCUMENTS: "READ_REENTRY_DOCUMENTS",
  WRITE_REENTRY_DOCUMENTS: "WRITE_REENTRY_DOCUMENTS",
  READ_REENTRY_NOTES: "READ_REENTRY_NOTES",
  WRITE_REENTRY_NOTES: "WRITE_REENTRY_NOTES",
  READ_BFET_EMPLOYMENT_GOALS: "READ_BFET_EMPLOYMENT_GOALS",
  READ_BFET_SKILLS_AND_QUALIFICATION: "READ_BFET_SKILLS_AND_QUALIFICATION",
  READ_BFET_ASSESSED_EMPLOYMENT_BARRIERS: "READ_BFET_ASSESSED_EMPLOYMENT_BARRIERS",
  READ_BFET_EMPLOYMENT_PLAN: "READ_BFET_EMPLOYMENT_PLAN",
  READ_BFET_DECLARATION_AND_SIGNATURE: "READ_BFET_DECLARATION_AND_SIGNATURE",
  READ_BFET_EMPLOYMENT_HISTORY: "READ_BFET_EMPLOYMENT_HISTORY",
  WRITE_BFET_EMPLOYMENT_GOALS: "WRITE_BFET_EMPLOYMENT_GOALS",
  WRITE_BFET_SKILLS_AND_QUALIFICATION: "WRITE_BFET_SKILLS_AND_QUALIFICATION",
  WRITE_BFET_ASSESSED_EMPLOYMENT_BARRIERS: "WRITE_BFET_ASSESSED_EMPLOYMENT_BARRIERS",
  WRITE_BFET_EMPLOYMENT_PLAN: "WRITE_BFET_EMPLOYMENT_PLAN",
  WRITE_BFET_DECLARATION_AND_SIGNATURE: "WRITE_BFET_DECLARATION_AND_SIGNATURE",
  WRITE_BFET_EMPLOYMENT_HISTORY: "WRITE_BFET_EMPLOYMENT_HISTORY",
  READ_BFET_JOB_SEARCH_INFO: "READ_BFET_JOB_SEARCH_INFO",
  WRITE_BFET_JOB_SEARCH_INFO: "WRITE_BFET_JOB_SEARCH_INFO",
  READ_BFET_DOCUMENTS: "READ_BFET_DOCUMENTS",
  WRITE_BFET_DOCUMENTS: "WRITE_BFET_DOCUMENTS",
  READ_BFET_NOTES: "READ_BFET_NOTES",
  WRITE_BFET_NOTES: "WRITE_BFET_NOTES",
  READ_MH_DEMOGRAPHICS: "READ_MH_DEMOGRAPHICS",
  READ_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES: "READ_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES",
  READ_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY: "READ_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY",
  READ_MH_DEVELOPMENT: "READ_MH_DEVELOPMENT",
  READ_MH_SOCIAL_RELATIONSHIPS: "READ_MH_SOCIAL_RELATIONSHIPS",
  READ_MH_EDUCATION: "READ_MH_EDUCATION",
  READ_MH_SPIRITUAL_RELIGION: "READ_MH_SPIRITUAL_RELIGION",
  READ_MH_LEGAL: "READ_MH_LEGAL",
  READ_MH_EMPLOYMENT: "READ_MH_EMPLOYMENT",
  READ_MH_MILITARY: "READ_MH_MILITARY",
  READ_MH_LEISURE_AND_RECREATIONAL: "READ_MH_LEISURE_AND_RECREATIONAL",
  READ_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY: "READ_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY",
  READ_MH_MEDICAL_AND_PHYSICAL_HEALTH: "READ_MH_MEDICAL_AND_PHYSICAL_HEALTH",
  READ_MH_MEDICAL_HISTORY: "READ_MH_MEDICAL_HISTORY",
  READ_MH_NUTRITION: "READ_MH_NUTRITION",
  READ_MH_CHEMICAL_USE_HISTORY: "READ_MH_CHEMICAL_USE_HISTORY",
  READ_MH_ADDITIONAL_INFORMATION: "READ_MH_ADDITIONAL_INFORMATION",
  WRITE_MH_DEMOGRAPHICS: "WRITE_MH_DEMOGRAPHICS",
  WRITE_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES: "WRITE_MH_PRIMARY_REASONS_FOR_SEEKING_SERVICES",
  WRITE_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY: "WRITE_MH_FAMILY_INFORMATION_AND_PSYCHOSOCIAL_HISTORY",
  WRITE_MH_DEVELOPMENT: "WRITE_MH_DEVELOPMENT",
  WRITE_MH_SOCIAL_RELATIONSHIPS: "WRITE_MH_SOCIAL_RELATIONSHIPS",
  WRITE_MH_EDUCATION: "WRITE_MH_EDUCATION",
  WRITE_MH_SPIRITUAL_RELIGION: "WRITE_MH_SPIRITUAL_RELIGION",
  WRITE_MH_LEGAL: "WRITE_MH_LEGAL",
  WRITE_MH_EMPLOYMENT: "WRITE_MH_EMPLOYMENT",
  WRITE_MH_MILITARY: "WRITE_MH_MILITARY",
  WRITE_MH_LEISURE_AND_RECREATIONAL: "WRITE_MH_LEISURE_AND_RECREATIONAL",
  WRITE_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY: "WRITE_MH_COUNSELING_AND_PRIOR_TREATMENT_HISTORY",
  WRITE_MH_MEDICAL_AND_PHYSICAL_HEALTH: "WRITE_MH_MEDICAL_AND_PHYSICAL_HEALTH",
  WRITE_MH_MEDICAL_HISTORY: "WRITE_MH_MEDICAL_HISTORY",
  WRITE_MH_NUTRITION: "WRITE_MH_NUTRITION",
  WRITE_MH_CHEMICAL_USE_HISTORY: "WRITE_MH_CHEMICAL_USE_HISTORY",
  WRITE_MH_ADDITIONAL_INFORMATION: "WRITE_MH_ADDITIONAL_INFORMATION",
  READ_MH_DOCUMENTS: "READ_MH_DOCUMENTS",
  WRITE_MH_DOCUMENTS: "WRITE_MH_DOCUMENTS",
  READ_MH_NOTES: "READ_MH_NOTES",
  WRITE_MH_NOTES: "WRITE_MH_NOTES",
  READ_DEFAULT_NOTES: "READ_DEFAULT_NOTES",
  WRITE_DEFAULT_NOTES: "WRITE_DEFAULT_NOTES",
  WRITE_DVR_SERVICE_DELIVERY: "WRITE_DVR_SERVICE_DELIVERY_OUTCOME",
  READ_DVR_SERVICE_DELIVERY: "READ_DVR_SERVICE_DELIVERY_OUTCOME",
  WRITE_DVR_VOCATIONAL: "WRITE_DVR_VOCATIONAL_ASSESSMENT_WORKSHEET",
  READ_DVR_VOCATIONAL: "READ_DVR_VOCATIONAL_ASSESSMENT_WORKSHEET",
  READ_DVR_DOCUMENTS: "READ_DVR_DOCUMENTS",
  WRITE_DVR_DOCUMENTS: "WRITE_DVR_DOCUMENTS",
  READ_DVR_NOTES: "READ_DVR_NOTES",
  WRITE_DVR_NOTES: "WRITE_DVR_NOTES",
  // WRITE_BFET_SUPPLIES: 'WRITE_BFET_SUPPLIES',
  // READ_BFET_SUPPLIES: 'READ_BFET_SUPPLIES',
  // WRITE_MENTAL_HEALTH_SUPPLIES: 'WRITE_MENTAL_HEALTH_SUPPLIES',
  // READ_MENTAL_HEALTH_SUPPLIES: 'READ_MENTAL_HEALTH_SUPPLIES',
  // WRITE_DVR_SUPPLIES: 'WRITE_DVR_SUPPLIES',
  // READ_DVR_SUPPLIES: 'READ_DVR_SUPPLIES',
  // WRITE_REENTRY_SUPPLIES: 'WRITE_REENTRY_SUPPLIES',
  // READ_REENTRY_SUPPLIES: 'READ_REENTRY_SUPPLIES',
  ADD_ROLE: "ADD_ROLE",
  EDIT_ROLE: "EDIT_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  VIEW_ROLES: "VIEW_ROLES",
  WRITE_DEFAULT_SUPPLIES: 'WRITE_DEFAULT_SUPPLIES',
  READ_DEFAULT_SUPPLIES: 'READ_DEFAULT_SUPPLIES',
  WRITE_DEFAULT_DOCUMENTS: 'WRITE_DEFAULT_DOCUMENTS',
  READ_DEFAULT_DOCUMENTS: 'READ_DEFAULT_DOCUMENTS',

  READ_REENTRY: "READ_REENTRY",
  WRITE_REENTRY: "WRITE_REENTRY",
  READ_BFET: "READ_BFET",
  WRITE_BFET: "WRITE_BFET",
  READ_MH: "READ_MH",
  WRITE_MH: "WRITE_MH",
  READ_DVR: "READ_DVR",
  WRITE_DVR: "WRITE_DVR",
  REPORT_QUERIES: "REPORT_QUERIES"
});

export const TOKEN_TYPE = Object.freeze({
  CLIENT_TOKEN: 'CLIENT',
  ACCOUNT_USER_TOKEN: 'ACCOUNT_USER'
})

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  loading: false,
  accessToken: null,
  accountId: null,
  accountUserId: null,
  email: null,
  firstName: null,
  lastName: null,
  profilePictureDownlo: null,
  status: null,
  permissions: [],
  iat: null,
  exp: null,
  refreshToken: null,
  tokenInfo: null,
  accountInfo: null,
  tokenType: 'ACCOUNT_USER',
  jwt: null
});

export const updateUserToken = (state, { jwt, tokenInfo, accountInfo, permissions, tokenType }) => {
  return state.merge({
    jwt,
    tokenInfo,
    accessToken: jwt,
    accountInfo,
    permissions,
    tokenType
  });
};

export const updateUser = (state, { user }) => {
  return state.merge({
    accountId: user.accountId,
    accountUserId: user.accountUserId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    profilePictureDownlo: user.profilePictureDownloadURL,
    status: user.status
  });
};

export const logout = (state, { user }) => {
  return state.merge({
    ...INITIAL_STATE,
  });
};

export const getUserInfo = (state, { userId }) => {
  return state.merge({
    loading: true,
  });
};

export const getUserInfoFailure = (state) =>
  state.merge({
    loading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_USER_TOKEN]: updateUserToken,
  [Types.UPDATE_USER]: updateUser,
  [Types.LOGGED_OUT]: logout,
  [Types.GET_USER_INFO]: getUserInfo,
  [Types.GET_USER_INFO_FAILURE]: getUserInfoFailure
});

export const isPermissionEnabled = (state, permission) => {
  return !!(state.permissions && state.permissions.includes(permission));
};
