import styled from 'styled-components';
import { Accordion } from 'react-bootstrap'

export const CustomAccordion = styled(Accordion)`
  background: #FFFFFF;
  box-shadow: 0px 2px 5px #3333334F;
  border-radius: 6px;
  .card a {
    text-decoration: none;
  }
  .card .selected {
    color: white;
  }
  .card-header {
    background-color: white;
    border: none;
    a {
      font-size: 16px;
      line-height: 25px;
      color: #111111;
    }
    .arrow-icon.show {
      transform: rotate(90deg);
    }
  }
  .card-header.selected {
    background: #3B41C2
  }
  .card-body {
    background: #F7F7F7;
    box-shadow: 0px 1px 3px #00000012;
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    margin: 0 .75rem;
    card {
      border: none;
    }
    a {
      text-decoration: none !important;
      font-size: 14px;
      line-height: 21px;
      color: #43425D;
      padding: .5rem 0 .5rem .5rem;
      margin-bottom: .25rem;
      border-radius: 4px;
      display: inline-block;
      width: 100%;
      text-align: left;
      &:hover, &.selected {
        background: #3B41C2;
        color: white;
      }
    }
  }
`

export const UploadContainer = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: 35px;
  width: 116px;
  background: #F2F4F8 0% 0% no-repeat padding-box;
  bottom: 8px;
  right: 37px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  font-size: 14px;
`