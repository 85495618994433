import React from 'react';
import { Alert } from 'react-bootstrap';

export class AlertMessage extends React.Component {
  render () {
    const { messages, type, onClear } = this.props  
    if (messages && messages.length > 0) {
      const list = messages.map((m, index) => <div key={index}>{m}</div>)
      return (
        <Alert className='w-100 mt-3' variant={type} dismissible={true} onClose={onClear}>
          {list}
        </Alert>
      )
    } else {
      return null
    }
  }
}

export default AlertMessage;