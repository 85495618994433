import { switchMap, filter, delay, tap, ignoreElements } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs'
import AppointmentActions, { AppointmentTypes } from "redux/AppointmentRedux";
import AlertActions from "redux/AlertRedux";
import { history } from 'navigation/history'     
import { ROOT } from 'navigation/CONSTANTS';
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'

export const getAppointmentsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.GET_APPOINTMENTS),
    switchMap((action) => {
      const { accessToken } = store.value.user
      return from(api.getAppointments(action.id, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AppointmentActions.getAppointmentsSuccess(response.data))
          } else {
            return of(
              AppointmentActions.getAppointmentsFailure(),
              AlertActions.error(['Retreiving appointments failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getAccountAppointmentsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.GET_ACCOUNT_APPOINTMENTS),
    delay(500),
    filter(() => store.value.user.accessToken && isPermissionEnabled(store.value.user, PERMISSIONS.VIEW_APPOINTMENTS)),
    switchMap((action) => {
      const { user } = store.value
      const { accountId, start, end } = action
      return from(api.getAccountAppointments(accountId, start, end, (action.pageNumber || 1), user.accessToken,)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AppointmentActions.getAccountAppointmentsSuccess(response.data, (action.pageNumber || 1)))
          } else {
            return of(
              AlertActions.error(['Fetching account appointments failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getAllAppointmentsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.GET_ALL_APPOINTMENTS),
    delay(500),
    filter(() => store.value.user.accessToken && isPermissionEnabled(store.value.user, PERMISSIONS.VIEW_ALL_APPOINTMENTS)),
    switchMap((action) => {
      const { user } = store.value
      const { start, end } = action
      return from(api.getAllAppointments(start, end, (action.pageNumber || 1), user.accessToken,)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AppointmentActions.getAllAppointmentsSuccess(response.data, (action.pageNumber || 1)))
          } else {
            return of(
              AlertActions.error(['Fetching All appointments failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getAccountAppointmentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.GET_ACCOUNT_APPOINTMENT),
    delay(500),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.getAccountAppointment(action.appointmentId, user.accessToken,)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AppointmentActions.getAccountAppointmentSuccess(response.data))
          } else {
            return of(
              AlertActions.error(['Fetching account appointment failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const getClientAppointmentsEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.GET_CLIENT_APPOINTMENTS),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      const { clientId, start, end } = action
      return from(api.getClientAppointments(clientId, start, end, (action.pageNumber || 1), user.accessToken,)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AppointmentActions.getClientAppointmentsSuccess(response.data, (action.pageNumber || 1)))
          } else {
            return of(
              AlertActions.error(['Fetching client appointments failed! Please try again.'])
            )
          }
        })
      )
    })
  )


export const createAppointmentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.CREATE_APPOINTMENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.createAppointment(action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AppointmentActions.createAppointmentSuccess(response.data),
              AlertActions.success(['Successfully created an appointment!']),
            )
          } else {
            return of(
              AlertActions.error(['Creating appointment for client failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const createAppointmentSuccessEpic = (action$, store) =>
  action$.pipe(
    ofType(AppointmentTypes.CREATE_APPOINTMENT_SUCCESS, AppointmentTypes.UPDATE_APPOINTMENT_SUCCESS),
    tap(action => history.push(ROOT)),
    ignoreElements()
  )

export const updateAppointmentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.UPDATE_APPOINTMENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.updateAppointment(action.appointmentId, action.params, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AppointmentActions.updateAppointmentSuccess(response.data),
              AlertActions.success(['Successfully updated an appointment!']),
            )
          } else {
            return of(
              AlertActions.error(['Updating an appointment failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const cancelAppointmentEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AppointmentTypes.CANCEL_APPOINTMENT),
    filter(() => store.value.user.accessToken),
    switchMap((action) => {
      const { user } = store.value
      return from(api.cancelAppointment(action.appointmentId, user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AppointmentActions.cancelAppointmentSuccess(action.appointmentId),
              AlertActions.success(['Successfully cancelled an appointment!'])
            )
          } else {
            return of(
              AlertActions.error(['cancelling appointment failed! Please try again.'])
            )
          }
        })
      )
    })
  )