import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { ImageTag } from 'pages/Styles/Patient'
import { LightGrayLabel } from 'styles/GenericStyles';
import { getAge } from 'helpers/genericHelper'
import { UploadContainer } from 'components/Patient/Styles/PatientSidebarStyles'
import Dropzone from 'react-dropzone'
import moment from "moment";
import { Link } from 'react-router-dom'
import Avatar from 'assets/profilePicture.jpeg';
import LoadingView from "components/LoadingView.jsx";
import { ReactComponent as DeleteIcon } from 'images/svg/trash.svg';

export default class PatientBasicDetails extends React.Component {
  handleSubmit = (values) => {
    this.props.postClientPicture(this.props.clientID, { ...values,  clientId: this.props.clientID })
  }

  deleteClientPicture = () => {
    this.props.deleteClientPicture(this.props.clientID)
  }

  renderLoading = () => {
    return <LoadingView />;
  };
  
  renderImage = () => {
    const { postingClientPicture, client } = this.props
    const { profilePictureDownloadURL } = client
    return (
      <Formik
        initialValues={{ file: null }}
        onSubmit={this.handleSubmit}>
        {
          (formProps) => (
            <Form>
              <Col className='flex-grow-0'>
                { profilePictureDownloadURL && <DeleteIcon style={{ position: 'absolute', top: 35, right: 45 }} onClick={this.deleteClientPicture} /> }
                <ImageTag
                  src={profilePictureDownloadURL || Avatar}
                  width={116}
                  height={116}
                  onError={({ currentTarget }) => {
                    currentTarget.src=`${profilePictureDownloadURL}`
                  }}
                />
                
                <Dropzone accept="image/png, image/jpeg, image/jpg" onDrop={acceptedFiles => console.log('AP:' + acceptedFiles)}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}>
                      <input
                        name='file'
                        {...getInputProps()}
                        onChange={(event) => {
                          formProps.setFieldValue("file", event.target.files[0]);
                          this.handleSubmit({ file: event.target.files[0] });
                        }}
                      />
                      <UploadContainer>{ postingClientPicture ? this.renderLoading() : 'Upload'}</UploadContainer>
                    </div>
                  )}
                </Dropzone>
              </Col>
            </Form>
          )
        }
      </Formik>
    )
  }

  render () {
    const { client, clientID } = this.props    
    
    let address = [
      client.addressLine1,
      client.addressLine2,
      client.city,
      client.state,
      client.zipcode,
      client.country
    ].filter(function (val) {return val;}).join(', ');

    let gender = client.gender
    let pronoun = client.pronoun
    let dob = client.dob
    let firstName = client.firstName
    let middleName = client.middleName || ''
    let lastName = client.lastName
    let phoneNumber = client.phoneNumber

    if (gender && pronoun) {
      gender = gender + ' (' + pronoun + ')'
    }

    if (!gender) {
      gender = pronoun
    }

    return (
      <Row className='border shadow rounded mb-3 d-flex'>
        { this.renderImage() }
        <Col>
          <Row className='justify-content-around pt-3 pb-3'>
            <Col>
              <LightGrayLabel>Name</LightGrayLabel>
              <div><Link to={`/clients/${clientID}`}>{firstName + ' ' + middleName + ' ' + lastName}</Link> </div>
            </Col>
            <Col>
              <LightGrayLabel>Age</LightGrayLabel>
              { dob && <div>{  getAge(dob) } years</div> }
            </Col>
            <Col>
              <LightGrayLabel>Mobile </LightGrayLabel>
              <div>{phoneNumber}</div>
            </Col>

            <Col>
              <LightGrayLabel>EJAS Number </LightGrayLabel>
              <div>{client.ejasNumber}</div>
            </Col>
          </Row>
          <Row className='justify-content-around pb-3'>
            <Col>
              <LightGrayLabel>ClientID</LightGrayLabel>
              <div>{client.clientShortId}</div>
            </Col>
            <Col>
              <LightGrayLabel>Gender</LightGrayLabel>
              <div>{gender}</div>
            </Col>
            <Col>
              <LightGrayLabel>DOB</LightGrayLabel>
              <div>{dob && moment(dob).format('MM-DD-YYYY')}</div>
            </Col>
            <Col>
              <LightGrayLabel>Address </LightGrayLabel>
              <div>{address}</div>
            </Col>
          </Row>
        </Col>
        <Link className='p-3' onClick={this.props.editClient} >Edit</Link>
      </Row>
    )
  }
}