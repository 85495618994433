import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Development extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Development:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Development: </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                Are there special, unusual, or traumatic circumstances that
                affected your development?:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.underSpecialCircumstances)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>If Yes, Please Describe</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.specialCircumstances}</label>
            </Col>
          </Row>
          <hr />
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>History of abuse?</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{humanFormat(data.hasHistoryOfAbuse)}</label>
            </Col>
          </Row>
          <Col className="col-12 p-0">
            <label className="input-label mb-2">If Yes, Which Type(S)?</label>
          </Col>
          <Row className="row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
            {/* <Col className="col-12 d-flex p-0"> */}
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Sexual</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isSexualAbuse)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Physical</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isPhysicalAbuse)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Verbal</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isVerbalAbuse)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Emotional</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isEmotionalAbuse)}</label>
              </Col>
            </Row>
            {/* </Col> */}
          </Row>
          <Col className="col-12 p-0">
            <label className="input-label mb-2">
              If Yes, Was The Abuse As A:
            </label>
          </Col>
          <Row className="row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
            <Col className="col-12 d-flex p-0">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Victim</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isVictim)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Perpetrator</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.isPerpetrator)}</label>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr />
          <Col className="col-12 p-0">
            <label className="input-label mb-2">Childhood Issues?</label>
          </Col>
          <Row className="row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
            <Col className="col-12 d-flex p-0">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Neglect</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.neglected)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Inadequate nutrition</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.inadequateNutrition)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Other</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(data.childHoodIssuesOther)}</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Other (specify)</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.otherChildHoodIssues}</label>
            </Col>
          </Row>

          <hr />
          <Row className="align-items-center mt-3 mb-4 w-100">
            <Col className="col-12">
              <label className="input-label mb-2">
                In your life, have you ever had any experience that was so
                frightening, horrible, or upsetting that in the past month you:
              </label>
            </Col>
            <Col className="col-12 p-0">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Had nightmares about it, or thought about it when you did
                    not want to?
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.hadNightMares)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Tried hard not to think about it, or went out of your way to
                    avoid situations that reminded you of it?
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.triedAvoidingSituations)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Were constantly on guard, watchful, or easily startled?
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.isOnConstantGuard)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>
                    Felt numb or detached from others, activities, or your
                    surroundings?
                  </LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{humanFormat(data.hasFeltNumb)}</label>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
