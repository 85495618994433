import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`
    .react-calendar.custom-react-calendar {
      border: none;
      width: 60%;
      min-width: 360px;

      & .tile.custom-tile {
        font-family: PoppinsRegular !important;
      }

      & .react-calendar__month-view {
        & abbr[title] {
          text-decoration: none;
        }
      }

    }
    .calendar-div {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 8px #00000029;
      border-radius: 8px;
      max-width: fit-content;
    }
    .slotsDiv {
      height: 50vh;
      min-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1rem;
      & button {
        font-weight: 600;
        min-width: 150px;
      }
    }
`