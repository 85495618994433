import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Education extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Education:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Mental Health &gt; Personal History &gt; Education:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="align-items-center mb-4">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Years Of Education</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.yearsOfEducation}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Currently enrolled in school?</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.isCurrentlyEnrolledInSchool)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>High school graduate/GED</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.isHighSchoolGraduate)}</label>
            </Col>
          </Row>
          <Row className="row-cols-xs-1 row-cols-sm-7 row-cols-md-1 row-cols-lg-4">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Vocational</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isVocational)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>No. of years</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.numberOfVocationalYears}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Graduated:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.vocationalFinished)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Major</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.vocationalMajor}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>College:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isCollege)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>No. of years</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.numberOfCollegeYears}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Graduated:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.collegeFinished)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Major</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.collegeMajor}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Graduate:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{isBoolean(data.isGraduate)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>No. of years</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.numberOfGraduateYears}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Graduated:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.graduationFinished)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Major</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.graduationMajor}</label>
              </Col>
            </Row>

            <Row className="pl-3 mb-3 ">
              <Col className="p-0 col-10">
                <Col className="col-12">
                  <LightGrayLabel>Other Training</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.otherTraining}</label>
                </Col>
              </Col>
            </Row>
            <Row className="mb-3 pl-3">
              <Col className="p-0 col-12">
                <Col className="col-12">
                  <LightGrayLabel>Special Circumstances</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{data.specialCircumstances}</label>
                </Col>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    );
  }
}
