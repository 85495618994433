import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Chemical extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Chemical Use History:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>
            Mental Health &gt; Personal History &gt; Chemical Use History:
          </Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div className="w-100">
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Do you have any current/historical substance or alcohol use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.currentOrHistoricalAlcoholOrSubstanceUse)}
                </label>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>If, yes</LightGrayLabel>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Have you ever felt you ought to cut down on your alcohol or
                  substance use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.feltToCutDownAlcoholOrSubstanceUse)}
                </label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Have people annoyed you by criticizing your alcohol or
                  substance use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(
                    data.havePeopleCriticizedForAlcoholOrSubstanceUse
                  )}
                </label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Have you ever felt bad or guilty about your alcohol or
                  substance use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(data.feltGuiltyAboutAlcoholOrSubstanceUse)}
                </label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0 ">
              <Col className="col-12">
                <LightGrayLabel>
                  Have you ever used alcohol or substances first thing in the
                  morning to steady your nerves or to get rid of a hangover?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">
                  {humanFormat(
                    data.usedAlcoholOrSubstanceUseFirstThingInMorning
                  )}
                </label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Do you smoke, vape, or chew?</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.smokeVapeOrChew)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Do you use pain medication?</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.usePainMedication)}</label>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <hr />
            </Col>
          </Row>

          <Row className="mb-3 ml-3">
            <Table className="w-100 p-0" borderless responsive>
              <thead>
                <th className="align-top" style={{ width: "10%" }}><LightGrayLabel>
                  Substance</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "10%" }}><LightGrayLabel>
                  Age Of 1St Use</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "30%" }}><LightGrayLabel>
                  Number Of Days Used In The Last 30 Days</LightGrayLabel>
                </th>
                <th className="align-top" style={{ width: "40%" }}><LightGrayLabel>
                  Specific Drug, If Applicable, And Amount Used</LightGrayLabel>
                </th>
                <th
                  className="align-top col-md-12"
                  style={{ width: "10%" }}
                ></th>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0 py-0">
                    <Col className="col-12">
                      <label className="input-label">{data.name}</label>
                    </Col>
                  </td>
                  <td className="py-0">
                    <Col className="col-12">
                      <label className="input-label">{data.ageWhenFirstUse}</label>
                    </Col>
                  </td>
                  <td className="py-0">
                    <Col className="col-12">
                      <label className="input-label">{data.numberOfTimesUsedInLast30Days}</label>
                    </Col>
                  </td>
                  <td className="py-0">
                    <Col className="col-12">
                      <label className="input-label">{data.specifics}</label>
                    </Col>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>

          <Row>
            <Col className="p-0">
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  What Is The Date Of Your Most Current Use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentUseDate}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Substance</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentUseSubstance}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Amount:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentUseSubstanceAmount}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Method Of Use:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentUseSubstanceMethod}</label>
              </Col>
            </Col>
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Frequency Of Use:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.recentUseSubstanceFrequency}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Does your use affect your family and friends?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.effectsFamily)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 p-0">
              <Col className="col-12 ">
                <LightGrayLabel>If Yes, Describe:</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.effectsFamilyDescription}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Do you use alcohol or substances to cope with mental health
                  issues or other stressors?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.usedToCopeMentalHealthIssues)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Have you increased your alcohol or substance use intake in the
                  last six months?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.usageIncreasedInLast6Months)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Does anyone in your family have current/historical alcohol or
                  substance use?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.familyUsageOfSubstance)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 ml-3 p-0">
              <label
                className="input-label mb-2"
                htmlFor="familyUseCurrentOrHistorical"
              >
                If Yes, Describe:
              </label>
            </Col>
            {data &&
          data.familyMembers &&
          data.familyMembers.map((familyMember) => (
            <Col className="col-12 p-0 d-flex align-items-center">
              <Col className="col-4 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Member</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{familyMember.member}</label>
                </Col>
              </Col>

              <Col className="col-4 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Substance</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{familyMember.substance}</label>
                </Col>
              </Col>
              <Col className="col-4 p-0">
                <Col className="col-12">
                  <LightGrayLabel>Usage Type</LightGrayLabel>
                </Col>
                <Col className="col-12">
                  <label className="input-label">{isBoolean(familyMember.usageType)}</label>
                </Col>
              </Col>
   
            </Col>
          ))}

            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Describe</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.symptompsDescription}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 mt-2">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>
                  Do you believe that your alcohol or substance use has created
                  problems in your life, job, and/or relationships?
                </LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.hasSubstanceCreatedProblems)}</label>
              </Col>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 p-0">
              <Col className="col-12">
                <LightGrayLabel>Describe</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{data.problemsDescription}</label>
              </Col>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
