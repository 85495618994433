import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { TextMessage } from 'components/Fields/TextMessage'
import { Select } from 'components/Fields/Select'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col, Table } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: "Yes", value: true },
  { key: "No", value: false },
];

const familySubstanceUseOptions = [
  { key: 'Current', value: true },
  { key: 'Historical', value: false }
]

const substanceOptions = [
  { label: "Select Item", value: "" },
  { label: "Alcohol", value: "Alcohol" },
  { label: "Tobacco", value: "Tobacco" },
];

export default class ChemicalUseHistory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    const substances = values && values.substances && values.substances.filter((c) => c)
    const familyMembers = values && values.familyMembers && values.familyMembers.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {...values, substances, familyMembers, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.chemicalUseHistory)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm
                handleSubmit={this.handleSubmit}
                updatingForm={updatingForm} disabled={!write}
              >
                <div className="w-100">
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="anySubstanceOrAlcoholUse"
                      >
                        Do you have any current/historical substance or alcohol
                        use?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="currentOrHistoricalAlcoholOrSubstanceUse"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <label className="input-label">If Yes:</label>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="haveYouFeltYouOughtToCutDownAlcohol"
                      >
                        Have you ever felt you ought to cut down on your alcohol
                        or substance use?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="feltToCutDownAlcoholOrSubstanceUse"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="havePeopleAnnoyedYouByCriticizing"
                      >
                        Have people annoyed you by criticizing your alcohol or
                        substance use?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="havePeopleCriticizedForAlcoholOrSubstanceUse"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="haveYouEverFeltBadOrGuilty"
                      >
                        Have you ever felt bad or guilty about your alcohol or
                        substance use?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="feltGuiltyAboutAlcoholOrSubstanceUse"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="hadAlcoholToSteadyYourNerves"
                      >
                        Have you ever used alcohol or substances first thing in
                        the morning to steady your nerves or to get rid of a
                        hangover?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="usedAlcoholOrSubstanceUseFirstThingInMorning"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="doYouSmokeVapeOrKnow"
                      >
                        Do you smoke, vape, or chew?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="smokeVapeOrChew"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="doYouUsePainMedicationForAlcohol"
                      >
                        Do you use pain medication?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="usePainMedication"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Table className="w-100 pl-0 ml-0" borderless responsive>
                      <thead>
                        <th className="pl-0 align-top" style={{ width: "20%" }}>
                          Substance
                        </th>
                        <th className="align-top" style={{ width: "10%" }}>
                          Age Of 1St Use
                        </th>
                        <th className="align-top" style={{ width: "20%" }}>
                          Number Of Days Used In The Last 30 Days
                        </th>
                        <th className="align-top" style={{ width: "40%" }}>
                          Specific Drug, If Applicable, And Amount Used
                        </th>
                        <th
                          className="align-top col-md-12"
                          style={{ width: "10%" }}
                        ></th>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="substances"
                          render={(arrayHelpers) => (
                            <>
                              {values.substances &&
                              values.substances.length > 0 ? (
                                values.substances.map((substance, index) => (
                                  <tr key={index}>
                                    <td className="pl-0 py-0">
                                      <Select
                                        name={`substances.${index}.name`}
                                        className="custom-select"
                                      >
                                        {substanceOptions.map(
                                          ({ label, value }) => (
                                            <option key={value} value={value}>
                                              {label}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </td>
                                    <td className="py-0">
                                      <TextInput
                                        name={`substances.${index}.ageWhenFirstUse`}
                                        placeholder="Age"
                                      />
                                    </td>
                                    <td className="py-0">
                                      <TextInput
                                        name={`substances.${index}.numberOfTimesUsedInLast30Days`}
                                        placeholder="number of times"
                                        type="number"
                                      />
                                    </td>
                                    <td className="py-0">
                                      <TextInput
                                        name={`substances.${index}.specifics`}
                                        placeholder="Specify"
                                      />
                                    </td>
                                    <Col className='col-md-12'>
                                      <td className="d-flex p-0">
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger mr-3"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          {" "}
                                          Remove{" "}
                                        </button>
                                        {index === 0 && (
                                          <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                              arrayHelpers.insert(index, "")
                                            }
                                          >
                                            {" "}
                                            Add{" "}
                                          </button>
                                        )}
                                      </td>
                                    </Col>
                                  </tr>
                                ))
                              ) : (
                                <Col className="col-12 d-flex mb-3">
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    {" "}
                                    Add Substance
                                  </button>
                                </Col>
                              )}
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                  </Row>

                  <Row>
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-lg-12 col-md-12 p-0">
                      <TextInput
                        type="date"
                        name="recentUseDate"
                        className="w-75"
                        labelClassName="mb-2 font-weight-bold"
                        label="What is the date of your most current use?"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        className="mr-3"
                        labelClassName="mb-2"
                        name="recentUseSubstance"
                        placeholder="Substance"
                        label="Substance"
                        type="text"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        className='mr-3'
                        labelClassName='mb-2'
                        name='recentUseSubstanceAmount'
                        placeholder='Amount'
                        label='Amount:'
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        className="mr-3"
                        labelClassName="mb-2"
                        name="recentUseSubstanceMethod"
                        placeholder="Method Of Use"
                        label="Method Of Use:"
                        type="text"
                      />
                    </Col>
                    <Col className="col-6 p-0">
                      <TextInput
                        labelClassName="mb-2"
                        name="recentUseSubstanceFrequency"
                        placeholder="Frequency Of Use"
                        label="Frequency Of Use:"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="doesYouruseAffectYourFamily"
                      >
                        Does your use affect your family and friends?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="effectsFamily"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col className="col-10 p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="If Yes, Describe:"
                        name="effectsFamilyDescription"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="usingToCopeWithMentalIssues"
                      >
                        Do you use alcohol or substances to cope with mental
                        health issues or other stressors?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="usedToCopeMentalHealthIssues"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="haveYouIncreasedIntakeInLastSixMonths"
                      >
                        Have you increased your alcohol or substance use intake
                        in the last six months?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="usageIncreasedInLast6Months"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="doesYourFamilyHaveSubstanceUse"
                      >
                        Does anyone in your family have current/historical
                        alcohol or substance use?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="familyUsageOfSubstance"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="familyUseCurrentOrHistorical"
                      >
                        If Yes, Describe:
                      </label>
                    </Col>
                    <Col className="p-0 col-10">
                      <TextMessage
                        name="symptompsDescription"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                    <FieldArray
                      name="familyMembers"
                      render={(arrayHelpers) => (
                        <>
                          {values.familyMembers &&
                          values.familyMembers.length > 0 ? (
                            values.familyMembers.map((familyMember, index) => (
                              <Col
                                key={index}
                                className="col-12 p-0 d-flex align-items-center justify-content-between"
                              >
                                <TextInput
                                  className="mr-3"
                                  name={`familyMembers.${index}.member`}
                                  placeholder="Member"
                                />
                                <TextInput
                                  name={`familyMembers.${index}.substance`}
                                  placeholder="Substance"
                                />
                                <RadioButton
                                  name={`familyMembers.${index}.usageType`}
                                  options={familySubstanceUseOptions}
                                  onChange={handleChange}
                                />

                                <Col>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger ml-3 mr-3"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    {" "}
                                    Remove{" "}
                                  </button>
                                  {index === 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      }
                                    >
                                      {" "}
                                      Add{" "}
                                    </button>
                                  )}
                                </Col>
                              </Col>
                            ))
                          ) : (
                            <Col className="col-12 d-flex mb-3">
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {" "}
                                Add Members
                              </button>
                            </Col>
                          )}
                        </>
                      )}
                    />

                  
                  </Row>
                  <Row className="align-items-center mb-3 mt-2">
                    <Col className="col-12 p-0">
                      <label
                        className="input-label mb-2"
                        htmlFor="doYouBeliveSubstanceUseCreatedYouProblems"
                      >
                        Do you believe that your alcohol or substance use has
                        created problems in your life, job, and/or
                        relationships?
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        name="hasSubstanceCreatedProblems"
                        options={yesOrNoOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col className="col-10 p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="If Yes, Describe:"
                        name="problemsDescription"
                        placeholder="Describe"
                        size="md"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
