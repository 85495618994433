import React from 'react';
import RouterConfig from 'navigation/RouterConfig';
import { Router } from 'react-router-dom';
import { history } from 'navigation/history'
import LoadingView from 'components/LoadingView.jsx'
import { connect } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from "styles/GlobalStyles"
import { ThemeProvider } from "styled-components"
import { theme } from 'themes'

export class App extends React.Component {
  render () {
    const { loading, loggedIn } = this.props
    if (loading) {
      return <LoadingView /> 
    }

    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <RouterConfig loggedIn={loggedIn} />
          </Router>
        </ThemeProvider>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.loading,
    loggedIn: state.auth.loggedIn
  }
}

export default connect(mapStateToProps)(App);