import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel, ViewHeading } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class AssessedEmploymentBarriers extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Assessed Employment Barriers:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>BFET &gt; Assessed Employment Barriers:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <div>
          <Col className="col-12 mb-2">
            <ViewHeading>CLIENT EMPLOYMENT BARRIERS</ViewHeading>
          </Col>

          <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-0">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Lack of Skills</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label input-label">{isBoolean(data.lackOfSkills)}</label>
                <label className="input-label">{data.servicesToAddressLackOfSkills}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  High School Diploma or Equivalency
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(
                    data.highSchoolDiplomaOrEquivalency
                  )}
                </label>
                <label className="input-label">{data.servicesToAddressHighSchoolDiplomaOrEquivalency}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Lack of Qualifications for Desired Employment
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(
                    data.lackOfQualificationsForDesiredEmployment
                  )}
                </label>
                <label className="input-label">{data.servicesToAddressLackOfQualificationsForDesiredEmploymen}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Lack of Employment History</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.lackOfEmploymentHistory)}
                </label>
                <label className="input-label">{data.servicesToAddressLackOfEmploymentHistory}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Limited English Proficiency</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.limitedEnglishProficiency)}
                </label>
                <label className="input-label">{data.servicesToAddressLimitedEnglishProficiency}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Physical Health Incapacities</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.physicalHealthIncapacities)}
                </label>
                <label className="input-label">{data.servicesToAddressPhysicalHealthIncapacities}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Mental Health Incapacities</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.mentalHealthIncapacities)}
                </label>
                <label className="input-label">{data.servicesToAddressMentalHealthIncapacities}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Substance Use</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.substanceUse)}
                </label>
                <label className="input-label">{data.servicesToAddressSubstanceUse}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Criminal Record affecting Employment
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(
                    data.criminalRecordAffectingEmployment
                  )}
                </label>
                <label className="input-label">{data.servicesToAddressCriminalRecordAffectingEmployment}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Required Employment Verification (i.e. ID / Birth Certificate,
                  Driver’s License, SSN Card)
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(
                    data.requiredEmploymentVerification
                  )}
                </label>
                <label className="input-label">{data.servicesToAddressRequiredEmploymentVerification}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>
                  Lack of necessary resources (i.e. childcare, transportation,
                  personal hygiene)
                </LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.lackOfNecessaryResources)}
                </label>
                <label className="input-label">{data.servicesToAddressLackOfNecessaryResources}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Housing</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.housing)}
                </label>
                <label className="input-label">{data.servicesToAddressHousing}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Other</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="mr-3 input-label">
                  {isBoolean(data.others)}
                </label>
                <label className="input-label">{data.servicesToAddressOthers}</label>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    );
  }
}
