import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput } from 'components/Fields/TextInput'
import { TextMessage } from 'components/Fields/TextMessage'
import { RadioButton } from 'components/Fields/RadioButton'
import { BOOLEAN_CHECKBOX_OPTIONS } from 'config/CONSTANTS'
import BaseForm from 'components/Patient/BaseForm'
import { CLIENT_REENTRY_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';
import { Select } from "components/Fields/Select";

export default class EmploymentSearchForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    const workingDays = values.workingDays && values.workingDays.filter((c) => c)
    const nonWorkingDays = values.nonWorkingDays && values.nonWorkingDays.filter((c) => c)
    this.props.onUpdate(this.props.clientID, {...values, workingDays, nonWorkingDays, clientId: this.props.clientID }, CLIENT_REENTRY_FORM_TYPES.employmentSearchInformation)
  }
  
  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row>
                    <Col className='col-12'>
                      <label className='input-label mb-2' htmlFor='jobType'>The kind of job I want is:</label>
                    </Col>
                    <Col className='col-12 col-lg-8'>
                      <TextInput name='jobType' type='text' placeholder='Job'/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='col-12'>
                      <label className='input-label mb-2' htmlFor='lastTimeJobSearchedAt'>The last time I looked for a job was:</label>
                    </Col>
                    <Col className='col-12 col-lg-8'>
                      <TextInput name='lastTimeJobSearchedAt' type='date' placeholder='Last Job'/>
                    </Col>
                  </Row>
                  <RadioButton
                    className='mb-3'
                    label='I have a current resume:'
                    name='hasResume'
                    labelClassName='input-label mb-2 d-block'
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <RadioButton
                    className='mb-3'
                    label='I have a current list of references:'
                    name='hasListOfReferences'
                    labelClassName='input-label mb-2 d-block'
                    options={BOOLEAN_CHECKBOX_OPTIONS}
                    onChange={handleChange}
                  />
                  <TextMessage labelClassName='input-label mb-2' className='mr-3 mb-3' label="I have missed past work because:" name='missedPreviousWorkReason' type='textarea' placeholder='Description' rows='4'/>
                  <Row className='row-cols-xs-1 row-cols-sm-7 mb-3'>
                    <Col className='col-12 mb-2'>
                      <label className='input-label'>I prefer to work the following hours on these days (such as 8am to 5pm or 11pm to 6am, etc.):</label>
                    </Col>
                    <Col className='col-12'>
                      <FieldArray
                        name="workingDays"
                        render={(arrayHelpers) => (
                          <>
                            {values.workingDays && values.workingDays.length > 0 ? (
                              values.workingDays.map((wk, index) => (
                                <Col key={index} className="col-12 d-flex">
                                  <Select
                                    name={`workingDays.${index}.day`}
                                    className="custom-select col-2"
                                  >
                                    <option>Select</option>
                                    <option value='monday'>Monday</option>
                                    <option value='tuesday'>Tuesday</option>
                                    <option value='wednesday'>Wednesday</option>
                                    <option value='thursday'>Thursday</option>
                                    <option value='friday'>Friday</option>
                                    <option value='saturday'>Saturday</option>
                                    <option value='sunday'>Sunday</option>
                                  </Select>

                                  <TextInput
                                    name={`workingDays.${index}.startTime`}
                                    type="time"
                                    className='col-2'
                                  />

                                  <TextInput
                                    name={`workingDays.${index}.endTime`}
                                    type="time"
                                    className='col-2'
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-outline-danger ml-3 mr-3 mb-3"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    {" "}
                                    Remove{" "}
                                  </button>
                                  {index === 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary mb-3"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      }
                                    >
                                      {" "}
                                      Add{" "}
                                    </button>
                                  )}
                                </Col>
                              ))
                            ) : (
                              <Col className="col-12 d-flex mb-3">
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {" "}
                                  Add working days
                                </button>
                              </Col>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='col-12 mb-2'>
                      <label className='input-label' htmlFor='hoursDays'>I prefer NOT to work the following: </label>
                    </Col>
                    <Col className='col-12'>
                      <FieldArray
                        name="nonWorkingDays"
                        render={(arrayHelpers) => (
                          <>
                            {values.nonWorkingDays &&
                            values.nonWorkingDays.length > 0 ? (
                              values.nonWorkingDays.map((wk, index) => (
                                <Col key={index} className="col-12 d-flex">

                                  <Select
                                    name={`nonWorkingDays.${index}.day`}
                                    className="custom-select col-2"
                                  >
                                    <option>Select</option>
                                    <option value='monday'>Monday</option>
                                    <option value='tuesday'>Tuesday</option>
                                    <option value='wednesday'>Wednesday</option>
                                    <option value='thursday'>Thursday</option>
                                    <option value='friday'>Friday</option>
                                    <option value='saturday'>Saturday</option>
                                    <option value='sunday'>Sunday</option>
                                  </Select>

                                  <TextInput
                                    name={`nonWorkingDays.${index}.startTime`}
                                    type="time"
                                    className="col-2"
                                  />

                                  <TextInput
                                    name={`nonWorkingDays.${index}.endTime`}
                                    type="time"
                                    className="col-2"
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-outline-danger ml-3 mr-3 mb-3"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    {" "}
                                    Remove{" "}
                                  </button>
                                  {index === 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary mb-3"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      }
                                    >
                                      {" "}
                                      Add{" "}
                                    </button>
                                  )}
                                </Col>
                              ))
                            ) : (
                              <Col className="col-12 d-flex mb-3">
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {" "}
                                  Add working days
                                </button>
                              </Col>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  <TextMessage labelClassName='mb-2' label='Because: ' name='cannotWorkReason' type='textarea' placeholder='Description' size="md" rows='4'/>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    ) 
  }
}

/*
  all dayTime ranges are not matching
  I cannot work the following hours/days:
*/