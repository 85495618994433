import React from 'react';
import { connect } from 'react-redux'
import UserActions from 'redux/UserRedux'
import LoadingView from 'components/LoadingView.jsx'
import Avatar from 'assets/profilePicture.jpeg';

import { ReactComponent as EditIcon } from '../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../images/svg/ic_delete.svg';
import { ReactComponent as LeftArrow } from '../images/arrows/svg/left-arrow.svg';
import { ReactComponent as RightArrow } from '../images/arrows/svg/right-arrow.svg';

import { ReactComponent as LeftArrowDisabled } from '../images/arrows/svg/left-arrow-disable.svg';

import { ReactComponent as DownArrow } from '../images/arrows/svg/down-arrow.svg';
import { ReactComponent as UpArrow } from '../images/arrows/svg/up-arrow.svg';

import { ReactComponent as DownArrowDefault } from '../images/arrows/svg/down-arrow-default.svg';
import { ReactComponent as UpArrowDefault } from '../images/arrows/svg/up-arrow-default.svg';

import { ReactComponent as RightArrowDisabled } from '../images/arrows/svg/right-arrow-disable.svg';
import { Pagination } from 'react-bootstrap';
import { PTh, PTr, PTd, PTable, IconButton, CustomPagination } from './Styles/PatientListStyles';
import { CLIENTS } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import { ImageCircleTag } from 'pages/Styles/Patient'
import moment from "moment";

export class PatientList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showAccountUpdateModal: false,
      showAccountUpdatePasswordModal: false,
      currentPage: props.currentpage,
      totalPages: props.totalPages,
      sortKey: props.sortKey || 'lastName',
      orderBy: props.orderBy || 'ASC'
    }
  }

  componentDidMount () {
    
  }

  renderLoading = () => {
    return <LoadingView />
  }

  sortLastName = () => {
    this.setState({
      currentPage: 1,
      sortKey: 'lastName',
      orderBy: 'ASC'
    }, () => {
      this.props.getClients(1, 'lastName', 'ASC')
    });
  }

  sortFirstName = () => {
    this.setState({
      currentPage: 1,
      sortKey: 'lastName',
      orderBy: 'DESC'
    }, () => {
      this.props.getClients(1, 'lastName', 'DESC')
    });
  }

  renderTable () {
    const { clients, showActionsControl } = this.props
    const { orderBy } = this.state
    
    const list = clients && clients.map((client, index) => {
      const picture = client.profilePictureDownloadURL
      let address = [client.addressLine1, client.addressLine2, client.city, client.state, client.zipcode, client.country].filter(function (val) {return val;}).join(', ');
      
      return (
        <PTr key={index
        }>
          <PTd>
              <div className="ptd-name-container">
                <ImageCircleTag className='mr-2' src={picture || Avatar} width={50} height={50} />
                <span className="name"><Link to={`${CLIENTS}/${client.clientId}/reentry/INDENTIFYING_INFORMATION`}>{client.firstName + ' ' + (client.middleName || '') + ' ' + client.lastName }</Link></span>
              </div>
            </PTd>
            <PTd>{client.clientShortId}</PTd>
            <PTd>{client.phoneNumber}</PTd>
            <PTd className='text-nowrap'>{client.dob && moment(client.dob).format('MM-DD-YY')}</PTd>
            <PTd>{client.ejasNumber}</PTd>
            <PTd>{address}</PTd>
            { showActionsControl && <PTd>
              <div className='d-flex'>

                <Link to={`${CLIENTS}/${client.clientId}/reentry/INDENTIFYING_INFORMATION`}>
                  <IconButton className='mr-2'>
                    <EditIcon />
                  </IconButton>
                </Link>
                
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </div>
            </PTd> }
        </PTr>
      )
    })


    return <PTable variant='hover'  className='table-borderless' responsive>
      <thead>
        <tr>
          <PTh className='col-2'> {orderBy === 'DESC' ? <UpArrow onClick={this.sortFirstName}  /> : <UpArrowDefault onClick={this.sortFirstName} /> } Full Name  {orderBy === 'ASC' ? (<DownArrow onClick={this.sortLastName} />) : <DownArrowDefault onClick={this.sortLastName} /> } </PTh>
          <PTh className='col-1'>Client ID</PTh>
          <PTh className='col-1'>Phone Number</PTh>
          <PTh className='col-1'>DOB </PTh>
          <PTh className='col-1'>EJAS number</PTh>
          <PTh className='col-4'>Address</PTh>
          { showActionsControl && <PTh className='col-1'>Action</PTh> }
        </tr>
      </thead>
      <tbody>
        { list }
      </tbody>
    </PTable>
  }

  getPaginationLink(number, sortKey, orderBy) {
    const { currentPage } = this.state;
    return (
      <Pagination.Item key={number} active={number === currentPage} onClick={this.handlePaginationLinkClick.bind(this, number, sortKey, orderBy)}>
        {number}
      </Pagination.Item>
    )
  }

  handlePaginationLinkClick (pageNumber, sortKey, orderBy) {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.props.getClients(pageNumber, sortKey, orderBy)
    });
  }

  renderPagination () {
    const { currentPage, totalPages, sortKey, orderBy } = this.state;
    if (totalPages < 2) return null;
    const DEFAULT_PAGINATION_LENGTH = 4;
    const currentPageRange = DEFAULT_PAGINATION_LENGTH * Math.floor((currentPage - 1) / DEFAULT_PAGINATION_LENGTH);
    let rangeStart = currentPageRange + 1;
    let rangeEnd = Math.min(rangeStart + DEFAULT_PAGINATION_LENGTH - 1, totalPages);
    const items = [];
    while (rangeStart <= rangeEnd) {
      items.push(this.getPaginationLink(rangeStart, sortKey, orderBy));
      rangeStart++;
    }
    return <CustomPagination className='justify-content-end w-100 mr-3'>
            <Pagination.Item disabled={currentPage === 1} onClick={this.handlePaginationLinkClick.bind(this, currentPage - 1, sortKey, orderBy)}>
              {currentPage === 1 ? <LeftArrowDisabled /> : <LeftArrow />}
            </Pagination.Item>
            {items}
            <Pagination.Item disabled={currentPage >= totalPages} onClick={this.handlePaginationLinkClick.bind(this, currentPage + 1, sortKey, orderBy)}>
              {currentPage >= totalPages ? <RightArrowDisabled /> : <RightArrow />}
            </Pagination.Item>
          </CustomPagination>;
  }

  render () {
    return (
      <>
        {this.renderTable()}
        {this.renderPagination()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fetchingPatient: state.user.fetchingPatient,
    patient: state.user.patient
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPatient: (userId) => dispatch(UserActions.getPatient(userId)),
    updatePatient: (userId, user) => dispatch(UserActions.updateUserInfo(userId, user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);