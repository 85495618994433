const Strings = {
  "prompt": {
    "message": "There are unsaved changes. Are you sure you want to leave this page?",
  },
  "permissions": {
    "message": "No Permisssions, Please contact your administrator."
  },
  "ClientFormCategories": {
    "inTake": "Intake Form",
    "reEntry": "Re-entry",
    "bfet": "BFET",
    "mentalHealth": "Mental Health",
    "dvr": "DVR"
  },

  'inTake': {
    "identifyinhInformation": "Identifying Information",
    "family": "Family",
    "income": "Income",
    "housing": "Housing",
    "personalHealthInformation": "Personal Health Information",
    "legalHistory": "Legal History",
    "incarcerationReentry": "Incarceration ReEntry",
    "transportation": "Transportation",
    "immediateNeeds": "Immediate Needs",
    "employmentSearchInformation": "Employment Search Information",
    "skillsAndAbilities": "Skills And Abilities"
  },

  "bfet": {
    "employmentGoals": "Employment Goals",
    "skillsAndQualification": "Skills And Qualification",
    "assessedEmploymentBarriers": "Assessed Employment Barriers",
    "employmentPlan": "Employment Plan",
    "declarationAndSignature": "Declaration And Signature",
    "employmentHistory": "Employment History"
  }
}

export default Strings