import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextInput } from 'components/Fields/TextInput'
import { Checkbox } from 'components/Fields/Checkbox'
import BaseForm from 'components/Patient/BaseForm'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

export default class PrimaryReasonsForSeekingServices extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.primaryReasonsForSeekingServices)
  }

  render() {

    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
        
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />

            <Form className='row mr-1'>
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <Row className='row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3'>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasAngerManagement'> Anger Management</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasDepression'> Depression</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasMentalConfusion'> Mental confusion</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='consumesAlcoholOrDrugs'> Alcohol/drugs</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasAnxiety'> Anxiety</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasEatingDisorder'> Eating Disorder</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasSexualConcerns'> Sexual concerns</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasAddictiveBehaviors'> Addictive behaviors</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasCoping'> Coping</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasFearOrphobias'> Fear/phobias</Checkbox>
                  </Col>
                  <Col className='p-0 mb-2'>
                    <Checkbox labelClassName='text-wrap' name='hasSleepingProblems'> Sleeping problems</Checkbox>
                  </Col>
                  <Col className='p-0 col-xs-12 col-md-8 col-lg-12 mt-2'>
                    <TextInput labelClassName='input-label mb-2' label='Other Mental Health Concerns' name='otherMentalHealthConcerns' type='other' placeholder='(specify)'/>
                  </Col>
                </Row>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}