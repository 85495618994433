import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { Checkbox } from "components/Fields/Checkbox";
import { RadioButton } from "components/Fields/RadioButton";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_REENTRY_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class HousingForm extends React.Component {
  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_REENTRY_FORM_TYPES.housing
    );
  };

  render() {
    const homeLessNessRadioButton = [
      {
        key: "Not homeless at present",
        value: "Not_homeless_at_present",
      },
      {
        key: "Less than one month",
        value: "Less_than_one_month",
      },
      {
        key: "At least one month, but less than 6 months",
        value: "At_least_one_month_but_less_than_6_months",
      },
      {
        key: "At least 6 months but less than 1 year",
        value: "At_least_6_months_but_less_than_1_year",
      },
      {
        key: "At least 1 year but less than 2 years",
        value: "At_least_1_year_but_less_than_2_years",
      },
      {
        key: "At least 2 years, but less than 3 years",
        value: "At_least_2_years_but_less_than_3_years",
      },
      {
        key: "Three years or more",
        value: "Three_years_or_more",
      },
      {
        key: "Others",
        value: "Others",
      },
    ];

    const housingSubsidyOptions = [
      { key: "Yes", value: true },
      { key: "No", value: false },
    ];

    const { data, updatingForm, read, write } = this.props;

    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  <Row className="row-cols-xs-1">
                    <Col md={12} className="p-0">
                      <label
                        className="input-label "
                        htmlFor="homelessnessLength"
                      >
                        Length Of Homelessness:
                      </label>
                    </Col>
                    <Col xs={10}>
                      <RadioButton
                        className="mb-3 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-3"
                        name="homelessnessLength"
                        options={homeLessNessRadioButton}
                        inputClassName="mt-1 align-self-start"
                        optionClassName="mb-2"
                        inputLabelClassName="text-wrap align-self-start"
                        onChange={handleChange}
                      />
                      <TextInput
                        className="mt-2 ml-1 row w-75 col-lg-12 col-sm-6 col-sx-4"
                        label="Other (Specify)"
                        name="otherHomelessnessLength"
                        type="text"
                        placeholder="Others"
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="col-12 p-0">
                      <label
                        className="input-label col-10"
                        htmlFor="numberOfhomelessnessEpisodesInLastFiveYears "
                      >
                        Number of homeless episodes in the last five years:
                      </label>
                    </Col>
                    <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                      <TextInput name="numberOfhomelessnessEpisodesInLastFiveYears" />
                    </Col>
                  </Row>

                  {/* <TextInput className='row col-lg-12 col-md-4 col-sm-4' labelClassName='input-label mb-2' label='Number of homeless episodes in the last five years:' name='numberOfhomelessnessEpisodesInLastFiveYears' /> */}
                  <TextInput
                    className="row col-lg-12 col-md-8 col-sm-4"
                    labelClassName="input-label mb-2"
                    label="Approximate number in lifetime:"
                    name="approximateNumberInLifetime"
                  />
                  <Row>
                    <Col className="col-12 p-0">
                      <label
                        className="input-label col-10"
                        htmlFor="spentInShelter "
                      >
                        Within the last four (4) years, how many nights, months,
                        or years, if any, have you spent in a shelter(s)?
                      </label>
                    </Col>
                    <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                      <TextInput name="spentInShelter" />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <label
                        className="input-label col-10"
                        htmlFor="sleptDetailsInLastThirtyDays "
                      >
                        Where have you slept for the last thirty (30) days?
                      </label>
                    </Col>
                    <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                      <TextInput name="sleptDetailsInLastThirtyDays" />
                    </Col>
                  </Row>
                  {/* <TextInput className='row col-lg-12 col-md-4 col-sm-4' labelClassName='input-label mb-2' label='Within the last four (4) years, how many nights, months, or years, if any, have you spent in a shelter(s)?' name='spentInShelter' /> */}
                  {/* <TextInput className='row col-lg-12 col-md-4 col-sm-4' labelClassName='input-label mb-2' label='Where have you slept for the last thirty (30) days?' name='sleptDetailsInLastThirtyDays' /> */}
                  <Row>
                    <Col className="input-label col-10">
                      <label className="input-label" htmlFor="isVeteran">
                        Is participant a Veteran, (anyone who has been on active
                        military duty)
                      </label>
                    </Col>
                    <Col className="col-12 p-0">
                      <RadioButton
                        className="mb-3"
                        name="isReceivingHousingSubsidy"
                        options={housingSubsidyOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <label
                        className="input-label col-10"
                        htmlFor="housingSubsidyType "
                      >
                        What type of housing subsidy is the participant
                        receiving?
                      </label>
                    </Col>
                    <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                      <TextInput name="housingSubsidyType" />
                    </Col>
                  </Row>
                  <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-1">
                    <Col className="col-6 col-xs-12 p-0">
                      <RadioButton
                        className="mb-3"
                        label="Does/did participant pay own rent?"
                        labelClassName="input-label d-block mb-2"
                        name="paysOwnRent"
                        options={housingSubsidyOptions}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-6 col-xs-12 p-0">
                      <RadioButton
                        className="mb-3"
                        label="Does/did participant pay for own utilities?"
                        labelClassName="input-label d-block mb-2"
                        name="paysForOwnUtilities"
                        options={housingSubsidyOptions}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="col-6 col-xs-12 p-0">
                      <RadioButton
                        className="mb-3"
                        label="Has participant ever been evicted?"
                        labelClassName="input-label d-block mb-2"
                        name="hasEverEvicted"
                        options={housingSubsidyOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="col-12 mb-2">
                      <label className="input-label">
                        Reason For Leaving Last Housing Situation:
                      </label>
                    </Col>
                    <Col className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-3">
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToUnpaidRent"
                      >
                        {" "}
                        Eviction due to unpaid rent{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToReasonOtherThanUnpaidRent"
                      >
                        {" "}
                        Eviction for reason other than unpaid rent{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToConflictWithFamily"
                      >
                        {" "}
                        Conflict with friends or family{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToOverCrowding"
                      >
                        {" "}
                        Overcrowding
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToDomesticViolence"
                      >
                        {" "}
                        Domestic violence:{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToIncarceration"
                      >
                        {" "}
                        Incarceration:{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToHospitalization"
                      >
                        {" "}
                        Hospitalization, including long term treatment{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToHousingCondemned"
                      >
                        {" "}
                        Housing condemned{" "}
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="evictionDueToFire"
                      >
                        {" "}
                        Fire{" "}
                      </Checkbox>
                    </Col>
                    <Col className="row col-12">
                      <Checkbox
                        labelClassName="mb-2 col-lg-3 col-md-10 col-sm-4 mt-1"
                        name="evictionDueToOther"
                      >
                        Other
                      </Checkbox>
                      <Col className="row col-9">
                        <label className="col-4 mt-1" htmlFor="otherEviction">
                          Please Specify:
                        </label>
                        <TextInput
                          className="col-5"
                          placehloder="Others"
                          name="otherEviction"
                        />
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 mb-2 p-0">
                      <label className="input-label col-10">
                        Please Identify Any Contributing Factors To Housing
                        Instability:
                      </label>
                    </Col>
                    <Col className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-lg-3">
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="nonHousing"
                      >
                        Non-Housing (Street, park, car, etc.)
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="emergencyShelter"
                      >
                        Emergency Shelter, Please name:
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="transitionalHousing"
                      >
                        Transitional Housing
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="pyschiatricFacility"
                      >
                        Psychiatric Facility
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="substanceAbuseTreatmentFacility"
                      >
                        Substance Abuse Treatment Facility
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="hospital"
                      >
                        Hospital
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="prisonOrJail"
                      >
                        Prison/Jail
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="domesticViolenceShelter"
                      >
                        Domestic Violence Shelter
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="livingWithFriendsOrFamily"
                      >
                        Living with friends/family
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="rentalHousing"
                      >
                        Rental Housing
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="ownApartmentOrHouse"
                      >
                        Own apartment or house
                      </Checkbox>
                      <Checkbox
                        labelClassName="mb-2 text-wrap col-lg-12 col-md-10 col-sm-4"
                        name="housingInstabilityFactorsOther"
                      >
                        {" "}
                        Other{" "}
                      </Checkbox>
                      <TextInput
                        className="w-75 col-lg-10 col-md-8 col-sm-4"
                        label="Please Specify:"
                        placehloder="Specify Other"
                        name="otherHousingInstabilityFactors"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
