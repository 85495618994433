
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LOGIN } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthActions from 'redux/AuthRedux'
import AlertMessage from 'components/AlertMessage.jsx'
import { REQUIRED_PASSWORD_LENGTH } from 'config/CONSTANTS'
import { ForgotPasswordContainer, ForgotPasswordInnerContainer, ForgotPasswordText } from 'pages/Styles/ForgotPassword'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'

export class ResetPassword extends React.Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {    
    const { password } = values
    const token = this.props.match.params.token
    this.props.validatePassword(password, token)
  }

  render () {
    let { sending, messages, type, onClear } = this.props
    return (
      <Container fluid className='p-0 h-100 w-100'>
        <ForgotPasswordContainer>
          <Row className='h-100 justify-content-center align-items-center'>
            <ForgotPasswordInnerContainer>
              <Col>
                <Row >
                  <ForgotPasswordText>Reset Password</ForgotPasswordText>
                </Row>

                <Row className='full-width'>
                  <Col sm={12} className='ml-2'>
                    <AlertMessage
                      type={type}
                      messages={messages }
                      onClear={onClear}
                    />
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col className='p-0'>
                    <Formik
                        initialValues={{ password: '', confirmPassword: '' }}
                        validationSchema={Yup.object({
                          password: Yup.string()
                            .required('No password provided.') 
                            .min(REQUIRED_PASSWORD_LENGTH, 'Password is too short - should be 8 chars minimum.')
                            .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
                          confirmPassword: Yup.string()
                            .required('No password provided.')
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        })}
                        onSubmit={this.handleSubmit}
                      >
                        <Form>
                          <TextInput label='Password' name='password' type='password' size='lg' labelwithin={true} />
                          <TextInput label='Confirm Password' name='confirmPassword' type='password' size='lg' labelwithin={true} />
                          <Button size="lg" className='mt-5' block variant="primary" disabled={sending} type='submit'>{sending ? 'Updating Password...' : 'Update Password'}</Button>
                        </Form>
                    </Formik>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col className='p-0'>
                    <p><Link to={LOGIN}>Already have an account? Sign in!</Link></p>
                  </Col>
                </Row>
              </Col>

            </ForgotPasswordInnerContainer>
          </Row>
        </ForgotPasswordContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.alert.messages,
    type: state.alert.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validatePassword: (newPassword, token) => dispatch(AuthActions.validatePassword(newPassword, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);