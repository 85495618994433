import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and ActionCreators
const { Types, Creators } = createActions({
  getRoles: ['pageNumber'],
  getRolesSuccess: ['roles', 'total', 'pageNumber'],
  getRolesFailure: null,
  createRole: ['params'],
  updateRole: ['id', 'params'],
  deleteRole: ['id'],
  editRole: ['id'],
  updateRoleFailure: null
});

export const RoleTypes = Types;
export default Creators;

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  fetchingRoles: false,
  roles: [],
  totalCount: 0,
  currentPage: 1
});

/* ---------- Reducers ----------------*/
export const getRoles = (state) => {
  return state.merge({
    fetchingRoles: true
  })
}

export const getRolesSuccess = (state, { roles, total, pageNumber }) => {
  return state.merge({
    fetchingRoles: false,
    roles: roles,
    totalCount: total,
    currentPage: pageNumber || 1
  })
}

export const getRolesFailure = (state) => {
  return state.merge({
    fetchingRoles: false
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ROLES]: getRoles,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_FAILURE]: getRolesFailure
});
