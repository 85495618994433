import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { RadioButton } from 'components/Fields/RadioButton'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextMessage } from 'components/Fields/TextMessage'
import { Row, Col } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

const yesOrNoOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
]
export default class SpiritualReligion extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.spiritual)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{...data}}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-3' htmlFor='hasTraumaticCircumstances'>Are there special, unusual, or traumatic circumstances that affected your development?:</label>
                    </Col>
                    <Col className='col-12 d-flex p-0'>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-4' name='noSpiritualImportance'>Not</Checkbox>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-4' name='littleSpiritualImportance'>Little</Checkbox>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-4' name='somewhatSpiritualImportance'>Somewhat</Checkbox>
                      <Checkbox labelClassName='d-flex align-items-center text-wrap mr-4' name='highSpiritualImportance'>Very</Checkbox>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='affiliatedToSpiritualGroup'>Are you affiliated with a spiritual/religious group?</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='isAffiliatedWithSpiritualGroup' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe'
                        name='spiritualGroupAffiliationDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='raisedWithinSpiritualGroup'>Were you raised within a spiritual/religious group?</label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='isRaisedWithinSpiritualGroup' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col className='p-0 col-8'>
                      <TextMessage
                        labelClassName='input-label text-wrap mb-2'
                        label='If Yes, Describe'
                        name='raisedWithinSpiritualGroupDescription'
                        placeholder='Describe'
                        size="md"/>
                    </Col>
                  </Row>
                  <Row className='align-items-center mb-2 w-100'>
                    <Col className='col-12 p-0'>
                      <label className='input-label mb-2' htmlFor='incorporateBeliefsInCounselling'>
                        Would you like your spiritual/religious beliefs incorporated into your counseling?
                      </label>
                    </Col>
                    <Col className='col-12 p-0'>
                      <RadioButton name='spiritualBeliefInCounselling' options={yesOrNoOptions} onChange={handleChange} />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}