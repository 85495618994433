
import React from 'react';
import BaseModal from 'components/Modals/BaseModal'
import { Row } from 'react-bootstrap';

export class ConfirmationDialog extends React.Component {

  render () {
    return (
      <BaseModal heading={this.props.heading} actionText={this.props.buttonText} onHide={this.props.onClose} onSave={this.props.onSave} danger={true}>
        <Row className='align-items-center m-2 w-100'>
          { this.props.message }
        </Row>
      </BaseModal>
    )
  }
}

export default ConfirmationDialog;