import React from 'react';
import { Select } from 'components/Fields/Select';
import { TextInput } from "components/Fields/TextInput";
import { Row, Col } from 'react-bootstrap';

export default class NestedDateUI extends React.Component {

  render () {
    const { values, allowedTypesMap, onOperatorChange, onNegateChange, index, ind } = this.props
    return (
      <Row className='mt-2 w-100'>
        <Col>
          <Select
            name={`includedFilters.filters.${index}.filters.${ind}.operator`}
            className="custom-select mt-2"
            onChange={(event) => onOperatorChange(event.target.value)}
          >
            <option>Select</option>
            {
              Object.entries(allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType]).map(([key, value]) => {
                return <option key={key} value={key}>{value.displayName}</option>
              })
            } 
          </Select>
        </Col>
        
        { values && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'DATE_TIME' && values.includedFilters.filters[index].filters[ind].operator && allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType][values.includedFilters.filters[index].filters[ind].operator] && allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType][values.includedFilters.filters[index].filters[ind].operator]['dateTypes'] && allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType][values.includedFilters.filters[index].filters[ind].operator]['dateTypes'].length > 0 && (
            <Col>
              <Select
                name={`includedFilters.filters.${index}.filters.${ind}.valueType`}
                className="custom-select mt-2"
                onChange={(event) => {
                  const v = event.target.value;
                  return onNegateChange(v);
                }}
              >
                <option>Select</option>
                {
                  (allowedTypesMap[values.includedFilters.filters[index].filters[ind].dataType][values.includedFilters.filters[index].filters[ind].operator]['dateTypes']).map((v) => {
                    let t = 'ABSOLUTE';
                    
                    if (v === 'days ago') {
                      t = 'RELATIVE_PAST';
                    }

                    if (v === 'days from now' || v === 'days') {
                      t = 'RELATIVE_FUTURE'
                    }

                    return <option key={v} value={t}>{v}</option>
                  })
                }
              </Select>
            </Col>
        )}

        {
          values.includedFilters.filters[index].filters[ind].valueType === "ABSOLUTE" && (
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.filters.${ind}.value`}
                type="date"
              />
            </Col>
          )
        }

        {(values.includedFilters.filters[index].filters[ind].valueType === "ABSOLUTE" && values.includedFilters.filters[index].filters[ind].operator === 'IN_BETWEEN_DATES') && (
          <Col>
            <TextInput
              className='mt-2'
              name={`includedFilters.filters.${index}.filters.${ind}.value1`}
              type="date"
            />
          </Col>
        )}

        {
          values.includedFilters.filters[index].filters[ind].valueType && values.includedFilters.filters[index].filters[ind].valueType !== "ABSOLUTE" && (
            <Col>
              <TextInput
                className='mt-2'
                name={`includedFilters.filters.${index}.filters.${ind}.value`}
                type="number"
                placeholder='days'
              />
            </Col>
          )
        }

      </Row>
    )
  }
}