import React from "react";
import { Formik, Form, FieldArray } from "formik";
import { Row, Col, Table } from "react-bootstrap";
import BaseForm from "components/Patient/BaseForm";
import { Select } from "components/Fields/Select";
import { TextInput } from "components/Fields/TextInput";
import { TextMessage } from "components/Fields/TextMessage";
import { RadioButton } from "components/Fields/RadioButton";
import { Checkbox } from "components/Fields/Checkbox";
import { FormHeader, LightGrayLabel } from "../../../../styles/GenericStyles";
import { CLIENT_MENTAL_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";

const receivedPsychiatricCareOptions = [
  { key: "Yes", value: true },
  { key: "No", value: false },
];

const assessCurrentRelationShipOptions = [
  { key: "Good", value: "Good" },
  { key: "Fair", value: "Fair" },
  { key: "Poor", value: "Poor" },
];

const livingSelectOptions = [
  { label: "Select", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const relationshipOptions = [
  { label: 'Select', value: '' },
  { label: 'Mother', value: 'Mother' },
  { label: 'Father', value: 'Father' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Brother', value: 'Brother' },
  { label: 'Sister', value: 'Sister' },
  { label: 'Children', value: 'Children' },
  { label: 'Others', value: 'Others' }
]

const maritalStatusOptions = [
  { label: "Select", value: "" },
  { label: "Single", value: "Single" },
  { label: "Seperated", value: "Seperated" },
  { label: "Widowed", value: "Widowed" },
  { label: "Annulment", value: "Annulment" },
  { label: "Legally Married", value: "Legally Married" },
  { label: "Unmarried, living together", value: "Unmarried, living together" },
  { label: "Divorced", value: "Divorced" },
  { label: "Divorce in progress", value: "Divorce in progress" },
];

export default class FamilyInformation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_MENTAL_FORM_TYPES.familyInformation
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm
                handleSubmit={this.handleSubmit}
                cancel={this.onCancel}
                updatingForm={updatingForm} disabled={!write}
              >
                <div className="w-100">
                  
                  <Row>
                    <Table className="w-100 pl-0 ml-0" borderless responsive>
                      <thead>
                        <th className="pl-0" style={{ width: "15%" }}>
                          Relationship
                        </th>
                        <th style={{ width: "30%" }}>Name</th>
                        <th style={{ width: "10%" }}>Age</th>
                        <th style={{ width: "10%" }}>Living</th>
                        <th style={{ width: "25%" }}>Living With you</th>
                        <th style={{ width: "10%" }}></th>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="familyInfoList"
                          render={(arrayHelpers) => (
                            <>
                              {values.familyInfoList &&
                              values.familyInfoList.length > 0 ? (
                                values.familyInfoList.map(
                                  (familyInfo, index) => (
                                    <tr key={index}>
                                      <td className="pl-0 py-0">
                                        <Select
                                          name={`familyInfoList.${index}.relationship`}
                                          className="custom-select"
                                        >
                                          {relationshipOptions.map(
                                            ({ label, value }) => (
                                              <option key={value} value={value}>
                                                {label}
                                              </option>
                                            )
                                          )}
                                        </Select>
                                      </td>
                                      <td className="py-0">
                                        <TextInput
                                          name={`familyInfoList.${index}.name`}
                                          placeholder="Name"
                                        />
                                      </td>
                                      <td className="py-0">
                                        <TextInput
                                          name={`familyInfoList.${index}.age`}
                                          type="number"
                                          placeholder="Age"
                                        />
                                      </td>
                                      <td className="py-0">
                                        <Select
                                          name={`familyInfoList.${index}.isLiving`}
                                          className="custom-select"
                                        >
                                          {livingSelectOptions.map(
                                            ({ label, value }) => (
                                              <option key={value} value={value}>
                                                {label}
                                              </option>
                                            )
                                          )}
                                        </Select>
                                      </td>
                                      <td className="py-0">
                                        <Select
                                          name={`familyInfoList.${index}.isLivingWithYou`}
                                          className="custom-select"
                                        >
                                          {livingSelectOptions.map(
                                            ({ label, value }) => (
                                              <option key={value} value={value}>
                                                {label}
                                              </option>
                                            )
                                          )}
                                        </Select>
                                      </td>

                                      <td className="d-flex p-0">
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger mr-3"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() =>
                                            arrayHelpers.insert(index, "")
                                          }
                                        >
                                          Add
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <Col className="col-12 d-flex mb-3">
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add Member
                                  </button>
                                </Col>
                              )}
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                  </Row>

                  <Row className="mb-3">
                    <Col className="p-0">
                      <RadioButton
                        name="receivesPsychiatricCare"
                        options={receivedPsychiatricCareOptions}
                        label="Does participant receive psychiatric care?"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <label>MARITAL STATUS: </label>
                    <Table className="w-100 pl-0 ml-0" borderless>
                      <tbody>
                        <FieldArray
                          name="martialStatusList"
                          render={(arrayHelpers) => (
                            <>
                              {values.martialStatusList &&
                              values.martialStatusList.length > 0 ? (
                                values.martialStatusList.map(
                                  (martialStatus, index) => (
                                    <tr key={index}>
                                      <td className="pl-0 py-0">
                                        <Select
                                          name={`martialStatusList.${index}.status`}
                                          className="custom-select"
                                        >
                                          {maritalStatusOptions.map(
                                            ({ label, value }) => (
                                              <option key={value} value={value}>
                                                {label}
                                              </option>
                                            )
                                          )}
                                        </Select>
                                      </td>
                                      <td className="py-0">
                                        <TextInput
                                          name={`martialStatusList.${index}.lengthOfTimeInMonths`}
                                          placeholder="Length of Time (In Months)"
                                          type="number"
                                        />
                                      </td>

                                      <td className="d-flex p-0">
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger mr-3"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                        {index === 0 && (
                                          <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                              arrayHelpers.insert(index, "")
                                            }
                                          >
                                            Add
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <Col className="col-12 d-flex mb-3">
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Add Member
                                  </button>
                                </Col>
                              )}
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                  </Row>

                  <hr />
                  <Row className="my-3">
                    <Col className="p-0 col-md-12 col-lg-12">
                      <TextInput
                        type="number"
                        name="totalNumberOfMarriages"
                        placeholder="Specify"
                        className="mr-3"
                        labelClassName="input-label mb-2"
                        label="Total Number Of Marriages"
                      />
                      <Col>
                        <label
                          className="input-label"
                          htmlFor="currentRelationshipAssessment"
                        >
                          Assessment Of Current Relationship (If Applicable):
                        </label>
                      </Col>
                      <RadioButton
                        className="ml-2"
                        name="currentRelationshipAssessment"
                        options={assessCurrentRelationShipOptions}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <FormHeader>PARENTAL INFORMATION:</FormHeader>
                  <Row className="my-3">
                    <Col className="col-4">
                      <Checkbox
                        labelClassName="input-label text-wrap"
                        name="parentsLegallyMarried"
                      >
                        Parents legally married
                      </Checkbox>
                    </Col>
                    <Col className="col-4">
                      <Checkbox
                        labelClassName="input-label text-wrap"
                        name="parentsHaveSeperated"
                      >
                        Parents have separated
                      </Checkbox>
                    </Col>
                    <Col className="col-4">
                      <Checkbox
                        labelClassName="input-label text-wrap"
                        name="parentsHaveDivorced"
                      >
                        Parents divorced
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col className="col-6 d-flex">
                      <Checkbox labelClassName="text-wrap" name="motherMarried">
                        Mother remarried
                      </Checkbox>
                      <TextInput
                        type="number"
                        name="totalNumberOfTimesMotherRemarried"
                        placeholder="No. of times"
                        className="d-inline"
                      />
                    </Col>
                    <Col className="col-6 d-flex">
                      <Checkbox labelClassName="text-wrap" name="fatherMarried">
                        Father remarried
                      </Checkbox>
                      <TextInput
                        type="number"
                        name="totalNumberOfTimesFatherRemarried"
                        placeholder="No. of times"
                        className="d-inline"
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row className="my-3">
                    <Col className="p-0">
                      <TextMessage
                        labelClassName="input-label text-wrap mb-2"
                        label="Special circumstances (e.g., raised by person other than parents, information about spouse/children not living with you, etc.):"
                        name="specialCircumstances"
                        placeholder="Describe"
                        rows={4}
                        size="md"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
