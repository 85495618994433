import { Row, Button } from 'react-bootstrap';

export const BaseForm = (props) => {

  return (
    <>
      <Row className='shadow rounded border mt-3 mr-0 ml-0 p-5 w-100'>
        {props.children}
      </Row>

      <Row className='justify-content-end mt-3 mr-0 ml-0 w-100'>
        <Button variant="primary" disabled={props.disabled} type='submit'>{!!props.updatingForm ? 'Saving...' : 'SAVE'}</Button>
      </Row>
    </>
  )
}

export default BaseForm;
