import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean, humanFormat } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class IdentifyingInfoForm extends React.Component {
  render() {
    let { data, link, read, write, client } = this.props;
    data = data || {};
    client = client || {};    
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Reentry &gt; Intake &gt; Identifying Information:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    let gender = (client.gender) || ''
    let pronoun = (client.pronoun) || ''
    let firstName = client.firstName
    let middleName = client.middleName || ''
    let lastName = client.lastName

    let name = firstName + ' ' + middleName + ' ' + lastName;

    let address =
      ((client.addressLine1) || '') +
      " " +
      (( client.addressLine2) || '') +
      " " +
      ((client.city) || '') +
      " " +
      (( client.state) || '');

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Identifying Information:</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>

        <Col className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
          <Row className="align-items-center w-100">
            <Col className="col-12">
              <LightGrayLabel>Date Information is Gathered:</LightGrayLabel>
            </Col>
            <Col className="col-xs-12 col-sm-12 col-md-8">
              <label className="input-label">
                {data.informationGatheredDate}
              </label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Participant Name:</LightGrayLabel>
            </Col>
            <Col className="col-8 d-flex">
              <label className="input-label"> {name}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Address:</LightGrayLabel>
            </Col>

            <Col className="col-8 d-flex flex-wrap">
              <label className="input-label">{address}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Zip Code:</LightGrayLabel>
            </Col>
            <Col className="col-8">
              <label className="input-label">{data.zipcode}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>
                Phone Where Participant Can Be Reached:
              </LightGrayLabel>
            </Col>
            <Col className="col-8">
              <label className="input-label">{data.phoneNumber}</label>
            </Col>
          </Row>

          <Row className="align-items-center w-100">
            <Col className="col-12">
              <LightGrayLabel>Email Address:</LightGrayLabel>
            </Col>
            <Col className="col-8">
              <label className="input-label">{data.email}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Social Security Number:</LightGrayLabel>
            </Col>
            <Col className="col-8 d-flex">
              <label className="input-label">{data.ssn}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Date Of Birth:</LightGrayLabel>
            </Col>
            <Col className="col-8 d-flex">
              <label className="input-label">{data.dob}</label>
            </Col>
          </Row>

          <Row className="align-items-center w-100">
            <Col className="col-12">
              <LightGrayLabel>Place Of Birth:</LightGrayLabel>
            </Col>
            <Col className="col-8 d-flex">
              <label className="input-label">{data.dob}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Gender:</LightGrayLabel>
            </Col>
            <Col className="col-8">
              <label className="input-label">{gender + (pronoun !== '' ? ('(' + pronoun + ')') : '')}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Race:</LightGrayLabel>
            </Col>
            <Col className="col-10">
              <label className="input-label">{humanFormat(data.race)}</label>
            </Col>
          </Row>
          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Race Other:</LightGrayLabel>
            </Col>
            <Col className="col-10">
              <label className="input-label">
                {humanFormat(data.raceOther)}
              </label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>Ethinicity:</LightGrayLabel>
            </Col>
            <Col className="col-sm-10 col-lg-8">
              <label className="input-label">
                {humanFormat(data.ethnicity)}
              </label>
            </Col>
          </Row>

          {/* <Row className="align-items-center mb-2 w-100"> */}
          {/* <Col> */}
          <Row className="align-items-center mb-2 w-100">
            <Col className="col-12">
              <LightGrayLabel>
                What Is Participant’s Primary Language?
              </LightGrayLabel>
            </Col>
            <Col className="col-4">
              <label className="input-label">{data.primaryLanguage}</label>
            </Col>
          </Row>

          <Row className="align-items-center mb-2">
            <Col className="col-12">
              <LightGrayLabel>
                What Is Participant’s Secondary Language?
              </LightGrayLabel>
            </Col>
            <Col className="col-4">
              <label className="input-label">{data.secondaryLanguage}</label>
            </Col>
          </Row>

          <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>Relationship Status:</LightGrayLabel>
            </Col>
            <Col className="col-lg-8 col-sm-12">
              <label className="input-label">
                {humanFormat(data.relationshipStatus)}
              </label>
            </Col>
          </Row>
          <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>Relationship Status - Others:</LightGrayLabel>
            </Col>
            <Col className="col-lg-8 col-sm-12">
              <label className="input-label">
                {data.relationshipStatusOther}
              </label>
            </Col>
          </Row>
          {/* <hr /> */}

          <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>
                Are there any identified, past or current, domestic violence
                issues?
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {isBoolean(data.domesticViolenceApplicable)}
              </label>
            </Col>
          </Row>

          <Row className="align-items-center w- mb-2">
            <Col className="col-12">
              <LightGrayLabel>
                Please Describe, With Dates Of Incidents:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">
                {data.domesticViolenceDescription}
              </label>
            </Col>
          </Row>

          <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>
                Is participant a Veteran, (anyone who has been on active
                military duty)
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.isVeteran)}</label>
            </Col>
          </Row>

          <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>Which Branch:</LightGrayLabel>
            </Col>
            <Col className="col-12 d-flex align-items-center">
              <label className="input-label">{data.activeStatus}</label>
            </Col>
            <Col className="col-12">
              <Col className="col-12">
                <LightGrayLabel>(If Discharged, What Kind?)</LightGrayLabel>
              </Col>
              <label className="input-label">{data.dischargedBranchType}</label>
            </Col>
          </Row>

          {/* <Row className="align-items-center w-100 mb-2">
            <Col className="col-12">
              <LightGrayLabel>Contact Type</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.contactType}</label>
            </Col>
          </Row> */}
        </Col>
      </Row>
    );
  }
}
