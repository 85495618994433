// A library to wrap and simplify api calls
import apisauce from 'apisauce'
import {
  LOGIN_URL,
  SIGN_UP_URL,
  LOGOUT_URL,
  TOKEN_INFO_URL,
  GET_ACCOUNTS_URL,
  GET_PASSWORD_URL,
  GET_CLIENTS_URL,
  GET_APPOINTMENTS_URL,
  GET_ROLES_URL,
  GET_ACCOUNT_CLIENT_URL,
  GET_QUERIES_URL
} from 'services/CONSTANTS'
import { PAGE_SIZE, ACCOUNTS_PAGE_SIZE, APPOINTMENTS_PAGE_SIZE } from 'config/CONSTANTS'

const create = (baseURL: string = process.env.REACT_APP_API_BASE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping th e output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const login = (email, password) => {
    const apiUrl = LOGIN_URL;
    return api.post(apiUrl, {
      email,
      password
    })
  }

  const signUp = (email, password) => {
    const apiUrl = SIGN_UP_URL;
    return api.post(apiUrl, {
      email,
      password
    })
  }

  const logout = (accessToken) => {
    const apiUrl = LOGOUT_URL;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getTokenInfo = (token) => {
    const apiUrl = `${TOKEN_INFO_URL}/${token}`;
    return api.get(apiUrl)
  }

  const sendEmail = (email) => {
    const apiUrl = GET_PASSWORD_URL + '/reset/byemail/initiate'
    return api.post(apiUrl, { email, })
  }

  const validatePassword = (password, token) => {
    const apiUrl = GET_PASSWORD_URL + '/reset/byemail/validate'
    return api.post(apiUrl, { password }, { headers: { 'X-Password-Reset-Token': token } })
  }

  const getUserInfo = (userId, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + userId;
    return api.get(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateUserInfo = (userId, user, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + userId;
    return api.put(apiUrl, { ...user }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createUser = (user, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL;
    return api.post(apiUrl, { ...user }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getUsers = (accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL;
    return api.get(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updatePassword = (current_password, new_password, accessToken) => {
    const apiUrl = GET_PASSWORD_URL + '/update'
    return api.post(apiUrl, { current_password, new_password, }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteUser = (userId, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + userId;
    return api.delete(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }


  /*-------------------------------------- Clients Start -------------------------------- */
  const getClients = (accessToken, pageNumber, sortKey = 'lastName', orderBy = 'ASC') => {
    const apiUrl = GET_CLIENTS_URL + `?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}&sortKey=${sortKey}&orderBy=${orderBy}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClient = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id;
    return api.get(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createClient = (postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL;
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClient = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteClient = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const searchClients = (params, pageNumber, accessToken) => {
    let apiUrl = GET_CLIENTS_URL + 'search';
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    apiUrl += `?${queryString}`
    apiUrl += `&pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}`
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientPicture = (id, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/picture`;
    return api.post(apiUrl, { documentType: postBody.file.type, documentName: postBody.file.name }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientPictureSuccessCallback = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/picture/successCallback`;
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteClientPicture = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/picture`;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  /*-------------------------------------- Clients End ---------------------------------- */

  /*-------------------------------------- Clients Re-entry Forms Start-------------------------------- */
  const getReentryIntakeInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeIdentifyingInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/identifyingInformation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeIncomeInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/income';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeEmploymentSearchInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/employmentSearchInformation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeFamilyInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/family';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeHousingInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/housing';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeImmediateNeedsInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/immediateNeeds';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeIncarcerationInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/incarceration';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeLegalHistoruInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/legalHistory';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  
  const updateReEntryIntakePersonalHealthInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/personalHealthInformation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  
  const updateReEntryIntakeSkillsAndAbilitiesInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/skillsAndAbilities';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReEntryIntakeTransportationInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryIntakeForms/transportation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  /*-------------------------------------- Clients Re-entry Forms End -------------------------------- */

  /*-------------------------------------- Clients BFET Forms Start-------------------------------- */
  const getBfetInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetEmploymentGoalsInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/employmentGoals';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetEmploymentHistoryInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/employmentHistory';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetEmploymentPlanInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/employmentPlan';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetAssessedEmploymentBarriersInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/assessedEmploymentBarriers';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetDeclarationAndSignatureInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/declarationAndSignature';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateBfetSkillsAndQualificationInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfetIntakeForms/skillsAndQualification';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  /*------------------------------------------ */
 
   /* -------- Visitor Notes forms ----------  */
  const getClientVisitorNotesInformation = (id, category, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/notesCategories/${category}/notes`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteClientVisitorNotesInformation = (id, noteId, category, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/notesCategories/${category}/notes/${noteId}`;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientVisitorNotesInformation = (id, category, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/notesCategories/${category}/notes`;
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientVisitorNotesInformation = (id, noteId, category, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/notesCategories/${category}/notes/${noteId}`;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getAccountSuppliesInformation = (category, accessToken) => {
    const apiUrl = SIGN_UP_URL + `/supplyCategories/${category}/supplies`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClientSuppliesInformation = (id, category, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/supplyCategories/${category}/supplies`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientSuppliesInformation = (id, category, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/supplyCategories/${category}/supplies`;
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientSuppliesInformation = (id, supplyId, category, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/supplyCategories/${category}/supplies/${supplyId}`;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /* -------- Doc forms ----------  */
  const getClientDocumentsInformation = (id, category, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/documentCategories/${category}/documents`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientDocumentsInformation = (id, category, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/documentCategories/${category}/documents`;
    return api.post(apiUrl, { fileType: postBody.file.type, documentType: postBody.docName || postBody.file.name, documentName: postBody.file.name }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteClientDocumentsInformation = (id, docId, category, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/documentCategories/${category}/documents/${docId}`;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const uploadDocumentFileSuccess = (id, documentId, documentCategory, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + `/documentCategories/${documentCategory}/documents/${documentId}/successCallback`;
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const uploadFile = (preSignedUrl, data, mimeType) => {
    return new Promise((resolve, reject) => {
        var request = new XMLHttpRequest();
        request.open("PUT", preSignedUrl, true);
        request.setRequestHeader('Content-Type', mimeType);
        
        request.onload = function () {
          if (request.status >= 200 && request.status < 400) {
            resolve({ ok: true })
          }
        }

        request.onerror = () => {
          console.error('File fetch gave error with following status code ' + request.status);
        }

        data.arrayBuffer().then(function(buffer) {
          request.send(buffer);
          resolve({ ok: true })
        });
    })
 }

 // JOb Search
  const getClientBfetJobSearchInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClientBfetJobSearchEmployersInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const postClientBfetJobSearchEmployerInformation = (id, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers';
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientBfetJobSearchEmployerInformation = (id, employerId, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers/' + employerId;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteClientBfetJobSearchEmployerInformation = (id, employerId, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers/' + employerId;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const initUploadClientBfetJobSearchEmployerForm = (id, employerId, postBody = {}, accessToken) => {
    const file = postBody.file || postBody.file1
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers/' + employerId + `/document`;
    return api.post(apiUrl, { documentName: file.name }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const uploadClientBfetJobSearchEmployerFormSuccess = (id, employerId, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog/employers/' + employerId + `/document/successCallback`;
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientBfetJobSearchInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/jobSearchLog';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /* -------- DVR Health forms ----------  */
  const getClientDVRServiceDeliveryInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/dvrForms/serviceDeliveryOutcome';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClientDVRVocationalInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/dvrForms/vocationalAssessmentWorksheet';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClientBFETSuppliesInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/supplies';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientBFETSuppliesInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/bfet/supplies';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientDVRServiceDeliveryInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/dvrForms/serviceDeliveryOutcome';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientDVRVocationalInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/dvrForms/vocationalAssessmentWorksheet';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /* -------- Consent Health forms ----------  */
  const getClientConsentInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryConsentForms/consentForms';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientConsentInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/reentryConsentForms/consentForms';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /* -------- Mental Health forms ----------  */
  const getClientMentalInformation = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalDemographics = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/demographics';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalDevelopment = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/development';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalEducation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/education';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalEmployment = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/employment';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalFamilyInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/familyInformation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalLegal = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/legal';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const initiateClientMentalLegalForm = (id, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/legal/initiateLegalTreatmentCourtOrderUpload';
    return api.post(apiUrl, { documentName: postBody.file.name }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const clientMentalLegalFormUploadSuccess = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/legal/legalTreatmentCourtOrderUpload/successCallback';
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const initiateClientMentalLegalExemptionForm = (id, postBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/legal/initiateLegalExemptionCourtOrderUpload';
    return api.post(apiUrl, { documentName: postBody.file1.name }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const clientMentalLegalExemptionFormUploadSuccess = (id, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/legal/legalExemptionCourtOrderUpload/successCallback';
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalLeisure = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/leisure';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalPrimaryReasonsForSeekingService = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/primaryReasonsForSeekingServices';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalSocialRelationship = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/socialRelationships';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalSpiritual = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/spiritual';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalMilitary = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/military';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalMedicalHealth = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/medicalHealth';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalAdditionalInformation = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/additionalInformation';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalChemicalUseHistory = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/chemicalUseHistory';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalNutrition = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/nutrition';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalMedicalHistory = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/medicalHistory';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateClientMentalTreatmentHistory = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/mentalhealthPersonalhistoryForms/treatmentHistory';
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /* ----------------------------------------- */

  /****************** Account Users Start *********************/
  const getAccounts = (pageNumber, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + `?pageSize=${ACCOUNTS_PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getAccount = (id, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + id;
    return api.get(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const referAccount = (params, accessToken) => {
    const apiUrl = SIGN_UP_URL + '/refer';
    return api.post(apiUrl, { ...params }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const referClient = (id, params, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + id + '/organization/refer';
    return api.post(apiUrl, { ...params }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }


  const createAccount = (postBody = {}, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL;
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateAccount = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + id;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteAccount = (id, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + id;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  /****************** Account Users END ***********************/

  /****************** Roles Start *********************/
  const getRoles = (pageNumber, accessToken) => {
    const apiUrl = GET_ROLES_URL + `?pageSize=${ACCOUNTS_PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createRole = (postBody = {}, accessToken) => {
    const apiUrl = GET_ROLES_URL;
    return api.post(apiUrl, postBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateRole = (id, putBody = {}, accessToken) => {
    const apiUrl = GET_ROLES_URL + id;
    return api.put(apiUrl, putBody, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteRole = (id, accessToken) => {
    const apiUrl = GET_ROLES_URL + id;
    return api.delete(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  /****************** Roles Users END ***********************/

   /****************** Reports Start *********************/
  const getSearchMetaData = (accessToken) => {
    const apiUrl = '/reports-meta';
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getQuery = (queryId, accessToken) => {
    const apiUrl = `/queries/${queryId}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getQueries = (pageNumber, accessToken) => {
    const apiUrl = `/queries?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createQuery = (postBody = {}, accessToken) => {
    const apiUrl = GET_QUERIES_URL;
    return api.post(apiUrl, { ...postBody }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteQuery = (queryId, accessToken) => {
    const apiUrl = `/queries/${queryId}`;
    return api.delete(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getJob = (jobId, accessToken) => {
    const apiUrl = `/queryjobs/${jobId}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createReportTemplate = (postBody = {}, accessToken) => {
    const apiUrl = 'report-templates';
    return api.post(apiUrl, { ...postBody }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getReportTemplates = (pageNumber, accessToken) => {
    const apiUrl = `/report-templates?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteReportTemplate = (templateId, accessToken) => {
    const apiUrl = `/report-templates/${templateId}`;
    return api.delete(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getReportQueryTemplates = (templateId, pageNumber, accessToken) => {
    const apiUrl = `/report-templates/${templateId}/queries?pageSize=${PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createReportTemplateQuery = (templateId, accessToken) => {
    const apiUrl = `report-templates/${templateId}/queries`;
    return api.post(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateReportTemplate = (templateId, putBody, accessToken) => {
    const apiUrl = `report-templates/${templateId}`;
    return api.put(apiUrl, { ...putBody }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const deleteReportTemplateQuery = (templateId, queryId, accessToken) => {
    const apiUrl = `/report-templates/${templateId}/queries/${queryId}`;
    return api.delete(apiUrl, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  /****************** Appointments *************************/
  const getAppointments = (pageNumber, accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL + `?pageSize=${APPOINTMENTS_PAGE_SIZE}&pageNumber=${pageNumber}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getAllAppointments = (start, end, pageNumber, accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL + `?pageSize=${APPOINTMENTS_PAGE_SIZE}&pageNumber=${pageNumber}&to=${end}&from=${start}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getAccountAppointments = (userId, start, end, pageNumber, accessToken) => {
    const apiUrl = GET_ACCOUNTS_URL + `${userId}/appointments?pageSize=${APPOINTMENTS_PAGE_SIZE}&pageNumber=${pageNumber}&to=${end}&from=${start}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getClientAppointments = (clientId, start, end, pageNumber, accessToken) => {
    const apiUrl = GET_CLIENTS_URL + `${clientId}/appointments?pageSize=${APPOINTMENTS_PAGE_SIZE}&pageNumber=${pageNumber}&to=${end}&from=${start}`;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const createAppointment = (postBody = {}, accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL;
    return api.post(apiUrl, { ...postBody }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const updateAppointment = (appointmentId, putBody = {}, accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL + appointmentId;
    return api.put(apiUrl, { ...putBody }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const cancelAppointment = (appointmentId, accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL + appointmentId + '/cancel';
    return api.post(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const getAccountAppointment = (appointmentId,  accessToken) => {
    const apiUrl = GET_APPOINTMENTS_URL + appointmentId;
    return api.get(apiUrl, null, { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }


  const getAccountsClientUrl = (clientId,  accessToken) => {
    const apiUrl = GET_ACCOUNT_CLIENT_URL + 'token/generation';
    return api.post(apiUrl, { clientId } , { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }

  const sendClientReferral = (clientId,  accessToken) => {
    const apiUrl = GET_CLIENTS_URL + `${clientId}/send/form`;
    return api.post(apiUrl, { clientId } , { headers: { 'Authorization': `Bearer ${accessToken}` } })
  }
  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    login,
    signUp,
    logout,
    sendEmail,
    updatePassword,
    validatePassword,
    getTokenInfo,
    getUserInfo,
    updateUserInfo,
    getUsers,
    createUser,
    deleteUser,

    getClients,
    getClient,
    createClient,
    updateClient,
    deleteClient,
    searchClients,
    getReentryIntakeInformation,
    updateReEntryIntakeIdentifyingInformation,
    updateReEntryIntakeIncomeInformation,
    updateReEntryIntakeEmploymentSearchInformation,
    updateReEntryIntakeFamilyInformation,
    updateReEntryIntakeHousingInformation,
    updateReEntryIntakeImmediateNeedsInformation,
    updateReEntryIntakeIncarcerationInformation,
    updateReEntryIntakeLegalHistoruInformation,
    updateReEntryIntakePersonalHealthInformation,
    updateReEntryIntakeSkillsAndAbilitiesInformation,
    updateReEntryIntakeTransportationInformation,
    
    getAccounts,
    getAccount,
    createAccount,
    updateAccount,
    deleteAccount,
    referAccount,
    referClient,

    getBfetInformation,
    updateBfetEmploymentGoalsInformation,
    updateBfetEmploymentHistoryInformation,
    updateBfetEmploymentPlanInformation,
    updateBfetAssessedEmploymentBarriersInformation,
    updateBfetDeclarationAndSignatureInformation,
    updateBfetSkillsAndQualificationInformation,

    getClientConsentInformation,
    updateClientConsentInformation,

    getClientDVRServiceDeliveryInformation,
    getClientDVRVocationalInformation,
    getClientBFETSuppliesInformation,
    updateClientBFETSuppliesInformation,
    updateClientDVRServiceDeliveryInformation,
    updateClientDVRVocationalInformation,

    //Notes
    getClientVisitorNotesInformation,
    postClientVisitorNotesInformation,
    updateClientVisitorNotesInformation,
    deleteClientVisitorNotesInformation,

    //Docs
    getClientDocumentsInformation,
    postClientDocumentsInformation,
    deleteClientDocumentsInformation,
    uploadFile,
    uploadDocumentFileSuccess,

    postClientPicture,
    deleteClientPicture,
    postClientPictureSuccessCallback,

    //MentalHealth
    getClientMentalInformation,
    updateClientMentalDemographics,
    updateClientMentalDevelopment,
    updateClientMentalEducation,
    updateClientMentalEmployment,
    updateClientMentalFamilyInformation,
    updateClientMentalLegal,
    initiateClientMentalLegalForm,
    initiateClientMentalLegalExemptionForm,
    clientMentalLegalExemptionFormUploadSuccess,
    clientMentalLegalFormUploadSuccess,
    updateClientMentalPrimaryReasonsForSeekingService,
    updateClientMentalSocialRelationship,
    updateClientMentalSpiritual,
    updateClientMentalMilitary,
    updateClientMentalLeisure,
    updateClientMentalMedicalHealth,
    updateClientMentalAdditionalInformation,
    updateClientMentalChemicalUseHistory,
    updateClientMentalNutrition,
    updateClientMentalMedicalHistory,
    updateClientMentalTreatmentHistory,

    getClientBfetJobSearchInformation,
    getClientBfetJobSearchEmployersInformation,
    postClientBfetJobSearchEmployerInformation,
    updateClientBfetJobSearchEmployerInformation,
    deleteClientBfetJobSearchEmployerInformation,
    updateClientBfetJobSearchInformation,
    initUploadClientBfetJobSearchEmployerForm,
    uploadClientBfetJobSearchEmployerFormSuccess,

    getAppointments,
    getAllAppointments,
    getAccountAppointment,
    getAccountAppointments,
    getClientAppointments,
    createAppointment,
    updateAppointment,
    cancelAppointment,

    getRoles,
    createRole,
    updateRole,
    deleteRole,

    getAccountSuppliesInformation,
    getClientSuppliesInformation,
    postClientSuppliesInformation,
    updateClientSuppliesInformation,

    getAccountsClientUrl,
    sendClientReferral,

    getSearchMetaData,
    getQuery,
    deleteQuery,
    getQueries,
    createQuery,
    getJob,
    createReportTemplate,
    getReportTemplates,
    getReportQueryTemplates,
    createReportTemplateQuery,
    updateReportTemplate,
    deleteReportTemplate,
    deleteReportTemplateQuery
  }
}

const c = {
  create
}

// let's return back our create method as the default.
export default c