import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat, isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class SkillsAndAbility extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Skills And Ability:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable">
      <Row className="pb-2 w-100">
        <Col>Reentry &gt; Intake &gt; Skills And Ability:</Col>
        {write && (
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
        )}
        <ReactToPrint
        // eslint-disable-next-line
          trigger={() => <a href="javascript:void();">Print</a>}
          content={() => this.componentRef}
        />
      </Row>
        <div>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                List any certifications/licenses you have:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.certificatesOrLicenses}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                List any specialized equipment you know how to use:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.equipmentsSpecialized}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>
                List any specialized software you are familiar with:
              </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.softwaresSpecialized}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Can you type? </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.canType)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>How many Words Per Minute (WPM)?</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.numberOfTypeingWordsPerMinute}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Do you have 10-key experience? </LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.has10KeyExperience)}</label>
            </Col>
          </Row>
          <Row className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Are you comfortable on computers?</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.isComfortableWithComputers)}</label>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>PC</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.isComfortableWithPC)}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>MAC</LightGrayLabel>
              </Col>
              <Col className="col-12">
                <label className="input-label">{humanFormat(data.isComfortableWithMAC)}</label>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    );
  }
}
