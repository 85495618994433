import styled from 'styled-components';
import { PTag } from 'styles/GenericStyles'

export const LeftWrapper = styled.div`
  background-color: ${props => props.theme.primaryBgColor};
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 15%;
`

export const RightWrapper = styled.div`
  background-color: ${props => props.theme.loginSecondaryBgColor};
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 20%;
`

export const WelcomeText = styled.p`
  font-size: 34px;
  color: white;
  font-family: PoppinsBold;
`

export const SignInText = styled.h1`
  color: #333333;
  text-align: left;
  font-family: PoppinsBold;
`

export const WelcomeTextMessage = styled(PTag)`
  font-size: 24px;
  color: white;
  margin-top: 48px;
`
export const HorizontalLine = styled.span`
  border: 3px solid ${props => props.color ? props.color :' white'};
  width: 18%;
`

export const LoginForm = styled.div`
  width: 100%;
  margin-top: 53px;
`