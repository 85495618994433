import React from 'react';
import { Formik, Form } from 'formik';
import BaseForm from 'components/Patient/BaseForm'
import { Checkbox } from 'components/Fields/Checkbox'
import { TextInput } from 'components/Fields/TextInput'
import { Row, Col, Table } from 'react-bootstrap'
import { CLIENT_MENTAL_FORM_TYPES } from 'redux/ClientRedux'
import { Prompt } from 'react-router-dom'
import Strings from 'i18n/en'
import { LightGrayLabel } from 'styles/GenericStyles';

export default class Nutrition extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (values) => {
    this.props.onUpdate(this.props.clientID, {...values, clientId: this.props.clientID }, CLIENT_MENTAL_FORM_TYPES.nutrition)
  }

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className='row mr-1'>
              <BaseForm handleSubmit={this.handleSubmit} updatingForm={updatingForm} disabled={!write}>
                <div className="w-100">
                  <Row>
                    <Col className='p-0'>
                      <Table borderless>
                        <thead>
                          <tr className='col'>
                            <th className='col-1'>Meals</th>
                            <th className='col-2'>How Often</th>
                            <th className='col-5'>Typical Foods Eaten</th>
                            <th className='col-4'>Typical Amount Eaten</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='col'>
                            <td className='align-middle'>Breakfast</td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' type='number' name='breakfastHowOften' placeholder='0'/>
                            </td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' name='breakfastFoodsEaten' type='text' placeholder='Foods Eaten'/>
                            </td>
                            <td className='align-middle'>
                              <div className="d-flex flex-wrap">
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='noBreakfastEaten'> None</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='lowBreakfastEaten'> Low</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='mediumBreakfastEaten'> Med</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='highBreakfastEaten'> High</Checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr className='col'>
                            <td className='align-middle'>Lunch</td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' type='number' name='lunchHowOften' placeholder='0'/>
                            </td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' name='lunchFoodsEaten' type='text' placeholder='Foods Eaten'/>
                            </td>
                            <td className='align-middle'>
                              <div className="d-flex flex-wrap">
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='noLunchEaten'> None</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='lowLunchEaten'> Low</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='lunchFoodAmountMed'> Med</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='mediumLunchEaten'> High</Checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr className='col'>
                            <td className='align-middle'>Dinner</td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' type='number' name='dinnerHowOften' placeholder='0'/>
                            </td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' name='dinnerFoodsEaten' type='text' placeholder='Foods Eaten'/>
                            </td>
                            <td className='align-middle'>
                              <div className="d-flex flex-wrap">
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='noDinnerEaten'> None</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='lowDinnerEaten'> Low</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='mediumDinnerEaten'> Med</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='highDinnerEaten'> High</Checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr className='col'>
                            <td className='align-middle'>Snacks</td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' type='number' name='snacksHowOften' placeholder='0'/>
                            </td>
                            <td className='align-middle'>
                              <TextInput className='mb-0' name='snacksFoodsEaten' type='text' placeholder='Foods Eaten'/>
                            </td>
                            <td className='align-middle'>
                              <div className="d-flex flex-wrap">
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='noSnacksEaten'> None</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='lowSnacksEaten'> Low</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='mediumSnacksEaten'> Med</Checkbox>
                                <Checkbox labelClassName='text-wrap d-flex align-items-center' name='highSnacksEaten'> High</Checkbox>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    )
  }
}