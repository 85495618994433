
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray } from 'formik';
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import ReportActions from 'redux/ReportRedux'
import { LightGrayLabel } from "styles/GenericStyles";
import { RadioButton } from 'components/Fields/RadioButton';
import { Select } from 'components/Fields/Select';
import BooleanUI from 'components/DataTypes/BooleanUI'
import NestedBooleanUI from 'components/DataTypes/NestedBooleanUI'
import NumericUI from 'components/DataTypes/NumericUI';
import NestedNumericUI from 'components/DataTypes/NestedNumericUI';
import StringUI from 'components/DataTypes/StringUI';
import NestedStringUI from 'components/DataTypes/NestedStringUI';
import DateUI from 'components/DataTypes/DateUI';
import NestedDateUI from 'components/DataTypes/NestedDateUI';
import ArrayStringUI from 'components/DataTypes/ArrayStringUI';
import NestedArrayStringUI from 'components/DataTypes/NestedArrayStringUI';
import Strings from 'i18n/en'
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput';

export class QueryModal extends React.Component {
  render () {
    let { isReadReportsEnabled, types, allowedTypesMap, filterMetaData, template, accounts, updateReportTemplate, createQuery, onClose, reportType } = this.props
    let outputColumns = (template && template.outputColumns) || []
    
    let req = template && template.request
    const icFilters = req && req.includedFilters && req.includedFilters.filters && req.includedFilters.filters.map((iFilter) => {
      if (iFilter.isNested) {
        const oFilter = iFilter.filters && iFilter.filters.find((f) => f.type === 'outer')
        const nFilters = iFilter.filters && iFilter.filters.filter((f) => f.type !== 'outer')
        return { ...iFilter, filters: nFilters, ...oFilter }
      }

      return iFilter;
    })

    req = { 'includedFilters': { 'filterOperator': req.includedFilters.filterOperator, filters: icFilters } }
    let request = req || { 'includedFilters' : { 'filterOperator': 'AND', 'filters': []} }
    let iValues = { ...request, outputColumns }

    const filterOperations = [
      { key: 'AND', value: 'AND' },
      { key: 'OR', value: 'OR' },
    ];

    const validationSchema = Yup.object().shape({
      'outputColumns': Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("is required"),
          parentType: Yup.string().required("is required"),
          filterType: Yup.string().required("is required")
        })
      ),
      'includedFilters': Yup.object().shape({
        'filterOperator': Yup.string().required("is required"),

        'filters': Yup.array().of(
          Yup.object().shape({
            filterOperator: Yup.string().optional(),
            isNested: Yup.boolean().optional(),
            filters: Yup.array().of(
              Yup.object().shape({
                parentType: Yup.string().required("is required"),
                filterType: Yup.string().required("is required"),
                dataType: Yup.string().required("is required"),
                name: Yup.string().required("is required"),
                operator: Yup.string().required("is required"),
                value: Yup.mixed()
                  .when(['dataType', 'operator'], {
                    is: (dataType, operator) => {
                      if (dataType === 'DATE_TIME' && operator) {
                        return !(allowedTypesMap[dataType][operator] && allowedTypesMap[dataType][operator]['dateTypes'] && allowedTypesMap[dataType][operator]['dateTypes'].length === 0)
                      }
                      
                      return (dataType === 'NUMERIC' ||  dataType === 'STRING' || dataType === 'BOOLEAN' || dataType === 'ARRAY_STRING')
                    },
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.notRequired()
                  })
                })
            ).when('isNested', {
              is: true,
              then: (schema) => schema.min(1)
            }),
            parentType: Yup.string().required("is required"),
            filterType: Yup.string().required("is required"),
            dataType: Yup.string().required("is required"),
            name: Yup.string().required("is required"),
            operator: Yup.string().required("is required"),
            value: Yup.mixed()
              .when(['dataType', 'operator'], {
                is: (dataType, operator) => {
                  if (dataType === 'DATE_TIME' && operator) {
                    return !(allowedTypesMap[dataType][operator] && allowedTypesMap[dataType][operator]['dateTypes'] && allowedTypesMap[dataType][operator]['dateTypes'].length === 0)
                  }
                  
                  return (dataType === 'NUMERIC' ||  dataType === 'STRING' || dataType === 'BOOLEAN' || dataType === 'ARRAY_STRING')
                },
                then: (schema) => schema.required("is required"),
                otherwise: (schema) => schema.notRequired()
              })
          }) 
        ),
      })
    }).test('has-either-item', null, function (value) {
      const hasOutputColumns = Array.isArray(value.outputColumns) && value.outputColumns.length > 0;
      const hasFilters = value.includedFilters && Array.isArray(value.includedFilters.filters) && value.includedFilters.filters.length > 0;
      if (!hasOutputColumns && !hasFilters) {
        return this.createError({
          message: 'At least one item is required in outputColumns array or filters array',
          path: 'outputColumns', // You can use any field here, as this error is for the entire object
        });
      }
    
      return true;
    });

    return (
      <BaseFormModal heading={`${reportType === 'template' ? 'Edit Query' : 'New Query'}`} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Col md={12}>
          { isReadReportsEnabled ? (
            <Formik
              enableReinitialize
              initialValues={{ ...iValues }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                let iCFilters = values.includedFilters && values.includedFilters.filters.map((filter) => {
                  if (filter.isNested) {
                    var newFilter = JSON.parse(JSON.stringify(filter))
                    const filteredArray = newFilter.filters.filter((element) => element !== "");
                    let fs = filteredArray.map((f) => {
                      delete f.isNested
                      delete f.filterOperator
                      return f
                    });
                    const tfilterOption = newFilter.filterOperator;
                    delete newFilter.filters;
                    delete newFilter.isNested;
                    delete newFilter.filterOperator;
                    return { isNested: true, filterOperator: tfilterOption, filters: [...fs, newFilter] }
                  }
                  
                  return filter;
                })
  
                const outputColumns = values.outputColumns;
                delete values.outputColumns;
               
                const req = {
                  "request": { 'includedFilters': { filterOperator: values.includedFilters.filterOperator, filters: iCFilters } },
                  "outputColumns": outputColumns
                }

                
                if (reportType === 'template') {
                  updateReportTemplate(template.templateId, req)
                } else if (reportType === 'playground') {
                  createQuery(req)
                }
                
                resetForm();
                onClose()
              }}
            >
                {({ values, isValid, dirty, setFieldValue, resetForm }) => (
                  <Form>
                    <Row className='p-2 w-100'>
                      <Row className='w-100'>
                        <Col className='col-2'><LightGrayLabel>Report Columns:</LightGrayLabel></Col>
                        <Col className='col-10'>
                          <FieldArray
                            name="outputColumns"
                            render={(arrayHelpers) => (
                              <>
                                {
                                  values.outputColumns && values.outputColumns.length > 0 ? (
                                    values.outputColumns.map((column, index) => (
                                      <Row className='mb-2'>

                                        <Col className='col-2'>
                                          <Select
                                            name={`outputColumns.${index}.parentType`}
                                            className="custom-select"
                                            onChange={(event) => {
                                              const selectedType = event.target.value;
                                              setFieldValue(`outputColumns.${index}.parentType`, selectedType);
                                              setFieldValue(`outputColumns.${index}.filterType`, '');
                                            }}
                                          >
                                            <option>Select</option>
                                            {
                                              types.map((s) => {
                                                return <option key={s} value={s}>{s}</option>
                                              })
                                            }
                                          </Select>  
                                        </Col>

                                        { values && values.outputColumns && values.outputColumns[index].parentType && (
                                          <Col className='col-2'>
                                            <Select
                                              name={`outputColumns.${index}.filterType`}
                                              className="custom-select"
                                              onChange={(event) => {
                                                const selectedFilterType = event.target.value;
                                                setFieldValue(`outputColumns.${index}.filterType`, selectedFilterType);
                                                setFieldValue(`outputColumns.${index}.name`, '');
                                              }}
                                            >
                                              <option>Select</option>
                                              {
                                                Object.entries(filterMetaData[values.outputColumns[index].parentType]).map(([key, value]) => {
                                                  return <option key={value.displayName} value={key}>{value.displayName}</option>
                                                })
                                              }
                                            </Select>
                                          </Col>
                                        )}

                                        { values && values.outputColumns && values.outputColumns[index].parentType && values.outputColumns[index].filterType && (
                                          <Col className='col-2'>
                                            <Select
                                              name={`outputColumns.${index}.name`}
                                              className="custom-select"
                                              onChange={(event) => {
                                                setFieldValue(`outputColumns.${index}.name`, event.target.value);
                                              }}
                                            >
                                              <option>Select</option>
                                              {
                                                Object.entries(filterMetaData[values.outputColumns[index].parentType][values.outputColumns[index].filterType]['DEFAULT']).map(([key, value]) => {
                                                  return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                                })
                                              }
                                            </Select>
                                          </Col>
                                        )}

                                        { values && values.outputColumns && values.outputColumns[index].parentType && values.outputColumns[index].filterType && values.outputColumns[index].name && (
                                          <Col className='col-2'>
                                            <TextInput name={`outputColumns.${index}.displayName`} type='text' placeholder='Header(Optional)'/>
                                          </Col>
                                        )}
                                        
                                        <Col className='col-4'>
                                          <button type="button" className='btn btn-outline-danger ml-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                          { index === 0 && <button type="button" className='btn btn-outline-primary ml-2' onClick={() => arrayHelpers.insert(index, '')}> + Add </button> }
                                        </Col>
                                      </Row>
                                    ))
                                  ) : (
                                    <Row className='justify-content-between ml-3 w-100'>
                                      <Button variant="btn btn-outline-primary" onClick={() => arrayHelpers.push('')}>+ Add</Button>
                                    </Row>
                                  )
                                }
                              </>
                            )} 
                          />
                        </Col>
                      </Row>

                      <Row className='w-100 mt-2'>
                        <Col className='col-2'>
                          <LightGrayLabel>Operator:</LightGrayLabel>
                        </Col>

                        <Col className='col-10'>
                          <RadioButton
                            className='mb-3'
                            name='includedFilters.filterOperator'
                            options={filterOperations}
                            onChange={(e) => {
                              setFieldValue(`includedFilters.filterOperator`, e.target.value)
                              values.includedFilters.filters && values.includedFilters.filters.map((f, i) => {
                                if (f.isNested) {
                                  setFieldValue(`includedFilters.filters.${i}.filterOperator`, e.target.value === 'OR' ? 'AND' : 'OR')
                                }
                              })
                            }}
                          />
                        </Col>
                      </Row>
                    </Row>
                      
                    <FieldArray
                      name="includedFilters.filters"
                      render={(arrayHelpers) => (
                        <>
                          {
                            values.includedFilters.filters && values.includedFilters.filters.length > 0 ? (
                              values.includedFilters.filters.map((filter, index) => (
                                <Row className='border border-dark rounded p-2 mt-4'>
                                  
                                  <FieldArray
                                    name={`includedFilters.filters.${index}.filters`}
                                    render={(aH) => (
                                      <>
                                      { 
                                        values.includedFilters.filters && values.includedFilters.filters[index].isNested && (
                                          <Row className='w-100 mt-2'>
                                            <Col className='col-10'>
                                              <RadioButton
                                                className='mb-3'
                                                name={`includedFilters.filters.${index}.filterOperator`}
                                                options={filterOperations} 
                                                onChange={(e) => {
                                                  values.includedFilters.filters && values.includedFilters.filters.map((f, i) => {
                                                    if (f.isNested) {
                                                      setFieldValue(`includedFilters.filters.${i}.filterOperator`, e.target.value)
                                                    }
                                                  })
                                                  setFieldValue(`includedFilters.filterOperator`, e.target.value === 'OR' ? 'AND' : 'OR')
                                                }}
                                              />
                                            </Col>
                                          </Row>)
                                      }

                                      {
                                        values.includedFilters.filters[index].filters && values.includedFilters.filters[index].filters && values.includedFilters.filters[index].filters.length > 0 ? (
                                          filter.filters.map((nF, ind) => (
                                            <Row className='m-2 p-2 w-100 rounded border border-info'>
                                              <Row className='w-100'>
                                                <Col className='col-4'>
                                                  <Select
                                                    name={`includedFilters.filters.${index}.filters.${ind}.parentType`}
                                                    className="custom-select mt-2"
                                                    onChange={(event) => {
                                                      const selectedType = event.target.value;
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.parentType`, selectedType);
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.filterType`, '');
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, null);
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                    }}
                                                  >
                                                    <option>Select</option>
                                                    {
                                                      types.map((s) => {
                                                        return <option key={s} value={s}>{s}</option>
                                                      })
                                                    }
                                                  </Select>  
                                                </Col>

                                                { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].parentType && (
                                                  <Col className='col-4'>
                                                    <Select
                                                      name={`includedFilters.filters.${index}.filters.${ind}.filterType`}
                                                      className="custom-select mt-2"
                                                      onChange={(event) => {
                                                        const selectedFilterType = event.target.value;
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.filterType`, selectedFilterType);
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, null);
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.name`, '');
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                      }}
                                                    >
                                                      <option>Select</option>
                                                      {
                                                        Object.entries(filterMetaData[values.includedFilters.filters[index].filters[ind].parentType]).map(([key, value]) => {
                                                          return <option key={value.displayName} value={key}>{value.displayName}</option>
                                                        })
                                                      }
                                                    </Select>
                                                  </Col>
                                                )}
                                            
                                                { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].parentType && values.includedFilters.filters[index].filters[ind].filterType && (
                                                  <Col className='col-4'>
                                                    <Select
                                                      name={`includedFilters.filters.${index}.filters.${ind}.name`}
                                                      className="custom-select mt-2"
                                                      onChange={(event) => {                                                      
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.name`, event.target.value);
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.dataType`, event.target.options[event.target.selectedIndex].dataset.dtype)
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, null)
                                                        setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value1`, null)
                                                      }}
                                                    >
                                                      <option>Select</option>
                                                      {
                                                        Object.entries(filterMetaData[values.includedFilters.filters[index].filters[ind].parentType][values.includedFilters.filters[index].filters[ind].filterType]['DEFAULT']).map(([key, value]) => {
                                                          return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                                        })
                                                      }
                                                    </Select>
                                                  </Col>
                                                )}
                                              </Row>

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'BOOLEAN' && (
                                                <NestedBooleanUI 
                                                  values={values}
                                                  index={index}
                                                  ind={ind}
                                                  allowedTypesMap={allowedTypesMap}
                                                  onOperatorChange={(v) => {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                    }
                                                  }}
                                                  onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, v)} 
                                                />
                                              )}

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'NUMERIC' && (
                                                <NestedNumericUI 
                                                  values={values}
                                                  index={index}
                                                  ind={ind}
                                                  allowedTypesMap={allowedTypesMap}
                                                  onOperatorChange={(v) => {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                    }
                                                  }}
                                                />
                                              )}

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && (values.includedFilters.filters[index].filters[ind].dataType === 'STRING') && (
                                                <NestedStringUI 
                                                  values={values}
                                                  index={index}
                                                  ind={ind}
                                                  accounts={accounts}
                                                  allowedTypesMap={allowedTypesMap}
                                                  onOperatorChange={(v) => {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                    }
                                                  }}
                                                />
                                              )}

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'ARRAY_STRING' && (
                                                <NestedArrayStringUI 
                                                  values={values}
                                                  index={index}
                                                  ind={ind}
                                                  allowedTypesMap={allowedTypesMap}
                                                  onOperatorChange={(v) => {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                    }
                                                  }}
                                                />
                                              )}

                                              { values && values.includedFilters.filters[index].filters[ind] && values.includedFilters.filters[index].filters[ind].dataType && values.includedFilters.filters[index].filters[ind].dataType === 'DATE_TIME' && (
                                                <NestedDateUI 
                                                  values={values}
                                                  index={index}
                                                  ind={ind}
                                                  allowedTypesMap={allowedTypesMap}
                                                  onOperatorChange={(v) => {
                                                    setFieldValue(`includedFilters.filters.${index}.filters.${ind}.operator`, v)
                                                    if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.value`, ' ')
                                                    }
                                                  }}
                                                  onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.filters.${ind}.valueType`, v)}
                                                />
                                              )}

                                              <Row className='mt-2'>
                                                <button type="button" className='btn btn-outline-danger ml-3'
                                                  onClick={() => {
                                                    aH.remove(index)
                                                    index === 0 && setFieldValue(`includedFilters.filters.${index}.isNested`, false)
                                                  }
                                                }>Remove</button>

                                                { ind === 0 && 
                                                  <button 
                                                    type="button" 
                                                    className='btn btn-outline-info ml-2' 
                                                    disabled={!isValid || !dirty} 
                                                    onClick={() => {
                                                      aH.insert(index, '')
                                                      setFieldValue(`includedFilters.filters.${index}.isNested`, true)
                                                      setFieldValue(`includedFilters.filters.${index}.filterOperator`, values.includedFilters.filterOperator === 'AND' ? 'OR' : 'AND')
                                                      setFieldValue(`includedFilters.filters.${index}.filters.${ind}.type`, 'inner')

                                                    }}> + Inner Nested
                                                  </button> }
                                              </Row>
                                            </Row>
                                          ))
                                        ) : (
                                          <Row className='ml-2 mt-3 w-100'>
                                            <Button 
                                              variant="btn btn-outline-info" 
                                              disabled={!isValid || !dirty}
                                              onClick={() => {
                                                aH.push('')
                                                setFieldValue(`includedFilters.filters.${index}.isNested`, true)
                                                setFieldValue(`includedFilters.filters.${index}.filterOperator`, values.includedFilters.filterOperator === 'AND' ? 'OR' : 'AND')
                                                setFieldValue(`includedFilters.filters.${index}.fiters.0.type`, 'inner')

                                              }}>+ Inner Nested
                                            </Button>
                                          </Row>
                                        )
                                      }
                                      </>
                                    )}
                                  />
                                  <Row className='ml-2 w-100'>
                                    <Row className='w-100'>
                                      <Col className='col-4'>
                                        <Select
                                          name={`includedFilters.filters.${index}.parentType`}
                                          className="custom-select mt-2"
                                          onChange={(event) => {
                                            const selectedType = event.target.value;
                                            setFieldValue(`includedFilters.filters.${index}.parentType`, selectedType);
                                            setFieldValue(`includedFilters.filters.${index}.filterType`, '');
                                            setFieldValue(`includedFilters.filters.${index}.dataType`, null);
                                            setFieldValue(`includedFilters.filters.${index}.value`, null)
                                            setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                          }}
                                        >
                                          <option>Select</option>
                                          {
                                            types.map((s) => {
                                              return <option key={s} value={s}>{s}</option>
                                            })
                                          }
                                        </Select>  
                                      </Col>

                                      { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && (
                                        <Col className='col-4'>
                                          <Select
                                            name={`includedFilters.filters.${index}.filterType`}
                                            className="custom-select mt-2"
                                            onChange={(event) => {
                                              const selectedFilterType = event.target.value;
                                              setFieldValue(`includedFilters.filters.${index}.filterType`, selectedFilterType);
                                              setFieldValue(`includedFilters.filters.${index}.dataType`, null);
                                              setFieldValue(`includedFilters.filters.${index}.name`, '');
                                              setFieldValue(`includedFilters.filters.${index}.value`, null)
                                              setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                            }}
                                          >
                                            <option>Select</option>
                                            {
                                              Object.entries(filterMetaData[values.includedFilters.filters[index].parentType]).map(([key, value]) => {
                                                return <option key={value.displayName} value={key}>{value.displayName}</option>
                                              })
                                            }
                                          </Select>
                                        </Col>
                                      )}
                                  
                                      { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].parentType && values.includedFilters.filters[index].filterType && (
                                        <Col className='col-4'>
                                          <Select
                                            name={`includedFilters.filters.${index}.name`}
                                            className="custom-select mt-2"
                                            onChange={(event) => {
                                              setFieldValue(`includedFilters.filters.${index}.name`, event.target.value);
                                              setFieldValue(`includedFilters.filters.${index}.dataType`, event.target.options[event.target.selectedIndex].dataset.dtype)
                                              setFieldValue(`includedFilters.filters.${index}.value`, null)
                                              setFieldValue(`includedFilters.filters.${index}.value1`, null)
                                            }}
                                          >
                                            <option>Select</option>
                                            {
                                              Object.entries(filterMetaData[values.includedFilters.filters[index].parentType][values.includedFilters.filters[index].filterType]['DEFAULT']).map(([key, value]) => {
                                                return <option key={value.displayName} value={key} data-dtype={value.dataType}>{value.displayName}</option>
                                              })
                                            }
                                          </Select>
                                        </Col>
                                      )}
                                    </Row>

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'BOOLEAN' && (
                                      <BooleanUI 
                                        values={values}
                                        index={index}
                                        allowedTypesMap={allowedTypesMap}
                                        onOperatorChange={(v) => {
                                          setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                          if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                            setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                          }
                                        }}
                                        onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.value`, v)} 
                                      />
                                    )}

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'NUMERIC' && (
                                      <NumericUI 
                                        values={values}
                                        index={index}
                                        allowedTypesMap={allowedTypesMap}
                                        onOperatorChange={(v) => {
                                          setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                          if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                            setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                          }
                                        }}
                                      />
                                    )}

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].dataType && (values.includedFilters.filters[index].dataType === 'STRING') && (
                                      <StringUI 
                                        accounts={accounts}
                                        values={values}
                                        index={index}
                                        allowedTypesMap={allowedTypesMap}
                                        onOperatorChange={(v) => {
                                          setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                          if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                            setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                          }
                                        }}
                                      />
                                    )}

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'ARRAY_STRING' && (
                                      <ArrayStringUI 
                                        values={values}
                                        index={index}
                                        allowedTypesMap={allowedTypesMap}
                                        onOperatorChange={(v) => {
                                          setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                          if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                            setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                          }
                                        }}
                                      />
                                    )}

                                    { values && values.includedFilters.filters[index] && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'DATE_TIME' && (
                                      <DateUI 
                                        values={values}
                                        index={index}
                                        allowedTypesMap={allowedTypesMap}
                                        onOperatorChange={(v) => {
                                          setFieldValue(`includedFilters.filters.${index}.operator`, v)
                                          if (v === 'DOES_NOT_EXIST' || v === 'EXISTS') {
                                            setFieldValue(`includedFilters.filters.${index}.value`, ' ')
                                          }
                                        }}
                                        onNegateChange={(v) => setFieldValue(`includedFilters.filters.${index}.valueType`, v)}
                                      />
                                    )}

                                    <Row className='mt-2'>
                                      <button type="button" className='btn btn-outline-danger ml-3' onClick={() => arrayHelpers.remove(index)}> Remove </button>
                                      { index === 0 && <button 
                                        type="button" 
                                        className='btn btn-outline-primary ml-2' 
                                        onClick={() => {
                                          arrayHelpers.insert(index, '')
                                          setFieldValue(`includedFilters.filters.${index}.type`, 'outer')
                                        }}> + Outer Filter </button> }
                                    </Row>
                                  </Row>
                                </Row>
                              ))
                            ) : (
                              <Row className='mt-3 w-100'>
                                <Button 
                                  variant="btn btn-outline-primary" 
                                  onClick={() => {
                                    arrayHelpers.push('')
                                    setFieldValue(`includedFilters.filters.0.type`, 'outer')
                                  }}>+ Outer Filter</Button>
                              </Row>
                            )
                          }
                        </>
                      )}
                    />

                    <Row className='mt-3'>
                      <Button variant="primary" type='submit' disabled={!isValid} >{reportType === 'template' ? 'Update Report' : 'Get Report'}</Button>
                      <Button 
                        variant="btn btn-outline-secondary"
                        className='ml-2' 
                        onClick={() => { 
                          resetForm();
                          this.props.onClose()
                        }}>Cancel
                      </Button>
                    </Row>
                  </Form>
                )}
            </Formik>
          ) : <Row>{Strings.permissions.message}</Row> }
        </Col>
      </BaseFormModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isReadReportsEnabled: isPermissionEnabled(state.user, PERMISSIONS.REPORT_QUERIES),
    types: state.report.types,
    allowedTypesMap: state.report.allowedTypesMap,
    filterMetaData: state.report.filterMetaData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuerySuccess: (query) => dispatch(ReportActions.getQuerySuccess(query)),
    updateReportTemplate: (templateId, params) => dispatch(ReportActions.updateReportTemplate(templateId, params)),
    createQuery: (params) => dispatch(ReportActions.createQuery(params)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryModal);