
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'
import Select, { components }  from 'react-select';
import { LightGrayLabel } from "styles/GenericStyles";

export class EditUser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      role: props.account.roles,
      selectedOption: null
    }
  }

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption,
      role: selectedOption.map(value => value.value)
    })
  }

  getRoleNames = (accountRoles) => {
    const { roles } = this.props
    return accountRoles.map((ar) => roles.find((r) => r.accountRoleId === ar).roleName ).join(', ')
  }

  handleSubmit = (params) => {
    let p = { ...params, roles: this.state.role }
    this.props.updateAccount(this.props.account.accountUserId, p)
    this.props.toggleEditClientModal(null)
  }
  
  render () {
    const { account, roles } = this.props

    let options = []

    roles && roles.forEach((role) => {
      options.push({ value: role.accountRoleId, label: role.roleName })
    })

    const SingleValue = ({ children, ...props }) => (
      <components.SingleValue {...props}>
        {children}
      </components.SingleValue>
    );

    return (
      <BaseFormModal heading={'Edit User'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Formik
          initialValues={{
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            password: account.password,
            role: account.role
          }}

          validationSchema={
            Yup.object({
              firstName: Yup.string().required('First name cannot be empty'),
              lastName: Yup.string().required('Last name cannot be empty'),
              email: Yup
                .string()
                .email('Invalid email format').required('Email cannot be empty')
            })
          }
          onSubmit={this.handleSubmit}
        >
            <Form>
              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'>First Name:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextInput className='mr-3 w-100' name='firstName' type='text' placeholder='First Name'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'>Last Name:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextInput className='mr-3 w-100' name='lastName' type='text' placeholder='Last Name'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'>Email:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextInput className='mr-3 w-100' name='email' type='text' placeholder='Email'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'>Password:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextInput className='mr-3 w-100' name='password' type='password' placeholder='Password'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'>Role:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <Select
                    className="col-md-12 pl-0 col-offset-4"
                    name="role"
                    options={options}
                    styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, background: this.state.selectedOption.value, color: 'black', display: 'flex' }) }}
                    components={{ SingleValue }}
                    onChange={this.handleChange}
                    isMulti
                  />
                </Col> 
              </Row>
              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='firstName'></label>
                </Col>
                <Col className='col-10 d-flex'>
                <LightGrayLabel>
                  <i>Existing Roles: {  this.getRoleNames(this.state.role) } </i>
                </LightGrayLabel>
                </Col> 
              </Row>
              <Button size="md" className='float-right' variant="primary" type='submit'>Update</Button>
            </Form>
        </Formik>
      </BaseFormModal>
    )
  }
}

export default EditUser;