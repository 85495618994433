import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { humanFormat } from "utils/genericUtils";

import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class Incarceration extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">Reentry &gt; Intake &gt; Incarceration:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    let name = (data.coOfficerName || "") + " " + (data.coPhoneNumber || "");

    return (
      <Row
        ref={(el) => (this.componentRef = el)}
        className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
      >
        <Row className="pb-2 w-100">
          <Col>Reentry &gt; Intake &gt; Incarceration</Col>
          {write && (
            <Col className="d-flex justify-content-end">
              <Link to={link}>Edit</Link>
            </Col>
          )}
          <ReactToPrint
          // eslint-disable-next-line
            trigger={() => <a href="javascript:void();">Print</a>}
            content={() => this.componentRef}
          />
        </Row>
        <Row className="col-12 row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
          <Row>
            <Col className="col-12">
              <LightGrayLabel>DOC Number</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.docNumber}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>Institution</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.institution}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>Unit</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.unit}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>Release Date</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.releaseDate}</label>
            </Col>
          </Row>

          <Row className="mb-3 pl-3">
            <Row>
              <Col className="col-12">
                <LightGrayLabel>Length of Incarceration</LightGrayLabel>
              </Col>
              <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
                <label className="input-label">
                  {humanFormat(data.courtOrdered)}
                </label>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>Name & Phone of CO Officer:</LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{name}</label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Approximate number of times incarcerated in your lifetime:
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.numberOfTimesIncarceratedInLifetime}
              </label>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Which County/State are you returning to upon reentry?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">{data.incarceratedCountry}</label>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Who is in your support team during your current
                incarceration(s)?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.supportTeamDuringCurrentIncarceration}
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <LightGrayLabel>
                Who is in your support team returning home?
              </LightGrayLabel>
            </Col>
            <Col className="ml-3 col-lg-10 col-sm-6 col-md-8 p-0">
              <label className="input-label">
                {data.supportTeamReturningHome}
              </label>
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
}
