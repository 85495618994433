import React from 'react';
import { Select } from 'components/Fields/Select';
import { Row, Col } from 'react-bootstrap';

export default class BooleanUI extends React.Component {


  render () {
    const { values, allowedTypesMap, onOperatorChange, onNegateChange, index } = this.props
    var BOOLEAN_VALUES = ['True', 'False'];

    return (
      <Row className='mt-2 w-100'>
        <Col className='col-6'>
          <Select
            name={`includedFilters.filters.${index}.operator`}
            className="custom-select mt-2"
            onChange={(event) => onOperatorChange(event.target.value)}
          >
            <option>Select</option>
            {
              Object.entries(allowedTypesMap[values.includedFilters.filters[index].dataType]).map(([key, value]) => {
                return <option key={key} value={key}>{value.displayName}</option>
              })
            }
          </Select>
        </Col>
        
        { values && values.includedFilters.filters[index].dataType && values.includedFilters.filters[index].dataType === 'BOOLEAN' && (values.includedFilters.filters[index].operator === 'IS' || values.includedFilters.filters[index].operator === 'IS_NOT') && (
          <Col className='col-6'>
            <Select
              name={`includedFilters.filters.${index}.value`}
              className="custom-select mt-2"
              onChange={(event) => onNegateChange(event.target.value)}
            >
              <option>Select</option>
              {
                BOOLEAN_VALUES.map(v => {
                  return <option key={v} value={v}>{v}</option>
                })
              }
            </Select>
          </Col>
        )}
      </Row>
    )
  }
}