import { REHYDRATE } from 'redux-persist/constants'
import { switchMap, filter, mapTo, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, concat, from } from 'rxjs'
import UserActions, { UserTypes } from "redux/UserRedux";
import AuthActions, { AuthTypes } from "redux/AuthRedux"
import AppActions from 'redux/AppRedux'
import AlertActions from 'redux/AlertRedux'

export const appLoadEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(REHYDRATE),
    switchMap((action) => {
      let { user } = action.payload
      const token = window.location.search && window.location.search.split('?token=')[1]
      if (!(user && user.jwt)) {
        user = store.value.user
      }
      
      if (token || (user && user.jwt)) {
        return from(api.getTokenInfo(token || user.jwt)).pipe(
          switchMap(response => {
            if (response.ok) {
              return of(
                UserActions.userTokenValid(true, response.data)
              )
            } else {
              return of(
                UserActions.userTokenValid(false, null)
              )
            }
          })
        )
      } else {
        return of(
          UserActions.userTokenValid(false, null)
        )
      }
    })
  )

export const userTokenValidatedEpic = (action$, store) =>
  action$.pipe(
    ofType(UserTypes.USER_TOKEN_VALID),
    mapTo(AppActions.setLoading(false))
  )

export const userTokenInValidEpic = (action$, store) =>
  action$.pipe(
    ofType(UserTypes.USER_TOKEN_VALID),
    filter((action) => !action.valid),
    mapTo(AuthActions.logoutSuccess())
  )

export const userTokenValidEpic = (action$, store) =>
  action$.pipe(
    ofType(UserTypes.USER_TOKEN_VALID),
    filter((action) => !!action.valid),
    switchMap((action) => {
      return of(
        UserActions.updateUser(action.user.user),
        UserActions.updateUserToken(action.user.jwt, action.user && action.user.tokenInfo, action.user && action.user.accountInfo, action.user && action.user.permissions, action.user && action.user.tokenType),
        AuthActions.loginSuccess(action.user.user && action.user.user.accountUserId)
      )
    })
  )

export const getUserInfoEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(UserTypes.GET_USER_INFO, AuthTypes.LOGIN_SUCCESS),
    delay(500),
    switchMap((action) => {
      const { userId } = action
      const { accessToken } = store.value.user
      return from(api.getUserInfo(userId, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(UserActions.updateUser(response.data))
          } else {
            return of(
              UserActions.getUserInfoFailure(false, null)
            )
          }
        })
      )
    })
  )

export const updateUserInfoEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(UserTypes.UPDATE_USER_INFO),
    switchMap((action) => {
      const { user, userId } = action
      const { accessToken } = store.value.user
      return from(api.updateUserInfo(userId, user, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            const { userId } = action
            const actions = []

            if (userId === store.value.user.userId) {
              actions.push(
                UserActions.updateUser(response.data)
              )
            }
            return concat(actions)
          } else {
            return of(AlertActions.error(['User update failed! Please try again.']))
          }
        })
      )
    })
  )

export const deleteUserEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(UserTypes.DELETE_USER),
    switchMap((action) => {
      const { userId } = action
      const { accessToken } = store.value.user
      return from(api.deleteUser(userId, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            const actions = [UserActions.deleteUserSuccess()]
            return concat(actions)
          } else {
            return of(AlertActions.error(['User update failed! Please try again.']))
          }
        })
      )
    })
  )
