import React from 'react';
import Layout from 'components/Layout.jsx'
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { Row, Col } from 'react-bootstrap';
import { PAGE_SIZE } from 'config/CONSTANTS'
import ReportActions from 'redux/ReportRedux'
import TemplatesListing from 'components/TemplatesListing'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import QueryModal from 'components/Modals/QueryModel'
import AccountActions from 'redux/AccountRedux'

export class Templates extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isEditingTemplate: false,
      editingTemplate: null
    }
  }

  componentDidMount () {
    this.props.getSearchMetaData()
    this.props.getReportTemplates(1)
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_USERS) && this.props.getAccounts(1)
  }


  onShowEditingQueryModal = (template) => {
    this.setState({
      isEditingTemplate: true,
      editingTemplate: template
    })
  }

  onHideEditingQueryModal = () => {
    this.setState({
      isEditingTemplate: false,
      editingTemplate: null
    })
  }


  renderLoading = () => {
    return <LoadingView />
  }

  render () {
    const { fetchingTemplates, fetchingAccounts, accounts } = this.props
    const { isEditingTemplate, editingTemplate } = this.state
    return (
      <Layout>
        { (fetchingTemplates || fetchingAccounts) && this.renderLoading() }
        { !fetchingTemplates && !fetchingAccounts && this.renderTemplates() }
        { isEditingTemplate && <QueryModal reportType={'template'} template={editingTemplate} onClose={this.onHideEditingQueryModal} onSave={this.onHideEditingQueryModal} accounts={accounts}></QueryModal>}
      </Layout>
    )
  }

  renderTemplates () {
    let { templates, templateCurrentPage, templateTotalCount, accounts } = this.props
    return (
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <h4 className='text-nowrap'>Query Templates</h4>
        </Row>

        <Row>
          <TemplatesListing
            accounts={accounts}
            templates={templates}
            currentpage={templateCurrentPage}
            totalPages={Math.ceil(templateTotalCount / PAGE_SIZE)}
            getQueries={this.props.getReportTemplates}
            onEdit={this.onShowEditingQueryModal}
            onRun={(templateId) => this.props.createReportTemplateQuery(templateId)}
          />
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    accounts: state.account.accounts,
    isReadReportsEnabled: isPermissionEnabled(state.user, PERMISSIONS.REPORT_QUERIES),
    templates: state.report.templates,
    templateCurrentPage: state.report.templateCurrentPage,
    templateTotalCount: state.report.templateTotalCount,
    fetchingTemplates: state.report.fetchingTemplates,
    fetchingAccounts: state.account.fetchingAccounts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchMetaData: () => dispatch(ReportActions.getSearchMetaData()),
    getAccounts: (pageNumber) => dispatch(AccountActions.getAccounts(pageNumber)),
    getReportTemplates: (pageNumber) => dispatch(ReportActions.getReportTemplates(pageNumber)),
    createReportTemplateQuery: (templateId) => dispatch(ReportActions.createReportTemplateQuery(templateId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);