import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from "react-to-print";

export default class PrimaryReasonsForSeekingServices extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">
            Mental Health &gt; Personal History &gt; Primary Reasons For Seeking
            Services:
          </Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
      <Row
      ref={(el) => (this.componentRef = el)}
      className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable"
    >
      <Row className="pb-2 w-100">
        <Col>
          Mental Health &gt; Personal History &gt; Primary Reasons For Seeking
            Services:
        </Col>
        {write && (
          <Col className="d-flex justify-content-end">
            <Link to={link}>Edit</Link>
          </Col>
        )}
        <ReactToPrint
        // eslint-disable-next-line
          trigger={() => <a href="javascript:void();">Print</a>}
          content={() => this.componentRef}
        />
        </Row>
        <Row className="row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Anger Management</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasAngerManagement)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Depression</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasDepression)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Mental confusion</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasMentalConfusion)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Alcohol/drugs</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.consumesAlcoholOrDrugs)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Anxiety</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasAnxiety)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Eating Disorder</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasEatingDisorder)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Sexual concerns</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasSexualConcerns)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Addictive behaviors</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasAddictiveBehaviors)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Coping</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasCoping)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Fear/phobias</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasFearOrphobias)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Sleeping problems</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{isBoolean(data.hasSleepingProblems)}</label>
            </Col>
          </Col>
          <Col className="col-12 p-0">
            <Col className="col-12">
              <LightGrayLabel>Other Mental Health Concerns</LightGrayLabel>
            </Col>
            <Col className="col-12">
              <label className="input-label">{data.otherMentalHealthConcerns}</label>
            </Col>
          </Col>
        </Row>
      </Row>
    );
  }
}
