import { Modal } from 'react-bootstrap';


export const BaseFormModal = (props) => {
  return (
    <Modal animation={false} show={true} size={props.size || 'md'} centered onHide={props.onHide}>
      <Modal.Header closeButton className="p-2 pl-4">
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  )
}

export default BaseFormModal;
