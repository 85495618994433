import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextInput } from "components/Fields/TextInput";
import { RadioButton } from "components/Fields/RadioButton";
import BaseForm from "components/Patient/BaseForm";
import { CLIENT_BFET_FORM_TYPES } from "redux/ClientRedux";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import { LightGrayLabel } from "styles/GenericStyles";

export default class DeclarationAndSignature extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (values) => {
    this.props.onUpdate(
      this.props.clientID,
      { ...values, clientId: this.props.clientID },
      CLIENT_BFET_FORM_TYPES.declarationAndSignature
    );
  };

  render() {
    const { data, updatingForm, read, write } = this.props;
    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    const LANGUAGE_OPTIONS = [
      { key: "Yes", value: true },
      { key: "No", value: false },
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={{ ...data }}
        onSubmit={this.handleSubmit}
        render={({ values, touched, dirty, errors, handleBlur, handleChange }) => (
          <>
            <Prompt when={dirty} message={Strings.prompt.message} />
            <Form className="row mr-1">
              <BaseForm updatingForm={updatingForm} disabled={!write}>
                <div>
                  {/* <Row className='mb-3'> */}
                  <Col className="p-0 col-12">
                    <p>
                      The following are the requirements to participate in the
                      Basic Food Employment & Training (BFET) program:
                    </p>
                    <ul>
                      <li>Receive Basic Food Assistance from DSHS;</li>
                      <li>
                        Not receiving Temporary Assistance for Needy Families
                        (TANF), Refugee Cash Assistance (RCA) or other cash
                        assistance under Title IV such as Tribal TANF Be able to
                        work at least 20 hours per week;
                      </li>
                      <li>
                        Cooperate with the requirements of this Individual
                        Employment Plan; and
                      </li>
                      <li>
                        Meet with your BFET case manager at least monthly.
                      </li>
                    </ul>
                  </Col>
                  {/* </Row> */}
                  {/* <Row> */}
                  <Col className="p-0 col-12">
                    <p>
                      I,{" "}
                      <TextInput
                        className="d-inline-block mx-2"
                        name="name"
                        type="text"
                        placeholder="Enter name"
                      />{" "}
                      have read the requirements and agree to abide by them.
                    </p>
                  </Col>
                  {/* </Row> */}
                  {/* <Row className='mb-3'> */}
                  <Col className="p-0 col-12">
                    <Col>
                      <label
                        className="input-label"
                        htmlFor="hasUnderstoodForm"
                      >
                        {" "}
                        I understand this form and the contents have been
                        explained to me in my primary language.
                      </label>
                    </Col>
                    <RadioButton
                      className="mb-3 ml-3"
                      name="hasUnderstoodForm"
                      options={LANGUAGE_OPTIONS}
                      onChange={handleChange}
                    />
                  </Col>
                  {/* </Row> */}

                  <Row className="row-cols-xs-12 row-cols-md-2 align-items-end" >
                    <Col className="col-7">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Client Signature"
                        name="clientSignature"
                        type="text"
                        placeholder="Signature"
                      />
                    </Col>
                    <Col className="col-5">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Date"
                        name="clientSignatureDate"
                        type="date"
                        placeholder="MM/DD/YYYY"
                      />
                    </Col>
                    <Col className="col-7">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Case Manager Signature"
                        name="clientManagerSignature"
                        type="text"
                        placeholder="Signature"
                      />
                    </Col>
                    <Col className="col-5">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Date"
                        name="clientManagerSignatureDate"
                        type="date"
                        placeholder="MM/DD/YYYY"
                      />
                    </Col>
                    <Col className="col-7">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Interpreter Signature (required if client cannot understand this form in English)"
                        name="interpreterSignature"
                        type="text"
                        placeholder="Signature"
                      />
                    </Col>
                    <Col className="col-5">
                      <TextInput
                        labelClassName="input-label text-wrap"
                        className="mr-3"
                        label="Date"
                        name="interpreterSignatureDate"
                        type="date"
                        placeholder="MM/DD/YYYY"
                      />
                    </Col>
                  </Row>
                </div>
              </BaseForm>
            </Form>
          </>
        )}
      />
    );
  }
}
