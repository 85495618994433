import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "react-bootstrap";
import { ReactComponent as ArrowLeftIcon } from 'images/arrows/svg/left-arrow.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrows/svg/right-arrow.svg';

function ScrollingNavigation ({ links, active, onSelected, }) {
  const [items, changeItems] = React.useState(links);

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    changeItems(links);
  }, [links]);
  
  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick = (id) => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id)
    
    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );

    onSelected(id)
  }  

  return (
    <ScrollMenu
      key={items.length}
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      scrollContainerClassName='scrollContainer justify-content-around'
      separatorClassName={'mx-4'}
    >
      {
        items.map(({ id, value, weekDay }, index) => (
          <Card value={value} weekDay={weekDay} itemId={id} title={id} key={index} onClick={handleClick(id)} selected={active === id} />
        ))
      }

    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    !isFirstItemVisible && <Button className='btn-secondary px-2 border-0' style={{ boxShadow: 'none' }} onClick={() => scrollPrev()} >
      <ArrowLeftIcon />
    </Button>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    !isLastItemVisible && <Button className='btn-secondary border-0 px-2' style={{ boxShadow: 'none' }} onClick={() => scrollNext()} >
      <ArrowRightIcon />
    </Button>
  );
}

function Card({
  onClick,
  selected,
  title,
  itemId,
  value,
  active,
  weekDay
}) {
  const visibility = React.useContext(VisibilityContext)

  let style = {
    'padding': '14px',
    'color': '#43425D' 
  }

  if (selected) {
    style = Object.assign({}, style, { 'color': '#3B41C2' })
  }

  return (
    <div key={value} onClick={() => onClick(visibility)} tabIndex={0} style={style}>
      <div>{weekDay}</div>
      <div>{value}</div>
    </div>
  );
}

export default ScrollingNavigation;