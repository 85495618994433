import { useField } from 'formik';

export const Select = ({ label, labelClassName, ...props }) => {
  const [field, meta] = useField(props);
  
  return (
    <>
      <label className={labelClassName} htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};