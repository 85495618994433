import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { ErrorMsg, Label } from 'styles/GenericStyles'

export const TextMessage = ({ label, labelClassName, ...props }) => {
  const [field, meta] = useField(props);
  
  return (
    <Form.Group className={`${props.className ? props.className : null}`}>
      <Label
        htmlFor={props.id || props.name}
        className={labelClassName ? labelClassName : null}
      >
          {label}
        </Label>
      <Form.Control {...field} {...props} as="textarea" className={ meta.touched && meta.error ? 'is-invalid' : null } />
      { meta.touched && meta.error ? (
        <ErrorMsg className="error">{meta.error}</ErrorMsg>
      ) : null }      
    </Form.Group>
  );
};
