
import React from 'react';
import BaseFormModal from 'components/Modals/BaseFormModal'
import { Row, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Fields/TextInput'
import { TextMessage } from 'components/Fields/TextMessage'
import { Checkbox } from 'components/Fields/Checkbox'
import { PERMISSIONS } from 'redux/UserRedux'

export class AddRole extends React.Component {
  handleChange = (e) => {
    this.setState({ role: e.target.value })
  }

  handleSubmit = (params) => {
    const apiParams = { roleName: params.roleName, description: params.description }
    delete params.roleName
    delete params.description
    this.props.createRole({...apiParams, permissions: Object.keys(params) })
  }
  
  render () {
    const list = Object.values(PERMISSIONS).map(value => {
      const displayText = value && value.toLowerCase()
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      return <Checkbox labelClassName='text-wrap' name={value}>{displayText}</Checkbox>
    })

    return (
      <BaseFormModal heading={'Create Role'} size='lg' actionText='Add' onHide={this.props.onClose} onSave={this.props.onSave}>
        <Formik
          initialValues={{
            roleName: '',
            description: ''
          }}

          validationSchema={
            Yup.object({
              roleName: Yup.string().required('Role name cannot be empty')
            })
          }
          onSubmit={this.handleSubmit}
        >
            <Form>
              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='roleName'>Role Name:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextInput className='mr-3 w-100' name='roleName' type='text' placeholder='Role Name'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='description'>Description:</label>
                </Col>
                <Col className='col-10 d-flex'>
                  <TextMessage className='mr-3 w-100' name='description' placeholder='Enter description'/>
                </Col> 
              </Row>

              <Row className='align-items-center m-2 w-100'>
                <Col className='col-sm-12 col-md-2'>
                  <label className='input-label' htmlFor='permissions'>Permissions:</label>
                </Col>

                <Col className='col-10'>
                  { list }
                </Col>
              </Row>

              <Button size="md" className='float-right' variant="primary" type='submit'>Add</Button>
            </Form>
        </Formik>
      </BaseFormModal>
    )
  }
}

export default AddRole;