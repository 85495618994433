import React from 'react';
import { Alert } from 'react-bootstrap';

export const ToastNotification = (props) => {

  const { messages, type, onClear } = props  
  
  if (messages && messages.length > 0) {
    const list = messages.map((m, index) => <div key={index}>{m}</div>)

    return (
      <Alert className='ath w-25' variant={type} dismissible={true} onClose={onClear}>
        {list}
      </Alert>
    )

  } else {
    return null
  }

}