import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  success: ['messages', 'key'],
  error: ['messages', 'key'],
  clear: null
})

export const AlertTypes = Types
export default Creators

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  messages: [],
  type: null
})

export const success = (state, { messages }) => {
  return state.merge({
    type: 'success',
    messages
  })
}

export const error = (state, { messages }) => {
  return state.merge({
    type: 'danger',
    messages
  })
}

export const clear = (state) => {
  return state.merge({
    type: null,
    messages: null
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: success,
  [Types.ERROR]: error,
  [Types.CLEAR]: clear
})