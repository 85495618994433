import { switchMap, map, filter, tap, ignoreElements } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, concat, from, empty } from 'rxjs'
import AuthActions, { AuthTypes } from "redux/AuthRedux";
import AlertActions from "redux/AlertRedux";
import UserActions from "redux/UserRedux";

import { history } from 'navigation/history'     
import {
  LOGIN,
  LOGGED_IN_ROUTES
} from 'navigation/CONSTANTS'

export const loginEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.LOGIN_REQUEST),
    switchMap((action) => {
      const { email, password } = action
      return from(api.login(email, password)).pipe(
        switchMap(response => {
          if (response.ok) {
            return concat([
              AlertActions.clear(),
              UserActions.updateUser(response.data && response.data.user),
              AuthActions.loginSuccess(response.data && response.data.user.accountUserId),
              UserActions.updateUserToken(response.data.jwt, response.data && response.data.tokenInfo, response.data && response.data.accountInfo, response.data && response.data.permissions, response.data && response.data.tokenType),
            ])
          } else {
            return of(
              AuthActions.loginFailure(),
              AlertActions.error(['Login failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const signUpEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.SIGN_UP_REQUEST),
    switchMap((action) => {
      const { email, password } = action
      return from(api.signUp(email, password)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AuthActions.loginSuccess(response.data && response.data.user_id),
              UserActions.updateUserToken(response.data.jwt, response.data && response.data.tokenInfo, response.data && response.data.accountInfo, response.data && response.data.permissions, response.data && response.data.tokenType),
            )
          } else {
            return of(
              AuthActions.loginFailure(),
              AlertActions.error(['SignUp failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const sendEmailEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.SEND_EMAIL_REQUEST),
    filter((action) => action.email),
    switchMap((action) => {
      const { email } = action
      return from(api.sendEmail(email)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AuthActions.emailSentSuccess(),
              AlertActions.success([
              `If the email is associated with an account in our system, we have emailed you a password reset link.`,
              `Please follow the instructions in that email to reset your password.`
            ]))
          } else {
            return of(
              AuthActions.emailSentFailed(),
              AlertActions.error(['Sending email failed! Please try again.'])
            )
          }
        })
      )
    })
  )

export const updatePasswordEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.UPDATE_PASSWORD),
    switchMap((action) => {
      const { currentPassword, newPassword } = action
      const { accessToken } = store.value.user
      return from(api.updatePassword(currentPassword, newPassword, accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AlertActions.success(['Password Updated successfully!']))
          } else {
            return of(AlertActions.error(['Update Password Operation failed! Please try again.']))
          }
        })
      )
    })
  )

export const validatePasswordEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.VALIDATE_PASSWORD),
    switchMap((action) => {
      const { password, token } = action
      return from(api.validatePassword(password, token)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(AlertActions.success(['Password Reset successfully. Please login to access the account.']))
          } else {
            return of(AlertActions.error(['Update Password Operation failed! Please try again.']))
          }
        })
      )
    })
  )

export const logoutEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.LOGOUT_REQUEST),
    switchMap((action) => {
      const { user } = store.value
      return from(api.logout(user.accessToken)).pipe(
        switchMap(response => {
          if (response.ok) {
            return of(
              AuthActions.logoutSuccess(),
              UserActions.loggedOut()
            )
          } else {
            return of(
              AuthActions.logoutFailed(),
              AlertActions.error(['Error logging you out! Please try again.'])
            )
          }
        })
      )
    })
  )

export const exitEpic = (action$, store, { api }) =>
  action$.pipe(
    ofType(AuthTypes.EXIT),
    switchMap((action) => {
      return of(
        UserActions.loggedOut(),
        AuthActions.logoutSuccess()
      )
    })
  )

export const loginSuccessEpic = (action$, store) =>
  action$.pipe(
    ofType(AuthTypes.LOGIN_SUCCESS),
    tap(action => history.push((window.location.pathname === '/login' || window.location.pathname === '/signup') ? '/' : window.location.pathname)),
    map(() => empty()),
    ignoreElements()
  )

export const logoutSuccessEpic = (action$, store) =>
  action$.pipe(
    ofType(AuthTypes.LOGOUT_SUCCESS),
    filter(() => LOGGED_IN_ROUTES.includes(history.location.pathname)),
    tap(action => history.push(LOGIN)),
    ignoreElements()
  )