import React from 'react';
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { ReactComponent as LeftArrow } from '../../images/arrows/svg/left-arrow.svg';
import { ReactComponent as LeftArrowDisabled } from '../../images/arrows/svg/left-arrow-disable.svg';
import { ReactComponent as RightArrow } from '../../images/arrows/svg/right-arrow.svg';
import { ReactComponent as RightArrowDisabled } from '../../images/arrows/svg/right-arrow-disable.svg';
import { Pagination } from 'react-bootstrap';
import { PTh, PTable, CustomPagination } from '../Styles/PatientListStyles';
import Role from 'components/Roles/Role';
import EditRoleModal from 'components/Modals/EditRoleModal'
import ConfirmationDialog from 'components/Modals/ConfirmationModal'
import RoleActions from 'redux/RoleRedux'

export class RoleList extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      showAccountUpdateModal: false,
      showAccountUpdatePasswordModal: false,
      currentPage: props.currentPage,
      totalPages: props.totalPages,
      showEditUserModal: false,
      editAccountId: null,
      showConfirmation: false,
      tentativeDeleteAccountId: null,
      isEditUserEnabled: null,
      isDeleteUserEnabled: null
    }

  }

  renderLoading = () => {
    return <LoadingView />
  }


  toggleEditClientModal = (accountRoleId) => {
    const { showEditUserModal } = this.state;
    this.setState({
      showEditUserModal: !showEditUserModal,
      editAccountId: accountRoleId
    })
  }

  showDeleteUserConfirmation = (accountId) => {
    this.setState({
      showConfirmation: true,
      tentativeDeleteAccountId: accountId
    })
  }

  deleteRole = () => {
    const { tentativeDeleteAccountId } = this.state
    this.setState({
      showConfirmation: false,
      tentativeDeleteAccountId: null
    }, () => {
      this.props.deleteRole(tentativeDeleteAccountId)
    })
  }

  renderTable () {
    const { clients, showActionsControl, isEditUserEnabled, isDeleteUserEnabled } = this.props
  
    const list = clients && clients.map((account, index) => {
      return <Role key={index}
        account={account} 
        showActionsControl
        onDelete={this.showDeleteUserConfirmation }
        onEdit={this.toggleEditClientModal}
        isEditUserEnabled={isEditUserEnabled}
        isDeleteUserEnabled={isDeleteUserEnabled}
      />
    })

    return <PTable variant='hover' className='table-borderless'>
      <thead>
        <tr>
          <PTh className='col-3'>Role Name</PTh>
          <PTh className='col-3'>Description</PTh>
          <PTh className='col-3'>Permissions</PTh>
          { showActionsControl && <PTh className='col-1'>Action</PTh> }
        </tr>
      </thead>
      <tbody>
        { list }
      </tbody>
    </PTable>
  }

  getPaginationLink(number) {
    const { currentPage } = this.state;
    return (
      <Pagination.Item key={number} active={number === currentPage} onClick={this.handlePaginationLinkClick.bind(this, number)}>
        {number}
      </Pagination.Item>
    )
  }

  handlePaginationLinkClick (pageNumber) {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.props.getAccounts(pageNumber)
    });
  }

  renderPagination () {
    const { currentPage, totalPages } = this.state;
    if (totalPages < 2) return null;
    const DEFAULT_PAGINATION_LENGTH = 4;
    const currentPageRange = DEFAULT_PAGINATION_LENGTH * Math.floor((currentPage - 1) / DEFAULT_PAGINATION_LENGTH);
    let rangeStart = currentPageRange + 1;
    let rangeEnd = Math.min(rangeStart + DEFAULT_PAGINATION_LENGTH - 1, totalPages);
    const items = [];
    while (rangeStart <= rangeEnd) {
      items.push(this.getPaginationLink(rangeStart));
      rangeStart++;
    }
    return <CustomPagination className='justify-content-end w-100'>
            <Pagination.Item disabled={currentPage === 1} onClick={this.handlePaginationLinkClick.bind(this, currentPage - 1)}>
              {currentPage === 1 ? <LeftArrowDisabled /> : <LeftArrow />}
            </Pagination.Item>
            {items}
            <Pagination.Item disabled={currentPage >= totalPages} onClick={this.handlePaginationLinkClick.bind(this, currentPage + 1)}>
              {currentPage >= totalPages ? <RightArrowDisabled /> : <RightArrow />}
            </Pagination.Item>
          </CustomPagination>;
  }

  render () {
    const { showConfirmation } = this.state

    const acc = this.props.clients.filter((account) =>
      account.accountRoleId === this.state.editAccountId
    )

    return (
      <>
        {this.renderTable()}
        {this.renderPagination()}
        { this.state.showEditUserModal &&
          <EditRoleModal
            onClose={this.toggleEditClientModal}
            editAccount={this.onEdit}
            updateRole={this.props.updateRole}
            account={acc && acc[0]}
            toggleEditClientModal={this.toggleEditClientModal}
          />
        }
        { showConfirmation && <ConfirmationDialog 
            heading={'Delete Role'}
            buttonText={'Delete'}
            onClose={() => { this.setState({ showConfirmation: false, tentativeDeleteAccountId: null  })}}
            onSave={this.deleteRole}
            message={'Are you sure you want to delete this role?'}
        /> }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRole: (id) => dispatch(RoleActions.deleteRole(id)),
    updateRole: (id, params) => dispatch(RoleActions.updateRole(id, params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);