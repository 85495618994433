import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

// Types and ActionCreators
const { Types, Creators } = createActions({
  setLoading: ['loading']
})

export const AppTypes = Types
export default Creators

/* ------- Initial State ---------- */
export const INITIAL_STATE = Immutable({
  loading: true
})

export const setLoading = (state, { loading }) => {
  return state.merge({
    loading
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoading
})