import { useField } from "formik";
import { Form } from "react-bootstrap";
import { ErrorMsg, Label, LabelWithin } from "styles/GenericStyles";

export const TextInput = ({ label, labelwithin, labelClassName, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group
      className={`position-relative ${
        props.className ? props.className : null
      }`}
    >
      {!!labelwithin ? (
        <LabelWithin htmlFor={props.id || props.name} bgcolor={props.bgcolor}>
          {label}
        </LabelWithin>
      ) : label ? (
        <Label
          className={labelClassName || ""}
          htmlFor={props.id || props.name}
        >
          {label}
        </Label>
      ) : null}

      <Form.Control
        {...field}
        {...props}
        className={meta.touched && meta.error ? "is-invalid" : null}
      />
      {meta.touched && meta.error ? (
        <ErrorMsg className="error">{meta.error}</ErrorMsg>
      ) : null}
    </Form.Group>
  );
};
