import { Modal, Button } from 'react-bootstrap';


export const BaseModal = (props) => {
  return (
    <Modal animation={false} show={true} size={props.size || 'md'} centered onHide={props.onHide}>
      <Modal.Header closeButton className="p-3">
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>Close</Button>
        <Button variant={`${props.danger ? 'danger' : 'primary'}`} onClick={props.onSave}>{props.actionText || 'Save'}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BaseModal;
