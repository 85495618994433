import React from 'react';
import { ReactComponent as EditIcon } from '../../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/svg/ic_delete.svg';
import { PTr, PTd, IconButton } from '../Styles/PatientListStyles';

export default class Role extends React.Component {
  onDelete = () => {
    this.props.onDelete(this.props.account.accountRoleId)
  }

  onEdit = (e) => {
    e.preventDefault()
    this.props.onEdit(this.props.account.accountRoleId)
  }

  render () {
    const { account, showActionsControl, isEditUserEnabled, isDeleteUserEnabled } = this.props
    return (
      <PTr>
          <PTd>{account.roleName}</PTd>
          <PTd>{account.description}</PTd>
          <PTd>{account.permissions && account.permissions.length + ' Permissions '}</PTd>
          { showActionsControl && account.roleName !== 'ADMIN' &&
            <PTd>
              <div className='d-flex'>
                { isEditUserEnabled && <IconButton className='mr-2'><EditIcon onClick={this.onEdit} /></IconButton> }
                { isDeleteUserEnabled && <IconButton><DeleteIcon onClick={this.onDelete}/></IconButton> }
              </div>
            </PTd>
         }
      </PTr>
    )
  }
}