import React from 'react';
import Layout from 'components/Layout.jsx'
import { connect } from 'react-redux'
import LoadingView from 'components/LoadingView.jsx'
import { Row, Col } from 'react-bootstrap';
import { PAGE_SIZE } from 'config/CONSTANTS'
import ReportActions from 'redux/ReportRedux'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import { TEMPLATES } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom'
import QueryTemplatesListing from 'components/QueryTemplatesListing';
import ShowInfoModal from 'components/Modals/ShowInfoModal';

export class TemplateQueries extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showingInfo: false,
      rows: []
    };
  }

  componentDidMount () {
    this.props.getReportQueryTemplates(this.props.match.params.id, 1)
  }

  renderLoading = () => {
    return <LoadingView />
  }

  onShowInfo = (rows) => {
    this.setState({
      showingInfo: true,
      rows
    })
  }

  onHideInfo = () => {
    this.setState({
      showingInfo: false,
      rows: []
    })
  }

  render () {
    const { fetchingQueryTemplates } = this.props
    const { showingInfo, rows } = this.state

    return (
      <Layout>
        { fetchingQueryTemplates && this.renderLoading() }
        { !fetchingQueryTemplates && this.renderQueryTemplates() }
        { showingInfo && <ShowInfoModal onClose={this.onHideInfo} onSave={this.onHideInfo} rows={rows} /> }
      </Layout>
    )
  }

  renderQueryTemplates () {
    let { queryTemplates, queryTemplatesCurrentPage, queryTemplatesTotalCount } = this.props
    return (
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <h4 className='text-nowrap'>
            <Link to={TEMPLATES}>Query Templates</Link></h4>
          <h4> > </h4>
          <h4 className='text-nowrap'>Report Query Templates</h4>
        </Row>

        <Row>
          <QueryTemplatesListing
            templateId={this.props.match.params.id}
            queryTemplates={queryTemplates}
            currentpage={queryTemplatesCurrentPage}
            totalPages={Math.ceil(queryTemplatesTotalCount / PAGE_SIZE)}
            getQueries={this.props.getReportQueryTemplates}
            onDelete={(templateId, queryId) => this.props.deleteReportTemplateQuery(templateId, queryId)}
            onShowInfo={this.onShowInfo}
          />
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isReadReportsEnabled: isPermissionEnabled(state.user, PERMISSIONS.REPORT_QUERIES),
    queryTemplates: state.report.queryTemplates,
    queryTemplatesCurrentPage: state.report.queryTemplatesCurrentPage,
    queryTemplatesTotalCount: state.report.queryTemplatesTotalCount,
    fetchingQueryTemplates: state.report.fetchingQueryTemplates
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReportQueryTemplates: (templateId, pageNumber) => dispatch(ReportActions.getReportQueryTemplates(templateId, pageNumber)),
    createReportTemplateQuery: (templateId) => dispatch(ReportActions.createReportTemplateQuery(templateId)),
    deleteReportTemplateQuery: (templateId, queryId) => dispatch(ReportActions.deleteReportTemplateQuery(templateId, queryId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateQueries);