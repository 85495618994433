import React from "react";
import { Row, Col } from "react-bootstrap";
import { LightGrayLabel } from "styles/GenericStyles";
import { isBoolean } from "utils/genericUtils";
import { Link } from "react-router-dom";
import Strings from "i18n/en";
import ReactToPrint from 'react-to-print';

export default class EmploymentPlan extends React.Component {
  render() {
    let { data, link, read, write } = this.props;

    data = data || {};
    if (!read && !write) {
      return (
        <Row className="shadow rounded border p-4 mr-1 d-flex flex-column">
          <Row className="pl-3">BFET &gt; Employment Plan:</Row>
          <Row className="pl-3 mt-2">
            <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
          </Row>
        </Row>
      );
    }

    return (
<Row ref={el => (this.componentRef = el)} className="shadow rounded border row p-4 mr-1 mr-1 mb-4 printable">
<Row className="pb-2 w-100">
  <Col>BFET &gt; Employment Plan: </Col>
  {write && (
    <Col className="d-flex justify-content-end">
      <Link to={link}>Edit</Link>
    </Col>
  )}
  <ReactToPrint
    // eslint-disable-next-line
    trigger={() => <a href="javascript:void();">Print</a>}
    content={() => this.componentRef}
  />
</Row>
        <div>
          <Row className="mb-3">
            <Col className="col-12 d-flex row row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Education/Training Plan</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isEducationPlan)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>VE</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isVE)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>BE (Including ESL)</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isBE)}</label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row-cols-2 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Educational Institution</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.educationalInstitution}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Academic Goal</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.academicGoal}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Dates Of Training</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.datesOfTraining}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Start</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.start}</label>
              </Col>
            </Row>
            <Row className="col-12">
              <Col className="col-12">
                <LightGrayLabel>Degree/Certification</LightGrayLabel>
              </Col>
              <Col className="col-12 p-0 ml-3">
                <label className="input-label">{data.certification}</label>
              </Col>
            </Row>
          </Row>
          <hr />
          <Row>
            <Col className="d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Job Search Training (JT)</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isJobSearchTraining)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Start</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.jobSearchTrainingStartDate}</label>
                </Col>
              </Row>
            </Col>
            <Col className="col-md-12 col-lg-12">
            <LightGrayLabel>
              <p>Job Search Training may include, but not limited to:</p>
              <ul>
                <li>Resume writing</li>
                <li>Interview skills</li>
                <li>Master application</li>
                <li>Workplace etiquette</li>
              </ul>
              </LightGrayLabel>
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col className="d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Supervised Job Search (JS))</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isSupervisedJobSearch)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Start</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.supervisedJobSearchStartDate}</label>
                </Col>
              </Row>
            </Col>
            <Col>
            <LightGrayLabel>

              <p>
                Supervised Job Search activity may include, but not limited to:
              </p>
              <ul>
                <li>Use of computer, email, fax, telephone</li>
                <li>Search of job listings</li>
                <li>Participation in a job club</li>
                <li>
                  Securing identification, professional license or
                  certifications
                </li>
              </ul>
            </LightGrayLabel>

            </Col>
          </Row>
          <Row>
            <Col className="d-flex col-12 row row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
            <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Job Retention Service Extension (BR)</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isJobRetentionService)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Start</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.jobRetentionServiceStartDate}</label>
                </Col>
              </Row>
            </Col>
            <Col >
            <LightGrayLabel>

              <p>
                Job Retention Service Extension may include, but not limited to:
              </p>
              <ul>
                <li>Counseling</li>
                <li>Coaching</li>
                <li>Case management</li>
                <li>Financial Empowerment Training</li>
                <li>Participant reimbursement</li>
              </ul>
            </LightGrayLabel>

            </Col>
          </Row>
          <hr />

          <Row>
          <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Life Skills (SL)</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{isBoolean(data.isLifeSkill)}</label>
                </Col>
              </Row>
              <Row className="col-12">
                <Col className="col-12">
                  <LightGrayLabel>Start</LightGrayLabel>
                </Col>
                <Col className="col-12 p-0 ml-3">
                  <label className="input-label">{data.lifeSkillStartDate}</label>
                </Col>
              </Row>
            
            <Col>
            <LightGrayLabel>

              <p>Life Skills may include, but not limited to:</p>
              <ul>
                <li>Work Preparation</li>
                <li>Health and well-being</li>
                <li>Effective communication</li>
                <li>Personal strength builders</li>
                <li>Community enagement</li>
              </ul>
            </LightGrayLabel>

            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}
