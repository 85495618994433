import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist'
import IPT from './IPT'
import { createFilter } from '@actra-development-oss/redux-persist-transform-filter-immutable'

import { createEpicMiddleware } from 'redux-observable'
import rootEpic from 'epics/index'

// Combine all reducers
const rootReducer = combineReducers({
  app: require('./AppRedux').reducer,
  alert: require('./AlertRedux').reducer,
  auth: require('./AuthRedux').reducer,
  user: require('./UserRedux').reducer,
  role: require('./RoleRedux').reducer,
  client: require('./ClientRedux').reducer,
  account: require('./AccountRedux').reducer,
  appointment: require('./AppointmentRedux').reducer,
  report: require('./ReportRedux').reducer
});

const middleware = []
const enhancers = []
const transforms = []

enhancers.push(autoRehydrate())

const epicMiddleware = createEpicMiddleware()
middleware.push(epicMiddleware)

enhancers.push(applyMiddleware(...middleware))

// Create the store finally!
const store = createStore(rootReducer, compose(...enhancers))
window.store = store;

epicMiddleware.run(rootEpic)

const userFilter = createFilter(
  'user',
  ['jwt', 'tokenInfo']
)

transforms.push(IPT, userFilter)

persistStore(store, {
  whitelist: ['user'],
  blacklist: [],
  transforms
})

export default store;