import React from 'react';
import Layout from 'components/Layout.jsx'
import AddClientModal from 'components/Modals/AddClientModal'
import { Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux'
import { TextInput } from 'components/Fields/TextInput'
import { Select } from 'components/Fields/Select'
import CalendarStyle from "styles/CalendarStyles"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Formik, Form } from 'formik';
import AppointmentActions from 'redux/AppointmentRedux'
import moment from 'moment';
import LoadingView from 'components/LoadingView.jsx'
import { MenuContainer } from "pages/Styles/AppointmentsStyles"
import ClientActions from 'redux/ClientRedux'
import { isPermissionEnabled, PERMISSIONS } from 'redux/UserRedux'
import RoleActions from 'redux/RoleRedux'
import AccountActions from 'redux/AccountRedux'
import Strings from 'i18n/en'

export class Appointments extends React.Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      client: null,
      selectedDate: moment(),
      selectedAccount: null,
      selectedSlot: null,
      showAutoSuggestion: false,
      roleSelected: null
    }
  }

  componentDidMount () {
    const { getRoles, getAccounts } = this.props
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_ROLES) && getRoles(1)
    isPermissionEnabled(this.props.user, PERMISSIONS.VIEW_USERS) && getAccounts(1)
  }

  handleSubmit = (values) => {
    if (values && !values.documentType && !this.state.selectedAccount) {
      return 
    }

    const { selectedDate, selectedSlot } = this.state;
    var startTime = moment(selectedDate).set({
      "hour": selectedSlot.split(':')[0],
      "minute": selectedSlot.split(':')[1],
      "second": 0,
      "millisecond": 0
    }).toISOString();

    var endTime = moment(startTime).add(30, 'minutes').toISOString();

    this.props.createAppointment({
      "accountUserId": this.state.selectedAccount, // this.props.accountId,
      "appointmentType": values.documentType,
      "clientId": this.state.client && this.state.client.clientId,
      "endTime": endTime,
      "startTime": startTime
    })
  }

  onSearch = (event) => {
    this.setState({
      searchString: event.target.value
    });
  }

  onSelect = (client) => {
    this.setState({
      client: client,
      showAutoSuggestion: false
    }, () => {
      const { selectedDate } = this.state
      const startTime = selectedDate.startOf('day').toISOString()
      const endTime = selectedDate.endOf('day').toISOString()
      this.props.getClientAppointments(client && client.clientId, startTime, endTime)
    });
  }

  onChangeUser = (e) => {
    this.setState({
      selectedAccount: e.target.value
    }, () => {
      const { selectedDate } = this.state
      const startTime = selectedDate.startOf('day').toISOString()
      const endTime = selectedDate.endOf('day').toISOString()
      const { getAccountAppointments, getClientAppointments } = this.props
      getAccountAppointments(e.target.value, startTime, endTime)
      getClientAppointments(this.state.client && this.state.client.clientId, startTime, endTime)
    })
  }

  onChange = (value) => {
    this.setState({
      selectedDate: moment(value)
    }, () => {
      const startTime = moment(value).startOf('day').toISOString()
      const endTime = moment(value).endOf('day').toISOString()
      const { getAccountAppointments, getClientAppointments } = this.props
      getAccountAppointments(this.state.selectedAccount, startTime, endTime)
      getClientAppointments(this.state.client && this.state.client.clientId, startTime, endTime)
    })
  }

  onChangeClientId = (e) => {
    const val = e.target.value
    if (!val) {
      this.setState({ showAutoSuggestion: false })
      return null
    }
    this.setState({
      showAutoSuggestion: true,
      client: null
    }, () => {
      this.props.searchClient({ lastName: e.target.value }, 1)
    })
  }

  
  toggleAddClientModal = () => {
    const { showAddClientModal } = this.state;
    this.setState({
      showAddClientModal: !showAddClientModal
    })
  }

  onCreate = (params) => {
    this.props.createClient(params)
    this.toggleAddClientModal()
  }

  renderLoading = () => {
    return <LoadingView />
  }

  handleSlot = (e, slot) => {
    this.setState({
      selectedSlot: slot
    })
  }

  renderNoResults = () => {
    return (
      <Row className='p-2 border-bottom mr-0 ml-0 flex-column'>
        <div>No results.</div>
      </Row>
    )
  }

  calculateSlots = (appointments) => {
    return appointments && appointments.map((cAppt) => moment(cAppt.startTime).format('HH:mm'))
  }

  renderTimeSlots = () => {
    const { selectedDate } = this.state
    const { accountAppointmentsFetching, clientAppointmentsFetching, accountAppointments, clientAppointments, fetchingAccounts, fetchingRoles } = this.props;
    
    if (accountAppointmentsFetching || clientAppointmentsFetching || fetchingAccounts || fetchingRoles ) {
      return this.renderLoading()
    }

    let slots = [];
    let slotInterval = 30;
    let currentTime = moment.now()
    
    let startTime = moment({
      year :selectedDate.year(),
      month : selectedDate.month(),
      day : selectedDate.date(),
      hour : '09',
      minute :'00'
    });

    let endTime = moment({
      year: selectedDate.year(),
      month: selectedDate.month(),
      day: selectedDate.date(),
      hour: '17',
      minute: '00'
    });

    while (startTime < endTime) {
      if (startTime > currentTime) {
        slots.push(startTime.format("HH:mm"));
      }
      startTime.add(slotInterval, 'minutes');
    }
    
    const aSlots = this.calculateSlots(accountAppointments);
    const cSlots = this.calculateSlots(clientAppointments);
    let bookedSlots = [...new Set([...aSlots,...cSlots])]
    const availableSlots = slots.filter((s) => !bookedSlots.includes(s))
  
    return (
      <div className='d-flex flex-column mt-4 slotsDiv'>
        {
          availableSlots.map((slot, index) => <Button
            key={index}
            variant="outline-primary"
            className={slot === this.state.selectedSlot ? 'mb-2 p-2 selected-slot' : 'mb-2 p-2'}
            onClick={(e) => this.handleSlot(e, slot)}>{moment(slot, 'hh:mm').format('LT')}
          </Button>)
        }
      </div>
    )
  }

  renderForm = () => {
    let { selectedDate, showAutoSuggestion, roleSelected } = this.state
    const { searchResults, searchingClient, updatingForm, isAddClientEnabled, isAddAppointmentEnabled, accounts, roles } = this.props;
    let accountList;

    const roleNameList = roles && roles.map((role) => {
      return <option value={role.accountRoleId} label={role.roleName} />
    })

    const roleAccounts = roleSelected && accounts && accounts.filter((account) => account.roles.includes(roleSelected))

    if (roleSelected){
      accountList = roleAccounts && roleAccounts.map((account) => {
        return <option value={account.accountUserId} label={account.firstName + ' ' + account.lastName} />
      })
    } else {
      accountList = accounts && accounts.map((account) => {
        return <option value={account.accountUserId} label={account.firstName + ' ' + account.lastName} />
      })
    }

    return (
      <Row className='w-100'>
        <Formik
          enableReinitialize
          initialValues={{ client: (this.state.client ? (this.state.client.firstName + ' ' + this.state.client.lastName) : null) }}
          onSubmit={this.handleSubmit}>
            <Form className='w-100'>
              <Row className='w-100 ml-3 mr-0 justify-content-end'>
                <Col lg={4} className='p-4'>
                  <Row className='ml-0 mr-0'>
                    <TextInput
                      name='client'
                      type='text'
                      label='Select Client'
                      labelClassName='input-label mb-2'
                      placeholder='Search Client by lastName'
                      className='w-100'
                      autoComplete='off'
                      onChange={this.onChangeClientId}
                    />
                    <MenuContainer show={showAutoSuggestion}>
                      { searchingClient && this.renderLoading() }
                      { !searchingClient && searchResults && searchResults.length === 0 && this.renderNoResults() }
                      { !searchingClient && searchResults && searchResults.length > 0 && searchResults.map((client, index) => {
                          return (
                            <Row className='p-2 border-bottom mr-0 ml-0 flex-column' onClick={() => this.onSelect(client)}>
                              <div>{`${client.firstName} ${client.lastName}`}</div>
                              <div>{client.email}</div>
                            </Row>
                        )
                      }) }
                    </MenuContainer>
                    
                  </Row>
                  <Row className='ml-0 mr-0'>
                    { isAddClientEnabled && <Button
                      onClick={this.toggleAddClientModal}
                      className='font-weight-bold ml-auto pr-0 mb-3 pt-0'
                      style={{boxShadow: 'none'}}
                      variant='link'>+ Add Client
                    </Button> }
                  </Row>

                  <Row className='ml-0 mr-0'>
                    <Select
                      name="role"
                      className='custom-select mr-3'
                      label='Provider Type'
                      labelClassName='input-label mb-2'
                      onChange={(e) => this.setState({ roleSelected : e.target.value })}
                    >
                      <option label="Select" />
                      { roleNameList }
                    </Select>
                  </Row>

                  <Row className='ml-0 mr-0 mt-3'>
                    <Select
                      name="selectedAccount"
                      className='custom-select mr-3'
                      label='Providers'
                      labelClassName='input-label mb-2'
                      onChange={this.onChangeUser}
                    >
                      <option label="Select" />
                      { accountList }
                    </Select>
                  </Row>

                  <Row className='ml-0 mr-0 mt-3'>
                    <Select
                      name="documentType"
                      className='custom-select mr-3'
                      label='Appointment Type'
                      labelClassName='input-label mb-2'
                    >
                      <option label="Select" />
                      <option value="Food Assistance (SNAP)" label="Food Assistance (SNAP)" />
                      <option value="Housing - BFET" label="Housing - BFET" />
                      <option value="Family Reunification - REENTRY" label="Family Reunification - REENTRY" />
                      <option value="Job Training - BFET/DVR/REENTRY" label="Job Training - BFET/DVR/REENTRY" />
                      <option value="Job Search - BFET/DVR/REENTRY" label="Job Search - BFET/DVR/REENTRY" />
                      <option value="Job Retention - BFET/DVR" label="Job Retention - BFET/DVR" />
                      <option value="Basic Needs (Hygiene/Bus Pass/Gas Card, etc) - BFET/REENTRY" label="Basic Needs (Hygiene/Bus Pass/Gas Card, etc) - BFET/REENTRY" />
                      <option value="Life Skills Training -BFET/REENTRY" label="Life Skills Training -BFET/REENTRY" />
                      <option value="SUD - Mental Health" label="SUD - Mental Health" />
                      <option value="Anger Management - Mental Health" label="Anger Management - Mental Health" />
                      <option value="Domestic Violence - Mental Health" label="Domestic Violence - Mental Health" />
                      <option value="Civil Legal Assistance - BFET/REENTRY" label="Civil Legal Assistance - BFET/REENTRY" />
                    </Select>
                  </Row>

                </Col>

                <Col lg={8}>
                  <Row className='calendar-div p-3'>
                    <label className='input-label mb-3'>Select a Date & Time</label>
                    <Row className='d-flex justify-content-around pl-3'>
                      <Calendar
                        onChange={this.onChange}
                        view={'month'}
                        className='custom-react-calendar'
                        tileClassName='custom-tile'
                        minDate={new Date()}
                      />

                      <Col>
                        <span>{moment(selectedDate).format('dddd, MMMM, D')}</span>
                        { this.renderTimeSlots() }
                      </Col>
                    </Row>
                  </Row>
                </Col>


              </Row>
              <Row className='mt-3 justify-content-end'>
                  <Button variant="primary" type='submit' disabled={!isAddAppointmentEnabled}>{!!updatingForm ? 'Saving...' : 'SAVE'}</Button>
              </Row>
            </Form>
        </Formik>
      </Row>

    )
  }

  renderContent = () => {
    const { creatingClient, isAddAppointmentEnabled } = this.props;
    return (
      <>
        <CalendarStyle />
        <Row className='ml-0 mr-0'>
          <h4 className='text-nowrap mb-4'>Create Appointment</h4>
          { isAddAppointmentEnabled ? this.renderForm() : (
            <Row className='mt-4'>{Strings.permissions.message}</Row>
          ) }
        </Row>

        { this.state.showAddClientModal && <AddClientModal onClose={this.toggleAddClientModal} createClient={this.onCreate}  creatingClient={creatingClient} />}
      </>
    );
  }
  
  render = () => {
    const { fetchingAccounts, fetchingRoles } = this.props
    return (
      <Layout>
        { (fetchingAccounts || fetchingRoles) && this.renderLoading() }
        { (!fetchingAccounts && !fetchingRoles) && this.renderContent() }
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    fetchingAccounts: state.account.fetchingAccounts,
    accounts: state.account.accounts,
    fetchingRoles: state.role.fetchingRoles,
    roles: state.role.roles,
    accountId: state.user.accountUserId,
    accountAppointmentsFetching: state.appointment.accountAppointmentsFetching,
    clientAppointmentsFetching: state.appointment.clientAppointmentsFetching,
    accountAppointments: state.appointment.accountAppointments,
    clientAppointments: state.appointment.clientAppointments,
    searchResults: state.client.searchResults,
    searchingClient: state.client.searchingClient,
    updatingForm: state.appointment.updatingForm,
    creatingClient: state.client.creatingClient,
    isAddClientEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_CLIENT),
    isAddAppointmentEnabled: isPermissionEnabled(state.user, PERMISSIONS.ADD_APPOINTMENT)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountAppointments: (accountId, to, from) => dispatch(AppointmentActions.getAccountAppointments(accountId, to, from)),
    getClientAppointments: (clientId, to, from) => dispatch(AppointmentActions.getClientAppointments(clientId, to, from)),
    
    getRoles: (pageNumber) => dispatch(RoleActions.getRoles(pageNumber)),
    getAccounts: (pageNumber) => dispatch(AccountActions.getAccounts(pageNumber)),

    createAppointment: (params) => dispatch(AppointmentActions.createAppointment(params)),
    searchClient: (query, pageNumber) => dispatch(ClientActions.searchClient(query, pageNumber)),
    createClient: (params) => dispatch(ClientActions.createClient(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);