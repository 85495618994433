export function isBoolean(value) {
  return (value === true || value === 'true') ? 'Yes' : ((value === false || value === 'false') ? 'No' : '');
}

export function humanFormat(val) {
  if (val === undefined || val === null) {
    return ''
  }

  if (typeof val === 'boolean' || val instanceof Boolean || val === 'true') {
    return (val === true || val === 'true') ? 'Yes' : 'No'; 
  } else if (typeof val === 'string' || val instanceof String) {
    return val.split('_').join(' ')
  }
}

export function camelCaseToNormalString (str) {
  return str && str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}


export function capitalizeToNormalString (str) {
  return str && str.split('_').join(' ').toLowerCase();
}

export const generateConcatenatedString = (filters, accounts) => {

  function camelCaseToHumanReadable(camelCaseString) {
    const wordsArray = camelCaseString.split(/(?=[A-Z])/);
    const humanReadableString = wordsArray.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return humanReadableString;
  }

  function buildFilterString(filter) {
    const { name, value, operator, value1 } = filter;

    // Convert camelCase property names to human-readable format
    const humanReadableName = camelCaseToHumanReadable(name);

    // Map operator values to more human-readable representations
    const operatorMap = {
      "ALL_OF_IN_THE_FOLLOWING": "all of in the following",
      "ALL_OF_IS_BETWEEN": "all of is between",
      "ALL_OF_IS_EQUAL_TO": "all of is equal to",
      "ALL_OF_IS_GREATER_THAN": "all of is greater than",
      "ALL_OF_IS_LESS_THAN": "all of is less than",
      "ANY_OF_IN_THE_FOLLOWING": "any of in the following",
      "ANY_OF_IS_BETWEEN": "any of is between",
      "ANY_OF_IS_EQUAL_TO": "any of is equal to",
      "ANY_OF_IS_GREATER_THAN": "any of is greater than",
      "ANY_OF_IS_LESS_THAN": "any of is less than",
      "IS_NOT_BETWEEN": "is not between",
      "IS_NOT_EQUAL_TO": "is not equal to",
      "NOT_IN_THE_FOLLOWING": "not in the following",
      "ALL_OF_CONTAINS": "all of contains",
      "ALL_OF_ENDS_WITH": "all of ends with",
      "ALL_OF_IS": "all of is",
      "ALL_OF_STARTS_WITH": "all of starts with",
      "ANY_OF_CONTAINS": "any of contains",
      "ANY_OF_ENDS_WITH": "any of ends with",
      "ANY_OF_STARTS_WITH": "any of starts with",
      "DOES_NOT_CONTAIN": "does not contain",
      "DOES_NOT_END_WITH": "does not end with",
      "DOES_NOT_START_WITH": "does not start with",
      "IS": "is",
      "IS_NOT": "is not",
      "EXISTS": "exists",
      "DOES_NOT_EXIST": "does not exist",
      "AFTER_DATE": "after date",
      "BEFORE_DATE": "before date",
      "IN_BETWEEN_DATES": "in between dates",
      "IN_THE_LAST_N_DAYS": "in the last N days",
      "IN_THE_NEXT_N_DAYS": "in the next N days",
      "LAST_MONTH": "last month",
      "LAST_WEEK": "last week",
      "ON_DATE": "on date",
      "THIS_MONTH": "this month",
      "THIS_WEEK": "this week",
      "TODAY": "today",
      "YESTERDAY": "yesterday",
      "IN_THE_FOLLOWING": "in the following",
      "IS_BETWEEN": "is between",
      "IS_EQUAL_TO": "is equal to",
      "IS_GREATER_THAN": "is greater than",
      "IS_LESS_THAN": "is less than",
      "NOT_IN_THE_FOLLOWING": "not in the following",
      "CONTAINS": "contains",
      "ENDS_WITH": "ends with",
      "STARTS_WITH": "starts with"
    };

    // Special case for "createdBy" filter
    if (name === "createdBy" && accounts) {
      const createdByAccount = accounts.find((account) => account.accountUserId === value);
      if (createdByAccount) {
        const { firstName, lastName } = createdByAccount;
        return `Created By ${operatorMap[operator]} [${firstName} ${lastName}]`;
      } else {
        const v = (value !== undefined && value !== null && value !== " ") ? `[${value}]` : ''
        return `Created By ${operatorMap[operator]} ${v}`;
      }
    }

    // Add brackets to the value and value1 properties if they are non-empty
    const formattedValue = (value !== undefined && value !== null && value !== " ") ? `[${value}]` : '';
    const formattedValue1 = (value1 !== undefined && value1 !== null && value1 !== " ") ? `[${value1}]` : '';

    
    // Build the human-readable string
    let filterString = `${humanReadableName} ${operatorMap[operator]}`;
    if (formattedValue) {
      filterString += ` ${formattedValue}`;
    }
    if (formattedValue1) {
      filterString += ` ${formattedValue1}`;
    }

    return filterString;
  }

  function traverseFilters(filters) {
    const filterOperator = filters && filters.filterOperator;
    const innerFilters = filters && filters.filters && filters.filters.map((filter) => {
      if (filter && filter.isNested) {
        return traverseFilters(filter);
      } else {
        return buildFilterString(filter);
      }
    });

    const concatenatedString = innerFilters && innerFilters.join(` ${filterOperator} `);
    return filterOperator === "AND" ? concatenatedString : `(${concatenatedString})`;
  }

  return traverseFilters(filters);
}