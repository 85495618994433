import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import { TextInput } from "components/Fields/TextInput";
import {
  FormHeader,
  LightGrayLabel,
} from "styles/GenericStyles";
import { Prompt } from "react-router-dom";
import Strings from "i18n/en";
import LoadingView from "components/LoadingView.jsx";
import ClientActions from "redux/ClientRedux";
import { connect } from "react-redux";
import { isPermissionEnabled, PERMISSIONS } from "redux/UserRedux";
import { Select } from "components/Fields/Select";
import AccountActions, { getBFETSupplies } from "redux/AccountRedux"
import moment from 'moment'

export class Supplies extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      selected: props.data && props.data[0],
      totalKitCost: this.calculateTotalKitCost(props.data && props.data[0] && props.data[0].items)
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  componentWillReceiveProps(newProps) {
    if ( (this.props.data && this.props.data.length) !== (newProps.data && newProps.data.length) ) {
      this.setState({
        selected: newProps.data[0],
        totalKitCost: this.calculateTotalKitCost(newProps.data[0] && newProps.data[0].items)
      });
    }
  }

  componentDidMount() {
    this.props.getAccountSuppliesInformation('BFET');
    this.props.getClientSuppliesInformation(this.props.clientID, 'BFET');
  }

  handleSubmit = (values) => {
    const items = values && values.items && values.items.filter((c) => c)
    if (this.state.selected && this.state.selected.supplyId) {
      this.props.updateClientSuppliesInformation(this.props.clientID, this.state.selected.supplyId,  'BFET', {...values, items, clientId: this.props.clientID })
    } else {
      this.props.postClientSuppliesInformation(this.props.clientID, 'BFET', {...values, items, clientId: this.props.clientID })
    }
    this.setState({
      selected: { ...values }
    })
  }

  onCancel = () => {}
  
  select = (supplyId) => {
    const supplies = this.props.data.filter((supply) => supply.supplyId === supplyId)
    this.setState({
      selected: supplies && supplies[0],
      totalKitCost: this.calculateTotalKitCost(supplies && supplies[0] && supplies[0].items)

    })
  }

  addNewSupply = () => {
    this.setState({
      selected: null
    })
  }

  getSupplyNameUnitPrice = (supplyId) => {
    const { bFetSupplies } = this.props;
    const s = bFetSupplies && bFetSupplies.find((supply) => supply.supplyId === supplyId)
    return s && s.unitPrice
  }

  getSupplyDisplayName = (supplyId) => {
    const { bFetSupplies } = this.props;
    const s = bFetSupplies && bFetSupplies.find((supply) => supply.supplyId === supplyId)
    return s && s.supplyName
  }

  calculateTotalKitCost = (items) => {
    return (items && items.reduce((sum, item) => {
      return sum + item.totalPrice
    }, 0))
  }

  renderContent () {
    const { data, read, write, bFetSupplies } = this.props;

    if (!write && !read) {
      return (
        <Row className="pt-4 pl-2">
          <LightGrayLabel>{Strings.permissions.message}</LightGrayLabel>
        </Row>
      );
    }
    const { selected, totalKitCost } = this.state

    // const list = bFetSupplies && bFetSupplies.map((s) => {
    //   return <option key={s.supplyId} value={s.supplyId} data-name={s.supplyName} selected={}>{s.supplyName}</option>
    // })

    return (
      <Row className='row mr-1' disabled={!write}>
        <Col className='p-0 col-3'>
          <div className="note-list">
            <div className="add-note note" onClick={this.addNewSupply}> + Add Supply</div>
            {
              data && data.map((supply, index) => {
                return (
                  <div key={index} className={`note ${((selected && selected.supplyId) === (supply && supply.supplyId)) ? 'note-selected' : ''}`} onClick={() => this.select(supply && supply.supplyId) }>
                    <span className="note-title">{supply && supply.supplyTitle}</span>
                    <span className="note-time">{ supply && moment(supply.createdAt).format('MM-DD-YYYY HH:mm')}</span>
                  </div>
                )
              })
            }
          </div>
        </Col>
        <Col className='p-0 pl-1 col-9'>
          <Formik
            key={selected && selected.supplyId}
            enableReinitialize
            initialValues={{ ...selected }}
            onSubmit={this.handleSubmit}
          >
            {props => {
              const {
                values,
                setFieldValue,
                dirty
              } = props;
              return (
                <>
                  <Prompt when={dirty} message={Strings.prompt.message} />
                  <Form>
                    <Row className='shadow rounded border mr-0 ml-0 p-5 w-100'>
                      <Row>
                        <FormHeader className="mt-n2 mb-3">SUPPLY LIST</FormHeader>
                      </Row>
                      <div className="w-100">
                        <TextInput className='w-100 mb-3' name='supplyTitle' placeholder={'Enter Title...'}/>
                        <Table className="w-100 pl-0 ml-0" borderless responsive>
                          <thead>
                            <th style={{ width: "20%" }}>Item Type</th>
                            <th style={{ width: "15%" }}>Quantity</th>
                            <th style={{ width: "25%" }}>Base Price</th>
                            <th style={{ width: "30%" }}>Total</th>
                            <th style={{ width: "10%" }}>Action</th>
                          </thead>
                          <tbody>
                            <FieldArray
                              name="items"
                              render={(arrayHelpers) => (
                                <>
                                  {values.items && values.items.length > 0 ? (
                                    values.items.map((supplyItem, index) => (
                                        
                                      <tr key={index}>
                                        <td className="pl-0 py-0">
                                          <Select
                                            name={`items.${index}.itemName`}
                                            className="custom-select"
                                            onChange={(event) => {
                                              setFieldValue(`items.${index}.itemName`, event.target.value);
                                              setFieldValue(`items.${index}.unitPrice`, this.getSupplyNameUnitPrice(event.target.value));
                                              setFieldValue(`items.${index}.totalPrice`, (this.getSupplyNameUnitPrice(event.target.value) * values.items[index].quantity));
                                            }}
                                          >
                                            <option>Select</option>
                                            { 
                                              bFetSupplies && bFetSupplies.map((s) => {
                                                return <option key={s.supplyId} value={s.supplyId}>{s.supplyName}</option>
                                              })
                                            }
                                          </Select>
                                        </td>
                                        <td className="py-0">
                                          <TextInput
                                            name={`items.${index}.quantity`}
                                            type="number"
                                            placeholder="Quantity"
                                            onChange={(event) => {
                                              setFieldValue(`items.${index}.quantity`, Number(event.target.value));
                                              setFieldValue(`items.${index}.totalPrice`, (values.items[index].unitPrice * Number(event.target.value)));
                                            }}
                                          />
                                        </td>
                                        <td className="py-0">
                                          <TextInput
                                            name={`items.${index}.unitPrice`}
                                            type="number"
                                            placeholder="Price"
                                          />
                                        </td>
                                        <td className="py-0">
                                          <TextInput
                                            name={`items.${index}.totalPrice`}
                                            type="number"
                                            placeholder="Total"
                                          />
                                        </td>
                                        <td className="d-flex p-0">
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger mr-3"
                                              onClick={() => {
                                                this.setState({ totalKitCost:  this.calculateTotalKitCost(arrayHelpers.form.values.items) })
                                                return arrayHelpers.remove(index)
                                              }}
                                            >
                                              Remove
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-outline-primary"
                                              onClick={() => {
                                                this.calculateTotalKitCost()
                                                return arrayHelpers.insert(index, "")
                                              }}
                                            >
                                              Add
                                            </button>
                                        </td>
                                      </tr>)
                                    )
                                  ) : (
                                  <Col className="col-12 d-flex mb-3">
                                      <button
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.push("")}
                                      >
                                      Add Member
                                      </button>
                                  </Col>
                                  )}
                                </>
                              )}
                            />
                          </tbody>
                        </Table>
                        <Row className="mb-3">
                            <Col className="p-0">
                            <hr />
                            </Col>
                        </Row>{" "}
                        <Row className="col-12 row-cols-1 row-cols-md-1 row-cols-lg-2">
                            <Col className="col-12">
                            <label className="input-label mb-3">SUMMARY VIEW</label>
                            </Col>
                            <Col className="col-12">
                              <label>Total cost of Kit: {totalKitCost > 0 ?  `$${totalKitCost}` : null}</label>
                            </Col>
                        </Row>
                        <Row className="ml-1 col-12 row-cols-1 row-cols-md-1 row-cols-lg-2 p-0">
                            <Col className="col-6 p-0">
                            <TextInput
                                type="text"
                                name="issuedTo"
                                className="w-75"
                                labelClassName="mb-2"
                                label="Issued To:"
                                placeholder="Issuer Name"
                            />
                            </Col>
                            <Col className="col-6 p-0">
                            <TextInput
                                className="w-75"
                                labelClassName="mb-2"
                                name="supplyDate"
                                type="date"
                                label="Date"
                            />
                            </Col>
                        </Row>
                        <Row className="ml-1 col-12 row-cols-1 row-cols-md-1 row-cols-lg-2 p-0">
                            <Col className="col-6 p-0">
                            <TextInput
                                type="text"
                                name="ejasNumber"
                                className="w-75"
                                labelClassName="mb-2"
                                label="EJAS Number"
                                placeholder="ejas number"
                            />
                            </Col>
                        </Row>
                        <Row className="ml-1 col-12 row-cols-1 row-cols-md-1 row-cols-lg-2 p-0">
                            <Col className="col-6 p-0">
                            <TextInput
                                type="text"
                                name="caseNumber"
                                className="w-75"
                                labelClassName="mb-2"
                                label="Case Number"
                                placeholder="Name"
                            />
                            </Col>
                            <Col className="col-6 p-0">
                            <TextInput
                                type="text"
                                name="caseManagerSignature"
                                className="w-75"
                                labelClassName="mb-2"
                                label="Case Manager Signature"
                                placeholder="Signature"
                            />
                            </Col>
                        </Row>
                      </div>
                    </Row>
                    <Row className='justify-content-between mt-3 mr-0 ml-0 w-100'>
                      <div></div>
                      <Button variant="primary" type='submit'>{selected && selected.supplyId ? 'UPDATE' : 'SAVE'}</Button>
                    </Row>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Col>
      </Row>      
    )
  }

  render() {
    const { getBFETSuppliesFetching } = this.props
    return (
      <>
        { !getBFETSuppliesFetching && this.renderLoading() }
        { getBFETSuppliesFetching && this.renderContent() }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.client.supplies.BFET && state.client.supplies.BFET.supplies,
    getBFETSuppliesFetching: state.client.suppliesFetching.BFET,
    bFetSupplies: getBFETSupplies(state.account, 'BFET'),
    write: isPermissionEnabled(state.user, PERMISSIONS.WRITE_BFET_SUPPLIES),
    read: isPermissionEnabled(state.user, PERMISSIONS.READ_BFET_SUPPLIES),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountSuppliesInformation: (category) => 
      dispatch(AccountActions.getAccountSuppliesInformation(category)),
    getClientSuppliesInformation: (id, category) =>
      dispatch(ClientActions.getClientSuppliesInformation(id, category)),
    postClientSuppliesInformation: (id, category) =>
      dispatch(ClientActions.postClientSuppliesInformation(id, category)),
    updateClientSuppliesInformation: (id, supplyId, category, params) =>
      dispatch(ClientActions.updateClientSuppliesInformation(id, supplyId, category, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplies);