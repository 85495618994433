
import React from 'react';
import Layout from 'components/Layout.jsx'
import { Col, Row, Button, Card, Badge} from 'react-bootstrap';
import { connect } from 'react-redux'
import UserActions from 'redux/UserRedux'
import LoadingView from 'components/LoadingView.jsx'
import AccountUpdate from 'components/Modals/AccountUpdate.jsx'
import AccountUpdatePassword from 'components/Modals/AccountUpdatePassword.jsx'
import AuthActions from 'redux/AuthRedux';

export class AccountInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showAccountUpdateModal: false,
      showAccountUpdatePasswordModal: false
    }
    this.handleUpdateInfo = this.handleUpdateInfo.bind(this)
    this.onCloseAccountUpdateModal = this.onCloseAccountUpdateModal.bind(this)
  }

  componentDidMount () {
    const { userId } = this.props
    if (userId) {
      this.props.getUserInfo(userId)
    }
  }

  renderLoading = () => {
    return <LoadingView />
  }

  renderContent = () => {
    const { email, firstName, lastName, phone, addressLine1 } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col lg={12}>
            <h1 className='page-header'>Account Settings</h1>
          </Col>
        </Row>


        <Card className='acc-box'>
          <Card.Body>
            <Row>
              <Col md={12} className='marginBottom10'>
                <Row>
                  <Col md={8}>
                    <h3>Account Information</h3>

                    <Row className="p-2">
                      <Badge className='detail-label'>Name: </Badge>
                      <Badge className='patient-detail'>{`${firstName} ${lastName}`}</Badge>
                    </Row>

                    <Row className="p-2">
                      <Badge className='detail-label'>Email: </Badge>
                      <Badge className='patient-detail'>{email}</Badge>
                    </Row>

                    <Row className="p-2">
                      <Badge className='detail-label'>Phone: </Badge>
                      <Badge className='patient-detail'>{phone}</Badge>
                    </Row>

                    <Row className="p-2">
                      <Badge className='detail-label'>Address: </Badge>
                      <Badge className='patient-detail'>{addressLine1}</Badge>
                    </Row>

                  </Col>
                  <Col md={4}>
                    <Button variant="outline-primary" className='marginBottom10 full-width' onClick={this.handleUpdatePassword}>Update Account Password</Button>
                    <Button variant="outline-primary" className='marginBottom10 full-width' onClick={this.handleUpdateInfo}>Update Account Information</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  }

  handleUpdateInfo = (e) => {
    e.preventDefault()
    this.setState({
      showAccountUpdateModal: true
    })
  }

  handleUpdatePassword = () => {
    this.setState({
      showAccountUpdatePasswordModal: true
    })
  }

  onCloseAccountUpdateModal = (e) => {
    this.setState({
      showAccountUpdateModal: false
    })
  }

  renderAccountInfoModal = () => {
    const { email, firstName, lastName, phone, addressLine1, city, state, country, zipcode, userId } = this.props

    return <AccountUpdate
      onClose={this.onCloseAccountUpdateModal}
      email={email}
      firstName={firstName}
      lastName={lastName}
      phone={phone}
      addressLine1={addressLine1}
      city={city}
      country={country}
      state={state}
      zipcode={zipcode}
      userId={userId}
      onSave={this.props.updateUserInfo}
    />
  }


  onCloseAccountUpdatePasswordModal = (e) => {
    this.setState({
      showAccountUpdatePasswordModal: false
    })
  }


  renderAccountUpdatePasswordModal = () => {
    return <AccountUpdatePassword
      onClose={this.onCloseAccountUpdatePasswordModal}
      onSave={this.props.updatePassword}
    />
  }

  render () {
    const { loading } = this.props
    const { showAccountUpdateModal, showAccountUpdatePasswordModal } = this.state

    return (
      <Layout>
        { loading && this.renderLoading() }
        { !loading && this.renderContent() }
        { showAccountUpdateModal && this.renderAccountInfoModal() }
        { showAccountUpdatePasswordModal && this.renderAccountUpdatePasswordModal() }
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      userId: state.user.userId,
      loading: state.user.loading,
      email: state.user.email,
      firstName: state.user.firstName || '',
      lastName: state.user.lastName || '',
      phone: state.user.phone,
      addressLine1: state.user.addressLine1,
      city: state.user.city,
      state: state.user.state,
      country: state.user.country,
      zipcode: state.user.zipcode,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getUserInfo: (userId) => dispatch(UserActions.getUserInfo(userId)),
      updateUserInfo: (userId, user) => dispatch(UserActions.updateUserInfo(userId, user)),
      updatePassword: (currentPassword, newPassword) => dispatch(AuthActions.updatePassword(currentPassword, newPassword))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);